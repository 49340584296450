import React, { memo, useState } from "react";
import "./queue.css";
import QueueTable from "./QueueTable";
import * as JobController from "../../../../actions/job";
import { useQuery } from "react-query";
import { useDebouncedValue } from "@mantine/hooks";
import { IPaginatedRequest } from "../../../../interfaces";
import SyncNav from "../../../ui/sync-nav/SyncNav";
import queryString from "query-string";
import FilterSelect from "../../../ui/select/FilterSelect";

export enum JobStatus {
  WAITING = "WAITING",
  RUNNING = "RUNNING",
  FINISHED = "FINISHED",
  ERROR = "ERROR",
}

const Queue: React.FC = () => {
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: "",
    status: "All statuses",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

  const {
    isLoading: loadJobs,
    data: jobItems,
    refetch,
  } = useQuery(`jobItems?${queryString.stringify(debouncedConfig)}`, () =>
    JobController.getJobItems(debouncedConfig)
  );

  return (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont p0">
          <SyncNav />
          <div className="mapping-title-border"></div>
          <div className="table-title-cont">
            <div className="mplc-filter-cont width100">
              <div className="mplc-filter">
                <div className="filter-text">Filter by:</div>
                <FilterSelect
                  onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentConfig((conf: IPaginatedRequest) => ({
                      ...conf,
                      status: e.target.value,
                    }))
                  }
                  value={currentConfig.status}
                  data={["All statuses", ...Object.values(JobStatus)]}
                />
              </div>
              <button className="tax-title-button pointer mr2">
                <div
                  className="pim-avaliacoes-title-button-text p0"
                  onClick={(e) => refetch()}
                >
                  refresh
                </div>
              </button>
            </div>
          </div>
          <QueueTable
            jobItems={jobItems}
            setCurrentConfig={setCurrentConfig}
            currentConfig={currentConfig}
            loadJobs={loadJobs}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Queue);
