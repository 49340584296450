import axios from "axios";
import { IPaginatedRequest, IPaginatedResponse, IRequest } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";
import { CreateNotification, Notification } from "types/notification";

export const getNotifications = async () => {
	const res = await axios.get("/notifications", getHeaders());
	return res.data.notifications as Notification[];
};

export async function getMyNotifications(pagination: IPaginatedRequest) {
	const res = await axios.get(
		`/me/notifications?${queryString.stringify(pagination)}`,
		getHeaders()
	);

	return res.data as IPaginatedResponse<Notification[]>;
}

export const readAllNotifcations = async () => {
	await axios.post("/notifications/readAll", {}, getHeaders());
};

export const getAllRequests = async () => {
	const res = await axios.get("/requests/all", getHeaders());
	return res.data.requests;
};

export const getUserRequests = async () => {
	const res = await axios.get("/requests", getHeaders());
	return res.data.requests;
};

export const changeAdminStatus = async (requestId: string, status: string) => {
	await axios.post(
		`/request/${requestId}/changeStatus`,
		{ status },
		getHeaders()
	);
};

export const sendUserRequest = async (body: any) => {
	await axios.post("/request", body, getHeaders());
};

export const cancelUserRequest = async (id: string) => {
	await axios.post(`/request/${id}/cancel`, {}, getHeaders());
};

export async function getPaginatedRequests(pagination: IPaginatedRequest) {
	const res = await axios.get(
		`/requests/all?${queryString.stringify(pagination)}`,
		getHeaders()
	);
	return res.data;
}

export const changeRequestStatus = async (body: IRequest) => {
	await axios.put(
		`/request/${body._id}/productManipulation`,
		body,
		getHeaders()
	);
};

export async function createNotification(data: CreateNotification) {
	await axios.post(`/notification`, data, getHeaders());
}

export async function readNotification(id: string) {
	await axios.post(`/notification/${id}/read`, {}, getHeaders());
}

export async function closeNotification(id: string) {
	await axios.post(`/notification/${id}/close`, {}, getHeaders());
}

export const getTicketNotifications = async () => {
	const res = await axios.get(`/ticket-notifications`, getHeaders());

	return res.data;
};

export const allReadTicketNotifications = async () => {
	await axios.put(`/ticket-notifications`, {}, getHeaders());
};
