import axios from "axios";
import { IPaginatedRequest } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";

export async function fetchReviews(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/reviews?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}

export async function fetchReviewsBySku(sku: string) {
  const res = await axios.get(`/reviews/${sku}`);
  return res.data;
}

export interface IReview {
  _id?: string;
  sku: string;
  product: any;
  origin: string;
  name: string;
  title: string;
  content: string;
  rating: number;
  country?: string;
}

export async function createReview(review: IReview) {
  await axios.post("/addReview", review);
}

export async function updateReview(id: string, review: IReview) {
  await axios.put(`/reviews/${id}`, review, getHeaders());
}

export async function deleteReview(id: string) {
  await axios.delete(`/reviews/${id}`, getHeaders());
}

export async function bulkImport(file: File) {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`/reviews/bulk-import`, formData, getHeaders());
  return res.data;
}
