import React, { useMemo, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useQuery } from "react-query";
import { getProductImportReports } from "../../../../actions/user";
import { ProductImportReport } from "types/seller";
import { DateTime } from "luxon";
import ImageWithFallback from "../../../ui/image-with-fallback";
import { getImagePath } from "../../../../utils";
import ProductCountsStats from "../../../ui/product-counts-stats";
import { toast } from "react-toastify";
import { downloadPriceStockData } from "../../../../actions/onboardingSeller";
import UploadPriceStockModal from "../../tables/gestao/UploadPriceStockModal";

export default function ProductImportReports() {
    const history = useHistory();
    const { data } = useQuery("productImportReports", getProductImportReports, { refetchOnWindowFocus: false });

    return (
        <div className="main-body">
            <div className="main-container">
                <div className="table-main-cont pb4">
                    {/* <span
                        onClick={() => history.goBack()}
                        className="single-billing-nav pointer"
                    > */}
                    <NavLink to="/my/catalog/unregistered" className="single-billing-nav pointer">
                        <img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
                        <div style={{ color: "#55718a" }} className="table-subtext bold pt1 pl2">back to Catalog</div>
                    </NavLink>
                    {/* </span> */}
                    <div style={{ paddingLeft: "0", paddingRight: "0" }} className="table-title-cont ">
                        <div className="mplc-filter-cont mt1">
                            <div className="gestao-select-cont justify-between">
                                <span className="pim-language-text pl4">Product import reports</span>
                            </div>
                        </div>
                    </div>

                    {data?.map(item => (
                        <ReportAccordion key={item._id} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
}

const ReportAccordion: React.FC<ProductImportReport> = (report) => {
    const [modal, setModal] = useState<boolean>(false);
    const [mode, setMode] = useState<string>("cost");

    const onModalClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setModal(false);
    };

    const downloadFile = async (e: React.MouseEvent) => {
        e.preventDefault();

        try {
            await downloadPriceStockData(mode, report._id);
            toast.success("Your spreadsheet will be sent by email!", { autoClose: 10000 });
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong");
        }
    };

    const platforms = useMemo(() => Object.keys(report.platformCounter).filter(zeoosName => report.platformCounter[zeoosName].productsCount).map(zeoosName => ({
        zeoosName,
        ...report.platformCounter[zeoosName]
    })), [report]);

    return (
        <>
            <Accordion className="pl4 mb4">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="width100"
                >
                    <div className="width100 in-row align-center justify-between">
                        <span className="pim-language-text">PIR {report._id.slice(-6)} - {report.total} EANs</span>
                        <div className="in-row alin-center">
                            <time className="pl2 email-text">{DateTime.fromISO(report.created as any).toFormat("LLLL dd, yyyy - HH:mm")}</time>
                        </div>
                    </div>
                </AccordionSummary>

                <div style={{ borderBottom: "1px solid #E1E1E1" }} />

                <AccordionDetails className="width100">
                    <div className="in-column width100 align-start justify-start p4">
                        <div className="in-row width100 align-center justify-between" style={{ height: 150 }}>
                            <div className="width50 in-column align-start p4 justify-start height100">
                                <span className="email-text mb3">
                                    1. Download the spreadsheet of your integrated products to fill in with<br /> your offers in the sales channels.
                                </span>
                                <div className="pc-modal-spreadsheet-button pointer">
                                    <img src="/icons/upload-spreadsheet.svg" alt="" />
                                    <div style={{ color: "#55718a" }}
                                        className="pc-modal-spreadsheet-button-text initial"
                                        onClick={downloadFile}
                                    >
                                        Get product spreadsheet
                                    </div>
                                </div>
                            </div>

                            <div className="width50 in-column align-start p4 justify-start height100">
                                <span className="email-text mb3">
                                    2. Upload your spreadsheet with your offers.
                                </span>
                                <div onClick={() => {
                                    setModal(true);
                                }} style={{ background: "#55718A" }} className="pc-modal-spreadsheet-button pointer mb3">
                                    <img src="/icons/upload2product.svg" alt="" />
                                    <div style={{ color: "#fff" }} className="pc-modal-spreadsheet-button-text initial">Send the offers file</div>
                                </div>
                                {report.offerImport &&
                                    <div className="in-row align-center pointer">
                                        <img src="/icons/filetype-xlsx.svg" alt="" />
                                        <div className="pc-modal-file-name-text">{report.offerImport.uploadFile}</div>
                                        <div style={{ color: "#299C00", fontSize: "12px" }} className="email-text pl2 bold">
                                            {DateTime.fromISO(report.offerImport.created as any).toFormat("LLLL dd, yyyy - HH:mm")}
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                        <div className="width100" style={{ borderBottom: "1px solid #E1E1E1" }} />

                        <div className="in-row width100 align-start justify-between">
                            <div className="width40 in-column align-start p4">
                                <span className="pim-language-text">{report.total} imported products</span>
                                <div className="in-row pt4 pb4 width100 align-center justify-between">
                                    <ProductCountsStats {...report} />
                                </div>
                                <span style={{ fontSize: 12 }} className="email-text bold">Unregistered products</span>
                                <small style={{ fontSize: 12 }} className="email-text pb2">If your import batch contains unregistered products, we will<br />automatically create a batch of products to register. Follow<br />the registration made by our team.</small>

                                {!!report.unregistered && (
                                    <NavLink to={`/my/catalog/unregistered/${report._id}`} className="pc-modal-spreadsheet-button pointer mt0">
                                        <div style={{ color: "#55718a", paddingLeft: 0 }} className="pc-modal-spreadsheet-button-text initial">Check registration</div>
                                    </NavLink>
                                )}
                            </div>

                            <div className="width60 in-column align-start p4">
                                <span className="pim-language-text">{report.integrated} integrated products in {platforms.length} sales channels</span>
                                <div className="batch-integrated-cont">

                                    {platforms.map(platform =>
                                        <div key={platform.zeoosName} className="batch-integrated-box">
                                            <ImageWithFallback
                                                primarySrc={getImagePath(platform.zeoosName)}
                                                fallbackSrc={getImagePath(platform.marketplaceName)}
                                                alt=""
                                                width="60px"
                                            />

                                            <div className="in-column align-start ml2">
                                                <span>{platform.productsCount} in {platform.zeoosName}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            {modal && <UploadPriceStockModal
                onModalClose={onModalClose}
                isOpened={modal}
                report={report}
                setModal={setModal}
            />
            }
        </>
    );
};
