import axios from "axios";
import { IVendorPermission } from "../interfaces";
import { getHeaders } from "./user";

export interface UserGroup {
  _id: string;
  name: string;
  vendorPermissions: IVendorPermission[];
  image: string;
  created: Date;
}

export async function find() {
  const res = await axios.get("/user-groups", getHeaders());
  return res.data as UserGroup[];
}

export async function create(userGroup: Partial<UserGroup>) {
  await axios.post("/user-group", userGroup, getHeaders());
}

export async function update(id: string, userGroup: Partial<UserGroup>) {
  await axios.put(`/user-group/${id}`, userGroup, getHeaders());
}
