import React, { memo, useCallback } from "react";
import "./events.css";
import { IPaginatedRequest } from "../../../../interfaces";
import { useMutation } from "react-query";
import { PricingEvent, EventType, EventStatus } from "types";
import Paginator from "../../../ui/pagination";
import Tooltip from "@mui/material/Tooltip";
import { DateTime } from "luxon";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";
import * as PricingController from "../../../../actions/pricingEvent";
import { NavLink } from "react-router-dom";
import { formatValue } from "../Pricing";

interface IPaginatedEvents {
	data: PricingEvent[];
	lastPage: number;
	page: number;
	perPage: number;
	total: number;
}

interface Props {
	events: IPaginatedEvents;
	rqKeyEvents: string;
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
}

const PlannedEvents: React.FC<Props> = ({
	events,
	rqKeyEvents,
	setCurrentConfig,
	currentConfig,
}) => {
	// const [selectedEvents, setSelectedEvents] = useState<string[]>([]);

	const refetchEventsConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyEvents);
			toast.success(`The event is successfully "deleted"!`);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const deleteMutation = useMutation(
		(id: string) => PricingController.deleteEvent(id),
		refetchEventsConfig
	);

	const onDeleteEvent = useCallback(
		async (e: React.FormEvent, id: string) => {
			e.preventDefault();
			await deleteMutation.mutateAsync(id);
		},
		[deleteMutation]
	);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	const lineColor = (status: string) => {
		switch (status) {
			case "ABORTED":
				return "pricing-line-aborted";
			case "SCHEDULED":
				return "";
			default:
				return "bluebg";
		}
	};

	// const handleSelectAll = useCallback(() => {
	// 	const ids = events?.data?.map((u) => u._id);
	// 	setSelectedEvents((prev: string[]) => (!prev.length ? ids : []));
	// }, [events?.data, setSelectedEvents]);

	return (
		<>
			<div className="table-main-title-cont">
				<div className="table-main-title-search-value-cont">
					<div className="search-box">
						<div className="search_form">
							<div className="table-search-box">
								<form
									className="table-search-form"
									onSubmit={(e) => e.preventDefault()}
								>
									<input
										className="table-search-input"
										placeholder="Search event by title"
										onChange={(e) =>
											setCurrentConfig((conf: IPaginatedRequest) => ({
												...conf,
												search: e.target.value,
											}))
										}
										value={currentConfig.search}
									/>
									<img
										className="table-search-image"
										src="/icons/search.svg"
										width="25px;"
										alt=""
									/>
								</form>
							</div>
						</div>
						<div className="table-main-title-text pl2">
							<span className="table-main-title-text">
								{events?.total} {events?.total > 1 ? "events" : "event"}
							</span>
						</div>
					</div>
				</div>
				<div className="marketplace-pagination">
					<Paginator data={events} refetch={onChangePage as any} />
				</div>
			</div>
			<div style={{ height: "max-content" }} className="table-overflow">
				<table
					style={{
						position: "sticky",
						zIndex: 101,
						top: "2px",
						width: "100%",
					}}
				>
					<thead>
						<tr
							style={{ marginTop: "-5px", width: "100%" }}
							className="table-results-title"
						>
							{/* <th className="ml3 mr3">
								<img
									className="pointer"
									src={
										selectedEvents.length
											? "/icons/carbon_checkbox.svg"
											: "/icons/check-box.svg"
									}
									alt=""
									onClick={handleSelectAll}
								/>
							</th> */}
							<th className="pricing-id">Event ID</th>
							<th className="pricing-title">Title</th>
							<th className="pricing-status">Status</th>
							<th className="pricing-period">Event Period</th>
							<th className="pricing-sku">SKUs</th>
							<th className="pricing-result in-column align-center jusify-between">
								<span>Event Result</span>
								<span>(Sold / Estimated)</span>
							</th>
							<th className="pricing-creator">Creator</th>
						</tr>
					</thead>
				</table>
				<table
					className="table-results mb2 batch-text"
					style={{ minHeight: "auto" }}
				>
					<tbody>
						{events?.data?.map((event: PricingEvent) => (
							<tr className="table-result-box height100px" key={event._id}>
								{/* <td className="ml3 mr3" onClick={() => {}}>
									<img
										className="pointer"
										src={
											!selectedEvents.find((x: any) => x._id === event._id)
												? "/icons/check-box.svg"
												: "/icons/check-box-checked.svg"
										}
										alt=""
									/>
								</td> */}
								<NavLink to={`/pricing-event/${event._id}`}>
									<td className="pricing-id pointer">
										<span>#{event._id.slice(0, 6)}</span>
									</td>
								</NavLink>
								<td className="pricing-title">
									<img
										className="mr2"
										width="20px"
										height="20px"
										src={
											event.type === EventType.CUSTOM
												? "/icons/custom-discount.svg"
												: "/icons/automatic-discount.svg"
										}
										alt=""
									/>
									<span>{event.name}</span>
								</td>
								<td className="pricing-status">
									<div className={`pricing-${event.status.toLowerCase()}`}>
										{event.status}
									</div>
								</td>
								<NavLink to={`/pricing-event/${event._id}`}>
									<LineWithTooltip
										color={
											// event.status === EventStatus.ABORTED
											// 	? "pricing-line-aborted"
											// 	: "bluebg"
											lineColor(event.status)
										}
										rangeType={"date"}
										firstVal={new Date(event.eventPeriod.start).valueOf()}
										secondVal={new Date(event.eventPeriod.end).valueOf()}
										firstText={"Start"}
										secondText={"End"}
										status={event.status}
										abortedDate={
											event.status === EventStatus.ABORTED &&
											event.eventPeriod?.aborted
										}
									/>
								</NavLink>
								<td className="pricing-sku">
									<span>{formatValue(event.products.length)}</span>
								</td>
								<NavLink to={`/pricing-event/${event._id}`}>
									<LineWithTooltip
										color={
											event.status === EventStatus.ABORTED
												? "pricing-line-aborted"
												: "greenbg"
										}
										rangeType={"result"}
										firstVal={event.eventResult.sold}
										secondVal={event.eventResult.goal}
										firstText={"Sale goal"}
										secondText={"Sold"}
										status={event.status}
									/>
								</NavLink>
								<td className="pricing-creator">
									<img
										className="mr2"
										style={{ borderRadius: "60px", marginRight: "10px" }}
										width="30px"
										height="30px"
										src={
											!event.creator.profileImage
												? "/icons/image-bot.svg"
												: event.creator.profileImage
										}
										alt=""
									/>
									<span>{event.creator.username}</span>
								</td>
								<td
									className="opacity0"
									onClick={(e) => onDeleteEvent(e, event._id)}
								>
									X
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
};

interface TooltipProps {
	color: string;
	rangeType: string;
	firstVal: number;
	secondVal: number;
	firstText: string;
	secondText: string;
	status: string;
	abortedDate?: any;
}

const LineWithTooltip: React.FC<TooltipProps> = ({
	color,
	rangeType,
	firstVal,
	secondVal,
	firstText,
	secondText,
	status,
	abortedDate,
}) => {
	const dateRange = () => {
		const dateDif = secondVal - firstVal;
		const fromNow = Date.now().valueOf() - firstVal;

		return fillLevel(fromNow, dateDif);
	};

	const fillLevel = (value: number, total: number) => {
		return value < total ? `${Math.round((value * 100) / total)}%` : "100%";
	};

	const dateFormat = (date: Date | number, time: boolean) => {
		const formatedDate = time
			? new Date(date).toLocaleString("en-GB").slice(0, -3).split(",")
			: new Date(date).toLocaleString("en-GB").slice(0, -9).split(",");

		return (
			<>
				{formatedDate.map((el: string, index: number) => (
					<span key={index}>{el}</span>
				))}
			</>
		);
	};

	const timeRemaining = () => {
		const date1 = DateTime.now();
		const date2 = DateTime.fromMillis(secondVal);

		const diff = date2.diff(date1, ["days", "hours", "minutes"]).toObject();

		return `${diff.days} days ${diff.hours} hours ${diff.minutes?.toFixed(
			0
		)} minutes`;
	};

	const valueRemaining = () => {
		const value =
			firstVal > secondVal
				? `${formatValue(firstVal - secondVal)} € (Exceeded)`
				: `${formatValue(secondVal - firstVal)} €`;

		return (
			<>
				<b>Remaining:</b>
				<span
					className={`bold ${status === EventStatus.ABORTED ? "red" : "green"}`}
				>
					{value}
				</span>
			</>
		);
	};

	const statusDate = () => {
		if (status === EventStatus.EXPIRED || status === EventStatus.SCHEDULED) {
			return <b className="blue">{status}</b>;
		} else if (status === EventStatus.ABORTED) {
			return (
				<div className="in-row">
					<span className="bold red">{status}: </span>
					<span className="bold red">{dateFormat(abortedDate, true)}</span>
				</div>
			);
		} else {
			return (
				<>
					<b>Remaining:</b>
					<span className="blue-text">{timeRemaining()}</span>
				</>
			);
		}
	};

	const tooltipTitle = () => {
		return (
			<div className="pricingTooltopgraph">
				<div className="textgraph">
					<b>{firstText}: </b>
					<span>
						{rangeType === "date"
							? dateFormat(firstVal, true)
							: `${formatValue(secondVal)} €`}
					</span>
				</div>
				<div className="textgraph">
					<b>{secondText}: </b>
					<span>
						{rangeType === "date"
							? dateFormat(secondVal, true)
							: `${formatValue(firstVal)} €`}
					</span>
				</div>
				<div className="textgraph">
					<div className="in-column align-start">
						{rangeType === "date" ? statusDate() : valueRemaining()}
					</div>
				</div>
			</div>
		);
	};

	return (
		<Tooltip arrow placement="top" title={tooltipTitle()}>
			<td className="pricing-period in-column align-center jusify-between pointer">
				<div className="ft1">
					{rangeType === "date" ? (
						<>
							{dateFormat(firstVal, false)} - {dateFormat(secondVal, false)}
						</>
					) : (
						<>{`${formatValue(firstVal)} € / ${formatValue(secondVal)} €`}</>
					)}
				</div>
				<div className="pricing-line height6">
					<div
						className={`pricing-line-active ${color}`}
						style={{
							width:
								rangeType === "date"
									? dateRange()
									: fillLevel(firstVal, secondVal),
						}}
					></div>
					{firstVal > secondVal && (
						<div
							className="pricing-line-over"
							style={{
								width: fillLevel(firstVal - secondVal, secondVal),
							}}
						></div>
					)}
				</div>
			</td>
		</Tooltip>
	);
};

export default memo(PlannedEvents);
