import React, { useState } from "react";
import { IZeoosOrder } from "../../../../../../interfaces";
import RoleRequired from "../../../../../utils/role-required/RoleRequired";
import { Role } from "../../../../../../interfaces";
// import { UserContext } from "../../../../../context";
import {
	confirmPurchaseOrder,
	rejectPurchaseOrder,
} from "../../../../../../actions/order";
import { toast } from "react-toastify";
import { downloadBlob } from "../singleorderview/SingleOrderViewStepper";
import FilterSelect from "../../../../../ui/select/FilterSelect";
import { Modal } from "../../../../../ui/modal/Modal";
import { downloadPurchaseOrder } from "../../../../../../actions/v2/order";
import CircularPreloader from "../../../../../ui/circular-preloader/CircularPreloader";
import { rejectReasons } from "../singleorderview/SingleOrderRefundModal";

interface Props {
	order: IZeoosOrder;
	shipping?: string;
	onSuccess: () => void;
}

const SingleOrderPurchaseInfo: React.FC<Props> = ({ order, shipping, onSuccess }) => {
	const [rejectModal, setRejectModal] = useState<boolean>(false);
	const [reason, setReason] = useState<string>(rejectReasons[0].value);
	const [isDownloadingOrder, setIsDownloadingOrder] = useState(false)

	const shipping_obj = order.shipping_list?.find(
		(s: any) => s._id === shipping
	);
	const confirm = shipping_obj.history.find(
		(h: any) => h.event === "confirm_purchase"
	);
	// const { user } = useUser();

	const confirmPurchase = async () => {
		const purchase = await confirmPurchaseOrder(shipping_obj.purchase_order);
		if (purchase.success) {
			toast.success(purchase.message);
			onSuccess();
		} else {
			toast.error(purchase.message);
		}
	};

	const rejectPurchase = async () => {
		const res = await rejectPurchaseOrder({
			id: order.order_id,
			reason,
		});
		if (res.success) {
			toast.success(res.message);
		} else {
			toast.error(res.message);
		}

		setRejectModal(false);
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setRejectModal(false);
	};

	const onDownloadPurchaseOrder = async () => {
		setIsDownloadingOrder(true)
		try{
			await downloadPurchaseOrder(shipping_obj.purchase_order)
			toast.success('Success')
		}catch(error:any){
			toast.error('Error nor')
		}
		setIsDownloadingOrder(false)
	} 
	

	return (
		<>
			<div className="single-order-title-cont">
				<div className="in-row align-center">
					<h2 className="single-order-title">
						Purchase Order {shipping_obj.purchase_order}
					</h2>
				</div>
				<div className="in-row align-center">
					<RoleRequired roles={[Role.user, Role.admin, Role.sellerUser, Role.sellerAdmin]}>
						{!isDownloadingOrder && <img
							className="mr2 pointer"
							src="/icons/order-print.svg"
							alt=""
							title="Download Purchase Order"
							onClick={onDownloadPurchaseOrder}
						/>}
						 {isDownloadingOrder && <CircularPreloader/>}
						{/* <div className="in-row align-center mr2 pointer so-purchase-text">
							<img
								className="mr2 pointer"
								src="/icons/so-tag-print.svg"
								alt=""
							/>
							Print Shipping Label
						</div> */}
					</RoleRequired>
					{!confirm && (
						<RoleRequired
							roles={[Role.user, Role.sellerUser, Role.sellerAdmin]}
						>
							<button
								className="so-reject-btn mr2 pt2 pb2"
								onClick={() => setRejectModal(true)}
							>
								Reject Purchase Order
							</button>
							<button
								className="so-blue-btn m0 pt2 pb2"
								onClick={confirmPurchase}
							>
								Confirm Purchase Order
							</button>
						</RoleRequired>
					)}
				</div>
			</div>
			{/* <div className='order-border'></div> */}
			<div className="so-adresse-cont mb4 mt2">
				{/*
                <div className='width100'>
                    <div className='mt4 single-order-text'>
                        <div className='so-details-box'>
                            <b className='mr1'>Model Service:</b> Drop Shipping
                        </div>
                        <div className='so-details-box'>
                            <b className='mr1'>Received Value:</b> 0,00
                        </div>
                        <div className='so-details-box'>
                            <b className='mr1'>DBills Paid:</b> No
                        </div>
                        <div className='so-details-box'>
                            <b className='mr1'>Payment Status:</b> Open
                        </div>
                        <div className='so-details-box'>
                            <b className='mr1'>Moeda:</b> EUR
                        </div>
                    </div>
                </div>
                <div className='width100'>
                    <div className='mt4 single-order-text'>
                        <div className='so-details-box'>
                            <b className='mr1'>Confirmation Date:</b> 22-06-2022 11:50:47
                        </div>
                        <div className='so-details-box'>
                            <b className='mr1'>Receipt Date:</b> 22-06-2022 13:00:00
                        </div>
                    </div>
                </div>
                */}
			</div>
			{rejectModal && (
				<Modal onModalClose={onModalClose} isOpened={rejectModal}>
					<>
						<div className="table-modal-cont">
							<div className="table-modal-title-box">
								<div>
									<div className="table-modal-title-pricing">
										Reject purchase order
									</div>
								</div>
							</div>
							<div className="table-modal-border"></div>
							<div className="single-order-text width100">
								<div className="so-details-box">
									<span className="ft2">
										By rejecting this purchase order, this order will be
										cancelled
									</span>
								</div>
								<div className="in-column items-start mt5 mb5">
									<div className="mb0">Please let us know the reason:</div>
									<FilterSelect
										onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
											setReason(e.target.value)
										}
										value={reason}
										options={rejectReasons}
									/>
								</div>
							</div>
							<div className="table-modal-border"></div>
							<div className="table-modal-form-button-box width100">
								<button
									onClick={onModalClose}
									className="table-modal-form-button-cancel"
								>
									Cancel
								</button>
								<button
									className="table-modal-form-button"
									onClick={rejectPurchase}
								>
									Reject purchase order
								</button>
							</div>
						</div>
					</>
				</Modal>
			)}
		</>
	);
};

export default SingleOrderPurchaseInfo;
