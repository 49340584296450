import React, { useMemo } from 'react';
import MultiSelect from '../multi-select';
import useActiveVendors from '../../../hooks/useActiveVendors';

interface Props {
  value: string;
  setValue: (newValue: string) => void;
}

const SellerSelect: React.FC<Props> = ({ value, setValue }) => {
  const { data: sellers } = useActiveVendors();
  const options = useMemo(() =>
    sellers?.map((seller: any) => ({ label: seller.name, value: seller._id })) || [], [sellers]);

  return (
    <MultiSelect
      helperText='Select Seller'
      value={value}
      setValue={setValue as any}
      options={options}
      multiple={false}
    />
  );
};

export default SellerSelect;
