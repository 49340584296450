import FilterSelect from "../../ui/select/FilterSelect";
import Slider from "@mui/material/Slider";
import { styled } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ICountryM } from "../../routes/country-management/CountryManagement";
import useCountries from "../../../hooks/useCountries";
import * as _ from "lodash";
import "./filter.css";

interface Props {
  isViewFilter: boolean;
  setIsViewFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

const PriceSlider = styled(Slider)({
  color: "#ACACAC",
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: "#fff",
    border: "2px solid #ACACAC",
  },
  "& .MuiSlider-rail": {
    color: "#ACACAC",
  },
  "& .MuiSlider-track": {
    color: "#ACACAC",
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "bold",
    top: 3,
    backgroundColor: "#ACACAC",
    color: "#ACACAC",
  },
});

const Filter: React.FC<Props> = ({ isViewFilter, setIsViewFilter }) => {
  const countries = useCountries() as ICountryM[];

  const [sliderValue, setSliderValue] = useState<number[]>([0, 100]);
  const [filterStock, setFilterStock] = useState("All");
  const [filterCountry, setFilterCountry] = useState<string>("All countries");

  useEffect(() => {
    document.body.style.overflow = isViewFilter ? "hidden" : "auto";
  }, [isViewFilter]);

  const sliderValueText = (value: number) => {
    return `${value} €`;
  };

  return (
    <>
      <button onClick={() => setIsViewFilter(true)} className="filter-button">
        <img src="/icons/filterIcon.svg" alt="filter icon" />
        <p>
          Filter <span>(2)</span>
        </p>
      </button>
      <div
        onClick={() => setIsViewFilter(false)}
        style={{
          opacity: isViewFilter ? 1 : 0,
          pointerEvents: isViewFilter ? "auto" : "none",
        }}
        className="filter-modal-back"
      />
      <div
        style={{ right: isViewFilter ? 0 : "-320px" }}
        className="filter-modal"
      >
        <div className="filter-top">
          <p>
            Filter by <span>(2)</span>
          </p>
          <button>Clear all filters</button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <FilterSelect
            style={{ width: "100%", marginRight: 0 }}
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilterCountry(e.target.value)
            }
            value={filterCountry}
            data={[
              "Category",
              ..._.uniq(_.map(countries, (item: ICountryM) => item.name)),
            ]}
          />
          <FilterSelect
            style={{ width: "100%", marginRight: 0 }}
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilterCountry(e.target.value)
            }
            value={filterCountry}
            data={[
              "Countries",
              ..._.uniq(_.map(countries, (item: ICountryM) => item.name)),
            ]}
          />
          <FilterSelect
            style={{ width: "100%", marginRight: 0 }}
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilterCountry(e.target.value)
            }
            value={filterCountry}
            data={[
              "Marketplace",
              ..._.uniq(_.map(countries, (item: ICountryM) => item.name)),
            ]}
          />
          <FilterSelect
            style={{ width: "100%", marginRight: 0 }}
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilterCountry(e.target.value)
            }
            value={filterCountry}
            data={[
              "Brands",
              ..._.uniq(_.map(countries, (item: ICountryM) => item.name)),
            ]}
          />
          <FilterSelect
            style={{ width: "100%", marginRight: 0 }}
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilterCountry(e.target.value)
            }
            value={filterCountry}
            data={[
              "Seller",
              ..._.uniq(_.map(countries, (item: ICountryM) => item.name)),
            ]}
          />
          <FilterSelect
            style={{ width: "100%", marginRight: 0 }}
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilterCountry(e.target.value)
            }
            value={filterCountry}
            data={[
              "Vendor",
              ..._.uniq(_.map(countries, (item: ICountryM) => item.name)),
            ]}
          />
        </div>
        <div style={{ padding: "29px 20px 40px" }}>
          <p
            style={{
              color: "#5A5A5A",
              marginBottom: "27px",
              fontWeight: 700,
            }}
          >
            Sale Price
          </p>
          <PriceSlider
            getAriaLabel={() => "Sale Price"}
            value={sliderValue}
            onChange={(event: Event, newValue: number | number[]) =>
              setSliderValue(newValue as number[])
            }
            //   valueLabelDisplay={salePriceFlag ? "on" : "off"}
            valueLabelFormat={sliderValueText}
            max={100}
            min={0}
            disableSwap
            //   disabled={!salePriceFlag}
          />
          <p
            style={{
              color: "#5A5A5A",
              marginBottom: "10px",
              marginTop: "40px",
              fontWeight: 700,
            }}
          >
            Stock
          </p>
          <div style={{ display: "flex", gap: "10px" }}>
            {["All", "In stock", "Out of stock"].map((item, i) => (
              <button
                onClick={() => setFilterStock(item)}
                key={i}
                style={{
                  background: "transparent",
                  border:
                    filterStock === item
                      ? "1px solid #F1692E"
                      : "1px solid #ACACAC",
                  color: filterStock === item ? "#F1692E" : "#ACACAC",
                  boxShadow: "none",
                  padding: "8px 14px",
                  fontSize: "14px",
                }}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
        <div
          style={{
            borderTop: "1px solid #E1E1E1",
            margin: "0 -10px",
            padding: "0 30px",
          }}
        >
          <button
            style={{
              boxShadow: "none",
              background: "#55718A",
              padding: "12px",
              width: "100%",
              marginTop: "22px",
              fontWeight: 700,
              fontSize: "16px",
              textTransform: "uppercase",
            }}
          >
            Apply
          </button>
          <button
            style={{
              boxShadow: "none",
              background: "transparent",
              padding: "0",
              width: "100%",
              marginTop: "17px",
              fontSize: "14px",
              textDecoration: "underline",
              color: "#5A5A5A",
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default Filter;
