import React, { memo, useCallback, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import * as JobController from "../../../../actions/job";
import { useMutation } from "react-query";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";

interface Props {
  job: JobController.IJob;
}

const JobsActivity: React.FC<Props> = ({ job }) => {
  const [checked, setChecked] = useState(job.active);
  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setModal(false);
    },
    [setModal]
  );

  const createEditMutation = useMutation(
    () => JobController.updateJob(job._id, { active: !checked }),
    {
      onSuccess: () => {
        setChecked((prev) => !prev);
      },
    }
  );

  const onActiveChange = useCallback(
    (e: React.MouseEvent) => {
      createEditMutation.mutate();
      onModalClose(e);
    },
    // eslint-disable-next-line
    [setModal, setChecked, createEditMutation]
  );

  const onToggle = useCallback(
    (e: React.MouseEvent) => {
      setModal(true);
      e.stopPropagation();
    },
    [setModal]
  );

  return (
    <>
      <td className="jobs-status">
        <AntSwitch onClick={onToggle} name="checkedC" checked={checked} />
        <span className="email-text ml1">Active</span>
      </td>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <div className="table-modal-form-cont p4">
            <div className="updateForm__container">
              <div className="vendor-mapping-modal-text">
                Are you sure you want to {checked ? "disable" : "enable"} <br />{" "}
                <strong>{job.name}</strong>?
              </div>
              <div className="table-modal-border"></div>
              <div className="table-modal-form-button-box width100 m0">
                <button
                  className="table-modal-form-button-cancel"
                  onClick={onModalClose}
                >
                  Cancel
                </button>
                <button
                  className="table-modal-form-button"
                  onClick={onActiveChange}
                >
                  SET
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default memo(JobsActivity);
