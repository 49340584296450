import React, { useCallback, useContext, useDeferredValue, useMemo, useState } from "react";
import "./password-management.css";
import { Modal } from "../../ui/modal/Modal";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { IPassword } from "../../../interfaces";
import { CountriesContext } from "../../context";
import { ICountryM } from "../country-management/CountryManagement";
import { useMutation } from "react-query";
import { queryClient } from "../../App";
import * as PasswordController from "../../../actions/passwordManagement";
import { useOTP } from "hooks";

interface Props {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  selectedPassword?: IPassword;
  rqKeyPasswordsInfo: string;
}

export const CreateEditPassword: React.FC<Props> = ({
  open,
  setOpen,
  selectedPassword,
  rqKeyPasswordsInfo,
}) => {
  const countries = useContext(CountriesContext) as ICountryM[];

  const [site, setSite] = useState<string>(selectedPassword?.site || "");
  const [label, setLabel] = useState<string>(selectedPassword?.label || "");
  const [country, setCountry] = useState<string>(
    selectedPassword?.country?.name || ""
  );
  const [userName, setUserName] = useState<string>(
    selectedPassword?.userName || ""
  );
  const [password, setPassword] = useState<string>(
    selectedPassword?.password || ""
  );
  const [otpToken, setOtpToken] = useState<string>(selectedPassword?.otpToken || "");
  const deferredOtpToken = useDeferredValue(otpToken);
  const { isValid } = useOTP(deferredOtpToken);

  const createCountriesArray = (array: ICountryM[]) => {
    return [
      "Select country",
      ..._.uniq(_.map(array, (item: ICountryM) => item.name)),
    ];
  };

  const data = useMemo(
    () => ({
      site,
      label,
      country: countries.find((x: ICountryM) => x.name === country)?._id,
      userName,
      password,
      otpToken,
    }),
    [site, country, userName, password, countries, otpToken, label]
  );

  const refetchPasswordConfig = {
    onSuccess: () => {
      queryClient.invalidateQueries(rqKeyPasswordsInfo);
      toast.success(
        `The password is successfully ${selectedPassword ? "updated" : "created"
        }!`
      );
    },
    onError: () => {
      toast.error("Something went wrong!");
    },
  };

  const editMutation = useMutation(
    () =>
      PasswordController.updatePasswordManagement(
        selectedPassword?._id as string,
        data as any
      ),
    refetchPasswordConfig
  );

  const createMutation = useMutation(
    () => PasswordController.createPasswordManagement(data as any),
    refetchPasswordConfig
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (selectedPassword) {
        await editMutation.mutateAsync();
      } else {
        await createMutation.mutateAsync();
      }

      setOpen(false);
    },
    [createMutation, setOpen, editMutation, selectedPassword]
  );

  const onModalClose = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setOpen(false);
    },
    [setOpen]
  );

  return (
    <Modal onModalClose={onModalClose} isOpened={open}>
      <>
        <div className="table-modal-cont">
          <div className="table-modal-title-box m0">
            <div className="mapping-modal-title-text">
              {!selectedPassword ? "New Password" : "Edit Password"}
            </div>
          </div>
          <form className="table-modal-form-cont" onSubmit={onSubmit}>
            <div className="table-modal-border"></div>
            <div className="tax-modal-cat mt2">
              <div className="mapping-modal-text">Site:</div>
              <input
                className="tax-modal-input"
                value={site}
                onChange={(e) => setSite(e.target.value)}
              />
            </div>
            <div className="tax-modal-cat mt2">
              <div className="mapping-modal-text">Label:</div>
              <input
                className="tax-modal-input"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </div>
            <div className="tax-modal-cat mt2 mb4">
              <div className="mapping-modal-text">Country:</div>
              <select
                className="tax-modal-input"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                {createCountriesArray(countries).map(
                  (country: string, index: number) => (
                    <option className="acompan-option" key={index}>
                      {country}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="tax-modal-cat mt2">
              <div className="mapping-modal-text">User’s Name:</div>
              <input
                className="tax-modal-input"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <HideShowInput
              value={password}
              setValue={setPassword}
              label={'Password:'}
            />
            <HideShowInput
              value={otpToken}
              setValue={setOtpToken}
              label={'OTP Token:'}
              valid={isValid}
            />
            <div className="table-modal-border"></div>
            <div className="table-modal-form-button-box width100">
              <button
                className="table-modal-form-button-cancel"
                onClick={onModalClose}
              >
                cancel
              </button>
              <button className="table-modal-form-button" type="submit">
                {!selectedPassword ? "Create" : "Edit"}
              </button>
            </div>
          </form>
        </div>
      </>
    </Modal>
  );
};

const HideShowInput: React.FC<{
  label: string,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>;
  valid?: boolean;
}> = ({ label, value, setValue, valid = true }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="tax-modal-cat mt2">
      <div className="mapping-modal-text">{label}</div>
      <div className="in-row align-center width100">
        <input
          style={({ ...(valid ? {} : { borderColor: 'red' }) })}
          className="tax-modal-input"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type={!show ? "password" : ""}
        />
        <img
          className="pointer ml2"
          width="25px"
          src={
            show
              ? "/icons/grey-view.svg"
              : "/icons/closed-eye.svg"
          }
          alt=""
          onClick={() => setShow(s => !s)}
        />
      </div>
    </div>
  );
};
