import React, { memo, useCallback, useMemo, useState } from "react";
import SelectComponent from "./SelectComponent";
import DiscountInfoComponent from "./DiscountInfoComponent";
import { PricingEvent, EventType, DiscountType } from "types";
import * as PricingController from "../../../../actions/pricingEvent";
import { useMutation, useQuery } from "react-query";
import {
	getPaginatedInventoryProducts,
	getSanitizedMarketplaces,
} from "../../../../actions/marketplace";
import Preloader from "../../../ui/preloader/Preloader";
import {
	IMarketplace,
	IProductGestao,
	SchemeRootObject,
} from "../../../../interfaces";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";
import queryString from "query-string";
import { getSchemes } from "../../../../actions/pim";
import { ProductResponse } from "../../tables/gestao/Gestao";

interface Props {
	// setEventId: (eventId: React.SetStateAction<string>) => void;
	rqKeyEvents: string;
	setAutomaticModal: (automaticModal: React.SetStateAction<boolean>) => void;
}

const AutomaticPricing: React.FC<Props> = ({
	rqKeyEvents,
	setAutomaticModal,
}) => {
	const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
	const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
	const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

	const [categoryValue, setCategoryValue] = useState<string>("All categories");
	const [platformValue, setPlatformValue] = useState<string>(
		"All platform accounts"
	);
	const [productsValue, setProductsValue] = useState<string>("All products");

	const productsConfig = useMemo(
		() => ({
			perPage: 50,
			page: 1,
			search: "",
		}),
		[]
	);

	const [discountInfoData, setDiscountInfoData] = useState<any>({
		isActive: false,
		name: "",
		description: "",
		discountType: DiscountType.PERCENTAGE,
		discountAmount: "",
		eventResult: {
			goal: "",
		},
		eventPeriod: {
			start: new Date(),
			end: new Date(),
		},
	});

	const { isLoading: loadSchemes, data: schemes } = useQuery("schemes", () =>
		getSchemes()
	);

	const { isLoading: loadPlatforms, data: platforms } = useQuery(
		"sanitizedMarkeplaces",
		() => getSanitizedMarketplaces()
	);

	const rqKeyProducts = useMemo(() => {
		return `/pricing/products?${queryString.stringify(productsConfig)}`;
	}, [productsConfig]);

	const { isLoading: loadProducts, data: products } = useQuery(
		rqKeyProducts,
		() =>
			getPaginatedInventoryProducts(productsConfig) as never as ProductResponse
	);

	const refetchEventsConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyEvents);
			toast.success(`The event is successfully "created"!`);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const createMutation = useMutation(
		(data: PricingEvent) => PricingController.createEvent(data),
		refetchEventsConfig
	);

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			const data = {
				...discountInfoData,
				categories: selectedCategories.length
					? selectedCategories
					: schemes?.map((scheme: SchemeRootObject) => scheme._id),
				platforms: selectedPlatforms.length
					? selectedPlatforms
					: platforms.map((platform: IMarketplace) => platform._id),
				products: selectedProducts.length
					? selectedProducts.map((p: string) => {
							const sku = products?.data.find(
								(product: IProductGestao) => product._id === p
							)?.sku;

							return { sku };
					  })
					: products?.all?.map((p) => ({ sku: p.sku })),
			};
			await createMutation.mutateAsync(data);

			setAutomaticModal(false);
		},
		[
			createMutation,
			discountInfoData,
			selectedCategories,
			selectedPlatforms,
			selectedProducts,
			products?.data,
			products?.all,
			schemes,
			platforms,
			setAutomaticModal,
		]
	);

	if (loadSchemes || loadPlatforms || loadProducts) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<>
			<div
				style={{ marginTop: "-60px", zIndex: "1200" }}
				className="product-header-cont"
			>
				<div className="in-row align-cener height100">
					<div
						className="product-exit-btn"
						onClick={() => setAutomaticModal(false)}
					>
						<img className="mr2" src="/icons/product-exit.svg" alt="" />
						Discard
					</div>
					<div className="in-row align-center ml2">
						<span className="product-header-text ml1">
							New Automatic Pricing
						</span>
					</div>
				</div>
				<button className="product-header-button">SAVE</button>
			</div>
			<div
				style={{
					background: "#E8EDF2",
					height: "max-content",
					minHeight: "93vh",
				}}
				className="product-cont m0 pt4"
			>
				<div className="new-pricing-column">
					<DiscountInfoComponent
						eventType={EventType.AUTOMATIC}
						discountInfoData={discountInfoData}
						setDiscountInfoData={setDiscountInfoData}
					/>
					<SelectComponent
						type={"categories"}
						specificArray={schemes || []}
						selectedItems={selectedCategories}
						setSelectedItems={setSelectedCategories}
						title={"Selected categories"}
						setValue={setCategoryValue}
						controlLabels={["All categories", "Specific category"]}
						controlValue={categoryValue}
					/>
					<SelectComponent
						type={"platforms"}
						specificArray={platforms || []}
						selectedItems={selectedPlatforms}
						setSelectedItems={setSelectedPlatforms}
						title={"Selected platform accounts"}
						setValue={setPlatformValue}
						controlLabels={[
							"All platform accounts",
							"Specific platform accounts",
						]}
						controlValue={platformValue}
					/>
					<SelectComponent
						type={"products"}
						specificArray={products?.data || []}
						selectedItems={selectedProducts}
						setSelectedItems={setSelectedProducts}
						title={"Discount applied to products"}
						setValue={setProductsValue}
						controlLabels={[
							"All products",
							"Products by Seller",
							"Specific products",
						]}
						controlValue={productsValue}
					/>
					<div className="in-row align-center justify-between mt4 width100">
						<button className="new-pricing-discard new-pricing-text">
							discard
						</button>
						<button
							className="new-pricing-save new-pricing-title-text"
							onClick={onSubmit}
						>
							save
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(AutomaticPricing);
