import React, { useState, useDeferredValue } from "react";
import IntelligenceNav from "../../../ui/int-nav/IntelligenceNav";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getSellerOveriewItems } from "../../../../actions/intelligence";
import { DateTime } from "luxon";
import { getImageURL } from "../../../../actions/vendor";

export default function IntelligenceOverview() {
    const [search, setSearch] = useState<any>("");
    const debounced = useDeferredValue(search);
    const overviewItems = useQuery(`/intelligence/overview?${debounced}`, () => getSellerOveriewItems(debounced));

    return (
        <div className="main-body">
            <div className="main-container">
                <div className="table-main-cont p0">
                    <IntelligenceNav />
                </div>
                <div className="table-main-cont">
                    <div className="in-row width100 align-center justify-center">
                        <div className="search-box">
                            <div className="search_form">
                                <div className="table-search-box">
                                    <div
                                        className="table-search-form"
                                    >
                                        <input
                                            className="table-search-input"
                                            placeholder="Search seller"
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <img
                                            className="table-search-image"
                                            src="/icons/search.svg"
                                            width="25px;"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "40px", height: "max-content" }} className="bi__cards_group">
                        {overviewItems.data?.map((item, index) => (
                            <Link
                                to={`/intelligence/products#seller=${item.seller}`}
                                style={{ width: "350px", height: "320px" }}
                                className="bi__card"
                            >
                                <img className="int-image" src={getImageURL(item.sellerId)} alt={item.seller} />
                                <div className="int-card-cont">
                                    <span className="int-text">{item.totalProducts} verified products in {item.totalMarketplaces} marketplaces</span>
                                    <div className="int-card-box">
                                        <div className="int-sub-image">
                                            <span style={{ textAlign: "right" }} className="int-blue-text">{item.totalBuyboxProducts}</span>
                                            <img className="ml2" src="/icons/int-medal.svg" alt="" />
                                        </div>
                                        <span style={{ textTransform: "uppercase", textAlign: "left" }} className="int-text">Buybox  <br />products</span>
                                    </div>
                                    <div className="int-card-box">
                                        <div className="int-sub-image">
                                            <span style={{ textAlign: "right" }} className="int-red-text">{item.totalPriceSuggestions}</span>
                                            <img className="ml2" src="/icons/int-alert.svg" alt="" />
                                        </div>
                                        <span style={{ textTransform: "uppercase", textAlign: "left" }} className="int-text">price <br />suggestions</span>
                                    </div>
                                    <span className="int-subtext">{item.updatedAt && `Last update: ${DateTime.fromISO(item.updatedAt).toFormat('dd/LL/yyyy - HH:mm')}`}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
