import React, { useContext } from "react";
import "./pim-translation.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CountriesContext } from "../../context";
import { ICountryM } from "../country-management/CountryManagement";

interface Props {
  search: string;
  setSearch: (search: string) => void;
  targetLanguage: string;
  setTargetLanguage: (targetLanguage: string) => void;
  saveTranslation: any;
}

export const TranslationFilter: React.FC<Props> = ({
  search,
  setTargetLanguage,
  targetLanguage,
  saveTranslation,
  setSearch,
}) => {
  const countries = useContext(CountriesContext) as any;

  return (
    <div className="trnl-filter-cont">
      <div className="trnl-lang-select">
        <div className="in-row align-center mr3">
          <img className="mr1" src="/icons/portugal.svg" alt="" width="20px;" />
          <div className="pim-language-text">Portuguese</div>
        </div>
        <div className="translation-arrow">
          <img src="/icons/translator-arrow.svg" alt="" width="20px;" />
        </div>
        <Select
          className="pim-count-select mwidth150"
          labelId="label"
          id="select"
          value={targetLanguage}
          onChange={(e: any) => setTargetLanguage(e.target.value)}
        >
          {countries
            .filter((x: ICountryM) => x.isLang)
            .map((item: any, index: number) => (
              <MenuItem value={item.langCode} key={index}>
                <div className="in-row align-center">
                  <img
                    className="mr1"
                    src={item.iconUrl}
                    alt=""
                    width="20px;"
                  />
                  <div className="pim-language-text">{item.language}</div>
                </div>
              </MenuItem>
            ))}
        </Select>
      </div>
      <div className="trnl-filter">
        <div className="table-search-box">
          <form
            className="table-search-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              className="table-search-input"
              placeholder="Filter items"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <img
              className="table-search-image"
              src="/icons/search.svg"
              width="25px;"
              alt=""
            />
          </form>
        </div>
        {/* <div className="">
					<select
						className="main-select-bi"
						// value={origin}
						// onChange={(e) => {
						//   e.preventDefault();
						//   setRatingStar("All stars");
						//   setCountry("All countries");
						//   setOrigin(e.target.value);
						// }}
					>
						{countries.map((origin: any, index: number) => (
							<option className="acompan-option" key={index}>
								{origin.language}
							</option>
						))}
					</select>
				</div>*/}
        <div>
          <button className="create-btn-lbox" onClick={saveTranslation}>
            salvar
          </button>
        </div>
      </div>
    </div>
  );
};
