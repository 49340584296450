import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 24,
  height: 11,
  padding: 0,
  display: "flex",
  borderRadius: 6,
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    color: "#fff",
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "light" ? "#00C714" : "#76ff03",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 11,
    height: 11,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#e0e0e0",
    boxSizing: "border-box",
  },
}));
