import React, { useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { forgotPassword } from "../../../actions/user";
import "./login.css";
import { toast } from "react-toastify";
import Contato from './Contato';
import Language from './Language';

const Esqueci: React.FC<any> = () => {
    const [email, setEmail] = useState("");
    const [textButton, setTextButton] = useState("SEND");
    const [classButton, setClassButton] = useState("");

    const handleEsqueci = async (e: any) => {
        setTextButton('Wait...');
        setClassButton('wait');
        e.preventDefault();

        try {
            const res = await forgotPassword({ email });
            toast.success(res.message, { autoClose: 5000 });
        } catch (error: any) {
            toast.error(error.response.data.message, { autoClose: 5000 });
        }

        setTextButton('Send');
        setClassButton('');
    };

    return (
        <div>
            <div className="s_main-login">
                {/* <div className="titel_box">
                    <div className="login-title-text">Zeoos</div>
                    <img src="/icons/logo.svg" alt="" />
                </div> */}
                <div className="login-box">
                    <div className="in-column">
                        <div className="titel_box">
                            {/* <div className="login-title-text"></div> */}
                            <img src="/icons/zeoos-logo.svg" width="160px;" alt="" />
                        </div>
                        <div className="in-column">
                            <form className="login-form-block" onSubmit={handleEsqueci}>
                                <div className="esqueci_text_box">
                                    <h3>REDEFINE PASSWORD</h3>
                                    <p>Fill in your email and click "Send".</p>
                                    <p className="pb4 pt2">You will receive a message with a link to reset your password by email.</p>
                                </div>
                                <div className="login-input">
                                    <img
                                        className="image-profile"
                                        src="/icons/carbon_email.svg"
                                        alt="settings"
                                        width="30px"
                                        height="30px"
                                    />
                                    <input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        className="email-field"
                                        name="Email"
                                        data-name="Email"
                                        id="Email"
                                        required
                                    />
                                </div>
                                <div className="button_box">
                                    <button onClick={handleEsqueci}
                                        disabled={(classButton === 'wait' ? true : false)}
                                        className={`log-in-login ${classButton}`}>
                                        {textButton}
                                    </button>
                                </div>
                                <div className="login-text-box">
                                    <div className="esqueci-text">
                                        <Link to="/login">LOGIN</Link>
                                    </div>
                                    {/* <Language /> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Contato />
            </div>
        </div>
    );
};

export default withRouter(Esqueci);
