import React, { memo, useCallback, useEffect, useState } from "react";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SpecificSearchModal from "./SpecificSearchModal";
import useVendors from "../../../../hooks/useVendors";
import { IConfigPagination } from "../../../../interfaces";
import Paginator from "../../../ui/pagination";

interface IPaginatedData {
	data: any;
	lastPage: number;
	page: number;
	perPage: number;
	total: number;
}

interface Props {
	type: string;
	specificArray: any;
	selectedItems: string[];
	setSelectedItems: (selectedItems: React.SetStateAction<string[]>) => void;
	title: string;
	setValue: (value: React.SetStateAction<string>) => void;
	controlLabels: string[];
	controlValue: string;
}

const SelectComponent: React.FC<Props> = ({
	type,
	specificArray,
	selectedItems,
	setSelectedItems,
	title,
	setValue,
	controlLabels,
	controlValue,
}) => {
	const { data } = useVendors();

	const [paginatedData, setPaginatedData] = useState<IPaginatedData>({
		data: selectedItems,
		perPage: 50,
		page: 1,
		total: selectedItems.length,
		lastPage: Math.ceil(selectedItems.length / 50),
	});

	useEffect(() => {
		setPaginatedData({
			...paginatedData,
			data: selectedItems,
			total: selectedItems.length,
			lastPage: Math.ceil(selectedItems.length / 50),
		});
		// eslint-disable-next-line
	}, [selectedItems]);

	const handleChangeRadio = (
		event: React.ChangeEvent<HTMLInputElement>,
		setValue: (value: React.SetStateAction<string>) => void,
		setArr: (arr: React.SetStateAction<string[]>) => void
	) => {
		if (
			(event.target as HTMLInputElement).value.includes("All") ||
			controlValue !== (event.target as HTMLInputElement).value
		) {
			setArr([]);
		}

		setValue((event.target as HTMLInputElement).value);
	};

	const onChangePage = useCallback(
		async (config: IConfigPagination) => {
			setPaginatedData((c: any) => {
				const getData = () => {
					if (config.page === 1) {
						return selectedItems.slice(0, config.perPage);
					}

					return config.perPage * config.page > c.total
						? selectedItems.slice(config.perPage * c.page)
						: selectedItems.slice(config.perPage, config.perPage * config.page);
				};

				return {
					...c,
					page: config.page,
					perPage: config.perPage,
					lastPage: Math.ceil(c.total / config.perPage),
					data: getData(),
				};
			});
		},
		// eslint-disable-next-line
		[setPaginatedData]
	);

	const renderItems = (x: any) => {
		switch (type) {
			case "categories":
				return (
					<span className="ml2 new-pricing-text">
						{specificArray?.find((scheme: any) => x === scheme._id)?.name}
					</span>
				);
			case "platforms":
				return (
					<div className="in-row align-center">
						<CountryFlag
							country={
								specificArray.find((platform: any) => x === platform._id)
									?.country
							}
							className="table-search-image"
							width="28px"
						/>
						<span className="ml2 new-pricing-text">
							{
								specificArray.find((platform: any) => x === platform._id)
									?.zeoosName
							}
						</span>
					</div>
				);
			case "products":
				return (
					<div className="in-row align-center">
						<img
							src={
								controlValue === "Products by Seller"
									? data.find((vendor: any) => vendor._id === x)?.vendorIcon
									: specificArray.find((product: any) => product._id === x)
											?.image
							}
							width="80px"
							height="60px"
							alt=""
						/>
						<span className="ml2 new-pricing-text">
							{controlValue === "Products by Seller"
								? data.find((vendor: any) => vendor._id === x)?.name
								: specificArray.find((product: any) => product._id === x)?.name}
						</span>
					</div>
				);

			default:
				break;
		}
	};

	return (
		<div className="new-pricing-cont">
			<div className="in-row align-center justify-between width100 mb4">
				<span className="new-pricing-title-text">{title}</span>
			</div>
			<RadioGroup
				aria-labelledby="demo-radio-buttons-group-label"
				defaultValue={controlLabels[0]}
				name="radio-buttons-group"
				onChange={(event) =>
					handleChangeRadio(event, setValue, setSelectedItems)
				}
			>
				{controlLabels.map((label: string) => (
					<FormControlLabel
						className="new-pricing-text"
						value={label}
						control={
							<Radio
								sx={{
									color: "#55718A",
									"&.Mui-checked": {
										color: "#55718A",
									},
								}}
							/>
						}
						label={label}
					/>
				))}
			</RadioGroup>
			{controlValue !== controlLabels[0] && (
				<SpecificSearchModal
					type={type}
					specificArray={
						controlValue !== "Products by Seller" ? specificArray : data
					}
					selectedItems={selectedItems}
					setSelectedItems={setSelectedItems}
				/>
			)}
			<div className="in-column justify-center align-start width100">
				{controlValue === "Specific products" && selectedItems.length ? (
					<div
						style={{ borderBottom: "1px solid #e1e1e1" }}
						className="in-row align-center justify-between pb1 width100"
					>
						<div className="new-pricing-text mt4 mb2">
							<b>{selectedItems.length} products added</b>
						</div>
						<div className="marketplace-pagination">
							<Paginator data={paginatedData} refetch={onChangePage as any} />
						</div>
					</div>
				) : (
					<></>
				)}
				{controlValue === "Products by Seller" && selectedItems.length ? (
					<div
						style={{ borderBottom: "1px solid #e1e1e1" }}
						className="in-row align-center justify-between pb1 width100"
					>
						<div className="new-pricing-text mt4 mb2">
							<b>Products added from {selectedItems.length} sellers</b>
						</div>
					</div>
				) : (
					<></>
				)}
				{paginatedData?.data.map((x: string) => (
					<div className="new-pricing-modal-cont justify-between m0 p1">
						{renderItems(x)}
						<img
							className="pointer"
							src="/icons/new-pricing-remove.svg"
							alt=""
							onClick={(e: React.MouseEvent) => {
								e.preventDefault();

								setSelectedItems(selectedItems.filter((z: string) => z !== x));
							}}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default memo(SelectComponent);
