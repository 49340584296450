import React, { useState } from "react";
import {
	getBillingsCSV,
	getPaginatedShipments,
} from "../../../actions/billings";
import Preloader from "../../ui/preloader/Preloader";
import "./billing.css";
import "./BillingTable";
import { BillingTable } from "./BillingTable";
import { SingleBilling } from "./single-billing/SingleBilling";
import { useQuery } from "react-query";
import useActiveVendors from "../../../hooks/useActiveVendors";
import FilterSelect from "../../ui/select/FilterSelect";
import { useDebouncedValue } from "@mantine/hooks";
import { IPaginatedRequest } from "../../../interfaces";
import queryString from "query-string";
import * as _ from "lodash";
import { Performance } from "./Performance";
import { Quantidade } from "./Quantidade";
import { toast } from "react-toastify";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../../../interfaces";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { withStyles } from "@material-ui/core/styles";
import PaymentBatchTable from "./payment-batch/PaymentBatchTable";
import TreasuryTable from "./treasury/TreasuryTable";
import BillingFilter from "./BillingFilter";
import PayoutsNav from "../../ui/payouts-nav/PayoutsNav";

const AntTabPanel = withStyles({
	root: {
		background: "#e8edf2 !important",
		padding: "0 !important",
	},
})(TabPanel);

export enum BillingStatus {
	ALL_STATUSES = "All statuses",
	OPEN = "Aberto",
	CLOSED = "Fechado",
}

export default function PayoutsPartners() {
	const [billingsWindow, setBillingsWindow] = useState<boolean>(false);
	const [billingsId, setBillingsId] = useState<any>([]);
	const [value, setValue] = React.useState('1');
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
		status: BillingStatus.ALL_STATUSES,
		vendorName: "All vendors",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const { isLoading: loadShipments, data: shipments } = useQuery(
		`/billings/paginatedShipments?${queryString.stringify(debouncedConfig)}`,
		() => getPaginatedShipments(debouncedConfig)
	);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const { isLoading, data } = useActiveVendors();

	if (loadShipments || isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<>
					{billingsWindow ? (
						<SingleBilling
							setBillingsWindow={setBillingsWindow}
							billingsId={billingsId}
						/>
					) : (
						<>
							<div className="table-main-cont p0">
								<PayoutsNav />
							</div>
							<div className="table-main-cont">
								{/* <div className="in-row justify-between align-center mt6">
									<div className="billing-modal-text bold">
										Fulfilment Billings
									</div>
									<div className="fw1 ft2 blue-color">View Drop Shipping</div>
								</div> */}
								{/* <div className="billing-graph-cont">
									<Performance data={shipments?.data} />
									<Quantidade data={shipments?.data} />
								</div> */}
								<div className="sb-filter-box">
									<div className="sb-filter-cont">
										<div className="sb-filter">
											{/* <div className="in-row align-center">
												<div className="filter-text">Filter by:</div>
												<FilterSelect
													onChangeHandler={(
														e: React.ChangeEvent<HTMLInputElement>
													) =>
														setCurrentConfig((conf: IPaginatedRequest) => ({
															...conf,
															status: e.target.value,
														}))
													}
													value={currentConfig?.status}
													options={Object.keys(BillingStatus).map((x) => ({
														label: _.capitalize(x.replace("_", " ")),
														value: x,
													}))}
												/>
												<RoleRequired role={Role.admin}>
													<FilterSelect
														onChangeHandler={(
															e: React.ChangeEvent<HTMLInputElement>
														) =>
															setCurrentConfig((conf: IPaginatedRequest) => ({
																...conf,
																vendorName:
																	data.find((x: any) => x.name === e.target.value)
																		?.id || "All vendors",
															}))
														}
														value={
															data.find(
																(x: any) => x.id === currentConfig.vendorName
															)?.name
														}
														data={[
															"All vendors",
															..._.uniq(_.map(data, (item: any) => item.name)),
														]}
													/>
												</RoleRequired>
											</div> */}
											<div className="width100 in-row align-center justify-between">
												<TabContext value={value}>
													<TabList onChange={handleChange} aria-label="lab API tabs example">
														<Tab className="seller-product-tab bold" label="Treasury" value="1" />
														<Tab className="seller-product-tab bold" label="Payment Batches" value="2" />
													</TabList>
												</TabContext>
												<div className="mr2">
													<BillingFilter />
												</div>
											</div>
											<div
												className="export-btn pointer"
												onClick={() => {
													getBillingsCSV().catch(console.error);
													toast.success(
														"You will receive an email with the file"
													);
												}}
											>
												Export
											</div>
										</div>
									</div>
								</div>
								<TabContext value={value}>
									<AntTabPanel style={{ background: "#e8edf2 !important" }} value="1">
										<TreasuryTable />
									</AntTabPanel>
									<AntTabPanel value="2">
										<PaymentBatchTable />
									</AntTabPanel>
								</TabContext>
							</div>
						</>
					)}
				</>
			</div>
		</div>
	);
}
