import React from "react";
import { NavLink } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useQuery } from "react-query";
import { getOfferImportReports } from "../../../../../actions/user";
import { OfferImportReport } from "types/seller";
import { DateTime } from "luxon";
import { round, uniq } from "lodash";
import { Doughnut } from "react-chartjs-2";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#F1692E' : '#308fe8',
    },
}));


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: '#fff',
        maxWidth: 200,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        background: "#454545",
    },
}));

export default function OfferBatchLogs() {
    const { data } = useQuery("offerImportReports", getOfferImportReports, { refetchOnWindowFocus: false });
    return (
        <>
            <div className="main-body">
                <div className="main-container">
                    <div className="table-main-cont pb4">
                        <NavLink to="/offers/all"
                            className="single-billing-nav pointer"
                        >
                            <img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
                            <div style={{ color: "#55718a" }} className="table-subtext bold pt1 pl2">back to Offers</div>
                        </NavLink>
                        <div style={{ paddingLeft: "0", paddingRight: "0" }} className="table-title-cont ">
                            <div className="mplc-filter-cont mt1">
                                <div className="gestao-select-cont justify-between">
                                    <span className="pim-language-text pl4">Offer Import Reports</span>
                                </div>
                            </div>
                        </div>
                        {data?.map(item => (
                          <ReportAccordion key={item._id} {...item} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

const ReportAccordion: React.FC<OfferImportReport> = (report) => {

  const getCountryCounter = (platformCounter: any) => {
    const countries = Object.keys(platformCounter||{}).map((zn: string) => zn.slice(-2));    
    return uniq(countries).length;
  }

  const percentByChannel = (marketplace: any) => {
    let res = { competitive:0, nonCompetitive: 0 };
    const total = marketplace.competitive + marketplace.nonCompetitive;
    if (total) {
      res = {
        competitive: round(marketplace.competitive / total * 100, 2),
        nonCompetitive: round(marketplace.nonCompetitive / total * 100, 2)
      }
    }
    return res;
  }

  const labels = ["competitive offers ", "non-competitive", "buybox winner", "buybox looser"];
  const backgroundColor =  ["#ffa31a", "#e8e8e8", "#299c00", "#e8e8e8"];

  return (
    <>
      <Accordion className="pl4 mb4">
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="width100"
        >
            <div className="width100 in-row align-center justify-between">
                <span className="pim-language-text">
                  #{report._id.slice(-6)} -
                  <span style={{ fontWeight: "normal", paddingLeft: 3 }}>
                    {report.offers} offers and {report.errorMessages.length} errors were found.
                  </span>
                </span>
                <div className="in-row alin-center">
                    <span className="pl2 email-text">
                      {DateTime.fromISO(report.created as any).toFormat("LLLL dd, yyyy - HH:mm")}
                    </span>
                </div>
            </div>
        </AccordionSummary>
        <div style={{ borderBottom: "1px solid #E1E1E1" }} />
        <AccordionDetails className="width100">
            <div className="in-column width100 align-start justify-start p4">
                <div className="width100" style={{ borderBottom: "1px solid #E1E1E1" }} />
                <div className="in-row width100 align-start justify-between">
                    <div className="width40 in-column align-start p4">
                        <span className="pim-language-text in-row align-center">
                          <p className="mr1">{report.offers} offers in Inventory</p>
                            <HtmlTooltip className="pointer" title="Competitive offers are flagged with the orange badge. The best competitive offers are also marked with a green badge." placement="bottom-start">
                                <img className="pointer" src="/icons/tooltip-black.svg" alt="" />
                            </HtmlTooltip>
                        </span>
                        <div className="in-row pt4 pb4 width100 align-center justify-between">
                          <Doughnut
                            // @ts-ignore
                            // type="doughnut"
                            data={{
                              labels,
                              datasets: [
                                {
                                  data: [report.competitive, report.nonCompetitive],
                                  backgroundColor,
                                  spacing: 1, // add spacing between parts,
                                  label: 'rankingzeoos',
                                },
                                // {
                                //   data: [null,null,report.buyboxWinner, report.offers-report.buyboxWinner],
                                //   backgroundColor,
                                //   spacing: 1, // add spacing between parts
                                //   label: 'buybox',                                                                          
                                // }
                              ],
                            }}
                            height={150}
                            width={150}
                            options={{
                              responsive: false,
                              maintainAspectRatio: false,
                              indexAxis: "y",
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                              // @ts-ignore
                              borderRadius: 4, // add rounded corners,
                            }}
                          />
                          <ul className="in-column width100 pl4 align-start">
                            <li className="email-text pb2">
                              <img width="16px" className="mr1" src="/icons/ranking-modal-yellow.svg" alt="" /> 
                              <b className="mr1">{report.competitive}</b> {labels[0]}
                            </li>
                            {/* <li className="email-text pb2">
                              <img width="16px" className="mr1" src="/icons/inv-modal1-rank.svg" alt="" />
                              <b className="mr1">{report.buyboxWinner}</b> {labels[2]}
                            </li> */}
                            <li className="email-text pb2">
                              <img width="16px" className="mr1" src="/icons/offer-grey-dott.svg" alt="" />
                              <b className="mr1">{report.nonCompetitive}</b> {labels[1]}
                            </li>
                          </ul>
                        </div>
                        {report.errorMessages.length > 0 &&
                        <>
                          <span className="email-text bold">Errors when creating offers</span>
                          <span className="email-text pb2">Errors found when importing offers can be corrected and imported again.</span>
                          <NavLink to="/offer-import" className="pc-modal-spreadsheet-button pointer mt2" onClick={()=>alert(report.errorMessages)}>
                              <div style={{ color: "#55718a" }} className="pc-modal-spreadsheet-button-text initial">Export errors</div>
                          </NavLink>
                        </>
                        }                        
                    </div>
                    <div className="width60 in-column align-start p4">
                        <span className="pim-language-text">
                          {report.offers} offers 
                          in {Object.keys(report.platformCounter||{}).length} sales channels 
                          in {getCountryCounter(report.platformCounter)} countries.
                        </span>
                        <div className="batch-integrated-cont justify-between">
                          {Object.keys(report.platformCounter).map((zn: string, index: number) => {
                            const percents = percentByChannel(report.platformCounter[zn]);
                            return (
                              <div style={{ alignItems: "stretch", flex: "0 1 40%" }} key={index} className="batch-integrated-box in-column">
                                  <span className="pim-language-text pb1">
                                    {zn} - <span style={{ fontWeight: "normal" }}>{report.platformCounter[zn].offers} offers</span>
                                  </span>
                                  <Box sx={{ flexGrow: 1 }}>
                                      <BorderLinearProgress variant="determinate" value={percents.competitive} />
                                  </Box>
                                  <span style={{ color: "#F1692E" }} className="email-text mt1 mb1">
                                    <b>{percents.competitive}%</b>&#183; - {report.platformCounter[zn].competitive||0} competitive
                                  </span>
                                  <span className="email-text">
                                    <b>{percents.nonCompetitive}%</b>&#183; - {report.platformCounter[zn].nonCompetitive||0} non-competitive
                                  </span>
                              </div>
                            )
                          })}
                        </div>
                    </div>
                </div>
            </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
