import { ASSETS_URL } from "../constants";

export function getAsset(path: string) {
  return `${ASSETS_URL}/${path === ".svg" ? "undefined.svg" : path}`;
}

export function getImagePath(path: string) {
  return `${ASSETS_URL}/${path}.svg`;
}

const formatter = new Intl.NumberFormat("en-US", { notation: "compact" });

export function formatNumber(num: any) {
  if (isNaN(num)) {
    return;
  }

  return formatter.format(num);
}



export function createImagesObject(images: string[]) {

const imageKeys = [
  "image1",
  "image2",
  "image3",
  "image4",
  "image5",
  "image6",
  "image7",
  "image8",
  "image9",
];
  const obj: any = {};

  if (images[0]) {
    obj.main_image = images[0];
  }

  for (let i = 0; i < imageKeys.length; i++) {
    if (images[i]) {
      obj[imageKeys[i]] = images[i];
    }
  }

  return obj;
}