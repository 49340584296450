import React, { memo, useCallback, useMemo, useState } from "react";
import "./jobs-panel.css";
import { Modal } from "../../../ui/modal/Modal";
import * as JobController from "../../../../actions/job";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../../App";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";

interface Props {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  job?: JobController.IJob;
}

interface ICustomField {
  field: any;
  values: any;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const CreateEditJob: React.FC<Props> = ({ open, setOpen, job }) => {
  const {
    isLoading,
    error,
    data: jobTypes,
  } = useQuery("jobTypes", () => JobController.getJobTypes());
  const [name, setName] = useState(job?.name || "");
  const [type, setType] = useState(
    ((job?.type as JobController.IJobType)?._id as string) || ""
  );
  const [description, setDescription] = useState(job?.description || "");
  const [frequency, setFrequency] = useState(job?.frequency || "");
  const [active, setActive] = useState(
    typeof job?.active !== "boolean" ? true : job.active
  );
  const modalTitle = !job ? "New Job" : "Edit Job";
  const buttonLabel = !job ? "Create" : "Save";
  const [input, setInput] = useState(!job ? {} : job.input);

  const onModalClose = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setOpen(false);
    },
    [setOpen]
  );

  const refetchJobsConfig = {
    onSuccess: () => {
      queryClient.invalidateQueries("jobs");
    },
  };
  const deleteMutation = useMutation(
    () => JobController.deleteJob(job?._id as string),
    refetchJobsConfig
  );
  const onDelete = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();

      await deleteMutation.mutateAsync();
      setOpen(false);
    },
    [setOpen, deleteMutation]
  );

  const data = useMemo(
    () => ({
      name,
      type,
      description,
      frequency,
      active,
      input,
    }),
    [name, type, description, frequency, active, input]
  );

  const editMutation = useMutation(
    () => JobController.updateJob(job?._id as string, data),
    refetchJobsConfig
  );
  const createMutation = useMutation(
    () => JobController.createJob(data),
    refetchJobsConfig
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (job) {
        await editMutation.mutateAsync();
      } else {
        await createMutation.mutateAsync();
      }

      setOpen(false);
    },
    [createMutation, setOpen, editMutation, job]
  );

  const fields = useMemo(
    () => jobTypes?.filter((jt) => jt._id === type)[0]?.fields,
    [jobTypes, type]
  );

  const onUpdateField = (e: React.FormEvent<HTMLInputElement>) => {
    let value: any = e.currentTarget.value;
    if (e.currentTarget.type === "checkbox") {
      value = e.currentTarget.checked;
    }
    setInput({ ...input, [e.currentTarget.name]: value });
  };

  if (isLoading || error) {
    return <></>;
  }

  return (
    <Modal onModalClose={onModalClose} isOpened={open}>
      <div className="mapping-modal-cont">
        <div className="table-modal-title-box m0">
          <div className="mapping-modal-title-text">{modalTitle}</div>
          {job && (
            <div className="in-row align-center pointer">
              <img
                src="/icons/admin-trash-can.svg"
                alt=""
                className="mr1"
                width="20px"
              />
              <div className="mapping-modal-text p0" onClick={onDelete}>
                delete
              </div>
            </div>
          )}
        </div>
        <form className="table-modal-form-cont" onSubmit={onSubmit}>
          <div className="table-modal-border"></div>
          <div className="tax-modal-cat">
            <div className="mapping-modal-text">Job Name:</div>
            <input
              className="tax-modal-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="tax-modal-cat mt2">
            <div className="width100 in-row justify-between">
              <div className="mapping-modal-text">Job Description:</div>
              <div className="mapping-modal-subtext">300 characters</div>
            </div>
            <textarea
              className="tax-modal-input height100px"
              maxLength={300}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {jobTypes && (
            <div className="tax-modal-cat mt2">
              <div className="mapping-modal-text">Type:</div>
              <select
                className="main-select-bi width100"
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                {[{ _id: 1, name: "All Types" } as any, ...jobTypes].map(
                  (type) => (
                    <option
                      className="acompan-option"
                      key={type._id}
                      value={type._id}
                    >
                      {type.name}
                    </option>
                  )
                )}
              </select>
            </div>
          )}
          {fields && (
            <>
              <div className="tax-modal-cat mt2">
                <div className="mapping-modal-text">Params:</div>
              </div>
              {fields.map((field: any) => (
                <CustomField
                  field={field}
                  values={input}
                  onChange={onUpdateField}
                />
              ))}
            </>
          )}
          <div className="tax-modal-columns">
            <div className="tax-modal-cat width100 mr4">
              <div className="mapping-modal-text">Frequency:</div>
              <input
                className="tax-modal-input"
                placeholder="* * * * * *"
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
                required
              />
            </div>
            <div className="tax-modal-cat width50">
              <div className="mapping-modal-text">Status:</div>
              <div className="in-row align-center">
                <AntSwitch
                  name="checkedC"
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
                <span className="email-text ml2">Active</span>
              </div>
            </div>
          </div>
          {/* <button className="mt4 bright-blue-button">CRON JOB FILE</button> */}
          <div className="table-modal-border"></div>
          <div className="table-modal-form-button-box width100">
            <button
              className="table-modal-form-button-cancel"
              onClick={onModalClose}
            >
              Cancel
            </button>
            <button className="table-modal-form-button" type="submit">
              {buttonLabel}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const CustomField: React.FC<ICustomField> = ({ field, values, onChange }) => {
  const name = Object.keys(field)[0];
  const label = field[name].label;
  const type = field[name].type;
  const value = values ? values[name] : "";

  return (
    <div className="tax-modal-columns mt1">
      <div className="tax-modal-cat width50 mr4">
        <strong>{name}:</strong>
      </div>

      <div className="tax-modal-cat width50 mr4">
        <input
          type={type}
          className="tax-modal-input"
          title={label}
          name={name}
          value={value}
          placeholder={label}
          checked={type === "checkbox" && value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default memo(CreateEditJob);
