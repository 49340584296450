import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";
import { useMutation, useQuery } from "react-query";
import * as Intelligence from "../../../../actions/intelligence";
import Preloader from "../../../ui/preloader/Preloader";
import { DateTime } from "luxon";
import { getImageURL } from "../../../../actions/vendor";
import { MarketplaceImage } from "../../billing/single-billing/SingleBillingTable";
import { getAdminNames } from "../../../../actions/user";
import { Robot, ScheduleItem } from "types/intelligence";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";

export default function IntelligenceSettingsSingle() {
    const params = useParams<{ id: string; }>();
    const key = useMemo(() => `/intelligence/robot/${params.id}`, [params.id]);
    const robot = useQuery(key, () => Intelligence.getRobot(params.id));
    const managers = useQuery('/admin-names', getAdminNames);

    const [data, setData] = useState(robot.data as Robot);

    useEffect(() => {
        setData(robot.data!);
    }, [robot.data]);

    const handleScheduleItemEdit = useCallback(<T extends keyof ScheduleItem,>(index: number, property: T, value: ScheduleItem[T]) => {
        setData(prev => ({
            ...prev,
            scheduleItems: prev!.scheduleItems.map((x, i) => {
                if (index === i) {
                    x[property] = value;
                }

                return x;
            })
        }));
    }, []);

    const handleDataEdit = useCallback(<T extends keyof Robot,>(property: T, value: Robot[T]) => {
        setData(prev => ({
            ...prev,
            [property]: value
        }));
    }, []);

    const refetchPartnerConfig = {
        onSuccess: () => {
            queryClient.invalidateQueries(key);
            toast.success(`The robot was successfully updated!`);
        },
        onError: () => {
            toast.error("Something went wrong!");
        },
    };

    const editMutation = useMutation(
        (data: Robot) => Intelligence.updateRobot(data.id, data),
        refetchPartnerConfig
    );

    const onSubmit = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();
            await editMutation.mutateAsync(data);
        },
        [data, editMutation]
    );

    if (robot.isLoading) {
        return <Preloader />;
    }

    if (!data) {
        return <>Not Found</>;
    }

    return (
        <div className="main-body">
            <div className="main-container">
                <div className="table-main-cont">
                    <div
                        className="single-billing-nav pointer"
                    >
                        <img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
                        <NavLink to="/intelligence/settings" className="table-subtext underline pl2">back to Robot List</NavLink>
                    </div>
                    <div className="sb-filter-box">
                        <div className="sb-filter-cont in-column justify-center p2">
                            <div className="sb-filter">
                                <div className="mb4 in-row align-center">
                                    <div style={{ width: "100px", height: "75px" }} className="int-set-match-logo">
                                        <img width="100px" height="75px" className="pb1" src={getImageURL(data.sellerId)} alt="" />
                                    </div>
                                    <div className="ml4 int-text in-column text-aligns align-start justify-start">
                                        <div className="in-row align-center">
                                            <b style={{ fontSize: "16px" }}>Round Robot ID {data.id}&nbsp;</b><i>- Created on {DateTime.fromISO(data.createdAt!).toFormat('dd/LL/yyyy')} by {data.creator || 'Unknown'}</i>
                                        </div>
                                        <br />
                                        {data.seller}
                                        <br /><br />
                                        {data.totalProductsOnMarketplace} products on {data.zeoosName}
                                    </div>
                                </div>
                            </div>
                            <div className="table-modal-border m0"></div>
                            <div className="in-row width100 align-center justify-between pt1">
                                <span className="int-text ml2">RB-{data.id} - <b> &nbsp;{data.type}</b></span>
                                <button onClick={onSubmit} style={{ fontWeight: "normal" }} className="int-set-modal-done">Save & Close</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-main-title-cont in-column p2 mb4">
                        <div className="sb-filter align-start mt2">
                            <div className="mb4 in-row align-center">
                                <MarketplaceImage zeoosName={data.zeoosName} width="100px" height="100px" className="pb1" alt="" />
                                <div className="ml4 int-text in-column text-aligns align-start justify-start">
                                    <div className="in-row align-center">
                                        <img src="/icons/green-int.svg" alt="" />
                                        <b className="ml2" style={{ fontSize: "16px" }}>25 out of 30 selected products</b>
                                    </div>
                                    <br />
                                    <div className="in-row align-center">
                                        <img src="/icons/int-set-list-modal-red-close.svg" alt="" />
                                        <span className="ml2">{data.seller}</span>
                                    </div>
                                    <br />
                                    <div className="int-nav-link pointer m0">Select products</div>
                                    {/* Modal done in IntelligenceSettingActions (modal1) */}
                                </div>
                            </div>
                            <div className="in-row align-center justify-start">
                                <span className="mr2 int-text">Manager:</span>
                                <select
                                    id="compa-channels"
                                    name="compa-channels"
                                    required
                                    className="compa-select mr4"
                                    onChange={e => handleDataEdit('manager', e.target.value)}
                                >
                                    <option className="compa-option" value="">
                                        Select
                                    </option>

                                    {managers.data?.map(name => (
                                        <option className="compa-option" key={name} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="table-modal-border m0"></div>
                        <div className="sb-filter">
                            <div className="mb4 in-row align-center">
                                <div className="pt4 int-text in-column text-aligns align-start justify-start">
                                    <b className="uppercase-text" style={{ fontSize: "16px" }}>frequency</b>

                                    {data.scheduleItems.map((item, index) => (
                                        <div key={item.id} className="mb4 mt4 in-row align-center justify-start">
                                            {/* <div className="in-row align-center justify-start">
                                            <span className="int-text">Repeat every:</span>
                                            <select
                                                id="compa-channels"
                                                name="compa-channels"
                                                required
                                                className="compa-select mr4"
                                            >
                                                <option className="compa-option" value="">
                                                    Week
                                                </option>
                                            </select>
                                        </div> */}
                                            <div className="in-row align-center ml4 mr4 justify-start">
                                                <span className="int-text">Week Days:</span>
                                                <RenderDays
                                                    frequency={item.frequency}
                                                    setFrequency={value => handleScheduleItemEdit(index, 'frequency', value)}
                                                />
                                            </div>
                                            <div className="in-row align-center justify-start">
                                                <span className="int-text">Start:</span>
                                                <input
                                                    className="compa-select mr4"
                                                    type='time'
                                                    value={item.startsAt}
                                                    onChange={e => handleScheduleItemEdit(index, 'startsAt', e.target.value)}
                                                />
                                            </div>

                                            <div className="in-row width100 mt2 mb2 align-center justify-start">
                                                <span className="int-text ">VPS:</span>
                                                <select style={{ width: "140px" }}
                                                    className="int-product-select ml4"
                                                    onChange={e => handleScheduleItemEdit(index, 'vps', e.target.value)}
                                                    value={item.vps}
                                                >
                                                    <option className="compa-option" value="">
                                                        Select
                                                    </option>

                                                    {[1, 2].map(x => (
                                                        <option key={x} className="compa-option" value={x}>
                                                            VPS {x}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* <div className="in-row align-center justify-start">
                                            <span className="int-text">End:</span>
                                            <select
                                                id="compa-channels"
                                                name="compa-channels"
                                                required
                                                className="compa-select mr4"
                                            >
                                                <option className="compa-option" value="">
                                                    10:00 AM
                                                </option>
                                            </select>
                                        </div> */}
                                        </div>
                                    ))}
                                    <div onClick={() => setData(p => ({ ...p, scheduleItems: [...p.scheduleItems, { startsAt: '12:00', frequency: '1', vps: 1 } as any] }))} className="int-nav-link pointer m0">Add frequency</div>
                                </div>
                            </div>
                        </div>
                        <div className="table-modal-border m0"></div>
                        <div className="sb-filter mt2">
                            <Accordion className="width100 border0 no-shadow mo p0">
                                <AccordionSummary
                                    className="p0 int-text bold blue-text"
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className="in-row align-center">
                                        <img className="mr2" src="/icons/int-arrow.svg" alt="" />
                                        Other robot parameters
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className="width100 p0">
                                    <div className="in-column align-start justify-center">
                                        <div className="in-row width100 mt2 mb2 align-center justify-start">
                                            <span className="int-text mwidth100">ProcessID:</span>
                                            <input readOnly style={{ width: "480px" }} className="int-product-select ml4" placeholder="<ProcessID>" value={data.id} />
                                        </div>
                                        <div className="in-row width100 mt2 mb2 align-center justify-start">
                                            <span className="int-text mwidth100">Mktplace:</span>
                                            <input readOnly style={{ width: "480px" }} className="int-product-select ml4" placeholder="CDiscount FR" value={data.zeoosName} />
                                        </div>
                                        {/* <div className="in-row width100 mt2 mb2 align-center justify-start">
                                            <span className="int-text mwidth100">RootURL:</span>
                                            <input style={{ width: "480px" }} className="int-product-select ml4" placeholder="www.cdiscount.com.fr" />
                                        </div> */}
                                        <div className="in-rowwidth100 mt2 mb2 align-center justify-start">
                                            <span className="int-text mwidth100">Setup:</span>
                                            <div className="ml4">
                                                <AntSwitch checked={data.setup} onChange={e => handleDataEdit('setup', e.target.checked)} />
                                            </div>
                                        </div>
                                        <div className="in-row width100 mt2 mb2 align-center justify-start">
                                            <span className="int-text mwidth100">OnlyBuyBox:</span>
                                            <div className="ml4">
                                                <AntSwitch checked={data.onlyBuybox} onChange={e => handleDataEdit('onlyBuybox', e.target.checked)} />
                                            </div>
                                        </div>

                                        {data.estimatedTime && (
                                            <div className="in-row width100 mt2 mb2 align-center justify-start">
                                                <span className="int-text mwidth100">Estimate time:</span>
                                                <b style={{ color: "#5A5A5A !important" }} className="ml4">{data.estimatedTime}</b>
                                            </div>
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const DAYS = [
    { label: 's', value: '1' },
    { label: 'm', value: '2' },
    { label: 't', value: '3' },
    { label: 'w', value: '4' },
    { label: 't', value: '5' },
    { label: 'f', value: '6' },
    { label: 's', value: '7' },
] as const;

const RenderDays: React.FC<{ frequency: string; setFrequency: (f: string) => void; }> = ({ frequency, setFrequency }) => {
    const values = useMemo(() => frequency.split(','), [frequency]);

    return (
        <div className="ml4 in-row align-center justify-start">
            {DAYS.map(d => {
                const isActive = values.includes(d.value);
                const className = isActive ? `int-set-single-day-active` : `int-set-single-day-inactive`;

                return (
                    <div
                        key={d.value}
                        className={className}
                        onClick={e => setFrequency((isActive ? values.filter(x => x !== d.value) : [...values, d.value]).join(','))}
                    >
                        {d.label}
                    </div>

                );
            }
            )}
        </div>
    );
};
