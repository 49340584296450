import { CSSProperties } from "react";
import "./main-filter-select.css";

interface Props {
  onChangeHandler: (e: any) => void;
  data?: string[];
  value: any;
  options?: { label: string; value: string }[];
  style?: CSSProperties;
}

const FilterSelect: React.FC<Props> = ({
  onChangeHandler,
  data,
  value,
  options,
  style,
}) => {
  return (
    <div className="main-filter-select-container">
      <select
        style={style}
        className="main-filter-select"
        onChange={onChangeHandler}
        value={value}
      >
        {data?.map((item: string, index: number) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
        {options?.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterSelect;
