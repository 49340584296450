import { useState, useMemo, useCallback } from "react";
import useUser from "../../../../hooks/useUser";
import "./card-buttons.css";
import { useHistory } from "react-router-dom";
import { Role } from "../../../../interfaces";
import { useQuery } from "react-query";
import { getProductCounts } from "../../../../actions/user";
import YouTube, { YouTubeProps } from "react-youtube";
import { Modal } from "../../../ui/modal/Modal";
import { CardBtnComponent } from "../../tables/gestao/CardButtons";

type Props = {
  isMy: boolean;
  setCurrentConfig: any;
};

const CardButtons: React.FC<Props> = ({ isMy, setCurrentConfig }) => {
  const { user } = useUser();
  const [modal, setModal] = useState<boolean>(false);
  const prefix = useMemo(() => (isMy ? "/my" : ""), [isMy]);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: "400",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const counts = useQuery(
    `catalog/counts&isMy=${isMy}`,
    () => getProductCounts(!isMy),
    { refetchOnWindowFocus: false }
  );

  const history = useHistory();
  const handleNavigate = useCallback((tab: string) => () => {
    setCurrentConfig({ page: '1', perPage: '10', type: tab });
    history.push(`${prefix}/catalog/${tab}${window.location.search}`);
  }, [prefix, history]);

  return (
    <div className="card-btns-cont">
      <CardBtnComponent
        label={"Integrated products"}
        productQty={counts.data?.integrated!}
        // percentage={"70,0"}
        // dynamics={"+5,4"}
        onClick={handleNavigate('integrated')}
        tab="integrated"
        discount="70,0%"
        discountLastWeek="+5,4%"
      />

      <CardBtnComponent
        label={"Non-integrated products"}
        productQty={counts.data?.nonIntegrated!}
        // percentage={"17,2"}
        // dynamics={"-2,4"}
        onClick={handleNavigate('non-integrated')}
        tab="non-integrated"
        discount="17,2%"
        discountLastWeek="-2,4%"
      />

      {(isMy || user.role === Role.admin) && (
        <CardBtnComponent
          label={"Unregistered products"}
          productQty={counts.data?.unregistered!}
          // percentage={"12,8"}
          // dynamics={"-2,4"}
          onClick={handleNavigate('unregistered')}
          tab="unregistered"
          discount="12,8%"
          discountLastWeek="-2,4%"
        />
      )}
      <div
        onClick={() => {
          setModal(true);
        }}
        className="card-btn-cont"
      >
        <div className="card-btn-watch-title">How Catalog module works</div>
        <div className="card-btn-watch-btn">
          <img src="/icons/ph_play-fill.svg" alt="" />
          <div className="card-btn-watch">watch</div>
        </div>
      </div>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <YouTube videoId="gIFglh35pho" opts={opts} onReady={onPlayerReady} />
        </Modal>
      )}
    </div>
  );
};

export default CardButtons;
