import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App, { queryClient } from "./components/App";
import Preloader from "./components/ui/preloader/Preloader";
import "./styles/index.css";
import { QueryClientProvider } from "react-query";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <Suspense
    fallback={
      <div className="center-loader">
        <Preloader />
      </div>
    }
  >
    <QueryClientProvider contextSharing={true} client={queryClient}>
      <App />
    </QueryClientProvider>
  </Suspense>
  // </React.StrictMode>
);
