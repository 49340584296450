import React, { memo, useCallback } from "react";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";
import DatePicker from "react-datepicker";
import { DiscountType, EventType, PricingEvent } from "types";

interface Props {
	eventType: string;
	discountInfoData: any;
	setDiscountInfoData?: any;
}

const DiscountInfoComponent: React.FC<Props> = ({
	eventType,
	discountInfoData,
	setDiscountInfoData,
}) => {
	const handleChange = useCallback(
		(key: string, value: any) =>
			setDiscountInfoData((prev: any) => ({ ...prev, [key]: value })),
		[setDiscountInfoData]
	);

	return (
		<div className="new-pricing-cont">
			<div className="in-row align-center justify-between width100 mb4">
				<span className="new-pricing-title-text">Discount information</span>
				<div className="new-pricing-text width13 in-row align-center justify-start">
					<AntSwitch
						className="mr2"
						checked={discountInfoData.isActive}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleChange("isActive", e.target.checked)
						}
						name="checkedC"
						required
					/>
					<span className="email-textm">
						{discountInfoData.isActive ? "Active" : "Inactive"}
					</span>
				</div>
			</div>
			<div className="new-pricing-box">
				<span className="new-pricing-text mwidth120">Name:</span>
				<input
					style={{ background: "#f2f2f2" }}
					className="tax-modal-input"
					value={discountInfoData.name}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange("name", e.target.value)
					}
					required
				/>
			</div>
			<div className="new-pricing-box align-start">
				<span className="new-pricing-text mwidth120">Description:</span>
				<input
					style={{ background: "#f2f2f2", minHeight: "100px" }}
					className="tax-modal-input"
					value={discountInfoData.description}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange("description", e.target.value)
					}
					required
				/>
			</div>
			{eventType === EventType.AUTOMATIC && (
				<div className="in-row align-center justify-between width100">
					<div className="new-pricing-box">
						<span className="new-pricing-text mwidth120">Discount Type:</span>
						<select
							className="freight-modal-input mwidth220 mr4"
							value={discountInfoData.discountType}
							onChange={(e) => handleChange("discountType", e.target.value)}
						>
							{Object.values(DiscountType).map((x: string, index: number) => (
								<option key={index}>{x}</option>
							))}
						</select>
					</div>
					<div className="new-pricing-box">
						<span className="new-pricing-text mwidth120">Discount Amount:</span>
						<input
							className="mapping-modal-table-input mapping-modal-widthx2 text-end pr1"
							value={discountInfoData.discountAmount}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								handleChange("discountAmount", e.target.value)
							}
							required
						/>
						<span className="ml1">%</span>
					</div>
				</div>
			)}
			<div className="new-pricing-box">
				<span className="new-pricing-text mwidth120">Sale Goal (€):</span>
				<input
					className="mapping-modal-table-input mwidth120 text-end pr1"
					value={discountInfoData.eventResult.goal}
					onChange={(e) =>
						setDiscountInfoData((prev: PricingEvent) => ({
							...prev,
							eventResult: { ...prev.eventResult, goal: e.target.value },
						}))
					}
					required
				/>
			</div>
			<div className="border-bottom width100"></div>
			<div className="new-pricing-box mt4">
				<div className="in-row align-center justify-between width100">
					<div className="in-row align-center mr4">
						<span className="new-pricing-text mwidth80">Start Date:</span>
						<div
							style={{ background: "#f2f2f2" }}
							className="filter-dates-calendar"
						>
							<div className="filter-calendar-icon">
								<img src="/icons/calendar.svg" alt="calendar" className="mr1" />
							</div>
							<DatePicker
								className="transperentbg"
								selected={discountInfoData.eventPeriod.start}
								onChange={(date: Date) =>
									setDiscountInfoData((prev: PricingEvent) => ({
										...prev,
										eventPeriod: { ...prev.eventPeriod, start: date },
									}))
								}
								timeInputLabel="Time:"
								dateFormat="MM/dd/yyyy h:mm aa"
								showTimeInput
							/>
						</div>
					</div>
					<div className="in-row align-center">
						<span className="new-pricing-text mwidth80">End Date:</span>
						<div
							style={{ background: "#f2f2f2" }}
							className="filter-dates-calendar"
						>
							<div className="filter-calendar-icon">
								<img src="/icons/calendar.svg" alt="calendar" className="mr1" />
							</div>
							<DatePicker
								className="transperentbg"
								selected={discountInfoData.eventPeriod.end}
								onChange={(date: Date) =>
									setDiscountInfoData((prev: PricingEvent) => ({
										...prev,
										eventPeriod: { ...prev.eventPeriod, end: date },
									}))
								}
								timeInputLabel="Time:"
								dateFormat="MM/dd/yyyy h:mm aa"
								showTimeInput
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(DiscountInfoComponent);
