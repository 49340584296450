import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "../../App";
import { Modal } from "../../ui/modal/Modal";
import * as UserController from "../../../actions/user";
import { IUser } from "../../../interfaces";

interface Props {
  mappedUser: IUser;
  setModal: (modal: boolean) => void;
  modal: boolean;
  rqKey: string;
}

export const UserActivity: React.FC<Props> = ({
  mappedUser,
  setModal,
  modal,
  rqKey,
}) => {
  const activityUserConfig = {
    onSuccess: () => {
      queryClient.invalidateQueries(rqKey);
    },
    onError: () => {
      toast.error("Something went wrong!");
    },
  };

  const editMutation = useMutation(
    (toUpdate: IUser) =>
      UserController.updateUser(mappedUser._id, toUpdate),
    activityUserConfig
  );

  const handleChange = async (e: React.MouseEvent) => {
    e.preventDefault();

    const toUpdate = {
      ...mappedUser,
      isActive: !mappedUser.isActive,
    };

    await editMutation.mutateAsync(toUpdate);
    setModal(false);
  };

  return (
    <Modal
      onModalClose={(e: React.MouseEvent) => {
        e.stopPropagation();
        setModal(false);
      }}
      isOpened={modal}
    >
      <>
        <div className="table-modal-form-cont p4">
          <div className="updateForm__container">
            <div className="vendor-mapping-modal-text">
              {`Are you sure you want to ${mappedUser.isActive ? "deactivate" : "activate"
                }`}{" "}
              <br /> <strong>{mappedUser.username}</strong>?
            </div>
            <div className="table-modal-border"></div>
            <div className="table-modal-form-button-box width100 m0">
              <button
                className="table-modal-form-button-cancel"
                onClick={() => setModal(false)}
              >
                Cancel
              </button>
              <button
                className="table-modal-form-button"
                onClick={handleChange}
              >
                SET
              </button>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};
