import React from "react";
import { Modal } from "../../../../ui/modal/Modal";
import CountryFlag from "../../../../ui/country-flag/CountryFlag";
import { IBatchMarkeplace, IBatchMProducts } from "../../../../../interfaces";

interface Props {
	setModal: (modal: boolean) => void;
	modal: boolean;
	batchPlatform: IBatchMarkeplace;
}

const BatchErrorsModal: React.FC<Props> = ({
	setModal,
	modal,
	batchPlatform,
}) => {
	return (
		<Modal
			onModalClose={(e: React.MouseEvent) => {
				e.stopPropagation();
				setModal(false);
			}}
			isOpened={modal}
		>
			<div className="batch-error-container">
				<div className="bold black-color ft4">Log Report</div>
				<div className="table-border-bottom mb4 mt1"></div>
				<div className="in-row align-center justify-start width100 mb4">
					<div>
						<img
							className="batch-image-border"
							height={84}
							width={84}
							src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${batchPlatform.marketplace.marketplaceName}.svg`}
							alt=""
						/>
					</div>
					<div className="in-row ml4 align-center">
						<CountryFlag
							country={batchPlatform.marketplace.country}
							className="table-search-image"
							width="35px"
						/>
						<div className="dark-gray-color bold">
							{batchPlatform.marketplace.zeoosName}
						</div>
					</div>
				</div>
				<div className="dark-gray-color mb1">Errors:</div>
				{batchPlatform.products
					.filter((x: IBatchMProducts) => x.error)
					.map((z: IBatchMProducts) => (
						<div className="mb4">
							<textarea
								className="batch-textarea"
								readOnly
								value={`The error for sku ${z.sku} is ${z.error}`}
							/>
						</div>
					))}
				<div className="justify-center align-center width100">
					<input
						type="submit"
						value="Close"
						className="table-modal-form-button"
						onClick={() => setModal(false)}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default BatchErrorsModal;
