import React, { useContext, useMemo, useState } from "react";
import { CountriesContext } from "../../context";
import { ICountryM } from "../../routes/country-management/CountryManagement";
import MultiSelect from "../multi-select";

interface Props {
  value: string;
  setValue: (newValue: string) => void;
  level?: number;
  code?: string;
}
const LanguageSelect: React.FC<Props> = ({ value, setValue }) => {
  const initialCountries = useContext(CountriesContext) as ICountryM[];
  const [countries, setCountries] = useState<ICountryM[]>(initialCountries);

  const options = useMemo(
    () =>
      countries?.map((country) => ({
        label: country.language,
        value: country.langCode,
      })) || [],
    [countries]
  );

  return (
    <MultiSelect
      helperText=""
      value={value}
      setValue={setValue as any}
      options={options}
      multiple={false}
    />
  );
};

export default LanguageSelect;
