import axios from "axios";
import { getHeaders } from "./user";
import queryString from "query-string";
import { IPaginatedRequest } from "../interfaces";
import { PricingEvent, StandingRule } from "types";

export async function getPaginatedEvents(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/pricing-events?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}

export async function getPricingEventsMetrics() {
  const res = await axios.get(`/pricing-events/metrics`, getHeaders());

  return res.data as {
    sales: {
      overall: {
        goal: number;
        sold: number;
      };
      byPlatform: {
        platformId: string;
        zeoosName: string;
        total: number;
      }[];
    };
    nbActive: number;
  };
}

export async function getEvent(id: string) {
  const res = await axios.get(`/pricing-event/${id}`, getHeaders());
  return res.data.singleEvent;
}

export async function createEvent(body: PricingEvent) {
  await axios.post("/pricing-event", body, getHeaders());
}

export async function updateEvent(body: PricingEvent) {
  await axios.put(`/pricing-event/${body._id}`, body, getHeaders());
}

export async function deleteEvent(id: string) {
  await axios.delete(`/pricing-event/${id}`, getHeaders());
}

export async function getPaginatedStandingRules(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/pricing-rules?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}

export async function getStandingRule(id: string) {
  const res = await axios.get(`/pricing-rule/${id}`, getHeaders());
  return res.data.singleStandingRule;
}

export async function createStandingRule(body: StandingRule) {
  await axios.post("/pricing-rule", body, getHeaders());
}

export async function updateStandingRule(body: StandingRule) {
  await axios.put(`/pricing-rule/${body._id}`, body, getHeaders());
}

export async function deleteStandingRule(id: string) {
  await axios.delete(`/pricing-rule/${id}`, getHeaders());
}
