import axios from "axios";
import { IPaginatedRequest } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";

export async function getSourceById(id: string) {
  const res = await axios.get(`/sources/${id}`, getHeaders());
  return res.data.fileInfo;
}

export async function getSources(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/sources?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}
