import React, { useState } from "react";
import { Paginator } from "../../../ui/pagination/Paginator";
import { NavLink } from "react-router-dom";

export default function PaymentBatchTable() {
    const [paginatedData, setPaginatedData] = useState<number>();
    const [limit, setLimit] = useState<number>(50);


    return (
        <div className="table-main-cont">
            <div className="table-main-title-cont">
                <div className="table-main-title-search-value-cont">
                    <div className="table-search-box">
                        <form className="table-search-form ml2" onSubmit={(e) => e.preventDefault()}>
                        <input
                            className="table-search-input"
                            placeholder="Search product"
                        />
                        <img
                            className="table-search-image"
                            src="/icons/search.svg"
                            width="25px;"
                            alt=""
                        />
                        </form>
                    </div>
					<div className="table-main-title-text">27 payment batchs</div>
				</div>
				<div className="marketplace-pagination">
                    <Paginator
                        data={{}}
                        rowsPerPage={limit}
                        setPaginatedData={setPaginatedData}
                        setLimit={setLimit}
                        limit={limit}
                      />
				</div>
            </div>
            <div className="table-border-bottom"></div>
                <div style={{ height: "max-content" }} className="table-overflow">
                    <table style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}>
						<thead>
							<tr
								style={{ marginTop: "-5px", width: "100%" }}
								className="table-results-title"
							>
									<th className="paybatch-id">
                                        Batch ID
                                    </th>
                                    <th className="paybatch-desc">Description</th>
                                    <th className="paybatch-amount-bil">Amount of <br/>Billings</th>
                                    <th className="paybatch-products">Products</th>
                                    <th className="paybatch-amount">Amount</th>
                                    <th className="paybatch-date">Date / Time</th>
                                    <th className="paybatch-status">Status</th>
                            </tr>
                        </thead>
                        <tbody></tbody>
					</table>
                    <div>
                        <table className="table-results p0">
                            <thead></thead>
                            <tbody>
                                <tr className="table-result-box billing-text" >
                                    <td className="paybatch-id bold">
                                        <NavLink to="/edit-paymentbatch">PB AAAB0011</NavLink>
                                    </td>
                                    <td className="paybatch-desc">Payment Batch description text</td>
                                    <td className="paybatch-amount-bil">
                                        <NavLink to="/create-paymentbatch" className="in-row align-center">
                                            <img className="mr1" src="/icons/paybatch-amountbil.svg" alt="" />
                                            800
                                        </NavLink>
                                    </td>
                                    <td className="paybatch-products">
                                        <img className="mr1" src="/icons/paybatch-product.svg" alt="" />
                                        1220
                                    </td>
                                    <td className="paybatch-amount bold">21.202,00 €</td>
                                    <td className="paybatch-date">
                                        2021/09/17
                                        <br/>
                                        8:45 PM
                                    </td>
                                    <td className="paybatch-status">
                                        <div className="paybatch-paid">
                                            Paid
                                        </div>
                                    </td>
                                </tr>
                                <tr className="table-result-box billing-text" >
                                    <td className="paybatch-id bold">
                                        <NavLink to="/edit-paymentbatch">PB AAAB0011</NavLink>
                                    </td>
                                    <td className="paybatch-desc">Payment Batch description text</td>
                                    <td className="paybatch-amount-bil">
                                        <NavLink to="/create-paymentbatch" className="in-row align-center">
                                            <img className="mr1" src="/icons/paybatch-amountbil.svg" alt="" />
                                            800
                                        </NavLink>
                                    </td>
                                    <td className="paybatch-products">
                                        <img className="mr1" src="/icons/paybatch-product.svg" alt="" />
                                        1220
                                    </td>
                                    <td className="paybatch-amount bold">21.202,00 €</td>
                                    <td className="paybatch-date">
                                        2021/09/17
                                        <br/>
                                        8:45 PM
                                    </td>
                                    <td className="paybatch-status">
                                        <div className="paybatch-processing">
                                            Processing
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
          </div>
    );
}
