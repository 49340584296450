import React, { useEffect, useState } from "react";
import { getPreviewBreakdown } from "../../../../actions/marketplace";
import useUser from "../../../../hooks/useUser";
import { Role } from "../../../../interfaces";

const formatMoney = (number: number) => {
	return new Intl.NumberFormat("de", {
		style: "currency",
		currency: "EUR",
	}).format(!number ? 0 : number);
};

const formatPercent = (number: number) => {
	return new Intl.NumberFormat("de", {
		style: "percent",
		maximumFractionDigits: 2,
	})
		.format(!number ? 0 : number / 100)
		.replace(/\s/g, "");
};

interface Props {
	breakdown: any;
	editPvpPrice: boolean;
	setEditPvpPrice: (editPvpPrice: React.SetStateAction<boolean>) => void;
	editPurchasePrice: boolean;
	setEditPurchasePrice: (editMode: React.SetStateAction<boolean>) => void;
	pricingType: string;
	setPvpPrice: (value: number) => void;
	setPurchasePrice: (value: number) => void;
  attBreak?: any;
  mode: "PVP"|"PROMO";
}

const formatBreakdown = {
	wortenSeller: [
		{ field: "pvpFinal", pvp: true, edit: true, label: "PVP", trcss: "mb2" },
		{
			field: "productVATValue",
			perc: "productVAT",
			label: "Product VAT",
			trcss: "separator",
			tdcss: "pb1 pt1",
		},
		{ field: "pvpBase", label: "PVP Base", trcss: "bold", tdcss: "pt1" },
		{ field: "zeoosValue", perc: "zeoosRate", label: "Zeoos Rate" },
		{
			field: "platformValue",
			perc: "platformRate",
			label: "Platform Rate",
			trcss: "separator",
			tdcss: "pb1",
		},
		{ field: "purchasePrice", label: "Purchase Price" },
		{ field: "cost", cost: true, edit: true, label: "Cost", tdcss: "pb1" },
	],
	wholesaler: [
		{ field: "pvpFinal", pvp: true, edit: true, label: "PVP", trcss: "mb2" },
		{
			field: "productVATValue",
			perc: "productVAT",
			label: "Product VAT",
			trcss: "separator",
			tdcss: "pb1 pt1",
		},
		{ field: "pvpBase", label: "PVP Base", trcss: "bold", tdcss: "pt1" },
		{ field: "zeoosValue", perc: "zeoosRate", label: "Zeoos Rate" },
		{
			field: "platformValue",
			perc: "platformRate",
			label: "Platform Rate",
			trcss: "separator",
			tdcss: "pb1",
		},
		{
			field: "purchasePrice",
			label: "Purchase Price",
			trcss: "bold",
			tdcss: "pt1",
		},
		{ field: "costVATValue", perc: "costVAT", label: "VAT" },
		{ field: "paymentCosts", label: "Payment Cost" },
		{ field: "cost", cost: true, label: "Cost", trcss: "bold", tdcss: "pb1" },
	],
	fullBreakdown: [
		{ field: "pvpFinal", pvp: true, edit: true, label: "PVP", trcss: "mb2" },
		// {
		// 	field: "freightVATValue",
		// 	perc: "freightVAT",
		// 	label: "Freight VAT",
		// 	trcss: "",
		// 	tdcss: "",
		// },
    {
			field: "productVATValue",
			perc: "productVAT",
			label: "Product VAT",
			trcss: "",
			tdcss: "pt1",
		},
		{ field: "duty", label: "Excise Duty", trcss: "separator" },
		{ field: "pvpBase", label: "PVP Base", trcss: "bold", tdcss: "pt1" },
    {
			field: "platformValue",
			perc: "platformRate",
			label: "Platform Rate",
			trcss: "",
			tdcss: "",
		},
    { field: "freight", label: "Freight" },
		{ field: "fulfillCost", label: "Fulfillment Cost", trcss: "", tdcss: "" },
		{
			field: "zeoosValue",
			perc: "zeoosRate",
			label: "Zeoos Rate",
			trcss: "separator",
		},
		{
			field: "purchasePrice",
			label: "Purchase Price",
			trcss: "bold",
			tdcss: "pt1",
		},
		{ field: "markup", label: "Markup" },
		{
			field: "cost",
			cost: true,
			edit: true,
			label: "Cost",
			trcss: "bold",
			tdcss: "pb1",
		},
	],
	pvpAndCost: [
		{ field: "pvpFinal", pvp: true, edit: true, label: "PVP", trcss: "mb2" },
		{
			field: "productVATValue",
			perc: "productVAT",
			label: "Product VAT",
			trcss: "separator",
			tdcss: "pb1 pt1",
		},
		{ field: "pvpBase", label: "PVP Base", trcss: "bold", tdcss: "pt1" },
		{
			field: "platformValue",
			perc: "platformRate",
			label: "Platform Rate",
			trcss: "",
			tdcss: "pt1",
		},
		{
			field: "zeoosValue",
			perc: "zeoosRate",
			label: "Zeoos Rate",
			trcss: "separator",
		},
		{
			field: "purchasePrice",
			label: "Purchase Price",
			trcss: "bold",
			tdcss: "pt1",
		},
		{ field: "transport", label: "Transport" },
		{
			field: "cost",
			cost: true,
			edit: true,
			label: "Cost",
			trcss: "bold",
			tdcss: "pb1",
		},
	],
  d2c: [
		{ 
      field: "pvpFinal", 
      pvp: true, 
      edit: true, 
      label: "PVP", 
      trcss: "mb2"
    },
		{
			field: "productVATValue",
			perc: "productVAT",
			label: "Product VAT",
			trcss: "separator",
			tdcss: "pb1 pt1",
      permission: [Role.admin]
		},
		{
      field: "pvpBase",
      label: "PVP Base",
      trcss: "bold",
      tdcss: "pt1",
      permission: [Role.admin]
    },
		{
			field: "platformValue",
			perc: "platformRate",
			label: "Platform Rate",
			trcss: "",
			tdcss: "pt1",
      permission: [Role.admin]
		},
		{
			field: "zeoosValue",
			perc: "zeoosRate",
			label: "Zeoos Rate",
			trcss: "separator",
      permission: [Role.admin]
		},
		{
			field: "purchasePrice",
			label: "Purchase Price",
			trcss: "bold",
			tdcss: "pt1",
      permission: [Role.admin]
		},
		{ 
      field: "transport",
      label: "Transport",
      permission: [Role.admin]
    },
		{
			field: "cost",
			cost: true,
			edit: false,
			label: "Cost",
			trcss: "bold",
			tdcss: "pb1",
		},
	],
  hub: [
    { 
      field: "pvpFinal", 
      pvp: true, 
      edit: true, 
      label: "PVP", 
      trcss: "mb2"
    },
  ],
	default: [
		{ field: "pvpFinal", pvp: true, edit: true, label: "PVP", trcss: "mb2" },
		{
			field: "ivaValue",
			perc: "iva",
			label: "IVA",
			trcss: "",
			tdcss: "pb1 pt1",
		},
		{ field: "iec", label: "IEC", trcss: "" },
		{ field: "deliveryPrice", label: "Freight", trcss: "" },
		{ field: "basePrice", label: "PVP Base" },
		{ field: "vinuusPrice", label: "Vinuus Services" },
		{
			field: "vendorPrice",
			cost: true,
			edit: true,
			label: "Vendor Price",
			trcss: "bold",
		},
	],
} as any;

const SellerPricingBreakdown: React.FC<Props> = ({
	breakdown,
	editPvpPrice,
	setEditPvpPrice,
	editPurchasePrice,
	setEditPurchasePrice,
	setPvpPrice,
	setPurchasePrice,
	pricingType,
  attBreak,
  mode
}) => {
	const [pvpPrice2, setPvpPrice2] = useState<string>(breakdown?.pvpFinal);
	const [purchasePrice2, setPurchasePrice2] = useState<string>(
		breakdown?.cost || breakdown?.vendorPrice
	);
	const [previewBreakdown, setPreviewBreakdown] = useState<any>(breakdown);
	const [intervPreview, setIntervPreview] = useState<any>(null);
	const [diff, setDiff] = useState<any>({});
	const visibility = !editPvpPrice && !editPurchasePrice ? "hidden" : "visible";
  const { user } = useUser();

	useEffect(
		() => {
			if (!editPvpPrice && !editPurchasePrice) {
				setPvpPrice(breakdown?.pvpFinal);
				setPvpPrice2(breakdown?.pvpFinal);
				setPurchasePrice(breakdown?.cost || breakdown?.vendorPrice);
				setPurchasePrice2(breakdown?.cost || breakdown?.vendorPrice);
				setPreviewBreakdown(breakdown);
			}
		},
		// eslint-disable-next-line
		[editPvpPrice, editPurchasePrice, breakdown]
	);

	useEffect(
		() => {
			let newDiff = {} as any;
			Object.keys(breakdown || {}).forEach((k: string) => {
				if (!isNaN(breakdown[k])) {
					newDiff[k] = previewBreakdown[k] - breakdown[k];
				}
			});
			setDiff(newDiff);
		},
		// eslint-disable-next-line
		[previewBreakdown]
	);

	const getPreview = (param: any) => {
		if (intervPreview) clearTimeout(intervPreview);

    if (attBreak) {
      breakdown = attBreak;
    }

		setIntervPreview(
			setTimeout(async () => {
				setPreviewBreakdown(
					await getPreviewBreakdown({
						pricingType,
						breakdown,
						...param,
					})
				);
			}, 1000)
		);
	};

  const checkPermission = (field: any, user: any) => {
    return !field.permission || field.permission.includes(user.role);
  }

	const formatRules = formatBreakdown[pricingType || "default"];
  const readonly = user?.role === Role.admin ? false : true;

	return (
		<div className="width100">
			<table
				style={{ borderCollapse: "collapse" }}
				className="width100 line-height-26 ft2"
			>
				{/* pvp */}
				{formatRules
					.filter((f: any) => f.pvp)
					.map((f: any, index: number) => {
            if (!checkPermission(f, user)) return <></>;

						return (
							<thead
								key={index}
								className={`seller-pricing-thead ${
									editPvpPrice ? "edit-color" : "admin-titlebg"
								}`}
							>
								<tr className="mb2">
									<th
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "flex-end",
											alignItems: "center",
											height: "40px",
										}}
									>
										{editPvpPrice ? (
											<img
												src="/icons/carbon_close-outline.svg"
												className="align-center pointer absolute left0 pl1"
												alt=""
												onClick={() => setEditPvpPrice(false)}
											/>
										) : (
											<>
												{!readonly && f.edit && !editPurchasePrice && (
													<div className="seller-pricing-pvp-cont">
														<div className="seller-pricing-img-cont">
															<img
																src="/icons/tag-edit.svg"
																className="seller-pricing-img-image"
																alt=""
															/>
															<div
																className="seller-pricing-img-overlay"
																onClick={() => setEditPvpPrice(true)}
															>
																<img src="/icons/tag-edit-open.svg" alt="" />
															</div>
														</div>
													</div>
												)}
											</>
										)}
										<div className="ml3">{mode === "PROMO" ? "PROMO " : ""}{f.label}:</div>
									</th>
									<th>{formatMoney(breakdown?.[f.field]) || "NA"}</th>
									<th className="grey" style={{ visibility: visibility }}>
										{formatMoney(diff[f.field])}
									</th>
									<th style={{ width: "20%", visibility: visibility }}>
										{editPvpPrice ? (
											<input
												className="table-modal-border-value width80px height30px text-alignc"
												onChange={(e) => {
													const pvp = Number(e.target.value);
													setPvpPrice2(e.target.value);
													setPvpPrice(pvp);
                          if (pricingType === 'd2c') {
                            getPreview({ pvp, cost: Number(purchasePrice2) });
                          } else {
                            getPreview({ pvp });
                          }
												}}
												step="0.01"
												value={pvpPrice2?.toString()}
												type="number"
											/>
										) : (
											<div style={{ textAlign: "center" }}>
												{formatMoney(previewBreakdown?.[f.field])}
											</div>
										)}
									</th>
								</tr>
							</thead>
						);
					})}
				<tbody className="seller-pricing-tbody">
					{/* other fields */}
					{formatRules
						.filter((f: any) => !f.pvp)
						.map((f: any, index: number) => {
              if (!checkPermission(f, user)) return <></>;

							return !f.cost ? (
								<tr className={f.trcss} key={index}>
									<td className={f.tdcss}>
										{f.label}
										{f.perc ? ` (${formatPercent(breakdown?.[f.perc]||attBreak?.[f.perc])})` : ""}:
									</td>
									<td className={f.tdcss}>
										{formatMoney(breakdown?.[f.field]) || "NA"}
									</td>
									<td
										className={`grey ${f.tdcss}`}
										style={{ visibility: visibility }}
									>
										{formatMoney(diff[f.field])}
									</td>
									<td className={f.tdcss} style={{ visibility: visibility }}>
										{formatMoney(previewBreakdown?.[f.field])}
									</td>
								</tr>
							) : (
								<tr key={index}
									className={`${f.edit ? "seller-pricing-tr" : ""} bold ${
										(editPurchasePrice
                      //  || (editPvpPrice && pricingType === 'd2c')
                    ) && "seller-pricing-tr edit-color"
									}`}
								>
									<td
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "flex-end",
											alignItems: "center",
											width: "100%",
											height: f.edit 
                        // || (editPvpPrice && pricingType === 'd2c') 
                        ? "40px" : "auto",
										}}
									>
										{editPurchasePrice ? (
											<img
												src="/icons/carbon_close-outline.svg"
												className="align-center pointer absolute left0 pl1"
												alt=""
												onClick={() => setEditPurchasePrice(false)}
											/>
										) : (
											<>
												{!readonly && !editPvpPrice && f.edit && (
													<div className="seller-pricing-pvp-cont">
														<div className="seller-pricing-img-cont">
															<img
																src="/icons/tag-edit.svg"
																className="seller-pricing-img-image"
																alt=""
															/>
															<div
																className="seller-pricing-img-overlay"
																onClick={() => setEditPurchasePrice(true)}
															>
																<img src="/icons/tag-edit-open.svg" alt="" />
															</div>
														</div>
													</div>
												)}
											</>
										)}
										<div className="ml3">{f.label}:</div>
									</td>
									<td>{formatMoney(breakdown?.[f.field]) || "NA"}</td>
									<td className="grey" style={{ visibility: visibility }}>
										{formatMoney(diff[f.field])}
									</td>
									<td style={{ width: "20%", visibility: visibility }}>
										{editPurchasePrice //|| (editPvpPrice && pricingType === 'd2c') 
                      ? (
											<input
												className="table-modal-border-value width80px height30px text-alignc"
												onChange={(e) => {
													const cost = Number(e.target.value);
													setPurchasePrice(cost);
													setPurchasePrice2(e.target.value);
                          if (pricingType === 'd2c') {
                            getPreview({ cost, pvp: Number(pvpPrice2) });
                          } else {
                            getPreview({ cost });
                          }
												}}
												step="0.01"
												value={purchasePrice2?.toString()}
												type="number"
											/>
										) : (
											<div style={{ visibility: visibility }}>
												{formatMoney(previewBreakdown?.[f.field])}
											</div>
										)}
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
};

export default SellerPricingBreakdown;
