import React, { useEffect, useState } from "react";
import { convertPrice, UpdateGestao } from "../../tables/gestao/UpdateGestao";
import { findMarketplace } from "../../../../actions/marketplace";
import { IMarketplace } from "../../../../interfaces";
import { Link } from "react-router-dom";
import "../pim.css";
import { ITaxMapping } from "../../mappings/tax/Tax";
import { getTaxMappings } from "../../../../actions/taxMapping";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CostsRate from "./CostsRate";


export const Costs: React.FC<any> = ({
  productInfo,
  onInputChange,
  combinedProduct,
  onUpdateCallback,
}) => {
  const [modal, setModal] = useState<boolean>(false);
  const [marketplace, setMarketplace] = useState<IMarketplace>();
  const [taxMappings, setTaxMappings] = useState<ITaxMapping[]>([]);
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  useEffect(() => {
    getTaxMappings().then(setTaxMappings).catch(console.error);
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };


  return (
    <div>
      <form>
        <div className="pim-main-title">
          <div className="pim-main-title-box">
            <div className="pim-title-legend">STOCK TOTAL</div>
          </div>
          <div className="pim-main-title-cont-costs align-center">
            <div className="pim-content-section align-center">
              <div className="pim-costs-title-cont-text">
                Stock total da variante:{" "}
              </div>
              <input
                className="pim-costs-title-cont-input"
                value={productInfo.stock}
                onChange={(e) => onInputChange(e, "stock")}
              />
            </div>

            <div className="pim-content-section align-center">
              <div className="pim-costs-title-cont-text">
                Categoria de Imposto:{" "}
              </div>
              <select
                className="pim-count-select m0 mwidth200 maxwidth200"
                placeholder="Select category"
                value={productInfo.taxCategory}
                onChange={(e) => onInputChange(e, "taxCategory")}
                style={{
                  border: `${!productInfo.taxCategory && "1px solid #55718a"}`,
                }}
              >
                {!taxMappings.find(
                  (x: any) => x._id === productInfo.taxCategory
                ) && <option>{productInfo.taxCategory}</option>}
                {taxMappings.map((x: any, index: number) => (
                  <option value={x._id} key={index}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="pim-main">
{/*           
          <div className="pim-title-legend pt4">Sellers</div>
            <div className="table-main-title-cont">
                <div className="table-main-title-search-value-cont">
                    <div className="table-search-box ml4">
                        <form className="table-search-form">
                        <input
                            className="table-search-input"
                            placeholder="Search product"
                        />
                        <img
                            className="table-search-image"
                            src="/icons/search.svg"
                            width="25px;"
                            alt=""
                        />
                        </form>
                    </div>
					          <div className="table-main-title-text">53 vendors</div>
				          </div>
                  <div className="marketplace-pagination">    
                  </div>
                </div>
                <div className="table-border-bottom"></div>
                <div style={{ height: "max-content" }} className="table-overflow">
					        <table style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%", }}>
                    <thead>
                      <tr
                        style={{ marginTop: "-5px", width: "100%" }}
                        className="table-results-title"
                      >
                        <th className="vendors-accordion"></th>
                        <th className="pim-costs-vendor">Vendor</th>
                        <th className="pim-costs-type">Delivery Type</th>
                        <th className="pim-costs-country">Countries</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
					        </table>
					        <div>
						        <table className="table-results p0">
							        <thead></thead>
							        <tbody>
                      <Accordion
                              className="m0 p0 width100"
                              expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
                            >
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="width100 p0 m0div"
                              >
                                <tr style={{width: "100%", marginBottom: "0"}} className="table-result-box height100px pim-costs-table-text">
                                <td className="vendors-accordion">
                                    <img
                                      src={
                                         "/icons/vendors-arrow-down.svg"
                                      }
                                      alt=""
                                    />
                                  </td>
                                  <th style={{fontWeight: "400"}} className="pim-costs-vendor">
                                    <img width="106px" height="80px" src="/icons/test.svg" alt="" />
                                    Direct Wine, Lda
                                  </th>
                                  <th style={{fontWeight: "400"}} className="pim-costs-type">Fulfillment</th>
                                  <th style={{fontWeight: "400"}} className="pim-costs-country">7 countries</th>
                                </tr>
                                </AccordionSummary>
                              <AccordionDetails className="width100 p0 m0div">
                              <table className="table-results mheightauto">  
                                  <thead>                               
                                    <tr style={{background: "#fff", borderLeft: "none", borderRight: "none",  borderTop: "none"}} className="table-results-title border0 width100 m0 height30 width100">
                                      <th className="pim-costs-sub-country">Country</th>
                                      <th className="pim-costs-sub2">Tax</th>
                                      <th className="pim-costs-sub">Cost</th>
                                      <th className="pim-costs-sub">Markup</th>
                                      <th className="pim-costs-sub">Purchase Price</th>
                                      <th className="pim-costs-sub">Zeoos Rate</th>                                
                                      <th className="pim-costs-sub2">Set Rates</th>
                                    </tr>               
                                    </thead>   
                                    <tbody>
                                      <tr style={{ height: "50px"}} className="table-result-box pim-costs-table-text border0 m0 border-bottom width100">
                                        <td className="pim-costs-sub-country">
                                          <img
                                            src="/icons/portugal.svg"
                                            alt=""
                                            width="28px"
                                          />
                                          <div className="email-text pl1">
                                            Portugal
                                          </div>
                                        </td>
                                        <td className="pim-costs-sub2">
                                          21%
                                        </td>
                                        <td className="pim-costs-sub">
                                        3,90 €
                                        </td>
                                        <td className="pim-costs-sub">
                                        5,90 €
                                        </td>
                                        <td className="pim-costs-sub">
                                        9,80 €
                                        </td>
                                        <td className="pim-costs-sub">
                                        12%
                                        </td>
                                        <td className="pim-costs-sub2">
                                          <CostsRate/>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "50px"}} className="table-result-box pim-costs-table-text border0 m0 border-bottom width100">
                                        <td className="pim-costs-sub-country">
                                          <img
                                            src="/icons/portugal.svg"
                                            alt=""
                                            width="28px"
                                          />
                                          <div className="email-text pl1">
                                            Portugal
                                          </div>
                                        </td>
                                        <td className="pim-costs-sub2">
                                          21%
                                        </td>
                                        <td className="pim-costs-sub">
                                        3,90 €
                                        </td>
                                        <td className="pim-costs-sub">
                                        5,90 €
                                        </td>
                                        <td className="pim-costs-sub">
                                        9,80 €
                                        </td>
                                        <td className="pim-costs-sub">
                                        12%
                                        </td>
                                        <td className="pim-costs-sub2">
                                          <CostsRate/>
                                        </td>
                                      </tr>
                                    </tbody>    
                                  </table>

                              </AccordionDetails>
                            </Accordion>


                      </tbody>
                    </table>
                  </div>
                </div> */}


          {/* <div className="table-overflow p0">
            <table className="table-results p0">
              <thead>
                <tr className="table-results-title">
                  <th className="costs-venda">Canal de Venda</th>
                  <th className="table-border-right"></th>
                  <th className="costs-stock">Stock</th>
                  <th className="table-border-right"></th>
                  <th className="costs-preco">Preço</th>
                  <th className="table-border-right"></th>
                  <th className="costs-acoes">Ações</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(combinedProduct?.marketplaces || {}).map(
                  (marketplace: string, index: number) => (
                    <tr className="table-result-box" key={index}>
                      <td className="costs-venda">
                        <div className="costs-venda-text underline">
                          <Link to={`/marketplace/${marketplace}`}>
                            {marketplace}
                          </Link>
                        </div>
                      </td>
                      <td className="table-border-right"></td>
                      <td className="costs-stock">
                        <div className="costs-stock-text">
                          {combinedProduct?.marketplaces[marketplace]?.stock}
                        </div>
                      </td>
                      <td className="table-border-right"></td>
                      <td className="costs-preco">
                        <div className="costs-preco-text">
                          {convertPrice(
                            combinedProduct?.marketplaces[marketplace]?.price ||
                              0
                          )}
                          €
                        </div>
                      </td>
                      <td className="table-border-right"></td>
                      <td className="costs-acoes">
                        <div
                          onClick={async (e) => {
                            e.preventDefault();

                            setMarketplace(
                              (await findMarketplace(
                                marketplace
                              )) as IMarketplace
                            );
                            setModal(true);
                          }}
                          className="costs-acoes-text pointer"
                        >
                          Editar
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div> */}
        </div>
        {/* {modal && (
          <UpdateGestao
            modal={modal}
            setModal={setModal}
            product={combinedProduct}
            onUpdateCallback={onUpdateCallback}
            productMarketplace={marketplace as IMarketplace}
          />
        )} */}
      </form>
    </div>
  );
};
