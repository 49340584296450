import React, { useCallback, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "../../App";
import {
	createOnBoardingSellerNew,
	deleteOnBoardingSeller,
	editOnBoardingSeller,
} from "../../../actions/onboardingSeller";
import { IVendor } from "../../../interfaces";
import { deleteVendor, editVendor } from "../../../actions/vendor";
import { AxiosError } from "axios";
// import isVAT from "validator/lib/isVAT";

interface Props {
	rqKey: string;
	setModal: (modal: React.SetStateAction<boolean>) => void;
	seller?: Partial<IVendor>;
	label: string;
	tab: string;
	accountType?: "D2C" | "Hub";
	create?: (data: any) => Promise<any>;
}

const SellerCreateEditModal: React.FC<Props> = ({
	rqKey,
	setModal,
	seller,
	label,
	tab,
	accountType,
	create = createOnBoardingSellerNew
}) => {

	const [loading, setLoading] = useState(false);
	const [sellerData, setSellerData] = useState<any>({
		contactInfo: {
			sellerName: seller?.contactInfo?.sellerName || "",
			companyName: seller?.contactInfo?.companyName || "",
			vatNumber: seller?.contactInfo?.vatNumber || "",
			phone: seller?.contactInfo?.phone || "",
		},
		fiscalAddress: {
			address: seller?.fiscalAddress?.address || "",
			city: seller?.fiscalAddress?.city || "",
			country: seller?.fiscalAddress?.country || "",
			postalCode: seller?.fiscalAddress?.postalCode || "",
			region: seller?.fiscalAddress?.region || "",
		},
		warehouseAddress: {
			wAddress: seller?.warehouseAddress?.wAddress || "",
			wComplement: seller?.warehouseAddress?.wComplement || "",
			wCity: seller?.warehouseAddress?.wCity || "",
			wCountry: seller?.warehouseAddress?.wCountry || "",
			wPostalCode: seller?.warehouseAddress?.wPostalCode || "",
			wRegion: seller?.warehouseAddress?.wRegion || "",
			wContact: seller?.warehouseAddress?.wContact || "",
			wPhone: seller?.warehouseAddress?.wPhone || "",
		},
		bankInfo: {
			bankName: seller?.bankInfo?.bankName || "",
			iban: seller?.bankInfo?.iban || "",
			swiftCode: seller?.bankInfo?.swiftCode || "",
		},

	});
	// eslint-disable-next-line
	const [mainEmail, setMainEmail] = useState<string>(seller?.email || "");
	// eslint-disable-next-line
	const [password, setPassword] = useState<string>("");
	// eslint-disable-next-line
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	// eslint-disable-next-line
	const [partnerWith, setPartnerWith] = useState<"Zeoos" | "Worten">(seller?.isWorten ? "Worten" : "Zeoos");
	// eslint-disable-next-line
	const [isTheSameAddress, setIsTheSameAddress] = useState<boolean>(false);
	// eslint-disable-next-line
	const [isTheSameEmail, setIsTheSameEmail] = useState<boolean>(false);
	// eslint-disable-next-line
	const [rate, setRate] = useState(seller?.rate || "");


	const refetchConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKey);
			queryClient.invalidateQueries("/notifications");
			toast.success(
				`The seller is successfully ${seller ? "edited" : "created"!}`
			);
		},
		onError: (error: AxiosError) => {
			toast.error(error.response?.data || "Something went wrong!");
		},
	};

	// eslint-disable-next-line
	const createMutation = useMutation(
		(data: Partial<IVendor>) => create(data),
		refetchConfig
	);

	// eslint-disable-next-line
	const editMutation = useMutation(
		(data: Partial<IVendor>) =>
			tab === "sellers" ? editVendor(data) : editOnBoardingSeller(data),
		refetchConfig
	);

	// eslint-disable-next-line
	const deleteMutation = useMutation(
		() =>
			tab === "sellers"
				? deleteVendor(seller!._id as string)
				: deleteOnBoardingSeller(seller!._id as string),
		refetchConfig
	);

	// eslint-disable-next-line
	const onDeleteSeller = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			await deleteMutation.mutateAsync();

			setModal(false);
		},
		[deleteMutation, setModal]
	);

	// eslint-disable-next-line
	// const checkVat = useCallback(() => {
	// 	const countryCode = countries?.find(
	// 		(x: any) => x.name === sellerData.fiscalAddress?.country
	// 	)?.isoCode;

	// 	return (
	// 		sellerData?.contactInfo?.vatNumber &&
	// 		isVAT(sellerData?.contactInfo?.vatNumber, countryCode)
	// 	);
	// }, [countries, sellerData]);

	// eslint-disable-next-line
	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			// if (!checkVat()) {
			// 	toast.error("Vat number is incorrect");
			// 	return;
			// }

			setLoading(true);
			let values: any = {};
			if (accountType === "D2C") {
				values = {
					...seller,
					email: mainEmail,
					contactInfo: sellerData.contactInfo,
					fiscalAddress: sellerData.fiscalAddress,
					zeoosClient: partnerWith === "Zeoos" ? true : false,
					isWorten: partnerWith === "Worten" ? true : false,
					warehouseAddress: sellerData.warehouseAddress,
					bankInfo: sellerData.bankInfo,
					accountType: accountType,
					rate
				};
			} else {
				if (password !== confirmPassword) {
					toast.error('Passwords do not match!');
				}
				values = {
					...seller,
					email: mainEmail,
					contactInfo: sellerData.contactInfo,
					fiscalAddress: sellerData.fiscalAddress,
					zeoosClient: true,
					accountType: accountType,
					password,
					rate
				};
			}

			try {
				if (seller) {
					await editMutation.mutateAsync(values);
				} else {
					await createMutation.mutateAsync(values);
				}

				setModal(false);
			} catch (error) {
				console.error(error);
			}

			// setLoading(false);
		},
		[createMutation, sellerData, setModal, editMutation, seller, mainEmail]
	);

	return (
		<form onSubmit={onSubmit}>
			<div className="product-header-cont">
				<div className="in-row align-cener height100">
					<div
						className="product-exit-btn pointer"
						onClick={() => setModal(false)}
					>
						<img className="mr2" src="/icons/product-exit.svg" alt="" />
						Discard
					</div>
					<div className="in-row align-center ml2">
						<span className="product-header-text ml1">{label}</span>
					</div>
				</div>
				<button className="product-header-button" type="submit" disabled={loading}>
					save
				</button>
			</div>
			<div
				style={{
					height: "max-content",
					paddingBottom: "20px",
					background: "#EDEBE4",
					minHeight: "85vh",
				}}
				className="in-column align-center justify-start"
			>
				<div className="sel-newon-cont">
					<div className="sel-newon-title">Contact information</div>
					<div className="sel-newon-box">
						<div className="sel-newon-content">
							<span>Seller Name: </span>
							<InputComponent
								value={sellerData?.contactInfo?.sellerName}
								setValue={setSellerData}
								inputField={"sellerName"}
								section={"contactInfo"}
							/>
						</div>
						<div className="sel-newon-content">
							<span>Company Name: </span>
							<InputComponent
								value={sellerData?.contactInfo?.companyName}
								setValue={setSellerData}
								inputField={"companyName"}
								section={"contactInfo"}
							/>
						</div>
						{accountType === "Hub" && <div className="sel-newon-content">
							<span>Email: </span>
							<input
								className={`sel-newon-input`}
								value={mainEmail}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									e.preventDefault();
									e.stopPropagation();
									setMainEmail(e.target.value);
								}}
								required={true}
								type={"email"}
							/>
						</div>}
						<div className="sel-newon-content">
							<span>Telephone: </span>
							<InputComponent
								value={sellerData?.contactInfo?.phone}
								setValue={setSellerData}
								inputField={"phone"}
								section={"contactInfo"}
								addClass={"maxwidth150"}
							/>
						</div>
						{accountType === "Hub" && <div className="sel-newon-content">
							<span>Password: </span>
							<input
								className={`sel-newon-input`}
								value={password}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									e.preventDefault();
									e.stopPropagation();
									setPassword(e.target.value);
								}}
								required={true}
							/>
						</div>}
						{accountType === "Hub" && <div className="sel-newon-content">
							<span>Confirm Password: </span>
							<input
								className={`sel-newon-input`}
								value={confirmPassword}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									e.preventDefault();
									e.stopPropagation();
									setConfirmPassword(e.target.value);
								}}
								required={true}
							/>
						</div>}
						{accountType === "D2C" && <div className="sel-newon-content">
							<span>VAT number (NIF): </span>
							<InputComponent
								value={sellerData?.contactInfo?.vatNumber}
								setValue={setSellerData}
								inputField={"vatNumber"}
								section={"contactInfo"}
							/>
						</div>}

						{accountType === "D2C" && <>

							<div className="sel-newon-content">
								<span>Main Email (registration): </span>
								<input
									className="sel-newon-input"
									value={mainEmail}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setMainEmail(e.target.value)
									}
									required
									type="email"
								/>
							</div>
							<div className="sel-newon-content">
								<span>Partner With: </span>
								<select
									className="filter-select"
									style={{ maxWidth: 270, marginTop: 8, marginBottom: 0 }}
									value={partnerWith}
									onChange={(e) => {
										setPartnerWith(e.target.value as "Zeoos" | "Worten");
									}}
								>
									<option value={"Zeoos"}>
										Zeoos
									</option>
									<option value={"Worten"}>
										Worten
									</option>
								</select>
							</div>
							{/* <div className="sel-newon-content"></div> */}
							<div className="sel-newon-content">
								<span>Email for orders: </span>
								<InputComponent
									value={sellerData?.contactInfo?.emailForOrders}
									setValue={setSellerData}
									inputField={"emailForOrders"}
									section={"contactInfo"}
									type="email"
								/>
							</div>
							{!isTheSameEmail && (
								<div className="sel-newon-content">
									<span>Email for billings: </span>
									<InputComponent
										value={sellerData?.contactInfo?.emailForBillings}
										setValue={setSellerData}
										inputField={"emailForBillings"}
										section={"contactInfo"}
										type="email"
									/>
								</div>
							)}

						</>}
						<div className="sel-newon-content">
							<span>Rate: </span>
							<input
								className={`sel-newon-input`}
								value={rate}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									e.preventDefault();
									e.stopPropagation();
									setRate(e.target.value);
								}}
								required={true}
								type="number"
							/>
						</div>

						{accountType === "Hub" && (
							<div className="sel-newon-content">
								<span>Partner With: </span>
								<select
									className="filter-select"
									style={{ maxWidth: 270, marginTop: 8, marginBottom: 0 }}
									value={partnerWith}
									onChange={(e) => {
										setPartnerWith(e.target.value as "Zeoos" | "Worten");
									}}
								>
									<option value={"Zeoos"}>
										Zeoos
									</option>
									<option value={"Worten"}>
										Worten
									</option>
								</select>
							</div>
						)}

						{accountType === "D2C" &&
							<CheckboxComponent
								value={isTheSameEmail}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setIsTheSameEmail(!isTheSameEmail);

									setSellerData((prev: any) => ({
										...prev,
										contactInfo: {
											...prev.contactInfo,
											emailForBillings:
												!isTheSameEmail && prev.contactInfo.emailForOrders !== ""
													? prev.contactInfo.emailForOrders
													: "",
										},
									}));
								}}
								label="Email for billings is the same as the email for orders"
								boxColor
							/>
						}
					</div>
				</div>
				<div className="sel-newon-cont">
					<div className="sel-newon-title">Fiscal address</div>
					<div className="sel-newon-box">
						<div className="sel-newon-content">
							<span>Address: </span>
							<InputComponent
								value={sellerData?.fiscalAddress?.address}
								setValue={setSellerData}
								inputField={"address"}
								section={"fiscalAddress"}
							/>
						</div>
						{accountType === "Hub" && <div className="sel-newon-content">
							<span>VAT number (NIF): </span>
							<InputComponent
								value={sellerData?.contactInfo?.vatNumber}
								setValue={setSellerData}
								inputField={"vatNumber"}
								section={"contactInfo"}
							/>
						</div>}
						{accountType === "D2C" && <div className="sel-newon-content">
							<span>Complement: </span>
							<InputComponent
								value={sellerData?.fiscalAddress?.complement}
								setValue={setSellerData}
								inputField={"complement"}
								section={"fiscalAddress"}
								required={false}
							/>
						</div>}
						<div className="sel-newon-content">
							<span>City: </span>
							<InputComponent
								value={sellerData?.fiscalAddress?.city}
								setValue={setSellerData}
								inputField={"city"}
								section={"fiscalAddress"}
							/>
						</div>
						<div className="sel-newon-content">
							<span>Country: </span>
							<AllCountrySelect
								value={sellerData?.fiscalAddress?.country}
								setValue={(newValue) => {
									setSellerData((data: any) => ({
										...data,
										fiscalAddress: {
											...data.fiscalAddress,
											country: newValue,
										},
									}));
								}}
							/>
						</div>
						<div className="sel-newon-content">
							<span>Postal Code: </span>
							<InputComponent
								value={sellerData?.fiscalAddress?.postalCode}
								setValue={setSellerData}
								inputField={"postalCode"}
								section={"fiscalAddress"}
								addClass={"maxwidth150"}
							/>
						</div>
						<div className="sel-newon-content">
							<span>Region: </span>
							<InputComponent
								value={sellerData?.fiscalAddress?.region}
								setValue={setSellerData}
								inputField={"region"}
								section={"fiscalAddress"}
							/>
						</div>
					</div>
				</div>
				{accountType === "D2C" && <>
					<CheckboxComponent
						value={isTheSameAddress}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setIsTheSameAddress(!isTheSameAddress);

							setSellerData((prev: any) => ({
								...prev,
								warehouseAddress: {
									...prev.warehouseAddress,
									wAddress: !isTheSameAddress ? prev.fiscalAddress.address : "",
									wComplement: !isTheSameAddress
										? prev.fiscalAddress.complement
										: "",
									wCity: !isTheSameAddress ? prev.fiscalAddress.city : "",
									wCountry: !isTheSameAddress ? prev.fiscalAddress.country : "",
									wPostalCode: !isTheSameAddress
										? prev.fiscalAddress.postalCode
										: "",
									wRegion: !isTheSameAddress ? prev.fiscalAddress.region : "",
								},
							}));
						}}
						label="Warehouse address is the same as the fiscal address"
					/>
					<div className="sel-newon-cont">
						<div className="sel-newon-title">Warehouse address</div>
						<div className="sel-newon-box">
							{!isTheSameAddress && (
								<>
									<div className="sel-newon-content">
										<span>Address: </span>
										<InputComponent
											value={sellerData?.warehouseAddress?.wAddress}
											setValue={setSellerData}
											inputField={"wAddress"}
											section={"warehouseAddress"}
										/>
									</div>
									<div className="sel-newon-content">
										<span>Complement: </span>
										<InputComponent
											value={sellerData?.warehouseAddress?.wComplement}
											setValue={setSellerData}
											inputField={"wComplement"}
											section={"warehouseAddress"}
											required={false}
										/>
									</div>
									<div className="sel-newon-content">
										<span>City: </span>
										<InputComponent
											value={sellerData?.warehouseAddress?.wCity}
											setValue={setSellerData}
											inputField={"wCity"}
											section={"warehouseAddress"}
										/>
									</div>
									<div className="sel-newon-content">
										<span>Country: </span>
										<AllCountrySelect
											value={sellerData?.warehouseAddress?.wCountry}
											setValue={(newValue) => {
												setSellerData((data: any) => ({
													...data,
													warehouseAddress: {
														...data.fiscalAddress,
														wCountry: newValue,
													},
												}));
											}}
										/>
									</div>
									<div className="sel-newon-content">
										<span>Postal Code: </span>
										<InputComponent
											value={sellerData?.warehouseAddress?.wPostalCode}
											setValue={setSellerData}
											inputField={"wPostalCode"}
											section={"warehouseAddress"}
											addClass={"maxwidth150"}
										/>
									</div>
									<div className="sel-newon-content">
										<span>Region: </span>
										<InputComponent
											value={sellerData?.warehouseAddress?.wRegion}
											setValue={setSellerData}
											inputField={"wRegion"}
											section={"warehouseAddress"}
										/>
									</div>
								</>
							)}
							<div className="sel-newon-content">
								<span>Warehouse contact: </span>
								<InputComponent
									value={sellerData?.warehouseAddress?.wContact}
									setValue={setSellerData}
									inputField={"wContact"}
									section={"warehouseAddress"}
								/>
							</div>
							<div className="sel-newon-content">
								<span>Warehouse phone: </span>
								<InputComponent
									value={sellerData?.warehouseAddress?.wPhone}
									setValue={setSellerData}
									inputField={"wPhone"}
									section={"warehouseAddress"}
								/>
							</div>
						</div>
					</div>
					<div className="sel-newon-cont">
						<div className="sel-newon-title">Bank information</div>
						<div className="sel-newon-box">
							<div className="sel-newon-content">
								<span>Bank name: </span>
								<InputComponent
									value={sellerData?.bankInfo?.bankName}
									setValue={setSellerData}
									inputField={"bankName"}
									section={"bankInfo"}
									disabled={seller?.bankInfoConfirmed}
								/>
							</div>
							<div className="sel-newon-content"></div>
							<div className="sel-newon-content">
								<span>IBAN: </span>
								<InputComponent
									value={sellerData?.bankInfo?.iban}
									setValue={setSellerData}
									inputField={"iban"}
									section={"bankInfo"}
									disabled={seller?.bankInfoConfirmed}
								/>
							</div>
							<div className="sel-newon-content">
								<span>Swift code: </span>
								<InputComponent
									value={sellerData?.bankInfo?.swiftCode}
									setValue={setSellerData}
									inputField={"swiftCode"}
									section={"bankInfo"}
									disabled={seller?.bankInfoConfirmed}
								/>
							</div>
						</div>
					</div>
				</>}
				<div
					style={{ maxWidth: "740px" }}
					className="width100 in-row align-center justify-between"
				>
					<div className="in-row align-center">
						<button
							style={{ background: "transparent" }}
							className="sel-modal-form-button-cancel"
							onClick={() => setModal(false)}
						>
							Discard
						</button>

						{seller && (
							<button style={{ background: "transparent" }} className="sel-modal-form-button-cancel" onClick={onDeleteSeller}>
								Delete
							</button>
						)}
					</div>

					<button className="table-modal-form-button" type="submit" disabled={loading}>
						SAVE
					</button>
				</div>
			</div>
		</form>

	);
};

interface IInputProps {
	value: string | undefined;
	setValue: (value: any) => void;
	inputField: string;
	section: string;
	addClass?: string;
	required?: boolean;
	disabled?: boolean;
	type?: string;
}

export const InputComponent: React.FC<IInputProps> = ({
	value,
	setValue,
	inputField,
	section,
	addClass,
	required = true,
	disabled = false,
	type,
}) => {
	const handleChange = useCallback(
		(newValue: string) =>
			setValue((prev: any) => ({
				...prev,
				[section]: {
					...prev[section],
					[inputField]: newValue,
				},
			})),
		[setValue, section, inputField]
	);

	return (
		<input
			className={`sel-newon-input ${addClass}`}
			value={value}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				e.preventDefault();
				e.stopPropagation();

				handleChange(e.target.value);
			}}
			required={required}
			disabled={disabled}
			type={type}
		/>
	);
};

interface IChecboxProps {
	value: boolean;
	onChange: (value: any) => void;
	label: string;
	boxColor?: boolean;
}

const CheckboxComponent: React.FC<IChecboxProps> = ({
	value,
	onChange,
	label,
	boxColor,
}) => {
	return (
		<div
			style={{ maxWidth: "740px" }}
			className={`in-row width100 align-center email-text ${boxColor && "ml-0"
				}`}
		>
			<div className="mr2">
				<Checkbox
					sx={{
						color: `${boxColor ? "#5a5a5a" : "#fff"}`,
						"&.Mui-checked": {
							color: "#55718A",
						},
					}}
					className="check_delivery"
					value={value}
					onChange={onChange}
				/>
			</div>
			{label}
		</div>
	);
};

const COUNTRIES = [
	{ name: "Afghanistan", code: "AF" },
	{ name: "Åland Islands", code: "AX" },
	{ name: "Albania", code: "AL" },
	{ name: "Algeria", code: "DZ" },
	{ name: "American Samoa", code: "AS" },
	{ name: "Andorra", code: "AD" },
	{ name: "Angola", code: "AO" },
	{ name: "Anguilla", code: "AI" },
	{ name: "Antarctica", code: "AQ" },
	{ name: "Antigua & Barbuda", code: "AG" },
	{ name: "Argentina", code: "AR" },
	{ name: "Armenia", code: "AM" },
	{ name: "Aruba", code: "AW" },
	{ name: "Australia", code: "AU" },
	{ name: "Austria", code: "AT" },
	{ name: "Azerbaijan", code: "AZ" },
	{ name: "Bahamas", code: "BS" },
	{ name: "Bahrain", code: "BH" },
	{ name: "Bangladesh", code: "BD" },
	{ name: "Barbados", code: "BB" },
	{ name: "Belarus", code: "BY" },
	{ name: "Belgium", code: "BE" },
	{ name: "Belize", code: "BZ" },
	{ name: "Benin", code: "BJ" },
	{ name: "Bermuda", code: "BM" },
	{ name: "Bhutan", code: "BT" },
	{ name: "Bolivia", code: "BO" },
	{ name: "Caribbean Netherlands", code: "BQ" },
	{ name: "Bosnia & Herzegovina", code: "BA" },
	{ name: "Botswana", code: "BW" },
	{ name: "Bouvet Island", code: "BV" },
	{ name: "Brazil", code: "BR" },
	{ name: "British Indian Ocean Territory", code: "IO" },
	{ name: "Brunei", code: "BN" },
	{ name: "Bulgaria", code: "BG" },
	{ name: "Burkina Faso", code: "BF" },
	{ name: "Burundi", code: "BI" },
	{ name: "Cambodia", code: "KH" },
	{ name: "Cameroon", code: "CM" },
	{ name: "Canada", code: "CA" },
	{ name: "Cape Verde", code: "CV" },
	{ name: "Cayman Islands", code: "KY" },
	{ name: "Central African Republic", code: "CF" },
	{ name: "Chad", code: "TD" },
	{ name: "Chile", code: "CL" },
	{ name: "China", code: "CN" },
	{ name: "Christmas Island", code: "CX" },
	{ name: "Cocos (Keeling) Islands", code: "CC" },
	{ name: "Colombia", code: "CO" },
	{ name: "Comoros", code: "KM" },
	{ name: "Congo - Brazzaville", code: "CG" },
	{ name: "Congo - Kinshasa", code: "CD" },
	{ name: "Cook Islands", code: "CK" },
	{ name: "Costa Rica", code: "CR" },
	{ name: "Côte d’Ivoire", code: "CI" },
	{ name: "Croatia", code: "HR" },
	{ name: "Cuba", code: "CU" },
	{ name: "Curaçao", code: "CW" },
	{ name: "Cyprus", code: "CY" },
	{ name: "Czechia", code: "CZ" },
	{ name: "Denmark", code: "DK" },
	{ name: "Djibouti", code: "DJ" },
	{ name: "Dominica", code: "DM" },
	{ name: "Dominican Republic", code: "DO" },
	{ name: "Ecuador", code: "EC" },
	{ name: "Egypt", code: "EG" },
	{ name: "El Salvador", code: "SV" },
	{ name: "Equatorial Guinea", code: "GQ" },
	{ name: "Eritrea", code: "ER" },
	{ name: "Estonia", code: "EE" },
	{ name: "Ethiopia", code: "ET" },
	{ name: "Falkland Islands (Islas Malvinas)", code: "FK" },
	{ name: "Faroe Islands", code: "FO" },
	{ name: "Fiji", code: "FJ" },
	{ name: "Finland", code: "FI" },
	{ name: "France", code: "FR" },
	{ name: "French Guiana", code: "GF" },
	{ name: "French Polynesia", code: "PF" },
	{ name: "French Southern Territories", code: "TF" },
	{ name: "Gabon", code: "GA" },
	{ name: "Gambia", code: "GM" },
	{ name: "Georgia", code: "GE" },
	{ name: "Germany", code: "DE" },
	{ name: "Ghana", code: "GH" },
	{ name: "Gibraltar", code: "GI" },
	{ name: "Greece", code: "GR" },
	{ name: "Greenland", code: "GL" },
	{ name: "Grenada", code: "GD" },
	{ name: "Guadeloupe", code: "GP" },
	{ name: "Guam", code: "GU" },
	{ name: "Guatemala", code: "GT" },
	{ name: "Guernsey", code: "GG" },
	{ name: "Guinea", code: "GN" },
	{ name: "Guinea-Bissau", code: "GW" },
	{ name: "Guyana", code: "GY" },
	{ name: "Haiti", code: "HT" },
	{ name: "Heard & McDonald Islands", code: "HM" },
	{ name: "Vatican City", code: "VA" },
	{ name: "Honduras", code: "HN" },
	{ name: "Hong Kong", code: "HK" },
	{ name: "Hungary", code: "HU" },
	{ name: "Iceland", code: "IS" },
	{ name: "India", code: "IN" },
	{ name: "Indonesia", code: "ID" },
	{ name: "Iran", code: "IR" },
	{ name: "Iraq", code: "IQ" },
	{ name: "Ireland", code: "IE" },
	{ name: "Isle of Man", code: "IM" },
	{ name: "Israel", code: "IL" },
	{ name: "Italy", code: "IT" },
	{ name: "Jamaica", code: "JM" },
	{ name: "Japan", code: "JP" },
	{ name: "Jersey", code: "JE" },
	{ name: "Jordan", code: "JO" },
	{ name: "Kazakhstan", code: "KZ" },
	{ name: "Kenya", code: "KE" },
	{ name: "Kiribati", code: "KI" },
	{ name: "North Korea", code: "KP" },
	{ name: "South Korea", code: "KR" },
	{ name: "Kosovo", code: "XK" },
	{ name: "Kuwait", code: "KW" },
	{ name: "Kyrgyzstan", code: "KG" },
	{ name: "Laos", code: "LA" },
	{ name: "Latvia", code: "LV" },
	{ name: "Lebanon", code: "LB" },
	{ name: "Lesotho", code: "LS" },
	{ name: "Liberia", code: "LR" },
	{ name: "Libya", code: "LY" },
	{ name: "Liechtenstein", code: "LI" },
	{ name: "Lithuania", code: "LT" },
	{ name: "Luxembourg", code: "LU" },
	{ name: "Macao", code: "MO" },
	{ name: "North Macedonia", code: "MK" },
	{ name: "Madagascar", code: "MG" },
	{ name: "Malawi", code: "MW" },
	{ name: "Malaysia", code: "MY" },
	{ name: "Maldives", code: "MV" },
	{ name: "Mali", code: "ML" },
	{ name: "Malta", code: "MT" },
	{ name: "Marshall Islands", code: "MH" },
	{ name: "Martinique", code: "MQ" },
	{ name: "Mauritania", code: "MR" },
	{ name: "Mauritius", code: "MU" },
	{ name: "Mayotte", code: "YT" },
	{ name: "Mexico", code: "MX" },
	{ name: "Micronesia", code: "FM" },
	{ name: "Moldova", code: "MD" },
	{ name: "Monaco", code: "MC" },
	{ name: "Mongolia", code: "MN" },
	{ name: "Montenegro", code: "ME" },
	{ name: "Montserrat", code: "MS" },
	{ name: "Morocco", code: "MA" },
	{ name: "Mozambique", code: "MZ" },
	{ name: "Myanmar (Burma)", code: "MM" },
	{ name: "Namibia", code: "NA" },
	{ name: "Nauru", code: "NR" },
	{ name: "Nepal", code: "NP" },
	{ name: "Netherlands", code: "NL" },
	{ name: "Curaçao", code: "AN" },
	{ name: "New Caledonia", code: "NC" },
	{ name: "New Zealand", code: "NZ" },
	{ name: "Nicaragua", code: "NI" },
	{ name: "Niger", code: "NE" },
	{ name: "Nigeria", code: "NG" },
	{ name: "Niue", code: "NU" },
	{ name: "Norfolk Island", code: "NF" },
	{ name: "Northern Mariana Islands", code: "MP" },
	{ name: "Norway", code: "NO" },
	{ name: "Oman", code: "OM" },
	{ name: "Pakistan", code: "PK" },
	{ name: "Palau", code: "PW" },
	{ name: "Palestine", code: "PS" },
	{ name: "Panama", code: "PA" },
	{ name: "Papua New Guinea", code: "PG" },
	{ name: "Paraguay", code: "PY" },
	{ name: "Peru", code: "PE" },
	{ name: "Philippines", code: "PH" },
	{ name: "Pitcairn Islands", code: "PN" },
	{ name: "Poland", code: "PL" },
	{ name: "Portugal", code: "PT" },
	{ name: "Puerto Rico", code: "PR" },
	{ name: "Qatar", code: "QA" },
	{ name: "Réunion", code: "RE" },
	{ name: "Romania", code: "RO" },
	{ name: "Russia", code: "RU" },
	{ name: "Rwanda", code: "RW" },
	{ name: "St. Barthélemy", code: "BL" },
	{ name: "St. Helena", code: "SH" },
	{ name: "St. Kitts & Nevis", code: "KN" },
	{ name: "St. Lucia", code: "LC" },
	{ name: "St. Martin", code: "MF" },
	{ name: "St. Pierre & Miquelon", code: "PM" },
	{ name: "St. Vincent & Grenadines", code: "VC" },
	{ name: "Samoa", code: "WS" },
	{ name: "San Marino", code: "SM" },
	{ name: "São Tomé & Príncipe", code: "ST" },
	{ name: "Saudi Arabia", code: "SA" },
	{ name: "Senegal", code: "SN" },
	{ name: "Serbia", code: "RS" },
	{ name: "Serbia", code: "CS" },
	{ name: "Seychelles", code: "SC" },
	{ name: "Sierra Leone", code: "SL" },
	{ name: "Singapore", code: "SG" },
	{ name: "Sint Maarten", code: "SX" },
	{ name: "Slovakia", code: "SK" },
	{ name: "Slovenia", code: "SI" },
	{ name: "Solomon Islands", code: "SB" },
	{ name: "Somalia", code: "SO" },
	{ name: "South Africa", code: "ZA" },
	{ name: "South Georgia & South Sandwich Islands", code: "GS" },
	{ name: "South Sudan", code: "SS" },
	{ name: "Spain", code: "ES" },
	{ name: "Sri Lanka", code: "LK" },
	{ name: "Sudan", code: "SD" },
	{ name: "Suriname", code: "SR" },
	{ name: "Svalbard & Jan Mayen", code: "SJ" },
	{ name: "Eswatini", code: "SZ" },
	{ name: "Sweden", code: "SE" },
	{ name: "Switzerland", code: "CH" },
	{ name: "Syria", code: "SY" },
	{ name: "Taiwan", code: "TW" },
	{ name: "Tajikistan", code: "TJ" },
	{ name: "Tanzania", code: "TZ" },
	{ name: "Thailand", code: "TH" },
	{ name: "Timor-Leste", code: "TL" },
	{ name: "Togo", code: "TG" },
	{ name: "Tokelau", code: "TK" },
	{ name: "Tonga", code: "TO" },
	{ name: "Trinidad & Tobago", code: "TT" },
	{ name: "Tunisia", code: "TN" },
	{ name: "Turkey", code: "TR" },
	{ name: "Turkmenistan", code: "TM" },
	{ name: "Turks & Caicos Islands", code: "TC" },
	{ name: "Tuvalu", code: "TV" },
	{ name: "Uganda", code: "UG" },
	{ name: "Ukraine", code: "UA" },
	{ name: "United Arab Emirates", code: "AE" },
	{ name: "United Kingdom", code: "GB" },
	{ name: "United States", code: "US" },
	{ name: "U.S. Outlying Islands", code: "UM" },
	{ name: "Uruguay", code: "UY" },
	{ name: "Uzbekistan", code: "UZ" },
	{ name: "Vanuatu", code: "VU" },
	{ name: "Venezuela", code: "VE" },
	{ name: "Vietnam", code: "VN" },
	{ name: "British Virgin Islands", code: "VG" },
	{ name: "U.S. Virgin Islands", code: "VI" },
	{ name: "Wallis & Futuna", code: "WF" },
	{ name: "Western Sahara", code: "EH" },
	{ name: "Yemen", code: "YE" },
	{ name: "Zambia", code: "ZM" },
	{ name: "Zimbabwe", code: "ZW" },
];

// eslint-disable-next-line
const COUNTRY_CODES_TO_CHECK = [
	"AL",
	"AR",
	"AT",
	"AU",
	"BE",
	"BG",
	"BO",
	"BR",
	"BY",
	"CA",
	"CH",
	"CL",
	"CO",
	"CR",
	"CY",
	"CZ",
	"DE",
	"DK",
	"DO",
	"EC",
	"EE",
	"EL",
	"ES",
	"FI",
	"FR",
	"GB",
	"GT",
	"HN",
	"HR",
	"HU",
	"ID",
	"IE",
	"IL",
	"IN",
	"IS",
	"IT",
	"KZ",
	"LT",
	"LU",
	"LV",
	"MK",
	"MT",
	"MX",
	"NG",
	"NI",
	"NL",
	"NO",
	"NZ",
	"PA",
	"PE",
	"PH",
	"PL",
	"PT",
	"PY",
	"RO",
	"RS",
	"RU",
	"SA",
	"SE",
	"SI",
	"SK",
	"SM",
	"SV",
	"TR",
	"UA",
	"UY",
	"UZ",
	"VE",
];

export const AllCountrySelect: React.FC<{
	value?: string;
	setValue: (newValue: string) => void;
}> = ({ value, setValue }) => {
	return (
		<select
			className="filter-select"
			style={{ maxWidth: 270, marginTop: 8, marginBottom: 0 }}
			value={value}
			onChange={(e) => {
				setValue(e.target.value);
			}}
		>
			<option value={""}>Select country</option>
			{COUNTRIES.map((item) => (
				<option value={item.name} key={item.code}>
					{item.name}
				</option>
			))}
		</select>
	);
};

export default SellerCreateEditModal;
