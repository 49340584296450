import React from "react";
import { Modal } from "../../ui/modal/Modal";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { downloadPlatformRates, updatePlatformRatesWithFile } from "../../../actions/marketplace";

interface Props {
  modal: boolean;
  setModal: (modal: boolean) => void;
  onModalClose: any;
  marketplace: any
}

export const ImportRates: React.FC<Props> = ({
  modal,
  setModal,
  onModalClose,
  marketplace,
}) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
      accept: ['.xlsx'],
      onDropRejected: () => {
          toast.error('File type rejected. Only ".xlsx" files are allowed.');
      },
      maxFiles: 1
  });

  const dropzone = (
      <div className='import-reviews-dropzone minheight100' {...getRootProps()}>
          <input {...getInputProps()} />
          {
              isDragActive ? <h4>Drop the files here ...</h4> : (
                  <h4 className="review-button">
                      {acceptedFiles.length > 0 ? `Click 'Import' to finish upload ${acceptedFiles[0].name}` : 'Drop the file here'}
                  </h4>
              )
          }
      </div>
  );

  const handleUpload = async (updateFile: File, marketplaceIntegration: string) => {
    try {
      await updatePlatformRatesWithFile({updateFile}, marketplaceIntegration)

      setModal(false)
      toast.success('Successfully uploaded!');
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal onModalClose={onModalClose} isOpened={modal}>
        <div
            style={{
                width: '520px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
          <h3 className="create-heading">Import rate to {marketplace.zeoosName}</h3>
          <div className="table-border-bottom pb2"></div>
            <p className="product-p mt4">
                Upload the file
                <span className="profile-popup-subtitle ml1">
                    (Allowed file format .xlsx)
                </span>
            </p>
            <div className='width100 height100'>
                {dropzone}
            </div>
            <span
                className="download-button-text p1 pointer"
                onClick={async () => {
                  await downloadPlatformRates(marketplace.marketplaceIntegration, marketplace.zeoosName);
                }}
            >
                Download {marketplace.zeoosName} rate spreadsheet
            </span>
          <div className="table-modal-border"></div>
          <div className="table-modal-form-button-box width100 mb0">
            <button
              className="table-modal-form-button-cancel"
              onClick={() => setModal(false)}
            >
              Cancel
            </button>
            <button
              className="table-modal-form-button"
              onClick={() => {
                if (acceptedFiles.length) {
                  handleUpload(acceptedFiles[0], marketplace.marketplaceIntegration)
                } else {
                  toast.error('File is not selected!');
                }
              }}
            >
              Upload
            </button>
          </div>
        </div>
    </Modal>
  );
};
