import React, { useState } from "react";
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom";
import { InfoSection } from "./InfoSection";
import { TableSection } from "./TableSection";
import { CreateEditMarketplaceForm } from "../marketplaces/CreateEditMplc";
import Preloader from "../../ui/preloader/Preloader";
import "./singlemarketplace.css";
import useActiveVendors from "../../../hooks/useActiveVendors";
import { useMemo } from "react";
import useMarketplace from "../../../hooks/useMarketplace";
import { IPaginatedRequest } from "../../../interfaces";
import { useDebouncedValue } from "@mantine/hooks";
import queryString from "query-string";
import { getProductsFromPlatform } from "../../../actions/marketplace";
import { useQuery } from "react-query";

type TParams = { zeoosName: string };

const Singlemarketplace: React.FC<RouteComponentProps<TParams>> = (props) => {
  const { zeoosName } = props.match.params;
  const history = useHistory();

  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    seller: "All vendors",
    perPage: 50,
    page: 1,
    search: "",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

  const key = useMemo(() => {
    return `products?${queryString.stringify(debouncedConfig)}`;
  }, [debouncedConfig]);

  const { isLoading, data: products } = useQuery(key, () =>
    getProductsFromPlatform(zeoosName, currentConfig)
  );

  const vendors = useActiveVendors();
  const marketplace = useMarketplace(zeoosName);
  const loading = useMemo(
    () => vendors.isLoading || marketplace.isLoading,
    [vendors.isLoading, marketplace.isLoading]
  );

  const onEditClick = () => {
    history.push(`/platforms/create-edit?action=edit&&platformName=${zeoosName}`);
  };
  return loading ? (
    <div className="center-loader">
      <Preloader />
    </div>
  ) : (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont">
          <InfoSection
            marketplace={marketplace.data!}
            setOpenEdit={onEditClick}
            vendors={vendors.data}
            setCurrentConfig={setCurrentConfig}
            currentConfig={currentConfig}
          />
          <TableSection
            products={products}
            marketplace={marketplace}
            setCurrentConfig={setCurrentConfig}
            currentConfig={currentConfig}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Singlemarketplace);
