import React, { useState } from "react";
import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
import { Modal } from "../../ui/modal/Modal";

export default function VendorsTable() {
  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <div className="table-main-cont">
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search marketplace"
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-main-title-text pl2">53 VENDORS</div>
          </div>
        </div>
        <div className="marketplace-pagination"></div>
      </div>
      <div className="table-overflow table-vendors-height">
        <table
          style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
        >
          <tr
            style={{ marginTop: "-5px", width: "100%" }}
            className="table-results-title"
          >
            <th className="vendors-mapping-title">Vendor</th>
            <th className="table-border-right"></th>
            <th className="vendors-column">Amazon ES</th>
            <th className="vendors-column">FNAC PT</th>
          </tr>
        </table>
        <table className="table-results mb2" style={{ minHeight: "auto" }}>
          <tbody>
            <tr className="table-result-box height100px">
              <td className="vendors-mapping-title">
                <div className="vendors-title-image"></div>
                <div className="email-text">
                  <div className="">
                    CA&F, LDA - Cabral de Almeida & Fonseca, Lda.
                  </div>
                </div>
              </td>
              <td className="table-border-right"></td>
              <td
                onClick={() => {
                  setModal(true);
                }}
                className="vendors-column"
              >
                <AntSwitch />
                <span className="vendor-text mt1">
                  <span className="primary-color">113&ensp;</span> / 125
                </span>
              </td>
              <td
                onClick={() => {
                  setModal(true);
                }}
                className="vendors-column"
              >
                <AntSwitch />
                <span className="vendor-text mt1">
                  <span className="primary-color">113&ensp;</span> / 125
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <div className="table-modal-form-cont p4">
            <div className="updateForm__container">
              <div className="vendor-mapping-modal-text">
                Are you sure you want to enable
                <br /> <strong>Direct Wine</strong> on <strong>Amazon</strong>{" "}
                ES ?
              </div>
              <div className="table-modal-border"></div>
              <div className="table-modal-form-button-box width100 m0">
                <button className="table-modal-form-button-cancel">
                  Cancel
                </button>
                <button className="table-modal-form-button" type="submit">
                  SET
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
