import React from "react";
import "./businessIntelligence.css";
import { OrderDetails } from "./OrderDetails";
import { LineChart } from "./LineChart";
import { PSPChart } from "./PSPChart";
import { Top5Chart } from "./Top5Chart";
import { columnsB2C } from "./B2CMarketplace";
import { ICombinedOrders, ICombinedOrder } from "../../../interfaces";

interface Props {
  orders: ICombinedOrders;
  ordersWaiting?: number;
  country: string;
  marketplaceMarket: string;
  marketplaceAccount: string;
  category: string;
  avgOrderCounter: (array: ICombinedOrder[]) => string | number;
  grossValueCounter: (array: ICombinedOrder[]) => string | number;
}

export const General: React.FC<Props> = ({
  orders,
  ordersWaiting,
  country,
  category,
  avgOrderCounter,
  grossValueCounter,
}) => {
  function createArray(obj: ICombinedOrders) {
    return [...obj.b2b, ...obj.b2c, ...obj.flash];
  }

  // function moneyCounter(array: ICombinedOrder[]) {
  // 	return array
  // 		.reduce(
  // 			(prev: number, next: ICombinedOrder) => prev + next.sale_amount,
  // 			0
  // 		)
  // 		.toLocaleString("de-DE", {
  // 			minimumFractionDigits: 2,
  // 			maximumFractionDigits: 2,
  // 		});
  // }

  const filteredByCountry =
    country === "All Countries"
      ? createArray(orders)
      : createArray(orders).filter(
          (x: ICombinedOrder) => x.country === country
        );

  const filteredByCategory =
    category === "All Categories"
      ? createArray(orders).flatMap((x: ICombinedOrder) => x.sale_line_ids)
      : createArray(orders)
          .flatMap((x: ICombinedOrder) => x.sale_line_ids)
          .filter((x: any) => x.category_id === category);

  // const totalMoneyMade = moneyCounter(filteredByCountry);
  const totalOrders = filteredByCountry.length.toLocaleString("de-DE");
  const avgOrderValue = avgOrderCounter(filteredByCountry);
  const grossValue = grossValueCounter(filteredByCountry);

  return (
    <>
      <div className="bi__cards_group">
        {/* <div className="bi__card">
          <div className="bi__card_title">GMV (Gross Merchandise Value)</div>
          <div className="bi__card_content_avg">
            <div>
              <span>{totalMoneyMade} </span>
              <span className="bi__card_content_avg_currency">€</span>
            </div>
          </div>
        </div> */}
        <OrderDetails
          orders={filteredByCountry}
          ordersWaiting={ordersWaiting}
          totalOrders={totalOrders}
          columns={columnsB2C}
        />
        <div className="bi__card">
          <div className="bi__card_title">GMV</div>
          <div className="bi__card_content_avg">
            <div>
              <span>{grossValue} </span>
              <span className="bi__card_content_avg_currency">€</span>
            </div>
          </div>
        </div>
        <div className="bi__card">
          <div className="bi__card_title">Average Order Value</div>
          <div className="bi__card_content_avg">
            <div>
              <span>{avgOrderValue} </span>
              <span className="bi__card_content_avg_currency">€</span>
            </div>
          </div>
        </div>
        <LineChart array={filteredByCountry} />
        <Top5Chart
          orders={filteredByCountry}
          orders2={filteredByCategory}
          category={category}
        />
        <PSPChart ordersList={filteredByCountry} />
      </div>
    </>
  );
};
