import React, { useEffect, useState } from "react";
import { Screen } from "../../ui/screen/Screen";
import "./businessIntelligence.css";
import { Cards } from "./Cards";
import Preloader from "../../ui/preloader/Preloader";
import { ICombinedOrders, Role } from "../../../interfaces";
import moment from "moment";
import { FilterSection } from "./FilterSection";
import { SelectSection } from "./SelectSection";
import { DateSelect } from "./DateSelect";
import { useOrders } from "../../../hooks/useOrders";
import useActiveVendors from "../../../hooks/useActiveVendors";
import RoleRequired from "../../utils/role-required/RoleRequired";
import OfferImportUploadedReportModal from "../../ui/offer-import-uploaded-report-modal";
import { useClickOutside } from "@mantine/hooks";

const BusinessIntelligence = () => {
  const [section, setSection] = useState<string>("General");
  const [country, setCountry] = useState<string>("All Countries");
  const [warehouse, setWarehouse] = useState<string>("All warehouses");
  const [category, setCategory] = useState<string>("All Categories");
  const [brand, setBrand] = useState<string>("All brands");

  const [marketplaceMarket, setMarketplaceMarket] =
    useState<string>("All Webstores");
  const [marketplaceAccount, setMarketplaceAccount] =
    useState<string>("All Marketplaces");

  const [date, setDate] = useState<string>("This Month");
  const [from, setDateFrom] = useState<Date | number>(
    Date.parse(`${moment().startOf("months")}`)
  );
  const [to, setDateTo] = useState<Date | number>(new Date().valueOf());
  const [filteredOrdersB2C, setflteredOrdersB2C] = useState<ICombinedOrders[]>(
    []
  );
  const [open, setOpen] = useState(false);
  const dropdownRef = useClickOutside(() => {
    if (open) {
      setOpen(false);
    }
  });

  const orders = useOrders(from, to);
  const activeVendors = useActiveVendors();

  return (
    <div className="main-body">
      <RoleRequired role={Role.user}>
        <OfferImportUploadedReportModal />
      </RoleRequired>

      <div className="main-container">
        {!activeVendors.isLoading && !orders.isLoading && !!orders.data ? (
          <>
            <div className="bi-header-cont">
              <div className="bi-title-cont">
                <SelectSection section={section} setSection={setSection} />
                <div className="bi-filters-cont">
                  <FilterSection
                    vendors={activeVendors.data}
                    from={from}
                    to={to}
                    section={section}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    setDate={setDate}
                    setCountry={setCountry}
                    country={country}
                    warehouse={warehouse}
                    setWarehouse={setWarehouse}
                    category={category}
                    setCategory={setCategory}
                    brand={brand}
                    setBrand={setBrand}
                    setMarketplaceMarket={setMarketplaceMarket}
                    marketplaceMarket={marketplaceMarket}
                    setMarketplaceAccount={setMarketplaceAccount}
                    marketplaceAccount={marketplaceAccount}
                    orders={orders.data!}
                    setflteredOrders={setflteredOrdersB2C}
                    date={date}
                  />
                <div className="app-drop-down-container ml2" ref={dropdownRef}>
                  <button
                    style={{ border: "1px solid #000", width: "120px" }}
                    className="main-select-bi no-shadow p1"
                    onClick={() => setOpen((p) => !p)}
                  >
                    <img src="/icons/not-set-dotts.svg" alt="" />
                    <span className="email-text">More</span>
                    <img width="14px" src="/icons/down-arrow.png" alt="" />
                  </button>
				  {open && (
              <ul
                style={{
                  marginLeft: "-80px",
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.50)",
                  borderRadius: "5px",
                }}
              >
               
                <li
                  style={{ padding: "10px 5px", width: "200px" }}
                  className="in-row align-center not-menuitem-cont"
                >
                  <span className="not-menuitem-text">Open Google Looker</span>
                </li>
                <li
                  style={{ padding: "10px 5px", width: "200px" }}
                  className="in-row align-center not-menuitem-cont"
                >
                  <span className="not-menuitem-text">Open Google Spreadsheet</span>
                </li>

   
              </ul>
            )}
                </div>
                </div>
              </div>
            </div>
            <Cards
              orders={orders.data!}
              ordersB2C={filteredOrdersB2C}
              section={section}
              country={country}
              warehouse={warehouse}
              category={category}
              brand={brand}
              marketplaceMarket={marketplaceMarket}
              marketplaceAccount={marketplaceAccount}
            />
          </>
        ) : (
          <Preloader />
        )}
      </div>
    </div>
  );
};

export default function WrapBI() {
  const [timeInterval, setTimeInterval] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeInterval((timeInterval) => timeInterval + 1);
    }, 900000);
    return () => {
      setTimeout(() => {
        setTimeInterval(0);
      }, 15000);
      clearInterval(interval);
    };
  }, [timeInterval]);

  return timeInterval ? <Screen /> : <BusinessIntelligence />;
}
