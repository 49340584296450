import React, { useState } from "react";
import * as _ from "lodash";
import moment from "moment";
import { Modal } from "../../ui/modal/Modal";
import { ICombinedOrder } from "../../../interfaces";

const { Line } = require("react-chartjs-2");

interface Props {
  array: ICombinedOrder[];
}

interface ILine {
  labels: Array<string>;
  data: Array<number>;
}

export const LineChart: React.FC<Props> = ({ array }) => {
  const [modal, setModal] = useState<boolean>(false);

  function getReport() {
    const orderGroups = _.groupBy(
      _.orderBy(array,['sale_date'],['asc']).map((x: ICombinedOrder) => ({
        ...x,
        sale_date: moment(x.sale_date).format("MMM Do YY"),
      })),
      "sale_date"
    );
    const labels = Object.keys(orderGroups);
    const data = labels.map((x) =>
      orderGroups[x].reduce(
        (prev: number, next: ICombinedOrder) => prev + next.sale_amount,
        0
      )
    );

    return { labels, data };
  }

  const { labels, data } = getReport();

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <div
      className="bi__card"
      onClick={() => {
        setModal(true);
      }}
    >
      <div className="bi__card_title">Sales</div>
      <div className="bi__card_content">
        <LineChartComponent labels={labels} data={data} />
        {modal && (
          <Modal onModalClose={onModalClose} isOpened={modal}>
            <div className="modal-width">
              <LineChartComponent labels={labels} data={data} />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export const LineChartComponent: React.FC<ILine> = ({ labels, data }) => {
  return (
    <Line
      type=""
      data={{
        labels,
        datasets: [
          {
            label: "Revenue",
            data,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
        ],
      }}
      height={280}
      width={430}
      options={{ maintainAspectRatio: false }}
    />
  );
};
