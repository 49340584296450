import React, { useState } from "react";
import { updateCountry } from "../../../actions/countryManagement";
import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
import { ICountryM } from "./CountryManagement";

interface Props {
  country: ICountryM;
}

export const LangToggle: React.FC<Props> = ({ country }) => {
  const [state, setState] = useState({
    checkedC: country.isLang,
  });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    await updateCountry({ ...country, isLang: event.target.checked });
  };

  return (
    <td className="cm-reg">
      <AntSwitch
        checked={state.checkedC}
        onChange={handleChange}
        name="checkedC"
        required
      />
    </td>
  );
};
