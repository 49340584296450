import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface Props extends React.PropsWithChildren, LinkProps {
  condition: boolean;
}

const ConditionalLink: React.FC<Props> = ({
  condition,
  children,
  className,
  ...props
}) => {
  if (condition) {
    return (
      <Link className={className} {...props}>
        {children}
      </Link>
    );
  }

  return <div className={className}>{children}</div>;
};

export default ConditionalLink;
