import React, { useCallback, useState } from "react";
import "./pim-translation.css";
import { useTheme, withStyles } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SwipeableViews from "react-swipeable-views";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { SchemeContentTrnl } from "./SchemeContentTrnl";

interface Props {
  schemes: any;
  setTrlnScheme: any;
  lang: string;
  search: string;
}

const AntTabs = withStyles({
  root: {
    border: "none !important",
    textDecoration: "none !important",
    minHeight: "60px !important",
  },
  indicator: {
    backgroundColor: "#fff",
    overflow: "auto",
  },
})(Tabs);

const AntTab = withStyles({
  root: {
    overflow: "auto !important",
    minWidth: "200px !important",
    opacity: "1",
    textTransform: "none",
    minHeight: "60px !important",
  },
  selected: {
    borderBottom: "1px solid #5a5a5a !important",
    color: "#292929 !important",
  },
})(Tab);

function TabsPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const SchemeTabsTrnl: React.FC<Props> = ({
  schemes,
  setTrlnScheme,
  lang,
  search,
}) => {
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const schemeLabels = () => {
    return schemes?.map((item: any) => item.name);
  };

  const antTab = useCallback((arr: any) => {
    return arr.map((tab: any) => tab.name);
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const tabData = (name: string) => {
    return schemes.find((item: any) => item.name === name);
  };

  return (
    <div className="trnl-tabs-container">
      <AppBar className="trnl-app-bar" position="static" color="default">
        <AntTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className="trnl-tabs-cont"
        >
          {antTab(schemes).map((tab: string, index: number) => (
            <AntTab
              className="trnl-tab-box"
              label={tab}
              {...a11yProps(index)}
              key={index}
            />
          ))}
        </AntTabs>
      </AppBar>
      <div className="table-border-bottom"></div>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {schemeLabels().map((label: string, index: number) => (
          <TabsPanel
            value={value}
            index={index}
            dir={theme.direction}
            key={index}
          >
            <SchemeContentTrnl
              schemeData={tabData(label)}
              setTrlnScheme={setTrlnScheme}
              lang={lang}
              search={search}
            />
          </TabsPanel>
        ))}
      </SwipeableViews>
    </div>
  );
};
