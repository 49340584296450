import React from "react";
import Tooltip from "@mui/material/Tooltip";

interface IPropsText {
  text: any;
  children?: any;
}

export const TooltipInfoText: React.FC<IPropsText> = ({ text }) => {
  return (
    <Tooltip title={text} className="pointer mb1">
      <img
        className="info-button"
        src="/icons/information-button.svg"
        width="15px;"
        alt=""
      />
    </Tooltip>
  );
};
