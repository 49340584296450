import React, { useMemo, useState } from "react";
import * as _ from "lodash";
import randomColor from "randomcolor";
import { Modal } from "../../ui/modal/Modal";
import { ICombinedOrder } from "../../../interfaces";

const { Bar } = require("react-chartjs-2");

interface Props {
  ordersList: ICombinedOrder[];
}

interface IBar {
  labels: Array<string>;
  data: Array<number>;
  color: Array<string>;
}

export const PSPChart: React.FC<Props> = ({ ordersList }) => {
  const [modal, setModal] = useState<boolean>(false);

  const getReport = useMemo(() => {
    const labels = _.uniq(
      _.map(ordersList, (item) => item.sale_marketplace_account || "B2B")
    );

    const isB2B = (order: ICombinedOrder) => {
      return ["sale_marketplace_account", "sale_marketplace"].some(
        (x: string) => !Object.keys(order).includes(x)
      );
    };

    const data = labels
      .map((x: string) => {
        return {
          value: ordersList
            .filter((order: ICombinedOrder) => {
              return (
                order.sale_marketplace_account === x ||
                (x === "B2B" && isB2B(order))
              );
            })
            .reduce((prev: number, next: ICombinedOrder) => {
              return prev + next.sale_amount;
            }, 0),
          label: x,
        };
      })
      .sort((a: any, b: any) => b.value - a.value);

    return {
      labels: data.map((x) => x.label),
      data: data.map((x) => x.value),
    };
  }, [ordersList]);

  const { labels, data } = getReport;

  const color = useMemo(
    () =>
      randomColor({
        count: labels.length,
        hue: "random",
        format: "rgba",
        alpha: 0.3,
      }),
    // eslint-disable-next-line
    [labels]
  );

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <div
      className="bi__card"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setModal(true);
      }}
    >
      <div className="bi__card_title">Platforms Performance</div>
      <div className="bi__card_content">
        <DoughnutChartComponent labels={labels} data={data} color={color} />
        {modal && (
          <Modal onModalClose={onModalClose} isOpened={modal}>
            <div className="modal-width">
              <DoughnutChartComponent
                labels={labels}
                data={data}
                color={color}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export const DoughnutChartComponent: React.FC<IBar> = ({
  labels,
  data,
  color,
}) => {
  return (
    <Bar
      type="bar"
      data={{
        labels,
        datasets: [
          {
            data,
            backgroundColor: color,
          },
        ],
      }}
      height={300}
      width={400}
      options={{
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
    />
  );
};
