import React, { useEffect, useState, useCallback } from "react";
import "./pim-translation.css";

export const SchemeContentTrnl: React.FC<any> = ({
  schemeData,
  setTrlnScheme,
  lang,
  search,
}) => {
  const [scheme, setScheme] = useState<any>(schemeData.details?.tabs);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, name: string, z: string) => {
      e.preventDefault();

      const updatedTabs = scheme.map((tab: any) => {
        tab.fields.map((field: any) => {
          field.fields.map((item: any) => {
            if (item.label === name) {
              const findLangObj = item.translationValue.find(
                (x: any) => x["pt"] === z
              );

              findLangObj[lang] = e.target.value;
            }
            return item;
          });
          return field;
        });
        return tab;
      });

      setScheme(updatedTabs);

      setTrlnScheme({
        ...schemeData,
        details: {
          ...schemeData.details,
          tabs: updatedTabs,
        },
      });
    },
    [lang, scheme, schemeData, setTrlnScheme]
  );

  useEffect(() => {
    const { tabs } = schemeData.details;

    if (search === "") {
      setScheme(tabs);
      return;
    }

    const lSearch = search.toLowerCase();

    const newTabs = [] as any;

    for (const tab of tabs) {
      const fieldsets = [] as any;

      for (const fieldset of tab.fields) {
        const fields = [] as any;

        for (const field of fieldset.fields) {
          if (!field.label?.toLowerCase()?.includes(lSearch)) {
            continue;
          }

          fields.push(field);
        }

        if (!fields.length) {
          continue;
        }

        fieldsets.push({
          ...fieldset,
          fields,
        });
      }

      if (!fieldsets.length) {
        continue;
      }

      newTabs.push({
        ...tab,
        fields: fieldsets,
      });
    }

    setScheme(newTabs);
  }, [search, schemeData]);

  useEffect(() => {
    setScheme((scheme: any) =>
      scheme.map((tab: any) => {
        tab.fields.map((field: any) => {
          field.fields.map((item: any) => {
            if (item?.translationValue?.length) {
              if (!Object.keys(item?.translationValue[0]).includes(lang)) {
                item.translationValue = item.translationValue.map(
                  (val: any) => {
                    return {
                      ...val,
                      [lang]: "",
                    };
                  }
                );
              }
            }
            return item;
          });
          return field;
        });
        return tab;
      })
    );
  }, [lang]);

  // TODO: Deactivated, keeping for the first days of this new feature live
  // const checkItemValue = (arr: any) => {
  //   const hasADash = arr.some((v: string) => v === "-");

  //   if (hasADash) {
  //     return arr.filter((x: any) => x !== "-").every((v: any) => !isNaN(v));
  //   } else {
  //     return arr.every((v: any) => !isNaN(v));
  //   }
  // };

  const checkItem = (item: any) => {
    const checkType =
      item.type === "input" ||
        item.type === "textArea" ||
        item.type === "assets"
        ? true
        : false;

    return !checkType && item.value && item.localized;
  };

  const checkField = (arr: any) => {
    return arr.map((item: any) => checkItem(item)).some((x: any) => x);
  };

  return (
    <form>
      {scheme.map((tab: any, index: number) => (
        <React.Fragment key={index}>
          {tab.fields?.map((field: any, index: number) => (
            <React.Fragment key={index}>
              {checkField(field.fields) && (
                <div className="trnl-field-section">
                  <div className="trnl-field-label">
                    {field.label.toUpperCase()}
                  </div>
                  {field.fields?.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                      {checkItem(item) && (
                        <div className="trnl-items-section">
                          <div>{item.label}</div>
                          {item?.translationValue.map(
                            (x: any, index: number) => (
                              <React.Fragment key={index}>
                                {/* {isNaN(x["pt"])
                                  && x["pt"] !== "-"
                                  ? ( */}
                                <div className="trnl-item-section">
                                  <div className="trnl-item-input">
                                    <textarea
                                      className="item-label width100"
                                      value={x["pt"]}
                                      disabled
                                    />
                                  </div>
                                  <div className="">
                                    <img
                                      src="/icons/translator-arrow.svg"
                                      alt=""
                                      width="20px;"
                                    />
                                  </div>
                                  <div className="trnl-item-input">
                                    <input
                                      className="trnl-item-label width100"
                                      value={x[lang] || ""}
                                      onChange={(e) =>
                                        onInputChange(e, item.label, x["pt"])
                                      }
                                    />
                                  </div>
                                </div>
                                {/* ) : (
                                    <></>
                                  )} */}
                              </React.Fragment>
                            )
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      {/* <div
				className="table-border-bottom"
				style={{ width: "104%", marginLeft: "-25px" }}
			></div>
			<div className="trnl-submit-button-cont">
				<button className="trnl-filter-button" type="submit">
					salvar
				</button>
			</div> */}
    </form>
  );
};
