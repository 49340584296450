import axios from "axios";
import { IPaginatedRequest } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";
import fileDownload from "js-file-download";

export async function getShipments() {
  const res = await axios.get("/shipments", getHeaders());
  return res.data.shipments;
}

export async function getAllShipments() {
  const res = await axios.get("/billings/allShipments", getHeaders());
  return res.data.shipments;
}

export const getPaginatedShipments = async (pagination: IPaginatedRequest) => {
  const res = await axios.get(
    // `/billings/paginatedShipments?${queryString.stringify(pagination)}`,
    `/payouts/shipments?${queryString.stringify(pagination)}`,
    getHeaders()
  );

  return res.data;
};

export const getPaginatedTreasury = async (pagination: IPaginatedRequest) => {
  const res = await axios.get(
    // `/billings/paginatedShipments?${queryString.stringify(pagination)}`,
    `/payouts/treasury?${queryString.stringify(pagination)}`,
    getHeaders()
  );

  return res.data;
};

export const getAllTreasury = async () => {
  const res = await axios.get(
    `/payouts/treasury/all`,
    getHeaders()
  );

  return res.data;
};

export const getPaginatedPayment = async (pagination: IPaginatedRequest) => {
  const res = await axios.get(
    // `/billings/paginatedShipments?${queryString.stringify(pagination)}`,
    `/payouts/payment?${queryString.stringify(pagination)}`,
    getHeaders()
  );

  return res.data;
};

export const getPayment = async (id: string) => {
  const res = await axios.get(
    `/payouts/payment/${id}`,
    getHeaders()
  );

  return res.data;
};

export async function editPayment(body: any) {
  const res = await axios.put(`/payouts/payment/${body.payment_id}`, body, getHeaders());
  return res.data;
}

export async function getShipmentsPerVendor() {
  const res = await axios.get("/billings/shipmentsPerVendor", getHeaders());
  return res.data.shipments;
}

export async function getShipment(id: number) {
  // const res = await axios.get(`/billings/shipment/${id}`, getHeaders());
  const res = await axios.get(`/payouts/shipment/${id}`, getHeaders());
  return res.data.shipment[0];
}

export async function getBilling(id: number) {
  // const res = await axios.get(`/billings/billing/${id}`, getHeaders());
  const res = await axios.get(`/payouts/shipment/${id}/billings`, getHeaders());
  return res.data.billing;
}

export async function getInvoice(ref: number) {
  // const res = await axios.post("/billings/invoice", { ref }, getHeaders());
  const res = await axios.get(`/payouts/invoice/${ref}`, getHeaders());
  return res.data.invoice[0];
}

export async function getBillingPrice(id: number) {
  const res = await axios.get(`/billings/billingPrice/${id}`, getHeaders());
  return res.data.billing[0].bill_line_ids[0];
}

export async function getSingleBillingCSV(id: number) {
  await axios.get(`/billings/billing/${id}/export`, getHeaders());
}

export async function getBillingsCSV() {
  await axios.get(`/billings/export`, getHeaders());
}

export const createPaymentBatch = async (body: any) => {
  const res = await axios.post(
    `/payouts/payment`,
    body,
    getHeaders()
  );

  return res.data;
};

export const downloadBillingRerpot = async (id: number, name: string) => {
  try {
    const res = await axios.get(`/payouts/payment/report/${id}`, {
      ...getHeaders(),
      responseType: "arraybuffer",
    });

    return fileDownload(res.data, `${name}.xlsx`);
  } catch (error: any) {
    console.log("Purchase Order Generation Error", error);
    throw new Error("Purchase Order Generation Error");
  }
};

export const singleUploadSellerInvoice = async (
  file: File,
  payment_id: string,
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("payment_id", payment_id);
  const res = await axios.post(
    `/payouts/payment/${payment_id}/invoice`,
    formData,
    getHeaders()
  );
  return res.data;
};

