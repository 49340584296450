import React, { useState, useDeferredValue } from "react";
import { useQuery } from "react-query";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { getSchemes } from "../../../actions/pim";
import { CategoryFieldController } from "../../../actions";
import * as CategoryFieldControllerV2 from "../../../actions/v2/categoryField";
import { IMarketplace } from "../../../interfaces";
import { ICategoryField } from "types";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useMemo } from "react";
import { useCallback } from "react";
import { queryClient } from "../../App";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModalHeader from "./ModalHeader";
import useMarketplace from "../../../hooks/useMarketplace";
import { getMarketplaceCategories } from "../../../actions/marketplace";
import { compact, isEmpty } from "lodash";
import useMarketplaceCategory from "../../../hooks/useMarketplaceCategory";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "../../ui/modal/Modal";
import SubcategorySelect from "../../ui/subcategory-select";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import { useParamsState } from "hooks";
import Pagination from "../../ui/pagination/Pagination";
import queryString from "query-string";
import { mapCategories } from "../../../actions/categoryField";
import { toast } from "react-toastify";
import Preloader from "../../ui/preloader/Preloader";

type TParams = { zeoosName: string; };

const ProductAttrubutes: React.FC<RouteComponentProps<TParams>> = (props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const zeoosName = useMemo(() => props.match.params.zeoosName, [props]);
  const [search, setSearch] = useState('')
  const marketplace = useMarketplace(zeoosName);

  const [currentConfig, setCurrentConfig] = useParamsState<any>({
    perPage: 10,
    page: 1,
    search:""
  });

  const debouncedConfig = useDeferredValue(currentConfig);
  const key = useMemo(
    () => `/category-fields?zeoosName=${zeoosName}/${queryString.stringify(debouncedConfig)}/${search}`,
    [zeoosName, debouncedConfig],
  );

  const marketplaceCategories = useQuery(
    `/marketplace/${zeoosName}/categories`,
    () => getMarketplaceCategories(zeoosName),
    { refetchOnWindowFocus: false }
  );


  const { isLoading, data } = useQuery(
   `/category-fields?zeoosName=${zeoosName}/${queryString.stringify(debouncedConfig)}`,
    async () =>
     await  CategoryFieldControllerV2.find({
        marketplaceName: marketplace.data?.marketplaceName!,
        ...debouncedConfig,
      }),
    {
      enabled: !!marketplace.data,
    }
  );

  const mutate = useCallback(() => {
    queryClient.invalidateQueries(key);
  }, [key]);

  const onSearchClick = () => {
    if(search){
      setCurrentConfig({search:search})
    }
  }

  const [toAddNew, setToAddNew] = useState(false);

  if (isLoading || marketplaceCategories.isLoading) {
    return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
  }

  const handleAutoMapButton = async () => {
    setIsButtonDisabled(true);
    const autoMappingToast = toast.warn('Auto mapping in process...', {autoClose: false})
    try {
      await mapCategories(marketplace.data?.marketplaceName)
      toast.update(autoMappingToast, {
        render: 'Auto mapping finished',
        type: toast.TYPE.SUCCESS,
      })
    } catch (error) {
      console.error(error)
      toast.update(autoMappingToast, {
        render: 'Error during auto mapping',
        type: toast.TYPE.ERROR,
      })
    } finally {
      setIsButtonDisabled(false);
    }
  }

  return (
    <div className="product-main-cont">
      <ModalHeader zeoosName={zeoosName}>
        <button 
          onClick={isButtonDisabled ? undefined : handleAutoMapButton}
          className="product-main-btn"
          style={{marginInlineEnd: '20px'}}
        >
          Auto Mapping
        </button>
      </ModalHeader>

      <div className="product-cont">
        <h1 className="product-h">Categorise your products</h1>
        <p className="product-p">
          Assign platform category to zeoos category to match attribute fields.
        </p>
        <div style={{backgroundColor:'transparent', margin:0, marginTop:'20px'}} className="table-main-cont">
        <div className="table-main-title-cont">
          <div className="table-main-title-search-value-cont">
            <div className="table-search-box ml2">
              <form
                className="table-search-form"
                onSubmit={(e) => {
                  e.preventDefault()
                  onSearchClick()
                }}
              >
                <input
                  className="table-search-input"
                  placeholder="Search category"
                  onChange={(e) =>
                    setSearch(e.currentTarget.value)
                  }
                  value={search}
                />
                <img
                onClick={onSearchClick}
                style={{cursor:'pointer'}}
                  className="table-search-image"
                  src="/icons/search.svg"
                  width="25px;"
                  alt=""
                />
              </form>
            </div>
            <div className="table-value">
              {isLoading ? (
                <CircularPreloader />
              ) : (
                <span className="table-main-title-text">
                  {data?.total} categories
                </span>
              )}
            </div>
          </div>
          <div className="marketplace-pagination">
            {!!data && (
              <Pagination
                lastPage={data?.lastPage}
                setConfig={setCurrentConfig}
                config={currentConfig}
              />
            )}
          </div>
        </div>
        <div className="table-border-bottom"></div>
      </div>
        <div style={{marginTop:'20px'}} className="product-content">
          <div className="product-box">
            <span className="product-content-title width100">
              {marketplace.data?.zeoosName} categories
            </span>
            <span className="product-content-title width60 text-aligns ml4">
              Zeoos categories
            </span>
          </div>
       

          {data?.data?.map((categoryField) => (
            <CreateEditCategoryField
              key={categoryField.scheme}
              marketplace={marketplace.data!}
              categoryField={categoryField}
              onSuccess={mutate}
              categories={marketplaceCategories?.data || []}
              loading={marketplaceCategories.isLoading}
            />
          ))}

          {toAddNew && (
            <CreateEditCategoryField
              marketplace={marketplace.data!}
              onSuccess={mutate}
              categories={marketplaceCategories?.data || []}
            />
          )}

          <div className="product-box">
            <div
              className="product-add-cont-order mt2"
              onClick={() => setToAddNew((prev) => !prev)}
            >
              <img
                className="mr2"
                src="/icons/product-add-circle-blue.svg"
                alt=""
              />
              {toAddNew ? "REMOVE THE CATEGORY" : "ADD A NEW CATEGORY"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ICategory {
  _id: number | string;
  name: string;
}

const CreateEditCategoryField: React.FC<{
  marketplace: IMarketplace;
  categoryField?: ICategoryField;
  onSuccess: Function;
  categories?: ICategory[];
  loading?: boolean;
}> = ({ marketplace, categoryField, onSuccess, categories, loading }) => {
  const [categoryName, setCategoryName] = useState(categoryField?.name || "");
  const [scheme, setScheme] = useState<string>(categoryField?.scheme || "");
  const [selectedCategory, setSelectedCategory] = useState<string>((typeof categoryField?.category == 'string' ? categoryField?.category : categoryField?.category?.name) || "");
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>((typeof categoryField?.category == 'string' ? categoryField?.category : categoryField?.category?.name) || "");
  const [categoryModal, setCategoryModal] = useState(false);
  const [editMode, setEditMode] = useState(!categoryField);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const debouncedCategoryName = useDeferredValue(categoryName);

  const marketplaceCategory = useMarketplaceCategory(
    marketplace.zeoosName,
    debouncedCategoryName,
    {
      enabled: !!categories?.length && editMode,
      refetchOnWindowFocus: false,
    }
  );


  return (

    <div className="product-box">
        
      <div className="product-input-cont width100">
        <span className="mwidth120">Category</span>
        {categories?.length ? (
          <>
            <Autocomplete
              value={categoryName}
              freeSolo
              className="mwidth220 mr3"
              onChange={(e, value) => {
                setCategoryName(value || "");
              }}
              renderInput={(params) => <TextField {...params} />}
              options={compact([categoryField?.name, ...categories?.map((c: ICategory) => c.name)])}
              disabled={!editMode}
            />

            {editMode && (
              <Box sx={{ ml: 1 }}>
                {marketplaceCategory.isLoading ? (
                  <CircularProgress size={28} />
                ) : isEmpty(marketplaceCategory.data) ? (
                  <CloseIcon sx={{ color: "#ff8383" }} />
                ) : (
                  <CheckIcon sx={{ color: "#00a224" }} />
                )}
              </Box>
            )}
          </>
        ) : (
          <input
            className="product-input"
            placeholder={`Category name`}
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            disabled={!editMode}
          />
        )}
      </div>
      <img className="ml2 mr2" src="/icons/product-arrow-right.svg" alt="" />
      <div className="product-input-cont width60">
        <TextField
          onClick={() => setCategoryModal(true)}
          value={selectedCategory}
          sx={{
            minWidth: 300,
          }}
          inputProps={{
            className: "product-select",
          }}
          disabled={!editMode}
        />
        <Modal onModalClose={() => setCategoryModal(false)} isOpened={categoryModal}>
          <div className="pim-modal-form-container">
            <div className="pim-modal-form-content">
              <p>Select Category</p>
              <SubcategorySelect
                value={selectedCategory}
                setValue={setSelectedCategoryId}
                setName={setSelectedCategory}
                useId={true}
              />
            </div>
          </div>
        </Modal>
        {categoryField && !editMode ? (
          <Link

            to={`/match-fields/${categoryField?._id}/${marketplace.zeoosName}`}
            className="product-main-btn"
          >
            match
          </Link>
        ) : (
          <button
            className="product-main-btn"
            onClick={async () => {
              if (categoryField) {
                await CategoryFieldController.update(categoryField._id, {
                  name: categoryName,
                  scheme,
                  category: selectedCategoryId,
                });

                setEditMode(false);
              } else {
                await CategoryFieldController.create({
                  marketplaceName: marketplace.marketplaceName,
                  name: categoryName,
                  scheme,
                  categoryId: categories?.find((c: ICategory) => c.name === categoryName)?._id,
                  categoryName: selectedCategory,
                  category: selectedCategoryId,
                } as Partial<ICategoryField>);
              }

              onSuccess();
            }}
          >
            Save
          </button>
        )}

        {categoryField && (
          <MoreVertIcon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={handleClick}
          />
        )}

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={async () => {
              handleClose();
              setEditMode((prev) => {
                if (prev && categoryField) {
                  setCategoryName(categoryField.name);
                  setScheme(categoryField.scheme);
                }

                return !prev;
              });
            }}
          >
            {editMode ? "Discard" : "Edit"}
          </MenuItem>
          <MenuItem
            onClick={async () => {
              if (
                window.confirm(
                  `Are you sure you want to delete the category "${categoryName}"`
                )
              ) {
                await CategoryFieldController.remove(categoryField?._id!);
                onSuccess();
              }

              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    </div>
    
  );
};

export default withRouter(ProductAttrubutes);
