import React from "react";
import { NavLink } from "react-router-dom";

export default function SellersNav() {
  return (
    <>
      <div style={{ minWidth: "400px" }} className="in-row align-center p1 mt2">
        <div className="table-title-nav-cont">
          <NavLink
            to="/sellers"
            className="int-tab-sync"
            activeClassName="int-tab-sync-active"
          >
            Vendors D2C
          </NavLink>
          <NavLink
            to="/sellers-onboarding"
            className="int-tab-sync"
            activeClassName="int-tab-sync-active"
          >
            Sellers HUB
          </NavLink>
          <NavLink
            to="/demo-request"
            className="int-tab-sync"
            activeClassName="int-tab-sync-active"
          >
            Demo Request
          </NavLink>
        </div>
      </div>
    </>
  );
}
