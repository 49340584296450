import React, { useState, memo, useCallback } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
import StandingEditModal from "./StandingEditModal";
import { useQuery, useMutation } from "react-query";
import { IMarketplace, IPaginatedRequest } from "../../../interfaces";
import { StandingRule } from "types";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import { getSanitizedMarketplaces } from "../../../actions/marketplace";
import * as _ from "lodash";
import { getLogoSvg } from "../marketplaces/MarketplacesList";
import * as PricingController from "../../../actions/pricingEvent";
import { queryClient } from "../../App";
import { toast } from "react-toastify";
import Paginator from "../../ui/pagination";

interface IPaginatedRules {
	data: StandingRule[];
	lastPage: number;
	page: number;
	perPage: number;
	total: number;
}

interface Props {
	standingRules: IPaginatedRules;
	rqKeyRules: string;
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
}

const StandingRules: React.FC<Props> = ({
	standingRules,
	rqKeyRules,
	setCurrentConfig,
	currentConfig,
}) => {
	const [updown, setUpdown] = useState(false);
	// const [isShownSync, setIsShownSync] = useState(false);

	const { isLoading: loadPlatforms, data: platforms } = useQuery(
		"sanitizedMarkeplaces",
		() => getSanitizedMarketplaces()
	);

	const getValidPlatforms = () => {
		const rulePlatforms = _.uniq(
			standingRules.data.flatMap((r: StandingRule) => r.platforms)
		);

		return platforms?.filter((platform: IMarketplace) =>
			rulePlatforms.includes(platform._id)
		);
	};

	const rulesByPlatform = (platformId: string) => {
		return standingRules.data.filter((rule: StandingRule) =>
			rule.platforms.includes(platformId)
		);
	};

	const toggleHide = (e: React.MouseEvent) => {
		e.preventDefault();
		setUpdown(!updown);
	};

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	return loadPlatforms ? (
		<CircularPreloader />
	) : (
		<>
			<div className="table-main-title-cont">
				<div className="table-main-title-search-value-cont ml2">
					<div className="table-search-box">
						<form className="table-search-form">
							<input
								className="table-search-input"
								placeholder="Search rule"
								onChange={(e) =>
									setCurrentConfig((conf: IPaginatedRequest) => ({
										...conf,
										search: e.target.value,
									}))
								}
								value={currentConfig.search}
							/>
							<img
								className="table-search-image"
								src="/icons/search.svg"
								width="25px;"
								alt=""
							/>
						</form>
					</div>
					<div className="table-main-title-text">
						{standingRules.total} standing rules
					</div>
				</div>
				<div className="marketplace-pagination">
					<Paginator data={standingRules} refetch={onChangePage as any} />
				</div>
			</div>
			<div className="table-border-bottom"></div>
			<div style={{ height: "max-content" }} className="table-overflow">
				<table
					style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
				>
					<thead>
						<tr
							style={{ margin: "-5px 0 -2px", width: "100%" }}
							className="table-results-title"
						>
							<th className="stan-acc">Platform Account</th>
						</tr>
					</thead>
					<tbody></tbody>
				</table>
			</div>
			<div>
				<table className="table-results p0">
					<thead></thead>
					<tbody>
						{getValidPlatforms().map(
							(platform: IMarketplace, index: number) => (
								<Accordion className="p0div m0 width100" key={index}>
									<AccordionSummary
										aria-controls="panel1a-content"
										id="panel1a-header"
										onClick={toggleHide}
										className="width100 m0div"
									>
										<tr className="table-result-box bordernone width100 height80px m0 p4 border-0">
											<td className="width100 in-row align-center">
												<img
													width="25px"
													className="mr2"
													src={
														updown
															? "/icons/vendors-arrow-up.svg"
															: "/icons/vendors-arrow-down.svg"
													}
													alt=""
												/>
												<img
													src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${getLogoSvg(
														platform
													)}.svg`}
													width="60px"
													alt=""
												/>
												<span className="pricing-text ml2">
													{platform.zeoosName}
												</span>
											</td>
											<td className="width100 in-row align-center justify-end mr4">
												<div className="pricing-text mwidth60">
													{rulesByPlatform(platform._id).length}{" "}
													{rulesByPlatform(platform._id).length > 1
														? "rules"
														: "rule"}
												</div>
												<div className="pricing-text-grey">
													{updown ? "Hide" : "See"}
												</div>
											</td>
										</tr>
									</AccordionSummary>
									<AccordionDetails>
										<table className="table-results mheightauto">
											<thead>
												<tr className="table-results-title whitebg border0 borderxnone width100 m0 height30 width100">
													<th className="stan-rule">Rule</th>
													<th className="stan-cond">Condition</th>
													<th className="stan-cat1">Discount applied</th>
													<th className="stan-cat1">Status</th>
													<th className="stan-cat2 justify-center">Actions</th>
												</tr>
											</thead>
											<tbody>
												{rulesByPlatform(platform._id).map(
													(rule: StandingRule) => (
														<tr
															style={{ height: "50px" }}
															className="table-result-box borderxnone border0 m0 border-bottom width100"
															key={rule?._id}
														>
															<td className="stan-rule">
																<span className="pricing-text-grey">
																	R-{rule._id?.slice(0, 6)}
																</span>
															</td>
															<td className="stan-cond">
																<div className="pricing-text">
																	{rule.product}
																</div>
															</td>
															<td className="stan-cat1">
																<div className="pricing-text">
																	{rule.discountAmount}%
																</div>
															</td>
															<td className="stan-cat1">
																<RuleToggle
																	rule={rule}
																	rqKeyRules={rqKeyRules}
																/>
															</td>
															<td className="stan-cat2">
																{/* <button
																	onMouseEnter={() => setIsShownSync(true)}
																	onMouseLeave={() => setIsShownSync(false)}
																	className="pricing-text-grey stand-actions-cont"
																>
																	{isShownSync ? (
																		<img
																			src="/icons/standing-sync-active.svg"
																			alt=""
																		/>
																	) : (
																		<img
																			src="/icons/standing-sync.svg"
																			alt=""
																		/>
																	)}
																	<span className="ml4">Sync</span>
																</button> */}
																<StandingEditModal
																	rule={rule}
																	platform={platform}
																	rqKeyRules={rqKeyRules}
																/>
															</td>
														</tr>
													)
												)}
											</tbody>
										</table>
									</AccordionDetails>
								</Accordion>
							)
						)}
					</tbody>
				</table>
			</div>
		</>
	);
};

interface ToggleProps {
	rule: StandingRule;
	rqKeyRules: string;
}

export const RuleToggle: React.FC<ToggleProps> = ({ rule, rqKeyRules }) => {
	const refetchRulesConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyRules);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const updateMutation = useMutation(
		(data: StandingRule) => PricingController.updateStandingRule(data),
		refetchRulesConfig
	);

	const handleChange = useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) =>
			await updateMutation.mutateAsync({
				...rule,
				isActive: event.target.checked,
			}),
		[updateMutation, rule]
	);

	return (
		<div className="pricing-text in-row align-center">
			<AntSwitch
				checked={rule.isActive}
				onChange={handleChange}
				name="checkedC"
				required
			/>
			<span className="email-textm ml2">
				{rule.isActive ? "Active" : "Inactive"}
			</span>
		</div>
	);
};

export default memo(StandingRules);
