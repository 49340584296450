import React, { useCallback, useMemo } from "react";
import "./pagination.css";

interface Props {
  lastPage: number;
  config: { page: number; perPage: number };
  setConfig: (config: Partial<{ page: number; perPage: number }>) => void;
}

export const limitArr = [
  { text: "Show 5", limit: 5 },
  { text: "Show 10", limit: 10 },
  { text: "Show 20", limit: 20 },
  { text: "Show 50", limit: 50 },
  { text: "Show 100", limit: 100 },
];

const Pagination: React.FC<Props> = ({ lastPage, config, setConfig }) => {
  const page = useMemo(() => +config.page, [config.page]);
  const perPage = useMemo(() => +config.perPage, [config.perPage]);

  const handleChangePage = useCallback(
    (newPage: number) => (e: React.MouseEvent) => {
      e.preventDefault();
      setConfig({ page: newPage });
    },
    []
  );

  const prevPage = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (page === 1) {
        return setConfig({ page: 1 });
      }

      setConfig({ page: page - 1 });
    },
    [page]
  );

  const nextPage = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      setConfig({ page: page + 1 });
    },
    [page]
  );

  const pages = useMemo(() => {
    const pageNumbers = [];

    for (let i = 1; i <= lastPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }, [lastPage]);

  const displayPages = useMemo(() => {
    if (pages.length <= 3) {
      return pages;
    }

    if (page === 1) {
      return pages.slice(page - 1, page + 2);
    }

    if (page === lastPage) {
      return pages.slice(lastPage - 3, lastPage);
    }

    return pages.slice(page - 2, page + 1);
  }, [page, pages, lastPage]);

  if (!displayPages.length) {
    return <></>;
  }

  return (
    <>
      <select
        className="main-select-bi"
        value={`Show ${perPage}`}
        onChange={(e) => {
          const perPage = limitArr.find(
            (item) => item.text === e.target.value
          )!.limit;
          setConfig({ perPage });
        }}
      >
        {limitArr.map((item, index: number) => (
          <option key={index}>{item.text}</option>
        ))}
      </select>

      <div className="pagination-container">
        <div
          className={`pagination-admin-button ${
            page === 1 ? "admin-button-disabled-pointer" : ""
          }`}
          onClick={prevPage}
        >
          <img
            src={page === 1 ? "/icons/less-gray.svg" : "/icons/less-blue.svg"}
            width="10px;"
            alt=""
          />
        </div>

        {displayPages.slice(0, 3).map((p: number) => {
          return (
            <div
              key={p}
              onClick={handleChangePage(p)}
              className={`pagination-admin-button ${
                page == p ? "current-admin-button" : "prev-next-color-button"
              }`}
            >
              {p}
            </div>
          );
        })}

        <div
          className={`pagination-admin-button ${
            page === lastPage
              ? "admin-button-disabled-pointer"
              : "prev-next-color-button"
          }`}
          onClick={nextPage}
        >
          <img
            src={
              page === lastPage
                ? "/icons/more-gray.svg"
                : "/icons/more-blue.svg"
            }
            width="10px"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Pagination;
