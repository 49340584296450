import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { OfferQuery } from "types/offer";
import { getCategoryTemplate, getMasterTemplate, getManualTranslateTemplate } from "../../../actions/v2";
import { createManualTranslation } from "../../../actions/v2/product";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import LanguageSelect from "../../ui/language-select";
import { Modal } from "../../ui/modal/Modal";
import SubcategorySelect from "../../ui/subcategory-select";

type Props = {
  modal: boolean;
  setModal: (modal: boolean) => void;
};
export const MultiLingualCreation: React.FC<Props> = ({ modal, setModal }) => {
  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <>
            <div className="pim-modal-form-container">
              <div className="pim-modal-form-content">
                <MassCreateProductForm close={() => setModal(false)} />
              </div>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};
const MassCreateProductForm: React.FC<{ close: () => void; }> = ({ close }) => {
  const [file, setFile] = useState<File>();
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [query, setQuery] = useState({} as Partial<OfferQuery>);

  const readUploadFile = useCallback(async (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!file) return;
    const data = {
      category: category,
      lang: query.category,
      file: file,
    };
    setLoading(true);
    try {
      await createManualTranslation(data);
      toast.success(
        "Success! We've sent a report to your email with the results!"
      );
      close();
    } catch (error: any) {
      console.error(error);
      toast.error(error.response?.data?.error || "Something went wrong");
    }
    setLoading(false);
  }, [file, category, query.category, close]);

  const onDownloadTemplate = async () => {
    setLoadingTemplate(true);
    try {
      //if no category is selected use the master template
      if (!category) {
        await getMasterTemplate(true);
      } else {
        await getManualTranslateTemplate(category);
      }
      toast.success("Template Downloaded Successfully");
    } catch (error: any) {
      toast.error("Error generating Template... Try Again");
    } finally {
      setLoadingTemplate(false);
    }
  };

  return (
    <div>
      <div
        className="pim-modal-title-sku"
        style={{ textAlign: "left", width: "100%" }}
      >
        Translate product data
      </div>
      <div className="table-modal-border" style={{ width: "360px" }}></div>
      <SubcategorySelect value={category} setValue={setCategory} />
      <div className="guided-item">
        <p>Language:</p>
        <LanguageSelect
          value={query.category || ""}
          setValue={(category) => setQuery((q) => ({ ...q, category }))}
        />
      </div>
      <div
        className="bold mt2 in-row width100 align-center"
        style={{ color: "#55718a" }}
        onClick={onDownloadTemplate}
      >
        <div style={{ fontSize: "12px", display: "flex" }} className="pointer">
          <p style={{ marginRight: "10px" }}>Download Template Spreadsheet</p>
          {loadingTemplate && <CircularPreloader />}
        </div>
      </div>

      <p style={{ marginTop: "30px", marginBottom: "8px" }}>
        Upload the spreadsheet with your product data:
      </p>
      <div className="mass-choose-file">
        <img
          className="mr2"
          src="/icons/mass-choose-file.svg"
          alt="mass-choose-file icon"
        />
        <label htmlFor="upload">Upload the spreadsheet</label>
        <input
          type="file"
          name="file"
          id="upload"
          onChange={readUploadFile}
          hidden
          accept=".csv"
        />
      </div>
      {file?.name}
      <div
        className="table-modal-border"
        style={{ marginTop: "45px", marginBottom: "28px" }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <button onClick={close} className="table-modal-form-button-cancel">
          Cancel
        </button>
        <div style={{ display: "flex", gap: "9px" }}>
          <button
            disabled={!file || loading}
            onClick={handleSubmit}
            className={file ? "guided-button" : "guided-button disabled"}
          >
            {loading ? "Sending..." : "Send to create"}
          </button>
        </div>
      </div>
    </div>
  );
};
function setCurrentConfig(query: Partial<OfferQuery>, arg1: string) {
  throw new Error("Function not implemented.");
}
