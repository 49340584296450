import React, { memo, useCallback } from "react";
import LogReportModal from "../LogReportModal";
import * as JobController from "../../../../actions/job";
import { DateTime } from "luxon";
import Date from "../../../ui/date/Date";
import Paginator from "../../../ui/pagination";
import { IPaginatedRequest } from "../../../../interfaces";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";

interface Props {
  jobItems: any;
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  currentConfig: IPaginatedRequest;
  loadJobs: boolean;
}

const QueueTable: React.FC<Props> = ({
  jobItems,
  setCurrentConfig,
  currentConfig,
  loadJobs,
}) => {
  const onChangePage = useCallback(
    async (config: IPaginatedRequest) => {
      setCurrentConfig((c: IPaginatedRequest) => {
        return {
          ...c,
          ...config,
        };
      });
    },
    [setCurrentConfig]
  );

  return (
    <div className="table-main-cont">
      <div style={{padding: "0 40px"}} className="width100">
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search queue"
                    onChange={(e) =>
                      setCurrentConfig((conf: IPaginatedRequest) => ({
                        ...conf,
                        search: e.target.value,
                      }))
                    }
                    value={currentConfig.search}
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-main-title-text pl2">
              {loadJobs ? (
                <CircularPreloader />
              ) : (
                <span className="table-main-title-text">
                  {jobItems?.total} Job items
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="marketplace-pagination">
          <Paginator
            data={!loadJobs ? jobItems : {}}
            refetch={onChangePage as any}
          />
        </div>
      </div>
      <div style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
        >
          <thead>
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="queue-process">Process ID</th>
              <th className="queue-name">Job Name</th>
              {/* <th className="queue-platform">Platform</th> */}
              <th className="queue-status">Status</th>
              <th className="queue-user">User</th>
              <th className="queue-date">Started</th>
              <th className="queue-date">Finished</th>
              <th className="queue-report">Log Report</th>
            </tr>
          </thead>
        </table>
        <table className="table-results mb2" style={{ minHeight: "auto" }}>
          <tbody>
            {jobItems?.data.map((jobItem: any) => (
              <tr className="table-result-box height100px" key={jobItem._id}>
                <td className="queue-process">
                  <span>{jobItem._id}</span>
                </td>
                <td className="queue-name">
                  {JobController.readJobFromJobItem(jobItem)?.name}
                  {jobItem.params?.zeoosName
                    ? ` (${jobItem.params?.zeoosName})`
                    : ""}
                  {jobItem.deletedJobSnapshot && (
                    <>
                      <br />
                      (deleted job)
                    </>
                  )}
                </td>
                {/* <td className="queue-platform">
									<img width={80} src={profileImage} alt='' />
								</td> */}
                <td className="queue-status">
                  <div className={`queue-${jobItem.status.toLowerCase()}`}>
                    {jobItem.status}
                  </div>
                </td>
                <td className="queue-user">
                  <img
                    style={{ borderRadius: "60px" }}
                    height={34}
                    width={34}
                    src={
                      jobItem.user?.isBot
                        ? "/icons/image-bot.svg"
                        : jobItem.user.profileImage
                    }
                    alt=""
                  />
                  <div className="ml2">
                    {jobItem.user?.isBot ? "Bot" : jobItem.user?.username}
                  </div>
                </td>
                <td className="queue-date">
                  <Date date={DateTime.fromISO(jobItem.created as any)} />
                </td>
                <td className="queue-date">
                  {jobItem.finished ? (
                    <Date date={DateTime.fromISO(jobItem.finished as any)} />
                  ) : (
                    "-"
                  )}
                </td>
                <td className="queue-report">
                  {jobItem.report ? (
                    <LogReportModal
                      jobName={JobController.readJobFromJobItem(jobItem)?.name}
                      report={jobItem.report}
                    />
                  ) : (
                    <div>No Report</div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  );
};

export default memo(QueueTable);
