import { UseQueryOptions } from "react-query";
import { StockCondition } from "types";
import { PermissionScope, PermissionType } from "./actions/user";
import { UserGroup } from "./actions/userGroup";
import { ICountryM } from "./components/routes/country-management/CountryManagement";
import { Category } from "types/category";

export enum Role {
  readonly = "READONLY",
  user = "USER",
  admin = "ADMIN",
  permissionBased = "PERMISSION_BASED",
  sellerAdmin = "SELLER_ADMIN",
  sellerUser = "SELLER_USER",
}

export interface IMarketplace {
  active?: boolean;
  created: string;
  createdBy: string;
  credentials: {
    accessToken: string;
    secret: string;
    secondaryToken?: string;
  };
  cronJob?: {
    expression: string;
    id: string;
  };
  marketplaceName: string;
  zeoosName: string;
  type?: string;
  _id: string;
  imgUrl: string;
  country: string;
  additionalPricePerCountry?: number;
  wineVatRate?: number;
  champagneVatRate?: number;
  wineIecRate?: number;
  champagneIecRate?: number;
  baseUrl?: string;
  userToken?: string;
  isManual: boolean;
  rate: {
    from: number;
    to: number;
  };
  statusMap: { fromLabel: string; toLabel: string }[];
  isFeed?: boolean;
  marketplaceImg: string;
  marketplaceIntegration?: string;
}

interface IMarketplaceCredentials {
  accessToken: string;
  secret: string;
  secondaryToken: string;
}

export interface INewMarketplace {
  active?: boolean;
  additionalPricePerCountry: number;
  baseUrl: string;
  champagneIecRate: number;
  champagneVatRate: number;
  country: string;
  created: string;
  createdBy: string;
  credentials: IMarketplaceCredentials;
  hasPromotionalPrice: string | boolean;
  marketplaceName: string;
  wineIecRate: number;
  wineVatRate: number;
  zeoosName: string;
  type?: string;
  imgUrl?: string;
  userToken?: string;
  isManual: boolean;
  marketplaceImg: string;
}

//BI
export interface ICombinedMarketplaceProduct {
  compareAtPrice: string;
  extraId: number;
  id: number;
  marketplaceName: string;
  price: string;
  priceStandard: string;
  sku: string;
  stock: number;
  link: string;
}

interface IStockWarehose {
  [key: string]: number;
}

export interface ICombinedProduct {
  barcode: string | boolean;
  brand: string;
  category_id: string;
  id?: number;
  components?: Array<any>;
  marketplaces?: {
    [key: string]: ICombinedMarketplaceProduct;
  };
  name: string;
  price: string;
  price_without_discount: number;
  product_type: string;
  reportId: string;
  responsible_id: string;
  seller_id: number;
  seller_id_country: string;
  sku: string;
  standard_cost: number;
  standard_price: number;
  stock: number;
  stock_warehouse: IStockWarehose;
  uom_id: string;
  virtual_available: number;
  weight?: number;
}

export interface ISaleLineIds {
  barcode: string | boolean;
  category_id: string | boolean;
  name: string;
  price: number;
  price_without_discount: number;
  quantity: number;
  responsible_id: string;
  sku: string;
  standard_cost: number;
  standard_price: number;
  uom_id: string;
  virtual_available: number;
}
export interface ICombinedOrder {
  country: string;
  customer: string;
  order_id: string;
  order_status: string;
  sale_amount: number;
  sale_date: string;
  sale_id: number;
  sale_lengow_id?: string | boolean;
  sale_lengow_status: string | boolean;
  sale_line_ids?: Array<ISaleLineIds>;
  sale_marketplace?: string;
  sale_marketplace_account?: string;
  salesperson: string;
  totalQuantity?: number;
  id?: number;
}
export interface IZeoosOrder {
  _id: string;
  country: string;
  customer: any;
  order_id: number;
  order_erp?: string;
  order_marketplace: string;
  status: string;
  price: number;
  date: string;
  products?: Array<ISaleLineIds>;
  marketplace?: string;
  zeoosName?: string;
  shipping_price: number;
  sale_id?: number;
  shipping_address?: any;
  billing_address?: any;
  shipping_list?: Array<any>;
  taxPO: number;
  activeCarriers?: Array<string>;
}
export interface ICombinedOrders {
  b2b: ICombinedOrder[];
  b2c: ICombinedOrder[];
  flash: ICombinedOrder[];
  b2b_wait: ICombinedOrder[];
  b2c_wait: ICombinedOrder[];
}

export interface IVendorBI {
  city: string;
  country: string;
  email: string;
  id: number;
  name: string;
  phone: string;
  salesperson_id: string;
}

export interface IObjData {
  value: number;
  label: string;
}

export interface IVendorPermission {
  id: number;
  name: string;
}

export interface IHistory {
  action: string;
  created: Date;
  _id: string;
  id?: string;
}

interface IPermissions {
  scope: PermissionScope;
  type: PermissionType;
}

export enum MFA {
  email = "EMAIL",
  none = "NONE",
}

export interface IUser {
  _id: string;
  created: Date;
  username: string;
  fullName?: string;
  email: string;
  password: string;
  role: Role;
  vendorPermissions: IVendorPermission[];
  history: IHistory[];
  permissions: IPermissions[];
  isActive: boolean;
  isBot: boolean;
  profileImage: string;
  group: string | UserGroup;
  mfa: MFA | string;
  isWortenWhiteLabel: boolean;
}

export interface IPaginatedUser {
  data: IUser[];
  lastPage: number;
  page: number;
  perPage: number;
  total: number;
}

export interface IProduct {
  id: number;
  title: string;
  variants: any[];
}

export interface IUpdateDataGestao {
  extraId: number;
  id: number;
  marketplaceName?: string;
  price: string;
  sku?: string;
  stock: number | string;
  zeoosName?: string;
  compareAtPrice?: any;
}

export interface IProductGestao {
  barcode: string | boolean;
  brand: string;
  category_id?: string;
  components?: any;
  image?: string;
  marketplaces: any;
  name: string;
  position?: number;
  price?: string;
  price_without_discount?: number;
  product_type?: string;
  reportId?: string;
  responsible_id?: string;
  seller_id?: string;
  seller_id_country?: string;
  sku: string;
  standard_cost?: number;
  standard_price?: number;
  stock: number;
  stock_warehouse?: IStockWarehose;
  uom_id?: string;
  virtual_available?: number;
  weight?: number;
  pimScheme: string;
  pimBrand: string;
  _id: string;
  disabled: boolean;
}

export interface IOrdersAcompB2 {
  country: string;
  customer: string;
  order_id: string;
  order_status: string;
  sale_amount: number;
  sale_date: string;
  sale_id: number;
  sale_lengow_status: string;
  sale_line_ids: any;
  sale_marketplace?: string;
  sale_marketplace_account: string;
  sale_shopify_id?: string;
  sale_shopify_status?: string;
  salesperson: string;
}

export interface IOrdersAcomp {
  b2b: IOrdersAcompB2;
  b2c: IOrdersAcompB2;
}

export interface INote {
  created: string;
  data: string;
  fromUser: {
    email: string;
    userId: string;
    username: string;
  };
  path: string;
  title: string;
  userId: string;
  wasRead: boolean;
  __v: number;
  _id: string;
}

export interface IRequest {
  content: any;
  created: Date;
  updated?: any;
  fromUser?: {
    email: string;
    userId: string;
    username: string;
  };
  user?: any;
  status: string;
  title: string;
  type: string;
  __v: number;
  _id: string;
  isSelected?: boolean;
}

export interface IPaginatedUserRequest {
  data: IRequest[];
  lastPage: number;
  page: number;
  perPage: number;
  total: number;
}

export interface IVendorContacts {
  contactName: string;
  contactEmail: string;
  _id: string;
}

export interface IVendorCategories {
  scheme: string;
  active: number;
  productCount: number;
  countries: any[];
  _id: string;
}

enum ZeoosPLan {
  jumpStart = "Jump Start",
  riseScale = "Rise & Scale",
  ultimate = "Ultimate",
}

enum Subscription {
  monthly = "Monthly",
  yearly = "Yearly",
}

enum Status {
  na = "Na",
  awaiting = "Awaiting acceptance",
  accepted = "Accepted",
}

export interface IVendor {
  _id: string;
  city: string;
  country: string;
  email: string;
  id: number;
  name: string;
  phone: string;
  salesperson_id: string;
  contacts: IVendorContacts[];
  vendorIcon: string;
  file?: any;
  zeoosClient: boolean;
  displayName: string;
  isActive?: boolean;
  categories: IVendorCategories[];
  rate?: number;
  pricingType?: string;
  onBoarding?: boolean;
  isWorten?: boolean;
  releaseProcess?: boolean;
  didUploadFile?: boolean;
  releaseDate?: Date;
  created: Date;
  contactInfo?: IContactInfoSeller;
  fiscalAddress?: IFiscalAddressSeller;
  warehouseAddress?: IWarehouseAddressSeller;
  bankInfoConfirmed?: boolean;
  bankInfo?: IBankInfoSeller;
  stripePriceId?: string;
  stripeCardId?: string;
  stripeSubscriptionId?: string;
  zeoosPlan?: ZeoosPLan;
  subscription?: Subscription;
  totalPricing?: string;
  otherCompliments?: string;
  contractStatus?: Status;
  paymentStatus?: Status;
  billingDataConfirmed?: boolean;
  onBoardingProducts?: string;
  onBoardingPriceStock?: string;
  onBoardingProductsDate?: Date;
  onBoardingPriceStockDate?: Date;
  accountType:"D2C"|"Hub"
  defaultHandlingTime?: string;
  activeCarriers?: string[];
}

export interface IVendorPermissions {
  id: number;
  name: string;
}

export interface IVendorProducts {
  _id: string;
  stock: number;
  sku: string;
  seller: number;
  deliveryType: string;
  countries: any[];
  info: any;
  scheme: any;
}

export type IPaginatedResponse<DataType = any, T = {}> = {
  data: DataType;
  perPage: number;
  page: number;
  total: number;
  lastPage: number;
} & {
  [key in keyof T]?: T[key];
};

export type CatalogTab = "integrated" | "non-integrated" | "unregistered";

export type IPaginatedRequest<T = {}> = {
  perPage: number;
  page: number;
  search?: string;
  ratingStar?: string;
  origin?: string;
  country?: string | string[];
  vendor?: string;
  sliderValue?: number[];
  brand?: string | string[];
  vendorName?: string | string[];
  outOfStock?: boolean;
  role?: string;
  platformName?: string;
  type?: string;
  status?: string;
  user?: string;
  created?: any;
  category?: string;
  stockCondition?: StockCondition;
  name?: string;
  skus?: string[];
  seller?: string;
  group?: string;
  isWortenWhiteLabel?: boolean;
  alertType?: string;
  wasRead?: boolean;
  isClosed?: boolean;
  my?: boolean;
  tab?: CatalogTab;
  allCatalog?: boolean;
  batchId?: string;
} & {
  [key in keyof T]?: T[key];
};

export interface IRoleOptions {
  value: string;
  label: string;
  bgColor: string;
}

export interface IPassword {
  _id?: string;
  site: string;
  label: string;
  country: ICountryM;
  userName: string;
  password: string;
  otpToken?: string;
}

export interface Validation {
  minChars: number;
  maxChars: number;
}

export interface TranslationValue {
  pt: string;
  it?: string;
  es?: string;
  de?: string;
  nl?: string;
  en?: string;
}

export interface SchemeTag {
  label: string;
  name: string;
  disabled?: boolean;
  createdByScheme?: boolean;
}

export interface SchemeItem {
  label: string;
  type: string;
  inputLength: string;
  name: string;
  value: string[];
  localized: boolean;
  requiredField: boolean;
  fieldLength: any;
  validation: Validation;
  readonlyField: boolean;
  translationValue: TranslationValue[];
  textAreaLength: string;
  tooltipLabel: string;
  autoValues?: boolean;
  tags: SchemeTag[];
  selectLenght: string;
  unit: string;
  marginLabel: string;
  star?: boolean;
  units: string;
  withImage?: boolean;
  description?: boolean;
  descriptionLabel: string;
}

export interface SchemeField {
  label: string;
  type: string;
  fields: SchemeItem[];
  elementsPosition: string;
}

export interface SchemeTab {
  label: string;
  type: string;
  fields: SchemeField[];
}

export interface SchemeDetails {
  name: string;
  tabs: SchemeTab[];
}

export interface SchemeRootObject {
  version: number;
  _id: string;
  name: string;
  details: SchemeDetails;
  created: Date;
  __v: number;
  category: Category;
}

export interface ProductInfoDetails {
  [key: string]: string | string[] | SchemeTag[];
}

export interface ProductInfo {
  version: number;
  isVariant: boolean;
  isMix: boolean;
  components: any[];
  _id: string;
  lang: string;
  productType: string;
  details: ProductInfoDetails;
  variantOfSku: string;
  sku: string;
  editor: string;
  created: Date;
  __v: number;
}

export interface ProductInfoRootObject {
  productInfo: ProductInfo;
}

export type ReactQueryConfig =
  | Omit<UseQueryOptions<any, unknown, any, string>, "queryKey" | "queryFn">
  | undefined;

export interface IUserHistory {
  created: string;
  text: string;
  userId: string;
  user: IUser;
  _id: string;
  type: string;
}

export interface IConfigPagination {
  page: number;
  perPage: number;
}

export interface IPaginatedProducts {
  data: IProductGestao[];
  lastPage: number;
  page: number;
  perPage: number;
  total: number;
}

export enum BatchListStatus {
  WAITING = "WAITING",
  RUNNING = "RUNNING",
  FINISHED = "FINISHED",
  ERROR = "ERROR",
}

export interface IBatchMProducts {
  sku: string;
  error: string;
  uploaded: boolean;
}

export interface IBatchMarkeplace {
  _id: string;
  marketplace: IMarketplace;
  products: IBatchMProducts[];
  status: BatchListStatus;
}

export interface IBatchList {
  name: string;
  products: [
    {
      sku: string;
    }
  ];
  marketplaces: IBatchMarkeplace[];
  user?: IUser;
  status: BatchListStatus;
  updated: string;
  created: string;
}

export interface IOnboardingSeller {
  contactInfo: IContactInfoSeller;
  fiscalAddress: IFiscalAddressSeller;
  warehouseAddress: IWarehouseAddressSeller;
  bankInfo: IBankInfoSeller;
}

interface IContactInfoSeller {
  sellerName: string;
  companyName: string;
  vatNumber: string;
  phone: string;
  emailForOrders: string;
  emailForBillings: string;
}

interface IFiscalAddressSeller {
  address: string;
  complement: string;
  city: string;
  country: string;
  postalCode: string;
  region: string;
}

interface IWarehouseAddressSeller {
  wAddress: string;
  wComplement: string;
  wCity: string;
  wCountry: string;
  wPostalCode: string;
  wRegion: string;
  wContact?: string;
  wPhone?: string;
}

interface IBankInfoSeller {
  bankName: string;
  iban: string;
  swiftCode: string;
}

export interface IProductsCounter {
  total: number;
  integrated: number;
  nonIntegrated: number;
  unregistered: number;
  platformCounter: {
    [key: string]: {
      productsCount: number;
      marketplaceName: string;
    };
  };
}
