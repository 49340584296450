import React, { useCallback, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import { OfferRequest, OfferQuery } from "types/offer";
import CountrySelect from "../../../../ui/country-select";
import PlatformSelect from "../../../../ui/platform-select";
import { Tab, Tabs } from "@mui/material";
import { STOCK_CONDITIONS } from "types";
import SellerSelect from "../../../../ui/seller-select";

interface OfferFilterProps {
	setCurrentConfig: any;
	currentConfig: OfferRequest;
}

const OfferFilter: React.FC<OfferFilterProps> = ({
	currentConfig,
	setCurrentConfig,
}) => {
	const [open, setOpen] = useState(false);
	const [query, setQuery] = useState({} as Partial<OfferQuery>);

	const clearFilter = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		setQuery({});
	}, []);

	const applyFilter = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		setCurrentConfig(query, 'override');
		setOpen(false);
	}, [query, setOpen, setCurrentConfig]);

	return (
		<>
			<button
				onClick={() => setOpen(!open)}
				className="main-select-bi no-shadow ml2"
			>
				<img src="/icons/bi-filter.svg" alt="" />
				FILTER <span className="orange-text bold">({Object.keys(query).length})</span>
			</button>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={open}
				className="relative"
			>
				<div className="filter-bg" onClick={() => setOpen(false)} />
				<div className="filter-cont-position">
					<div className="filter-cont">
						<div className="filter-content justify-start">
							<div className="filter-header pt4">
								<div className="filter-header-title">
									Filter <span className="orange-text">({Object.keys(query).length})</span>
								</div>
								<div
									className="filter-header-reset"
									onClick={clearFilter}
								>
									Clear filters
								</div>
							</div>
							<div className="filter-content-cont mt2">
								<CountrySelect value={query.country || ''} setValue={country => setQuery(q => ({ ...q, country }))} />
								<PlatformSelect value={query.platform || ''} setValue={platform => setQuery(q => ({ ...q, platform }))} />
								<SellerSelect value={query.seller || ''} setValue={seller => setQuery(q => ({ ...q, seller }))} />

								<div className="in-row align-center justify-start width100 mb3">
									<p>Select stock filters</p>
								</div>

								<div className="width100">
									<Tabs
										value={STOCK_CONDITIONS.findIndex(
											(s) => s === query.stock
										)}
										onChange={(
											event: React.SyntheticEvent,
											newValue: number
										) => {
											setQuery(q => ({ ...q, stock: STOCK_CONDITIONS[newValue] }));
										}}
										aria-label="stock items"
										sx={{
											boxShadow: "none",
											"& .MuiTabs-flexContainer": {
												width: "100%",
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
												alignItems: "center",
											},
											"MuiTab-root": {
												boxShadow: "none",
											},
											"& button:hover": {
												color: "#F1692E",
											},
											"& button.Mui-selected": {
												color: "#F1692E",
											},
										}}
									>
										{STOCK_CONDITIONS.map((c) => (
											<Tab
												label={c}
												key={c}
												sx={{
													textTransform: "none",
													boxShadow: "none",
													border: "1px solid",
													borderRadius: "10px",
													minWidth: "50px",
												}}
											/>
										))}
									</Tabs>
								</div>
							</div>
						</div>
						<div className="filter-border mt4 mb2"></div>
						<div className="filter-buttons width100 m0">
							<button
								className="filter-apply-btn width80"
								onClick={applyFilter}
							>
								APPLY
							</button>
							<button
								className="filter-cancel-btn mb2"
								onClick={() => setOpen(false)}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</Backdrop>
		</>
	);
};

export default OfferFilter;
