import React, { useState, useCallback, useMemo } from "react";
import "./gestao.css";
import "../tables.css";
import { UpdateGestao } from "./UpdateGestao";
import {
  IProductGestao,
  IMarketplace,
  IVendor,
  IPaginatedRequest,
} from "../../../../interfaces";
import { TooltipText } from "./Tooltip";
import { convertPrice } from "./UpdateGestao";
import Paginator from "../../../ui/pagination";
// import { getImageURL } from "../../../../actions/pim";
import { getMarketplaceProductImage } from "../../../../actions/pim";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import ConditionalLink from "../../../utils/conditional-link";
import { Role } from "../../../../interfaces";
import useUser from "../../../../hooks/useUser";
import { useAtom } from "jotai";
import { ProductResponse, selectedProductsAtom } from "./Gestao";
// import RoleRequired from "../../../utils/role-required/RoleRequired";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { Modal } from "../../../ui/modal/Modal";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import GestaoGridModal from "./GestaoGridModal";
import _, { set } from "lodash";
import { styled } from "@mui/material/styles";
import {
  ModalOffer,
  Offer,
  OfferGroup,
  OfferProduct,
  OfferResponse,
} from "types/offer";
import Pagination from "../../../ui/pagination/Pagination";
import OffersTableView from "./offers-table-view";
import { atomWithStorage } from "jotai/utils";
import OffersGridView from "./offers-grid-view";
import CheckOffersModal from "./check-offers-modal";

interface Props {
  marketplaces: IMarketplace[];
  vendors: IVendor[];
  products: OfferResponse;
  setCurrentConfig: any;
  currentConfig: any;
  refetchProducts: () => void;
  loadProducts: boolean;
  allSelectedFlag?: boolean;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export const rankingZeoosWinner = (data: any, forceSeller?: number) => {
  if (data?.rankingZeoos) {
    const sellers = Object.keys(data.rankingZeoos)?.filter(
      (seller: any) => !isNaN(seller)
    );
    let winner, seller_id;

    if (forceSeller) {
      winner = data.rankingZeoos[forceSeller];
      seller_id = forceSeller;
    } else {
      for (const seller of sellers) {
        if (data.rankingZeoos[seller].ranking === 1) {
          winner = data.rankingZeoos[seller];
          seller_id = seller;
          break;
        }
      }
    }

    return {
      seller: Number(seller_id),
      price:
        winner?.breakdownPromotional ||
        winner?.breakdownCustom ||
        winner?.breakdown,
      ranking: winner?.ranking,
      stock: winner?.stock,
      priceType: winner?.breakdownPromotional
        ? "Promotional Price"
        : winner?.breakdownCustom
        ? "Custom Price"
        : "Default Price",
    };
  } else {
    return {
      price:
        data?.priceBreakdownPromotional ||
        data?.priceBreakdownCustom ||
        data?.priceBreakdown,
      stock: data?.stock,
      priceType: data?.breakdownPromotional
        ? "Promotional Price"
        : data?.breakdownCustom
        ? "Custom Price"
        : "Default Price",
    };
  }
};

export const featuredMarketplace = (data: any) => {
  let list = integratedMarketplaces(data);
  if (!list.length) {
    list = allMarketplaces(data);
  }

  let top5: any[] = [];
  list.forEach((m: any) => {
    if (top5.length < 5) {
      if (m.integrated && m.available !== false) {
        //&& isSelling(m)
        top5.push(m);
      }
    }
  });

  return top5;
};

const rankingZeoosOrdered = (data: any) => {
  let ordered = [];
  if (data?.rankingZeoos) {
    const sellers = Object.keys(data.rankingZeoos)?.filter(
      (seller: any) => !isNaN(seller)
    );

    for (let index = 1; index <= sellers.length; index++) {
      for (const seller of sellers) {
        if (data.rankingZeoos[seller].ranking === index) {
          ordered.push({
            ...data.rankingZeoos[seller],
            price:
              data.rankingZeoos[seller].breakdownPromotional ||
              data.rankingZeoos[seller].breakdownCustom ||
              data.rankingZeoos[seller].breakdown,
            seller: Number(seller),
          });
          break;
        }
      }
    }
  }

  return ordered;
};

export const integratedMarketplaces = (item: IProductGestao) => {
  let list: any[] = [];
  Object.keys(item?.marketplaces || {}).forEach((zeoosName: string) => {
    if (item?.marketplaces?.[zeoosName]?.integrated) {
      list.push({
        ...item?.marketplaces?.[zeoosName],
        zeoosName,
      });
    }
  });

  const orderIsSelling = (res: any) => isSelling(res);

  return _.orderBy(
    list,
    [orderIsSelling, "buybox.ranking", "stock", "zeoosName"],
    ["desc", "asc", "asc", "asc"]
  );
};

export const allMarketplaces = (item: IProductGestao) => {
  let list: any[] = [];
  Object.keys(item?.marketplaces || {}).forEach((zeoosName: string) => {
    list.push({
      ...item?.marketplaces?.[zeoosName],
      zeoosName,
    });
  });

  const orderIntegrated = (res: any) =>
    !_.isUndefined(res.integrated) && res.integrated === true;
  const orderIsSelling = (res: any) => isSelling(res);

  return _.orderBy(
    list,
    [orderIntegrated, orderIsSelling, "buybox.ranking", "zeoosName"],
    ["desc", "desc", "asc", "asc"]
  );
};

export const isSelling = (marketplace: any, seller?: number) => {
  if (marketplace.available === false || !marketplace.integrated) return false;

  const winner = rankingZeoosWinner(marketplace, seller);
  if (!winner?.stock || !winner?.price?.pvpFinal) return false;

  return true;
};

export const stockTotal = (item: IProductGestao, seller?: number) => {
  let cont = 0;
  const sellers = seller
    ? [seller]
    : typeof item?.seller_id === "object"
    ? item?.seller_id
    : [item?.seller_id];

  sellers.forEach((seller: any) => {
    let aux = 0;
    for (const zeoosName of Object.keys(item?.marketplaces || {})) {
      if (item?.marketplaces?.[zeoosName]?.rankingZeoos?.[seller]) {
        if (
          item?.marketplaces?.[zeoosName]?.rankingZeoos?.[seller].stock > aux
        ) {
          aux = item?.marketplaces?.[zeoosName]?.rankingZeoos?.[seller].stock;
        }
      }
    }
    cont += aux;
  });

  return cont;
};

export const getSellersMarketplace = (data: any) => {
  if (data?.rankingZeoos) {
    return Object.keys(data.rankingZeoos).filter((k: string) => Number(k))
      .length;
  }
};

const offersView = atomWithStorage<"grid" | "table">("offersView", "grid");

export const TableGestao: React.FC<Props> = ({
  marketplaces,
  vendors,
  products,
  setCurrentConfig,
  currentConfig,
  refetchProducts,
  loadProducts,
  allSelectedFlag,
}) => {
  const { user } = useUser();
  const [modal, setModal] = useState<boolean>(false);
  const [product, setProduct] = useState<ModalOffer | undefined>();
  const [marketplace, setMarketplace] = useState<IMarketplace>();
  const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);
  const [value, setValue] = React.useState("1");

  const [view, setView] = useAtom(offersView);

  const [selectedWinner, setSelectedWinner] = useState<any>({});
  const [selectedSeller, setSelectedSeller] = useState<string | undefined>();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [modal1, setModal1] = useState<boolean>(false);

  const handleSelectAll = useCallback(() => {
    setSelectedProducts((prev) =>
      !prev.length ? products?.data.map((x) => x.sku) : []
    );
  }, [products?.data, setSelectedProducts]);

  const handleSelectOne = useCallback(
    (item: OfferGroup) => () => {
      setSelectedProducts((prev) => {
        if (prev.find((x) => x === item.sku)) {
          return prev.filter((x) => x !== item.sku);
        }
        return [...prev, item.sku];
      });
    },
    [setSelectedProducts]
  );

  const isAdmin = user.role === Role.admin ? true : false;

  const getSellerName = (id: number) => {
    const vendor = vendors.find((v: any) => v.id === id);
    if (isAdmin || user.seller?.id === vendor?.id) {
      return vendor?.displayName || vendor?.name || "";
    } else {
      return "*****";
    }
  };

  const openPriceUpdateModal = useCallback(
    (offer: ModalOffer) => {
      setProduct(offer);

      if (offer.seller || !offer.price) {
        setMarketplace(
          marketplaces.find((m: any) => m._id === offer?.platformId)
        );
        setSelectedSeller(offer.seller);
        setModal(true);
      } else {
        setModal1(true);
      }

      // if (offer.price) {
      // 	setModal1(true);
      // } else {
      // 	setModal(true);
      // 	setSelectedSeller(vendor?.id);
      // }

      // setProduct(offer as any);
      // setSelectedWinner(offer);
    },
    [marketplaces]
  );

  return (
    <>
      <div className="table-main-cont">
        <div className="table-main-title-cont">
          <div className="table-main-title-search-value-cont">
            <div className="table-search-box ml2">
              <form
                className="table-search-form"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  className="table-search-input"
                  placeholder="Search product or sku"
                  onChange={(e) =>
                    setCurrentConfig({
                      search: e.target.value,
                    })
                  }
                  value={currentConfig.search}
                />
                <img
                  className="table-search-image"
                  src="/icons/search.svg"
                  width="25px;"
                  alt=""
                />
              </form>
            </div>
            <div className="table-value">
              {loadProducts ? (
                <CircularPreloader />
              ) : (
                <span className="table-main-title-text">
                  {products?.total} products
                </span>
              )}
            </div>
          </div>
          <div className="marketplace-pagination">
            {/* <div className="mr2 in-row align-center">
							<img
								onClick={() => setView("grid")}
								className="mr1 pointer"
								style={{
									width: "30px",
									height: "30px",
								}}
								src={view === "grid" ? "/icons/pim-main-tab1active.svg" : "/icons/pim-main-tab1.svg"}
								alt=""
							/>
							<img
								onClick={() => setView("table")}
								className="mr1 pointer"
								style={{
									width: "30px",
									height: "30px",
								}}
								src={view === "table" ? "/icons/pim-main-tab2active.svg" : "/icons/pim-main-tab2.svg"}
								alt=""
							/>
						</div> */}

            {!loadProducts && (
              <Pagination
                lastPage={products?.lastPage || 0}
                setConfig={setCurrentConfig}
                config={currentConfig}
              />
            )}
          </div>
        </div>
        <div className="table-border-bottom"></div>

        {/* {view === "table" ? (
					<OffersTableView
						offerGroups={products?.data || []}
						allSelected={allSelectedFlag}
						openPriceUpdateModal={openPriceUpdateModal}
					/>
				) : (
					)} */}
        <OffersGridView
          offerGroups={products?.data || []}
          allSelected={allSelectedFlag}
          openPriceUpdateModal={openPriceUpdateModal}
          handleSelectOne={handleSelectOne}
        />
      </div>

      {modal1 && product && (
        <CheckOffersModal
          offer={product}
          openPriceUpdateModal={openPriceUpdateModal}
          close={() => {
            setModal1(false);
            setProduct(undefined);
          }}
        />
      )}

      {modal && product && (
        <UpdateGestao
          modal={modal}
          setModal={setModal}
          product={product}
          setProduct={setProduct}
          onUpdateCallback={refetchProducts}
          productMarketplace={marketplace as IMarketplace}
          vendors={vendors}
          // @ts-ignore
          seller={selectedSeller}
        />
      )}
    </>
  );
};

interface ConditionalTooltipProps extends TooltipProps {
  condition: boolean;
}

const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({
  condition,
  children,
  ...props
}) => {
  if (condition) {
    return <Tooltip {...props}>{children}</Tooltip>;
  }

  return <div>{children}</div>;
};
