import React from "react";
import "../pim.css";

export const Awards: React.FC<any> = () => {

  return (
    <div>
      <form>
        <div className="pim-main-title">
          <div className="pim-main-title-box">
            <div className="pim-title-legend">citações de especialistas</div>
            <img src="" alt="" //tooltiptext
            />
          </div>
          <div className="pim-main-title-cont">
            <div className="pim-content-section pim-premios-title">
              <div className="mwidth150 in-row align-center">
                <div className="mr1">Decanter:</div>
                <img src="/icons/icon-modal-arrow.svg" alt="" width="20px;" />
              </div>
              <input className="pim-prem-input" placeholder="94" />
              <span className="ml1 pim-prem-span">/100</span>
            </div>
            <div className="pim-content-section pim-premios-title">
              <div className="mwidth150 in-row align-center">
                <div className="mr1">James Suckling:</div>
                <img src="/icons/icon-modal-arrow.svg" alt="" width="20px;" />
              </div>
              <input className="pim-prem-input" placeholder="94" />
              <span className="ml1 pim-prem-span">/100</span>
            </div>
            <div className="pim-content-section pim-premios-title">
              <div className="mwidth150 in-row align-center">
                <div className="mr1">Wine Spectator:</div>
                <img src="/icons/icon-modal-arrow.svg" alt="" width="20px;" />
              </div>
              <input className="pim-prem-input" placeholder="94" />
              <span className="ml1 pim-prem-span">/100</span>
            </div>
            <div className="pim-content-section pim-premios-title">
              <div className="mwidth150 in-row align-center">
                <div className="mr1">Wine Enthusiast:</div>
                <img src="/icons/icon-modal-arrow.svg" alt="" width="20px;" />
              </div>
              <input className="pim-prem-input" placeholder="94" />
              <span className="ml1 pim-prem-span">/100</span>
            </div>
          </div>
        </div>
        <div className="pim-main-title mt5">
          <div className="pim-main-title-box">
            <div className="pim-title-legend">Prêmios</div>
          </div>
          <div className="pim-main-title-cont">
            <div className="pim-cont">
              <div className="pim-image-box">
                <img src="/icons/testemunhos-upload.svg" alt="" width="25px" />
                <div className="pim-image-text">Enviar vídeo ou imagem</div>
              </div>
              <div className="pim-content-box">
                <span className="pim-content-title-text">Prêmio 1</span>
                <div className="pim-content-section">
                  <div className="mwidth120">URL:</div>
                  <input className="width90 mr2 pim-content-input" />
                  <button className="pim-content-button-active">Ver</button>
                  <button className="pim-content-button-active">
                    <img src="/icons/testemunhos-trash.svg"
                      alt="" width="25;" />
                  </button>
                </div>
                <div className="pim-content-section">
                  <div className="mwidth120">Descrição:</div>
                  <textarea className="width50 pim-content-input pl1 pt1" />
                </div>
              </div>
            </div>
            <div className="table-modal-border"></div>
            <div className="pim-cont">
              <div className="pim-image-box">
                <img src="/icons/testemunhos-upload.svg" alt="" width="25px" />
                <div className="pim-image-text">Enviar vídeo ou imagem</div>
              </div>
              <div className="pim-content-box">
                <span className="pim-content-title-text">Prêmio 2</span>
                <div className="pim-content-section">
                  <div className="mwidth120">URL:</div>
                  <input className="width90 mr2 pim-content-input" />
                  <button className="pim-content-button-inactive">Ver</button>
                  <button className="pim-content-button-inactive">
                    <img src="/icons/testemunhos-trash.svg"
                      alt="" width="25;" />
                  </button>
                </div>
                <div className="pim-content-section">
                  <div className="mwidth120">Descrição:</div>
                  <textarea className="width50 pim-content-input pl1 pt1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
