import React from "react";
import "./users-management.css";
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import { withStyles } from "@material-ui/core/styles";

interface Props {
  vendorOptions: any;
  setVendorPermissions?: any;
  vendorPermissions: any;
  readOnly?: boolean;
}
const AntAutocomplete = withStyles({
  listbox: {
    maxHeight: "auto !important",
  },
  option: {
    minHeight: "48px !important",
  },
})(Autocomplete);

export const AutocompleteVendors: React.FC<Props> = ({
  vendorOptions,
  setVendorPermissions,
  vendorPermissions,
  readOnly = false,
}) => {
  return (
    <div className="width100 mt-9">
      <AntAutocomplete
        readOnly={readOnly}
        multiple
        id="tags-outlined"
        options={vendorOptions}
        getOptionLabel={(option: any) =>
          typeof option.name === "string" ? option.name : ""
        }
        filterSelectedOptions
        value={[...vendorPermissions] as any}
        onChange={
          readOnly
            ? () => { }
            : (e, values: any) => {
              setVendorPermissions(
                values.map((x: any) => ({ id: x.id, name: x.name }))
              );
            }
        }
        renderInput={(params) => (
          <TextField
            style={{ maxHeight: "200px !important" }}
            className="liheight"
            {...params}
            variant="outlined"
            label=""
            placeholder="Vendor Permission"
          />
        )}
      />
    </div>
  );
};
