import z from "zod";
import { PermissionScope, PermissionType } from "../actions/user";
import { Role } from "../interfaces";

export const Permission = z.object({
  scope: z.nativeEnum(PermissionScope),
  type: z.nativeEnum(PermissionType),
});

export const VendorPermission = z.object({
  id: z.number(),
  name: z.string(),
});

export const CreateEditUserInput = z
  .object({
    _id: z.string().optional(),
    username: z.string().min(2),
    email: z.string().email(),
    password: z.string().optional(),
    role: z.nativeEnum(Role),
    vendorPermissions: z.array(VendorPermission),
    isActive: z.boolean(),
    isWortenWhiteLabel: z.boolean().optional().nullable(),
    group: z.string(),
    permissions: z.array(Permission),
    seller: z.string(),
  })
  .superRefine(({ role, seller, group, _id, password }, ctx) => {
    if (role === Role.user && !seller) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Seller cannot be left blank!",
      });

      return;
    }

    if (role === Role.sellerAdmin && !group) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "User group cannot be left blank!",
      });

      return;
    }

    if (!_id && (password?.length || 0) < 8) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 8,
        type: "string",
        inclusive: true,
        message: "Too many items 😡",
      });

      return;
    }
  });

export type CreateEditUser = z.infer<typeof CreateEditUserInput>;
