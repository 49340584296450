import React, { memo, useState } from "react";
import {
	getAllTreasury,
	getPaginatedShipments,
} from "../../../actions/billings";
import "./billing.css";
import "./BillingTable";
import { BillingTable } from "./BillingTable";
import { SingleBilling } from "./single-billing/SingleBilling";
import { useQuery } from "react-query";
import { useDebouncedValue } from "@mantine/hooks";
import { IPaginatedRequest } from "../../../interfaces";
import queryString from "query-string";
import BatchBillingsDropDown from "../../ui/batch-dropdown/BatchBillingsDropDown";
import { atom, useAtom } from "jotai";
import CreatePayment from "./payment-batch/CreatePayment";
import BillingPayoutsNav from "../../ui/billing-payouts-nav/BillingPayoutsNav";

export enum BillingStatus {
	ALL_STATUSES = "All statuses",
	OPEN = "Aberto",
	CLOSED = "Fechado",
}

export const selectedBillingsAtom = atom([] as any[]);
export const allBillingsAtom = atom(0);

const Billing = () => {
	const [billingsWindow, setBillingsWindow] = useState<boolean>(false);
	const [billingsId, setBillingsId] = useState<any>([]);
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
		status: BillingStatus.ALL_STATUSES,
		vendorName: "All vendors",
	});
	const [selectedBillings, setSelectedBillings] = useAtom(selectedBillingsAtom);
	const [batch, setBatch] = useState<boolean>(false);
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const { isLoading: loadShipments, data: shipments } = useQuery(
		`/payouts/shipments?${queryString.stringify(debouncedConfig)}`,
		() => getPaginatedShipments(debouncedConfig)
	);

	const { isLoading: loadAllTreasury, data: allTreasury } = useQuery(
		`/payouts/treasury/all`,
		() => getAllTreasury()
	);

	if (batch) {
		return <CreatePayment billings={selectedBillings} setBatch={setBatch} />;
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<>
					<BatchBillingsDropDown
						allTreasury={!loadAllTreasury ? allTreasury : []}
						selectedBillings={selectedBillings}
						setSelectedBillings={setSelectedBillings}
						setBatch={setBatch}
					/>
					{billingsWindow ? (
						<SingleBilling
							setBillingsWindow={setBillingsWindow}
							billingsId={billingsId}
						/>
					) : (
						<>
							<div className="table-main-cont">
								<BillingPayoutsNav />
								<BillingTable
									shipments={shipments}
									setBillingsWindow={setBillingsWindow}
									setBillingsId={setBillingsId}
									setCurrentConfig={setCurrentConfig}
									currentConfig={currentConfig}
									loadShipments={loadShipments}
								/>
							</div>
						</>
					)}
				</>
			</div>
		</div>
	);
};

export default memo(Billing);
