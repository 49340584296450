import React, { createContext, useEffect, useState } from "react";
import "./pim.css";
import { withRouter, RouteComponentProps } from "react-router-dom";
import TabsPanel from "./tabs/Tabs";
import { PimOpenTabs } from "./PimMain";
import { addOpenTab } from "../../../actions/pim";
import { CreateProductModal } from "./CreateProductModal";
import {
	checkProductInfo,
} from "../../../actions/marketplace";
import Preloader from "../../ui/preloader/Preloader";
import * as _ from "lodash";

type TParams = {
	zeoosName: string;
	sku: string;
};

export const objKey = (obj: any) => {
	return Object.keys(obj)[0];
};

export const getContent = (obj: any, pimData: any) => {
	const objKeys = Object.keys(pimData);
	// eslint-disable-next-line
	const content = objKeys.filter((key: string) => {
		if (obj.name === key) {
			return pimData[key];
		}
	});
	return pimData[content[0]];
};

export const SkuContext = createContext<string>("");

const Pim: React.FC<RouteComponentProps<TParams>> = (props) => {
	const sku = props.match.params.sku as string;

	const [createProductModal, setCreateProductModal] = useState(false);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		addOpenTab(sku);
	}, [sku]);

	useEffect(() => {
		async function initializeState() {
			const res = await checkProductInfo(sku);
			setLoading(false);

			if (!res.productInfo) {
				setCreateProductModal(true);
			}
		}

		initializeState();
		// eslint-disable-next-line
	}, []);

	return (
		<SkuContext.Provider value={sku}>
			<div className="main-body">
				<div className="main-container">
					<PimOpenTabs />
					{!loading ? (
						<>
							<TabsPanel sku={sku} />
							{createProductModal && (
								<CreateProductModal
									modal={createProductModal}
									setModal={setCreateProductModal}
								/>
							)}
						</>
					) : (
						<Preloader />
					)}
				</div>
			</div>
		</SkuContext.Provider>
	);
};

export default withRouter(Pim);
