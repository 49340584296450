import React, { useMemo } from 'react';
import MultiSelect from '../multi-select';
import useSanitizedMarketplaces from '../../../hooks/useSanitizedMarketplaces';

interface Props {
  value: string;
  setValue: (newValue: string) => void;
}

const PlatformSelect: React.FC<Props> = ({ value, setValue }) => {
  const { data: platforms } = useSanitizedMarketplaces();
  const options = useMemo(() =>
    platforms?.map((platform) => ({ label: platform.zeoosName, value: platform._id })) || [], [platforms]);

  return (
    <MultiSelect
      helperText='Select Platform'
      value={value}
      setValue={setValue as any}
      options={options}
      multiple={false}
    />
  );
};

export default PlatformSelect;
