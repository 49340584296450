import React, { useState } from "react";
import { Paginator } from "../../../ui/pagination/Paginator";

export default function TreasuryTable() {
  const [paginatedData, setPaginatedData] = useState<number>();
  const [limit, setLimit] = useState<number>(50);

  return (
    <div className="table-main-cont">
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="table-search-box">
            <form
              className="table-search-form ml2"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className="table-search-input"
                placeholder="Search product"
              />
              <img
                className="table-search-image"
                src="/icons/search.svg"
                width="25px;"
                alt=""
              />
            </form>
          </div>
          <div className="table-main-title-text">670 Billings</div>
        </div>
        <div className="marketplace-pagination">
          <Paginator
            data={{}}
            rowsPerPage={limit}
            setPaginatedData={setPaginatedData}
            setLimit={setLimit}
            limit={limit}
          />
        </div>
      </div>
      <div className="table-border-bottom"></div>
      <div style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
        >
          <thead>
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="ml3 mr3">
                <img className="pointer" src="/icons/check-box.svg" alt="" />
              </th>
              <th className="treasury-id">Billing ID</th>
              <th className="treasury-order">ID Order</th>
              <th className="treasury-sku">[SKU] Product Name</th>
              <th className="treasury-name">Plaform Name</th>
              <th className="treasury-seller">Seller</th>
              <th className="treasury-country">Customer Country</th>
              <th className="treasury-products">Qtt Solds</th>
              <th className="treasury-amount">Amount</th>
              <th className="treasury-date">Date / Time</th>
              <th className="treasury-status">Status</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
        <div>
          <table className="table-results p0">
            <thead></thead>
            <tbody>
              <tr className="table-result-box billing-text">
                <td className="ml3 mr3">
                  <img
                    className="pointer"
                    src="/icons/check-box-checked.svg"
                    alt=""
                  />
                </td>
                <td className="treasury-id bold">LB AAAB0011</td>
                <td className="treasury-order">
                  32021
                  <img className="pl1" src="/icons/treasure-id.svg" alt="" />
                </td>
                <td className="treasury-sku">[SKU] Product Name</td>
                <td className="treasury-name">Louis Bourgon</td>
                <td className="treasury-seller">Lindborg Wines</td>
                <td className="treasury-country">
                  <img width="28px" src="/icons/spain.svg" alt="" />
                </td>
                <td className="treasury-products">
                  {/* <img className="mr1" src="/icons/paybatch-product.svg" alt="" /> */}
                  2
                </td>
                <td className="treasury-amount bold">22,00 €</td>
                <td className="treasury-date">
                  2021/09/17
                  <br />
                  8:45 PM
                </td>
                <td className="treasury-status">
                  <div className="paybatch-open">Open</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
