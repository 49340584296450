import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getCategoryLabel, useCategories } from "../../../actions/v2";
import { Category } from "types/category";

interface Props {
  value: string;
  setValue: (newValue: string) => void;
  setName?: (newValue: string) => void; //sets the value selected to be the category name instead of code
  useId?: boolean;  //if true sets the value selected to be the category id instead of code
}

const SubcategorySelect: React.FC<Props> = ({ value, setValue, setName, useId = false }) => {
  const { data: categories1 } = useCategories({ level: 1 });
  const [category1, setCategory1] = useState<string>();

  const { data: categories2 } = useCategories(
    { level: 2, code: category1 },
    { enabled: !!category1 }
  );
  const [category2, setCategory2] = useState<string>();

  const { data: categories3 } = useCategories(
    { level: 3, code: category2 },
    { enabled: !!category2 }
  );
  const [category3, setCategory3] = useState<string>();

  const getCategoryNameByCode = (categories: Category[], code: string) => {
    return categories.find((ct) => ct.code === code)?.name;
  };

  const getCategoryId = (categories: Category[], code: string) => {
    return categories.find((ct) => ct.code === code)?._id!;
  };

  return (
    <>
      <div className="guided-item">
        <p>Category:</p>
        <Select
          className="guided-item-select"
          value={category1 || ""}
          onChange={(e) => {
            setCategory1(e.target.value);
            setValue(useId ? getCategoryId(categories1!, e.target.value) : e.target.value);
            if (setName) {
              setName(getCategoryNameByCode(categories1!, e.target.value)!);
            }
          }}
        >
          {categories1?.map((category) => (
            <MenuItem value={category.code} key={category._id}>
              {getCategoryLabel(category)}
            </MenuItem>
          ))}
        </Select>
      </div>

      {category1 && !!categories2?.length && (
        <div className="guided-item" style={{ alignItems: "start" }}>
          <p>Subcategory:</p>
          <div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <img src="/icons/arrow-bounce.svg" alt="arrow-bounce icon" />
              <Select
                className="guided-item-select"
                style={{ width: "252px" }}
                value={category2 || ""}
                onChange={(e) => {
                  setCategory2(e.target.value);
                  setValue(useId ? getCategoryId(categories2!, e.target.value) : e.target.value);
                  if (setName) {
                    setName(getCategoryNameByCode(categories2!, e.target.value)!);
                  }
                }}
              >
                {categories2?.map((category) => (
                  <MenuItem value={category.code} key={category._id}>
                    {getCategoryLabel(category)}
                  </MenuItem>
                ))}
              </Select>
            </div>

            {category2 && !!categories3?.length && (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <img src="/icons/arrow-bounce.svg" alt="arrow-bounce icon" />
                <Select
                  className="guided-item-select"
                  style={{ width: "252px" }}
                  value={category3 || value}
                  onChange={(e) => {
                    setCategory3(e.target.value);
                    setValue(useId ? getCategoryId(categories3!, e.target.value) : e.target.value);
                    if (setName) {
                      setName(getCategoryNameByCode(categories3!, e.target.value)!);
                    }
                  }}
                >
                  {categories3?.map((category) => (
                    <MenuItem value={category.code} key={category._id}>
                      {getCategoryLabel(category)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SubcategorySelect;
