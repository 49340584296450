import React from "react";
import {
	makeStyles,
	useTheme,
	Theme,
	createStyles,
} from "@material-ui/core/styles";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import Header from "../header/Header";
import Navigation from "../navigation/Navigation";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			transition: theme.transitions.create(["margin", "width"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			height: "60px",
			display: "flex",
			justifyContent: "center",
			boxShadow: "none !important",
		},
		appBarShift: {
			marginLeft: drawerWidth,
			transition: theme.transitions.create(["margin", "width"], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		menuButton: {
			boxShadow: "none",
			background: "#920023",
		},
		hideIconMenu: {
			width: "0",
			paddingLeft: "240px",
		},
		hide: {
			display: "none",
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
			overflow: "hidden",
		},
		drawerHeader: {
			display: "flex",
			alignItems: "center",
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
			justifyContent: "flex-end",
		},
	})
);

interface Props {
	open: boolean;
	setOpen: (modal: boolean) => void;
}

const PanelBar: React.FC<Props> = ({ setOpen, open }) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<>
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, open && classes.appBarShift)}
			>
				<div className="main-header-cont">
					<div className={`icon-menu ${clsx(open && classes.hideIconMenu)}`}>
						<IconButton
							color="inherit"
							style={{ padding: "0" }}
							aria-label="open drawer"
							onClick={() => setOpen(true)}
							edge="start"
							className={clsx(classes.menuButton, open && classes.hide)}
						>
							<div className="nav-logo-box">
								<MenuIcon className="burger-icon" />
								{/* <div className="ml1">Zeoos</div> */}
								<img
									src="/icons/logo_zeoos.svg"
									alt=""
									className="vinuus-portal-image"
								/>
							</div>
						</IconButton>
					</div>
					<Header />
				</div>
			</AppBar>

			<Drawer
				id="drawer"
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className="icon-nav-panel">
					<div className={classes.drawerHeader}>
						<IconButton
							className="no-shadow ml3"
							onClick={() => setOpen(false)}
						>
							{theme.direction === "ltr" ? (
								<ChevronLeftIcon className="arrow-panel" />
							) : (
								<ChevronRightIcon />
							)}
						</IconButton>
					</div>
				</div>
				<Navigation />
			</Drawer>
		</>
	);
};

export default PanelBar;
