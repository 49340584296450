import React, { useContext, useState } from "react";
import "./marketplaces.css";
import {
	getSupportedMarketplaces,
	getMarketplaceSetupConfig,
	createMarketplace,
	editMarketplace,
	deleteMarketplace,
} from "../../../actions/marketplace";
import { IMarketplace } from "../../../interfaces";
import Preloader from "../../ui/preloader/Preloader";
import { CountriesContext, UserContext } from "../../context";
import { boolean } from "boolean";
import { toast } from "react-toastify";
import { ICountryM } from "../country-management/CountryManagement";
import { queryClient } from "../../App";
import { useMutation, useQuery } from "react-query";
import { Modal } from "../../ui/modal/Modal";
import { useHistory } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import { useDropzone } from "react-dropzone";

interface Props {
	marketplace?: IMarketplace;
	setModal: (modal: boolean) => void;
	modal: boolean;
	action: "edit" | "create";
}

export const firstElByAlphabet = (array: string[]) => {
	return array.sort()[0];
};

declare const confirm: (question: string) => boolean;

const FILE_TYPES = [".svg", ".png", ".jpg", ".jpeg"];

export const CreateEditMarketplaceForm: React.FC<Props> = ({
	marketplace,
	setModal,
	modal,
	action,
}) => {
	const { user } = useUser();
	const countries = useContext(CountriesContext) as ICountryM[];
	let history = useHistory();

	const [zeoosName, setZeoosName] = useState<string>(
		marketplace?.zeoosName || ""
	);
	const [type, setType] = useState<string>(marketplace?.type || "");
	const [marketplaceName, setMarketplaceName] = useState<any>(
		marketplace?.marketplaceName
	);
	const [country, setCountry] = useState<string>(
		marketplace?.country || countries[0].name
	);
	const [isManual, setIsManual] = useState(boolean(marketplace?.isManual));
	const [active, setActive] = useState(
		marketplace ? boolean(marketplace?.active) : true
	);
	const [credentials, setCredentials] = useState(
		marketplace?.credentials || ({} as any)
	);

	const { isLoading: loadSupportedMarketplaces, data: supportedMarketplaces } =
		useQuery("supportedMarketplaces", () => getSupportedMarketplaces());

	const { isLoading: loadMarketplaceConfig, data: marketplaceConfig } =
		useQuery("marketplaceConfig", () => getMarketplaceSetupConfig());

	const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
		accept: FILE_TYPES,
		onDropRejected: () => {
			toast.error(`File type rejected. Select one of ${FILE_TYPES.join(", ")}}`);
		},
		maxFiles: 1,
		// 10mb
		maxSize: 1e7,
	});

	const actionMarketplaceConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries("marketplaces");
			queryClient.invalidateQueries(
				`single-marketplace?zeoosName=${marketplace?.zeoosName}`
			);
			toast.success(
				`The platform is successfully ${action === "edit" ? "edited" : "created"
				}!`
			);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const createMutation = useMutation(
		(marketplaceFields) => createMarketplace(marketplaceFields),
		actionMarketplaceConfig
	);

	const editMutation = useMutation(
		(marketplaceFields) => editMarketplace(marketplaceFields),
		actionMarketplaceConfig
	);

	const onSubmitHandler = async (e: React.MouseEvent) => {
		e.preventDefault();

		const marketplaceFields = marketplace || ({} as any);
		marketplaceFields.zeoosName = zeoosName;
		marketplaceFields.marketplaceName = marketplaceName;
		marketplaceFields.type = type;
		marketplaceFields.country = country;
		marketplaceFields.additionalPricePerCountry =
			marketplace?.additionalPricePerCountry || 0;
		marketplaceFields.wineVatRate = marketplace?.wineVatRate || 0;
		marketplaceFields.champagneVatRate = marketplace?.champagneVatRate || 0;
		marketplaceFields.wineIecRate = marketplace?.wineIecRate || 0;
		marketplaceFields.champagneIecRate = marketplace?.champagneIecRate || 0;
		marketplaceFields.credentials = credentials;
		marketplaceFields.userToken = user.accessToken;
		marketplaceFields.isManual = isManual;
		marketplaceFields.active = active;
		marketplaceFields.marketplaceImg =
			acceptedFiles.length > 0 ? acceptedFiles[0] : marketplace?.marketplaceImg;

		if (action === "edit") {
			await editMutation.mutateAsync(marketplaceFields as any);
		} else {
			await createMutation.mutateAsync(marketplaceFields as any);
		}

		setModal(false);
	};

	const deleteButtonHandler = async () => {
		const result = confirm("Are you sure?");
		if (result) {
			await deleteMarketplace(zeoosName);
			history.push("/platforms");
		}
	};

	if (loadSupportedMarketplaces || loadMarketplaceConfig) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<Modal
			onModalClose={(e: React.MouseEvent) => {
				e.stopPropagation();
				setModal(false);
			}}
			isOpened={modal}
		>
			<form id="email-form" name="email-form" className="formEdit">
				<div className="formEdit__container">
					<div className="in-row align-center justify-between width100">
						<h3 className="create-heading">
							{action === "create" ? "Create Platform:" : "Edit Platform:"}
						</h3>
						<div
							className="in-row align-center justify-between width13 pointer mr4 mt3"
							onClick={deleteButtonHandler}
						>
							<img
								src="/icons/grey-trash-can.svg"
								alt=""
								width="20px;"
								height="20px;"
							/>
							<span>delete</span>
						</div>
					</div>
					<div className="table-border-bottom pb2"></div>
					{action === "create" && (
						<>
							<div className="in-column width100 align-start pt3">
								<span>Platform name:</span>
								<input
									type="text"
									className="formEdit__input"
									required
									onChange={(e) => setZeoosName(e.target.value)}
								/>
							</div>
						</>
					)}
					<div className="in-column width100 align-start pt3">
						<span>Type:</span>
						<input
							type="text"
							className="formEdit__input"
							required
							value={type}
							onChange={(e) => setType(e.target.value)}
						/>
					</div>
					<div className="in-row width100 pt2 pb2">
						<div className="in-column width100 align-start pr1">
							<span>Platform</span>
							<select
								className="formEdit__input"
								required
								value={marketplaceName}
								onChange={(e) => setMarketplaceName(e.target.value)}
							>
								{supportedMarketplaces
									.sort()
									.map((marketplace: string, index: number) => (
										<option key={index}>{marketplace}</option>
									))}
							</select>
						</div>
						<div className="in-column width100 align-start">
							<span>Country:</span>
							<select
								className="formEdit__input"
								required
								value={country}
								onChange={(e) => setCountry(e.target.value)}
							>
								{countries.map((country: ICountryM, index: number) => (
									<option key={index}>{country.name}</option>
								))}
							</select>
						</div>
					</div>
					<div className="in-row width100 pt1 pb1 align-center">
						<label htmlFor="is-manual" className="pointer mr1">
							<span>Manual:</span>
						</label>
						<input
							id="is-manual"
							type="checkbox"
							required
							defaultChecked={isManual}
							onChange={(e) => setIsManual(e.target.checked)}
						/>
					</div>
					<div className="in-row width100 pt1 pb1 align-center">
						<label htmlFor="active" className="pointer mr1">
							<span>Active:</span>
						</label>
						<input
							id="active"
							type="checkbox"
							required
							defaultChecked={active}
							onChange={(e) => setActive(e.target.checked)}
						/>
					</div>
					<div className="table-border-bottom" />
					<div className="table-vendormm-title-image">
						<div className="table-vendormm-title-imagebg" {...getRootProps()}>
							{/* @ts-ignore */}
							{action !== "view" && <input {...getInputProps()} />}
							{marketplace?.marketplaceImg && !acceptedFiles.length && (
								<img
									src={marketplace?.marketplaceImg}
									width="210px"
									height="160px"
									alt=""
								/>
							)}
							{!marketplace?.marketplaceImg && (
								<div>
									<h2 style={{ color: "#000" }}>
										{acceptedFiles.length > 0
											? `Click 'Apply' to finish upload ${acceptedFiles[0].name}`
											: "Drop the file here"}
									</h2>
									{!acceptedFiles.length && (
										<div className="table-vendormm-title-image-desc">
											<div className="table-vendormm-title-image-text">
												Add 480 x 360 image |&nbsp;
											</div>
										</div>
									)}
								</div>
							)}
							{marketplace?.marketplaceImg && acceptedFiles.length ? (
								<h2 style={{ color: "#000" }}>
									Click 'Apply' to finish upload {acceptedFiles[0].name}
								</h2>
							) : (
								<></>
							)}
						</div>
					</div>
					{marketplaceConfig[marketplaceName] &&
						Object.keys(marketplaceConfig[marketplaceName]).map(
							(key: string) => (
								<div className="in-column width100 pr1">
									<span>{marketplaceConfig[marketplaceName][key]?.label}:</span>
									<input
										type="text"
										className="formEdit__input"
										required
										value={credentials[key]}
										onChange={(e) =>
											setCredentials((prev: any) => ({
												...prev,
												[key]: e.target.value,
											}))
										}
									/>
								</div>
							)
						)}
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box width100">
						<button
							className="table-modal-form-button-cancel"
							onClick={(e: React.MouseEvent) => {
								e.stopPropagation();
								setModal(false);
							}}
						>
							Cancel
						</button>
						<input
							type="submit"
							value={action === "create" ? "Create" : "Edit"}
							className="table-modal-form-button"
							onClick={onSubmitHandler}
						/>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export const RenderMarketplaceSetupField: React.FC<any> = ({
	keyword,
	marketplaceName,
	marketplaceConfig,
	children,
}) => {
	const config = marketplaceConfig?.[marketplaceName]?.[keyword];

	if (config?.label) {
		return (
			<>
				<span>{config?.label}:</span>
				{children}
			</>
		);
	}

	return null;
};
