import React from "react";
import "./businessIntelligence.css";
import useUser from "../../../hooks/useUser";
import { Role } from "../../../interfaces";

interface Props {
	setSection: (country: string) => void;
	section: string;
}

export const SelectSection: React.FC<Props> = ({ setSection, section }) => {
	const { user } = useUser();

	const sections = [Role.admin].includes(user.role)
		? [
			"B2C",
			// "FlashSales",
			"B2B",
			"General",
			// "S&OP"
		]
		: ["General"];

	return (
		<div className="table-title-sort">
			<div className="gestao-select-cont">
				{sections.map((item: any, index: number) => (
					<button
						className={`gestao-select-button ${section === item ? "gestao-active" : ""
							}`}
						key={index}
						onClick={(e) => {
							e.preventDefault();
							setSection(item);
						}}
					>
						{item}
					</button>
				))}
			</div>
		</div>
	);
};
