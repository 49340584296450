import React, { useContext } from "react";
import { CountriesContext } from "../../context";
import { ICountryM } from "../../routes/country-management/CountryManagement";

const CountryFlag: React.FC<any> = ({ country, ...props }) => {
	const countries = useContext(CountriesContext) as ICountryM[];

	const flag = countries.find((x: any) => country === x.name)?.iconUrl as any;

	if (!flag) {
		return <span>{country}</span>;
	}

	return <img {...props} src={flag} alt={country} />;
};

export default CountryFlag;
