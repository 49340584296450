import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "../../ui/modal/Modal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SkuContext } from "./Pim";
import { toast } from "react-toastify";
import { translate } from "../../../actions/translator";
import { getProductInfo, saveProductInfo } from "../../../actions/marketplace";
import {
  ProductInfoContext,
  PimLanguageContext,
  useLanguages,
} from "./tabs/Tabs";

interface Props {
  rules: any;
}

const isTranslatable = (input: any) =>
  (typeof input === "string" && input?.length > 1) ||
  (typeof input === "object" && input?.join().length > 1);

const PimTranslator: React.FC<Props> = ({ rules }) => {
  const { productInfo, setProductInfo } = useContext(ProductInfoContext);
  const { pimLanguage } = useContext(PimLanguageContext);
  const countries = useLanguages();
  const sku = useContext(SkuContext);

  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  const handleTranslate = async (
    input: any,
    langCode: string,
    showError = true
  ) => {
    if (!isTranslatable(input)) {
      throw new Error(`Need more characters to translate`);
    }

    const split = typeof input === "object" && input.length >= 1;

    try {
      let resp = await translate({
        text: encodeURIComponent(
          typeof input === "object" ? input.join() : input
        ),
        langCode: langCode.toUpperCase(),
        sourceLang: pimLanguage,
      });

      return split ? resp.split(",").map((i: any) => i.trim()) : resp;
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  const handleSaveProductInfo = async (value: string, langCode: string) => {
    if (!isTranslatable(value)) {
      return toast.error("Translated field is empty!");
    }

    try {
      const { productInfo: productInfoToTranslate } = await getProductInfo(
        sku,
        langCode
      );

      const newProperties = {
        ...productInfoToTranslate,
        details: {
          ...productInfoToTranslate.details,
          [rules.name]: value,
        },
      };

      await saveProductInfo(newProperties);
      setProductInfo((prev: any) => ({ ...prev, version: prev.version + 1 }));
      toast.success(`Success! Filed saved for language ${langCode}`);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong!");
    }
  };

  const otherLangs = useMemo(
    () => countries.filter((c) => c.langCode !== pimLanguage),
    [countries, pimLanguage]
  );

  if (!rules.localized || hasTranslationOptions(rules)) {
    return <></>;
  }

  return (
    <>
      <div className="pim-translator-cont">
        <img
          onClick={() => {
            setModal(true);
          }}
          className="pointer"
          src="/icons/translator-pim.svg"
          alt=""
          width="25px;"
        />
        {otherLangs.map(({ langCode }: any, index: number) => (
          <React.Fragment key={index}>
            <button
              className="pim-translator-text"
              onClick={async (e) => {
                e.preventDefault();
                try {
                  await handleSaveProductInfo(
                    await handleTranslate(
                      productInfo.details[rules.name],
                      langCode
                    ),
                    langCode
                  );
                } catch (error: any) {
                  toast.error(error.message);
                }
              }}
            >
              {langCode}
            </button>
            {index !== countries.length - 1 && (
              <span className="pim-translator-text">.</span>
            )}
          </React.Fragment>
        ))}
      </div>
      {modal && (
        <TranslateFieldModal
          onModalClose={onModalClose}
          modal={modal}
          rules={rules}
          handleTranslate={handleTranslate}
          handleSaveProductInfo={handleSaveProductInfo}
        />
      )}
    </>
  );
};

export const TranslateFieldModal: React.FC<any> = ({
  onModalClose,
  modal,
  rules,
  handleTranslate,
  handleSaveProductInfo,
}) => {
  const countries = useLanguages();
  const { productInfo } = useContext(ProductInfoContext);
  const { pimLanguage } = useContext(PimLanguageContext);

  const [input, setInput] = useState(productInfo.details[rules?.name] || "");
  const [output, setOutput] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("en");

  useEffect(() => {
    if (isTranslatable(input)) {
      handleTranslate(input, targetLanguage)
        .then((text: string) => setOutput(text || ""))
        .catch(console.error);
    }
    // eslint-disable-next-line
  }, [targetLanguage]);

  const country = countries.find(
    ({ langCode }: any) => langCode === pimLanguage
  );

  return (
    <Modal onModalClose={onModalClose} isOpened={modal}>
      <div className="translator-main-modal-cont">
        <div className="table-modal-title-box">
          <div className="table-modal-title-pricing">Traduzir</div>
        </div>
        <form
          className="table-modal-form-cont"
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveProductInfo(output, targetLanguage);
          }}
        >
          <div className="table-modal-border"></div>
          <div className="mb5">
            <div className="pim-translator-modal-cont">
              <div className="initial">{rules.label}:</div>
              {rules?.validation?.maxChars && (
                <span className="pim-subtext">
                  {input.length}/{rules.validation.maxChars}
                </span>
              )}
            </div>
            <textarea
              className="pim-translator-modal-input"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
          <div className="pim-translator-modal-box">
            <img src={country!.iconUrl} alt="" width="30px;" />
            <div className="pim-translator-modal-text">{country?.language}</div>
            <img src="/icons/translator-arrow.svg" alt="" width="35px;" />
            <Select
              className="pim-count-select mwidth150"
              labelId="label"
              id="select"
              value={targetLanguage}
              onChange={(e: any) => setTargetLanguage(e.target.value)}
            >
              {countries
                .filter((x) => x.langCode !== pimLanguage)
                .map((item: any, index: number) => (
                  <MenuItem value={item.langCode} key={index}>
                    <div className="in-row align-center">
                      <img
                        className="mr1"
                        src={item.iconUrl}
                        alt=""
                        width="20px;"
                      />
                      <div className="pim-language-text">{item.language}</div>
                    </div>
                  </MenuItem>
                ))}
            </Select>
            <button
              onClick={async (e) => {
                e.preventDefault();

                try {
                  setOutput(await handleTranslate(input, targetLanguage));
                } catch (error: any) {
                  toast.error(error.message);
                }
              }}
              className="pim-translator-modal-button"
            >
              Traduzir
            </button>
          </div>
          <div className="table-modal-border"></div>
          <div className="mb5">
            <div className="pim-translator-modal-cont">
              <div className="initial">{rules.label}:</div>
              {rules?.validation?.maxChars && (
                <span className="pim-subtext">
                  {output.length}/{rules.validation.maxChars}
                </span>
              )}
            </div>
            <textarea
              className="pim-translator-modal-input"
              value={output}
              onChange={(e) => setOutput(e.target.value)}
            />
          </div>
          <div className="table-modal-form-button-box width100">
            <button
              className="table-modal-form-button-cancel"
              onClick={onModalClose}
            >
              Cancelar
            </button>
            <button className="table-modal-form-button" type="submit">
              salvar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export const hasTranslationOptions = (rules: any) => {
  return (
    Array.isArray(rules.translationValue) && !!rules.translationValue.length
  );
};

export default PimTranslator;
