import React, { useCallback, useState } from "react";
import { Modal } from "../../ui/modal/Modal";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import { getProductBySku } from "../../../actions/pim";
import { useHistory } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createProduct, createProducts } from "../../../actions/v2/product";
import { CreateProduct } from "types/product";
import SubcategorySelect from "../../ui/subcategory-select";
import { getCategoryTemplate, getMasterTemplate } from "../../../actions/v2";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import { template } from "lodash";

type Props = {
  modal: boolean;
  setModal: (modal: boolean) => void;
  creationType?:string
};

export const CreateProductModal: React.FC<Props> = ({
  modal,
  setModal,
  creationType
}) => {
  const [modalView, setModalView] = useState<string>(creationType || '');

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <>
            <div className="pim-modal-form-container">
              <div className="pim-modal-form-content">
                {modalView ? (
                  <>
                    {modalView === "guided" ? <CreateProductForm close={() => setModal(false)} /> :
                      <MassCreateProductForm close={() => setModal(false)} />
                    }
                  </>
                ) : (
                  <>
                    <div className="pim-modal-title-sku">
                      Choose how to create products
                    </div>
                    <div className="table-modal-border"></div>
                    <div className="pim-modal-first">
                      <div
                        className="pim-modal-first-item"
                        onClick={() => setModalView("guided")}
                      >
                        <img src="/icons/guided.svg" alt="guided icon" />
                        <p>Guided product creation</p>
                      </div>
                      <div className="pim-modal-first-item-border"></div>
                      <div
                        className="pim-modal-first-item"
                        onClick={() => setModalView("mass")}
                      >
                        <img src="/icons/mass.svg" alt="mass icon" />
                        <p>Mass product creation</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

const CreateProductForm: React.FC<{ close: () => void; }> = ({ close }) => {
  const history = useHistory();
  const [viewAddCustomClass, setViewAddCustomClass] = useState(false);
  const [stepGuided, setStepGuided] = useState(1);

  const [data, setData] = useState<CreateProduct>({
    ean: "",
    name: "",
    description: "",
    model: "",
    brand: "",
    category: "",
    images: [],
    logisticClass: undefined,
    details: {},
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const product = await createProduct(data);

      toast.success("Success! New product created!");
      close();

      history.push(`/productInfo/${product.sku}`);
    } catch (error: any) {
      console.error(error);
      toast.error(error.response?.data?.error || "Something went wrong");
    }

    setLoading(false);
  }, [data, history, close]);

  return (
    <>
      <div
        className="pim-modal-title-sku"
        style={{ textAlign: "left", width: "100%" }}
      >
        Create Product
      </div>

      <div className="guided-first-top">
        <div className="guided-first-top-numbers">
          <p
            style={{
              background:
                stepGuided === 1 ? "#006DD2" : "#9E9E9E",
            }}
          >
            1
          </p>
          <div className="border"></div>
          <p
            style={{
              background:
                stepGuided === 2 ? "#006DD2" : "#9E9E9E",
            }}
          >
            2
          </p>
          <div className="border"></div>
          <p
            style={{
              background:
                stepGuided === 3 ? "#006DD2" : "#9E9E9E",
            }}
          >
            3
          </p>
        </div>
        <div className="guided-first-top-titles">
          <p style={{ fontWeight: stepGuided === 1 ? 700 : 400 }}>
            Basic information
          </p>
          <p style={{ fontWeight: stepGuided === 2 ? 700 : 400 }}>
            Category
          </p>
          <p
            style={{
              width: "128px",
              textAlign: "center",
              fontWeight: stepGuided === 3 ? 700 : 400,
            }}
          >
            Images
          </p>
        </div>
      </div>

      {stepGuided === 1 && (
        <>
          <div
            style={{ marginTop: "30px" }}
            className="guided-item"
          >
            <p>EAN Code:</p>
            <TextField
              value={data.ean}
              type="text"
              className="product-title"
              style={{ width: "148px" }}
              onChange={(e) => setData({ ...data, ean: e.target.value })}
            />
          </div>
          <div className="guided-item">
            <p>Product name:</p>
            <TextField
              value={data.name}
              type="text"
              className="product-title"
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </div>
          <div className="guided-item">
            <p>Description:</p>
            <TextField
              value={data.description}
              type="text"
              className="product-title"
              style={{ height: "60px" }}
              onChange={(e) => setData({ ...data, description: e.target.value })}
              multiline
            />
          </div>
          <div className="guided-item">
            <p>Model:</p>
            <TextField
              value={data.model}
              type="text"
              style={{ width: "238px" }}
              className="product-title"
              onChange={(e) => setData({ ...data, model: e.target.value })}
            />
          </div>
          <div
            style={{ marginBottom: "20px" }}
            className="guided-item"
          >
            <p>Brand:</p>
            <TextField
              value={data.brand}
              type="text"
              style={{ width: "238px" }}
              className="product-title"
              onChange={(e) => setData({ ...data, brand: e.target.value })}
            />
          </div>
          <div className="table-modal-border"></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <button
              onClick={close}
              className="table-modal-form-button-cancel"
            >
              Cancel
            </button>
            <button
              disabled={!data.ean || !data.name || !data.model || !data.brand}
              onClick={() => setStepGuided(2)}
              className="guided-button"
              style={{ opacity: !data.ean || !data.name || !data.model || !data.brand ? 0.5 : 1 }}
            >
              NEXT
            </button>
          </div>
        </>
      )}

      {stepGuided === 2 && (
        <>
          <SubcategorySelect value={data.category} setValue={category => setData(d => ({ ...d, category }))} />
          <div
            className="table-modal-border"
            style={{ marginTop: "32px" }}
          ></div>
          <div className="guided-item">
            <p>Logistic class:</p>
            <span style={{ fontWeight: 700 }}>
              Inherited from category
            </span>
          </div>
          {viewAddCustomClass ? (
            <div
              style={{
                width: "100%",
                background: "#F2F2F2",
                padding: "12px 18px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <p>Custom logistic class:</p>
              <Select
                className="guided-item-select"
                style={{ width: "213px" }}
                value={data.logisticClass || ""}
                onChange={(e) => setData({ ...data, logisticClass: e.target.value as any })}
              >
                {[
                  "Small & Non-Heavy",
                  "Mid-Heavy",
                  "Heavy",
                ].map(x => (
                  <MenuItem value={x} key={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "36px",
                  height: "36px",
                  background: "white",
                  boxShadow: "none",
                }}
                onClick={() => {
                  setData({ ...data, logisticClass: undefined });
                  setViewAddCustomClass(false);
                }}
              >
                <img src="/icons/basket.svg" alt="icons basket" />
              </button>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <button
                onClick={() => setViewAddCustomClass(true)}
                className="table-modal-form-button-cancel"
                style={{ color: "#006DD2", paddingLeft: 0 }}
              >
                Add a custom logistic class
              </button>
            </div>
          )}
          <div
            className="table-modal-border"
            style={{ marginTop: "32px" }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <button
              onClick={close}
              className="table-modal-form-button-cancel"
            >
              Cancel
            </button>
            <div style={{ display: "flex", gap: "9px" }}>
              <button
                onClick={() => setStepGuided(1)}
                style={{
                  background: "transparent!important",
                  color: "#55718A!important",
                  border: "1px solid #55718A!important",
                }}
                className="guided-button outlined"
              >
                BACK
              </button>
              <button
                disabled={!data.category}
                onClick={() => setStepGuided(3)}
                className="guided-button"
                style={{ opacity: !data.category ? 0.5 : 1 }}
              >
                NEXT
              </button>
            </div>
          </div>
        </>
      )}

      {stepGuided === 3 && (
        <>
          <div className="guided-item">
            <p>Image link 1:</p>
            <TextField
              value={data.images[0]}
              type="text"
              className="product-title"
              onChange={(e) => setData(data => ({ ...data, images: data.images.length ? [...data.images.map((x, i) => i === 0 ? e.target.value : x)] : [e.target.value] }))}
            />
          </div>

          {data.images.slice(1).map((image: string, index: number) => (
            <div className="guided-item">
              <p>Image link {index + 2}:</p>
              <TextField
                value={data.images[index + 1]}
                type="text"
                className="product-title"
                onChange={(e) => setData(data => ({ ...data, images: [...data.images.map((x, i) => i === index + 1 ? e.target.value : x)] }))}
              />
            </div>
          ))}

          <div style={{ width: "100%" }}>
            <button
              className="table-modal-form-button-cancel"
              style={{
                color: "#006DD2",
                paddingLeft: 0,
                marginTop: "14px",
              }}
              onClick={() => setData({ ...data, images: [...data.images, ""] })}
            >
              Add a new image link
            </button>
          </div>
          <div
            className="table-modal-border"
            style={{ marginTop: "32px" }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <button
              onClick={close}
              className="table-modal-form-button-cancel"
            >
              Cancel
            </button>
            <div style={{ display: "flex", gap: "9px" }}>
              <button
                onClick={() => setStepGuided(2)}
                style={{
                  background: "transparent!important",
                  color: "#55718A!important",
                  border: "1px solid #55718A!important",
                }}
                className="guided-button outlined"
              >
                BACK
              </button>
              <button
                disabled={!data.images[0] || loading}
                className="guided-button"
                onClick={handleSubmit}
              >
               {loading ? "CREATING....": "CREATE"}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

 const MassCreateProductForm: React.FC<{ close: () => void; }> = ({ close }) => {
  const [file, setFile] = useState<File>();
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingTemplate, setLoadingTemplate] = useState(false);

  const readUploadFile = useCallback(async (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!file) return;

    setLoading(true);

    try {
      await createProducts(file);
      toast.success("Success! We've sent a report to your email with the results!");
      close();
    } catch (error: any) {
      console.error(error);
      toast.error(error.response?.data?.error || "Something went wrong");
    }

    setLoading(false);
  }, [file, close]);

  const onDownloadTemplate = async () => {
    setLoadingTemplate(true)
    try {
      //if no category is selected use the master template
      if(!category){
        await getMasterTemplate()
      }else{
        await getCategoryTemplate(category)
      }
      toast.success("Template Downloaded Successfully");
    } catch (error:any) {
      toast.error("Error generating Template... Try Again");
    }finally{
      setLoadingTemplate(false)
    }
  }

  return (
    <div>
      <div
        className="pim-modal-title-sku"
        style={{ textAlign: "left", width: "100%" }}
      >
        Mass product creation
      </div>
      <div
        className="table-modal-border"
        style={{ width: "360px" }}
      ></div>
      <p style={{ marginBottom: "8px" }}>
        Category of products you want to create:
      </p>
      <SubcategorySelect value={category} setValue={setCategory} />

        <div
          className="bold mt2 in-row width100 align-center"
          style={{ color: "#55718a" }}
          onClick={onDownloadTemplate}
        >
          <div style={{ fontSize: "12px", display:'flex' }} className="pointer">
            <p style={{marginRight:'10px'}}>Download Template Spreadsheet</p>
          {loadingTemplate && <CircularPreloader/>}
          </div>
        </div>

      <p style={{ marginTop: "30px", marginBottom: "8px" }}>
        Upload the spreadsheet with your product data:
      </p>
      <div className="mass-choose-file">
        <img
          className="mr2"
          src="/icons/mass-choose-file.svg"
          alt="mass-choose-file icon"
        />
        <label htmlFor="upload">Upload the spreadsheet</label>
        <input
          type="file"
          name="file"
          id="upload"
          onChange={readUploadFile}
          hidden
          accept=".csv"
        />
      </div>
      {file?.name}
      <div
        className="table-modal-border"
        style={{ marginTop: "45px", marginBottom: "28px" }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <button
          onClick={close}
          className="table-modal-form-button-cancel"
        >
          Cancel
        </button>
        <div style={{ display: "flex", gap: "9px" }}>
          <button
            disabled={!file || loading}
            onClick={handleSubmit}
            className={
              file
                ? "guided-button"
                : "guided-button disabled"
            }
          >
            {loading ? "Sending..." : "Send to create"}
          </button>
        </div>
      </div>
    </div>
  );
};

interface IComponentsVariant {
  info?: any;
  setVariant: (sku: string) => void;
  setVariantQty: (qty: string) => void;
}

export const ComponentsVariant: React.FC<IComponentsVariant> = ({
  setVariant,
  setVariantQty,
  info,
}) => {
  const [parentName, setParentName] = useState<string>("");
  const [parent, setParent] = useState<string>(info?.parent?.sku);

  return (
    <div className="in-column align-start width100 mb2 components">
      <span className="mr2">Variant of SKU:</span>
      <div className="component-line">
        <TextField
          type="number"
          placeholder="SKU"
          size="small"
          className="component-sku"
          value={parent}
          title="Parent SKU"
          onChange={(e) => {
            setVariant(e.target.value);
            setParent(e.target.value);
          }}
          onBlur={async (e) => {
            let result = false;
            let msg = "";

            if (e.target.value) {
              const find = await getProductBySku(e.target.value);

              if (
                Object.keys(find.data).length === 0 ||
                !find.data.productInfo?.details?.title
              ) {
                msg = "SKU not found!";
              } else {
                if (
                  !find.data.productInfo.isMix &&
                  !find.data.productInfo.isVariant &&
                  !find.data.productInfo.variantOfSku &&
                  !find.data.productInfo.components.length
                ) {
                  setParentName(find.data.productInfo.details.title);
                  result = true;
                } else {
                  msg = "SKU is not a single product!";
                }
              }

              if (!result) {
                toast.error(msg);
                setVariant("");
                setParentName("");
                setParent("");
                e.target.value = "";
                e.target.focus();
              }
            } else {
              setParentName("");
            }
          }}
        />

        {!info && (
          <TextField
            type="number"
            placeholder="Qty"
            size="small"
            className="component-qty"
            title="Quantity"
            onChange={(e) => setVariantQty(e.target.value)}
          />
        )}

        <div className="component-title" title={parentName}>
          {parentName}
        </div>
      </div>
    </div>
  );
};

interface IComponentsMix {
  components: any[];
  quantities: any[];
  addComponent: (sku: string, qty: string, name: string) => void;
  removeComponent: (index: number) => void;
}

export const ComponentsMix: React.FC<IComponentsMix> = ({
  components,
  quantities,
  addComponent,
  removeComponent,
}) => {
  const [sku_add, setSku_add] = useState("");
  const [qty_add, setQty_add] = useState("");
  return (
    <div className="in-column align-start width100 mb2 components">
      <span className="mr2">SKU Components:</span>
      <div>
        <TextField
          type="number"
          placeholder="SKU"
          size="small"
          className="component-sku"
          value={sku_add}
          onChange={(e) => setSku_add(e.target.value)}
        />

        <TextField
          type="number"
          placeholder="Qty"
          size="small"
          className="component-qty"
          value={qty_add}
          onChange={(e) =>
            setQty_add(Math.abs(Number(e.target.value)).toString())
          }
        />
        <img
          src="/icons/add-circle-pim-ficha.svg"
          className="searchsku"
          alt=""
          width="22"
          onClick={async (e) => {
            let msg = "";
            let result = false;

            if (sku_add && qty_add) {
              const find = await getProductBySku(sku_add);

              if (
                Object.keys(find.data).length !== 0 &&
                find.data.productInfo?.details?.title
              ) {
                if (
                  !find.data.productInfo.isMix &&
                  !find.data.productInfo.isVariant &&
                  !find.data.productInfo.variantOfSku &&
                  !find.data.productInfo.components.length
                ) {
                  addComponent(
                    sku_add,
                    qty_add,
                    find.data.productInfo.details.title
                  );
                  setSku_add("");
                  setQty_add("");
                  result = true;
                } else {
                  msg = `SKU ${sku_add} is not a single product!`;
                }
              } else {
                msg = `SKU ${sku_add} not found!`;
              }

              if (!result) {
                toast.error(msg);
              }
            }
          }}
        />
      </div>
      {components.map((c: any, i: number) => {
        return (
          <div className="component-line mix" key={i}>
            <div>SKU: {c.sku}</div>
            <div>Qty: {quantities[i]}</div>
            <img
              src="/icons/grey-trash-can.svg"
              className="searchsku"
              alt=""
              title="Delete"
              width="20"
              onClick={(e) => removeComponent(i)}
            />
            <div className="component-title ml2" title={c.name}>
              {c.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};
