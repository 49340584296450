import React, { useMemo } from 'react';
import MultiSelect from '../multi-select';
import { useBrandsNew } from '../../../actions/v2';

interface Props {
  value: string;
  setValue: (newValue: string) => void;
  category?: string;
}

const BrandSelect: React.FC<Props> = ({ value, setValue, category }) => {
  const { data} = useBrandsNew({});
  const options = useMemo(() => data?.map((b) => ({ label: b.brand, value: b.brand })) || [], [data]);

  return (
    <MultiSelect
      helperText='Select Brand'
      value={value}
      setValue={setValue as any}
      options={options}
      multiple={false}
    />
  );
};

export default BrandSelect;
