import React from "react";
import "./navigation.css";
import { NavLink } from "react-router-dom";
// import { UserContext } from "../../context";
import RoleRequired from "../../utils/role-required/RoleRequired";
import {
	createPermission,
	PermissionScope,
	PermissionType,
} from "../../../actions/user";
import { Role } from "../../../interfaces";
import useUser from "../../../hooks/useUser";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { styled } from "@mui/material/styles";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	width: "100%",
}));

export default function Navigation() {
	const { user } = useUser();

	return (
		<>
			{user ? (
				<>
					<div className="main-nav">
						<div className="navigation-cont">
							<div className="width100">
								<div className="in-column justify-center align-center">
									<NavLink to="/">
										<img
											src="/icons/admin-zeoos.svg"
											alt=""
											width="110px"
											height="60px"
										/>
									</NavLink>
								</div>
								<RoleRequired roles={[Role.sellerAdmin, Role.sellerUser]}>
									{user.group?.image && (
										<>
											<div className="table-border-bottom"></div>
											<div className="in-column justify-center align-center mt2 mb2">
												<img
													src={user.group?.image}
													alt=""
													width="140px"
													height="100px"
												/>
											</div>
											<div className="table-border-bottom mb1"></div>
										</>
									)}
								</RoleRequired>
								<div className="navigation-menu-cont">
									<RoleRequired
										permission={createPermission(
											PermissionScope.dashboard,
											PermissionType.read
										)}
										roles={[Role.sellerAdmin]}
									>
										{/* TODO: this should be a component to use here everywhere: just pass props image and label */}
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/dashboard"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-dashboard.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Dashboard</div>
										</NavLink>
									</RoleRequired>
									<RoleRequired
										permission={createPermission(
											PermissionScope.platforms,
											PermissionType.read
										)}
										roles={[Role.sellerUser, Role.sellerAdmin]}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/platforms"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-platforms.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Platforms</div>
										</NavLink>
									</RoleRequired>
									<RoleRequired
										permission={createPermission(
											PermissionScope.vendors,
											PermissionType.read
										)}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/sellers"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-vendors.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Sellers</div>
										</NavLink>
									</RoleRequired>

									<RoleRequired>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/catalog/integrated"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-pim.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Catalog</div>
										</NavLink>
									</RoleRequired>

									<RoleRequired role={Role.user} strict>
										<Accordion className="width100 in-column align-center no-shadow">
											<AccordionSummary
												style={{
													minHeight: "46px",
													height: "46px",
													width: "95%",
													padding: "0 5px",
												}}
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
												className="navigation-menu-box align-center p0 m0"
											>
												<NavLink
													className="width100 in-row align-center"
													to="/my/catalog/integrated"
												>
													<div className="nav-image-box">
														<img
															className="navigation-image"
															src="/icons/nav-pim.svg"
															width=""
															alt=""
														/>
													</div>
													<div className="navigation-menu-box-text align-center">
														Catalog
													</div>
												</NavLink>
											</AccordionSummary>
											<AccordionDetails className="width100 p0">
												<NavLink
													activeClassName="navigation-menu-box-active"
													to="/my/catalog/integrated"
													className="navigation-menu-box width100"
												>
													<div
														style={{ padding: "0px 68px" }}
														className="navigation-menu-box-text width100"
													>
														My Catalog
													</div>
												</NavLink>
												<NavLink
													activeClassName="navigation-menu-box-active"
													to="/catalog/integrated"
													className="navigation-menu-box width100"
												>
													<div
														style={{ padding: "0px 68px" }}
														className="navigation-menu-box-text width100"
													>
														Zeoos Catalog
													</div>
												</NavLink>
											</AccordionDetails>
										</Accordion>
									</RoleRequired>

									<RoleRequired
										permission={createPermission(
											PermissionScope.inventory,
											PermissionType.read
										)}
										roles={[Role.sellerAdmin]}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/offers/all"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-inventory.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Offers</div>
										</NavLink>
									</RoleRequired>
									{/* <RoleRequired
										permission={createPermission(
											PermissionScope.intelligence,
											PermissionType.read
										)}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/pricing"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-pricing.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Pricing</div>
										</NavLink>
									</RoleRequired> */}
									<RoleRequired
										permission={createPermission(
											PermissionScope.orders,
											PermissionType.read
										)}
										roles={[Role.sellerAdmin]}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/orders"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-orders.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Orders</div>
										</NavLink>
									</RoleRequired>
									{/* TODO: put back */}
									{/* <RoleRequired
										permission={createPermission(
											PermissionScope.intelligence,
											PermissionType.read
										)}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/intelligence/products"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-int.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">
												Intelligence
											</div>
										</NavLink>
									</RoleRequired> */}
									<RoleRequired
										permission={createPermission(
											PermissionScope.logistic,
											PermissionType.read
										)}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/logistic"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-logistics.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Logistics</div>
										</NavLink>
									</RoleRequired>
									<RoleRequired
										permission={createPermission(
											PermissionScope.billings,
											PermissionType.read
										)}
										roles={[Role.sellerAdmin]}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/payouts/treasury"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image billing-image"
													src="/icons/nav-billing.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Payouts</div>
										</NavLink>
									</RoleRequired>
									{/* <RoleRequired
										permission={createPermission(
											PermissionScope.billings,
											PermissionType.read
										)}
										roles={[Role.sellerAdmin]}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/payouts"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image billing-image"
													src="/icons/nav-billing.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Payouts</div>
										</NavLink>
									</RoleRequired> */}
									{/* <RoleRequired
                    permission={createPermission(
                      PermissionScope.intelligence,
                      PermissionType.read
                    )}
                  >
                    <NavLink
                      activeClassName="navigation-menu-box-active"
                      to="/intelligence"
                      className="navigation-menu-box"
                    >
                      <div className="nav-image-box">
                        <img
                          className="navigation-image"
                          src="/icons/nav-int.svg"
                          width=""
                          alt=""
                        />
                      </div>
                      <div className="navigation-menu-box-text">
                        Intelligence
                      </div>
                    </NavLink>
                  </RoleRequired> */}

									<RoleRequired
										permission={createPermission(
											PermissionScope.mappings,
											PermissionType.read
										)}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/freight"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-mappings.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Mappings</div>
										</NavLink>
									</RoleRequired>

									<RoleRequired
										permission={createPermission(
											PermissionScope.syncs,
											PermissionType.read
										)}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/jobs-panel"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-sync.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Syncs</div>
										</NavLink>
									</RoleRequired>

									{/* <RoleRequired
                    permission={createPermission(
                      PermissionScope.digital_assets,
                      PermissionType.read
                    )}
                  >
                    <a
                      // activeClassName="navigation-menu-box-active"
                      href="https://pim.zeoos.com/admin"
                      className="navigation-menu-box"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="nav-image-box">
                        <img
                          className="navigation-image"
                          src="/icons/nav-digitalassets.svg"
                          width=""
                          alt=""
                        />
                      </div>
                      <div className="navigation-menu-box-text">
                        Digital Assets
                      </div>
                      <img
                        className="ml4"
                        src="/icons/nav-external-link.svg"
                        alt=""
                      />
                    </a>
                  </RoleRequired> */}

									<RoleRequired
										permission={createPermission(
											PermissionScope.settings,
											PermissionType.read
										)}
										roles={[Role.sellerAdmin, Role.user]}
									>
										<NavLink
											activeClassName="navigation-menu-box-active"
											to="/users-management"
											className="navigation-menu-box"
										>
											<div className="nav-image-box">
												<img
													className="navigation-image"
													src="/icons/nav-settings.svg"
													width=""
													alt=""
												/>
											</div>
											<div className="navigation-menu-box-text">Settings</div>
										</NavLink>
									</RoleRequired>
								</div>
							</div>
							<div className="nav-bottom-title-cont">
								{/* <div className="border-bottom"></div> */}
								{/* <div className="nav-bottom-title">DOCUMENTATION</div> */}
								{/* <RoleRequired
                    permission={createPermission(
                      PermissionScope.intelligence,
                      PermissionType.read
                    )}
					roles={[Role.admin]}
                  >
                    <NavLink
                      activeClassName="navigation-menu-box-active"
                      to="/intelligence" target="_blank"
                      className="navigation-menu-box pink-border"
                    >
                      <div className="nav-image-box">
                        <img
                          className="navigation-image"
                          src="/icons/nav-int.svg"
                          width=""
                          alt=""
                        />
                      </div>
                      <div className="navigation-menu-box-text pink">
                        Intelligence Board
                      </div>
                    </NavLink>
                  </RoleRequired> */}
							</div>
						</div>
					</div>
				</>
			) : (
				<></>
			)}
		</>
	);
}
