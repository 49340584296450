import React, { useMemo, useState } from "react";
import { IPaginatedRequest, IUser } from "../../../interfaces";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "react-query";
import { getPaginatedUsers } from "../../../actions/user";
import queryString from "query-string";
import { DateSelect } from "../../ui/date/DateSelect";
import FilterSelect from "../../ui/select/FilterSelect";
import { useDebouncedValue } from "@mantine/hooks";

interface Props {
  setCurrentHistoryConfig: React.Dispatch<
    React.SetStateAction<IPaginatedRequest>
  >;
  currentHistoryConfig: IPaginatedRequest;
}

export const HLFilter: React.FC<Props> = ({
  setCurrentHistoryConfig,
  currentHistoryConfig,
}) => {
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 5,
    page: 1,
    search: "",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
  const [open, setOpen] = useState(false);

  const rqKeyUsers = useMemo(() => {
    return `users?${queryString.stringify(debouncedConfig)}`;
  }, [debouncedConfig]);

  const { isLoading: loadUsers, data: users } = useQuery(rqKeyUsers, () =>
    getPaginatedUsers(debouncedConfig)
  );

  return (
    <div className="table-title-cont">
      <div className="mplc-filter-cont width100">
        <div className="mplc-filter">
          <>
            <div className="filter-text">Filter by:</div>
            <Autocomplete
              id="asynchronous-user"
              className="mwidth220 mr3"
              sx={{ p: 0 }}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              isOptionEqualToValue={(option, value) =>
                option.username === value.username
              }
              getOptionLabel={(option: any) => option.username}
              options={users?.data || []}
              loading={loadUsers}
              includeInputInList
              filterSelectedOptions
              onChange={(event: any, newValue: IUser) => {
                setCurrentHistoryConfig((prev) => ({
                  ...prev,
                  user: newValue
                    ? users?.data.find(
                        (x: IUser) => x.username === newValue.username
                      )!._id
                    : "All users",
                }));
              }}
              onInputChange={(event, newInputValue) => {
                setCurrentConfig((prev) => ({
                  ...prev,
                  search: newInputValue,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="All users"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadUsers ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <FilterSelect
              onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentHistoryConfig((conf: IPaginatedRequest) => ({
                  ...conf,
                  type: e.target.value,
                }))
              }
              value={currentHistoryConfig?.type}
              data={["All types", "INVENTORY", "PLATFORMS"]}
            />
            <DateSelect
              date={currentHistoryConfig?.created}
              setCurrentConfig={setCurrentHistoryConfig}
            />
          </>
        </div>
      </div>
    </div>
  );
};
