import axios from "axios";
import { uniq } from "lodash";
import { IPaginatedRequest, SchemeRootObject } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";
import { stringify } from "query-string";

export const getSchemes = async () => {
  const res = await axios.get("/productSchemes", getHeaders());
  return res.data.schemes as SchemeRootObject[];
};

export async function massImport(
  file: File,
  lang: string,
  schemeId: string,
  separator: string,
  mode: string
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("lang", lang);
  formData.append("schemeId", schemeId);
  formData.append("separator", separator);
  formData.append("mode", mode);
  const res = await axios.post(`/productMassImport`, formData, getHeaders());
  return res.data;
}

export async function massUpdate(
  file: File,
  lang: string,
  schemeId: string,
  separator: string
) {
  const formData = new FormData();
  formData.append("file", file);
  // formData.append("lang", lang);
  // formData.append("schemeId", schemeId);
  formData.append("separator", separator);
  const res = await axios.post(`/productmassUpdate`, formData, getHeaders());
  return res.data;
}

export async function exportPriceStockData(skus?: string[], mode?: string) {
  await axios.get(
    `/marketplaceData/export?${stringify({ skus, mode })}${
      window.location.search ? `&${window.location.search.slice(1)}` : ""
    }`,
    getHeaders()
  );
}
export async function translateProductAutomatically(skus: string[]) {
  await axios.post(`/v2/products/translation`, {skus}, getHeaders());
}

export async function exportMyPriceStockData() {
  await axios.get(`/marketplaceData/export-my`, getHeaders());
}

export const updateCurrentProductInfo = async (sku: string, data: any) => {
  await axios.put(`/productInfo/${sku}`, data, getHeaders());
};

export async function pushAllProductVariantsToShopify() {
  await axios.post("/shopify/products/pushVariants", {}, getHeaders());
}

export async function pushProductVariants(sku: string) {
  await axios.post(`/shopify/products/${sku}/pushVariants`, {}, getHeaders());
}

type ExportParams = {
  sellerId: string;
  zeoosName: string;
  schemeId: string;
  disabledSKU: boolean;
  skus?: string[];
  brand?: string;
};

export async function exportAllProductInfo(data: ExportParams) {
  await axios.post(`/productInfo/export`, data, getHeaders());
}

export async function getAllProductInfoVersions(
  sku: string,
  lang: string,
  pagination: IPaginatedRequest
) {
  const res = await axios.get(
    `/productInfo/versions/${sku}?lang=${lang}&${queryString.stringify(
      pagination
    )}`,
    getHeaders()
  );

  return res.data;
}

export async function deleteProductInfoVersion(id: string) {
  await axios.delete(`/productInfo/versions/${id}`, getHeaders());
}

export function getOpenTabs(): string[] {
  const tabs = localStorage.getItem("openTabs");
  return tabs ? JSON.parse(tabs) : [];
}

export function addOpenTab(sku: string) {
  const tabs = getOpenTabs();
  localStorage.setItem("openTabs", JSON.stringify(uniq([...tabs, sku])));
}

export function getOpenSchemeTabs(): string[] {
  const tabs = localStorage.getItem("openSchemeTabs");
  return tabs ? JSON.parse(tabs) : [];
}

export function addOpenSchemeTab(id: string) {
  const tabs = getOpenSchemeTabs();
  localStorage.setItem("openSchemeTabs", JSON.stringify(uniq([...tabs, id])));
}

export function removeOpenTab(sku: string) {
  const tabs = getOpenTabs();
  localStorage.setItem(
    "openTabs",
    JSON.stringify(tabs.filter((x) => x !== sku))
  );
}

export function removeOpenSchemeTab(id: string) {
  const tabs = getOpenSchemeTabs();
  localStorage.setItem(
    "openSchemeTabs",
    JSON.stringify(tabs.filter((x) => x !== id))
  );
}

export function getMarketplaceProductImage(product: any) {
  return (
    product.image ||
    product.main_image ||
    product.pack ||
    product.individual1 ||
    "/no-image.jpg"
  );
}

export async function getOdooProduct(sku: string) {
  return await axios.get(`/odoo/product/${sku}`, getHeaders());
}

export async function createOdooProduct(details: any) {
  return await axios.post(`/odoo/product`, details, getHeaders());
}

export async function getOdooCategories() {
  return await axios.get(`/odoo/categories`, getHeaders());
}

export async function getProductBySku(sku: string) {
  return await axios.get(`/productInfo/${sku}?lang=pt`, getHeaders());
}

export async function getDistinctBrands(productType = "") {
  const res = await axios.get(
    `/pim/brands?productType=${productType}`,
    getHeaders()
  );

  return res.data as string[];
}

export async function getEssentialProductSchemeData() {
  const res = await axios.get(`/inventory/filter/schemes`, getHeaders());

  return res.data as { name: string; _id: string }[];
}

export function getImageURL(sku: string) {
  return `${process.env.REACT_APP_API_URL}/product/${sku}/image`;
}

export async function uploadFile(file: File) {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`/pim/upload`, formData, getHeaders());
  return res.data as string;
}
