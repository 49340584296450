import React, { useContext, useState } from "react";
import { withRouter } from "react-router";
import { loginWithMFACode } from "../../../actions/user";
import { UserContext } from "../../context";
import "./login.css";
import { toast } from "react-toastify";
import useUser from "../../../hooks/useUser";

const MFALogin: React.FC<any> = ({ history }) => {
  const { setUser } = useUser();
  const [verifyCode, setVerifyCode] = useState("");

  const handleMFALogin = async (e: any) => {
    e.preventDefault();

    try {
      const res = await loginWithMFACode({ code: verifyCode });
      setUser(res.data.user);
      history.push("/");
      toast.success(res.message);
    } catch (error: any) {
      history.push("/login");
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="s_main-login">
      <div className="login-box">
        <div className="in-column">
          <form className="login-form-block" onSubmit={handleMFALogin}>
            <div className="login-title-box">
              <div className="login-title-text">MFA Login</div>
            </div>
            <div className="email_text_box">
              <label className="email-text-login">Verification Code:</label>
            </div>
            <div className="login-input">
              <img
                className="image-profile"
                src="/icons/verification-code.svg"
                alt="settings"
                width="30px"
                height="30px"
              />
              <input
                value={verifyCode}
                onChange={(e) => setVerifyCode(e.target.value)}
                className="email-field"
                name="verifyCode"
                id="verifyCode"
                required
              />
            </div>
            <div className="button_box">
              <button onClick={handleMFALogin} className={`log-in-login`}>
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MFALogin);
