import axios from "axios";
import { IPaginatedRequest, IPassword } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";

export async function createPasswordManagement(body: IPassword) {
  await axios.post("/passwordManagement", body, getHeaders());
}

export async function getPaginatedPasswordsManagement(
  pagination: IPaginatedRequest
) {
  const res = await axios.get(
    `/passwordsManagement?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}

export async function getPasswordsManagement() {
  const res = await axios.get("/passwordsManagement", getHeaders());
  return res.data.passwordManagement;
}

export async function getPasswordManagement(id: string) {
  const res = await axios.get(`/passwordManagement/${id}`, getHeaders());
  return res.data.passwordManagement;
}

export async function updatePasswordManagement(id: string, body: IPassword) {
  await axios.put(`/passwordManagement/${id}`, body, getHeaders());
}

export async function deletePasswordManagement(id: string) {
  await axios.delete(`/passwordManagement/${id}`, getHeaders());
}
