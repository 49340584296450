import React from "react";
import "./businessIntelligence.css";
import { OrderDetails } from "./OrderDetails";
import { LineChart } from "./LineChart";
import { Top5Chart } from "./Top5Chart";
import { columnsB2C } from "./B2CMarketplace";
import { ICombinedOrders, ICombinedOrder } from "../../../interfaces";
import { PSPChart } from "./PSPChart";

interface Props {
  orders: ICombinedOrders;
  avgOrderCounter: (array: ICombinedOrder[]) => string | number;
  grossValueCounter: (array: ICombinedOrder[]) => string | number;
  country: string;
  category: string;
}

export const FlashSales: React.FC<Props> = ({
  orders,
  avgOrderCounter,
  grossValueCounter,
  country,
  category,
}) => {
  const filteredByCountry =
    country === "All Countries"
      ? orders.flash
      : orders.flash?.filter((x: ICombinedOrder) => x.country === country);

  const filteredByCategory =
    category === "All Categories"
      ? orders.flash?.flatMap((x: ICombinedOrder) => x.sale_line_ids)
      : orders.flash
          .flatMap((x: ICombinedOrder) => x.sale_line_ids)
          .filter((x: any) => x.category_id === category);

  const totalOrdersFlash = filteredByCountry.length.toLocaleString("de-DE");
  const avgOrderValueFlash = avgOrderCounter(filteredByCountry);
  const grossValueFlash = grossValueCounter(filteredByCountry);

  return (
    <>
      <div className="bi__cards_group">
        <OrderDetails
          orders={filteredByCountry}
          totalOrders={totalOrdersFlash}
          columns={columnsFlash}
        />
        <div className="bi__card">
          <div className="bi__card_title">GMV</div>
          <div className="bi__card_content_avg">
            <div>
              <span>{grossValueFlash} </span>
              <span className="bi__card_content_avg_currency">€</span>
            </div>
          </div>
        </div>
        <div className="bi__card">
          <div className="bi__card_title">Average Order Value</div>
          <div className="bi__card_content_avg">
            <div>
              <span>{avgOrderValueFlash} </span>
              <span className="bi__card_content_avg_currency">€</span>
            </div>
          </div>
        </div>
        <LineChart array={filteredByCountry} />
        <Top5Chart
          orders={filteredByCountry}
          orders2={filteredByCategory}
          category={category}
        />
        <PSPChart ordersList={filteredByCountry} />
      </div>
    </>
  );
};

const flashBlackList = [
  "sale_lengow_status",
  "sale_marketplace_account",
  "sale_lengow_id",
];

const columnsFlash = columnsB2C.filter(
  (x: any) => !flashBlackList.includes(x.field)
);
