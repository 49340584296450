import React, { memo, useCallback, useEffect, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import Checkbox from "@mui/material/Checkbox";
import CountryFlag from "../../../ui/country-flag/CountryFlag";

interface Props {
	type: string;
	specificArray: any;
	selectedItems: string[];
	setSelectedItems: (selectedItems: React.SetStateAction<string[]>) => void;
}

const SpecificSearchModal: React.FC<Props> = ({
	type,
	specificArray,
	selectedItems,
	setSelectedItems,
}) => {
	const [modal, setModal] = useState<boolean>(false);
	const [search, setSearch] = useState<string>("");
	const [results, setResults] = useState<any>(specificArray);

	useEffect(() => {
		const results = specificArray
			// eslint-disable-next-line
			?.filter((item: any) => {
				if (search === "") {
					return item;
				} else if (
					Object.keys(item).filter(
						(x: any) =>
							typeof item[x] === "string" &&
							item[x].toLowerCase().includes(search.toLowerCase())
					)?.length > 0
				) {
					return item;
				}
			});
		setResults(results);
		// eslint-disable-next-line
	}, [search, specificArray]);

	const handleSelectOne = useCallback(
		(item: any) => () => {
			setSelectedItems((prev) => {
				if (prev.find((x) => x === item._id)) {
					return prev.filter((x) => x !== item._id);
				}
				return [...prev, item._id];
			});
		},
		[setSelectedItems]
	);

	const renderItems = (x: any) => {
		switch (type) {
			case "categories":
				return (
					<div className="new-pricing-modal-cont">
						<Checkbox
							sx={{
								color: "#55718A",
								"&.Mui-checked": {
									color: "#55718A",
								},
							}}
							checked={selectedItems.includes(x._id)}
							onChange={handleSelectOne(x)}
							inputProps={{ "aria-label": "controlled" }}
						/>
						<span className="ml2 new-pricing-text">{x.name}</span>
					</div>
				);
			case "platforms":
				return (
					<div className="new-pricing-modal-cont">
						<Checkbox
							sx={{
								color: "#55718A",
								"&.Mui-checked": {
									color: "#55718A",
								},
							}}
							checked={selectedItems.includes(x._id)}
							onChange={handleSelectOne(x)}
							inputProps={{ "aria-label": "controlled" }}
						/>
						<CountryFlag
							country={x.country}
							className="table-search-image"
							width="35px"
						/>
						<span className="ml1 new-pricing-text">{x.zeoosName}</span>
					</div>
				);
			case "products":
				return (
					<div className="new-pricing-modal-cont">
						<Checkbox
							sx={{
								color: "#55718A",
								"&.Mui-checked": {
									color: "#55718A",
								},
							}}
							checked={selectedItems.includes(x._id)}
							onChange={handleSelectOne(x)}
							inputProps={{ "aria-label": "controlled" }}
						/>
						<img
							src={
								specificArray[0].vendorIcon
									? specificArray.find((vendor: any) => vendor._id === x._id)
											?.vendorIcon
									: specificArray.find((product: any) => product._id === x._id)
											?.image
							}
							width="80px"
							height="60px"
							alt=""
						/>
						<span className="ml1 new-pricing-text">
							{specificArray.find((z: any) => z._id === x._id)?.name}
						</span>
					</div>
				);

			default:
				break;
		}
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<>
			<div
				onClick={() => {
					setModal(true);
				}}
				style={{
					borderBottom: selectedItems.length ? "1px solid #e1e1e1" : "",
				}}
				className="width100 in-row align-center pointer pb2"
			>
				<input className="tax-modal-input mr2 pointer" />
				<div className="new-pricing-search">search</div>
			</div>
			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<>
						<div className="mapping-modal-cont">
							<div className="table-modal-title-box m0">
								<div className="mapping-modal-title-text">
									Select the sellers
								</div>
							</div>

							<form className="table-modal-form-cont">
								<div className="table-modal-border"></div>
								<div className="width100 in-row align-center pointer mb4">
									<input
										className="tax-modal-input mr2 pointer"
										value={search}
										onChange={(e) => {
											e.preventDefault();

											setSearch(e.target.value);
										}}
									/>
									<div className="new-pricing-search">Buscar</div>
								</div>
								<div className="table-modal-border m0"></div>
								<div className="new-pricing-overflow">
									{results?.map((x: any) => renderItems(x))}
								</div>
								<div className="table-modal-form-button-box width100">
									<button
										className="table-modal-form-button-cancel"
										onClick={() => {
											setSelectedItems([]);
											setModal(false);
										}}
									>
										Cancelar
									</button>
									<button
										className="table-modal-form-button"
										onClick={onModalClose}
									>
										done
									</button>
								</div>
							</form>
						</div>
					</>
				</Modal>
			)}
		</>
	);
};

export default memo(SpecificSearchModal);
