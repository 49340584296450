import React, { useCallback, useEffect, useState } from "react";
import { withRouter, useParams, useHistory } from "react-router";
import "./login.css";
import { toast } from "react-toastify";
import Contato from "./Contato";
import {
	getTokenValidity,
	createPassword,
} from "../../../actions/onboardingSeller";

const SetupAccount: React.FC<any> = () => {
	const { token } = useParams<{ token?: string; }>();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const history = useHistory();

	useEffect(() => {
		getTokenValidity(token!)
			.then(setEmail)
			.catch(() => {
				// history.push("/login");
			});
	}, [token, history]);

	const handleSubmit = useCallback(
		async (e: any) => {
			e.preventDefault();

			if (password !== repeatPassword) {
				return toast.error("Passwords have to be equal");
			}

			try {
				await createPassword(token!, password);

				toast.success("Password created. Redirecting to login...", {
					autoClose: 3000,
				});
				setTimeout(() => {
					history.push("/login");
				}, 3000);
			} catch (error: any) {
				toast.error("Something went wrong...", { autoClose: 5000 });
			}
		},
		[token, history, password, repeatPassword]
	);

	return (
		<div>
			<div className="s_main-login">
				<div className="login-box">
					<div className="in-column">
						<div className="titel_box mb9">
							<img src="/icons/zeoos-logo.svg" width="160px;" alt="" />
						</div>
						<form className="login-form-block" onSubmit={handleSubmit}>
							<input type="hidden" name="token" value={token} />
							<div className="esqueci_text_box mb7">
								<h3>CREATE PASSWORD</h3>
							</div>
							<div className="email_text_box">
								<label className="password-text-login">New password:</label>
							</div>
							<div className="login-input">
								<div>
									<img
										className="image-profile"
										src="/icons/lock-password.svg"
										alt="settings"
										width="30px"
										height="30px"
									/>
								</div>
								<input
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									type="password"
									className="password-field"
									name="password"
									required
									minLength={8}
								></input>
							</div>

							<div className="email_text_box">
								<label className="password-text-login">
									Repeat new password:
								</label>
							</div>
							<div className="login-input mb9">
								<div>
									<img
										className="image-profile"
										src="/icons/lock-password.svg"
										alt="settings"
										width="30px"
										height="30px"
									/>
								</div>
								<input
									value={repeatPassword}
									onChange={(e) => setRepeatPassword(e.target.value)}
									type="password"
									className="password-field"
									name="repeatPassword"
									required
								></input>
							</div>

							<div className="button_box">
								<button className={`log-in-login`}>CREATE PASSWORD</button>
							</div>
						</form>
					</div>
				</div>
				<Contato />
			</div>
		</div>
	);
};

export default withRouter(SetupAccount);
