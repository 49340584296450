import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from "@mui/material/Checkbox";

export default function BillingFilter() {
    const [open, setOpen] = useState(false);

    return (
        <>
      <button
        onClick={() => setOpen(!open)}
        className="main-select-bi no-shadow ml2"
      >
        <img src="/icons/bi-filter.svg" alt="" />
        FILTER <span className="orange-text bold">(1)</span>
      </button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        className="relative"
      >
        <div className="filter-bg" onClick={() => setOpen(false)} />
        <div className="filter-cont-position">
          <div className="filter-cont">
            <div className="filter-content justify-start">
              <div className="filter-header pt4">
                <div className="filter-header-title">
                  Filter <span className="orange-text">(1)</span>
                </div>
                <div
                  className="filter-header-reset"
                >
                  Clear filters
                </div>
              </div>
              <div className="filter-content-cont mt2">
              <div className="sale-cont">
              <div
                    className="in-row align-center"
                  >
                    <Checkbox
                      sx={{
                        color: "#55718A",
                        paddingLeft: "0",
                        "&.Mui-checked": {
                          color: "#55718A",
                          paddingLeft: "0",
                        },
                      }}
                    />
                    <div className="seller-product-filter-text">Show only out-of-stock products</div>
                  </div>
                  <div
                    className="in-row align-center"
                  >
                    <Checkbox
                      sx={{
                        color: "#55718A",
                        paddingLeft: "0",
                        "&.Mui-checked": {
                          color: "#55718A",
                          paddingLeft: "0",
                        },
                      }}
                    />
                    <div className="seller-product-filter-text">Show only products in stock</div>
                  </div>
                  </div>
                  <div className="sale-box">
                    {/* PriceSlider */}
                  </div>
                <select className="filter-select">
                  <option className="">All Categories</option>
                  <option className="">Wine</option>
                </select>
                <select className="filter-select">
                  <option className="">All Categories</option>
                  <option className="">Wine</option>
                </select>
                <select className="filter-select">
                  <option className="">All Categories</option>
                  <option className="">Wine</option>
                </select>
                <select className="filter-select">
                  <option className="">All Categories</option>
                  <option className="">Wine</option>
                </select>
                <select className="filter-select">
                  <option className="">All Categories</option>
                  <option className="">Wine</option>
                </select>
                <select className="filter-select">
                  <option className="">All Categories</option>
                  <option className="">Wine</option>
                </select>

                
              </div>
            </div>
            <div className="filter-border mt4 mb2"></div>
            <div className="filter-buttons width100 m0">
              <button
                className="filter-apply-btn width80"
              >
                APPLY
              </button>
              <button
                className="filter-cancel-btn mb2"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Backdrop>
    </>
    );
}
