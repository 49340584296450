import React, { useCallback, useMemo, useState } from "react";
import SettingsNav from "../../ui/settings-nav/SettingsNav";
import { Checkbox, FormControlLabel } from "@mui/material";
import useUser from "../../../hooks/useUser";
import { IVendor, ReactQueryConfig } from "../../../interfaces";
import { editOnBoardingSeller } from "../../../actions/onboardingSeller";
import { toast } from "react-toastify";
import { getCarrierList } from "../../../actions/logistic";
import { useQuery } from "react-query";
import { pull } from "lodash";

function useCarriers(config?: ReactQueryConfig) {  
  const key = useMemo(() => `carriers`, []);
  const data = useQuery(key, async () => await getCarrierList(), config);

  return data;
}

export default function ShippingSettings() {
	const { user } = useUser();
	const [sellerData, setSellerData] = useState<Partial<IVendor>>(user.seller);
  const list = useCarriers();

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			try {
				await editOnBoardingSeller(sellerData);
				toast.success('Data confirmed!');
			} catch (error) {
				console.error(error);
				toast.error('Something went wrong...');
			}

		}, [sellerData]
	);

	return (
		<>
			<div className="main-body">
				<div className="main-container">
					<div className="table-main-cont p0">
						<SettingsNav />
						<form
							style={{
								height: "max-content",
								paddingBottom: "20px",
								minHeight: "85vh",
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "flex-start",
							}}
							className="in-column align-center justify-start p4"
							onSubmit={onSubmit}
						>
							<div style={{ maxWidth: "100%" }} className="sel-newon-cont">
								<div className="sel-newon-title">Shipping settings</div>
								<div style={{ justifyContent: "flex-start" }} className="sel-newon-box">
									<div className="sel-newon-content">
										<span>Handling time: </span>
                    <input
											className="sel-newon-input"
											value={sellerData.defaultHandlingTime}
											onChange={e => setSellerData(d => ({ ...d, defaultHandlingTime: e.target.value }))}
											required
                      step={1}
											type="number"
										/>
									</div>

									<div className="sel-newon-content ml1"></div>
									<div className="sel-newon-content">                    
										<span>Carriers: </span>
                    {
                      list.data?.map((carrier:string,i:number) => {
                        return <FormControlLabel 
                            control={
                              <Checkbox checked={sellerData.activeCarriers?.includes(carrier)} 
                                onChange={(e) => {
                                  let aux = sellerData.activeCarriers || [];
                                  if (e.target.checked) {
                                    aux.push(carrier)
                                  } else {
                                    aux = pull(aux, carrier);
                                  }
                                  setSellerData(d => ({ ...d, activeCarriers: aux }))}
                                }
                              />} 
                            label={carrier} 
                          />
                      })
                    }
									</div>
								</div>
							</div>
							<div
								style={{ maxWidth: "100%" }}
								className="width100 in-row align-center justify-between"
							>
								<button className="table-modal-form-button" type="submit">
									SAVE
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}
