import { memo, useCallback, useEffect, useState } from "react";
import Paginator from "../../../ui/pagination";
import { IPaginatedRequest } from "../../../../interfaces";
import {
  BillingStatus,
  selectedBillingsAtom,
  allBillingsAtom,
} from "../Billing";
import { useDebouncedValue } from "@mantine/hooks";
import { useQuery } from "react-query";
import queryString from "query-string";
import { getPaginatedTreasury } from "../../../../actions/billings";
import { DateTime } from "luxon";
import { convertPrice } from "../../tables/gestao/UpdateGestao";
import { renderPaymentStatus } from "../single-billing/SingleBillingTable";
import { useAtom } from "jotai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { PriceBreakdown } from "./PriceBreakdown";
import BillingPayoutsNav from "../../../ui/billing-payouts-nav/BillingPayoutsNav";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import BatchBillingDropDown from "../../../ui/batch-dropdown/BatchBillingsDropDown";
import CreatePayment from "../payment-batch/CreatePayment";
import { NavLink } from "react-router-dom";
import useUser from "../../../../hooks/useUser";

export const totalAmount = (lines: any[]) => {
  let total = 0;
  // eslint-disable-next-line
  lines?.map((l: any) => {
    total += l.price_total;
  });
  return total;
};

export const totalProducts = (lines: any[]) => {
  let total = 0;
  // eslint-disable-next-line
  lines.filter((l: any) => l.name !== "Freight")?.map((l: any) => {
    total += l.product_uom_qty;
  });
  return total;
};

const totalOrder = (order: any) => {
  if (order) {
    const value = order.price + order.shipping_price;

    if (!isNaN(value)) return <>{convertPrice(order.price + order.shipping_price)} €</>
  }
  return "NA"
}

const TreasuryTable = () => {
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: "",
    status: BillingStatus.ALL_STATUSES,
    vendorName: "All vendors",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
  const [selectedBillings, setSelectedBillings] = useAtom(selectedBillingsAtom);
  const [allBillings, setAllBillings] = useAtom(allBillingsAtom);
  const [search, setSearch] = useState<string>("");
  const [openTreasury, setOpenTreasury] = useState<string>();
  const [modal, setModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [batch, setBatch] = useState<boolean>(false);
  const user = useUser().user;

  const { isLoading: loadBillings, data: billings } = useQuery(
    // `/billings/paginatedShipments?${queryString.stringify(debouncedConfig)}`,
    `/payouts/treasury?${queryString.stringify(debouncedConfig)}`,
    () => getPaginatedTreasury(debouncedConfig)
  );

  const onChangePage = useCallback(
    async (config: IPaginatedRequest) => {
      setCurrentConfig((c: IPaginatedRequest) => {
        return {
          ...c,
          ...config,
        };
      });
    },
    [setCurrentConfig]
  );

  const handleSelectOne = useCallback(
    (item: any) => () => {
      if (isPayable(item)) {
        setSelectedBillings((prev: any) => {
          if (prev.find((x: any) => x._id === item._id)) {
            return prev.filter((x: any) => x._id !== item._id);
          }
          return [
            ...prev,
            {
              _id: item._id,
              seller: item.seller.name,
              billing: item.billing_name,
              platform: item.order?.zeoosName,
              order: item.related_sale_order,
              products: totalProducts(item.billing_lines),
              amount: totalAmount(item.billing_lines),
              date: item.date_creation,
            },
          ];
        });
      }
    },
    // eslint-disable-next-line
    [setSelectedBillings]
  );

  const handleSelectAll = useCallback(() => {
    setSelectedBillings((prev) =>
      !prev.length
        ? billings?.data
            .filter((b: any) => isPayable(b))
            .map((item: any) => ({
              _id: item._id,
              seller: item.seller.name,
              billing: item.billing_name,
              platform: item.order?.zeoosName,
              order: item.related_sale_order,
              products: totalProducts(item.billing_lines),
              amount: totalAmount(item.billing_lines),
              date: item.date_creation,
            }))
        : []
    );
    // eslint-disable-next-line
  }, [billings?.data, setSelectedBillings]);

  useEffect(() => {
    setAllBillings(billings?.total);
    // eslint-disable-next-line
  }, [billings]);

  const handleAccordClick = (id: any) => {
    if (openTreasury === id) setOpenTreasury("");
    if (openTreasury !== id) setOpenTreasury(id);
  };

  const maketplacePaymentState = (item: any) => {
    const payments = item.order?.marketplacePayment
    let state = !item.order ? 'NA' : 'not_paid';

    payments?.forEach((pay:any) => {
      state = pay.state;
    })

    if (item.order?.refund_status || ["returned", "refunded"].includes(item.order?.status)) {
      state = item.order?.refund_status || "refunding";
    }

    return state;
  }

  const isPayable = (item: any) => {
    return item.payment_state !== "not_paid" || maketplacePaymentState(item) !== "paid"
      ? false
      : true
  }

  const tagType = (line: any) => {
    let tag;
    const title = line.billing_transaction_type ||
      line.shipment?.operation_type || 
      line.order?.products[0].inventory;

    
    switch (title) {
      case "dropshipping":
        tag = "DS";
        break;

      case "fulfillment":
        tag = "FF";
        break;

      case "order":
        tag = "PO";
        break;

      case "order_refund":
        tag = "RF";
        break;
      
      case "subscription":
        tag = "SB";
        break;
    
      default:
        break;
    }

    return isHub ? <></>: <span className="tagType" title={title}>{tag}</span>;
  }

  if (batch) {
    return <CreatePayment billings={selectedBillings} setBatch={setBatch}/>
  }

  const isHub = user?.seller?.accountType === "Hub";

  return (
    <div className="main-body">
      <div className="main-container">
      <BatchBillingDropDown
          allTreasury={billings?.data}
          selectedBillings={selectedBillings}
          setSelectedBillings={setSelectedBillings}
          setBatch={setBatch}
        />        
        <div className="table-main-cont">
          <BillingPayoutsNav />
          <div className="table-main-title-cont">
            <div className="table-main-title-search-value-cont">
              <div className="table-search-box">
                <form
                  className="table-search-form ml2"
                  onSubmit={(e) => {
                    e.preventDefault();
                    const form = e.target as HTMLFormElement;
                    setCurrentConfig({
                      ...currentConfig,
                      search: form["search"].value,
                    });
                  }}
                >
                  <input
                    className="table-search-input"
                    name="search"
                    placeholder="Search product"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
              <div>
                {loadBillings ? (
                  <CircularPreloader />
                ) : (
                  <span className="table-main-title-text">
                    {allBillings} Billings
                  </span>
                )}
              </div>
            </div>
            <div className="marketplace-pagination">
              <Paginator
                data={!loadBillings ? billings : {}}
                refetch={onChangePage as any}
              />
            </div>
          </div>
          <div className="table-border-bottom"></div>
          <div style={{ height: "max-content" }} className="table-overflow">
            <table
              style={{
                position: "sticky",
                zIndex: 101,
                top: "2px",
                width: "100%",
              }}
            >
              <thead>
                <tr
                  style={{ marginTop: "-5px", width: "100%" }}
                  className="table-results-title"
                >
                  <th className="ml3 mr3">
                    <img
                      className="pointer"
                      src={
                        selectedBillings.length
                          ? "/icons/inventory-all-selected.svg"
                          : "/icons/check-box.svg"
                      }
                      alt=""
                      onClick={handleSelectAll}
                    />
                  </th>
                  <th className="treasury-id">Billing ID</th>
                  <th className="treasury-order">
                    {!isHub &&
                      <>Purchase<br /></>
                    }
                    Order ID{" "}
                  </th>
                  <th className="treasury-seller">Vendor</th>
                  <th className="treasury-name">Plaform</th>
                  <th className="treasury-name">Plaform Order</th>
                  <th className="treasury-status">Plaform Status</th>
                  <th className="treasury-country">
                    Customer
                    <br />
                    Country
                  </th>
                  <th className="treasury-products">
                    Items
                    <br />
                    Sold
                  </th>
                  <th className="treasury-amount">
                    {isHub
                      ? <>Zeoos Rate w/VAT</>
                      : <>Purchase<br />Price w/VAT</>
                    }
                  </th>
                  {isHub &&
                  <th className="treasury-amount">
                    Order Total
                  </th>
                  }
                  <th className="treasury-date">
                    Date
                    <br />
                    Time
                  </th>
                  <th className="treasury-status">Status</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <div>
              <table className="table-results p0">
                <thead></thead>
                <tbody>
                  {billings?.data.map((item: any, index: number) => (
                    <Accordion
                      expanded={item._id === openTreasury}
                      className="m0"
                      key={index}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="width100 p0 m0div"
                      >
                        <tr className="table-result-box billing-text">
                          <td
                            className="ml3 mr3"
                            onClick={handleSelectOne(item)}
                          >
                            <img
                              className={`${
                                isPayable(item)
                                  ? "pointer"
                                  : "disabled"
                              }`}
                              src={
                                !selectedBillings.find(
                                  (x: any) => x._id === item._id
                                )
                                  ? "/icons/check-box.svg"
                                  : "/icons/check-box-checked.svg"
                              }
                              alt=""
                            />
                          </td>
                          <td className="treasury-id bold">
                            {!isHub && 
                              <img
                                className="mr4"
                                alt=""
                                src={
                                  item._id === openTreasury
                                    ? "/icons/vendors-arrow-up.svg"
                                    : "/icons/vendors-arrow-down.svg"
                                }
                                onClick={() => {
                                  handleAccordClick(item._id);
                                }}
                              />
                            }
                            {item.billing_name}
                          </td>
                          <td className="treasury-order">
                            {item.order 
                              ? 
                                <NavLink to={`/orders/${item.order._id}`}>
                                  {item.shipment?.shipment_name || item.related_sale_order}
                                  {tagType(item)}  
                                </NavLink>
                              : 
                                <>
                                  {item.shipment?.shipment_name || item.related_sale_order}
                                  {tagType(item)}  
                                </>
                            }
                          </td>
                          <td className="treasury-seller">
                            {item.seller.name}
                          </td>
                          <td className="treasury-name">
                            {item.order?.zeoosName || 'NA'}
                          </td>
                          <td className="treasury-name">
                            {item.order?.order_marketplace || 'NA'}
                          </td>
                          <td className="treasury-name">
                            {
                              renderPaymentStatus(
                                maketplacePaymentState(item)
                              )
                            }
                          </td>
                          <td className="treasury-country">
                            {
                              item.order?.customer?.country && 
                              <img
                                width="28px"
                                src={`/icons/${item.order?.customer?.country?.toLowerCase()}.svg`}
                                alt={item.order?.customer.country}
                              /> 
                            }                            
                          </td>
                          <td className="treasury-products">
                            <img
                              className="mr1"
                              src="/icons/paybatch-product.svg"
                              alt=""
                            />
                            {totalProducts(item.billing_lines)}
                          </td>
                          <td className="treasury-amount bold">
                            {item.billing_type === 'to_receive' && !isHub ? '-' : ''}
                            {convertPrice(totalAmount(item.billing_lines))} €
                          </td>
                          {isHub &&
                          <td className="treasury-amount">
                            {totalOrder(item.order)}
                          </td>
                          }
                          <td className="treasury-date">
                            {DateTime.fromISO(
                              item.date_creation
                            ).toFormat("dd/MM/yy HH:mm")}
                          </td>
                          <td className="treasury-status">
                            {renderPaymentStatus(
                              ["canceled","refunded"].includes(item.order?.status)
                              ? item.order?.status
                              : item.payment_state
                            )}
                          </td>
                        </tr>
                      </AccordionSummary>
                      <AccordionDetails className="width100 p0 m0div">
                        <table className="width100 p0 m0div billing-lines">
                          <thead>
                            <tr className="table-results-title width100 height40px">
                              <th className="product">SKU - Product</th>
                              <th className="items">Items sold</th>
                              <th className="price">Unit Purchase<br />Price wo/VAT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item?.billing_lines.map(
                              (line: any, index: number) => (
                                <tr
                                  className="table-results-title width100"
                                  key={index}
                                >
                                  <td className="product">
                                    {line.sku} - {line.name}
                                  </td>
                                  <td className="items">
                                    {line.product_uom_qty}
                                  </td>
                                  <td
                                    className="price pointer"
                                    onClick={(e) => {
                                      setSelectedProduct({
                                        ...line,
                                        billingDate: item.date_creation,
                                        pricingType: item.seller?.pricingType,
                                      });
                                      setModal(true);
                                    }}
                                  >
                                    {convertPrice(line.price_unit)} €
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {modal && (
            <PriceBreakdown
              modal={modal}
              setModal={setModal}
              product={selectedProduct}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(TreasuryTable);
