import React, { useContext, useState } from "react";
import "./tax.css";
import { ITaxMappingCountry } from "./Tax";
import {
  deleteTaxMapping,
  updateTaxMapping,
} from "../../../../actions/taxMapping";
import { toast } from "react-toastify";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import { CountriesContext } from "../../../context";
import { ICountryM } from "../../country-management/CountryManagement";
import { TaxRate } from "types/category";

export default function TaxTable({ data }: { data: TaxRate[] | undefined }) {
  const countries = useContext(CountriesContext) as ICountryM[];
  return (
    <div className="table-main-cont">
      <div style={{ padding: "0 40px" }} className="width100">
        <div className="table-main-title-cont">
          <div className="table-main-title-search-value-cont">
            <div className="table-main-title-text pl2">
              {data?.length} tax maps
            </div>
          </div>
        </div>
        <div style={{ height: "max-content" }} className="table-overflow">
          <table
            style={{
              position: "sticky",
              zIndex: 101,
              top: "2px",
              width: "100%",
            }}
          >
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="tax-cat">Categorias</th>
              <th className="table-border-right"></th>
              <th className="tax-imp">
                <div className="pl1">Tax</div>
              </th>
              <th className="table-border-right"></th>
              <th className="table-border-right"></th>
              {countries.map((country: ICountryM, index: number) => (
                <React.Fragment key={index}>
                  <th className="tax-lg">
                    <CountryFlag country={country.name} width="28px;" />
                    <div>{country.name}</div>
                  </th>
                </React.Fragment>
              ))}
            </tr>
          </table>
          <table className="table-results mb2" style={{ minHeight: "auto" }}>
            <tbody>
              {data?.map((taxMapping: TaxRate, index: number) => (
                <tr
                  className="table-result-box tax-body pointer"
                  //   onDoubleClick={async (e) => {
                  //     e.preventDefault();

                  //     if (
                  //       !window.confirm(
                  //         `Are you sure you want to delete category ${taxMapping.name} `
                  //       )
                  //     ) {
                  //       return;
                  //     }

                  //     await deleteTaxMapping(taxMapping?._id as string);
                  //     setTaxMappings((prevState: ITaxMapping[]) =>
                  //       prevState.filter((x) => x._id !== taxMapping._id)
                  //     );
                  //     toast.success("Category was deleted!");
                  //   }}
                  key={index}
                >
                  <td
                    className="tax-cat weight500"
                    // onClick={() => setTaxMappingToEdit(taxMapping)}
                  >
                    {taxMapping.name}
                  </td>
                  <th className="table-border-right"></th>
                  <th className="tax-imp">
                    <div className="pl1">VAT</div>
                  </th>
                  <th className="table-border-right"></th>
                  {countries.map((item) => {
                    const value = taxMapping.rate[item._id] || 0;
                    return (
                      <td key={item._id} className="tax-lg">
                        {value}%
                      </td>
                    );
                  })}
                  {/* {countries.map((country: ICountryM, index: number) => {
                    const countryInfo = taxMapping.countries.find(
                      (x: ITaxMappingCountry) => x.name === country.name
                    );
                    return countryInfo ? (
                      <React.Fragment key={index}>
                        <td className="tax-lg">
                          <div>{countryInfo.vat}%</div>
                          <div className="tax-border-bottom"></div>
                          <div>€{countryInfo.iec}</div>
                        </td>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={index}>
                        <td className="tax-lg">
                          <div>-</div>
                          <div className="tax-border-bottom"></div>
                          <div>-</div>
                        </td>
                      </React.Fragment>
                    );
                  })} */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* {taxMappingToEdit && (
          <CreateEditTaxMappingForm
            onModalClose={(e: React.MouseEvent) => {
              e.stopPropagation();
              setTaxMappingToEdit(null);
            }}
            modal={!!taxMappingToEdit}
            taxMapping={taxMappingToEdit}
            onAction={async (newTaxMapping: ITaxMapping) => {
              await updateTaxMapping(
                taxMappingToEdit?._id as string,
                newTaxMapping.name,
                newTaxMapping.countries
              );
              toast.success("Category updated!");
              setTaxMappings((prevState: any) =>
                prevState.map((x: any) => {
                  if (x._id === taxMappingToEdit?._id) {
                    x = {
                      ...x,
                      ...newTaxMapping,
                    };
                  }

                  return x;
                })
              );
            }}
          />
        )} */}
      </div>
    </div>
  );
}
