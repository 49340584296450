import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { NavLink } from "react-router-dom";
import { IZeoosOrder } from "../../../../../interfaces";
import { Checkbox } from "@mui/material";
import { Role } from "../../../../../interfaces";
import RoleRequired from "../../../../utils/role-required/RoleRequired";

interface Props {
	order: IZeoosOrder;
	actions: any;
}

interface PropsAction {
	orderId?: string;
	shippingId: string;
	actions: any;
}

const ActionButton: React.FC<PropsAction> = ({
	shippingId,
	actions,
	orderId,
}) => {
	const action = actions.find((a: any) => a.id === shippingId);
	return (
		// <RoleRequired role={Role.admin}>
		<>
			{action.action ? (
				<NavLink className="so-button" to={`${orderId}/${shippingId}`}>
					{action.label}
				</NavLink>
			) : (
				<>{action.label}</>
			)}
		</>
		// </RoleRequired>
	);
};

const SingleOrderTitle: React.FC<Props> = ({ order, actions }) => {
	const [selectedDeliveries, setSelectedDeliveries] = React.useState(
		[] as string[]
	);
	const [selectAll, setSelectAll] = React.useState(false);
	const allDeliveries = order?.shipping_list
		? order?.shipping_list?.map((shipping: any) => shipping._id)
		: [];

	const handleToggleAll = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectAll(e.target.checked);
		if (e.target.checked) {
			setSelectedDeliveries(allDeliveries);
		} else {
			setSelectedDeliveries([]);
		}
	};

	const handleSelectDelivery = (value: string, checked: boolean) => {
		if (checked && !selectedDeliveries.includes(value)) {
			setSelectedDeliveries([...selectedDeliveries, value]);
		}

		if (!checked && selectedDeliveries.includes(value)) {
			setSelectedDeliveries(
				selectedDeliveries.filter((v: string) => v !== value)
			);
		}
	};

	useEffect(() => {
		if (
			selectedDeliveries.length > 0 &&
			selectedDeliveries.length === allDeliveries.length
		) {
			setSelectAll(true);
		}
		if (selectedDeliveries.length === 0) {
			setSelectAll(false);
		}
		// eslint-disable-next-line
	}, [selectedDeliveries]);

	return (
		<>
			<div className="single-order-cont">
				<div className="single-order-title-cont">
					<h2 className="single-order-title">Order View {order.order_id}</h2>
					<div className="in-row align-center">
						{/* TODO */}
						{/* <img className='mr2 pointer' src='/icons/order-refresh.svg' alt='' />
                      <img className='mr2 pointer' src='/icons/order-print.svg' alt='' /> */}
					</div>
				</div>
				<div className="order-border"></div>
				{/* TODO */}
				{/* <span className='pt2 table-subtext underline pointer hide'>Cancel Order</span> */}
				<TableContainer className="mt4">
					<Table>
						<TableHead className="single-order-head-text">
							<TableRow>
								<TableCell className="so-squad">
									<Checkbox
										indeterminate={
											selectedDeliveries.length > 0 &&
												allDeliveries.length !== selectedDeliveries.length
												? true
												: false
										}
										checked={selectAll}
										onChange={handleToggleAll}
									/>
								</TableCell>
								<TableCell className="so-delivery">Delivery Process</TableCell>
								<TableCell className="so-dispatcher">Dispatcher</TableCell>
								<TableCell className="so-status">Action / Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{order?.shipping_list &&
								order.shipping_list.map((shipping: any, index: number) => (
									<TableRow
										key={index}
										className="so-row-cont"
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell className="so-squad" component="th" scope="row">
											<Checkbox
												value={shipping._id}
												name={`delivery_${shipping._id}`}
												className="check_delivery"
												onChange={(e) =>
													handleSelectDelivery(shipping._id, e.target.checked)
												}
												checked={selectedDeliveries.includes(shipping._id)}
											/>
										</TableCell>
										<TableCell
											className="so-delivery underline"
											component="th"
											scope="row"
										>
											<NavLink to={`${order._id}/${shipping._id}`}>
												{shipping.reference || shipping.type}
											</NavLink>
										</TableCell>
										<TableCell
											className="so-dispatcher"
											component="th"
											scope="row"
										>
											{shipping.type === "fulfillment"
												? "NA"
												: shipping.seller_name}
										</TableCell>
										<TableCell className="so-status" component="th" scope="row">
											<ActionButton
												shippingId={shipping._id}
												actions={actions}
												orderId={order._id}
											/>
										</TableCell>
									</TableRow>
								))}
							{/* <TableRow
                      className='so-row-cont'
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell className='so-squad' component="th" scope="row">
                          <img className="pointer mt1" src="/icons/check-box.svg" alt=""/>
                        </TableCell>
                        <TableCell className='so-delivery underline' component="th" scope="row">
                          <NavLink to="/singleorderview">
                              LX/OUT/00869
                          </NavLink>
                        </TableCell>
                        <TableCell className='so-dispatcher' component="th" scope="row">
                          NA
                        </TableCell>
                        <TableCell className='so-status' component="th" scope="row">
                              <NavLink className="so-button" to="/singleorderview">Schedule Pickup</NavLink>
                        </TableCell>
                      </TableRow>
                      <TableRow
                      className='so-row-cont'
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell className='so-squad' component="th" scope="row">
                          <img className="pointer mt1" src="/icons/check-box.svg" alt=""/>
                        </TableCell>
                        <TableCell className='so-delivery underline' component="th" scope="row">
                          <NavLink to="/singleorderview">
                              DS A2022/00181
                          </NavLink>
                        </TableCell>
                        <TableCell className='so-dispatcher' component="th" scope="row">
                          Soc. Agrícola e Comercial dos Vinhos Messias SA
                        </TableCell>
                        <TableCell className='so-status' component="th" scope="row">
                          Awaiting Schedule Pickup
                        </TableCell>
                      </TableRow>
                      <TableRow
                      className='so-row-cont'
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell className='so-squad' component="th" scope="row">
                          <img className="pointer mt1" src="/icons/check-box.svg" alt=""/>
                        </TableCell>
                        <TableCell className='so-delivery underline' component="th" scope="row">
                          <NavLink to="/singleorderview">
                              DS A2022/00182
                          </NavLink>
                        </TableCell>
                        <TableCell className='so-dispatcher' component="th" scope="row">
                          Direct Wine, Lda
                        </TableCell>
                        <TableCell className='so-status' component="th" scope="row">
                              <NavLink className="so-button" to="/singleorderview">Send Purchase Order</NavLink>
                        </TableCell>
                      </TableRow> */}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
};

export default SingleOrderTitle;
