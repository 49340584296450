import React, { useCallback } from "react";
import {
  IPaginatedRequest,
  IUserHistory,
  IPaginatedResponse,
} from "../../../interfaces";
import Paginator from "../../ui/pagination";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";

interface Props {
  userHistory: IPaginatedResponse<IUserHistory[]>;
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  currentConfig: IPaginatedRequest;
  loadUserHistory: boolean;
}

export const HLTable: React.FC<Props> = ({
  userHistory,
  setCurrentConfig,
  currentConfig,
  loadUserHistory,
}) => {
  const onChangePage = useCallback(
    async (config: IPaginatedRequest) => {
      setCurrentConfig((c: IPaginatedRequest) => {
        return {
          ...c,
          ...config,
        };
      });
    },
    [setCurrentConfig]
  );

  const dateFormat = (date: Date | string) => {
    return new Date(date).toLocaleString("en-US").replace(",", " -");
  };

  return (
    <div className="table-main-cont">
      <div style={{padding: "0 40px"}} className="width100">
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search user"
                    onChange={(e) =>
                      setCurrentConfig((conf: IPaginatedRequest) => ({
                        ...conf,
                        search: e.target.value,
                      }))
                    }
                    value={currentConfig.search}
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-value">
              {loadUserHistory ? (
                <CircularPreloader />
              ) : (
                <span className="table-main-title-text">
                  {userHistory?.total} logs
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="marketplace-pagination">
          <Paginator
            data={!loadUserHistory ? userHistory : {}}
            refetch={onChangePage as any}
          />
        </div>
      </div>
      <div className="table-border-bottom"></div>
      <div style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{
            position: "sticky",
            zIndex: 101,
            top: "2px",
            width: "100%",
          }}
        >
          <thead>
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="rew-lgs ml3">Date Time</th>
              <th className="rew-mins">Type</th>
              <th className="rew-lgs">User</th>
              <th className="rew-biggest">Log</th>
            </tr>
          </thead>
        </table>
        <table className="table-results mb2" style={{ minHeight: "auto" }}>
          <tbody>
            {userHistory?.data?.map((history: IUserHistory) => (
              <tr
                className="table-result-box height100px ft2"
                key={history._id}
              >
                <td className="rew-lgs ml3">{dateFormat(history?.created)}</td>
                <td className="rew-mins">{history.type}</td>
                <td className="rew-lgs">
                  <div className="table-user">
                    <img
                      className="table-avatar"
                      src={
                        !history?.user?.profileImage
                          ? "/icons/image-bot.svg"
                          : history?.user?.profileImage
                      }
                      alt=""
                    />
                    <div>{history?.user?.username}</div>
                  </div>
                </td>
                <td className="rew-biggest">{history.text}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};
