import React, { useCallback, useMemo } from "react";
import { IPaginatedRequest, IPaginatedResponse } from "../../../interfaces";
import "./pagination.css";
import { useAtom } from "jotai";
import { atomWithHash } from "jotai/utils";

interface Props {
	data?: any;
	refetch: (config: IPaginatedRequest) => Promise<IPaginatedResponse<any>>;
}

export const limitArr = [
	{ text: "Show 5", limit: 5 },
	{ text: "Show 10", limit: 10 },
	{ text: "Show 20", limit: 20 },
	{ text: "Show 50", limit: 50 },
	{ text: "Show 100", limit: 100 },
];

const pageAtom = atomWithHash("page", 1);
const perPageAtom = atomWithHash("perPage", limitArr[3].limit);

const Paginator: React.FC<Props> = ({ data, refetch }) => {
	const [page, setPage] = useAtom(pageAtom);
	const [perPage, setPerPage] = useAtom(perPageAtom);

	const handleChangePage = useCallback(
		(newPage: number) => (e: React.MouseEvent) => {
			e.preventDefault();
			setPage(newPage);
			refetch({ page: newPage, perPage });
		},
		[setPage, perPage, refetch]
	);

	const prevPage = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault();

			if (page === 1) {
				return setPage(1);
			}

			setPage((prev) => {
				const page = prev - 1;
				refetch({ page, perPage });
				return page;
			});
		},
		[setPage, page, perPage, refetch]
	);

	const nextPage = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault();

			setPage((prev) => {
				const page = prev + 1;
				refetch({ page, perPage });
				return page;
			});
		},
		[setPage, perPage, refetch]
	);

	const pages = useMemo(() => {
		const pageNumbers = [];

		for (let i = 1; i <= data?.lastPage; i++) {
			pageNumbers.push(i);
		}

		return pageNumbers;
	}, [data?.lastPage]);

	const displayPages = useMemo(() => {
		if (pages.length <= 3) {
			return pages;
		}

		if (page === 1) {
			return pages.slice(page - 1, page + 2);
		}

		if (page === data?.lastPage) {
			return pages.slice(data?.lastPage - 3, data?.lastPage);
		}

		return pages.slice(page - 2, page + 1);
	}, [page, pages, data?.lastPage]);

	if (!displayPages.length) {
		return <></>;
	}

	return (
		<>
			<select
				className="main-select-bi"
				value={`Show ${perPage}`}
				onChange={(e) => {
					const perPage = limitArr.find(
						(item) => item.text === e.target.value
					)!.limit;
					setPerPage(perPage);
					refetch({ page, perPage });
				}}
			>
				{limitArr.map((item, index: number) => (
					<option key={index}>{item.text}</option>
				))}
			</select>

			<div className="pagination-container">
				<div
					className={`pagination-admin-button ${page === 1 ? "admin-button-disabled" : ""
						}`}
					onClick={prevPage}
				>
					<img src="/icons/less-blue.svg" width="10px;" alt="" />
				</div>

				{displayPages.map((p: number) => {
					return (
						<div
							key={p}
							onClick={handleChangePage(p)}
							className={`pagination-admin-button ${page === p ? "current-admin-button" : "prev-next-color-button"
								}`}
						>
							{p}
						</div>
					);
				})}

				<div
					className={`pagination-admin-button ${page === data?.lastPage
						? "admin-button-disabled"
						: "prev-next-color-button"
						}`}
					onClick={nextPage}
				>
					<img src="/icons/more-blue.svg" width="10px;" alt="" />
				</div>
			</div>
		</>
	);
};

export default Paginator;
