import React, { useContext, useEffect, useState } from "react";
import "./marketplaces.css";
import {
  getSupportedMarketplaces,
  getMarketplaceSetupConfig,
  createMarketplace,
  editMarketplace,
  deleteMarketplace,
  findMarketplace,
} from "../../../actions/marketplace";
import { IMarketplace, Role } from "../../../interfaces";
import Preloader from "../../ui/preloader/Preloader";
import { CountriesContext, UserContext } from "../../context";
import { boolean } from "boolean";
import { toast } from "react-toastify";
import { ICountryM } from "../country-management/CountryManagement";
import { queryClient } from "../../App";
import { useMutation, useQuery } from "react-query";
import { Modal } from "../../ui/modal/Modal";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import { useDropzone } from "react-dropzone";
import ModalHeader from "../single-marketplace/ModalHeader";
import qs from "query-string";
import useMarketplace from "../../../hooks/useMarketplace";
import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Checkbox } from "@mui/material";
import { isEmpty } from "lodash";

const globalAccount = ["amazon", "mirakl"];

export const firstElByAlphabet = (array: string[]) => {
  return array.sort()[0];
};

declare const confirm: (question: string) => boolean;

const FILE_TYPES = [".svg", ".png", ".jpg", ".jpeg"];

const CreateEditPlatform = (props: any) => {
  const { action, platformName } = qs.parse(window.location.search);
  const { user } = useUser();
  const countries = useContext(CountriesContext) as ICountryM[];
  const history = useHistory();

  const { data: marketplace, isSuccess } = useMarketplace(
    platformName as string,
    user?.seller?.accountType
  );

  const [zeoosName, setZeoosName] = useState<string>(
    marketplace?.zeoosName || ""
  );
  const [type, setType] = useState<string>(marketplace?.type || "marketplace");
  const [marketplaceName, setMarketplaceName] = useState<any>(
    marketplace?.marketplaceName
  );
  const [country, setCountry] = useState<string[]>(
    [marketplace?.country || countries[0].name]
  );
  const [isManual, setIsManual] = useState(boolean(marketplace?.isManual));
  const [active, setActive] = useState(
    marketplace ? boolean(marketplace?.active) : true
  );
  const [credentials, setCredentials] = useState(
    typeof marketplace?.credentials === 'string' ? JSON.parse(marketplace?.credentials) : (marketplace?.credentials || {} as any)
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setZeoosName(marketplace?.zeoosName! || zeoosName);
    setType(marketplace?.type! || "marketplace");
    setMarketplaceName(marketplace?.marketplaceName || marketplaceName);
    setCredentials(typeof marketplace?.credentials === 'string' ? JSON.parse(marketplace?.credentials) : marketplace?.credentials);
    setIsManual(boolean(marketplace?.isManual));
    setCountry(marketplace?.country ? [marketplace?.country] : country);
    setActive(marketplace && !isEmpty(marketplace) ? boolean(marketplace?.active) : true);
  }, [action, isSuccess, marketplace, countries, country, marketplaceName, zeoosName]);

  const { isLoading: loadSupportedMarketplaces, data: supportedMarketplaces } =
    useQuery("supportedMarketplaces", () => getSupportedMarketplaces());

  const { isLoading: loadMarketplaceConfig, data: marketplaceConfig } =
    useQuery("marketplaceConfig", () => getMarketplaceSetupConfig());

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: FILE_TYPES,
    onDropRejected: () => {
      toast.error(
        `File type rejected. Select one of ${FILE_TYPES.join(", ")}}`
      );
    },
    maxFiles: 1,
    // 10mb
    maxSize: 1e7,
  });

  const actionMarketplaceConfig = {
    onSuccess: () => {
      queryClient.invalidateQueries("marketplaces");
      queryClient.invalidateQueries(
        `single-marketplace?zeoosName=${marketplace?.zeoosName}`
      );
      toast.success(
        `The platform is successfully ${action === "edit" ? "edited" : "created"
        }!`
      );
      history.push("/platforms");
      setIsSubmitting(false);
    },
    onError: () => {
      toast.error("Something went wrong!");
      setIsSubmitting(false);
    },
  };

  const createMutation = useMutation(
    (marketplaceFields) => createMarketplace(marketplaceFields),
    actionMarketplaceConfig
  );

  const editMutation = useMutation(
    (marketplaceFields) => editMarketplace(marketplaceFields),
    actionMarketplaceConfig
  );


  const checkMarketplaceName = (marketplaceName: string) => {
    if (marketplaceName === 'Carrefour FR' || marketplaceName === 'Worten PT' || marketplaceName === 'Worten ES') {
      return 'mirakl';
    }

    return marketplaceName;
  };

  const onSubmitHandler = async (e: React.MouseEvent) => {
    if (!type || !country || !zeoosName || !marketplaceName) {
      toast.error("Please fill all field to proceed");
      return;
    }


    setIsSubmitting(true);
    e.preventDefault();
    const marketplaceFields = marketplace || ({} as any);
    marketplaceFields.zeoosName = zeoosName;
    marketplaceFields.marketplaceName = checkMarketplaceName(marketplaceName);
    marketplaceFields.type = type;
    marketplaceFields.country = country;
    marketplaceFields.additionalPricePerCountry =
      marketplace?.additionalPricePerCountry || 0;
    marketplaceFields.wineVatRate = marketplace?.wineVatRate || 0;
    marketplaceFields.champagneVatRate = marketplace?.champagneVatRate || 0;
    marketplaceFields.wineIecRate = marketplace?.wineIecRate || 0;
    marketplaceFields.champagneIecRate = marketplace?.champagneIecRate || 0;
    marketplaceFields.credentials = credentials;
    marketplaceFields.userToken = user.accessToken;
    marketplaceFields.isManual = isManual;
    marketplaceFields.active = active;
    marketplaceFields.marketplaceImg =
      acceptedFiles.length > 0 ? acceptedFiles[0] : marketplace?.marketplaceImg;
    if (user?.seller?.accountType === "Hub") {
      marketplaceFields.seller = user?.seller?.id;
    }

    if (action === "edit") {
      await editMutation.mutateAsync(marketplaceFields as any);
    } else {
      await createMutation.mutateAsync(marketplaceFields as any);
    }

    setIsSubmitting(false);
  };

  const deleteButtonHandler = async () => {
    const result = confirm("Are you sure?");
    if (result) {
      await deleteMarketplace(zeoosName);
      history.push("/platforms");
    }
  };


  if (user?.role !== Role.admin && !user?.seller?.contractURL) {
    return (
      <div className="s_accessdenied">
        <div className="access_box">
          <div>
            <h1 className="access_h1">Access Denied</h1>
            <p style={{ textAlign: "center" }}>Please Upload Contract File</p>
          </div>
        </div>
      </div>
    );

  }

  if (loadSupportedMarketplaces || loadMarketplaceConfig) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  if (!marketplaceName) {
    setMarketplaceName(supportedMarketplaces.sort()[0]);
  }

  const edit = marketplace?.zeoosName ? true : false;

  return (
    <div style={{ backgroundColor: "#E8EDF2" }}>
      <div style={{ zIndex: "1200" }} className="product-header-cont">
        <div className="in-row align-cener height100">
          <div className="in-row align-center ml2">
            <span className="product-header-text ml1">
              {action === "edit"
                ? `${platformName} Settings`
                : "Add a Platform"}
            </span>
          </div>
        </div>
        <div style={{ display: "flex", height: "100%" }}>
          <div
            style={{ backgroundColor: "#454545", cursor: "pointer" }}
            className="product-exit-btn"
            onClick={() => history.push("/platforms")}
          >
            <img className="mr2" src="/icons/product-exit.svg" alt="" />
            Discard
          </div>
          <button onClick={onSubmitHandler} className="product-header-button">
            {action === "create"
              ? `${isSubmitting ? "CREATING..." : "CREATE"}`
              : `${isSubmitting ? "EDITING..." : "EDIT"}`}{" "}
          </button>
        </div>
      </div>
      <div>
        <NavLink to="/platforms" className="single-billing-nav pointer ml3">
          <img src="/icons/back-colored.svg" alt="" width="25px;" />
          <div className="marketplace-new-platform-back-text ml2">
            back to Platforms
          </div>
        </NavLink>
      </div>
      <div className="marketplace-new-platform-create-container">
        <div className="marketplace-new-platform-form-container">
          <div className="in-row align-center justify-between width100">
            <p className="marketplace-new-platform-header-text">
              Platform Settings
            </p>
          </div>
          <div className="marketplace-new-platform-form-info">
            {/* 01 */}
            <RoleRequired>
              <div>
                <div
                  className="marketplace-new-platform-image-bg"
                  {...getRootProps()}
                >
                  {action !== "view" && <input {...getInputProps()} />}
                  {(marketplace?.marketplaceImg || acceptedFiles.length > 0) && (
                    <img
                      src={
                        marketplace?.marketplaceImg ||
                        URL.createObjectURL(acceptedFiles[0])
                      }
                      width="210px"
                      height="160px"
                      alt=""
                    />
                  )}
                  {!marketplace?.marketplaceImg && (
                    <div>
                      {acceptedFiles.length < 1 && (
                        <div className="marketplace-new-platform-logo-container">
                          <p className="marketplace-new-platform-logo-text">
                            LOGO
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {marketplace?.marketplaceImg && acceptedFiles.length ? (
                    <h2 style={{ color: "#000" }}>
                      Click 'Apply' to finish upload {acceptedFiles[0].name}
                    </h2>
                  ) : (
                    <></>
                  )}
                </div>
                {!acceptedFiles.length && (
                  <div className="table-vendormm-title-image-desc">
                    <div className="table-vendormm-title-image-text">
                      Add 480 x 360 image
                    </div>
                  </div>
                )}
              </div>
            </RoleRequired>
            {/* 02 */}
            <div>
              <>
                <div className="marketplace-new-platform-form-fields">
                  <span className="marketplace-new-platform-normal-text">
                    Platform name:
                  </span>
                  {action === "create" && (
                    <input
                      type="text"
                      className="formEdit__input"
                      required
                      onChange={(e) => setZeoosName(e.target.value)}
                      readOnly={edit}
                    />
                  )}
                  {action === "edit" && (
                    <p className="marketplace-new-platform-header-text pt1">
                      {zeoosName}
                    </p>
                  )}
                </div>
              </>
              <div className="marketplace-new-platform-form-fields">
                <span className="marketplace-new-platform-normal-text">
                  Platform:
                </span>
                <select
                  className="formEdit__input"
                  required
                  value={marketplaceName}
                  onChange={(e) => setMarketplaceName(e.target.value)}
                  disabled={edit}
                >
                  {supportedMarketplaces
                    .sort()
                    .map((marketplace: string, index: number) => {
                      if (marketplace === "mirakl") return;
                      return (
                        <>
                          <option key={index}>{marketplace}</option>
                        </>
                      );
                    })}
                  <option value={'Carrefour FR'}>Carrefour FR</option>
                  <option value={'Worten PT'}>Worten PT</option>
                  <option value={'Worten ES'}>Worten ES</option>
                </select>
              </div>
              <RoleRequired isHubOnly >

                {/* <div className="in-row width100 pt1 pb1 align-center">
                  <input
                    id="is-manual"
                    type="checkbox"
                    required
                    defaultChecked={isManual}
                    onChange={(e) => setIsManual(e.target.checked)}
                  />
                  <label htmlFor="is-manual" className="pointer ml1">
                    <span className="marketplace-new-platform-normal-text">
                      Manual
                    </span>
                  </label>
                </div> */}
              </RoleRequired>
            </div>
            {/* 03 */}
            <div>
              <div className="marketplace-new-platform-form-fields">
                <span className="marketplace-new-platform-normal-text">
                  Type:
                </span>
                <input
                  type="text"
                  className="formEdit__input"
                  required
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  disabled
                />
              </div>
              <div className="marketplace-new-platform-form-fields">
                <span className="marketplace-new-platform-normal-text">
                  Country:
                </span>
                <select
                  className={`formEdit__input ${globalAccount.includes(marketplaceName) ? "multi" : ""}`}
                  size={globalAccount.includes(marketplaceName) ? 5 : 1}
                  title="CTRL+Click to select multiple"
                  required
                  multiple={globalAccount.includes(marketplaceName)}
                  value={country}
                  onChange={(e) => {
                    const value = Array.from(e.target.options)
                      .filter((o: any) => o.selected)
                      .map((c: any) => c.value);
                    setCountry(value);
                  }}
                  disabled={edit}
                >
                  {countries.map((c: ICountryM, index: number) => (
                    <option key={index} selected={country.includes(c.name)}>{c.name}</option>
                  ))}
                </select>
              </div>
              <RoleRequired isHubOnly  >
                <div className="in-row width100 pt1 pb1 align-center">
                  <AntSwitch
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                    // onClick={onToggle}
                    name="active"
                    id="active"
                    required
                  />
                  <label htmlFor="active" className="pointer ml1">
                    <span style={{ color: active ? "#299C00" : "#ACACAC" }}>
                      Active
                    </span>
                  </label>
                </div>
              </RoleRequired>
            </div>

            <RoleRequired role={Role.user}>
              <div></div>
              <div></div>
              <div></div>
            </RoleRequired>
          </div>
        </div>
      </div>
      {marketplaceConfig[checkMarketplaceName(marketplaceName)] && (
        <div className="marketplace-new-platform-more-settings">
          <p className="marketplace-new-platform-header-text mb2">
            More Settings
          </p>
          {Object.keys(marketplaceConfig[checkMarketplaceName(marketplaceName)]).map(
            (key: string, index) => (
              <div key={index} className="in-column width100 ">
                <span className="marketplace-new-platform-credential-label">
                  {marketplaceConfig[checkMarketplaceName(marketplaceName)][key]?.label}:
                </span>
                <input
                  type="text"
                  className="formEdit__input"
                  required
                  value={credentials?.[key]}
                  onChange={(e) =>
                    setCredentials((prev: any) => ({
                      ...prev,
                      [key]: e.target.value,
                    }))
                  }
                />
              </div>
            )
          )}
        </div>
      )}
      {action === "edit" && (
        <div
          className="marketplace-new-platform-delete pointer "
          onClick={deleteButtonHandler}
        >
          <img
            src="/icons/grey-trash-can.svg"
            alt=""
            width="20px;"
            height="20px;"
          />
          <span className="marketplace-new-platform-back-text pl2">
            Delete platform account
          </span>
        </div>
      )}
    </div>
  );
};

export const RenderMarketplaceSetupField: React.FC<any> = ({
  keyword,
  marketplaceName,
  marketplaceConfig,
  children,
}) => {
  const config = marketplaceConfig?.[marketplaceName]?.[keyword];

  if (config?.label) {
    return (
      <>
        <span>{config?.label}:</span>
        {children}
      </>
    );
  }

  return null;
};

export default withRouter(CreateEditPlatform);
