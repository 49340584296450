import React, { useCallback, useState } from "react";
import { Shipment } from "./Shipment";
import { Invoices } from "./Invoices";
// import { AjusteDeLiquidacao } from "./AjusteDeLiquidacao";
import { toStringConverter } from "../tables/gestao/UpdateGestao";
import { TooltipText } from "../tables/gestao/Tooltip";
import { IPaginatedRequest } from "../../../interfaces";
import Paginator from "../../ui/pagination";

interface Props {
	shipments: any;
	setBillingsWindow: (billingWindow: boolean) => void;
	setBillingsId: (id: number) => void;
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
}

export function renderUnifiedOrderStatus(order: any) {
	const pickStatus = (statuses: string[]) => {
		for (const status of statuses) {
			if (typeof status === "string" && status !== "") {
				return status;
			}
		}
		return null;
	};
	const status: any = pickStatus([
		order?.sale_lengow_status,
		order?.sale_shopify_status,
		order?.shipment_payment_status,
	]);
	if (["fulfilled", "shipped", undefined, "Aberto"].includes(status)) {
		return <div className="billing-aberto">Open</div>;
	}
	if (["unfulfilled", "closed", false, "Fechado"].includes(status)) {
		return <div className="billing-fechado">Closed</div>;
	}
}

export const BillingTable: React.FC<Props> = ({
	shipments,
	setBillingsWindow,
	setBillingsId,
	setCurrentConfig,
	currentConfig,
}) => {
	const [openShipment, setOpenShipment] = useState<boolean>(false);
	const [openInvoice, setOpenInvoice] = useState<boolean>(false);
	const [shipmentId, setShipmentId] = useState<number>(0);
	// const [invoiceRef, setInvoiceRef] = useState<string>("");
	const [invoiceRef, setInvoiceRef] = useState<number>(0);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	return (
		<>
			<div className="table-main-title-cont">
				<div className="table-main-title-search-value-cont">
					<div className="search-box">
						<div className="search_form">
							<div className="table-search-box">
								<form
									className="table-search-form"
									onSubmit={(e) => e.preventDefault()}
								>
									<input
										className="table-search-input"
										placeholder="Search by shipment ID"
										onChange={(e) =>
											setCurrentConfig((conf: IPaginatedRequest) => ({
												...conf,
												search: e.target.value,
											}))
										}
										value={currentConfig.search}
									/>
									<img
										className="table-search-image"
										src="/icons/search.svg"
										width="25px;"
										alt=""
									/>
								</form>
							</div>
						</div>
					</div>
					<span className="table-main-title-text">
						{shipments.total} shipments
					</span>
				</div>
				<div className="marketplace-pagination">
					<Paginator data={shipments} refetch={onChangePage as any} />
				</div>
			</div>
			<div style={{ height: "max-content" }} className="table-overflow">
				<table
					style={{
						position: "sticky",
						zIndex: 101,
						top: "2px",
						width: "100%",
					}}
				>
					<tbody>
						<tr
							style={{ marginTop: "-5px", width: "100%" }}
							className="table-results-title"
						>
							<th className="billing-medium justify-start pl4">Shipment ID</th>
							<th style={{ marginLeft: "10px" }} className="billing-medium justify-start">Seller</th>
							<th className="billing-medium">Items Sold</th>
							<th className="billing-small">Quantity of Orders</th>
							<th className="billing-small">Value Received</th>
							<th className="billing-small">Total amount of the invoice</th>
							<th style={{ justifyContent: "flex-start", marginLeft: "10px" }} className="billing-small">Billings</th>
							<th className="billing-medium">Invoices</th>
							<th className="billing-medium">Status</th>
							<th className="billing-small">Settlement Adjustment</th>
						</tr>
					</tbody>
				</table>
				<table className="table-results">
					<tbody>
						{shipments?.data.map((item: any, index: number) => (
							<tr className="table-result-box billing-text" key={index}>
								<td className="justify-start pl4 billing-medium bold">
									<div
										className="billing-text pointer"
										onClick={() => {
											setOpenShipment(true);
											setShipmentId(item.shipment_id);
										}}
									>
										Shipment {item.shipment_id}
									</div>
								</td>
								<td
									className="billing-medium"
									style={{ justifyContent: "flex-start" }}
								>
									<TooltipText
										text={item.partner_id}
										items={3}
										separator={" "}
									/>
								</td>
								<td className="billing-medium">
									<div className="billing-largetext">
										{item.total_number_of_items_sold}/
										{item.total_number_of_items_purchased}
									</div>
								</td>
								<td className="billing-small">
									<div className="billing-text">
										{item.number_of_related_sale_orders}
									</div>
								</td>
								<td className="billing-small">
									{!item.valor_recebido ? (
										<></>
									) : (
										<div className="billing-cost">
											{toStringConverter(item.valor_recebido)} €
										</div>
									)}
								</td>
								<td className="billing-medium">
									{!item.total_amount ? (
										<></>
									) : (
										<div className="billing-cost">{item.total_amount} €</div>
									)}
								</td>
								<td style={{ justifyContent: "flex-start", marginLeft: "10px" }} className="billing-small bold ">
									{item.number_of_billings === 0 ? (
										<></>
									) : (
										<div
											className="billing-text pointer"
											onClick={() => {
												setBillingsId(item.shipment_id);
												setBillingsWindow(true);
											}}
										>
											{item.number_of_billings}{" "}
											{item.number_of_billings > 1 ? "Billings" : "Billing"}
										</div>
									)}
								</td>
								<td
									className="billing-medium  bold pointer"
									onClick={() => {
										setOpenInvoice(true);
										setInvoiceRef(item.bill_ref);
									}}
								>
									{!item.bill_ref ? <></> : <div>#{item.bill_ref}</div>}
								</td>
								<td className="billing-medium">
									<div className="billing-text">
										{renderUnifiedOrderStatus(item)}
										{/* {item.shipment_payment_status} */}
									</div>
								</td>
								<td
									className="billing-small  bold pointer"
									onClick={() => {
										setOpenInvoice(true);
										setInvoiceRef(item.bill_ref);
									}}
								>
									{/* {!item.creditNotes.length ? <></> : <AjusteDeLiquidacao data={item.creditNotes} />} */}
									{item.name_of_note}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{openShipment && (
				<Shipment
					shipmentId={shipmentId}
					setOpenShipment={setOpenShipment}
					openShipment={openShipment}
				/>
			)}
			{openInvoice && (
				<Invoices
					invoiceRef={invoiceRef}
					setOpenInvoice={setOpenInvoice}
					openInvoice={openInvoice}
				/>
			)}
		</>
	);
};
