import axios from "axios";
import fileDownload from "js-file-download";
import { UseQueryOptions } from "react-query";
import { PaginatedRequest } from "types/pagination";
import {
  CreateProduct,
  Product,
  ProductRequest,
  ProductResponse,
  UnregisteredProductResponse,
} from "types/product";
import { createUseDataHook, useData } from ".";
import { getHeaders } from "../user";
import { SchemeRootObject } from "../../interfaces";

export const useProducts = createUseDataHook<ProductRequest, ProductResponse>(
  "/v2/my/products"
);

export function useProductPlatforms(
  sku: string,
  query: { active?: boolean } = {},
  config: UseQueryOptions = {}
) {
  return useData<
    { active?: boolean },
    {
      name: string;
      platformId: string;
      image: string;
      country: string;
      platformProductId: string;
      active: boolean;
    }[]
  >(`/v2/product/${sku}/platforms`, query, config);
}

export function useActiveProductPlatforms(sku: string) {
  return useProductPlatforms(sku, { active: true }, { refetchOnMount: true });
}

export function useProductStock(sku: string) {
  return useData<any, number>(`/v2/product/${sku}/stock`, {});
}

export async function exportProductResults(seller?: string) {
  let query = "";

  if (window.location.pathname.startsWith("/my")) {
    query = `${window.location.search}&seller=${seller}`;
  } else {
    query = `${window.location.search}`;
  }
  await axios.post(`/v2/product-results/export${query}`, {}, getHeaders());
}
export async function exportProductResultsByLanguage(seller?: string, lang:string = 'pt') {
  let query = "";

  if (window.location.pathname.startsWith("/my")) {
    query = `${window.location.search}&seller=${seller}`;
  } else {
    query = `${window.location.search}`;
  }
  await axios.get(`/v2/product-results/export/language${query}&lang=${lang}`,  getHeaders());
}

export function useProductExistsInMyCatalog(
  sku: string,
  config: UseQueryOptions = {}
) {
  return useData<any, boolean>(`/v2/product/${sku}/exists`, {}, config);
}

export async function createProduct(data: CreateProduct) {
  const res = await axios.post("/v2/product", data, getHeaders());
  return res.data as Product;
}

export async function createProducts(file: File) {
  const formData = new FormData();
  formData.append("file", file);

  await axios.post("/v2/products/master", formData, getHeaders());
}

interface Props {
  category: string;
  lang: string | undefined;
  file: File;
}
export async function createManualTranslation(data: Props) {
  const formData = new FormData();
  formData.append("file", data.file);

  await axios.post(`/v2/products/${data.lang}/ManualTranslation`, formData, getHeaders());
}

export async function toggleProductStatus(
  platformProduct: string,
  status: boolean
) {
  await axios.put(
    `/v2/product/${platformProduct}/status`,
    { status },
    getHeaders()
  );
}

export const getSchemeByCategoryCode = async (code: string) => {
  const res = await axios.get(`/v2/product-scheme/${code}`, getHeaders());

  return res.data.schemes as SchemeRootObject;
};


export async function toggleProductStatusInBulk(
  platforms: Record<string, boolean>,
  skus?: string[]
) {
  await axios.put(
    `/v2/products/status${window.location.search}`,
    { platforms, skus },
    getHeaders()
  );
}

export function useBatch(batchId: string) {
  return useData<any, { registering: number; complete: number }>(
    `/v2/batch/${batchId}`,
    {}
  );
}

export function useBatchProducts(batchId: string, query: PaginatedRequest) {
  return useData<any, UnregisteredProductResponse>(
    `/v2/batch/${batchId}/products`,
    query
  );
}

export async function consultsEANs(file: File) {
  const formData = new FormData();
  formData.append("file", file);

  const res = await axios.post("/v2/consult-eans", formData, getHeaders());
  return fileDownload(res.data, "results.csv");
}

export async function getProductBySkuNew(sku: string) {
  const res = await axios.get(`/v2/product-by-sku/${sku}`, getHeaders());
  return res.data.product
}

export async function addAssetsByBatch(sku: string, images:string[]) {
  const res = await axios.post(`/v2/product/assets-upload/`, {sku, images}, getHeaders());
  return res.data
}
export async function getSellersCatalog(seller?: string) {
  const res = await axios.get(`/v2/product/seller-catalog${seller ? `?seller=${seller}` : ''}`, getHeaders());
  return res.data
}
