import { useQuery } from "react-query";
import { refreshTokens } from "../actions/user";
import { queryClient } from "../components/App";
import { Role } from "../interfaces";

const key = "currentUser";

function useUser() {
  const user = useQuery(key, async () => (await refreshTokens())?.user, {
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });

  return {
    isLoading: user.isLoading,
    user: user.data as any,
    setUser: (data: any) => {
      queryClient.setQueryData([key], (prev: any) => ({
        ...prev,
        ...data,
      }));
    },
    invalidate: () => queryClient.invalidateQueries(key),
  };
}

export function useIsAdmin() {
  const { user } = useUser();
  return user?.role === Role.admin;
}

export default useUser;
