import React from "react";
import { NavLink } from "react-router-dom";
import { IMarketplace } from "../../../interfaces";

interface Props {
  marketplace: IMarketplace;
}

export const PlatformNav: React.FC<Props> = ({marketplace}) => {
  return (
    <div
      className="table-title-nav-cont"
      style={{ gap: "25px", display: "flex" }}
    >
      <NavLink
        style={{
          padding: "10px 10px 5px",
        }}
        to={`/platform/${marketplace.zeoosName}`}
        className="table-tab-mapping"
        activeClassName="table-tab-mapping-active"
        exact
      >
        Products
      </NavLink>
      {marketplace.marketplaceIntegration && (
        <NavLink
          style={{ padding: "10px 10px 5px" }}
          to={`/platform/${marketplace.zeoosName}/category-rate`}
          className="table-tab-mapping"
          activeClassName="table-tab-mapping-active"
          exact
        >
          Category Rate
        </NavLink>
      )}
      {/* <NavLink
				style={{ padding: "10px 10px 24px" }}
				to="/platform-rates"
				className="table-tab-mapping"
				activeClassName="table-tab-mapping-active"
			>
				Standing Rules
			</NavLink> */}
    </div>
  );
}
