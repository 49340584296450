import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { setToken } from '../../../actions/token';

interface Props {

}

const UserPreview: React.FC<Props> = () => {
    const { search } = useLocation();
    const history = useHistory();

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const jwt = searchParams.get("jwt");

        setToken(jwt || '');

        window.location.replace(`http://${window.location.host}`);
    }, [search, history]);

    return (
        <div>loading...</div>
    );
};

export default UserPreview;
