import React, { memo, useState, useCallback } from "react";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { getSchemes } from "../../../../actions/pim";
import * as PricingController from "../../../../actions/pricingEvent";
import { StandingRule } from "types";
import Preloader from "../../../ui/preloader/Preloader";
import {
	SchemeField,
	SchemeItem,
	SchemeRootObject,
	SchemeTab,
} from "../../../../interfaces";
import { getSanitizedMarketplaces } from "../../../../actions/marketplace";
import SpecificSearchModal from "./SpecificSearchModal";
import CountryFlag from "../../../ui/country-flag/CountryFlag";

interface Props {
	setModal: (modal: React.SetStateAction<boolean>) => void;
	rqKeyRules: string;
}

const CreateStandingRule: React.FC<Props> = ({ setModal, rqKeyRules }) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const [selectedCategory, setSelectedCategory] =
		useState<string>("All categories");
	const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
	const [selectedProduct, setSelectedProduct] =
		useState<string>("All products");
	const [discountAmount, setDiscountAmount] = useState<string>("");

	const { isLoading: loadSchemes, data: schemes } = useQuery("schemes", () =>
		getSchemes()
	);

	const { isLoading: loadPlatforms, data: platforms } = useQuery(
		"sanitizedMarkeplaces",
		() => getSanitizedMarketplaces()
	);

	const refetchEventsConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyRules);
			toast.success(`The event is successfully "created"!`);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const createMutation = useMutation(
		(data: StandingRule) => PricingController.createStandingRule(data),
		refetchEventsConfig
	);

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			const data = {
				discountAmount: Number(discountAmount),
				category: schemes?.find(
					(scheme: SchemeRootObject) => scheme.name === selectedCategory
				)?._id as string,
				platforms: selectedPlatforms,
				product: selectedProduct,
				isActive,
			};
			await createMutation.mutateAsync(data);
		},
		[
			createMutation,
			discountAmount,
			selectedCategory,
			selectedPlatforms,
			selectedProduct,
			isActive,
			schemes,
		]
	);

	const productTypeSelect = useCallback(() => {
		const haveNumberOfBottlesArr = schemes
			?.map((scheme: SchemeRootObject) => {
				const logisticObj = scheme.details.tabs.find((tab: SchemeTab) =>
					tab.label.toLowerCase().includes("logística")
				);

				const envioObj = logisticObj?.fields.find((field: SchemeField) =>
					field.label.toLowerCase().includes("envio")
				);

				const obj = envioObj?.fields.find(
					(item: SchemeItem) => item.name === "numberBottles"
				);

				return obj
					? {
						scheme: scheme.name,
						value: obj.value,
					}
					: undefined;
			})
			.filter((x: { scheme: string; value: string[]; } | undefined) => x);

		if (selectedCategory !== "All categories") {
			return haveNumberOfBottlesArr?.find(
				(x: any) => x.scheme === selectedCategory
			)?.value;
		}
	}, [selectedCategory, schemes]);

	if (loadSchemes || loadPlatforms) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<>
			<div
				style={{ marginTop: "-60px", zIndex: "1200" }}
				className="product-header-cont"
			>
				<div className="in-row align-cener height100">
					<div className="product-exit-btn" onClick={() => setModal(false)}>
						<img className="mr2" src="/icons/product-exit.svg" alt="" />
						Discard
					</div>
					<div className="in-row align-center ml2">
						<span className="product-header-text ml1">Standing Rule</span>
					</div>
				</div>
				<button className="product-header-button" onClick={onSubmit}>
					SAVE & SYNC
				</button>
			</div>
			<div
				style={{
					background: "#E8EDF2",
					height: "max-content",
					minHeight: "93vh",
				}}
				className="product-cont m0 pt4"
			>
				<div className="new-pricing-column">
					<div className="new-pricing-cont">
						<div className="in-row align-center justify-between width100 mb4">
							<span className="new-pricing-title-text">Rule Conditions</span>
							<div className="new-pricing-text in-row align-center">
								<AntSwitch
									className="mr2"
									name="checkedC"
									checked={isActive}
									onChange={(e) => setIsActive(e.target.checked)}
									required
								/>
								<span className="email-textm">
									{isActive ? "Active" : "Inactive"}
								</span>
							</div>
						</div>
						<div className="in-row align-center justify-between width100">
							<div className="new-pricing-box in-column align-start">
								<span className="new-pricing-text mwidth120 mb2">
									Product category:
								</span>
								<select
									className="freight-modal-input mwidth220 mr4"
									value={selectedCategory}
									onChange={(e) => {
										if (selectedCategory !== e.target.value) {
											setSelectedProduct("All products");
										}
										setSelectedCategory(e.target.value);
									}}
								>
									{[
										"All categories",
										...schemes!.map((s: SchemeRootObject) => s.name),
									].map((cat: string, index: number) => (
										<option key={index}>{cat}</option>
									))}
								</select>
							</div>
							{productTypeSelect()?.length && (
								<div className="new-pricing-box in-column align-start">
									<span className="new-pricing-text mwidth120 mb2">
										Product type:
									</span>
									<select
										value={selectedProduct}
										onChange={(e) => {
											setSelectedProduct(e.target.value);
										}}
										className="freight-modal-input mwidth220 mr4"
									>
										{["All products", ...(productTypeSelect() as string[])].map(
											(val: string, index: number) => (
												<option key={index}>{val}</option>
											)
										)}
									</select>
								</div>
							)}
						</div>
						<div className="new-pricing-box in-column align-start">
							<span className="new-pricing-text mwidth120 mb2">
								Discount applied:
							</span>
							<div className="mwidth120 in-row align-center">
								<input
									className="mapping-modal-table-input text-end pr1 mr1"
									value={discountAmount}
									onChange={(e) => setDiscountAmount(e.target.value)}
								/>
								<span>%</span>
							</div>
						</div>
						<div className="border-bottom width100"></div>
						<div className="new-pricing-box in-column align-start mt4">
							<span className="new-pricing-text mwidth120 mb2">
								Platform Account:
							</span>
							<SpecificSearchModal
								type={"platforms"}
								specificArray={platforms || []}
								selectedItems={selectedPlatforms}
								setSelectedItems={setSelectedPlatforms}
							/>
							{selectedPlatforms.map((x: string, index: number) => (
								<div
									className="new-pricing-modal-cont justify-between m0 p1"
									key={index}
								>
									<div className="in-row align-center">
										<CountryFlag
											country={
												platforms.find((platform: any) => x === platform._id)
													?.country
											}
											className="table-search-image"
											width="28px"
										/>
										<span className="ml2 new-pricing-text">
											{
												platforms.find((platform: any) => x === platform._id)
													?.zeoosName
											}
										</span>
									</div>
									<img
										className="pointer"
										src="/icons/new-pricing-remove.svg"
										alt=""
										onClick={(e: React.MouseEvent) => {
											e.preventDefault();

											setSelectedPlatforms(
												selectedPlatforms.filter((z: string) => z !== x)
											);
										}}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(CreateStandingRule);
