import React, { memo, useState, useMemo } from "react";
import "./pricing.css";
import GraphBoxes from "./GraphBoxes";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { withStyles } from "@material-ui/core/styles";
// import PricingtFilter from "./PricingFilter";
import { Modal } from "../../ui/modal/Modal";
import StandingRules from "./StandingRules";
import PlannedEvents from "./planned-events/PlannedEvents";
// import PricingTimelineTable from "./PricingTimelineTable";
import { useDebouncedValue } from "@mantine/hooks";
import queryString from "query-string";
import { useQuery } from "react-query";
import {
	getPaginatedEvents,
	getPaginatedStandingRules,
} from "../../../actions/pricingEvent";
import { IPaginatedRequest } from "../../../interfaces";
import Preloader from "../../ui/preloader/Preloader";
import { PricingEvent } from "types";
import AutomaticPricing from "./planned-events-and-standing-edit/AutomaticPricing";
import CustomPricing from "./planned-events-and-standing-edit/CustomPricing";
import CreateStandingRule from "./planned-events-and-standing-edit/CreateStandingRule";

const AntTabPanel = withStyles({
	root: {
		background: "#e8edf2 !important",
		padding: "0 !important",
	},
})(TabPanel);

export const formatValue = (value: any) => {
	if (!value) {
		return 0;
	} else {
		return !String(value).includes(",")
			? value.toLocaleString("de-DE")
			: value.toLocaleString("de-DE", {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
			  });
	}
};

const Pricing: React.FC = () => {
	const [modalEvent, setModalEvent] = useState<boolean>(false);
	const [modalRule, setModalRule] = useState<boolean>(false);
	const [automaticModal, setAutomaticModal] = useState<boolean>(false);
	const [customModal, setCustomModal] = useState<boolean>(false);
	const [value, setValue] = useState<string>("1");
	// const [hide, setHide] = useState<boolean>(false);
	// const [hide2, setHide2] = useState<boolean>(false);

	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
	});

	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const rqKeyEvents = useMemo(() => {
		return `/pricing-events?${queryString.stringify(debouncedConfig)}`;
	}, [debouncedConfig]);

	const rqKeyRules = useMemo(() => {
		return `/pricing-rules?${queryString.stringify(debouncedConfig)}`;
	}, [debouncedConfig]);

	const { isLoading: loadEvents, data: events } = useQuery(rqKeyEvents, () =>
		getPaginatedEvents(debouncedConfig)
	);

	const { isLoading: loadRuless, data: standingRules } = useQuery(
		rqKeyRules,
		() => getPaginatedStandingRules(debouncedConfig)
	);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		event.preventDefault();
		setValue(newValue);
	};

	// const toggleHide = () => {
	// 	setHide(!hide);
	// };

	// const toggleHide2 = (e: React.MouseEvent) => {
	// 	e.preventDefault();
	// 	setHide2(!hide2);
	// };

	if (loadEvents || loadRuless) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<>
			{automaticModal || customModal || modalRule ? (
				<>
					{automaticModal && (
						<AutomaticPricing
							rqKeyEvents={rqKeyEvents}
							setAutomaticModal={setAutomaticModal}
						/>
					)}
					{customModal && (
						<CustomPricing
							rqKeyEvents={rqKeyEvents}
							setCustomModal={setCustomModal}
						/>
					)}
					{modalRule && (
						<CreateStandingRule
							rqKeyRules={rqKeyRules}
							setModal={setModalRule}
						/>
					)}
				</>
			) : (
				<div className="table-main-cont">
					<div className="billing-graph-cont">
						<GraphBoxes
							events={events?.data.filter(
								(event: PricingEvent) => !event.isActive
							)}
						/>
					</div>
					<div className="table-title-cont p0 mt4 mb4">
						<div className="mplc-filter-cont width100">
							<div className="in-row align-center">
								<TabContext value={value}>
									<TabList
										onChange={handleChange}
										aria-label="lab API tabs example"
									>
										<Tab
											// onClick={toggleHide2}
											value="1"
											className={`seller-product-tab ${
												value === "1" && "active-tab-default"
											}`}
											label="Planned Events"
										/>
										<Tab
											// onClick={toggleHide2}
											value="2"
											className="seller-product-tab"
											label="Standing Rules"
										/>
									</TabList>
								</TabContext>
							</div>
							<div className="in-row align-center">
								{/* <div
									style={{
										display: hide ? "none" : "flex",
									}}
									className="mr2 in-row align-center"
								>
									<img
										onClick={toggleHide}
										className="mr1 pointer"
										style={{
											width: "30px",
											height: "30px",
											display: hide ? "none" : "flex",
										}}
										src="/icons/pricing-tab2.svg"
										alt=""
									/>
									<img
										onClick={toggleHide}
										className="mr1 pointer"
										style={{
											width: "30px",
											height: "30px",
											display: hide ? "flex" : "none",
										}}
										src="/icons/pricing-tab2active.svg"
										alt=""
									/>
									<img
										onClick={toggleHide}
										className="pointer"
										style={{
											width: "30px",
											height: "30px",
											display: hide ? "flex" : "none",
										}}
										src="/icons/pricing-tab1.svg"
										alt=""
									/>
									<img
										onClick={toggleHide}
										className="mr1 pointer"
										style={{
											width: "30px",
											height: "30px",
											display: hide ? "none" : "flex",
										}}
										src="/icons/pricing-tab1active.svg"
										alt=""
									/>
								</div>
								<div className="">
									<PricingtFilter />
								</div>
								*/}
								<TabContext value={value}>
									<AntTabPanel value="1">
										<div className="single-main-title-end">
											<div
												onClick={() => setModalEvent(true)}
												className="tax-title-button pointer mr2"
											>
												<img src="/icons/pim-plus.svg" alt="" />
												<div className="pim-avaliacoes-title-button-text">
													Create Planned Event
												</div>
											</div>
										</div>
									</AntTabPanel>
									<AntTabPanel value="2">
										<div className="single-main-title-end">
											<div
												className="tax-title-button pointer mr2"
												onClick={() => setModalRule(true)}
											>
												<img src="/icons/pim-plus.svg" alt="" />
												<div className="pim-avaliacoes-title-button-text">
													Create Standing Rule
												</div>
											</div>
										</div>
									</AntTabPanel>
								</TabContext>
							</div>
						</div>
					</div>
					<div className="table-main-cont p0">
						<TabContext value={value}>
							<AntTabPanel
								style={{ background: "#e8edf2 !important" }}
								value="1"
							>
								<PlannedEvents
									events={events}
									rqKeyEvents={rqKeyEvents}
									setCurrentConfig={setCurrentConfig}
									currentConfig={currentConfig}
								/>
								{/* <div
									style={{
										display: hide ? "flex" : "none",
										height: "max-content",
										marginBottom: "20px",
									}}
								>
									<PricingTimelineTable events={events} />
								</div> */}
							</AntTabPanel>
							<AntTabPanel value="2">
								<div
									style={{
										height: "max-content",
										marginBottom: "20px",
									}}
								>
									<StandingRules
										standingRules={standingRules}
										rqKeyRules={rqKeyRules}
										setCurrentConfig={setCurrentConfig}
										currentConfig={currentConfig}
									/>
								</div>
							</AntTabPanel>
						</TabContext>
					</div>
					{modalEvent && (
						<CreateEventModal
							modal={modalEvent}
							setModal={setModalEvent}
							setAutomaticModal={setAutomaticModal}
							setCustomModal={setCustomModal}
						/>
					)}
				</div>
			)}
		</>
	);
};

interface ICreateEventModal {
	modal: boolean;
	setModal: (modal: React.SetStateAction<boolean>) => void;
	setAutomaticModal: (automaticModal: React.SetStateAction<boolean>) => void;
	setCustomModal: (customModal: React.SetStateAction<boolean>) => void;
}

const CreateEventModal: React.FC<ICreateEventModal> = ({
	setModal,
	modal,
	setAutomaticModal,
	setCustomModal,
}) => {
	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="mapping-modal-cont p0">
				<div className="table-modal-form-cont p1">
					<div className="freight-modal-table-box">
						<div className="table-modal-title-pricing">
							Select Temporary Pricing Event Type
						</div>
						<div className="table-modal-border"></div>
						<div
							style={{
								borderBottom: "1px solid #E1E1E1",
								padding: "20px",
							}}
							className="in-row align-center justify-between pricing-event-modal-active"
							onClick={() => {
								setAutomaticModal(true);
								setModal(false);
							}}
						>
							<div className="in-row align-center">
								<img width="24px" src="/icons/pricing-flash.svg" alt="" />
								<div
									style={{ textAlign: "start" }}
									className="pricing-text maxwidth300 ml4"
								>
									<b>Automatic Pricing</b>
									<br />
									Temporary discount applied to all selected products.
								</div>
							</div>
							<img width="24px" src="/icons/pricing-arrow.svg" alt="" />
						</div>
						<div
							style={{
								borderBottom: "1px solid #E1E1E1",
								padding: "20px",
								marginBottom: "10px",
							}}
							className="in-row align-center justify-between pricing-event-modal-active"
							onClick={() => {
								setCustomModal(true);
								setModal(false);
							}}
						>
							<div className="in-row align-center">
								<img width="24px" src="/icons/pricing-sheet.svg" alt="" />
								<div
									style={{ textAlign: "start" }}
									className="pricing-text maxwidth300 ml4"
								>
									<b>Custom Pricing</b>
									<br />
									Temporary customized discount per product created by
									spreadsheet.
								</div>
							</div>
							<img width="24px" src="/icons/pricing-arrow.svg" alt="" />
						</div>
						<div className="table-modal-form-button-box">
							<button
								onClick={onModalClose}
								className="table-modal-form-button-cancel"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(Pricing);
