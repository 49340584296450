import React, {
  ChangeEvent,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PimMainTable } from "./PimMainTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { CreateProductModal } from "./CreateProductModal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  Link,
  NavLink,
  RouteComponentProps,
  useHistory,
  withRouter,
} from "react-router-dom";
import {
  getOpenSchemeTabs,
  getOpenTabs,
  getSchemes,
  removeOpenTab,
  removeOpenSchemeTab,
} from "../../../actions/pim";
import { useQuery } from "react-query";
import { CreateSchemeForm } from "./scheme/CreateScheme";
import { CatalogTab, Role } from "../../../interfaces";
import RoleRequired from "../../utils/role-required/RoleRequired";
import useUser from "../../../hooks/useUser";
import { useAtom } from "jotai";
import { selectedProductsAtom } from "../tables/gestao/Gestao";
import { IPaginatedRequest, IPaginatedResponse } from "../../../interfaces";
import BatchDropDown from "../../ui/batch-dropdown/BatchDropDown";
import CardButtons from "../catalog/card-buttons/CardButtons";
import { UnregisteredTable } from "../catalog/unregistered-table/UnregisteredTable";
import ProductImportUploadedReportModal from "../../ui/product-import-uploaded-report-modal";
import OfferImportUploadedReportModal from "../../ui/offer-import-uploaded-report-modal";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import { useParamsState } from "hooks";
import { getSellersCatalog, useProducts } from "../../../actions/v2/product";
import { ProductRequest } from "types/product";
import useActiveVendors from "../../../hooks/useActiveVendors";
import FilterCategory from "../../ui/filters/FilterCategory";
import { CatalogFilter } from "../catalog/filter";
import { useClickOutside } from "@mantine/hooks";
import { toast } from "react-toastify";

export type PimProductResponse = IPaginatedResponse<
  any[],
  {
    all: { sku: string; }[];
  }
>;

// Note: this components is used for both:
// /catalog and /my/catalog routes
const PimMain: React.FC<RouteComponentProps<{ tab: string; }>> = ({
  match,
  location,
}) => {
  const [allSelectedFlag, setAllSelectedFlag] = useState(false);
  const [batch, setBatch] = useState<boolean>(false);

  const isMyCatalog = useMemo(
    () => location.pathname.startsWith("/my"),
    [location]
  );

  const tab = useMemo(
    () => (match.params?.tab || "integrated") as CatalogTab,
    [match]
  );
  const { user } = useUser();
  const history = useHistory();

  useEffect(() => {
    if (isMyCatalog && user.role === Role.admin) {
      history.push(`/catalog/${tab}`);
    }
  }, [user, isMyCatalog, tab, history]);

  const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);
  const [currentConfig, setCurrentConfig] = useParamsState<ProductRequest>({
    perPage: 10,
    page: 1,
    type: tab as any,
  });
  const debouncedConfig = useDeferredValue(currentConfig);

  const { isLoading, data: products } = useProducts({
    ...debouncedConfig,
    ...(isMyCatalog ? { seller: user?.seller?._id || "myseller" } : {}),
  });

  const tabHTML = useMemo(() => {
    switch (tab) {
      case "integrated":
      case "non-integrated":
        return (
          <PimMainTable
            label={`${tab} Products`}
            products={products!}
            setCurrentConfig={setCurrentConfig}
            currentConfig={currentConfig}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            isLoading={isLoading}
          />
        );
      case "unregistered":
        return <UnregisteredTable />;
    }
  }, [
    tab,
    currentConfig,
    products,
    selectedProducts,
    setSelectedProducts,
    isLoading,
    setCurrentConfig,
  ]);

  return (
    <div className="main-body">
      <div className="main-container">
        <PIMHeader
          setCurrentConfig={setCurrentConfig}
          currentConfig={currentConfig}
        />
        <BatchDropDown
          products={products!}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          batch={batch}
          setBatch={setBatch}
          productsFrom={location.pathname}
          allSelectedFlag={allSelectedFlag}
          setAllSelectedFlag={setAllSelectedFlag}
        />

        <div className="table-main-cont pt4">
          {!isMyCatalog && <CardButtons isMy={isMyCatalog} setCurrentConfig={setCurrentConfig} />}


          {tabHTML}
        </div>
      </div>

      <RoleRequired role={Role.user} strict>
        <ProductImportUploadedReportModal />
        <OfferImportUploadedReportModal />
      </RoleRequired>
    </div>
  );
};

const PIMHeader: React.FC<{
  setCurrentConfig: any;
  currentConfig: any;
}> = ({ setCurrentConfig, currentConfig }) => {
  const { user } = useUser();

  const [modal, setModal] = useState<boolean>(false);
  const [createProductModal, setCreateProductModal] = useState(false);
  const [isViewFilterCategory, setIsViewFilterCategory] = useState(false);
  const [filterName, setFilterName] = useState<string | undefined>(undefined);
  const [clearFilter, setClearFilter] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [isExportingCatalog, setIsExportingCatalog] = useState(false);
  const [consultEansModal, setConsultEansModal] = useState(false);

  const history = useHistory();
  const { data: schemes } = useQuery("schemes", () => getSchemes(), {
    enabled: user.role === Role.admin,
    refetchOnWindowFocus: false,
  });
  const vendors = useActiveVendors();
  const dropdownRef = useClickOutside(() => {
    if (open) {
      setOpen(false);
    }
  });

  const onCatalogExport = async () => {
    if (isExportingCatalog) return;
    setIsExportingCatalog(true);
    try {
      await getSellersCatalog();
      toast.success("Catalog Exported Successfully, Please check you mail!");
    } catch (error) {
      console.log("Error exporting catalog", error);
      toast.error("An error occured, Please try again");
    } finally {
      setIsExportingCatalog(false);
    }
  };

  const onChangeCatalog = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    if (e.target.value === "All Seller Catalogs") {
      setCurrentConfig({ seller: "all" });
    } else if (e.target.value === "Zeoos Catalog") {
      setCurrentConfig({ seller: "" });
    } else {
      setCurrentConfig({
        seller: vendors.data?.find((x: any) => x.name === e.target.value)._id,
      });
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    history.push("/catalogs-upload-files-in-batch", {
      files: Array.from(event.target.files!),
    });
  };

  return (
    <>
      <div className="pim-main-tabs">
        <PimOpenTabs />
      </div>

      <div
        className="main-table-title-cont m0"
        style={{ paddingLeft: "25px", paddingRight: "40px" }}
      >
        <RoleRequired isHubOnly>
          <div className="mplc-filter">
            <button
              onClick={() => {
                setCreateProductModal(true);
              }}
              className="pim-title-button pim-green"
            >
              Create Product
            </button>

            {createProductModal && (
              <CreateProductModal
                modal={createProductModal}
                setModal={setCreateProductModal}
                creationType="mass"
              />
            )}
          </div>
        </RoleRequired>
        <RoleRequired isD2COnly strict>
          <div className="mplc-filter"></div>
        </RoleRequired>
        <div style={{ display: "flex" }}>
          {currentConfig && setCurrentConfig && (
            <>
              <FilterCategory
                isViewFilter={isViewFilterCategory}
                setIsViewFilter={setIsViewFilterCategory}
                setCurrentConfig={setCurrentConfig}
                setFilterName={setFilterName}
                onClearFilter={clearFilter}
              />

              <CatalogFilter
                currentConfig={currentConfig}
                setCurrentConfig={setCurrentConfig}
              />
            </>
          )}
          <div className="app-drop-down-container ml2" ref={dropdownRef}>
            <button
              style={{ border: "1px solid #000", width: "120px" }}
              className="main-select-bi no-shadow p1"
              onClick={() => setOpen((p) => !p)}
            >
              <img src="/icons/not-set-dotts.svg" alt="" />
              <span className="email-text">More</span>
              <img width="14px" src="/icons/down-arrow.png" alt="" />
            </button>

            {open && (
              <ul
                style={{
                  marginLeft: "-80px",
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.50)",
                  borderRadius: "5px",
                }}
              >
                {/* <li
                      style={{ padding: "10px 5px", width: "200px" }}
                      className="in-row align-center not-menuitem-cont"
                    >
                      <span
                        className="not-menuitem-text"
                        onClick={() => setShowExportForm(true)}
                      >
                        Export products
                      </span>
                    </li> */}
                {/* <li
                  onClick={() => setOpenExportModal(true)}
                  style={{ padding: "10px 5px", width: "200px" }}
                  className="in-row align-center not-menuitem-cont"
                >
                  <span className="not-menuitem-text">Export page results</span>
                </li> */}

                <RoleRequired role={Role.user} strict>
                  <li
                    onClick={onCatalogExport}
                    style={{
                      padding: "10px 5px",
                      width: "200px",
                    }}
                    className="in-row align-center not-menuitem-cont"
                  >
                    <span className="not-menuitem-text">
                      {isExportingCatalog ? "Exporting..." : "Export Catalog"}
                    </span>
                  </li>
                  {/* <li
                    onClick={() => {
                      setModal(true);
                    }}
                    style={{
                      padding: "10px 5px",
                      width: "200px",
                    }}
                    className="in-row align-center not-menuitem-cont"
                  >
                    <span className="not-menuitem-text">
                      Add products to my catalog
                    </span>
                  </li> */}
                  <NavLink
                    className="width100"
                    to={`/catalog-export-reports`}
                  >
                    <li
                      style={{
                        padding: "10px 5px",
                        width: "200px",
                      }}
                      className="in-row align-center not-menuitem-cont"
                    >
                      <span className="not-menuitem-text">
                        Catalog Export Reports
                      </span>
                    </li>
                  </NavLink>
                </RoleRequired>

                <RoleRequired>
                  <li
                    style={{ padding: "10px 5px", width: "200px" }}
                    className="in-row align-center not-menuitem-cont"
                  >
                    <span
                      className="not-menuitem-text"
                      onClick={() => setConsultEansModal(true)}
                    >
                      Consult EANs
                    </span>
                  </li>
                  <li
                    style={{ padding: "10px 5px", width: "200px" }}
                    className="in-row align-center not-menuitem-cont"
                  >
                    <span
                      className="not-menuitem-text"
                      onClick={() => setCreateProductModal(true)}
                    >
                      Translate via file
                    </span>
                  </li>
                  <li
                    style={{ padding: "10px 5px", width: "200px" }}
                    className="in-row align-center not-menuitem-cont"
                  >
                    <Link to="/batch-list">
                      <span className="not-menuitem-text">Product export</span>
                    </Link>
                  </li>
                  <li
                    style={{ padding: "10px 5px", width: "200px" }}
                    className="in-row align-center not-menuitem-cont"
                  >
                    <label className="not-menuitem-text">
                      Upload files in batch
                      <input type="file" multiple onChange={handleFileChange} />
                    </label>
                  </li>
                </RoleRequired>
              </ul>
            )}
          </div>
        </div>
        {filterName && (
          <div
            style={{
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              marginBottom: "11px",
              marginLeft: "15px",
              color: "#9E9E9E",
            }}
          >
            <span>{filterName}</span>
            <button
              onClick={() => setClearFilter(!clearFilter)}
              style={{
                background: "transparent",
                boxShadow: "none",
                gap: "5px",
                color: "#ACACAC",
                flexDirection: "row",
                fontSize: "12px",
              }}
            >
              <img src="/icons/cross.svg" alt="cross icon" />
              Clear
            </button>
          </div>
        )}
        <RoleRequired>
          <div className="in-row align-center">
            <div className="align-center">
              <span className="filter-text mr1">Catalog:</span>
              {!vendors.isLoading ? (
                <div className="main-filter-select-container width200px">
                  <select
                    className="main-filter-select"
                    onChange={(e) => onChangeCatalog(e)}
                    value={
                      currentConfig.seller === "all"
                        ? "All Seller Catalogs"
                        : vendors?.data?.find(
                          (x: any) => x._id === currentConfig.seller
                        )?.name || "Zeoos Catalog"
                    }
                  >
                    {[
                      "All Seller Catalogs",
                      "Zeoos Catalog",
                      ...vendors?.data?.map((vendor: any) => vendor.name),
                    ]?.map((item: string, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <CircularPreloader />
              )}
            </div>

            {/* {batch && (
            <div className="in-row align-center">
              <div className="pim-batch-figure">
                <img
                  width="45px"
                  height="45px"
                  src="/icons/comment-cloud.svg"
                  alt=""
                />
                <span className="pim-batch-text">
                  {selectedProducts.length}
                </span>
              </div>
              <NavLink to="/batch-products?from=products">
                <div className="upload-batch-button in-row align-center ml1">
                  <img
                    className="mr1"
                    width="15px"
                    height="15px"
                    src="/icons/upload.svg"
                    alt=""
                  />
                  <div>Upload batch</div>
                </div>
              </NavLink>
            </div>
          )} */}
          </div>
        </RoleRequired>
      </div>
    </>
  );
};

export const PimOpenTabs: React.FC<any> = () => {
  const [tabs, setTabs] = useState([] as string[]);
  const [schemeTabs, setSchemeTabs] = useState([] as string[]);

  const history = useHistory();

  useEffect(() => {
    let count = 0;
    const handle = setInterval(() => {
      if (++count === 3) {
        clearInterval(handle);
      }

      setTabs(getOpenTabs());
      setSchemeTabs(getOpenSchemeTabs());
    }, 100);
  }, [history.location]);

  return (
    <Box sx={{ maxWidth: 2560, background: "#55718A" }}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        value={0}
      >
        <Tab
          style={{
            padding: "0 10px",
            minHeight: "50px",
            minWidth: "auto",
            display: "flex",
            alignItems: "center",
          }}
          iconPosition="end"
          className={`pim-main-tab ${history.location.pathname.startsWith("/catalog")
            ? "pim-main-tab-active"
            : ""
            }`}
          label="Products"
          to="/catalog/integrated"
          component={Link}
        />
        {tabs.map((sku: string) => {
          const productPath = `/productInfo/${sku}`;
          return (
            <Tab
              key={sku}
              style={{
                padding: "0px 10px 0px 25px",
                minHeight: "50px",
                minWidth: "auto",
                display: "flex",
                alignItems: "center",
              }}
              icon={
                <CancelRoundedIcon
                  className="pointer"
                  style={{ width: "20px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    removeOpenTab(sku);

                    const newTabs = tabs.filter((x) => x !== sku);
                    setTabs(newTabs);

                    if (history.location.pathname === productPath) {
                      history.push("/catalog/integrated");
                    }
                  }}
                />
              }
              iconPosition="end"
              className={`pim-main-tab ${history.location.pathname === productPath
                ? "pim-main-tab-active"
                : ""
                }`}
              label={sku}
              to={productPath}
              component={Link}
            />
          );
        })}
        {schemeTabs.map((id: string, index: number) => {
          const schemePath = `/pim/scheme/${id}`;
          return (
            <Tab
              style={{
                padding: "0px 10px 0px 25px",
                minHeight: "50px",
                minWidth: "auto",
                display: "flex",
                alignItems: "center",
              }}
              icon={
                <CancelRoundedIcon
                  className="pointer"
                  style={{ width: "20px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    removeOpenSchemeTab(id);

                    const newTabs = schemeTabs.filter((x) => x !== id);
                    setSchemeTabs(newTabs);

                    if (history.location.pathname === schemePath) {
                      history.push("/catalog/integrated");
                    }
                  }}
                />
              }
              iconPosition="end"
              className={`pim-main-tab ${history.location.pathname === schemePath
                ? "pim-main-tab-active"
                : ""
                }`}
              label={id}
              to={schemePath}
              component={Link}
              key={index}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default withRouter(PimMain);
