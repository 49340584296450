import React, { useCallback, useMemo, useState } from "react";
import { ModalOffer, Offer, OfferGroup, OfferProduct } from "types/offer";
import ConditionalLink from "../../../../utils/conditional-link";
import { getMarketplaceProductImage } from "../../../../../actions/pim";
import useUser from "../../../../../hooks/useUser";
import { Role } from "../../../../../interfaces";
import { TooltipText } from "../Tooltip";
import useSanitizedMarketplaces from "../../../../../hooks/useSanitizedMarketplaces";
import { useAtom, useAtomValue } from "jotai";
import { selectedProductsAtom } from "../Gestao";
import { getSellersMarketplace, rankingZeoosWinner } from "../TableGestao";
import { convertPrice } from "../UpdateGestao";
import GestaoGridModal from "../GestaoGridModal";

interface Props {
  offerGroups: OfferGroup[];
  allSelected?: boolean;
  openPriceUpdateModal?: (offer: ModalOffer) => void;
  handleSelectOne?: (item: OfferGroup) => () => void;
}

const OffersGridView: React.FC<Props> = ({
  offerGroups,
  openPriceUpdateModal,
  handleSelectOne,
  allSelected = false,
}) => {
  const { user } = useUser();
  const isAdmin = useMemo(() => user.role === Role.admin, [user.role]);
  const selectedProducts = useAtomValue(selectedProductsAtom);
  const [offerGroup, setOfferGroup] = useState<OfferGroup>();

  return (
    <div
      style={{ alignItems: "stretch" }}
      className="mt2 mb2 width100 wrap align-start"
    >
      {offerGroups?.map((item) => {
        // const featuredMrktp = featuredMarketplace(item);
        // const winner = rankingZeoosWinner(featuredMrktp[0]);
        const noffer = item.offers?.length || 0;
        const show = noffer > 5 ? 5 : noffer;
        return (
          <div className="gestao-product-main-box" key={item._id}>
            <div>
              <div className="in-row align-center justify-start width100">
                {/* @ts-ignore */}
                <div className="ml3 mr3" onClick={handleSelectOne(item)}>
                  <img
                    className="pointer"
                    src={
                      !selectedProducts.find((x) => x === item.sku)
                        ? "/icons/check-box.svg"
                        : "/icons/check-box-checked.svg"
                    }
                    alt=""
                  />
                </div>
                <div style={{ fontSize: "13px" }} className="gestao-title-text">
                  <TooltipText text={item.name} items={20} separator={" "} />
                </div>
              </div>
              <div className="border-bottom mt2 mb2" />
              <div className="in-row align-center justify-start width100">
                <ConditionalLink
                  condition={isAdmin}
                  to={`/productInfo/${item.sku}`}
                >
                  <img
                    src={getMarketplaceProductImage(item)}
                    alt={item.sku}
                    width="120px !important"
                    height="120px !important"
                  />
                </ConditionalLink>
                <div className="pl1">
                  <div
                    style={{ fontSize: "13px" }}
                    className="in-row align-center ustify-start width100 gestao-title-text mb1"
                  >
                    <b style={{ paddingRight: "4px" }}>SKU: </b>
                    <ConditionalLink
                      condition={isAdmin}
                      to={`/productInfo/${item.sku}`}
                    >
                      <TooltipText text={item.sku} items={7} separator={""} />
                    </ConditionalLink>
                  </div>
                  <div
                    style={{ fontSize: "13px" }}
                    className="in-row align-center ustify-start width100 gestao-title-text mb1"
                  >
                    <b style={{ paddingRight: "4px" }}>EAN: </b>
                    <div className="gestao-ean-text">{item.ean}</div>
                  </div>
                  <div
                    style={{ fontSize: "13px" }}
                    className="in-row align-center ustify-start width100 gestao-title-text mb1"
                  >
                    <b className="pr2">{!isAdmin ? "My " : ""}Stock: </b>
                    {isAdmin ? (
                      <>
                        {/* <HtmlTooltip
														title={
															<React.Fragment>
																<div className="gestao-tooltip-stock">
																	<div className="in-row width100 bold justify-between">
																		<span>Seller</span>
																		<span>Stock</span>
																	</div>
																	<div className="gestao-tooltip-border" />
																	{isAdmin ? "stockSellers(item)" : ""}
																</div>
															</React.Fragment>
														}
													> */}
                        <button className="no-shadow in-row align-center justify-center transparent gestao-total-text">
                          {/* {stockTotal(
																item,
																isAdmin ? false : user.seller?.id
															)} */}
                          {item.offers?.[0]?.stock || 0}
                          {/* <img
																width="10px"
																style={{ opacity: 0.5, marginLeft: 5 }}
																src="/icons/down-arrow.png"
																alt=""
															/> */}
                        </button>
                        {/* </HtmlTooltip> */}
                      </>
                    ) : (
                      <div className="gestao-total-text">
                        {/* {stockTotal(
															item,
															isAdmin ? false : user.seller?.id
														)} */}
                        {item.offers?.[0]?.stock || 0}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="border-bottom mt2 mb2" />

              <table
                style={{ fontSize: "12px" }}
                className="mapping-modal-table gestao-title-text"
              >
                <thead className="in-column justify-center">
                  <tr
                    style={{
                      width: "91%",
                      borderBottom: "1px solid #e1e1e1",
                      margin: "auto !important",
                    }}
                    className="mapping-modal-results-title justify-between m0 pt2 pb2"
                  >
                    <th
                      style={{ flex: "0 1 30%", width: "120px" }}
                      className="inv-modal1-table-seller"
                    >
                      Platform
                    </th>
                    {/* {isAdmin && (
                                            <th className="inv-modal1-table-column">Sellers</th>
                                        )} */}
                    <th className="inv-modal1-table-column">Price</th>
                    {/* <th className="inv-modal1-table-column">Ranking</th> */}
                  </tr>
                </thead>
                <tbody className="in-column justify-center">
                  {item.offers?.map((offer, i) => {
                    if (i >= 5) return;
                    const seller = isAdmin ? undefined : user.seller;
                    const showSeller = isAdmin ? false : user.seller?.id;
                    const winner = item.offers?.find((o) => o.ranking === 1);

                    // const winner = rankingZeoosWinner(m, seller);

                    return (
                      <tr
                        key={offer.ean + offer.platformId}
                        style={{
                          width: "91%",
                          borderBottom: "1px solid #e1e1e1",
                          margin: "auto !important",
                        }}
                        className="mapping-modal-result-box justify-between pt1 pb1 pointer platform-list"
                        onClick={() => {
                          openPriceUpdateModal?.(offer);
                        }}
                      >
                        <td
                          style={{ flex: "0 1 30%", width: "120px" }}
                          className="inv-modal1-table-seller"
                          title={offer.platform}
                        >
                          {offer.platform}
                        </td>
                        {/* {isAdmin && (
															<td className="inv-modal1-table-column">
																{getSellersMarketplace(m)}
															</td>
														)} */}

                        <td className="inv-modal1-table-column text-end ">
                          {offer?.price ? (
                            `${convertPrice(
                              offer?.promotionalPrice?.pvpFinal || 
                              offer?.price?.pvpFinal || 0
                            )} €`
                          ) : (
                            <div className="blue">add</div>
                          )}
                        </td>

               
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="mt2 mb2" />

            <div className="in-row align-center justify-center">
              <div
                onClick={() => setOfferGroup(item)}
                className="gestao-grid-modal-btn"
              >
                {item.integratedPlatformsCount - show <= 0
                  ? "no more platforms"
                  : `+ ${
                      item.integratedPlatformsCount - show
                    } platform(s)`}
              </div>
            </div>
          </div>
        );
      })}

      {offerGroup && (
        <GestaoGridModal
          product={offerGroup}
          openPriceUpdateModal={openPriceUpdateModal}
          close={() => setOfferGroup(undefined)}
          // setModal1={setModal1}
          // setProduct={setProduct}
          // setMarketplace={setMarketplace}
          // setSelectedWinner={setSelectedWinner}
          // setSelectedSeller={setSelectedSeller}
          // setModalUpdate={setModal}
        />
      )}
    </div>
  );
};

export default OffersGridView;
