import React from "react";
import { NavLink } from "react-router-dom";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../../../interfaces";

export default function PayoutsNav() {
	return (
		<>
			<div className="table-value-search-cont mt2">
				<div className="table-title-nav-cont">
					<RoleRequired role={Role.admin}>
						<NavLink
							to="/payouts"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							Sellers
						</NavLink>
						<NavLink
							to="/payouts-partners"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							Partners
						</NavLink>
					</RoleRequired>
				</div>
			</div>
			<div className="mapping-title-border"></div>
		</>

	);
}
