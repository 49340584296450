import React, { useState } from "react";
import { Modal } from "../../ui/modal/Modal";

interface Props {
  passwordId: string;
  handleRemovePassword: (event: React.MouseEvent, id: string) => void;
}

export const Delete: React.FC<Props> = ({
  passwordId,
  handleRemovePassword,
}) => {
  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <>
      <img
        onClick={() => {
          setModal(true);
        }}
        className="cm-delete-button"
        src="/icons/grey-trash-can.svg"
        alt=""
        width="30px;"
      />
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <>
            <div className="table-modal-form-cont p4">
              <div className="updateForm__container">
                <div className="vendor-mapping-modal-text">
                  Are you sure you want to delete this <br /> password's
                  account?
                </div>
                <div className="table-modal-border"></div>
                <div className="table-modal-form-button-box width100 m0">
                  <button
                    className="table-modal-form-button-cancel"
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="table-modal-form-button"
                    type="submit"
                    onClick={(e) => {
                      handleRemovePassword(e, passwordId);
                      setModal(false);
                    }}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};
