import axios from "axios";
import { ICategoryField } from "types";
import { getHeaders } from "../user";
import { stringify } from "query-string";

type Response = {
    data:ICategoryField[],
    page:number,
    perPage:number,
    total:number,
    lastPage:number
}
export async function find(search: { marketplaceName?: string; id?: string, search?:string, page?:number, perPage?:number }) {
	const query = stringify(search);
	const res = await axios.get(`/v2/category-fields?${query}`, getHeaders());
	return res.data as Response
}