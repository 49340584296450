import React, { memo, useCallback, useMemo, useState } from "react";
import * as _ from "lodash";
import { Modal } from "../../../ui/modal/Modal";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import { ICountryM } from "../../country-management/CountryManagement";
import useCountries from "../../../../hooks/useCountries";
import { toast } from "react-toastify";
import type {
	ILogisticWarehouse,
	ILogisticPartner,
} from "../../../../../../../packages/types/index";
import * as LogisticController from "../../../../actions/logistic";
import { useQuery } from "react-query";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { IPaginatedRequest } from "../../../../interfaces";
import { useDebouncedValue } from "@mantine/hooks";
import queryString from "query-string";

interface Props {
	warehouse?: ILogisticWarehouse;
	rqKeyWarehouses?: string;
	modal: boolean;
	setModal: (value: React.SetStateAction<boolean>) => void;
}

const CreateEditWarehouse: React.FC<Props> = ({
	warehouse,
	rqKeyWarehouses,
	modal,
	setModal,
}) => {
	const countries = useCountries() as ICountryM[];

	const [warehouseName, setWarehouseName] = useState(
		warehouse?.warehouseName || ""
	);
	const [logisticPartner, setLogisticPartner] = useState(
		warehouse?.logisticPartner.partnerName || ""
	);
	const [country, setCountry] = useState(
		warehouse?.country || "Select country"
	);
	const [phone, setPhone] = useState(warehouse?.phone || "");
	const [contactName, setContactName] = useState(warehouse?.contactName || "");
	const [email, setEmail] = useState(warehouse?.email || "");
	const [postalCode, setPostalCode] = useState(warehouse?.postalCode || "");
	const [address, setAddress] = useState(warehouse?.address || "");
	const [autocompleteOpen, setAutocompleteOpen] = useState(false);
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 5,
		page: 1,
		search: "",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const rqKeyPartners = useMemo(() => {
		return `/logistic-partners?${queryString.stringify(debouncedConfig)}`;
	}, [debouncedConfig]);

	const { isLoading, data: partners } = useQuery(rqKeyPartners, () =>
		LogisticController.getPaginatedPartners(debouncedConfig)
	);

	const refetchWarehouseConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyWarehouses);
			toast.success(
				`The warehouse is successfully ${warehouse ? "updated" : "created"}!`
			);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const createMutation = useMutation(
		(data: ILogisticWarehouse) => LogisticController.createWarehouse(data),
		refetchWarehouseConfig
	);

	const editMutation = useMutation(
		(data: ILogisticWarehouse) => LogisticController.updateWarehouse(data),
		refetchWarehouseConfig
	);

	const deleteMutation = useMutation(
		(id: string) => LogisticController.deleteWarehouse(id),
		refetchWarehouseConfig
	);

	const data = useMemo(
		() => ({
			...warehouse,
			warehouseName,
			country,
			phone,
			contactName,
			email,
			postalCode,
			address,
		}),
		[
			warehouse,
			warehouseName,
			country,
			phone,
			contactName,
			email,
			postalCode,
			address,
		]
	) as ILogisticWarehouse;

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			data.logisticPartner = partners?.data.find(
				(p: ILogisticPartner) => p.partnerName === logisticPartner
			);

			if (warehouse) {
				await editMutation.mutateAsync(data);
			} else {
				await createMutation.mutateAsync(data);
			}

			setModal(false);
		},
		[
			warehouse,
			setModal,
			createMutation,
			data,
			editMutation,
			logisticPartner,
			partners?.data,
		]
	);

	const onDeleteHandler = useCallback(
		async (e: React.MouseEvent) => {
			e.preventDefault();

			if (warehouse) {
				await deleteMutation.mutateAsync(warehouse?._id);
			}

			setModal(false);
		},
		[deleteMutation, warehouse, setModal]
	);

	const createCountriesArray = (array: ICountryM[]) => {
		return [
			"Select country",
			..._.uniq(_.map(array, (item: ICountryM) => item.name)),
		];
	};

	const onModalClose = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			setModal(false);
		},
		[setModal]
	);

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="warehouse-modal-cont">
				<div className="table-modal-title-box mt3">
					<div className="warehouse-modal-title-text">
						<span>{warehouse ? "Update" : "New"} Warehouse</span>
						<span className="opacity0 pointer" onClick={onDeleteHandler}>
							delete
						</span>
					</div>
				</div>
				<form className="table-modal-form-cont text-start" onSubmit={onSubmit}>
					<div className="table-modal-border"></div>
					<div className="in-row align-center justify-between width100 mt3 mb3">
						<div className="width60">
							<div>Warehouse Name:</div>
							<input
								className="logistic-modal-input"
								value={warehouseName}
								onChange={(e) => setWarehouseName(e.target.value)}
								required
							/>
						</div>
						<div className="width40 in-column justify-start align-start pl2">
							<div>Logistic Partner:</div>
							<Autocomplete
								id="asynchronous-partner"
								className="warehouse-modal-select border-none"
								sx={{ p: 0 }}
								open={autocompleteOpen}
								onOpen={() => {
									setAutocompleteOpen(true);
								}}
								onClose={() => {
									setAutocompleteOpen(false);
								}}
								isOptionEqualToValue={(option, value) =>
									option.partnerName === value.partnerName
								}
								getOptionLabel={(option: any) => option.partnerName}
								options={partners?.data || []}
								loading={isLoading}
								includeInputInList
								filterSelectedOptions
								onChange={(event: any, newValue: ILogisticPartner) => {
									setLogisticPartner(newValue.partnerName);
								}}
								onInputChange={(event, newInputValue) => {
									setCurrentConfig((prev) => ({
										...prev,
										search: newInputValue,
									}));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label="All partners"
										required
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isLoading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="width100 mb4">
						<div className="width100 in-row align-center justify-between mb4">
							<div className="width40 pr2">
								<div className="bold">Contact Name:</div>
								<input
									className="logistic-modal-input"
									value={contactName}
									onChange={(e) => setContactName(e.target.value)}
									required
								/>
							</div>
							<div className="width60 pl2">
								<div className="bold">E-mail:</div>
								<input
									className="logistic-modal-input"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="width100 in-row align-center justify-between">
							<div className="width40">
								<div className="bold">Country of Operation:</div>
								<select
									className="warehouse-modal-select"
									value={country}
									onChange={(e) => {
										setCountry(e.target.value);
									}}
								>
									{createCountriesArray(countries).map(
										(country: string, index: number) => (
											<option key={index}>{country}</option>
										)
									)}
								</select>
							</div>
							<div className="width30">
								<div className="bold">Phone:</div>
								<input
									className="logistic-modal-input"
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									required
								/>
							</div>
							<div className="width25">
								<div className="bold">Postal Code:</div>
								<input
									className="logistic-modal-input"
									value={postalCode}
									onChange={(e) => setPostalCode(e.target.value)}
									required
								/>
							</div>
						</div>
					</div>
					<div className="width100 mb4">
						<div className="bold">Address:</div>
						<input
							className="logistic-modal-input"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							required
						/>
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box width100">
						<button
							className="table-modal-form-button-cancel"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button className="table-modal-form-button" type="submit">
							{warehouse ? "Update" : "Create"}
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(CreateEditWarehouse);
