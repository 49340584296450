import React, { useState } from "react";
import { Modal } from "../../ui/modal/Modal";
import { ICountryM } from "./CountryManagement";

interface Props {
	handleRemoveCountry: any;
	country: ICountryM;
}

export const CountryDelete: React.FC<Props> = ({
	handleRemoveCountry,
	country,
}) => {
	const [modal, setModal] = useState<boolean>(false);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<>
			<img
				onClick={() => {
					setModal(true);
				}}
				className="cm-delete-button"
				src="/icons/grey-trash-can.svg"
				alt=""
				width="30px;"
			/>
			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<>
						<div className="table-modal-form-cont p4">
							<div className="updateForm__container">
								<div className="vendor-mapping-modal-text">
									Are you sure you want to delete <br />{" "}
									<strong>"{country.name}"</strong>?
								</div>
								<div className="table-modal-border"></div>
								<div className="table-modal-form-button-box width100 m0">
									<button
										className="table-modal-form-button-cancel"
										onClick={() => {
											setModal(false);
										}}
									>
										Cancel
									</button>
									<button
										className="table-modal-form-button"
										type="submit"
										onClick={(e) => {
											handleRemoveCountry(e, country._id);
											setModal(false);
										}}
									>
										SET
									</button>
								</div>
							</div>
						</div>
					</>
				</Modal>
			)}
		</>
	);
};
