import axios from "axios";
import { IPaginatedRequest, IUser } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";

export interface IJobType {
  _id: string;
  name: string;
  description?: string;
  inputRequired: boolean;
  created: Date;
  fields?: any;
}

export async function getJobTypes() {
  const res = await axios.get("/jobTypes", getHeaders());
  return res.data.jobTypes as IJobType[];
}

export enum jobItemStatus {
  WAITING = "WAITING",
  RUNNING = "RUNNING",
  FINISHED = "FINISHED",
  ERROR = "ERROR",
}

export interface IJob {
  _id: string;
  name: string;
  type: string | IJobType;
  description: string;

  // ┌────────────── second (optional)
  // │ ┌──────────── minute
  // │ │ ┌────────── hour
  // │ │ │ ┌──────── day of month
  // │ │ │ │ ┌────── month
  // │ │ │ │ │ ┌──── day of week
  // │ │ │ │ │ │
  // │ │ │ │ │ │
  // * * * * * *
  frequency: string;

  latestReport: string;
  latestStatus: jobItemStatus;
  input: any;

  active: boolean;
  created: Date;
  user: IUser;
}

export async function createJob(job: Partial<IJob>) {
  const res = await axios.post("/job", job, getHeaders());
  return res.data.job as IJob;
}

export async function updateJob(id: string, job: Partial<IJob>) {
  await axios.put(`/job/${id}`, job, getHeaders());
}

export async function getJobs() {
  const res = await axios.get("/jobs", getHeaders());
  return res.data.jobs as IJob[];
}

export async function getJob(id: string) {
  const res = await axios.get(`/job/${id}`, getHeaders());
  return res.data.job as IJob;
}

export async function deleteJob(id: string) {
  await axios.delete(`/job/${id}`, getHeaders());
}

export async function executeJob(id: string, params?: any) {
  // This sends a request to our cronjob server
  await axios.post(
    `${
      process.env.REACT_APP_CRONJOB_SERVER_API_URL ||
      "https://my-app-9dijt.ondigitalocean.app/api"
    }/execute/job/${id}`,
    { ...params },
    getHeaders()
  );
}

export interface IJobItem {
  _id: string;
  job: string | IJob;
  status: jobItemStatus;
  report?: string;
  created: Date;
  finished: Date;
  user: IUser;
  deletedJobSnapshot?: IJob;
}

export async function createJobItem(jobItem: Partial<IJobItem>) {
  const res = await axios.post("/jobItem", jobItem, getHeaders());
  return res.data.jobItem as IJobItem;
}

export async function updateJobItem(id: string, jobItem: Partial<IJobItem>) {
  await axios.put(`/jobItem/${id}`, jobItem, getHeaders());
}

export async function getJobItems(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/jobItems?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}

export function readJobFromJobItem(jobItem: IJobItem) {
  return (jobItem.deletedJobSnapshot || jobItem.job) as IJob;
}
