import React, { useState } from "react";
import Iframe from "react-iframe";
import { Dropdown } from "../../ui/dropdown/Dropdown";
import "./intelligence.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";

const reports = [
  {
    name: "Amazon",
    items: [
      {
        title: "50 13D",
        id: "8575bb11-53a5-48e3-9e68-d81c1582931a",
      },
      {
        title: "All Wines - Spain",
        id: "9e36a12d-3919-4a82-a274-4f1d06d7470b",
      },
      {
        title: "Portuguese Wines - Spain",
        id: "bfc5b1fb-f9a1-4714-9df4-d286731b55aa",
      },
    ],
  },
  // {
  //   name: "Pricing",
  //   items: [
  //     {
  //       title: "Vivino PT",
  //       id: "72d471fc-abf3-4dab-bf32-84a51723652b",
  //     },
  //   ],
  // },
  {
    name: "Producers",
    items: [
      {
        title: "Producers",
        id: "6467a80c-c4da-4bbb-9c80-d996bbb79933",
      },
    ],
  },
  {
    name: "Websites",
    items: [
      {
        title: "BODEBOCA",
        id: "ac4afd84-0984-4120-bf5e-404faacad52f",
      },
      {
        title: "BODEBOCA PT",
        id: "aa199580-a0a5-41ac-89d0-5e32192d19fa",
      },
      {
        title: "DrinksCO",
        id: "522bc0c8-c19b-45bc-a231-c38052a09693",
      },
      {
        title: "Garrafeira",
        id: "a412b97d-ced5-4646-ae44-5cfd899343b1",
      },
      {
        title: "Portugal Wineyards",
        id: "37c95f39-65e5-4b3d-b2a0-c05e009154fe",
      },
      {
        title: "Vivino",
        id: "ac4afd84-0984-4120-bf5e-404faacad52f",
      },
    ],
  },
  {
    name: "Sales",
    items: [
      {
        title: "Portugal WineYards",
        id: "ffb5adb7-cc0e-4774-a871-8db18485d64e",
      },
    ],
  },
  {
    name: "Portuguese Wines",
    items: [
      {
        title: "Europe",
        id: "1585ba7d-9434-45c1-982a-a180ce0afecb",
      },
    ],
  },
];

const reportUrl = (id: string) => {
  return `https://app.powerbi.com/reportEmbed?reportId=${id}&autoAuth=true&ctid=2519ee02-a9f0-41ee-b091-589c7c347340&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLWUtcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D`;
};

export default function Intelligence() {
  reports.sort((a: any, b: any) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  const [url, setUrl] = React.useState(reportUrl(reports[0].items[0].id));
  const [title, setTitle] = React.useState(
    `${reports[0].name}: ${reports[0].items[0].title}`
  );

  const [dropdown, setDropdown] = useState<boolean>(false);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDropdown(false);
  };

  return (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont">
          <div className="marketplace-heading-and-create-box">
            <div className="int-filter-cont">
              <button
                className="int-dropdown"
                onClick={() => {
                  setDropdown(true);
                }}
              >
                REPORTS
                <img className="ml2" src="/icons/white-arrow-down.svg" alt="" />
              </button>
              {dropdown && (
                <Dropdown onModalClose={onModalClose} isOpened={dropdown}>
                  <>
                    <div className="int-option-title">Reports</div>
                    {reports.map((report: any) => (
                      <Accordion className="int-main-acc">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="int-accordion"
                        >
                          <span className="primary-color ml1">
                            {report.name}
                          </span>
                        </AccordionSummary>
                        <AccordionDetails className="p0">
                          {report.items.map((item: any, index: Number) => (
                            <MenuItem
                              className="int-option"
                              value={reportUrl(item.id)}
                              key={`item-${index}`}
                              onClick={(e) => {
                                setUrl(reportUrl(item.id));
                                setTitle(`${report.name}: ${item.title}`);
                              }}
                            >
                              {item.title}
                              <img src="/icons/int-arrow-right.svg" alt="" />
                            </MenuItem>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                </Dropdown>
              )}
              {/* <div className="mplc-filter-cont">
              <div className="mplc-filter">
                {reports.map((report: any) => (
                  <div className="selectdiv">
                    <select
                      className="main-select-bi ml1"
                      onChange={(e) => {
                        if (e.target.value) {
                          setUrl(e.target.value);
                          setTitle(
                            `${report.name}: ${
                              report.items[e.target.selectedIndex - 1].title
                            }`
                          );
                        }
                        Array.prototype.slice
                          .call(
                            document.getElementsByClassName("main-select-bi")
                          )
                          .map((m) => (m.selectedIndex = 0));
                      }}
                    >
                      <option value="">{report.name}</option>
                      {report.items.map((item: any, index: Number) => (
                        <option
                          value={reportUrl(item.id)}
                          key={`item-${index}`}
                        >
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            </div> */}
              <h4 className="int-status-title">{title}</h4>
            </div>
          </div>
        </div>
        <div className="ds-box">
          <Iframe
            url={url}
            width="100%"
            height="730vh"
            id="myId"
            className="myClassname"
            display="block"
            position="relative"
            frameBorder={0}
          />
        </div>
      </div>
    </div>
  );
}
