import { useMemo } from "react";
import { useQuery } from "react-query";
import { queryClient } from "../components/App";
import { getTicketNotifications } from "../actions/notifications";

function useTicketNotifications() {
  const key = useMemo(() => `/ticket-notifications`, []);
  const data = useQuery(key, async () => await getTicketNotifications(), {
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });

  return useMemo(
    () => ({
      ...data,
      invalidate: () => queryClient.invalidateQueries(key),
      key,
    }),
    [data, key]
  );
}

export default useTicketNotifications;
