import React from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import { useBatchProducts, useBatch } from "../../../../actions/v2/product";
import ConditionalLink from "../../../utils/conditional-link";
import { useIsAdmin } from "../../../../hooks/useUser";
import { useParamsState } from "hooks";
import { PaginatedRequest } from "types/pagination";
import Pagination from "../../../ui/pagination/Pagination";

type TParams = { batchId: string; };

const ProductBatchLog: React.FC<RouteComponentProps<TParams>> = (props) => {
	const { batchId } = props.match.params;

	const [currentConfig, setCurrentConfig] = useParamsState<PaginatedRequest>({
		perPage: 10,
		page: 1,
		search: "",
	});

	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const { isLoading: loadingBatch, data: batchData } = useBatch(batchId);
	const { isLoading, data } = useBatchProducts(batchId, debouncedConfig);
	const isAdmin = useIsAdmin();

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont pb4">
					<NavLink
						to="/catalog/unregistered"
						className="single-billing-nav pointer"
					>
						<img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
						<div
							style={{ color: "#55718a" }}
							className="table-subtext bold pt1 pl2"
						>
							back to Catalog
						</div>
					</NavLink>
					<div
						style={{ paddingLeft: "0", paddingRight: "0" }}
						className="table-title-cont "
					>
						{loadingBatch ? (
							<CircularPreloader />
						) : (
							<div className="mplc-filter-cont mt1">
								<div className="gestao-select-cont justify-between">
									<div className="in-column align-start">
										<span className="pim-language-text pl4">
											Unregistered Product Batch {batchId.slice(-5)}
										</span>
										<div className="in-row align-center pl4 pt2">
											<span
												style={{ color: "#F1692E" }}
												className="email-text bold"
											>
												{batchData?.registering} registering
											</span>
											&nbsp; | &nbsp;
											<span
												style={{ color: "#4CAB2B" }}
												className="email-text bold"
											>
												{batchData?.complete} complete
											</span>
										</div>
									</div>
									{/* <button className="main-select-bi no-shadow ml2">
										<img src="/icons/bi-filter.svg" alt="" />
										FILTER <span className="orange-text bold">(2)</span>
									</button> */}
								</div>
							</div>
						)}
					</div>
					<div className="table-main-title-cont">
						<div className="table-main-title-search-value-cont width100 justify-between">
							<div className="search-box">
								<div className="search_form">
									<div className="table-search-box">
										<form
											className="table-search-form"
											onSubmit={(e) => e.preventDefault()}
										>
											<input
												className="table-search-input"
												placeholder="Search product by ean"
												onChange={(e) =>
													setCurrentConfig({
														search: e.target.value,
													})
												}
												value={currentConfig.search}
											/>
											<img
												className="table-search-image"
												src="/icons/search.svg"
												width="25px;"
												alt=""
											/>
										</form>
									</div>
								</div>
								<div className="unregistered-table-value">
									<span className="table-main-title-text">
										{isLoading ? (
											<CircularPreloader />
										) : (
											<span className="table-main-title-text">
												{data?.total} products
											</span>
										)}
									</span>
								</div>
							</div>
							<div className="unregistered-table-pagination">
								<Pagination lastPage={data?.lastPage || 0} setConfig={setCurrentConfig} config={currentConfig} />
							</div>
						</div>
					</div>
					<div style={{ height: "max-content" }} className="table-overflow">
						<table
							style={{
								position: "sticky",
								zIndex: 101,
								top: "2px",
								width: "100%",
							}}
						>
							<tbody>
								<tr
									style={{ marginTop: "-5px", width: "100%" }}
									className="table-results-title"
								>
									<th className="pbl-image"></th>
									<th className="pbl-ean">EAN</th>
									<th className="pbl-small">SKU</th>
									<th className="pbl-title">Title</th>
									<th className="pbl-small">Preview</th>
									<th className="pbl-small">Registration Status</th>
								</tr>
							</tbody>
						</table>
						{!isLoading && (
							<table className="table-results">
								<tbody>
									{data?.data?.map((item) => (
										<tr
											className="table-result-box billing-text"
											key={item._id}
										>
											<td className="pbl-image">
												{item.image &&
													<ConditionalLink condition={isAdmin} to={`/productInfo/${item.sku}`}>
														<img className="ml2" width={50} height={50} src={item.image} alt="" />
													</ConditionalLink>
												}
											</td>
											<td className="pbl-ean">{item.ean}</td>
											<td className="pbl-small">{item.sku || "no sku yet"}</td>
											<td className="pbl-title">
												{item.name || "no title yet"}
											</td>

											<td
												style={{ color: "#2B5EC5" }}
												className={`pbl-small ${item.sku && "pointer"}`}
											>
												{item.sku && (
													<a
														className="underline in-row align-center gestao-title-text"
														target={"_blank"}
														style={{ color: "#2B5EC5" }}
														rel="noreferrer"
														href={`${process.env.REACT_APP_PREVIEW_URL}/pt/${item.sku}`}
													>
														<img
															className="pr2"
															src="/icons/preview-link-blue.svg"
															alt=""
														/>
														Preview
													</a>
												)}
											</td>
											<td
												style={{
													color: item.isCreated ? "#4CAB2B" : "#F1692E",
												}}
												className="pbl-small bold"
											>
												{item.isCreated ? "Complete" : "Registering"}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ProductBatchLog);
