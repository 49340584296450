import React from 'react';
import { Doughnut } from "react-chartjs-2";

interface Props {
    competitive: number;
    nonCompetitive: number;
}

const OfferImportPie: React.FC<Props> = ({ competitive, nonCompetitive }) => {
    const labels = ["Comptetitive", "Non-competitive"];
    const data = [competitive, nonCompetitive];

    return (
        <div>
            <Doughnut
                // @ts-ignore
                // type="doughnut"
                data={{
                    labels,
                    datasets: [
                        {
                            data,
                            backgroundColor: ["#ef7731", "#ccc",],
                            spacing: 1, // add spacing between parts
                        },
                    ],
                }}
                height={150}
                width={200}
                options={{
                    maintainAspectRatio: false,
                    indexAxis: "y",
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    // @ts-ignore
                    borderRadius: 4, // add rounded corners
                }}
            />
        </div>
    );
};

export default OfferImportPie;
