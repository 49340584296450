import React from "react";
import SingleOrderTitle from "./SingleOrderTitle";
import "./singleorder.css";
import SingleOrderAdresses from "./SingleOrderAdresses";
import SingleOrderDetail from "./SingleOrderDetail";
import SingleOrderProducts from "./SingleOrderProducts";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { useSingleOrder } from "../../../../../hooks/useOrders";
import Preloader from "../../../../ui/preloader/Preloader";

type TParams = {
	id: string;
};

const SingleOrder: React.FC<RouteComponentProps<TParams>> = (props) => {
	const order = useSingleOrder(props.match.params.id);

	return (
		<div className="main-body">
			<div className="main-container">
				{!order.isLoading ? (
					<div className="table-main-cont">
						<NavLink to="/orders">
							<div
								className="single-billing-nav pointer"
								style={{ paddingTop: "20px" }}
							>
								<img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
								<div className="table-subtext underline pl2">
									back to Orders
								</div>
							</div>
						</NavLink>
						<SingleOrderTitle
							order={order.data.order}
							actions={order.data.actions}
						/>
						<div className="single-order-cont">
							<SingleOrderDetail order={order.data.order} />
							<SingleOrderProducts order={order.data.order} />
							<SingleOrderAdresses order={order.data.order} />
						</div>
					</div>
				) : (
					<div className="acompan-preloader">
						<Preloader />
					</div>
				)}
			</div>
		</div>
	);
};

export default withRouter(SingleOrder);
