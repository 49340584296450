import React, { useCallback, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { ProductImportReport } from "types/seller";
import { massUploadInvoices } from "../../../../actions/order";

interface Props {
  onModalClose: any;
  isOpened: boolean;
  report?: ProductImportReport;
  setModal: any;
}

const InvoiceBulkUploadModal: React.FC<Props> = ({
  onModalClose,
  isOpened,
  report,
  setModal
}) => {
  const [mode, setMode] = useState<string>("cost");

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: [".zip"],
    onDropRejected: () => {
      toast.error('File type rejected. Only ".zip" files are allowed.');
    },
  });

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      try {
        const data = await massUploadInvoices(acceptedFiles[0]);
        if (data.success) {
          return toast.success(data.report.messages[0], { autoClose: 5000 });
        } 
      } catch (error) {}
      toast.error(`Something went wrong. Please, check you file once again`, { autoClose: false });
    },
    [acceptedFiles, report?._id, mode]
  );

  return (
    <Modal onModalClose={onModalClose} isOpened={isOpened}>
      <div className="table-modal-form-cont in-column justify-center p4" style={{width:430}}>
        <div className="table-modal-title-box m0">
          <div className="import-title">Invoice Bulk Upload</div>
        </div>
        <div className="table-modal-border"></div>
        <p className="text-start">
          Create a Zip file with your invoices.
          Each invoice must be named after the Order ID.<br/>
          <em>
            <small>(For Order ID 1234, invoice name will be <b>1234.pdf</b>)</small>
          </em>
        </p>

        <span className="mt2 in-row width100 align-center">
          <b className="mr1">Add the invoices file</b>
          (.zip file)
        </span>
        <div
          className="width100 pointer import-reviews-dropzone"
          style={{ width: "400px", height: "150px", background: "" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <h4>
              <img src="/icons/upload-spreadsheet.svg" alt="" />
              Drop the file here
            </h4>
          ) : (
            <h2 className="pc-modal-dropzone-text">
              {acceptedFiles.length > 0
                ? `Click 'Import' to finish upload ${acceptedFiles[0].name}`
                : <>
                  <img src='/icons/upload-spreadsheet.svg' alt='' />
                  Drop the file here
                </>
              }
            </h2>
          )}
        </div>
        <div className="table-modal-border"></div>
        <div style={{ marginBottom: "0" }} className="table-modal-form-button-box width100">
          <button
            className="sel-modal-form-button-cancel"
            onClick={onModalClose}
          >
            Cancel
          </button>
          <button onClick={(e: React.FormEvent) => {
            setModal(false);
            onSubmit(e);
          }}
            disabled={!acceptedFiles.length}
            className="table-modal-form-button initial" type="submit">
            Import
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default InvoiceBulkUploadModal;
