"use strict";
exports.__esModule = true;
exports.Type = void 0;
var Type;
(function (Type) {
    Type["ORDERS"] = "ORDERS";
    Type["PRICE_AND_STOCK_UPDATES"] = "PRICE_AND_STOCK_UPDATES";
    Type["INTEGRATED_PRODUCT"] = "INTEGRATED_PRODUCT";
    Type["IMPORTED_PRODUCT"] = "IMPORTED_PRODUCT";
    Type["BUYBOX"] = "BUYBOX";
    Type["RANKING_ZEOOS"] = "RANKING_ZEOOS";
    Type["DEFAULT_NOTIFICATION"] = "DEFAULT_NOTIFICATION";
})(Type = exports.Type || (exports.Type = {}));
