import React, { memo, useCallback, useState, useMemo } from "react";
import DiscountInfoComponent from "./DiscountInfoComponent";
import { PricingEvent, EventType, DiscountType } from "types";
import { useMutation, useQuery } from "react-query";
import { getSanitizedMarketplaces } from "../../../../actions/marketplace";
import SelectComponent from "./SelectComponent";
import Preloader from "../../../ui/preloader/Preloader";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";
import * as PricingController from "../../../../actions/pricingEvent";
import * as XLSX from "xlsx";
import queryString from "query-string";
import { getPaginatedInventoryProducts } from "../../../../actions/marketplace";
import {
	IMarketplace,
	IPaginatedRequest,
	IProductGestao,
} from "../../../../interfaces";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import Paginator from "../../../ui/pagination";

interface Props {
	rqKeyEvents: string;
	setCustomModal: (customModal: React.SetStateAction<boolean>) => void;
}

interface IProductsFromExcel {
	sku: number;
	price: number;
}

const CustomPricing: React.FC<Props> = ({ rqKeyEvents, setCustomModal }) => {
	const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
	const [platformValue, setPlatformValue] = useState<string>(
		"All platform accounts"
	);
	const [discountInfoData, setDiscountInfoData] = useState<any>({
		isActive: false,
		name: "",
		description: "",
		discountType: DiscountType.PERCENTAGE,
		discountAmount: "",
		eventResult: {
			goal: "",
		},
		eventPeriod: {
			start: new Date(),
			end: new Date(),
		},
	});
	const [productsFromExcel, setProductsFromExcel] = useState<any>([]);
	const [fileName, setFileName] = useState<string>("");

	const { isLoading: loadPlatforms, data: platforms } = useQuery(
		"sanitizedMarkeplaces",
		() => getSanitizedMarketplaces()
	);

	const refetchEventsConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyEvents);
			toast.success(`The event is successfully "created"!`);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const createMutation = useMutation(
		(data: PricingEvent) => PricingController.createEvent(data),
		refetchEventsConfig
	);

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			const data = {
				...discountInfoData,
				type: EventType.CUSTOM,
				platforms: selectedPlatforms.length
					? selectedPlatforms
					: platforms.map((platform: IMarketplace) => platform._id),
				products: productsFromExcel,
			};
			await createMutation.mutateAsync(data);

			setCustomModal(false);
		},
		[
			createMutation,
			discountInfoData,
			selectedPlatforms,
			productsFromExcel,
			platforms,
			setCustomModal,
		]
	);

	const readUploadFile = useCallback(async (e: React.ChangeEvent<any>) => {
		e.preventDefault();

		const file = e.target.files[0];
		setFileName(file.name);

		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data);
		const worksheet = workbook.Sheets[workbook.SheetNames[0]];
		const jsonData = XLSX.utils.sheet_to_json(worksheet);

		setProductsFromExcel(jsonData);
	}, []);

	if (loadPlatforms) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<>
			<div
				style={{ marginTop: "-60px", zIndex: "1200" }}
				className="product-header-cont"
			>
				<div className="in-row align-cener height100">
					<div
						className="product-exit-btn"
						onClick={() => setCustomModal(false)}
					>
						<img className="mr2" src="/icons/product-exit.svg" alt="" />
						Discard
					</div>
					<div className="in-row align-center ml2">
						<span className="product-header-text ml1">New Custom Pricing</span>
					</div>
				</div>
				<button className="product-header-button">SAVE</button>
			</div>
			<div
				style={{
					background: "#E8EDF2",
					height: "max-content",
					minHeight: "93vh",
				}}
				className="product-cont m0 pt4"
			>
				<div className="new-pricing-column">
					<DiscountInfoComponent
						eventType={EventType.CUSTOM}
						discountInfoData={discountInfoData}
						setDiscountInfoData={setDiscountInfoData}
					/>
					<SelectComponent
						type={"platforms"}
						specificArray={platforms || []}
						selectedItems={selectedPlatforms}
						setSelectedItems={setSelectedPlatforms}
						title={"Selected platform accounts"}
						setValue={setPlatformValue}
						controlLabels={[
							"All platform accounts",
							"Specific platform accounts",
						]}
						controlValue={platformValue}
					/>
					<div className="new-pricing-cont">
						<div className="in-row align-center justify-between width100 mb4">
							<span className="new-pricing-title-text">Selected products</span>
						</div>
						<div className="new-pricing-box">
							<div className="new-pricing-choose-file">
								<img
									className="mr2"
									src="/icons/pricing-choose-file.svg"
									alt=""
								/>
								<label htmlFor="upload">CHOOSE FILE</label>
								<input
									type="file"
									name="file"
									id="upload"
									onChange={readUploadFile}
									hidden
								/>
							</div>
							<div
								style={{ color: "#ACACAC" }}
								className="new-pricing-text ml4"
							>
								{fileName === "" ? "No file chosen" : fileName}
							</div>
						</div>
						{productsFromExcel.length ? (
							<ProducstFromExcel
								productsFromExcel={productsFromExcel}
								platforms={platforms}
								selectedPlatforms={selectedPlatforms}
							/>
						) : (
							<></>
						)}
					</div>
					<div className="in-row align-center justify-between mt4 width100">
						<button className="new-pricing-discard new-pricing-text">
							discard
						</button>
						<button
							className="new-pricing-save new-pricing-title-text"
							onClick={onSubmit}
						>
							save
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

interface IExcelProducts {
	productsFromExcel: IProductsFromExcel[];
	platforms: IMarketplace[];
	selectedPlatforms: string[];
}

const ProducstFromExcel: React.FC<IExcelProducts> = ({
	productsFromExcel,
	platforms,
	selectedPlatforms,
}) => {
	const [productsConfig, setProductsConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
		skus: productsFromExcel.map((p: IProductsFromExcel) => String(p.sku)),
	});

	const rqKeyProducts = useMemo(() => {
		return `/inventory/products?${queryString.stringify(productsConfig)}`;
	}, [productsConfig]);

	const { isLoading: loadProducts, data: products } = useQuery(
		rqKeyProducts,
		async () => await getPaginatedInventoryProducts(productsConfig),
		{ enabled: !!productsFromExcel.length, refetchOnWindowFocus: false }
	);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setProductsConfig((c) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setProductsConfig]
	);

	return loadProducts ? (
		<CircularPreloader />
	) : (
		<>
			<div
				style={{ borderBottom: "1px solid #e1e1e1" }}
				className="in-row align-center justify-between pb1 width100"
			>
				<div className="new-pricing-text mt2">
					<b>{productsFromExcel.length} products added</b>
				</div>
				<div className="marketplace-pagination">
					{products!.total > productsConfig.perPage && (
						<Paginator
							data={!loadProducts ? products : {}}
							refetch={onChangePage as any}
						/>
					)}
				</div>
			</div>
			<div className="border-bottom width100"></div>
			{products?.data.length ? (
				<div style={{ height: "max-content" }} className="table-overflow">
					<table style={{ position: "sticky", zIndex: 101, top: "2px" }}>
						<thead>
							<tr
								style={{ marginTop: "-5px", width: "100%" }}
								className="table-results-title"
							>
								<th className="pricing-event-sticky-block admin-titlebg title-border">
									<th className="width100">
										<b>
											Displaying 1 to{" "}
											{products?.data.length < 50
												? String(products?.data.length)
												: "50"}{" "}
											products out of {products?.data.length} added.
										</b>
									</th>
								</th>
								{selectedPlatforms.map((platformId: string, index: number) => (
									<React.Fragment key={index}>
										<th className="table-border-right"></th>
										<th className="gestao-vinuus">
											<div>
												{
													platforms?.find(
														(platform: IMarketplace) =>
															platformId === platform._id
													)?.zeoosName
												}
											</div>
											<div className="in-row width100 height100 gestao-sort">
												<div className="gestao-cost justify-center">
													Old Price
												</div>
												<div className="gestao-stock justify-center">
													New Price
												</div>
											</div>
										</th>
									</React.Fragment>
								))}
							</tr>
						</thead>
						<tbody></tbody>
					</table>
					<table className="table-results p0">
						<thead></thead>
						<tbody>
							{products?.data.map((xlsProduct: IProductGestao) => (
								<tr
									className="table-result-box gestao-height-box"
									key={xlsProduct._id}
								>
									<td className="pricing-event-sticky-block title-border">
										<div className="in-row align-center">
											<img src={xlsProduct?.image} width="50px" alt="" />
											<span className="new-pricing-text ml2">
												{xlsProduct?.name}
											</span>
										</div>
									</td>
									{selectedPlatforms.map((platformId: string) => {
										const platformsBySku = products?.data?.find(
											(x: IProductGestao) => xlsProduct.sku === x.sku
										)?.marketplaces;

										const zeoosName = platforms?.find(
											(platform: IMarketplace) => platformId === platform._id
										)?.zeoosName;

										const data = zeoosName
											? platformsBySku?.[zeoosName]
											: ({} as any);

										return (
											<React.Fragment key={platformId}>
												<td className="table-border-right"></td>
												<td className="gestao-vinuus">
													<div className="in-row width100 align-center justify-center">
														<div
															style={{ color: "#ACACAC" }}
															className="new-pricing-text"
														>
															{data?.price || 0}
														</div>
														<img
															className="ml2 mr2"
															src="/icons/pricing-arrow-right.svg"
															alt=""
														/>
														<div
															style={{
																color: "#299C00",
																fontWeight: "700",
															}}
															className="new-pricing-text"
														>
															{
																productsFromExcel.find(
																	(p: IProductsFromExcel) =>
																		xlsProduct.sku === String(p.sku)
																)?.price
															}{" "}
															€
														</div>
													</div>
												</td>
											</React.Fragment>
										);
									})}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default memo(CustomPricing);
