import React, { useContext, useState } from "react";
import {
	createCountry,
	updateCountry,
} from "../../../actions/countryManagement";
import "./country-management.css";
import { Modal } from "../../ui/modal/Modal";
import { CountriesTable } from "./CountriesTable";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { CountriesContext } from "../../context";
import SettingsNav from "../../ui/settings-nav/SettingsNav";

export interface ICountryM {
	_id: string;
	name: string;
	language: string;
	isLang: boolean;
	langCode: string;
	iconUrl: string;
	isoCode?: string;
}

export const CountryManagement = () => {
	const initialCountries = useContext(CountriesContext) as ICountryM[];
	const [modal, setModal] = useState<boolean>(false);
	const [countries, setCountries] = useState<ICountryM[]>(initialCountries);
	const [country, setCountry] = useState<ICountryM | {} | undefined>();
	const [action, setAction] = useState<string>("");

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont p0">
					<SettingsNav />
					<div className="table-title-cont">
						<div className="mplc-filter-cont justify-end">
							<div className="mplc-filter justify-end">
								<div
									className="tax-title-button pointer mr2"
									onClick={() => {
										setModal(true);
										setCountry({});
										setAction("create");
									}}
								>
									<img src="/icons/pim-plus.svg" alt="" />
									<div className="pim-avaliacoes-title-button-text">
										New country
									</div>
								</div>
							</div>
						</div>
					</div>
					<CountriesTable
						countries={countries}
						setCountries={setCountries}
						setModal={setModal}
						setCountry={setCountry}
						setAction={setAction}
					/>
				</div>
			</div>
			{modal && (
				<CountryForm
					onModalClose={onModalClose}
					modal={modal}
					setModal={setModal}
					action={action}
					country={country}
					setCountries={setCountries}
				/>
			)}
		</div>
	);
};

interface ICountryForm {
	onModalClose: (e: React.MouseEvent) => void;
	modal: boolean;
	setModal: (modal: boolean) => void;
	action: string;
	country: any;
	setCountries: (country: any) => void;
}

export const CountryForm: React.FC<ICountryForm> = ({
	onModalClose,
	modal,
	setModal,
	action,
	country,
	setCountries,
}) => {
	const [name, setName] = useState<string>(country?.name || "");
	const [language, setLanguage] = useState<string>(country?.language || "");
	const [langCode, setLangCode] = useState<string>(country?.langCode || "");

	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({
			accept: [".svg", "image/svg+xml"],
			onDropRejected: () => {
				toast.error('File type rejected. Only ".svg" files are allowed.');
			},
		});

	const handleApplyCountry = async (e: React.MouseEvent) => {
		e.preventDefault();
		try {
			if (action === "create") {
				const body = {
					name,
					language,
					langCode,
					file: acceptedFiles[0],
				};
				const country = await createCountry(body);
				setCountries((prevState: ICountryM[]) => [...prevState, country]);
				toast.success("Country created!");
			} else {
				const updatedFile =
					acceptedFiles.length > 0 ? acceptedFiles[0] : country.iconUrl;
				const updatedBody = {
					_id: country._id,
					file: updatedFile,
					name,
					language,
					isLang: country.isLang,
					langCode,
				};

				const updatedCountry = await updateCountry(updatedBody);

				setCountries((prevState: ICountryM[]) =>
					prevState.map((x: ICountryM) => {
						if (x._id === country._id) {
							x = updatedCountry;
						}

						return x;
					})
				);
				toast.success("Country updated!");
			}
			setModal(false);
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="cm-modal-cont">
				<div className="table-modal-title-box m0">
					<div className="cm-modal-title-text">
						{country.name ? "Edit" : "Create"} country
					</div>
					<div className="in-row align-center pointer">
						<img
							src="/icons/admin-trash-can.svg"
							alt=""
							className="mr1"
							width="20px"
						/>
						<div className="mapping-modal-text p0">delete</div>
					</div>
				</div>
				<form className="table-modal-form-cont">
					<div className="table-modal-border"></div>
					<div className="cm-modal-cat">
						<div className="cm-modal-text">Name:</div>
						<input
							className="cm-modal-input"
							placeholder=""
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div className="in-row align-center justify-between mt4">
						<div className="cm-modal-cat">
							<div className="cm-modal-text">Language:</div>
							<input
								className="cm-modal-input"
								placeholder=""
								value={language}
								onChange={(e) => setLanguage(e.target.value)}
							/>
						</div>
						<div className="cm-modal-cat">
							<div className="cm-modal-text">Language code:</div>
							<input
								className="cm-modal-input"
								placeholder=""
								value={langCode}
								onChange={(e) => setLangCode(e.target.value)}
							/>
						</div>
					</div>
					<div className="cm-modal-cat mt4">
						<div className="cm-modal-text">Icon:</div>
						<div
							className="width100 cm-import-reviews-dropzone"
							style={{ height: "100px" }}
							{...getRootProps()}
						>
							<input {...getInputProps()} />
							{isDragActive ? (
								<h4>Drop the files here or click me to select ...</h4>
							) : (
								<h2 className="cm-import-reviews-dropzone-text">
									{acceptedFiles.length > 0
										? `Click 'Apply' to finish upload ${acceptedFiles[0].name}`
										: "Drop the file here"}
								</h2>
							)}
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box width100">
						<button
							className="table-modal-form-button-cancel"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button
							className="table-modal-form-button"
							onClick={handleApplyCountry}
						>
							Create
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};
