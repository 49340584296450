import React from "react";
import "./reviews.css";
import * as _ from "lodash";
import CreateReview from "./create-review/CreateReview";
import FilterSelect from "../../ui/select/FilterSelect";
import { IMarketplace, IPaginatedRequest } from "../../../interfaces";
import { useQuery } from "react-query";
import { getSanitizedMarketplaces } from "../../../actions/marketplace";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import useCountries from "../../../hooks/useCountries";
import { ICountryM } from "../country-management/CountryManagement";

interface Props {
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  currentConfig: IPaginatedRequest;
  rqKeyReviews: string;
}

export const ReviewsFilter: React.FC<Props> = ({
  currentConfig,
  setCurrentConfig,
  rqKeyReviews,
}) => {
  const countries = useCountries() as ICountryM[];

  const { isLoading: loadSanitizedMarkeplaces, data: sanitizedMarkeplaces } =
    useQuery("sanitizedMarkeplaces", () => getSanitizedMarketplaces());

  return (
    <div className="table-title-cont">
      <div className="mplc-filter-cont width100">
        <div className="mplc-filter-cont">
          <div className="mplc-filter">
            <div className="filter-text">Filter by:</div>
            <FilterSelect
              onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentConfig((conf: IPaginatedRequest) => ({
                  ...conf,
                  ratingStar: e.target.value,
                }))
              }
              value={currentConfig?.ratingStar}
              data={["All stars", "5", "4", "3", "2", "1"]}
            />
            {loadSanitizedMarkeplaces ? (
              <CircularPreloader />
            ) : (
              <FilterSelect
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCurrentConfig((conf: IPaginatedRequest) => ({
                    ...conf,
                    origin: e.target.value,
                  }))
                }
                value={currentConfig?.origin}
                data={[
                  "All origins",
                  ..._.uniq(
                    _.map(
                      sanitizedMarkeplaces,
                      (item: IMarketplace) => item.zeoosName
                    )
                  ),
                ]}
              />
            )}
            <FilterSelect
              onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentConfig((conf: IPaginatedRequest) => ({
                  ...conf,
                  country: e.target.value,
                }))
              }
              value={currentConfig?.country}
              data={[
                "All countries",
                ..._.uniq(_.map(countries, (item: ICountryM) => item.name)),
              ]}
            />
          </div>
          <div className="create-button-box">
            <CreateReview rqKeyReviews={rqKeyReviews} />
          </div>
        </div>
      </div>
    </div>
  );
};
