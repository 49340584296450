import React from 'react';
import { MenuItem, TextField } from "@mui/material";

export type Option = { label: string; value: string; };

type SharedMultiSelectProps = {
    multiple?: boolean;
    options: Option[];
    resetFilter?: (newValue: any, oldValue: any) => void;
    helperText?: string;
};

type _MultiSelectProps = {
    value: string[];
    setValue: React.Dispatch<React.SetStateAction<string[]>>;
} & SharedMultiSelectProps;

type _SingleSelectProps = {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
} & SharedMultiSelectProps;

type Props = _SingleSelectProps | _MultiSelectProps;
export type MultiSelectProps = Props;

const MultiSelect: React.FC<Props> = ({
    value,
    setValue,
    options,
    resetFilter,
    helperText,
    multiple = true,
}) => {
    return (
        <TextField
            label={helperText}
            select
            className="filter-select"
            sx={{ mb: 1 }}
            SelectProps={{
                multiple,
                value,
                onChange: (e: any) => {
                    e.preventDefault();
                    resetFilter?.(e.target.value, value);
                    setValue(e.target.value);
                },
            }}
        >
            {options.map((option, index) => (
                <MenuItem value={option.value} className="acompan-option" key={index}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};


export default MultiSelect;
