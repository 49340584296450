import React, { useState } from "react";
import { Modal } from "../../../../../ui/modal/Modal";
// @ts-ignore
import FilterSelect from "../../../../../ui/select/FilterSelect";
// import Button from '@mui/material/Button';
// import ButtonGroup from '@mui/material/ButtonGroup';
// import moment from "moment";

//TODO: read reasons from backend
export const reasons = {
  OUT_OF_STOCK: "Out-of-stock",
  PRICING_ISSUE: "Pricing issue",
  ITEM_RETURNED: "Item returned",
  OTHER: "Other reason"
} as any;

export const rejectReasons = Object.keys(reasons).map((r:any) => ({ value: r, label: reasons[r]}));

const SingleOrderRefundModal: React.FC<any> = ({
	order,
	onModalClose,
	onModalSubmit,
}) => {
	const [reason, setReason] = useState(rejectReasons[0].value);

	const onSubmit = async (e: any) => {
    e.preventDefault();
		e.target.disabled = true;
		await onModalSubmit(order.order_id, reason);
		e.target.disabled = false;
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={true}>
			<>
				<form className="table-modal-cont" onSubmit={onSubmit}>
					<div className="table-modal-title-box">
						<div>
							<div className="table-modal-title-pricing">Full Order Refund</div>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="single-order-text width100">
						<div className="so-details-box">
							<b className="mr1">Reason:</b> 
              <FilterSelect
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setReason(e.target.value)
                }
                value={reason}
                options={rejectReasons}
							/>
						</div>
					</div>
					<div className="table-modal-border"></div>
	
					<div className={`table-modal-form-button-box width100`}>          
            <button onClick={onModalClose} className="table-modal-form-button-cancel">
              Cancel
            </button>
            <button
              className="table-modal-form-button"
              type="submit"
              // onClick={async (e: any) => {
              //   e.target.disabled = true;
              //   await onModalSubmit(order._id, reason);
              //   e.target.disabled = false;
              // }}
            >
              confirm
            </button>						
					</div>

				</form>
			</>
		</Modal>
	);
};

export default SingleOrderRefundModal;
