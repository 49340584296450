import React, { useCallback } from "react";
import "./file-management.css";
import { IFileM } from "./FileManagement";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import { TooltipText } from "../tables/gestao/Tooltip";
import Paginator from "../../ui/pagination";
import { IPaginatedRequest, IPaginatedResponse } from "../../../interfaces";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";

interface Props {
  sources: IPaginatedResponse<IFileM[]>;
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  currentConfig: IPaginatedRequest;
  loadSources: boolean;
}

export const FilesTable: React.FC<Props> = ({
  sources,
  setCurrentConfig,
  currentConfig,
  loadSources,
}) => {
  const onChangePage = useCallback(
    async (config: IPaginatedRequest) => {
      setCurrentConfig((c: IPaginatedRequest) => {
        return {
          ...c,
          ...config,
        };
      });
    },
    [setCurrentConfig]
  );

  const createUrl = (path: string) => {
    return `https://vinuus-portal.s3.eu-west-3.amazonaws.com/${path}`;
  };

  const copyToClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(`Copied!`);
    } catch (err) {
      console.error(err);
      toast.error("Failed to copy!");
    }
  };

  const addIndexNumber = (arr: IFileM[]) => {
    arr?.forEach(
      (sourse: IFileM, index: number) =>
        (sourse.index = sources.page * sources.perPage + index + 1)
    );
    return arr;
  };

  return (
    <div className="table-main-cont">
      <div style={{padding: "0 40px"}} className="width100">
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search file"
                    onChange={(e) =>
                      setCurrentConfig((conf: IPaginatedRequest) => ({
                        ...conf,
                        search: e.target.value,
                      }))
                    }
                    value={currentConfig.search}
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-main-title-text pl2">
              {loadSources ? (
                <CircularPreloader />
              ) : (
                <span className="table-main-title-text">
                  {sources?.total} files
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="countries-pagination">
          <Paginator
            data={!loadSources ? sources : {}}
            refetch={onChangePage as any}
          />
        </div>
      </div>
      <div  style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
        >
          <tr
            style={{ marginTop: "-5px", width: "100%" }}
            className="table-results-title"
          >
            <th className="fm-num">#</th>
            <th className="table-border-right"></th>
            <th className="fm-source">Source</th>
            <th className="table-border-right"></th>
            <th className="fm-reg">Purpose</th>
            <th className="table-border-right"></th>
            <th className="fm-reg">Target</th>
            <th className="table-border-right"></th>
            <th className="fm-date">Date</th>
          </tr>
        </table>
        <table className="table-results">
          <tbody>
            {addIndexNumber(sources?.data)?.map(
              (source: IFileM, index: number) => (
                <React.Fragment key={index}>
                  <tr className="table-result-box freight-body">
                    <td className="fm-num">{source?.index}</td>
                    <td className="table-border-right"></td>
                    <td className="fm-source">
                      <a
                        href={createUrl(source.source)}
                        target="_blank"
                        rel="noreferrer"
                        className="fm-sourse-text pointer"
                      >
                        <TooltipText
                          text={createUrl(source.source)}
                          items={100}
                          separator={""}
                        />
                      </a>
                      <div className="fm-sourse-copy-icon">
                        <Tooltip
                          title="Copy link"
                          placement="right"
                          className="fm-sourse-copy pointer width100"
                        >
                          <img
                            src={"/icons/copy-link.svg"}
                            alt=""
                            width="10px"
                            height="20px"
                            onClick={() =>
                              copyToClipBoard(createUrl(source.source))
                            }
                          />
                        </Tooltip>
                      </div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="fm-reg">{source.purpose}</td>
                    <td className="table-border-right"></td>
                    <td className="fm-reg">{source.target}</td>
                    <td className="table-border-right"></td>
                    <td className="fm-date">
                      {new Date(source.created).toUTCString().slice(0, -7)}
                    </td>
                  </tr>
                </React.Fragment>
              )
            )}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  );
};
