import React, { useState } from "react";
import { syncOrders } from "../../../actions/marketplace";
import { IPaginatedRequest } from "../../../interfaces";
import "./singlemarketplace.css";
import CountryFlag from "../../ui/country-flag/CountryFlag";
import { NavLink, useHistory } from "react-router-dom";
import { getLogoSvg } from "../marketplaces/MarketplacesList";
import * as _ from "lodash";
import { toast } from "react-toastify";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { PlatformNav } from "../../ui/platform-nav";
import { ImportRates } from "./ImportRates";
import { downloadPlatformRates } from "../../../actions/marketplace";

interface Props {
  vendors: any;
  marketplace: any;
  setOpenEdit: () => void;
  setCurrentConfig?: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  currentConfig?: IPaginatedRequest;
}

export const InfoSection: React.FC<Props> = ({
  marketplace,
  setOpenEdit,
}) => {
  const { location } = useHistory();
  const isCategoryRate = location.pathname.endsWith('/category-rate');

  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <>
      <NavLink to="/platforms" className="single-billing-nav pointer">
        <img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
        <div className="billing-text underline pl2">back to Platforms</div>
      </NavLink>

      <div className="table-title-cont p0 mb2">
        <div className="main-table-title-cont p0">
          <img
            src={
              marketplace.marketplaceImg ||
              `https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${getLogoSvg(
                marketplace
              )}.svg`
            }
            alt=""
            style={{
              width: "120px",
              maxHeight: "120px",
              borderRadius: "5px 0px 0px 5px",
              margin: "5px 20px 5px 10px",
            }}
          />
          <div className="single-main-title-cont">
            <div className="single-main-title-start">
              <div className="singlemarketplace-title-image">
                <CountryFlag
                  country={marketplace.country as string}
                  width="32px"
                  height="32px"
                />
                <b className="primary-color ft3 ml2">{marketplace.zeoosName}</b>
              </div>
              <div className="singlemarketplace-title-marketplace-box">
                <div>Sell at:&nbsp;</div>
                <div className="bold capital">
                  {marketplace.marketplaceName}
                </div>
              </div>
              {/* <div className="singlemarketplace-title-marketplace-box">
                <div>Platform Rate:&nbsp;</div>
                <div className="bold">
                  {marketplace?.rate
                    ? `${marketplace?.rate.from}% - ${marketplace?.rate.to}%`
                    : "-"}
                </div>
              </div> */}
            </div>
            <div className="single-main-title-end">
              <div
                style={{
                  alignItems: "end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <button
                  className="singlemarketplace-edit-button"
                  style={{ marginRight: "20px" }}

                >
                  edit
                </button> */}
                <Select
                  className="more-cont"
                  labelId="label"
                  id="select"
                  value="first"
                >
                  <MenuItem className="hide-important" value="first">
                    <img src="/icons/edit-sm.svg" alt="" />
                    More
                  </MenuItem>
                  <MenuItem onClick={() => setOpenEdit()}>
                    Edit
                  </MenuItem>
                  <MenuItem value="">
                    <NavLink
                      to={`/product-attributes/${marketplace.zeoosName}`}
                    >
                      Match Attributes
                    </NavLink>
                  </MenuItem>
                  {/* <MenuItem value="">
                    <NavLink to={`/status-order/${marketplace.zeoosName}`}>
                      Match Status Order
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    value=""
                    onClick={() => {
                      syncOrders(marketplace).catch(() =>
                        toast.error("Something went wrong")
                      );
                      toast.success(
                        "Orders are syncying... Check odoo shortly"
                      );
                    }}
                  >
                    Sync Orders
                  </MenuItem> */}
                  {/* <MenuItem value="">Platform Rates</MenuItem> */}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="main-table-title-cont"
        style={{ margin: "20px 0", padding: "14px 15px" }}
      >
        <PlatformNav marketplace={marketplace} />
        {isCategoryRate && (
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              className="platform-button"
              onClick={() => setModal(true)}
            >
              Import rates
            </button>
            <button
              className="platform-button"
              onClick={async () => {
                await downloadPlatformRates(marketplace.marketplaceIntegration, marketplace.zeoosName);
              }}
            >
              Export rates
            </button>
          </div>
        )}
      </div>
      {modal && (
        <ImportRates
          modal={modal}
          onModalClose={onModalClose}
          setModal={setModal}
          marketplace={marketplace}
        />
      )}
    </>
  );
};
