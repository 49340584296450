import React, { useState, useMemo } from "react";
import "./freight.css";
import { FreightTable } from "./FreightTable";
import { getFreights, getTimes, getWeights } from "../../../../actions/freight";
import Preloader from "../../../ui/preloader/Preloader";
import { FreightModal } from "./FreightModal";
import { useQuery } from "react-query";
import MappingsNav from "../../../ui/mappings-nav/MappingsNav";

export default function Freight() {
  const [modal, setModal] = useState<boolean>(false);

  const freights = useQuery("freights", () => getFreights());
  const weights = useQuery("weights", () => getWeights());
  const times = useQuery("times", () => getTimes());

  const isLoading = useMemo(
    () => freights.isLoading || weights.isLoading || times.isLoading,
    [freights.isLoading, weights.isLoading, times.isLoading]
  );

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <>
      <div className="main-body">
        <div className="main-container">
          {!isLoading ? (
            <>
              <div className="table-main-cont p0">
                <div className="table-value-search-cont">
                  <MappingsNav />
                  <div
                    onClick={() => {
                      setModal(true);
                    }}
                    className="tax-title-button pointer"
                  >
                    <img src="/icons/pim-plus.svg" alt="" />
                    <div className="pim-avaliacoes-title-button-text">
                      ADD freight mapping
                    </div>
                  </div>
                </div>
                <div className="mapping-title-border"></div>
                <div className="table-border-bottom"></div>
                <FreightTable />
              </div>
            </>
          ) : (
            <Preloader />
          )}
        </div>
      </div>
      {modal && (
        <FreightModal onModalClose={onModalClose} isOpened={modal} freight="" />
      )}
    </>
  );
}
