import React, { useState, useMemo } from "react";
import "./shipping.css";
import { ShippingTable } from "./ShippingTable";
import { getFreights, getTimes, getWeights } from "../../../../actions/freight";
import Preloader from "../../../ui/preloader/Preloader";
import { useQuery } from "react-query";
import FilterSelect from "../../../ui/select/FilterSelect";
import { Modal } from "../../../ui/modal/Modal";
import Filter from "../../../ui/filters/Filter";
import CreateEditPartner from "../partners/CreateEditPartner";

export default function ShippingCost() {
  const [modalOrigin, setModalOrigin] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState(false);
  const [filter, setFilter] = useState<boolean>(false);
  const [freight, setFreight] = useState("Bigbuy");
  const [country, setCountry] = useState("Portugal");

  const freights = useQuery("freights", () => getFreights());
  const weights = useQuery("weights", () => getWeights());
  const times = useQuery("times", () => getTimes());

  const isLoading = useMemo(
    () => freights.isLoading || weights.isLoading || times.isLoading,
    [freights.isLoading, weights.isLoading, times.isLoading]
  );

  const onModalOriginClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalOrigin(false);
  };

  return (
    <>
      <div className="main-body">
        <div className="main-container">
          {!isLoading ? (
            <>
              <div className="table-main-cont p0">
                <div style={{ padding: "0 40px", marginBottom: "20px" }}>
                  <div className="mapping-filter">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "40px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <p
                          style={{
                            color: "#ACACAC",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Partner:
                        </p>
                        <FilterSelect
                          data={["Bigbuy", "Standart freight"]}
                          value={freight}
                          onChangeHandler={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => setFreight(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <p
                          style={{
                            color: "#ACACAC",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Origin:
                        </p>
                        <FilterSelect
                          data={["Portugal", "Portugal1", "Portugal2"]}
                          value={country}
                          onChangeHandler={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => setCountry(e.target.value)}
                        />
                        <button
                          className="mapping-filter-button"
                          onClick={() => setModalOrigin(true)}
                        >
                          <img src="/icons/plus.svg" alt="plus icon" />
                          Add new origin
                        </button>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Filter
                        isViewFilter={filter}
                        setIsViewFilter={setFilter}
                      />
                      <div
                        onClick={() => setCreateModal(true)}
                        className="tax-title-button pointer mr2"
                      >
                        <img src="/icons/pim-plus.svg" alt="" />
                        <div className="pim-avaliacoes-title-button-text">
                          New shipping partner
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ShippingTable />
              </div>
            </>
          ) : (
            <Preloader />
          )}
        </div>
      </div>
      {modalOrigin && (
        <Modal isOpened={modalOrigin} onModalClose={onModalOriginClose}>
          <div
            className="origin-modal"
            style={{
              padding: "23px 30px",
              color: "#5A5A5A",
              textAlign: "left",
              width: "401px",
            }}
          >
            <p
              style={{
                color: "#5A5A5A",
                fontSize: "18px",
                fontWeight: "700",
                paddingBottom: "8px",
                borderBottom: "1px solid #E1E1E1",
                textAlign: "left",
              }}
            >
              New shipping partner
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "19px",
                gap: "3px",
              }}
            >
              <p style={{ fontSize: "14px", width: "78px" }}>Partner:</p>
              <input
                placeholder="New freight mapping"
                className="logistic-modal-input"
                style={{ width: "240px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                gap: "3px",
              }}
            >
              <p style={{ fontSize: "14px", width: "78px" }}>Origin:</p>
              <FilterSelect
                data={["Bigbuy", "Standart freight"]}
                value={freight}
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFreight(e.target.value)
                }
                style={{ width: "240px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                gap: "3px",
              }}
            >
              <p style={{ fontSize: "14px", width: "78px" }}>Freight rule:</p>
              <FilterSelect
                data={["Bigbuy", "Standart freight"]}
                value={freight}
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFreight(e.target.value)
                }
                style={{ width: "240px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "50px",
                paddingTop: "24px",
                borderTop: "1px solid #E1E1E1",
              }}
            >
              <button
                onClick={() => setModalOrigin(false)}
                style={{ padding: "11px 0px" }}
              >
                Cancel
              </button>
              <button
                style={{
                  fontSize: "16px",
                  background: "#55718A",
                  color: "white",
                }}
              >
                CREATE
              </button>
            </div>
          </div>
        </Modal>
      )}
      {createModal && (
        <CreateEditPartner modal={createModal} setModal={setCreateModal} />
      )}
    </>
  );
}
