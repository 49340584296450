import React, { useCallback, useEffect, useState } from "react";
import "./contrato.css";
import Checkbox from "@mui/material/Checkbox";
import LogoutWrap from "../../../utils/logout-wrap/LogoutWrap";
import useUser from "../../../../hooks/useUser";
import { toast } from "react-toastify";
import { readContract, uploadContractFile } from "../../../../actions/onboardingSeller";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { Status } from "../Onboarding";
import { Link } from "react-router-dom";

export default function Contrato() {
  const [checked, setChecked] = useState(false);
  const [openContract, setOpenContract] = useState(true);
  const [openDebit, setOpenDebit] = useState(false);
  const { invalidate } = useUser();
  const [file, setFile] = useState<File>();
  const [submitting, setIsSubmitting] = useState(false);

  const { user } = useUser();

  useEffect(() => {
    if (user.seller?.contractStatus !== Status.accepted) {
      setOpenContract(true);
      setOpenDebit(false);
    } else {
      setOpenContract(false);
      setOpenDebit(true);
    }
  }, [user]);

  const readUploadFile = useCallback(async (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  }, []);

  const saveContractFile = useCallback(async () => {
    if (!file) {
      toast.error("Please Select a file");
      return;
    }
    setIsSubmitting(true);
    try {
      await uploadContractFile(file);
      invalidate();
    } catch (error) {
      toast.error("Something went wrong...");
    }
    setIsSubmitting(false);
  }, [invalidate]);

  const handleSubmit = useCallback(async () => {
    try {
      await readContract();
      invalidate();
    } catch (error) {
      toast.error("Something went wrong...");
    }
  }, [invalidate]);

  const onSkipPress = () => {
    localStorage.setItem(`${user?.seller?._id}:sellerContractFileStatus`, "skip");
    window.location.reload();
  };

  return (
    <div className="onboadring-process-cont">
      <div
        style={{ margin: "auto", background: "#fff" }}
        className="in-row pt2 pb2 pl4 pr4 align-center justify-between"
      >
        <img
          style={{ zIndex: "10000" }}
          src="/icons/zeoos-logo.svg"
          width="150px;"
          alt=""
        />
        <LogoutWrap>
          <button
            style={{
              background: "transparent",
              boxShadow: "none",
              border: "none",
            }}
            className="logout-btn-onboarding"
          >
            <div>Log out</div>
          </button>
        </LogoutWrap>
      </div>
      <div className="contrato-bg pt4">
        <Accordion
          expanded={openContract}
          className="contrato-bg pt4"
          style={{ width: "70%" }}
        >
          <AccordionSummary
            aria-controls="contract"
            id="contract"
            expandIcon={<ArrowDropDown />}
            style={{ width: "100%" }}
          >
            <p>Contract</p>
          </AccordionSummary>
          <AccordionDetails>
            <div className="contrato-cont">
              <div className="contrato-box">
                <img
                  width="96px"
                  height="28px"
                  src="/icons/zeoos-logo.svg"
                  alt=""
                />
                <div className="policy-text p1">
                  COOKIE POLICY
                  <br />
                  <br />
                  BPMG - Drinks and Consulting, LDA uses cookies that allow
                  improving the performance and browsing experience of the
                  user.Any internet browser allows the user to accept, refuse,
                  or delete cookies, through the browser's own settings. If you
                  choose to disable cookies, some services on our website will
                  stop working, thus affecting your browsing experience.Cookies
                  do not contain personal identification information. By using
                  our website, you are authorizing the use of these cookies.
                  <br />
                  <br />
                  What are cookies?
                  <br />
                  <br />
                  Cookies are text files with relevant information that the
                  browser processes when a site is visited by a user.The
                  placement of cookies helps the site recognize the user's
                  device (tablet, desktop, mobile phone, etc.) every time they
                  access this site. Cookies retain only information related to
                  the user's preferences, without revealing personal
                  information.
                  <br />
                  <br />
                  Types of cookies:
                  <br />
                  <br />
                  There are several types of cookies with different
                  characteristics and functions. They are:· Essential cookies -
                  These are important to access specific areas of the site,
                  allowing correct navigation.· Functionality cookies -
                  Functionality cookies allow the user's preferences to be saved
                  when visiting the site. This way, it is not necessary to
                  personalize the site every time it is visited.· Analytical
                  cookies - This type of cookie allows analyzing how users use
                  the site, knowing the most popular pages and monitoring the
                  site's performance, making it possible to understand possible
                  error messages on the site. Personal information will never be
                  revealed.
                  <br />
                  <br />
                  Social Networks
                  <br />
                  <br />
                  Our site contains references (links) to apply to external
                  social networks, such as Facebook ('social plugins'). The
                  functions assigned to the links, especially the transmission
                  of information and user data, are activated only when the user
                  clicks on the respective link. Once this click on the social
                  network link is made, a direct connection with its servers is
                  established, and user data may be transferred. From that
                  moment on, the Social Network will be solely responsible for
                  processing the user's data.If you have the opportunity to
                  'share' content from our Site with friends through social
                  networks such as Facebook and Twitter, cookies may be sent to
                  you from these sites. We do not control the setting of these
                  cookies, so we suggest that you check other sites to obtain
                  more information about their cookies and how to manage them.
                  <br />
                  <br />
                  PRIVACY POLICY
                  <br />
                  <br />
                  Scope
                  <br />
                  <br />
                  BPMG - Drinks and Consulting, LDA, hereinafter referred to as
                  'ZEOOS,' respects the privacy of its interlocutors. The ZEOOS
                  Privacy Policy aims to make known who we are, for what
                  purposes we collect personal data, how we treat it, with whom
                  we share it, for how long we keep it, as well as the ways to
                  contact us and exercise your rights. By accepting the SACV
                  Privacy Policy, you give your consent to the processing of
                  personal data for the purposes indicated below. Therefore, we
                  advise careful reading of this Privacy Policy, as well as the
                  Terms and Conditions.
                  <br />
                  <br />
                  Data Controller
                  <br />
                  <br />
                  The data controller for personal data is BPMG - Drinks and
                  Consulting, LDA, corporate entity no. 516006983, headquartered
                  at Rua Mário Castelhano, no. 10, 2730 20 Queluz, Lisbon
                  district, Oeiras Municipality, Barcarena Parish, telephone
                  number +351 211 653 380.In certain cases, ZEOOS will act as a
                  subcontractor, processing your data on behalf of another
                  entity that will act as the data controller. In these cases,
                  we recommend consulting the privacy policy and/or other
                  information about the processing of your data with the data
                  controllers.
                  <br />
                  <br />
                  Information Retention Period
                  <br />
                  <br />
                  ZEOOS will only retain and store data for the period necessary
                  to fulfill the purposes that motivated its collection and
                  processing, except in cases where there is a specific legal
                  requirement. In the latter case, for example, legal
                  obligations resulting from participation in projects financed
                  by the European Union.
                  <br />
                  <br />
                  Data Subject Rights
                  <br />
                  <br />
                  The data subject may exercise at any time the rights of
                  access, rectification, erasure, limitation of processing or
                  opposition, and where applicable, the right to data
                  portability. If the processing depends on consent, the data
                  subject has the right to withdraw consent at any time,
                  although this right does not affect the lawfulness of the
                  processing carried out based on previously given consent. If
                  consent is legally required for the processing of personal
                  data, the data subject has the right to withdraw consent at
                  any time, although this right does not affect the lawfulness
                  of the processing carried out based on previously given
                  consent, nor the subsequent processing of the same data based
                  on another legal basis, such as compliance with the contract
                  or the legal obligation to which ZEOOS is subject. If the data
                  subject wishes to exercise any of the rights, they should
                  contact ZEOOS in writing, via email at info@zeoos.com or by
                  letter to the address:Rua Mário Castelhano, no 10, 2730 20
                  Queluz, district of Lisbon, municipality of Oeiras, parish of
                  Barcarena.Proof of identity may be requested to ensure that
                  the sharing of personal data is only carried out with the data
                  subject. It should be noted that in certain cases (for
                  example, due to legal requirements) the request may not be
                  immediately satisfied. In any case, the data subject will be
                  informed of the measures taken in this regard within one month
                  from the moment the request is made. The data subject also has
                  the right to lodge a complaint with the National Data
                  Protection Commission.
                  <br />
                  <br />
                  Security and Confidentiality
                  <br />
                  <br />
                  ZEOOS is committed to ensuring the security and
                  confidentiality of the personal data collected. However, the
                  transmission of information over the Internet is not
                  completely secure and, therefore, we cannot guarantee the
                  complete security of the information transmitted through our
                  Website or through any computer application made available
                  over the Internet.ZEOOS has been adopting various security
                  measures to protect personal data from any unlawful use, as
                  well as requiring its partners to adopt security measures
                  equivalent to those it practices. We do not sell or otherwise
                  commercially make available your information to any third
                  party.ZEOOS undertakes to keep your information confidential
                  in accordance with this Privacy Policy and applicable
                  legislation.
                  <br />
                  <br />
                  Changes to the Security Policy
                  <br />
                  <br />
                  ZEOOS may update this Privacy Policy at any time.If ZEOOS
                  decides to change these Policies and Privacy, the new version
                  will replace the present one and will take effect from the
                  date of its publication on this Website. The relationship
                  between ZEOOS and all users will be governed by the new
                  published version.We therefore request that you review this
                  document periodically to stay up to date
                  <br />
                  <br />
                  TERMS AND CONDITIONS
                  <br />
                  <br />
                  This website is owned by BPMG - Drinks and Consulting, LDA
                  (hereinafter referred to as 'ZEOOS' or 'we'), which is
                  responsible for its maintenance.Thank you for visiting our
                  website. Please ensure that you read these Terms and
                  Conditions carefully, as your use of this website constitutes
                  your acceptance of these Terms and Conditions.This legal
                  notice governs the use of our website.If you do not agree to
                  these Terms and Conditions, you may not continue to use this
                  website or make any service requests integrated into it.
                  <br />
                  <br />
                  Copyright and Other Property Rights
                  <br />
                  <br />
                  The content of this website, including trademarks, designs,
                  logos, texts, images, audio and video materials, is the
                  property of ZEOOS, unless otherwise indicated, and is
                  protected under general law and national and international
                  legislation on the protection of intellectual property. It is
                  not allowed to display, reproduce, distribute, modify,
                  transmit or use the contents in any way, for any public or
                  commercial purpose without our prior and express written
                  consent.Total or partial reproduction of the website for
                  commercial purposes is prohibited, as well as its modification
                  or incorporation into any article, publication or other
                  website. No content of this website may be construed as
                  granting a license, authorization or right to use the
                  trademark.The incorrect or abusive use of the registered
                  trademark or any other content of this website is expressly
                  prohibited, except as provided in these Terms and
                  Conditions.However, the user may create and distribute links
                  to the pages of the website. ZEOOS reserves the right to
                  accept or reject these links.Although the personal data
                  provided to us is covered by the Privacy Policy published
                  here, any information or other content that the user transmits
                  to this website (for example, comments, suggestions), whether
                  by email, discussion group message, file upload or otherwise,
                  will be considered as non-confidential, non-reserved, and
                  without proprietary rights.
                  <br />
                  <br />
                  Social Networks
                  <br />
                  <br />
                  Our website contains references - links - to external social
                  networks, such as Facebook, Youtube, Instagram and WeChat. The
                  functions assigned to the links, especially the transmission
                  of information and user data, are activated only when the user
                  accesses the respective link.Once the user accesses the social
                  network link, a direct connection is established with its
                  servers and user data may be transferred.From that moment on,
                  the social network to which the user accessed will be solely
                  responsible for processing the data.If you share content from
                  our website through social networks, you may receive
                  cookies.We do not control the setting of these cookies, so we
                  suggest that you check other websites for more information
                  about their cookies and how to manage them.
                  <br />
                  <br />
                  Termination of Use
                  <br />
                  <br />
                  ZEOOS may, at any time and by any criteria it deems
                  appropriate, terminate the user's access to the website.
                  <br />
                  <br />
                  Disclaimer
                  <br />
                  <br />
                  The information on this website has been included in good
                  faith and serves solely for general information, and its use
                  is at the user's sole risk.ZEOOS shall not be liable for any
                  damages or losses in civil liability arising from the correct
                  or incorrect use of the website and its contents by the user,
                  or for third-party access to the user's computer and
                  information system.Despite ZEOOS's efforts to keep the content
                  updated and accurate, it may contain inaccuracies, writing
                  errors, or be outdated, and ZEOOS cannot be held responsible
                  for the complete accuracy and timeliness of any information
                  contained on its sites.Viewing legal provisions on this
                  website does not dispense with consulting the current legal
                  norms.
                  <br />
                  <br />
                  Information on Alternative Consumer Dispute Resolution
                  <br />
                  <br />
                  The consumer may resort to one of the Alternative Consumer
                  Dispute Resolution entities, whose name, contacts and Internet
                  website addresses are listed with the Consumer Directorate.
                  For more information, consult the Consumer Portal:
                  www.consumidor.pt.
                </div>
              </div>
              <div className="width100 in-row align-center justify-between mt4 mb4">
                <div className="in-row policy-text align-center">
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    sx={{
                      color: "",
                      "&.Mui-checked": {
                        color: "#55718A",
                      },
                    }}
                  />
                  <b className="ml2" style={{ color: "#55718A" }}>
                    I read and accept the terms
                  </b>
                </div>

                <div>
                  <button
                    onClick={handleSubmit}
                    disabled={!checked}
                    style={{ background: "#55718A" }}
                    className="log-in-login p2"
                  >
                    Accept the terms
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={openDebit}
          style={{ width: "70%" }}
          className="contrato-bg pt4"
        >
          <AccordionSummary
            aria-controls="debit"
            id="debit1"
            expandIcon={<ArrowDropDown />}
            style={{ width: "100%" }}
          >
            <p>Sign Contract</p>
          </AccordionSummary>
          <AccordionDetails style={{ width: "100%" }}>
            <div className="contrato-cont">
              <p className="debit-text-1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src="/icons/sepa-logo.png" width={30} height={30} style={{ marginRight: 4 }} />Download the contract from <a href="/template.pdf" className="contrato-template-here" style={{ textDecoration: "underline", marginLeft: 4 }} target="_blank">the source</a>.</p>
              <p style={{ textAlign: "center", marginTop: 10 }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti dolore ex vel, autem rem asperiores soluta aut earum provident ducimus in vitae eum illum amet aperiam, porro cum! Alias, laudantium.</p>
              <div className="contrato-choose-file pointer">
                <img
                  className="mr2"
                  src="/icons/mass-choose-file.svg"
                  alt="mass-choose-file icon"
                />
                <label htmlFor="upload">Upload the file</label>
                <input
                  type="file"
                  name="file"
                  id="upload"
                  onChange={readUploadFile}
                  hidden
                  accept="application/pdf"
                />
              </div>
              <p>
                {file?.name}
              </p>

              <div style={{ display: "flex", flexDirection: "row", height: 78, marginTop: 20, width: "100%", justifyContent: "space-evenly" }}>
                <button
                  onClick={onSkipPress}
                  disabled={submitting}
                  style={{ height: 54, margin: 0, width: "88px" }}
                  className=" contrato-choose-file log-in-login p2"
                >
                  Skip
                </button>

                <button
                  onClick={saveContractFile}
                  disabled={submitting}
                  style={{ background: "#55718A", height: 54, margin: 0, width: "80%" }}
                  className="log-in-login p2"
                >
                  {submitting ? "Sending..." : "Send"}
                </button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
