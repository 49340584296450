import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import "../pim.css";
import { TooltipInfoText } from "../../../ui/tooltip/TooltipInfoText";
import { getContent } from "../Pim";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PimTranslator from "../PimTranslator";
import { PimSchemeDataContext, ProductInfoContext } from "../tabs/Tabs";
import * as _ from "lodash";
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import { SchemeTag } from "../../../../interfaces";
import { uploadFile } from "../../../../actions/pim";

const theme = createTheme({
	palette: {
		neutral: {
			main: "#bdbdbd",
			contrastText: "#fff",
		},
		invisible: {
			main: "#ffcdd2",
			contrastText: "#fff",
		},
	},
});
declare module "@mui/material/styles" {
	interface Palette {
		neutral: Palette["primary"];
		invisible: Palette["primary"];
	}

	interface PaletteOptions {
		neutral?: PaletteOptions["primary"];
		invisible?: PaletteOptions["primary"];
	}
}

export const ReusableTab: React.FC<any> = ({
	schemeData,
	productInfo,
	onInputChange,
	onCheckboxListChange,
	onTagsChange,
}) => {
	return (
		<div>
			<form>
				{schemeData.map((item: any, index: number) => (
					<Section
						fields={item.fields}
						legend={item.label}
						schemeItem={item}
						productInfo={productInfo}
						onInputChange={onInputChange}
						onCheckboxListChange={onCheckboxListChange}
						onTagsChange={onTagsChange}
						key={index}
					/>
				))}
			</form>
		</div>
	);
};

const Section: React.FC<any> = ({
	fields,
	legend,
	schemeItem,
	productInfo,
	onInputChange,
	onCheckboxListChange,
	onTagsChange,
}) => {
	const renderSection = (elementsPosition: string) => {
		switch (elementsPosition) {
			case "row":
				return (
					<SectionRow
						fields={fields}
						productInfo={productInfo}
						onInputChange={onInputChange}
						onCheckboxListChange={onCheckboxListChange}
						onTagsChange={onTagsChange}
					/>
				);
			case "mixed":
				return (
					<SectionMixed
						fields={fields}
						productInfo={productInfo}
						onInputChange={onInputChange}
						onCheckboxListChange={onCheckboxListChange}
						onTagsChange={onTagsChange}
						flag={"mixed"}
					/>
				);
			default:
				return;
		}
	};

	//hide pack image fields in "recursos" tab for single products and vice-versa
	if (
		(productInfo.tipologia !== "Single" && schemeItem.label === "Single") ||
		(productInfo.tipologia === "Single" && schemeItem.label === "Pack")
	) {
		return <></>;
	}

	return (
		<div className="pim-main-title">
			<div className="pim-main-title-box justify-between align-start">
				<div className="pim-title-legend">{legend}</div>
				{fields.some((item: any) => item.requiredField) && (
					<div className="in-row align-start">
						<div className="blue">*</div>
						<div className="pim-campo-text">Required field</div>
					</div>
				)}
			</div>
			<div className="pim-main-title-cont">
				{schemeItem.elementsPosition ? (
					<React.Fragment>
						{renderSection(schemeItem.elementsPosition)}
					</React.Fragment>
				) : (
					<React.Fragment>
						{fields.map((item: any, index: number) => (
							<SectionComponent
								item={item}
								content={getContent(item, productInfo)}
								onInputChange={onInputChange}
								productInfo={productInfo}
								onCheckboxListChange={onCheckboxListChange}
								onTagsChange={onTagsChange}
								fields={fields}
								index={index}
								key={index}
							/>
						))}
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

const SectionRow: React.FC<any> = ({
	fields,
	productInfo,
	onInputChange,
	onCheckboxListChange,
	onTagsChange,
}) => {
	return (
		<div className="pim-ficha-column-cont">
			{fields.map((item: any, index: number) => (
				<div className="pim-ficha-box width100" key={index}>
					<SectionComponent
						item={item}
						content={getContent(item, productInfo)}
						onInputChange={onInputChange}
						productInfo={productInfo}
						onCheckboxListChange={onCheckboxListChange}
						onTagsChange={onTagsChange}
						fields={fields}
						index={index}
						key={index}
					/>
				</div>
			))}
		</div>
	);
};

const SectionMixed: React.FC<any> = ({
	fields,
	productInfo,
	onInputChange,
	onCheckboxListChange,
	onTagsChange,
	flag,
	item,
}) => {
	return (
		<div className="pim-ficha-column-cont">
			{fields.sort().map((item: any, index: number) => (
				<React.Fragment key={index}>
					<SectionComponent
						item={item}
						content={getContent(item, productInfo)}
						onInputChange={onInputChange}
						productInfo={productInfo}
						onCheckboxListChange={onCheckboxListChange}
						onTagsChange={onTagsChange}
						fields={fields}
						index={index}
						flag={flag}
					/>
					{fields.length - 2 === index && (
						<div className="table-modal-border"></div>
					)}
				</React.Fragment>
			))}
		</div>
	);
};

const SectionComponent: React.FC<any> = ({
	item,
	content,
	onInputChange,
	productInfo,
	onCheckboxListChange,
	onTagsChange,
	fields,
	index,
	flag,
}) => {
	const renderComponent = (type: string, item: any) => {
		switch (type) {
			case "input":
				return (
					<InputComponent
						item={item}
						content={content}
						onInputChange={onInputChange}
					/>
				);
			case "checkbox":
				return (
					<CheckboxComponent
						item={item}
						productInfo={productInfo}
						onCheckboxListChange={onCheckboxListChange}
					/>
				);
			case "array":
				return (
					<SelectComponent
						item={item}
						content={content}
						onInputChange={onInputChange}
					/>
				);
			case "textArea":
				return (
					<TextAreaComponent
						item={item}
						content={content}
						onInputChange={onInputChange}
						onTagsChange={onTagsChange}
					/>
				);
			case "assets":
				return (
					<AssetsComponent
						item={item}
						content={content}
						onInputChange={onInputChange}
						fields={fields}
						index={index}
					/>
				);
			default:
				return null;
		}
	};

	const symbolsCounter = (content: string) => {
		if (!content || typeof content !== "string") {
			return 0;
		} else {
			return content.split("").length;
		}
	};

	return (
		<div
			className={
				flag !== "mixed" ? contentSectionStyle(item.type) : "pim-ficha-box"
			}
		>
			{item.type !== "assets" && (
				<div className="mwidth150 in-column maxwidth150 pr1 mt2">
					<div className={labelMarginStyle(item.marginLabel)}>
						<div className="mr1">{item.label}:</div>
						{item.requiredField && <div className="blue">*</div>}
						{item.tooltipLabel && (
							<div className="pim-subtext align-start">
								<TooltipInfoText text={item.tooltipLabel} />
							</div>
						)}
					</div>
					{item.fieldLength && (
						<span className="pim-subtext align-start width100">
							{symbolsCounter(content)}/{item.fieldLength}
						</span>
					)}
				</div>
			)}
			{flag === "mixed" ? (
				<div className="pim-content-section">
					{renderComponent(item.type, item)}
				</div>
			) : (
				<>{renderComponent(item.type, item)}</>
			)}
		</div>
	);
};

const InputComponent: React.FC<any> = ({ item, onInputChange }) => {
	const { productInfo } = useContext(ProductInfoContext);

	const replaceDotToCommaForDecimal = (value: any) => {
		if (Number.isInteger(Number(value))) {
			return value;
		} else {
			return !isNaN(value)
				? Number(value).toLocaleString("de-DE", {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				})
				: value;
		}
	};

	return (
		<div className={`${inputWidthStyle(item.label)} width100`}>
			<div className="in-row width100">
				<input
					type="text"
					className={`${inputTypeStyle(item.inputLenght)} ${productInfo.details[item.name] && "pim-input-cont-red"
						}`}
					value={replaceDotToCommaForDecimal(productInfo.details[item.name])}
					onChange={(e) => onInputChange(e, item.name)}
					readOnly={item.readonlyField}
				/>
				{item.unit && (
					<div className="pim-logistica-subtext">({item.unit})</div>
				)}
			</div>
			<PimTranslator rules={item} />
		</div>
	);
};

function useOptions(item: any, includeValue: (o: string[]) => string[]) {
	const { productInfo } = useContext(ProductInfoContext);

	const defaultOptions = useMemo(() => item.value, [item.value]);

	const translatedOptions = useMemo(() => {
		return item.translationValue.map((val: any) => val[productInfo.lang]);
	}, [item.translationValue, productInfo.lang]);

	const options = useMemo(() => {
		if (translatedOptions.some((o: string) => !o)) {
			return includeValue(defaultOptions);
		}

		return includeValue(translatedOptions);
	}, [defaultOptions, translatedOptions, includeValue]);

	return options;
}

const SelectComponent: React.FC<any> = ({ item, onInputChange }) => {
	const { productInfo } = useContext(ProductInfoContext);
	const value = useMemo(
		() => productInfo.details[item.name],
		[productInfo.details, item.name]
	);
	const includeValue = useCallback((options: string[]) => {
		// return !options.includes(value) ? [value, ...options] : options;
		return options.filter((x: string) => !!x);
	}, []);

	const options = useOptions(item, includeValue);

	const optionsArray = (array: any) => {
		return ["", ..._.uniq(_.map(array, (item: any) => item))];
	};

	return (
		<div className="pim-input-cont">
			<select
				className={selectTypeStyle(item.selectLenght)}
				value={value}
				onChange={(e) => {
					onInputChange(e, item.name);
				}}
				style={{
					border: `${!value && "1px solid #55718a"}`,
				}}
				disabled={item.readonlyField}
			>
				{optionsArray(options).map((x: string, index: number) => (
					<option className="pim-title-select-option" key={index}>
						{x}
					</option>
				))}
			</select>
			{/* <span onClick={(e: any) => {
        e.target.value = "";
        onInputChange(e, item.name)
      }}>x</span> */}
			<PimTranslator rules={item} />
		</div>
	);
};

const TextAreaComponent: React.FC<any> = ({
	item,
	onInputChange,
	onTagsChange,
}) => {
	const { productInfo } = useContext(ProductInfoContext);
	const { schemeData } = useContext(PimSchemeDataContext);

	const [tags, setTags] = useState<SchemeTag[]>([]);
	const [schemeTags, setSchemeTags] = useState<SchemeTag[]>([]);
	const [defaultTags, setDefaultTags] = useState<SchemeTag[]>([]);

	useEffect(() => {
		const productInfoTags = productInfo.details[`${item.name}_tags`];

		setDefaultTags(
			schemeData
				.flatMap((tab) => {
					return tab.fields.flatMap((field) => {
						return field.fields.map((x) =>
							x.label !== item.label
								? {
									label: x.label,
									name: x.name,
									disabled: false,
									createdByScheme: false,
								}
								: ""
						);
					});
				})
				.filter((item: SchemeTag | string) => item !== "") as SchemeTag[]
		);

		setTags(productInfoTags || item.tags || []);

		setSchemeTags(
			productInfoTags?.some((t: SchemeTag) => t.disabled)
				? _.uniqBy([...productInfoTags, ...item.tags], "name").filter(
					(t: SchemeTag) => {
						const names = item.tags.map(
							(schemeTag: SchemeTag) => schemeTag.name
						);

						return names.includes(t.name);
					}
				)
				: item.tags
		);
		// eslint-disable-next-line
	}, []);

	const showTag = (tagValue: SchemeTag[], getTagProps: any) => {
		const customTags = tagValue.filter((tag: SchemeTag) => {
			const names = schemeTags.map((schemeTag: SchemeTag) => schemeTag.name);

			return !names.includes(tag.name) && !tag.createdByScheme;
		});

		const toggleDisable = (arr: SchemeTag[], name: string) => {
			return arr.map((t: SchemeTag) => {
				if (t.name === name) {
					t.disabled = !t.disabled;
				}
				return t;
			});
		};

		return (
			<>
				{item.autoValues &&
					schemeTags.map((schemeTag: SchemeTag, index: number) => (
						<React.Fragment key={index}>
							<ThemeProvider theme={theme}>
								<Chip
									label={schemeTag.label}
									{...getTagProps({ index })}
									deleteIcon={
										!schemeTag.disabled ? (
											<VisibilityIcon />
										) : (
											<VisibilityOffIcon />
										)
									}
									onDelete={(e: React.MouseEvent) => {
										e.preventDefault();
										setSchemeTags(toggleDisable(schemeTags, schemeTag.name));
										onTagsChange(
											e,
											item.name,
											_.uniqBy([...schemeTags, ...tags], "name")
										);
									}}
									color={!schemeTag.disabled ? "neutral" : "invisible"}
									variant={!schemeTag.disabled && "outlined"}
								/>
							</ThemeProvider>
						</React.Fragment>
					))}
				{customTags.map((customTag: SchemeTag, index: number) => (
					<React.Fragment key={index}>
						<ThemeProvider theme={theme}>
							<Chip
								label={customTag.label}
								{...getTagProps({ index })}
								color="neutral"
								variant="outlined"
								onDelete={(e: React.MouseEvent) => {
									e.preventDefault();
									setTags(
										tags.filter((t: SchemeTag) => t.name !== customTag.name)
									);
									onTagsChange(
										e,
										item.name,
										_.uniqBy([...schemeTags, ...tags], "name").filter(
											(t: SchemeTag) => t.name !== customTag.name
										)
									);
								}}
							/>
						</ThemeProvider>
					</React.Fragment>
				))}
			</>
		);
	};

	return (
		<div className="width100 in-column">
			{tags?.length ? (
				<Autocomplete
					className="tags-autocomplete-container width100"
					multiple
					id="tags"
					options={defaultTags}
					getOptionLabel={(option: SchemeTag) => option.label}
					defaultValue={tags}
					value={!tags.length ? schemeTags : tags}
					onChange={(e: any, values: SchemeTag[]) => {
						e.preventDefault();
						const filteredValues = _.uniqBy([...schemeTags, ...values], "name");
						setTags(filteredValues);
						onTagsChange(e, item.name, filteredValues);
					}}
					renderTags={(tagValue, getTagProps) => showTag(tagValue, getTagProps)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Option"
							placeholder="Option"
						/>
					)}
				/>
			) : (
				<textarea
					className={textAreaTypeStyle(item.textAreaLenght)}
					value={productInfo.details[item.name]}
					onChange={(e) => onInputChange(e, item.name)}
					readOnly={item.readonlyField}
				/>
			)}
			<PimTranslator rules={item} />
		</div>
	);
};

const CheckboxComponent: React.FC<any> = ({ item, onCheckboxListChange }) => {
	const { productInfo } = useContext(ProductInfoContext);

	// const value = useMemo(
	// 	() => productInfo.details[item.name],
	// 	[productInfo.details, item.name]
	// );
	// const valueArray = useMemo(
	// 	() => (!Array.isArray(value) ? [value] : value),
	// 	[value]
	// );

	const includeValue = useCallback((options: string[]) => {
		// const inOptions = [] as string[];
		// const notInOptions = [] as string[];

		// for (let index = 0; index < valueArray.length; index++) {
		// 	const element = valueArray[index];

		// 	if (options.includes(element)) {
		// 		inOptions.push(element);
		// 	} else {
		// 		notInOptions.push(element);
		// 	}
		// }

		// return (
		// 	valueArray != null ? [...notInOptions, ...options] : options
		// ).filter((x: string) => !!x);
		return options.filter((x: string) => !!x);
	}, []);

	const options = useOptions(item, includeValue);

	return (
		<div className="pim-ficha-column-cont">
			{options.map((x: any, index: number) => (
				<div className="pim-ficha-box" key={index}>
					<FormControllLabelPim
						control={
							<Checkbox
								className="blue"
								checked={productInfo.details[item.name]?.includes(x)}
								onChange={() => {
									onCheckboxListChange(item.name, x, options);
								}}
								name={item.name}
								disabled={item.readonlyField}
							/>
						}
						label={x}
					/>
				</div>
			))}
			<PimTranslator rules={item} />
		</div>
	);
};

const ImageUpload: React.FC<any> = ({ onInputChange, item }) => {
	const ref = useRef<HTMLInputElement>(null);

	const handleUpload = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files?.[0]) {
			return;
		}

		try {
			const artificialEvent = {
				preventDefault: e.preventDefault,
				target: {
					value: await uploadFile(e.target.files?.[0]),
				},
			};

			onInputChange(artificialEvent, item.name);
		} catch (error) {
			console.error(error);
		}
	}, [item?.name, onInputChange]);

	return (
		<>
			<input
				onChange={handleUpload}
				ref={ref}
				style={{ display: "none" }}
				type="file"
			/>

			<div onClick={() => ref.current?.click()} className="pim-image-box">
				<img src="/icons/testemunhos-upload.svg" alt="" width="25px" />
				<div className="pim-image-text">Enviar vídeo ou imagem</div>
			</div>
		</>
	);
};

const AssetsComponent: React.FC<any> = ({
	item,
	onInputChange,
	fields,
	index,
}) => {
	const { productInfo } = useContext(ProductInfoContext);

	return (
		<>
			<div className="pim-cont">
				{productInfo.details[item.name] ? (
					<div className="pim-image-box">
						<img
							src={productInfo.details[item.name]}
							className="pim-image-recursos"
							alt=""
							width="150px"
							height="150px"
						/>
						{/* <img src="/icons/testemunhos-upload.svg" alt="" width="25px" />
            <div className="pim-image-text">Enviar vídeo ou imagem</div> */}
					</div>
				) : (
					<ImageUpload item={item} onInputChange={onInputChange} />
					// <div className="pim-image-box">
					// 	<img src="/icons/testemunhos-upload.svg" alt="" width="25px" />
					// 	<div className="pim-image-text">Enviar vídeo ou imagem</div>
					// </div>
				)}
				<div className={"pim-content-box"}>
					<span className="pim-content-title-text">{item.label}</span>
					<div className="pim-content-section">
						<div className="mwidth120">URL:</div>
						<input
							className="width90 mr2 pim-content-input"
							value={productInfo.details[item.name]}
							onChange={(e) => onInputChange(e, item.name)}
						/>
						<div className="pim-content-button-active">
							<a href={productInfo.details[item.name]} target="blank">
								Ver
							</a>
						</div>
						<button
							className="pim-content-button-active"
							onClick={(e) => {
								e.preventDefault();

								const artificialEvent = {
									preventDefault: e.preventDefault,
									target: {
										value: "",
									},
								};

								onInputChange(artificialEvent, item.name);
							}}
						>
							<img src="/icons/testemunhos-trash.svg" alt="" width="25;" />
						</button>
					</div>
					{item.description && (
						<div className="pim-content-section">
							<div className="mwidth120">Descrição:</div>
							<textarea
								className="width50 pim-content-input pl1 pt1"
								value={productInfo.details[item.name]}
								onChange={(e) => onInputChange(e, item.name)}
							/>
						</div>
					)}
				</div>
			</div>
			{fields.length !== index + 1 && (
				<div className="table-modal-border"></div>
			)}
		</>
	);
};

const FormControllLabelPim = withStyles({
	label: {
		color: "#5A5A5A",
		fontSize: "14px",
		lineHeight: "16px",
	},
})(FormControlLabel);

// const Chippim = withStyles({
//   root: {
//     marginRight: '10px',
//     border: '1px solid #ACACAC',
//     borderRadius: '16px',
//   },
//   label: {
//     color: '#5A5A5A',
//     fontSize: '14px',
//     lineHeight: '16px'
//   },
// })(Chip);
const inputWidthStyle = (style: string) => {
	switch (style) {
		case "Titulo":
			return "pim-input-cont width100";
		case "Link Shopify":
			return "pim-input-cont width100";
		default:
			return "pim-input-cont width100";
	}
};

const inputTypeStyle = (style: string) => {
	switch (style) {
		case "small":
			return "pim-logistics-input";
		case "custom":
			return "pim-logistics-input mwidth200";
		case "average":
			return "pim-logistics-input width50";
		case "big":
			return "width90 mr2 pim-content-input";
		case "biggest":
		default:
			return "width100 pim-content-input";
	}
};

const selectTypeStyle = (style: string) => {
	switch (style) {
		case "small":
			return "pim-count-select m0";
		case "average":
			return "pim-count-select m0 mwidth200 maxwidth200";
		case "big":
			return "pim-count-select m0 width50";
		default:
			return "pim-count-select m0 mwidth200";
	}
};

const textAreaTypeStyle = (style: string) => {
	switch (style) {
		case "average":
			return "width100 pim-content-input minheight150 pl1 pt1";
		case "big":
			return "width100 pim-content-input minheight100 pl1 pt1";
		default:
			return "width100 pim-content-input minheight100 pl1 pt1";
	}
};

const labelMarginStyle = (style: string) => {
	switch (style) {
		case "big":
			return "mwidth180 in-row align-center";
		default:
			return "in-row width100";
	}
};

const contentSectionStyle = (type: string) => {
	switch (type) {
		case "textArea":
			return "pim-content-section align-start";
		case "assets":
			return "width100";
		default:
			return "pim-content-section";
	}
};
