import React, { useCallback } from "react";
import { IZeoosDemoRequest, DemoRequestStatus } from "types";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import {
	archiveDemoRequest,
	startOnboarding,
} from "../../../../actions/vendor";

interface Props {
	demoRequests: any;
	rqKeyDemoRequests: string;
	setModal: (value: React.SetStateAction<boolean>) => void;
	setModalInteraction: (value: React.SetStateAction<boolean>) => void;
	setSelectedId: (value: React.SetStateAction<string>) => void;
}

export const dateFormat = (date: Date | string, onlyDate?: boolean) => {
	let formatedDate = new Date(date)
		.toLocaleString("en-GB")
		.slice(0, -3)
		.split(",");

	if (onlyDate) {
		formatedDate.splice(-1);
	}

	return (
		<>
			{formatedDate.map((el: string, index: number) => (
				<React.Fragment key={index}>
					{el}
					<br />
				</React.Fragment>
			))}
		</>
	);
};

const DemoRequestTable: React.FC<Props> = ({
	demoRequests,
	rqKeyDemoRequests,
	setModal,
	setModalInteraction,
	setSelectedId,
}) => {
	const refetchOnboardingConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyDemoRequests);
			toast.success("The action is successfully completed");
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const createMutation = useMutation(
		(data: IZeoosDemoRequest) => startOnboarding(data),
		refetchOnboardingConfig
	);

	const editMutation = useMutation(
		(id: string) => archiveDemoRequest(id),
		refetchOnboardingConfig
	);

	const onStartHandler = useCallback(
		async (e: React.MouseEvent, data: IZeoosDemoRequest) => {
			e.preventDefault();

			await createMutation.mutateAsync(data);
		},
		[createMutation]
	);

	const onArchiveHandler = useCallback(
		async (e: React.MouseEvent, id: string) => {
			e.preventDefault();

			await editMutation.mutateAsync(id);
		},
		[editMutation]
	);

	const actionsCell = (demoRequest: IZeoosDemoRequest) => {
		switch (demoRequest.status) {
			case "NEW":
				return (
					<>
						<button
							style={{ zIndex: "10" }}
							className="demo-request-archive"
							onClick={(e) => onArchiveHandler(e, demoRequest._id)}
						>
							Archive
						</button>
						<button
							className="demo-request-start"
							onClick={(e) => onStartHandler(e, demoRequest)}
						>
							Start onboarding
						</button>
					</>
				);
			case "ARCHIVED":
				return (
					<span>Archived on {dateFormat(demoRequest.actionDate, true)}</span>
				);
			case "ONBOARDING":
				return (
					<span>
						Start onboarding on {dateFormat(demoRequest.actionDate, true)}
					</span>
				);
			default:
				return <></>;
		}
	};

	return (
		<div
			style={{ height: "max-content", marginBottom: "20px" }}
			className="table-overflow"
		>
			<table
				style={{
					zIndex: 101,

					width: "100%",
				}}
			>
				<thead>
					<tr
						style={{ marginTop: "-5px", width: "100%" }}
						className="table-results-title"
					>
						<th className="demo-request-name">Company name</th>
						<th className="demo-request-col">
							Company
							<br />
							Information
						</th>
						<th className="demo-request-col">Request Date</th>
						<th className="demo-request-col">Chosen plan</th>
						<th className="demo-request-col">Interactions</th>
						<th className="demo-request-act">Actions</th>
					</tr>
				</thead>
			</table>
			<table className="table-results">
				<tbody>
					{demoRequests?.data.map((demoRequest: IZeoosDemoRequest) => (
						<tr
							style={{ marginTop: "-5px" }}
							className="table-result-box email-text border0 width100"
							key={demoRequest._id}
						>
							<td className="demo-request-name">{demoRequest?.companyName}</td>
							<td className="demo-request-col">
								<img
									className="pointer"
									src="/icons/sel-info-icon.svg"
									alt=""
									onClick={(e) => {
										e.preventDefault();
										setModal(true);
										setSelectedId(demoRequest._id);
									}}
								/>
							</td>
							<td className="demo-request-col">
								{dateFormat(demoRequest?.created)}
							</td>
							<td className="demo-request-col uppercase-text">
								{demoRequest.planName}
							</td>
							<td
								className={`demo-request-col pointer ${
									demoRequest?.interactions.length ? "bold" : "grey"
								}`}
								onClick={(e) => {
									e.preventDefault();
									setSelectedId(demoRequest._id);
									setModalInteraction(true);
								}}
							>
								{demoRequest?.interactions.length
									? `${demoRequest?.interactions.length} interactions`
									: `0 action`}
							</td>
							<td
								className={`demo-request-act ${
									demoRequest?.status === DemoRequestStatus.NEW
										? "justify-around"
										: "bold"
								}`}
							>
								{actionsCell(demoRequest)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default DemoRequestTable;
