import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../../../interfaces";
import { convertPrice } from "../../routes/tables/gestao/UpdateGestao";
import {
  totalAmount,
  totalProducts,
} from "../../routes/billing/treasury/TreasuryTable";

interface Props {
  allTreasury: any;
  selectedBillings: string[];
  setSelectedBillings: React.Dispatch<React.SetStateAction<string[]>>;
  setBatch: React.Dispatch<React.SetStateAction<boolean>>;
}

const BatchBillingDropDown: React.FC<Props> = ({
  allTreasury,
  selectedBillings,
  setSelectedBillings,
  setBatch,
}) => {
  const totalOpen = () => {
    return selectedBillings.reduce(
      (total: number, curr: any) => total + curr.amount,
      0
    );
  };

  return (
    <AnimatePresence>
      {!!selectedBillings.length && (
        <div className="width100 animation-block treasury-batch">
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            style={{ overflow: "hidden" }}
            transition={{ duration: 0.5 }}
          >
            <div
              style={{ display: "flex" }}
              className="inv-dropdown-cont justify-between"
            >
              <div className="in-row align-center">
                <div className="inv-dropdown-text bold">
                  {selectedBillings.length} selected bilings.
                </div>

                <div
                  className="pointer fw1 ft1 ml1"
                  onClick={() =>
                    setSelectedBillings(
                      allTreasury.map((b: any) => ({
                        _id: b._id,
                        amount: totalAmount(b.billing_lines),
                        billing: b.billing_name,
                        date: b.date_creation,
                        order: b.related_sale_order,
                        platform: b.order?.zeoosName,
                        products: totalProducts(b.billing_lines),
                        seller: b.seller?.name,
                      }))
                    )
                  }
                >
                  Select all {allTreasury.length}
                </div>
                <div className="pointer fw1 ft1 ml1">|</div>
                <div
                  className="pointer fw1 ft1 ml1"
                  onClick={() => {
                    setSelectedBillings([]);
                    setBatch(false);
                  }}
                >
                  Clear
                </div>
              </div>
              <div className="in-row">
                <RoleRequired role={Role.admin}>
                  <div className="in-row bold" style={{ textAlign: "right" }}>
                    Total Selected: <br />
                    {convertPrice(totalOpen())} €
                  </div>
                  <div className="req-popup-button in-row align-center ml3">
                    <div onClick={() => setBatch(true)}>
                      Create payment batch
                    </div>
                  </div>
                </RoleRequired>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default BatchBillingDropDown;
