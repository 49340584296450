import React from 'react';
import { Doughnut } from "react-chartjs-2";

interface Props {
    integrated: number;
    nonIntegrated: number;
    unregistered: number;
}

const ProductImportPie: React.FC<Props> = ({ integrated, unregistered, nonIntegrated }) => {
    const labels = ["Integrated", "Non-integrated", "Unregistered"];
    const data = [integrated, nonIntegrated, unregistered];

    return (
        <div>

            <Doughnut
                // @ts-ignore
                // type="doughnut"
                data={{
                    labels,
                    datasets: [
                        {
                            data,
                            backgroundColor: ["#299C00", "#CCCCCC", "#FFC7C7"],
                            spacing: 1, // add spacing between parts
                        },
                    ],
                }}
                height={150}
                width={200}
                options={{
                    maintainAspectRatio: false,
                    indexAxis: "y",
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    // @ts-ignore
                    borderRadius: 4, // add rounded corners
                }}
            />
        </div>
    );
};

export default ProductImportPie;
