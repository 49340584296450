import React, { useMemo, useState } from "react";
import RenderReviews from "./render-reviews/RenderReviews";
import "./reviews.css";
import { ReviewsFilter } from "./ReviewsFilter";
import { useDebouncedValue } from "@mantine/hooks";
import { IPaginatedRequest } from "../../../interfaces";
import SettingsNav from "../../ui/settings-nav/SettingsNav";
import { useQuery } from "react-query";
import { fetchReviews } from "../../../actions/reviews";
import queryString from "query-string";

export default function Reviews() {
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: "",
    ratingStar: "All stars",
    origin: "All origins",
    country: "All countries",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

  const rqKeyReviews = useMemo(() => {
    return `reviews?${queryString.stringify(debouncedConfig)}`;
  }, [debouncedConfig]);

  const { isLoading: loadReviews, data: reviews } = useQuery(rqKeyReviews, () =>
    fetchReviews(debouncedConfig)
  );

  return (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont p0">
          <SettingsNav />
          <ReviewsFilter
            rqKeyReviews={rqKeyReviews}
            setCurrentConfig={setCurrentConfig}
            currentConfig={currentConfig}
          />
        </div>
        <RenderReviews
          reviews={reviews}
          loadReviews={loadReviews}
          rqKeyReviews={rqKeyReviews}
          setCurrentConfig={setCurrentConfig}
          currentConfig={currentConfig}
        />
      </div>
    </div>
  );
}
