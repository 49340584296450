import React, { useMemo, useState } from "react";
import "./password-management.css";
import { PasswordManagementtable } from "./PasswordManagementTable";
import { CreateEditPassword } from "./CreateEditPassword";
import { getPaginatedPasswordsManagement } from "../../../actions/passwordManagement";
import { useQuery } from "react-query";
import { IPaginatedRequest } from "../../../interfaces";
import * as _ from "lodash";
import SettingsNav from "../../ui/settings-nav/SettingsNav";
import FilterSelect from "../../ui/select/FilterSelect";
import { useDebouncedValue } from "@mantine/hooks";
import queryString from "query-string";
import useCountries from "../../../hooks/useCountries";
import { ICountryM } from "../country-management/CountryManagement";

export default function PasswordManagement() {
  const countries = useCountries() as ICountryM[];

  const [modal, setModal] = useState<boolean>(false);

  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: "",
    country: "All countries",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

  const rqKeyPasswordsInfo = useMemo(() => {
    return `/passwordsManagement?${queryString.stringify(debouncedConfig)}`;
  }, [debouncedConfig]);

  const { isLoading: loadPasswordsInfo, data: passwordsInfo } = useQuery(
    rqKeyPasswordsInfo,
    () => getPaginatedPasswordsManagement(debouncedConfig)
  );

  return (
    <>
      <div className="main-body">
        <div className="main-container">
          <div className="table-main-cont p0">
            <SettingsNav />
            <div className="table-title-cont">
              <div className="mplc-filter-cont width100">
                <div className="mplc-filter">
                  <div className="filter-text">Filter by:</div>
                  <FilterSelect
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCurrentConfig((conf: IPaginatedRequest) => ({
                        ...conf,
                        country: countries.find(
                          (item: ICountryM) => e.target.value === item.name
                        )?._id,
                      }))
                    }
                    value={
                      currentConfig.country !== "All countries" &&
                      countries.find(
                        (item: ICountryM) => item._id === currentConfig.country
                      )?.name
                    }
                    data={[
                      "All countries",
                      ..._.uniq(
                        _.map(countries, (item: ICountryM) => item.name)
                      ),
                    ]}
                  />
                </div>
                <div
                  onClick={() => setModal(true)}
                  className="tax-title-button pointer mr2"
                >
                  <img src="/icons/pim-plus.svg" alt="" />
                  <div className="pim-avaliacoes-title-button-text">
                    New Password
                  </div>
                </div>
              </div>
            </div>
            <PasswordManagementtable
              passwordsInfo={passwordsInfo}
              currentConfig={currentConfig}
              setCurrentConfig={setCurrentConfig}
              loadPasswordsInfo={loadPasswordsInfo}
              rqKeyPasswordsInfo={rqKeyPasswordsInfo}
            />
          </div>
        </div>
        {modal && (
          <CreateEditPassword
            open={modal}
            setOpen={setModal}
            rqKeyPasswordsInfo={rqKeyPasswordsInfo}
          />
        )}
      </div>
    </>
  );
}
