import React from "react";
import { NavLink } from "react-router-dom";
import { Role } from "../../../interfaces";
import RoleRequired from "../../utils/role-required/RoleRequired";

export default function IntelligenceSetNav() {
	return (
		<>
			<div className="in-row align-center p1 mt2">
				<div className="table-title-nav-cont">
					<NavLink
						to="/intelligence/settings"
						className="int-tab-sync"
						activeClassName="int-tab-sync-active"
					>
						Robot List
					</NavLink>
					<RoleRequired role={Role.admin}>
						<NavLink
							to="/intelligence/settings-match"
							className="int-tab-sync"
							activeClassName="int-tab-sync-active"
						>
							Categories Match
						</NavLink>
					</RoleRequired>
				</div>
			</div>
		</>
	);
}
