import React, { memo } from "react";
import { ILogisticServices } from "../../../../../../../packages/types";
import TooltipIcon from "../TooltipIcon";

interface Props {
	title: string;
	btnText: string;
	array: ILogisticServices[];
	handleClick: (e: React.MouseEvent) => void;
}

const ServicesTable: React.FC<Props> = ({
	title,
	btnText,
	array,
	handleClick,
}) => {
	return (
		<div className="table-main-cont mb3 p0">
			<div className="table-main-title-cont pl2 pr2">
				<div className="uppercase-text ft2 opacity5">{title}</div>
				<div
					className="img-border blue-color ft2 p1 border5 pointer"
					onClick={handleClick}
				>
					+ add {btnText}
				</div>
			</div>
			<div style={{ height: "max-content" }} className="table-overflow">
				<table
					style={{
						position: "sticky",
						zIndex: 101,
						top: "2px",
						width: "100%",
					}}
				>
					<thead>
						<tr
							style={{ marginTop: "-5px", width: "100%" }}
							className="table-results-title"
						>
							<th className="service-name">Services</th>
							<th className="service-amount">Fee amount</th>
							<th className="service-unit">Unit</th>
							<th className="service-note">Note</th>
						</tr>
					</thead>
				</table>
				<table
					className="table-results s-warehouse-text"
					style={{ minHeight: "auto" }}
				>
					<tbody>
						{array.map((x: ILogisticServices) => (
							<tr className="table-result-box height50px mn" key={x._id}>
								<td className="service-name pointer">
									<span>{x.service}</span>
								</td>
								<td className="service-amount">
									<span>{x.amount} €</span>
								</td>
								<td className="service-unit">
									<span>{x.unit}</span>
								</td>
								<td className="service-note">
									{x.note ? (
										<TooltipIcon
											title={x.note}
											icon={"/icons/i-icon.svg"}
											iconHover={"/icons/i-icon-hover.svg"}
											size={{ w: "20px", h: "20px" }}
										/>
									) : (
										<span>-</span>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default memo(ServicesTable);
