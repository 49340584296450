import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { useQuery } from "react-query";
import { RuleController, SchemeCategoryItemController } from "../../../actions";
import { Menu, MenuItem, TextField } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import { Rule, RuleItem, RuleOrientation, RuleType } from "types";
import { useCallback } from "react";
import { queryClient } from "../../App";
import ModalHeader from "./ModalHeader";

type Params = { zeoosName: string; };

const Rules: React.FC<RouteComponentProps<Params>> = (props) => {
	const { zeoosName } = props.match.params;

	const key = 'rules';
	const rules = useQuery(key, RuleController.find, { refetchOnWindowFocus: false });
	const mutate = useCallback(() => {
		queryClient.invalidateQueries(key);
	}, [key]);
	const [toAddNew, setToAddNew] = useState(false);

	if (rules.isLoading) {
		return <></>;
	}

	return (
		<div className="product-main-cont">
			<ModalHeader zeoosName={zeoosName}>
				<div className="height100 in-row align-cente">
					<Link
						to={"/product-attributes/" + zeoosName}
						className="product-back-btn"
					>
						back
					</Link>
					{/* <button className="product-header-button">save</button> */}
				</div>
			</ModalHeader>

			<div className="product-cont">
				<h1 className="product-h">Create rules</h1>
				<p className="product-p">
					Link your products fields with the platform structure to define how
					your products will be displayed.
				</p>
				<div className="product-content">
					{rules.data?.map(item => (
						<CreateEditRule
							key={item._id}
							rule={item}
							onSuccess={mutate}
						/>
					))}

					{toAddNew &&
						<CreateEditRule
							onSuccess={() => {
								mutate();
								setToAddNew(false);
							}}
						/>
					}

					<div className="product-box">
						<div
							className="product-add-cont-order mt2"
							onClick={() => setToAddNew(prev => !prev)}
						>
							<img
								className="mr2"
								src="/icons/product-add-circle-blue.svg"
								alt=""
							/>
							ADD A NEW RULE
						</div>
					</div>
				</div>
			</div>

		</div>
	);
};

const CreateEditRule: React.FC<{
	rule?: Rule;
	onSuccess: Function;
}> = ({
	rule,
	onSuccess,
}) => {
		const [name, setName] = useState(rule?.name || '');
		const [list, setList] = useState(rule?.list || []);
		const [editMode, setEditMode] = useState(!rule);
		const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
		const open = Boolean(anchorEl);
		const handleClick = (event: any) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const setListItems = useCallback((attribute: string, index: number) => (e: any) => {
			setList(prev => prev.map((x, i) => {
				if (i === index) {
					// @ts-ignore
					x[attribute] = e.target.value;
				}

				return x;
			}));
		}, []);

		return (
			<div className="product-input-cont width100 in-column">
				{(rule && !editMode) ? (
					<>
						<div className="in-row width100 justify-between align-center">
							<span className="product-match-title">{name}</span>
							{rule && (
								<MoreVertIcon fontSize="small" sx={{ cursor: 'pointer' }} onClick={handleClick} />
							)}
						</div>
					</>
				) : (
					<>
						<div className="product-input-cont width100 in-column items-start" style={{ padding: 0 }}>
							<span className="mb1">Rule name</span>
							<input
								className="product-input"
								placeholder={`Rule name`}
								value={name}
								onChange={e => setName(e.target.value)}
								disabled={!editMode}
							/>
						</div>

						<div className="product-input-cont width100 in-column items-start" style={{ padding: 0 }}>
							<span className="mb1 align-center">
								Rule list
								<img
									className="mr2"
									src="/icons/product-add-circle-blue.svg"
									alt=""
									onClick={() =>
										setList((prev) => ([
											...prev,
											{
												type: RuleType.CAPITALIZE,
											} as RuleItem
										]))
									}
								/>
							</span>

							{list.map((item, index) => (
								<div className="product-input-cont width100 in-column" key={index}>
									<div className="product-input-cont width100 in-column items-start" style={{ padding: 0 }}>
										<span className="mb1">Type</span>

										<TextField
											value={item.type}
											select
											sx={{
												minWidth: 300
											}}
											inputProps={{
												className: "product-select",
											}}
											onChange={setListItems('type', index)}
											disabled={!editMode}
										>
											{Object.keys(RuleType).map(key => (
												<MenuItem className="compa-option" value={RuleType[key as keyof typeof RuleType]} key={key}>
													{key}
												</MenuItem>
											))}
										</TextField>
									</div>

									{item.type === RuleType.ADD_CONTENT && (
										<>
											<div className="product-input-cont width100 in-column items-start" style={{ padding: 0 }}>
												<span className="mb1">Orientation</span>

												<TextField
													value={item.orientation}
													select
													sx={{
														minWidth: 300
													}}
													inputProps={{
														className: "product-select",
													}}
													onChange={setListItems('orientation', index)}
													disabled={!editMode}
												>
													{Object.keys(RuleOrientation).map(key => (
														<MenuItem className="compa-option" value={RuleOrientation[key as keyof typeof RuleOrientation]} key={key}>
															{key}
														</MenuItem>
													))}
												</TextField>
											</div>

											<div className="product-input-cont width100 in-column items-start" style={{ padding: 0 }}>
												<span className="mb1">Content</span>
												<input
													className="product-input"
													placeholder={`Content`}
													value={item.content}
													onChange={setListItems('content', index)}
													disabled={!editMode}
												/>
											</div>
										</>
									)}

									{item.type === RuleType.SPLIT && (
										<div className="product-input-cont width100 in-column items-start" style={{ padding: 0 }}>
											<span className="mb1">Separator</span>
											<input
												className="product-input"
												placeholder={`Separator`}
												value={item.content}
												onChange={setListItems('content', index)}
												disabled={!editMode}
											/>
										</div>
									)}

									{item.type === RuleType.REPLACE && (
										<>
											<div className="product-input-cont width100 in-column items-start" style={{ padding: 0 }}>
												<span className="mb1">From</span>
												<input
													className="product-input"
													placeholder={`From`}
													value={item.from}
													onChange={setListItems('from', index)}
													disabled={!editMode}
												/>
											</div>

											<div className="product-input-cont width100 in-column items-start" style={{ padding: 0 }}>
												<span className="mb1">To</span>
												<input
													className="product-input"
													placeholder={`to`}
													value={item.to}
													onChange={setListItems('to', index)}
													disabled={!editMode}
												/>
											</div>
										</>
									)}
								</div>
							))}
						</div>

						<div className="width100 justify-end">
							<button
								className="product-main-btn"
								onClick={async () => {
									const data = {
										name,
										list,
									};

									if (rule) {
										await RuleController.update(rule._id, data);

										setEditMode(false);
									} else {
										await RuleController.create(data);
									}

									onSuccess();
								}}
							>
								Save
							</button>
						</div>
					</>
				)}

				<Menu
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
				>
					<MenuItem
						onClick={async () => {
							handleClose();
							setEditMode(true);
						}}
					>
						Edit
					</MenuItem>
					<MenuItem
						onClick={async () => {
							if (window.confirm(`Are you sure you want to delete the item "${name}"`)) {
								await SchemeCategoryItemController.remove(rule?._id!);
								onSuccess();
							}

							handleClose();
						}}
					>
						Delete
					</MenuItem>
				</Menu>
			</div>
		);
	};

export default withRouter(Rules);
