import React, { useCallback, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import { ProductRequest, ProductQuery } from "types/product";
import CountrySelect from "../../../ui/country-select";
import PlatformSelect from "../../../ui/platform-select";
import CategorySelect from "../../../ui/category-select";
import BrandSelect from "../../../ui/brand-select";
import CatalogSelect from "../../../ui/catalog-select";
import RoleRequired from "../../../utils/role-required/RoleRequired";

export interface CatalogFilterProps {
	setCurrentConfig: any;
	currentConfig: ProductRequest;
}

export const CatalogFilter: React.FC<CatalogFilterProps> = ({
	currentConfig,
	setCurrentConfig,
}) => {
	const [open, setOpen] = useState(false);
	const [query, setQuery] = useState({} as Partial<ProductQuery>);

	const clearFilter = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		setQuery({});
	}, []);

	const applyFilter = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		setCurrentConfig(query, 'override');
		setOpen(false);
	}, [query, setOpen, setCurrentConfig]);

	return (
		<>
			<button
				onClick={() => setOpen(!open)}
				className="main-select-bi no-shadow ml2"
			>
				<img src="/icons/bi-filter.svg" alt="" />
				FILTER <span className="orange-text bold">({Object.keys(query).length})</span>
			</button>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={open}
				className="relative"
			>
				<div className="filter-bg" onClick={() => setOpen(false)} />
				<div className="filter-cont-position">
					<div className="filter-cont">
						<div className="filter-content justify-start">
							<div className="filter-header pt4">
								<div className="filter-header-title">
									Filter <span className="orange-text">({Object.keys(query).length})</span>
								</div>
								<div
									className="filter-header-reset"
									onClick={clearFilter}
								>
									Clear filters
								</div>
							</div>
							<div className="filter-content-cont mt2">
								<CountrySelect value={query.country || ''} setValue={country => setQuery(q => ({ ...q, country }))} />
								<PlatformSelect value={query.platform || ''} setValue={platform => setQuery(q => ({ ...q, platform }))} />
								{/* {currentConfig.categoryCode && <CategorySelect level={3} value={query.category || ''} setValue={category => setQuery(q => ({ ...q, category }))} code={currentConfig.categoryCode} />} */}
								 <BrandSelect category={currentConfig.category} value={query.brand || ''} setValue={brand => setQuery(q => ({ ...q, brand }))} />
								 <RoleRequired>
								 <CatalogSelect currentConfig={currentConfig} setCurrentConfig={setCurrentConfig}/>
								 </RoleRequired>
							</div>
						</div>
						<div className="filter-border mt4 mb2"></div>
						<div className="filter-buttons width100 m0">
							<button
								className="filter-apply-btn width80"
								onClick={applyFilter}
							>
								APPLY
							</button>
							<button
								className="filter-cancel-btn mb2"
								onClick={() => setOpen(false)}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</Backdrop>
		</>
	);
};
