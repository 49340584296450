import { Modal } from "components";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { getUnreadProductImportReport, readProductImportReport } from "../../../actions/user";
import ProductCountsStats from "../product-counts-stats";
import { queryClient } from "../../App";


interface Props {

}

const ProductImportUploadedReportModal: React.FC<Props> = () => {
    const { data } = useQuery("unreadProductImportReport", getUnreadProductImportReport, { refetchInterval: 10000 });
    const history = useHistory();
    const handleRead = useCallback(async () => {
        if (!data?._id) {
            return;
        }

        await readProductImportReport(data._id);
        queryClient.invalidateQueries("unreadProductImportReport");
    }, [data]);

    if (!data) {
        return <></>;
    }

    return (
        <Modal onModalClose={() => { }} isOpened={true}>
            <div style={{ maxWidth: "500px", textAlign: "start" }} className="table-modal-form-cont p4">
                <div className="table-modal-title-box m0">
                    <div className="import-title">Your product import is complete (&nbsp;ID:&nbsp;{data._id.slice(-6)}&nbsp;).</div>
                </div>
                <div className="table-modal-border" />
                <div className="email-text pt4 pb4">New products have been imported into your catalogue. The next step is to create offers by adding prices to your products in the sales channels.</div>

                <div className="width100 in-column align-start p4">
                    <div className="in-row pt4 pb4 width100 align-center justify-between">
                        <ProductCountsStats {...data} showTotal />
                    </div>
                </div>

                <div className="table-modal-border" />

                <div style={{ marginBottom: "0" }} className="table-modal-form-button-box width100">
                    <button
                        style={{ padding: "10px 15px" }}
                        className="pc-modal-spreadsheet-button pointer pc-modal-spreadsheet-button-text initial"
                        onClick={handleRead}
                    >
                        Create offers later
                    </button>

                    <button
                        className="table-modal-form-button initial"
                        onClick={async () => {
                            await handleRead();
                            history.push('/my/catalog/reports');
                        }}
                    >
                        Create offers now
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ProductImportUploadedReportModal;
