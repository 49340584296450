import React from "react";
import "./date-select.css";
import { DateTime } from "luxon";
import { IPaginatedRequest } from "../../../interfaces";

interface Props {
  date: number;
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
}

export const dates = [
  {
    label: "Yesterday",
    formatDate: DateTime.now().minus({ days: 1 }).startOf("day").valueOf(),
  },
  {
    label: "Last 24h",
    formatDate: DateTime.now().minus({ days: 1 }).valueOf(),
  },
  {
    label: "Today",
    formatDate: DateTime.now().startOf("day").valueOf(),
  },
  {
    label: "Last Week",
    formatDate: DateTime.now().minus({ week: 1 }).valueOf(),
  },
  {
    label: "This Month",
    formatDate: DateTime.now().startOf("month").valueOf(),
  },
  {
    label: "Last Month",
    formatDate: DateTime.now().minus({ month: 1 }).valueOf(),
  },
  {
    label: "Last Year",
    formatDate: DateTime.now().minus({ year: 1 }).valueOf(),
  },
];

export const DateSelect: React.FC<Props> = ({ date, setCurrentConfig }) => {
  return (
    <div className="date-select-container">
      <select
        required
        className="date-select"
        value={dates.find((x) => x.formatDate === date)?.label}
        onChange={(e) =>
          setCurrentConfig((conf: IPaginatedRequest) => ({
            ...conf,
            created: dates.find((x) => x.label === e.target.value)!.formatDate,
          }))
        }
      >
        {dates.map((item, index: number) => (
          <option key={index}>{item.label}</option>
        ))}
      </select>
    </div>
  );
};
