import axios from "axios";
import { Rule } from "types";
import { getHeaders } from "./user";

export async function find() {
  const res = await axios.get(`/rules`, getHeaders());
  return res.data as Rule[];
}

export async function create(item: Partial<Rule>) {
  await axios.post("/rule", item, getHeaders());
}

export async function update(id: string, item: Partial<Rule>) {
  await axios.put(`/rule/${id}`, item, getHeaders());
}

export async function remove(id: string) {
  await axios.delete(`/rule/${id}`, getHeaders());
}
