import axios from "axios";
import { IZeoosOrder } from "../interfaces";
import { getHeaders } from "./user";
import FileDownload from "js-file-download";

export const listOrders = async (body: any) => {
	const search = new URLSearchParams(body).toString();
	const res = await axios.get(`/order/list?${search}`, getHeaders());
	return res.data.data;
};

export const getOrder = async (id: string) => {
	const res = await axios.get(`/order/${id}`, getHeaders());
	return res.data.data as IZeoosOrder;
};

export const sendPurchaseOrder = async (order: string, shipping: string) => {
	const res = await axios.post(
		`/order/send-purchase`,
		{ order, shipping },
		getHeaders()
	);
	return res.data;
};

export const confirmPurchaseOrder = async (purchase: string) => {
	const res = await axios.post(
		`/order/confirm-purchase`,
		{ purchase },
		getHeaders()
	);
	return res.data;
};

interface IRejectObj {
	id: number;
	reason: string;
}

export const rejectPurchaseOrder = async ({ id, reason }: IRejectObj) => {
	const res = await axios.post(
		`/order/reject-order/${id}`,
		{ reason },
		getHeaders()
	);
	return res.data;
};

export const refundOrder = async ({ id, reason }: IRejectObj) => {
	const res = await axios.post(
		`/order/refund/${id}`,
		{ reason },
		getHeaders()
	);
	return res.data;
};

export const schedulePickup = async (body: any) => {
	const res = await axios.post(`/order/schedule-pickup`, body, getHeaders());
	return res.data;
};

export const createCustomerInvoice = async (
	order: string,
	shipping: string
) => {
	const res = await axios.post(
		`/order/create-invoice`,
		{ order, shipping },
		getHeaders()
	);
	return res.data;
};

export const saveCustomerInvoice = async (invoice: string) => {
	const res = await axios.post(
		`/order/save-invoice`,
		{ invoice },
		getHeaders()
	);
	return res.data;
};

export const downloadCustomerInvoice = async (invoice: string) => {
	const res = await axios.post(
		`/order/download-invoice`,
		{ invoice },
		getHeaders()
	);
	return res.data;
};

export const downloadShippingLabel = async (reference: string) => {
	const res = await axios.post(
		`/order/download-shipping-label`,
		{ reference },
		getHeaders()
	);
	return res.data;
};

export const downloadPurchaseOrder = async (po_name: string) => {
	const res = await axios.post(
		`/order/download-purchase-order`,
		{ po_name },
		getHeaders()
	);
	return res.data;
};

export async function downloadOrderBulkTemplate() {
  const res = await axios.get(`/order/download-bulk-template-file`, {
    ...getHeaders(),
    responseType: "blob",
  });

  return FileDownload(res.data, "orders_bulk_update.xlsx");
};

export const massUpdateOrders = async (
  file: File,
  reportId?: string
) => {
  const formData = new FormData();
  formData.append("file", file);
  // if (reportId) formData.append("report", reportId);
  const res = await axios.post(
    `/order/bulk-update`,
    formData,
    getHeaders()
  );
  return res.data;
};

export const massUploadInvoices = async (
  file: File,
) => {
  const formData = new FormData();
  formData.append("file", file);
  // if (reportId) formData.append("report", reportId);
  const res = await axios.post(
    `/order/bulk-invoice-upload`,
    formData,
    getHeaders()
  );
  return res.data;
};

export const updateShippingInfo = async (body: any) => {
	const res = await axios.post(`/order/shipping-info/${body.order_id}`, body, getHeaders());
	return res.data;
};

export const singleUploadInvoice = async (
  file: File,
  order_id: string,
  seller_id: string
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("order_id", order_id);
  formData.append("seller_id", seller_id);
  const res = await axios.post(
    `/order/single-invoice-upload`,
    formData,
    getHeaders()
  );
  return res.data;
};
