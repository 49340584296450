import React, { forwardRef, useEffect, useState } from "react";
import "./businessIntelligence.css";
import {
  getOrders,
  getCombinedProductsBI,
  generateProductsBI,
} from "../../../actions/bi";
import { DateButtons } from "./DateButtons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ICombinedOrders, ICombinedProduct } from "../../../interfaces";
import moment from "moment";

interface Props {
  getOrdersCallback: (data: ICombinedOrders) => void;
  getProductsCallback: (data: ICombinedProduct[]) => void;
  setLoading: (loading: boolean) => void;
}

// TODO: This component doesn't seem to be used anywhere
export const DatePickers: React.FC<Props> = ({
  getOrdersCallback,
  getProductsCallback,
  setLoading,
}) => {
  const [date, setDate] = useState<string>("This Month");

  const [from, setDateFrom] = useState<Date | number>(
    Date.parse(`${moment().startOf("months")}`)
  );

  const [to, setDateTo] = useState<Date | number>(new Date().valueOf());

  useEffect(() => {
    async function initializeState() {
      setLoading(true);
      const ordersData = await getOrders({
        from,
        to,
      });
      getOrdersCallback(ordersData);
      setLoading(false);
    }
    initializeState();
    // eslint-disable-next-line
  }, [from, to]);

  useEffect(() => {
    async function fetchProducts() {
      setLoading(true);
      let productsData = await getCombinedProductsBI();
      if (productsData.data.products.length === 0) {
        productsData = await generateProductsBI();
      }
      getProductsCallback(productsData.data.products);
      setLoading(false);
    }
    fetchProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="bi-date-cont">
        <DateButtons
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          date={date}
          setDate={setDate}
        />
        <div className="bi__dates">
          <div className="bi_dates-box">
            <div className="bi__labels">From</div>
            <div className="bi__calendar">
              <div>
                <DatePicker
                  selected={from as Date}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: any) => {
                    setDateFrom(Date.parse(date));
                    setDate("Selecione período");
                  }}
                  customInput={<ExampleCustomInput />}
                />
              </div>
              <div className="bi__calendar_icon">
                <img src="/icons/calendar.svg" alt="calendar" />
              </div>
            </div>
          </div>
          <div className="bi_dates-box">
            <div className="bi__labels">To</div>
            <div className="bi__calendar">
              <div>
                <DatePicker
                  selected={to as Date}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: any) => {
                    setDateTo(Date.parse(date));
                    setDate("Selecione período");
                  }}
                  customInput={<ExampleCustomInput />}
                />
              </div>
              <div className="bi__calendar_icon">
                <img src="/icons/calendar.svg" alt="calendar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
  <div className="bi__calendar_input" onClick={onClick} ref={ref}>
    {value}
  </div>
));
