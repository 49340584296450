import React, { useEffect } from 'react';
import useNotifications from '../../../hooks/useNotifications';
import { NotificationPopup } from 'components';
import { closeNotification, readNotification } from '../../../actions/notifications';

interface Props {

}

const NotificationAlerts: React.FC<Props> = () => {
    const topbar = useNotifications({ page: 1, perPage: 1, alertType: 'topbar', isClosed: false });
    const popup = useNotifications({ page: 1, perPage: 1, alertType: 'popup', wasRead: false });

    useEffect(() => {
        try {
            const element = document.querySelector('header')!;
            const drawer = document.querySelector('#drawer')!.children[0];
            const content = document.querySelector('.main-container')!;

            if (topbar.data?.data.length) {
                // @ts-ignore
                element.style.top = '40px';

                // @ts-ignore
                drawer.style.top = '40px';

                // @ts-ignore
                content.style.marginTop = '35px';
            } else {
                // @ts-ignore
                element.style.top = '0px';

                // @ts-ignore
                drawer.style.top = '0px';

                // @ts-ignore
                content.style.marginTop = '-5px';
            }
        } catch (error) {
            console.error(error);
        }
    }, [topbar.data?.data]);

    return (
        <>
            {topbar.data?.data.map(n => (
                <div style={{
                    top: 0,
                    left: 0,
                    background: '#F19F00',
                    position: 'fixed',
                    height: 40,
                    width: '100vw',
                    zIndex: 100000,
                    fontWeight: 'bold',
                    fontSize: 14
                }} className='justify-center items-center align-center in-row'>
                    <span>{n.lineText}</span>

                    {n.isClosable && (
                        <img
                            alt=''
                            src='/icons/close-outline.svg'
                            className='pointer ml1'
                            onClick={async () => {
                                try {
                                    await closeNotification(n._id);
                                    topbar.invalidate();
                                } catch (error) {
                                    console.error(error);
                                }
                            }}
                        />
                    )}
                </div>
            ))}

            {popup.data?.data.map(n => (
                <NotificationPopup
                    key={n._id}
                    open={true}
                    title={n.title}
                    description={n.data}
                    onClose={async () => {
                        try {
                            await readNotification(n._id);
                            popup.invalidate();
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                />
            ))}
        </>
    );
};

export default NotificationAlerts;
