import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { IPaginatedRequest } from "../../../interfaces";
import { getPaginatedRequests } from "../../../actions/notifications";
import Preloader from "../../ui/preloader/Preloader";
import { RNFilter } from "./RNFilter";
import useActiveVendors from "../../../hooks/useActiveVendors";
import { RequestsNotiesTable } from "./RequestsNotiesTable";
import queryString from "query-string";
import { motion, AnimatePresence } from "framer-motion";

export const RequestsNoties = () => {
  const [selectedIds, setSelectedIds] = useState([] as string[]);
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    status: "All Statuses",
    type: "All Types",
    vendor: "All Vendors",
    user: "All Users",
  });

  const vendors = useActiveVendors();

  const rqKey = useMemo(() => {
    return `requests?${queryString.stringify(currentConfig)}`;
  }, [currentConfig]);

  const { isLoading, data: requests } = useQuery(rqKey, () =>
    getPaginatedRequests(currentConfig)
  );

  const onChangePage = useCallback(async (config: IPaginatedRequest) => {
    setCurrentConfig(config);
  }, []);

  if (isLoading) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="main-body">
      <div className="main-container">
        <div className="table-title-cont">
          <AnimatePresence>
            {!!selectedIds.length && (
              <div className="width100 mb2 mt-15 animation-block">
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  style={{ overflow: "hidden" }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="inv-dropdown-cont justify-between">
                    <div className="in-row align-center">
                      <div className="inv-dropdown-text bold">
                        {selectedIds.length} selected notifications.
                      </div>
                      <div
                        className="pointer underline fw1 ft1 ml1"
                        onClick={() => setSelectedIds([])}
                      >
                        Clear
                      </div>
                    </div>
                    <div className="in-row">
                      <div className="req-popup-button in-row align-center">
                        <img
                          className="mr1"
                          width="22px"
                          height="22px"
                          src="/icons/white-reject.svg"
                          alt=""
                        />
                        <div>Reject All</div>
                      </div>
                      <div className="req-popup-button in-row align-center ml3">
                        <img
                          className="mr1"
                          width="22px"
                          height="22px"
                          src="/icons/white-accept.svg"
                          alt=""
                        />
                        <div>Accept All</div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            )}
          </AnimatePresence>
          <div className="mplc-filter-cont">
            <div className="gestao-select-cont">
              <RNFilter
                requests={requests.data}
                setCurrentConfig={setCurrentConfig}
                currentConfig={currentConfig}
                vendors={vendors.data}
              />
            </div>
          </div>
        </div>
        <RequestsNotiesTable
          requests={requests}
          refetch={onChangePage}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
        />
        <div className="table-title-cont" />
      </div>
    </div>
  );
};
