import React, { useEffect, useMemo, useState } from "react";
import "./gestao.css";
import "../tables.css";
import { Modal } from "../../../ui/modal/Modal";
import { getAttributesBreakdown, getPreviewBreakdown, updateProductPrice } from "../../../../actions/marketplace";
import { toast } from "react-toastify";
import { IMarketplace } from "../../../../interfaces";
import Preloader from "../../../ui/preloader/Preloader";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { rankingZeoosWinner } from "./TableGestao";
import SellerPricingBreakdown from "./SellerPricingBreakdown";
import { queryClient } from "../../../App";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { ModalOffer } from "types/offer";
import { updateOffer, useSellerOffer } from "../../../../actions/v2/offer";

interface Props {
	modal: boolean;
	setModal: (modal: boolean) => void;
	product: ModalOffer;
	setProduct: React.Dispatch<React.SetStateAction<any>>;
	onUpdateCallback: (data: any) => void;
	productMarketplace: IMarketplace;
	vendors?: any;
	seller?: number;
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		className="width100"
		expandIcon={<img src="/icons/inv-modal-arrow.svg" alt="" />}
		{...props}
	/>
))(({ theme }) => ({
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(180deg)",
		marginRight: theme.spacing(1),
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
}));

export function toStringConverter(num: number) {
	return num?.toLocaleString("de-DE", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
}

export function convertPrice(price: any) {
	return parseFloat(String(price).replace(",", ".")).toLocaleString("de-DE", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
}

const formatMoney = (number: number) => {
	return new Intl.NumberFormat("de", {
		style: "currency",
		currency: "EUR",
	}).format(!number ? 0 : number);
};

export const UpdateGestao: React.FC<Props> = ({
	modal,
	setModal,
	product,
	onUpdateCallback,
	productMarketplace,
	vendors,
	seller,
	setProduct,
}) => {
	// const productInfo = product.marketplaces?.[productMarketplace.zeoosName] || {};

	const sellerOffer = useSellerOffer(product.sku, { platform: product.platformId, seller: product.seller! });

	const [loading, setLoading] = useState<boolean>(true);
	const [expanded, setExpanded] = React.useState<string | false>(
    sellerOffer.data?.promotionalPrice ? "panel2" : "panel1"
  );
	const [editPvpPrice, setEditPvpPrice] = useState(false);
	const [editPurchasePrice, setEditPurchasePrice] = useState(false);
	const [attBreak, setAttBreak] = useState<any | boolean>(false);
	const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [mode, setMode] = useState<string>(sellerOffer.data?.promotionalPrice ? "PROMO" : "PVP");

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};

	// const winner = rankingZeoosWinner(productInfo, seller);
	const vendor = vendors.find((v: any) => v._id === seller);
	const newPriceBreakdown = useMemo(() => sellerOffer.data?.price, [sellerOffer.data?.price]);
  const promotionalBreakdown = useMemo(() => sellerOffer.data?.promotionalPrice, [sellerOffer.data?.promotionalPrice]);

	const [pvpPrice, setPvpPrice] = useState<number>(newPriceBreakdown?.pvpFinal!);
	const [purchasePrice, setPurchasePrice] = useState<number>(
		newPriceBreakdown?.purchasePrice!
	);

  const actualPrice = promotionalBreakdown || newPriceBreakdown;

	useEffect(() => {
		async function initAtt() {
			const att = await getAttributesBreakdown({
				sku: product.sku,
				seller,
				zeoosName: productMarketplace.zeoosName
			});
			setLoading(false);

			if (att) {
				setAttBreak(att);
			}
		}
		if (!newPriceBreakdown || !attBreak) {
			initAtt();
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {    
    if (!sellerOffer.isLoading) {
      setLoading(false);
      setExpanded(sellerOffer.data?.promotionalPrice ? "panel2" : "panel1");          
    }		
		// eslint-disable-next-line
	}, [
		product.sku,
		productMarketplace.zeoosName,
		// product.weight,
		productMarketplace.country,
    sellerOffer.isLoading
	]);

	// eslint-disable-next-line
	const onSubmitHandler = async (e: React.MouseEvent) => {
		e.preventDefault();

		if (!product.seller) {
			toast.error("Seller is not defined");
			return;
		}

		const update = {
			platform: product.platformId,
			sku: product.sku,
			seller: product.seller,
			pvp: undefined as number | undefined,
			cost: undefined as number | undefined,
      promo: undefined as number | undefined,
		};

		if (editPvpPrice) {
			update.pvp = pvpPrice;
      // if (vendor?.pricingType === 'd2c') {
      //   update.cost = purchasePrice;
      // }

      if (mode === "PROMO") {
        update.promo = pvpPrice;
        delete update.pvp;
      }
		}

		if (editPurchasePrice) {
			update.cost = purchasePrice;
      if (vendor?.pricingType === 'd2c') {
        update.pvp = pvpPrice;
      }
		}

		const resp = await updateOffer(update);

		if (resp.success) {
			// const breakdown = await getPreviewBreakdown({ ...update, pricingType: vendor?.pricingType, breakdown: newPriceBreakdown });

			// setProduct((prev: any) => ({
			// 	...prev, marketplaces: {
			// 		...prev.marketplaces,
			// 		[update.zeoosName]: {
			// 			...prev.marketplaces[update.zeoosName],
			// 			rankingZeoos: {
			// 				...prev.marketplaces[update.zeoosName].rankingZeoos,
			// 				[update.seller]: {
			// 					...prev.marketplaces[update.zeoosName].rankingZeoos[update.seller],
			// 					breakdown
			// 				}
			// 			}
			// 		}
			// 	}
			// }));
			toast.success(resp.message);
			setTimeout(() => {
				onUpdateCallback(update);
				queryClient.invalidateQueries("offers/counts");
			}, 5000);
			queryClient.invalidateQueries(`/v2/my/product/${product.sku}/offers`);
			sellerOffer.invalidate();
		} else {
			toast.error(resp.message);
		}

		setModal(false);
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return loading ? (
		<div className="center-loader">
			<Preloader />
		</div>
	) : (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div style={{ maxWidth: "520px" }} className="table-modal-cont">
				<div className="table-modal-title-box width100 pb1 m0">
					<div className="mapping-modal-title-text">
						Price on {productMarketplace.zeoosName}
					</div>
				</div>
				<div className="table-modal-border m0"></div>
				<div className="in-row align-start mb2 mt2">
					<div
						style={{ width: "122px", height: "120px" }}
						className="int-set-match-logo m0"
					>
						<img
							width="120px"
							height="120px"
							className="pb1"
							src={product.image}
							alt=""
						/>
					</div>
					<div className="ml2 int-text in-column text-aligns align-start justify-start">
						<span style={{ lineHeight: "20px" }}>{product.name}</span>
						<div className="in-row mt2 align-center justify-start width100">
							<span className="int-text mr4">
								<b className="mr1">SKU:</b>
								{product.sku}
							</span>
							<span className="int-text">
								<b className="mr1">EAN:</b>
								{product.ean}
							</span>
						</div>
						<div className="in-row align-center justify-start width100">
							{!product.platformId && (
								<div className="int-text in-column text-aligns align-start justify-start">
									<span
										className="in-row mt2 align-center justify-start width100"
										style={{ color: "#E80000", fontWeight: "bold" }}
									>
										Product not integrated yet
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
				<div
					style={{ background: "#DDEFDD", flexDirection: "column" }}
					className="inv-title-modal1-cont"
				>
					<div className="in-row align-center justify-start width100 mb1">
						<span className="int-text mr4">
							<b className="mr1">Seller:</b>
							{vendor?.displayName || vendor?.name}
						</span>
					</div>
					<div className="in-row align-center justify-between width100 mb1">
						<span className="int-text mr4">
							<b className="mr1">PVP:</b>
							{formatMoney(actualPrice?.pvpFinal!)}
						</span>
						<span className="int-text mr4">
							<b className="mr1">Stock:</b>
							{/* @ts-ignore */}
							{sellerOffer.data?.stock || 0}
						</span>
					</div>
					<div className="in-row align-center justify-start width100 mb1">
						<span className="int-text mr4">
							<b className="mr1">Freight:</b>
							{formatMoney(
								vendor?.pricingType === "fullBreakdown"
									? 0
									: actualPrice?.freightFinal!
							)}
						</span>
					</div>
					<div className="in-row align-center justify-start width100">
						<span className="int-text mr4">
							<b className="mr1">Checkout Price:</b>
							{formatMoney(
								actualPrice?.pvpFinal! +
								(vendor?.pricingType === "fullBreakdown"
									? 0
									: actualPrice?.freightFinal!)
							)}
						</span>
					</div>
				</div>
        {promotionalBreakdown &&
        <Accordion
          style={{ margin: "0 !important" }}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="width100 mt3 border0 no-shadow"
        >
          <AccordionSummary
            style={{
              margin: "0 !important",
              height: "auton !important",
            }}
            className="m0 inv-acc-text p0"
            aria-controls="panel2-content"
            id="panel2-header"
          >
            {expanded === "panel2" ? (
              <>Hide Promo Breakdown</>
            ) : (
              <>Show Promo Breakdown</>
            )}
            <div className="opacity5 hide">Minimum PVP limit: 12,34 €</div>
          </AccordionSummary>
          <AccordionDetails className="p0">
            <form className="table-modal-form-cont">
              <div className="width100">
                <div className="width100 in-row align-center ft2">
                  <div className="width60 justify-end bold uppercase-text opacity5">
                    {/* {title} */}
                  </div>
                </div>
                <SellerPricingBreakdown
                  breakdown={promotionalBreakdown}
                  pricingType={vendor?.pricingType}
                  editPvpPrice={editPvpPrice}
                  setEditPvpPrice={setEditPvpPrice}
                  editPurchasePrice={editPurchasePrice}
                  setEditPurchasePrice={setEditPurchasePrice}
                  setPvpPrice={setPvpPrice}
                  setPurchasePrice={setPurchasePrice}
                  attBreak={attBreak}
                  mode="PROMO"
                />
              </div>
              <div className="table-modal-border"></div>
              <div
                className={`table-modal-form-button-box mb-none ${editPvpPrice || editPurchasePrice
                  ? "justify-between align-center"
                  : "justify-center"
                  }`}
              >
                {editPvpPrice || editPurchasePrice ? (
                  <>
                    <div
                      onClick={() => {
                        setEditPvpPrice(false);
                        setEditPurchasePrice(false);
                      }}
                      className="grid-modal-close-btn mt-none"
                    >
                      Cancel
                    </div>
                    <div className="table-modal-form-button pointer"
                      onClick={() => {
                        setMode("PROMO");
                        setConfirmDialog(true);
                      }}
                    >
                      Save
                    </div>
                  </>
                ) : (
                  <div
                    onClick={onModalClose}
                    className="grid-modal-close-btn mt0 btn-border border5 pt1 pb1 pr4 pl4"
                  >
                    Close
                  </div>
                )}
              </div>
            </form>
          </AccordionDetails>
        </Accordion>
        }
				<Accordion
					style={{ margin: "0 !important" }}
					expanded={expanded === "panel1"}
					onChange={handleChange("panel1")}
					className="width100 m0 border0 no-shadow"
				>
					<AccordionSummary
						style={{
							margin: "0 !important",
							height: "auton !important",
						}}
						className="m0 inv-acc-text p0"
						aria-controls="panel1-content"
						id="panel1-header"
					>
						{expanded === "panel1" ? (
							<>Hide Pricing Breakdown</>
						) : (
							<>Show Pricing Breakdown</>
						)}
						<div className="opacity5 hide">Minimum PVP limit: 12,34 €</div>
					</AccordionSummary>
					<AccordionDetails className="p0">
						<form className="table-modal-form-cont">
							<div className="width100">
								<div className="width100 in-row align-center ft2">
									<div className="width60 justify-end bold uppercase-text opacity5">
										{/* {title} */}
									</div>
								</div>
								<SellerPricingBreakdown
									breakdown={newPriceBreakdown}
									pricingType={vendor?.pricingType}
									editPvpPrice={editPvpPrice}
									setEditPvpPrice={setEditPvpPrice}
									editPurchasePrice={editPurchasePrice}
									setEditPurchasePrice={setEditPurchasePrice}
									setPvpPrice={setPvpPrice}
									setPurchasePrice={setPurchasePrice}
									attBreak={attBreak}
                  mode="PVP"
								/>
							</div>
							<div className="table-modal-border"></div>
							<div
								className={`table-modal-form-button-box mb-none ${editPvpPrice || editPurchasePrice
									? "justify-between align-center"
									: "justify-center"
									}`}
							>
								{editPvpPrice || editPurchasePrice ? (
									<>
										<div
											onClick={() => {
												setEditPvpPrice(false);
												setEditPurchasePrice(false);
											}}
											className="grid-modal-close-btn mt-none"
										>
											Cancel
										</div>
										<div className="table-modal-form-button pointer"
											onClick={() => {
                        setMode("PVP");
                        setConfirmDialog(true);
                      }}
										>
											Save
										</div>
									</>
								) : (
									<div
										onClick={onModalClose}
										className="grid-modal-close-btn mt0 btn-border border5 pt1 pb1 pr4 pl4"
									>
										Close
									</div>
								)}
							</div>
						</form>
					</AccordionDetails>
				</Accordion>
			</div>
			<Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)} className="confirm-dialog">
				<DialogContent>
					<DialogContentText>
						<strong>
							{/* {editPvpPrice && <>This action will change your cost price.<br /></>} */}
							Are you sure you want to set 
              {editPvpPrice ? ` the ${mode === "PROMO" ? mode : ''} PVP ` : "your cost price "}
							to {editPvpPrice ? formatMoney(pvpPrice) : formatMoney(purchasePrice)}?
						</strong>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button className="no" onClick={() => setConfirmDialog(false)} >
						Cancel
					</Button>
					<Button className="table-modal-form-button"
						variant="contained"
						onClick={(e) => {
							onSubmitHandler(e);
							setConfirmDialog(false);
						}}
					>
						Yes, save the new price
					</Button>
				</DialogActions>
			</Dialog>
		</Modal>
	);
};
