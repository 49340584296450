import React, { useCallback, useMemo, useState } from "react";
import SellersNav from "../../../ui/sellers-nav/SellersNav";
import Paginator from "../../../ui/pagination";
import { IPaginatedRequest } from "../../../../interfaces";
import DemoRequestTable from "./DemoRequestTable";
import { useDebouncedValue } from "@mantine/hooks";
import queryString from "query-string";
import { useQuery } from "react-query";
import { getPaginatedDemoRequests } from "../../../../actions/vendor";
import Preloader from "../../../ui/preloader/Preloader";
import CompanyInformation from "./CompanyInformation";
import { IZeoosDemoRequest } from "types";
import Interactions from "./Interactions";

export default function DemoRequest() {
	const [modal, setModal] = useState<boolean>(false);
	const [modalInteraction, setModalInteraction] = useState<boolean>(false);
	const [selectedId, setSelectedId] = useState<string>("");
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const rqKeyDemoRequests = useMemo(() => {
		return `/zeoosCustomer/demo-requests?${queryString.stringify(
			debouncedConfig
		)}`;
	}, [debouncedConfig]);

	const { isLoading: loadDemeRequests, data: demoRequests } = useQuery(
		rqKeyDemoRequests,
		() => getPaginatedDemoRequests(debouncedConfig)
	);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	if (loadDemeRequests) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="s-users-management-container">
					<div className="table-main-cont">
						<div className="table-title-cont p0 mt4 mb4">
							<div className="mplc-filter-cont width100">
								<SellersNav />
							</div>
						</div>
						<div className="table-main-title-cont">
							<div className="table-main-title-search-value-cont">
								<div className="search-box">
									<div className="search_form">
										<div className="table-search-box">
											<form
												className="table-search-form"
												onSubmit={(e) => e.preventDefault()}
											>
												<input
													className="table-search-input"
													placeholder="Search by company name"
													onChange={(e) =>
														setCurrentConfig((conf: IPaginatedRequest) => ({
															...conf,
															search: e.target.value,
														}))
													}
													value={currentConfig.search}
												/>
												<img
													className="table-search-image"
													src="/icons/search.svg"
													width="25px;"
													alt=""
												/>
											</form>
										</div>
									</div>
									<div className="table-value">
										<span className="table-main-title-text">
											{" "}
											{demoRequests?.total}{" "}
											{demoRequests?.total > 1
												? "Demo requests"
												: "Demo request"}
										</span>
									</div>
								</div>
							</div>
							<div className="marketplace-pagination">
								<Paginator data={demoRequests} refetch={onChangePage as any} />
							</div>
						</div>
						<DemoRequestTable
							demoRequests={demoRequests}
							rqKeyDemoRequests={rqKeyDemoRequests}
							setModal={setModal}
							setModalInteraction={setModalInteraction}
							setSelectedId={setSelectedId}
						/>
					</div>
				</div>
			</div>
			{modal && (
				<CompanyInformation
					modal={modal}
					setModal={setModal}
					info={demoRequests?.data.find(
						(x: IZeoosDemoRequest) => x._id === selectedId
					)}
				/>
			)}
			{modalInteraction && (
				<Interactions
					modal={modalInteraction}
					setModal={setModalInteraction}
					rqKeyDemoRequests={rqKeyDemoRequests}
					selectedDemoRequest={demoRequests?.data.find(
						(x: IZeoosDemoRequest) => x._id === selectedId
					)}
				/>
			)}
		</div>
	);
}
