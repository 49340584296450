import { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Modal } from "../../../ui/modal/Modal";
import * as reactQuery from "react-query";
import { getOfferCounts } from "../../../../actions/user";
import YouTube, { YouTubeProps } from 'react-youtube';
import { formatNumber } from "../../../../utils";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		color: '#fff',
		maxWidth: 200,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
		background: "#454545",
		zIndex: 1000000,
	},
}));


type Props = {
	setCurrentConfig: any;
};

const CardButtons: React.FC<Props> = ({
	setCurrentConfig
}) => {
	// const [modal1, setModal1] = useState<boolean>(false);
	const [modal, setModal] = useState<boolean>(false);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	const onPlayerReady: YouTubeProps['onReady'] = (event) => {
		// access to player in all event handlers via event.target
		// event.target.pauseVideo();
	};

	const opts: YouTubeProps['opts'] = {
		height: '400',
		width: '640',
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 1,
		},
	};

	// const onModalClose1 = (e: React.MouseEvent) => {
	// 	e.stopPropagation();
	// 	setModal1(false);
	// };
	const counts = reactQuery.useQuery(`offers/counts`, getOfferCounts, { refetchOnWindowFocus: false });
	const history = useHistory();
	const handleNavigate = useCallback((tab: string) => () => {
		console.log(`/offers/${tab}${window.location.search}`);
		setCurrentConfig({ page: '1', perPage: '10', type: tab });
		history.push(`/offers/${tab}${window.location.search}`);

	}, [history]);


	return (
		<div className="card-btns-cont">
			<CardBtnComponent
				label="All my offers"
				productQty={counts.data?.all as any}
				onClick={handleNavigate('all')}
				tab="all"
				discount="70,0%"
				discountLastWeek="+5,4%"
				tooltip="These are all offers for your products that match the available sales channels."
			/>

			<CardBtnComponent
				label="Offers without price"
				productQty={counts.data?.withoutPrice as any}
				onClick={handleNavigate('without-price')}
				tab="without-price"
				discount="70,0%"
				discountLastWeek="+5,4%"
				tooltip="These are your product offerings with stock but no price."
			/>

			{/* <NavLink
				to="/offers/non-competitive" style={{ width: "200px" }}
				className="card-btn-cont"
				activeClassName="card-btn-cont-active"
			>
				<div className="card-btn-percentage-cont width100 justify-between">
					<div className="card-btn-label">
						Non-compettive offers
					</div>
					<HtmlTooltip className="pointer" title="These are your offers that will not be listed in sales channels because they are not competitive." placement="bottom-start">
						<img src="/icons/tooltip-black.svg" alt="" />
					</HtmlTooltip>
				</div>

				<div className="card-btn-qty">
					{counts.data?.nonCompetitive}
				</div>

				<div className="card-btn-percentage-cont width100 justify-between">
					<div style={{ background: "transparent", color: "#6BBE4E" }}
						className="card-btn-percentage in-row align-center"
					>
						<img className="mr1" src="/icons/green-arrow-offer.svg" alt="" />
						+5,4%
					</div>

					<div
						className="card-btn-dynamics underline"
					>
						View report
					</div>
				</div>
			</NavLink> */}

			<div onClick={() => {
				setModal(true);
			}} className="card-btn-cont">
				<div className="card-btn-watch-title">How Offers module works</div>
				<div className="card-btn-watch-btn">
					<img src="/icons/ph_play-fill.svg" alt="" />
					<div className="card-btn-watch">watch</div>
				</div>
			</div>
			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<YouTube videoId="5lMdiz6sZCI" opts={opts} onReady={onPlayerReady} />
				</Modal>
			)}
		</div>
	);
};

interface ICardBtnComponent {
	label: string;
	productQty: string | number;
	// percentage: string;
	// dynamics: string;
	onClick: () => void;
	discount: string;
	discountLastWeek: string;
	tab: string;
	tooltip?: string;
}

export const CardBtnComponent: React.FC<ICardBtnComponent> = ({
	label,
	productQty,
	// percentage,
	// dynamics,
	onClick,
	tab,
	discount,
	discountLastWeek,
	tooltip,
}) => {
	const params = useParams<{ tab: string; }>();

	const className = useMemo(() => {
		return `card-btn-cont ${params.tab === tab ? 'card-btn-cont-active' : ''}`;
	}, [params.tab, tab]);

	return (
		<div
			onClick={onClick}
			className={className}
			style={tooltip ? { width: 200 } : {}}
		>
			<div className="card-btn-percentage-cont width100 justify-between">
				<div className="card-btn-label">
					{label}
				</div>

				{tooltip && (
					<Tooltip className="pointer" title={tooltip}>
						<img src="/icons/tooltip-black.svg" alt="" />
					</Tooltip>
				)}
			</div>
			{/* <div className="card-btn-label">{label}</div> */}

			<div className="card-btn-qty">{formatNumber(+productQty)}</div>

			{/* <div className="card-brn-discount">
		  <p>{discount}</p>
		  <span>{discountLastWeek} last week</span>
		</div> */}

			{/* <div className="card-btn-percentage-cont">
				  <div
					  className="card-btn-percentage"
				  >
					  {percentage}%
				  </div>
  
				  <div
					  className="card-btn-dynamics"
				  >
					  {dynamics}% last week
				  </div>
			  </div> */}
		</div>
	);
};

export default CardButtons

