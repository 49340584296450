import React, { memo, useMemo, useState } from "react";
import { IPaginatedRequest, IBatchList } from "../../../../interfaces";
import FilterSelect from "../../../ui/select/FilterSelect";
import { useDebouncedValue } from "@mantine/hooks";
import { useQuery } from "react-query";
import queryString from "query-string";
import { getPaginatedBatchList } from "../../../../actions/batch";
import * as _ from "lodash";
import { BatchStatus } from "../../sync/batch-list/BatchList";
import { NavLink } from "react-router-dom";
import BatchListTable from "../../sync/batch-list/BatchListTable";

const CatalogBatchList: React.FC = () => {
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		status: "All statuses",
		user: "All users",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const rqKey = useMemo(() => {
		return `/batch-items?${queryString.stringify(debouncedConfig)}`;
	}, [debouncedConfig]);

	const { isLoading, data: batchList } = useQuery(rqKey, async () => {
		const batchList = await getPaginatedBatchList(debouncedConfig);
		return batchList;
	});

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont p0">
					<div className="mapping-title-border"></div>
					<div className="table-title-cont">
						<NavLink to="/my/catalog/integrated" className="single-billing-nav pointer" style={{ paddingTop: 0 }}>
							<img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
							<div style={{ color: "#55718a" }} className="table-subtext bold pt1 pl2">back to Catalog</div>
						</NavLink>
						<div className="mplc-filter-cont width100">
							<div className="mplc-filter">
								<div className="filter-text">Filter by:</div>
								<FilterSelect
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
										setCurrentConfig((conf: IPaginatedRequest) => ({
											...conf,
											status: e.target.value,
										}))
									}
									value={currentConfig.status}
									data={["All statuses", ...Object.values(BatchStatus)]}
								/>
							</div>
						</div>
					</div>
					<BatchListTable
						batchList={batchList}
						setCurrentConfig={setCurrentConfig}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
};

export default memo(CatalogBatchList);
