import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Paginator } from "../../../ui/pagination/Paginator";
import { convertPrice } from "../../tables/gestao/UpdateGestao";
import { uniq } from "lodash";
import { selectedBillingsAtom } from "../Billing";
import { useAtom } from "jotai";
import { totalAmount, totalProducts } from "../treasury/TreasuryTable";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { createPaymentBatch } from "../../../../actions/billings";
import { M } from "ts-toolbelt";

interface Props {
	billings: any;
  setBatch: (batch: boolean) => void;
	// setBillingsWindow: (billingWindow: boolean) => void;
	// setBillingsId: (id: number) => void;
	// setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	// currentConfig: IPaginatedRequest;
}

export const CreatePayment: React.FC<Props> = ({ 
  billings,
  setBatch
}) => {
  const [paginatedData, setPaginatedData] = useState<number>();
  const [limit, setLimit] = useState<number>(50);

  const [name, setName] = useState<string>('');
  const [selectedBillings, setSelectedBillings] = useAtom(selectedBillingsAtom);

  const totalPayment = (billings: any) => {
    return billings.reduce((total: number, curr: any) => total + curr.amount, 0)
  }

  const sellerBillings = (seller: string) => {
    return billings.filter((b: any) => b.seller === seller);
  }

  const sellers = uniq(billings.map((b: any) => b.seller));

  const removeBilling = (id: string) => {
    setSelectedBillings((prev:any) => {
      if (prev.find((x:any) => x._id === id)) {
        return prev.filter((x:any) => x._id !== id);
      }
      return [...prev];
    });
  }

  const removeSeller = (seller: string) => {
    setSelectedBillings((prev:any) => {
      if (prev.find((x:any) => x.seller === seller)) {
        return prev.filter((x:any) => x.seller !== seller);
      }
      return [...prev];
    });
  }

  const handleCreatePayment = async (e: any) => {
    e.target.disabled = true;
    e.target.className=`${e.target.className} disabled`;
    let error, message;
    if (!selectedBillings.length) {
      error = "No bilings selected";
    }
    if (!name) {
      error = "Description must be filled";
    }

    if (!error) {
      const newBatch = await createPaymentBatch({
        name,
        billings: selectedBillings.map((b: any) => b._id)
      });

      if (newBatch.success) {
        setBatch(false);
        setSelectedBillings([]);
        message = `Payment batch ${newBatch.payment.payment_id} created successfully`;
      } else {
        error = newBatch.message;
      }
    }

    if (error) {
      toast.error(error);
    } else {
      toast.success(message)
    }
    
    e.target.disabled = false;
    e.target.className = e.target.className.replace(' disabled','');
  }

  return (
      <>
          <div style={{ marginTop: "-60px", zIndex: "1200" }} className="product-header-cont">
              <div className="in-row align-cener height100">
                  <div className="product-exit-btn pointer" 
                    onClick={() => {setBatch(false);setSelectedBillings([]);}}
                    >
                  {/* <Link to="/" className="product-exit-btn"> */}
                      <img className="mr2" src="/icons/product-exit.svg" alt="" />
                      Discard
                  {/* </Link> */}
                  </div>
                  <div className="in-row align-center ml2">
                      <span className="product-header-text ml1">Create a Payment Batch</span>
                  </div>
              </div>
              <button
                onClick={handleCreatePayment}
                className="product-header-button"
              >
                  Create
              </button>
          </div>
          <div style={{ background: "#E8EDF2", height: "max-content", minHeight: "80vh" }} className="product-cont m0 pt4">
              <div className="spayment-column">
                  {/* <div className="spaybatch-not-cont">
                      <div className="spaybatch-not-text">126 billings paid and in process have been removed from this batch. 85 open billings left.</div>
                      <img className="mr2" src="/icons/spaybatch-close.svg" alt=""/>
                  </div> */}
                  <div className="spayment-cont">
                      <div className="in-row align-center justify-between width100 mb4">
                          <span className="spayment-title-text">Payment Batch information</span>
                      </div>
                      <div className="spayment-box">
                          <span className="spayment-text mr4">Description:</span>
                          <input style={{ background: "#f2f2f2"}} 
                            placeholder="Payment Batch description text" 
                            className="tax-modal-input" 
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                      </div>
                      <div className="spayment-box justify-between">
                          <div className="spayment-text">
                              Total amount to be paid:  <b className="ml4">{convertPrice(totalPayment(billings))} €</b>
                          </div>
                          {/* <div className="in-row align-center">
                              <div className="spayment-text mr4">Status:</div>
                                  <div  className="spaybatch-create mr2">
                                      Processing
                                  </div>
                                  <div className="spaybatch-create">
                                      Paid
                                  </div>
                          </div> */}
                      </div>
              </div>
              <div className="table-main-title-cont mt4">
                  <div className="table-main-title-search-value-cont">
                      <div style={{textTransform: "uppercase"}} className="spayment-title-text bold black ml4">
                        {billings.length} Open Billings - {sellers.length} sellers
                      </div>
                  </div>
                  <div className="marketplace-pagination">
                      <Paginator
                          data={{}}
                          rowsPerPage={limit}
                          setPaginatedData={setPaginatedData}
                          setLimit={setLimit}
                          limit={limit}
                      />
                  </div>
              </div>
              <div className="table-border-bottom"></div>
                  <div style={{ height: "max-content" }} className="table-overflow">
                      <table style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}>
                          <thead>
                              <tr
                                  style={{ marginTop: "-5px", width: "100%" }}
                                  className="table-results-title"
                              >
                                      <th className="spaybatch-id ml4">Billing ID</th>
                                      <th className="spaybatch-order">ID Order</th>
                                      <th className="spaybatch-name">Plaform Name</th>
                                      <th className="spaybatch-products">Products</th>
                                      <th className="spaybatch-amount">Amount</th>
                                      <th className="spaybatch-date">Date / Time</th>
                              </tr>
                          </thead>
                          <tbody></tbody>
                      </table>
                      <div>
                          <table className="table-results p0">
                              <thead></thead>
                              <tbody>
                                {
                                  sellers.map((seller: any, index: number) => {
                                    const billingsSeller = sellerBillings(seller);
                                    return (
                                      <React.Fragment key={index}>
                                        <tr style={{background: "#F2F2F2", height: "40px"}} className="table-result-box billing-text">
                                          <td className="in-row align-center justify-between width100 ml4 mr4">
                                            <div>
                                                <b className="mr1">{seller}</b>
                                                  - {billingsSeller.length} billings 
                                                  ({convertPrice(totalPayment(billingsSeller))} €)
                                            </div>
                                            <div className="spaybatch-remove pointer" 
                                              style={{color: "#acacac", fontWeight: "700"}}
                                              onClick={() => removeSeller(seller)}
                                              >
                                              remove
                                            </div>
                                          </td>
                                        </tr>
                                        {billingsSeller.map((billing: any) => (
                                          <tr className="table-result-box billing-text" >
                                            <td className="spaybatch-id">
                                              <img className="mr2 pointer spaybatch-remove" src="/icons/spaybatch-close.svg" alt="" 
                                                onClick={() => removeBilling(billing._id)}
                                              />
                                              {billing.billing}
                                            </td>
                                            <td className="spaybatch-order">
                                              {billing.order}
                                            </td>
                                            <td className="spaybatch-name">
                                              {billing.platform}
                                            </td>
                                            <td className="spaybatch-products">
                                              <img className="mr1" src="/icons/paybatch-product.svg" alt="" />
                                              {billing.products}
                                            </td>
                                            <td className="spaybatch-amount bold">
                                              {convertPrice(billing.amount)} €
                                            </td>
                                            <td className="spaybatch-date">
                                              {DateTime.fromISO(billing.date).toLocaleString(DateTime.DATETIME_SHORT)}
                                            </td>
                                          </tr>  
                                        ))}
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
}

export default CreatePayment;