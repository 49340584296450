import React, { useState } from "react";
import { getSources } from "../../../actions/fileManagement";
import "./file-management.css";
import { FilesTable } from "./FilesTable";
import { IPaginatedRequest } from "../../../interfaces";
import { useDebouncedValue } from "@mantine/hooks";
import { useQuery } from "react-query";
import SyncNav from "../../ui/sync-nav/SyncNav";
import queryString from "query-string";

export interface IFileM {
  _id: string;
  created: string;
  purpose: string;
  source: string;
  target: string;
  index?: number;
}

export const FileManagement = () => {
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: "",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

  const { isLoading: loadSources, data: sources } = useQuery(
    `sources?${queryString.stringify(debouncedConfig)}`,
    () => getSources(debouncedConfig)
  );

  return (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont p0">
          <SyncNav />
          <div className="mapping-title-border"></div>
          <FilesTable
            sources={sources}
            setCurrentConfig={setCurrentConfig}
            currentConfig={currentConfig}
            loadSources={loadSources}
          />
        </div>
      </div>
    </div>
  );
};
