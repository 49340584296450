import React, { useState } from "react";
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom";
import { InfoSection } from "./InfoSection";
import { CreateEditMarketplaceForm } from "../marketplaces/CreateEditMplc";
import Preloader from "../../ui/preloader/Preloader";
import "./singlemarketplace.css";
import useActiveVendors from "../../../hooks/useActiveVendors";
import { useMemo } from "react";
import useMarketplace from "../../../hooks/useMarketplace";
import { IPaginatedRequest } from "../../../interfaces";
import { CategoryRateTable } from "./CateroryRateTable";

type TParams = { zeoosName: string };

const CategoryRate: React.FC<RouteComponentProps<TParams>> = (props) => {
  const { zeoosName } = props.match.params;
  const [modal, setModal] = useState(false)
  const history = useHistory();

  const vendors = useActiveVendors();
  const marketplace = useMarketplace(zeoosName);

  const loading = useMemo(
    () => vendors.isLoading || marketplace.isLoading,
    [vendors.isLoading, marketplace.isLoading]
  );

  const onEditClick = () => {
    history.push(`/platforms/create-edit?action=edit&&platformName=${zeoosName}`);
  };
  return loading ? (
    <div className="center-loader">
      <Preloader />
    </div>
  ) : (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont">
          <InfoSection
            marketplace={marketplace.data!}
            setOpenEdit={onEditClick}
            vendors={vendors.data}
          />
          <CategoryRateTable
            marketplace={marketplace}
          />
        </div>
      </div>
      {modal && (
        <CreateEditMarketplaceForm
          setModal={setModal}
          modal={modal}
          marketplace={marketplace.data!}
          action={"edit"}
        />
      )}
    </div>
  );
};

export default withRouter(CategoryRate);
