import React, { useContext } from "react";
import { isValidPermission, TPermission } from "../../../actions/user";
import { UserContext } from "../../context";
import { Role } from "../../../interfaces";
import useUser from "../../../hooks/useUser";

interface Props extends React.PropsWithChildren {
  role?: string;
  roles?: string[];
  permission?: TPermission;
  strict?: boolean;
  isHubOnly?:boolean;
  isD2COnly?:boolean
}

const RoleRequired: React.FC<Props> = ({
  children,
  role,
  roles,
  permission,
  strict = false,
  isHubOnly,
  isD2COnly
}) => {
  const { user } = useUser();
  const type = user?.seller?.accountType

  if (user.role === Role.admin && !strict) {
    return <>{children}</>;
  }

  return (
    <>
      {(role === user.role ||
        roles?.includes(user.role) || (isHubOnly && type === "Hub") || (isD2COnly && type === "D2C") ||
        (permission && isValidPermission(permission, user.permissions))) &&
        children}
    </>
  );
};

export default RoleRequired;
