import React, { useMemo } from 'react';
import MultiSelect from '../multi-select';
import useCountries from '../../../hooks/useCountries';

interface Props {
  value: string;
  setValue: (newValue: string) => void;
}

const CountrySelect: React.FC<Props> = ({ value, setValue }) => {
  const countries = useCountries();
  const options = useMemo(() => countries.map((country) => ({ label: country.name, value: country.name })), [countries]);

  return (
    <MultiSelect
      helperText='Select Country'
      value={value}
      setValue={setValue as any}
      options={options}
      multiple={false}
    />
  );
};

export default CountrySelect;
