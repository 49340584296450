import axios from "axios";
import { getHeaders } from "./user";

export const getFreights = async () => {
	const res = await axios.get(`/freight`, getHeaders());

	if (res.status !== 200) {
		return new Error("Something goes wrong");
	}

	return res.data.freights;
}


export const getWeights = async () => {
    const res = await axios.get(`/freight/weights`, getHeaders());

	if (res.status !== 200) {
		return new Error("Something goes wrong");
	}

	return res.data.weights;
}

export const getTimes = async () => {
    const res = await axios.get(`/freight/times`, getHeaders());

	if (res.status !== 200) {
		return new Error("Something goes wrong");
	}

	return res.data.times;
}

export async function createFreight(freight: any) {
	const res = await axios.post(`/freight`, { freight }, getHeaders());
	return res.data;
}

export async function updateFreight(freight: any) {
	const res = await axios.put(`/freight/${freight._id}`, { freight }, getHeaders());
	return res.data;
}
