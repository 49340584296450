import { Modal } from "components";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { getUnreadOfferImportReport, readOfferImportReport } from "../../../actions/user";
import { queryClient } from "../../App";
import useUser from "../../../hooks/useUser";
import { HtmlTooltip } from "../../routes/tables/gestao/CardButtons";
import { DateTime } from "luxon";
import OfferImportPie from "../offer-import-pie";
import fileDownload from "js-file-download";


interface Props {

}

const OfferImportUploadedReportModal: React.FC<Props> = () => {
    const { data } = useQuery("unreadOfferImportReport", getUnreadOfferImportReport, { refetchInterval: 10000 });
    const history = useHistory();
    const { user } = useUser();

    const handleRead = useCallback(async () => {
        if (!data?._id) {
            return;
        }

        await readOfferImportReport(data._id);
        queryClient.invalidateQueries("unreadOfferImportReport");

        history.push("/offers/all");
    }, [data, history]);

    if (!data || user?.seller?.onboardingOfferReport !== data._id) {
        return <></>;
    }

    return (
        <Modal onModalClose={() => { }} isOpened={true}>
            <div style={{ maxWidth: "740px", textAlign: "start" }} className="table-modal-form-cont p4">
                <div className="offers-modal-grey-cont">
                    <div className="in-row width100 align-center justify-between">
                        <div className="import-title">Congratulations! Onboarding completed.</div>
                        <span className="email-text">{DateTime.fromISO(data.created as any).toFormat("LLLL dd, yyyy - HH:mm")}</span>
                    </div>

                    <div className="in-row email-text mt1 width100 align-center justify-start">
                        <span>{data.offers} offers and {data.errorMessages.length} errors were found.</span>

                        {data.errorMessages?.length && (
                            <button onClick={() => fileDownload(data.errorMessages.join('\n'), `error-report-${data._id}.txt`)} style={{ all: "unset", cursor: 'pointer' }}>
                                <b style={{ color: "#55718A" }} className="ml1 mr1">Export errors</b>
                            </button>
                        )}

                        <HtmlTooltip className="pointer" title="Some errors may occur. A integrated product cannot have a value recorded in the PVP and Purchase Price columns simultaneously." placement="bottom-start">
                            <img className="pointer" src="/icons/tooltip-black.svg" alt="" />
                        </HtmlTooltip>
                    </div>
                </div>

                {data.offers > 0 && (
                    <div className="in-row width100 align-start">
                        <div className="width60 in-column align-start p4">
                            <span className="pim-language-text in-row align-center"><p className="mr1">{data.offers} offers in Inventory</p>
                                <HtmlTooltip className="pointer" title="Competitive offers are flagged with the orange badge. The best competitive offers are also marked with a green badge." placement="bottom-start">
                                    <img className="pointer" src="/icons/tooltip-black.svg" alt="" />
                                </HtmlTooltip>
                            </span>

                            <div className="in-row pt4 pb4 width100 align-center justify-between">
                                <div>
                                    <OfferImportPie {...data} />
                                </div>

                                <ul className="in-column width100 pl4 align-start">
                                    <li className="email-text pb2"><img width="16px" className="mr1" src="/icons/ranking-modal-yellow.svg" alt="" /> <b className="mr1">{data.competitive}</b> competitive offers</li>
                                    <li className="email-text pb2"><img width="16px" className="mr1" src="/icons/offer-grey-dott.svg" alt="" /> <b className="mr1">{data.nonCompetitive}</b> non-competitive</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}

                <div className="table-modal-border p0 m0" />

                <div className="in-row width100 align-center">
                    <div className="width100 in-column align-start p4">
                        <span className="email-text pb4">Only your competitive offers are listed in sales channels. In your inventory, you will be able to track which offers are competitive and which are not.<br /><br /> You can download the spreadsheet with your non-competitive offers, change the price using some of our suggestions and import the new offers.</span>

                        <button onClick={handleRead} className="table-modal-form-button initial">
                            <div style={{ color: "#FFF", padding: "10px 0 !important" }} className="pc-modal-spreadsheet-button-text initial">See all offers</div>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default OfferImportUploadedReportModal;
