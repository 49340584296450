import React, { useCallback, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";
import { updateSellerPlatformStatus } from "../../../../actions/vendor";
import { toast } from "react-toastify";
import { queryClient } from "../../../App";

interface Props {
	vendorId: number;
	isActive: boolean;
	label1?: string;
	label2?: string;
	module?: string;
	platform: string;
}

export const PlatformAvailability: React.FC<Props> = ({
	vendorId,
	isActive,
	label1,
	label2,
	module,
	platform,
}) => {
	const [status, setStatus] = useState(isActive);
	const [modal, setModal] = useState<boolean>(false);

	const handleSubmit = async (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();

		try {
			const body = {
				id: vendorId,
				zeoosName: platform,
				status: !status,
			};

			await updateSellerPlatformStatus(body);
			queryClient.invalidateQueries("sanitizedMarkeplaces");
			queryClient.invalidateQueries(`singleVendor/${vendorId}`);
			queryClient.invalidateQueries("currentUser");
			setStatus(!status);
			setModal(false);
			toast.success("Platform status has been successfully updated");
		} catch (error) {
			console.error(error);
			toast.error("Couldn't update the status!");
		}
	};

	const antSwitchText = () => {
		switch (module) {
			case "platforms":
				return status ? `${label1}d` : `${label2}d`;
			case "sellers":
				return status ? label1 : label2;
			default:
				return "";
		}
	};

	const modalTitleText = () => {
		switch (module) {
			case "platforms":
				return `${status ? label2 : label1} ${platform}`;
			case "sellers":
				return `make ${platform} ${(status ? label2 : label1)?.toLowerCase()}`;
			default:
				return "";
		}
	};

	const buttonText = () => {
		switch (module) {
			case "platforms":
				return `Yes, ${status ? label2 : label1}`;
			case "sellers":
				return `Yes, make ${status ? label2 : label1}`;
			default:
				return "";
		}
	};

	const onModalClose = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			setModal(false);
		},
		[setModal]
	);

	return (
		<>
			<td className="marketplace-actions in-row">
				<AntSwitch
					checked={status}
					onChange={() => setModal(true)}
					name="checkedC"
					required
				/>
				<span className={`email-text ml1 ${status ? "green" : "red"}`}>
					{antSwitchText()}
				</span>
			</td>
			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<div className="table-modal-form-cont p4">
						<div className="updateForm__container">
							<div className="vendor-mapping-modal-text">
								<strong>Are you sure you want to {modalTitleText()}?</strong>
							</div>
							{status && (
								<div className="vendor-mapping-modal-text mt3 mb3">
									{module === "platforms"
										? "It is not possible to create offers on deactivated sales channels."
										: "This seller will not be able to create offers in this sales channel."}
								</div>
							)}
							<div className="table-modal-border"></div>
							<div className="table-modal-form-button-box width100 m0">
								<button
									className="table-modal-form-button-cancel"
									onClick={onModalClose}
								>
									Cancel
								</button>
								<div
									className="table-modal-form-button pointer"
									onClick={handleSubmit}
								>
									{buttonText()}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};
