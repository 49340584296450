import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  IBatchMProducts,
  IBatchMarkeplace,
  IConfigPagination,
} from "../../../../../interfaces";
import CountryFlag from "../../../../ui/country-flag/CountryFlag";
import Paginator from "../../../../ui/pagination";
import BatchErrorsModal from "./BatchErrorsModal";
import CircularPreloader from "../../../../ui/circular-preloader/CircularPreloader";
import { getMarketplaceTemplate } from "../../../../../actions/v2";
import { ReactComponent as ActiveSyncLink } from "../../../../../assets/ActiveSyncLink.svg";
import { executeBatch } from "../../../../../actions/batch";

interface IPaginatedBatchMplc {
  data: IBatchMarkeplace[];
  lastPage: number;
  page: number;
  perPage: number;
  total: number;
}

interface Props {
  marketplaces: IBatchMarkeplace[];
  platformName: string;
  country: string;
  batchId?:string
}

const SingleBatchTable: React.FC<Props> = ({
  marketplaces,
  platformName,
  country,
  batchId
}) => {
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [batchPlatform, setBatchPlatform] = useState<IBatchMarkeplace>(
    marketplaces[0]
  );
  const [search, setSearch] = useState<string>("");
  const [isDownloadingTemplate, setIsDownloadingTemplate] = useState("");
  const [zeoosNames, setZeoosNames] = useState<string>("");
  const [isPlatform, setIsPlatform] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [paginatedData, setPaginatedData] = useState<IPaginatedBatchMplc>({
    data: marketplaces,
    perPage: 50,
    page: 1,
    total: marketplaces.length,
    lastPage: Math.ceil(marketplaces.length / 50),
  });
  const [results, setResults] = useState<IBatchMarkeplace[]>(marketplaces);
  const [isExecutingBatch, setIsExecutingBatch] = useState<boolean>(false)

  useEffect(() => {
    const _filter =
      platformName === "All platforms"
        ? { ...paginatedData, data: marketplaces }
        : {
          ...paginatedData,
          data: marketplaces.filter(
            (x: IBatchMarkeplace) => x.marketplace.zeoosName === platformName
          ),
        };
    const filtered =
      country === "All countries"
        ? _filter
        : {
          ...paginatedData,
          data: marketplaces.filter(
            (x: IBatchMarkeplace) => x.marketplace.country === country
          ),
        };

    setPaginatedData(filtered);
    // eslint-disable-next-line
  }, [platformName, country]);

  useEffect(() => {
    const results = marketplaces
      // eslint-disable-next-line
      .filter((item: any) => {
        if (search === "") {
          return item;
        } else if (
          Object.keys(item.marketplace).filter(
            (x: any) =>
              typeof item.marketplace[x] === "string" &&
              item.marketplace[x].toLowerCase().includes(search.toLowerCase())
          )?.length > 0
        ) {
          return item;
        }
      });
    setResults(results);

    setPaginatedData({
      ...paginatedData,
      data: results.slice(0, paginatedData.perPage),
    });
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (paginatedData.data.length > 0) {
      const firstZeoosName = paginatedData.data[0].marketplace.zeoosName;
      setZeoosNames(firstZeoosName);
    }
    if (
      zeoosNames.startsWith("Carrefour") ||
      zeoosNames.startsWith("Fnac") ||
      zeoosNames.startsWith("Rakuten")
    ) {
      setIsPlatform(true);
    } else {
      setIsPlatform(false);
    }
  }, [paginatedData, zeoosNames]);

  const onChangePage = useCallback(
    async (config: IConfigPagination) => {
      setPaginatedData((c: IPaginatedBatchMplc) => {
        const getData = () => {
          if (config.page === 1) {
            return marketplaces.slice(0, config.perPage);
          }

          return config.perPage * config.page > c.total
            ? marketplaces.slice(config.perPage * c.page)
            : marketplaces.slice(config.perPage, config.perPage * config.page);
        };

        return {
          ...c,
          page: config.page,
          perPage: config.perPage,
          lastPage: Math.ceil(c.total / config.perPage),
          data: getData(),
        };
      });
    },
    // eslint-disable-next-line
    [setPaginatedData]
  );

  const checkBatchErrors = (batchPlatform: IBatchMarkeplace) => {
    return batchPlatform.products.filter((x: IBatchMProducts) => x.error)
      .length;
  };

  const downloadFile = async (e: React.MouseEvent) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    try {
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const downloadTemplateFile = async (batchId:string, marketplace:string, itemid:string) => {
    setIsDownloadingTemplate(itemid)
    try {
      await getMarketplaceTemplate(batchId, marketplace)
    } catch (error) {
      toast.error("Please configure category mapping for marketplace!", {
        autoClose: 5000,
      });
    }finally{
      setIsDownloadingTemplate('')
    }
  }

  const runApiIntegration = async (batchId: string) => {
    setIsExecutingBatch(true)
    try {
      await executeBatch(batchId)
      toast.success('Batch execution completed')
    } catch (error) {
      console.error(error)
      toast.error("Error: Could not execute batch")
    } finally {
      setIsExecutingBatch(false)
    }
  }

  return (
    <>
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search platform"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-main-title-text pl2">
              <span className="table-main-title-text">
                {results?.length} platforms
              </span>
            </div>
          </div>
        </div>
        <div className="marketplace-pagination">
          <Paginator data={paginatedData} refetch={onChangePage as any} />
        </div>
      </div>
      <div style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
        >
          <thead>
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="batch-platform">Integration</th>
              <th className="batch-status">Country</th>
              <th className="batch-platform-name">Platform Name</th>
              <th className="batch-status">Type</th>
              <th className="batch-status">Products</th>
              <th className="batch-status">Errors</th>
              <th className="batch-status">Status</th>
              <th className="batch-status">Actions</th>
            </tr>
          </thead>
        </table>
        <table
          className="table-results mb2 batch-text"
          style={{ minHeight: "auto" }}
        >
          <tbody>
            {paginatedData.data.map((batchPlatform: IBatchMarkeplace) => {
              const type: string = (batchPlatform.marketplace.marketplaceIntegration as any)?.catalogIntegrationType;
              return (
                <tr
                  className="table-result-box height100px"
                  key={batchPlatform._id}
                >
                  <td className="batch-platform">
                    <img
                      className="batch-image-border"
                      height={84}
                      width={84}
                      src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${batchPlatform.marketplace.marketplaceName}.svg`}
                      alt=""
                    />
                  </td>
                  <td className="batch-status">
                    <CountryFlag
                      country={batchPlatform.marketplace.country}
                      className="table-search-image"
                      width="35px"
                    />
                  </td>
                  <td className="batch-platform-name">
                    <span>{batchPlatform.marketplace.zeoosName}</span>
                  </td>
                  <td className="batch-status">
                    <span>
                      <span>{type}</span>
                    </span>
                  </td>
                  <td className="batch-status">
                    <span>{batchPlatform.products.length}</span>
                  </td>
                  <td className="batch-status">
                    {checkBatchErrors(batchPlatform) ? (
                      <div
                        className="red semibold pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setBatchPlatform(batchPlatform);
                          setErrorModal(true);
                        }}
                      >
                        {batchPlatform.status !== 'FINISHED'  ? `See errors: ${checkBatchErrors(batchPlatform)}` : ''}
                      </div>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="batch-status">
                    <div
                      className={`batch-${(type === 'File') ? 'running' : batchPlatform.status.toLowerCase()}`}
                    >
                      {type === 'File' ? (
                        'Manual action'
                      ) : (
                        batchPlatform.status
                      )}
                    </div>
                  </td>

                  <td className="batch-status">
                    {checkBatchErrors(batchPlatform) || (type === 'API') ? (
                      <div
                        className="pointer"
                        onClick={() => isExecutingBatch ? undefined : runApiIntegration(batchId!)}
                      >
                        {isExecutingBatch ? <CircularPreloader/> : <ActiveSyncLink />}
                      </div>
                    ) : (type === 'File') ? (
                      <div onClick={() => downloadTemplateFile(batchId!, batchPlatform?.marketplace?.marketplaceName?.toLowerCase(), batchPlatform?._id)}>
                        {isDownloadingTemplate !== batchPlatform?._id  ? <img className="pointer" src="/icons/download-file.svg" alt="" onClick={isButtonDisabled ? undefined : downloadFile} /> : <CircularPreloader/>}
                      </div>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {errorModal && (
          <BatchErrorsModal
            modal={errorModal}
            setModal={setErrorModal}
            batchPlatform={batchPlatform}
          />
        )}
      </div>
    </>
  );
};

export default SingleBatchTable;
