import React, { memo, useCallback } from "react";
import { Link } from "react-router-dom";
import { IBatchList, IPaginatedRequest } from "../../../../interfaces";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import Paginator from "../../../ui/pagination";

interface IPaginatedBatch {
  data: IBatchList[];
  lastPage: number;
  page: number;
  perPage: number;
  total: number;
}

interface Props {
  batchList: IPaginatedBatch;
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  isLoading: boolean;
}

const BatchListTable: React.FC<Props> = ({
  batchList,
  setCurrentConfig,
  isLoading,
}) => {
  const onChangePage = useCallback(
    async (config: IPaginatedRequest) => {
      setCurrentConfig((c: IPaginatedRequest) => {
        return {
          ...c,
          ...config,
        };
      });
    },
    [setCurrentConfig]
  );

  const dateFormat = (date: Date) => {
    const formatedDate = new Date(date)
      .toLocaleString("en-GB")
      .slice(0, -3)
      .split(",");

    return (
      <>
        {formatedDate.map((el: string, index: number) => (
          <span key={index}>{el}</span>
        ))}
      </>
    );
  };

  return (
    <div className="table-main-cont">
      <div style={{ padding: "0 40px" }} className="width100">
        <div className="table-main-title-cont">
          <div className="table-main-title-search-value-cont">
            <div className="search-box">
              <div className="table-main-title-text pl2">
                {isLoading ? (
                  <CircularPreloader />
                ) : (
                  <span className="table-main-title-text">
                    {batchList?.total} Exports
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="marketplace-pagination">
            <Paginator
              data={!isLoading ? batchList : {}}
              refetch={onChangePage as any}
            />
          </div>
        </div>
        <div style={{ height: "max-content" }} className="table-overflow">
          <table
            style={{
              position: "sticky",
              zIndex: 101,
              top: "2px",
              width: "100%",
            }}
          >
            <thead>
              <tr
                style={{ marginTop: "-5px", width: "100%" }}
                className="table-results-title"
              >
                <th className="batch-name">Export ID</th>
                <th className="batch-products">Products</th>
                <th className="batch-user">User</th>
                <th className="batch-status">Status</th>
                <th className="batch-status">Marketplaces</th>
                <th className="batch-status">Actions</th>
                <th className="batch-date">Last Update</th>
              </tr>
            </thead>
          </table>
          <table
            className="table-results mb2 batch-text"
            style={{ minHeight: "auto" }}
          >
            <tbody>
              {batchList?.data?.map((batch: any) => (
                <tr className="table-result-box height100px" key={batch._id}>
                  <Link
                    to={"/batch/" + batch._id}
                    className="batch-name pointer"
                  >
                    <td>
                      <img
                        className="mr2"
                        width="15px"
                        height="15px"
                        src="/icons/upload-default.svg"
                        alt=""
                      />
                      <span>{batch._id}</span>
                    </td>
                  </Link>
                  <td className="batch-products">
                    <span>{batch.products.length}</span>
                  </td>
                  <td className="batch-user">
                    <img
                      style={{ borderRadius: "60px" }}
                      height={34}
                      width={34}
                      src={
                        batch.user?.isBot
                          ? "/icons/image-bot.svg"
                          : batch.user?.profileImage
                      }
                      alt=""
                    />
                    <div className="ml2">
                      {batch.user?.isBot ? "Bot" : batch.user?.username}
                    </div>
                  </td>
                  <td className="batch-status">
                    <div className={`batch-${batch.status.toLowerCase()}`}>
                      {batch.status}
                    </div>
                  </td>
                  <td className="batch-status">
                    <span>{batch.marketplaces.length}</span>
                  </td>
                  <Link
                    to={"/batch/" + batch._id}
                    className="batch-status underline pointer"
                  >
                    <td>
                      <span>See details</span>
                    </td>
                  </Link>
                  <td className="batch-date">
                    <div className="batch-date-br">
                      {dateFormat(batch?.created)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default memo(BatchListTable);
