import {
  OfferProduct,
  OfferRequest,
  OfferResponse,
  PlatformOfferStat,
  SellerOffer,
} from "types/offer";
import { createUseDataHook, useData } from ".";
import axios from "axios";
import { getHeaders } from "../user";
import qs from "query-string";

export const useOffers = createUseDataHook<OfferRequest, OfferResponse>(
  "/v2/my/offers"
);

export function usePlatformOfferStat(
  sku: string,
  type: "integrated" | "all" = "integrated"
) {
  return useData<any, PlatformOfferStat[]>(`/v2/my/product/${sku}/stats`, {
    type,
  });
}

export function useProductOffers(sku: string, platform?: string) {
  return useData<any, OfferProduct[]>(`/v2/my/product/${sku}/offers`, {
    platform,
  });
}

export function useSellerOffer(
  sku: string,
  query: { platform: string; seller: string }
) {
  return useData<any, SellerOffer>(`/v2/my/product/${sku}/offer`, query);
}

export async function updateOffer({
  sku,
  platform,
  seller,
  ...data
}: {
  sku: string;
  seller: string;
  platform: string;
  pvp?: number;
  cost?: number;
}) {
  const res = await axios.put(
    `/v2/my/offer/${sku}?${qs.stringify({ platform, seller })}`,
    data,
    getHeaders()
  );

  return res.data;
}

export async function getPlatformOffer(ean: string, platform: string) {
  const res = await axios.get(`/v2/my/offer/platform-product/${platform}/${ean}`, getHeaders());
  
  return res.data;
}
