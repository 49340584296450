import React from 'react';
import ProductImportPie from '../product-import-pie';
import { ProductImportReport } from 'types/seller';

interface Props extends ProductImportReport {
    showTotal?: boolean;
}

const ProductCountsStats: React.FC<Props> = ({ showTotal, ...report }) => {
    return (
        <>
            <ProductImportPie {...report} />

            <ul className="in-column width100 pl4 align-start">
                {showTotal && <li className="pim-language-text pb2">{report.total} products</li>}
                <li style={{ color: "#44AF1F", fontWeight: "500" }} className="email-text pb2">&#183; {getPercentage(report.integrated, report.total)} Integrated</li>
                <li style={{ color: "#9F9F9F", fontWeight: "500" }} className="email-text pb2">&#183; {getPercentage(report.nonIntegrated, report.total)} Non-integrated</li>
                <li style={{ color: "#EA596E", fontWeight: "500" }} className="email-text pb2">&#183; {getPercentage(report.unregistered, report.total)} Unregistred</li>
            </ul>
        </>
    );
};

export function getPercentage(qty: number, total: number) {
    return `${((100 * qty) / total).toFixed(1)}% (${qty})`;
};

export default ProductCountsStats;
