import React, { useEffect } from "react";

function useChatScroll<T>(dep: T): React.MutableRefObject<HTMLDivElement> {
	const ref = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) {
			ref.current.scrollTop = ref.current.scrollHeight;
		}
	}, [dep]);

	return ref as any;
}

export default useChatScroll;
