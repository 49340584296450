import { useMemo } from "react";
import { useQuery } from "react-query";
import querystring from "query-string";
import { getOrdersZeoos } from "../actions/bi";
import { IPaginatedRequest, ReactQueryConfig } from "../interfaces";
import { listOrders, getOrder } from "../actions/order";
import { queryClient } from "../components/App";

export function useOrders(
  from: number | Date,
  to: number | Date,
  config?: ReactQueryConfig
) {
  const key = useMemo(
    () => `orders?${querystring.stringify({ from, to })}`,
    [from, to]
  );
  const data = useQuery(
    key,
    async () => await getOrdersZeoos({ from, to }),
    // async () => await listOrders({ from, to }),
    config
  );

  return data;
}

export function useListOrders(
  query: IPaginatedRequest & { from: number | Date; to: number | Date },
  config?: ReactQueryConfig
) {
  const key = useMemo(
    () => `listOrders?${querystring.stringify(query)}`,
    [query]
  );
  const data = useQuery(
    key,
    // async () => await getOrdersZeoos({ from, to }),
    async () => await listOrders(query),
    config
  );

  return data;
}

export function useSingleOrder(id: string, config?: ReactQueryConfig) {
  const key = useMemo(() => `singleOrder/${id}`, [id]);
  const data = useQuery(key, async () => await getOrder(id), config);

  return {
    ...data,
    invalidate: () => queryClient.invalidateQueries(key),
  };
}

export default useOrders;
