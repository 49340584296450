import React, { useEffect, useState } from "react";
import { getBillingPrice } from "../../../../actions/billings";
import { Modal } from "../../../ui/modal/Modal";
import Preloader from "../../../ui/preloader/Preloader";
import { toStringConverter } from "../../tables/gestao/UpdateGestao";

interface Props {
	item: any;
	bill: any;
}

export const Preco: React.FC<Props> = ({ item, bill }) => {
	const [modal, setModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [billingPrice, setBillingPrice] = useState<any>();

	useEffect(() => {
		async function initializeState() {
			try {
				setBillingPrice(await getBillingPrice(item.bill_id));
			} catch (error) {
				console.error(error);
			}

			setLoading(false);
		}

		initializeState();
		// eslint-disable-next-line
	}, []);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<>
			<div
				onClick={() => {
					setModal(true);
				}}
				className="billing-cost bold pointer"
			>
				{toStringConverter(bill.subtotal)} €
			</div>
			{modal &&
				(loading ? (
					<Preloader />
				) : (
					<Modal onModalClose={onModalClose} isOpened={modal}>
						<div className="billing-modal-cont">
							<div>
								<div className="table-modal-title-box m0">
									<div className="mapping-modal-title-text">
										Pricing Breakdown
									</div>
								</div>
								<div className="billing-modal-title-cont">
									<div className="billing-modal-text ft1">
										ID {item.bill_id}
									</div>
									<div className="billing-modal-text ft1">
										{item.invoice_date}
									</div>
								</div>
								<form className="table-modal-form-cont">
									<div className="billing-modal-border"></div>
									<div className="pt2 pb2">
										<div className="billing-modal-box billing-modal-text greybg ft3 pb4 pt4">
											<div className="width100 bold">PVP:</div>
											<div className="billing-input-box">
												<div className="bold">
													{toStringConverter(billingPrice.pvp_visao_por)}€
												</div>
											</div>
										</div>
										<div className="mt2 height80px">
											<div className="billing-modal-box billing-modal-text">
												<div className="width100">Platform Rate (15%):</div>
												<div className="billing-input-box">
													<div>{toStringConverter(billingPrice.iva)} €</div>
												</div>
											</div>
											<div className="billing-modal-box billing-modal-text">
												<div className="width100">VAT (21%)</div>
												<div className="billing-input-box">
													<div>{toStringConverter(billingPrice.iva)} €</div>
												</div>
											</div>
											<div className="billing-modal-box billing-modal-text">
												<div className="width100">Excise Duty:</div>
												<div className="billing-input-box">
													<div>0 €</div>
												</div>
											</div>
										</div>
										<div className="billing-modal-border"></div>
										<div className="height80px mt1">
											<div className="billing-modal-box billing-modal-text bold">
												<div className="width100">PVP Base:</div>
												<div className="billing-input-box">
													<div>
														{toStringConverter(billingPrice.pvp_base)} €
													</div>
												</div>
											</div>
											<div className="billing-modal-box billing-modal-text">
												<div className="width100">Fulfilment Cost:</div>
												<div className="billing-input-box">
													<div>1,00 €</div>
												</div>
											</div>
											<div className="billing-modal-box billing-modal-text">
												<div className="width100">Zeoos Rate (10%):</div>
												<div className="billing-input-box">
													<div>
														{toStringConverter(billingPrice.vinuus_rate)} €
													</div>
												</div>
											</div>
										</div>
										<div className="billing-modal-border"></div>
										<div className="height80px mt1">
											<div className="billing-modal-box billing-modal-text bold">
												<div className="width100 bold">Purchase Price:</div>
												<div className="billing-input-box">
													<div>
														{toStringConverter(billingPrice.preco_compra)}€
													</div>
												</div>
											</div>
											<div className="billing-modal-box billing-modal-text">
												<div className="width100">Markup:</div>
												<div className="billing-input-box">
													<div>6,00 €</div>
												</div>
											</div>
											<div className="billing-modal-box billing-modal-text">
												<div className="width100">Cost:</div>
												<div className="billing-input-box">
													<div>4,00 €</div>
												</div>
											</div>
										</div>
										<div className="billing-modal-border"></div>
									</div>
									<div className="table-modal-form-button-box width100 mt4 align-center justify-center">
										<button
											className="table-modal-form-button"
											onClick={() => setModal(false)}
										>
											close
										</button>
									</div>
								</form>
							</div>
						</div>
					</Modal>
				))}
		</>
	);
};
