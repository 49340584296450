import React, { useState } from "react";
import { exportAllProductInfo, getDistinctBrands, getEssentialProductSchemeData } from "../../../actions/pim";
import { Modal } from "../../ui/modal/Modal";
import { toast } from "react-toastify";
import { Checkbox } from "@mui/material";
import useSanitizedMarketplaces from "../../../hooks/useSanitizedMarketplaces";
import useSchemes from "../../../hooks/useSchemes";
import { useEffect } from "react";
import { useQuery } from "react-query";
import useVendors from "../../../hooks/useVendors";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  modal: boolean;
  skus?: string[];
}

export const ExportAllProductInfo: React.FC<Props> = ({
  modal,
  setModal,
  skus,
}) => {
  const [schemeId, setSchemeId] = useState('');
  const [zeoosName, setZeoosName] = useState('');
  const [brand, setBrand] = useState('');
  const [disabledSKU, setdisabledSKU] = useState(false);
  const [sellerId, setSellerId] = useState('');

  const marketplaces = useSanitizedMarketplaces();
  const schemes = useQuery(`schemesEssential`, getEssentialProductSchemeData);
  const brands = useQuery(`pimBrands${schemeId}`, () => getDistinctBrands(schemeId));
  const sellers = useVendors();

  useEffect(() => {
    setZeoosName(marketplaces?.data?.[0]?.zeoosName || '');
  }, [marketplaces?.data]);

  useEffect(() => {
    setSchemeId(schemes?.data?.[0]?._id || '');
  }, [schemes?.data]);

  return (
    <Modal
      onModalClose={(e: React.MouseEvent) => {
        e.stopPropagation();
        setModal(false);
      }}
      isOpened={modal}
    >
      <div className="modal-export-cont">
        <div className='width100 justify-center'>
          <p className="import-title">Select either seller or scheme</p>
        </div>

        <div className="width100 items-start justify-between">
          <label className="mapping-modal-text pr2" htmlFor="">
            Seller:
          </label>
          <select
            className="freight-modal-input"
            style={{ width: 230 }}
            onChange={(e) => {
              setSellerId(e.target.value);
            }}
            value={sellerId}
          >
            <option value="">Select seller</option>
            {sellers.data?.map((x: any) => (
              <option value={x.id}>{x.name}</option>
            ))}
          </select>
        </div>

        <div className="width100 items-start justify-between">
          <label className="mapping-modal-text pr2" htmlFor="">
            Scheme:
          </label>
          <select
            className="freight-modal-input"
            style={{ width: 230 }}
            onChange={(e) => {
              setSchemeId(e.target.value);
            }}
            value={schemeId}
          >
            <option value="">Select scheme</option>
            {schemes.data?.map(x => (
              <option value={x._id}>{x.name}</option>
            ))}
          </select>
        </div>

        <div className="width100 items-start justify-between">
          <label className="mapping-modal-text pr2" htmlFor="">
            Platform:
          </label>
          <select
            className="freight-modal-input"
            style={{ width: 230 }}
            onChange={(e) => setZeoosName(e.target.value)}
            value={zeoosName}
          >
            <option value=''>Select platform</option>
            {marketplaces.data?.map((x: any) => (
              <option value={x.zeoosName}>{x.zeoosName}</option>
            ))}
          </select>
        </div>

        <div className="width100 items-start justify-between">
          <label className="mapping-modal-text pr2" htmlFor="">
            Brand:
          </label>
          <select
            className="freight-modal-input"
            style={{ width: 230 }}
            onChange={(e) => setBrand(e.target.value)}
            value={brand}
          >
            <option value=''>Select brand</option>
            {brands.data?.map(x => (
              <option value={x}>{x}</option>
            ))}
          </select>
        </div>

        <div className="width100 align-center">
          <label className="mapping-modal-text pr2" htmlFor="" style={{ paddingBottom: 0 }}>
            Disabled SKU:
          </label>

          <Checkbox
            value={disabledSKU}
            sx={{ p: 0 }}
            onChange={(e) => {
              setdisabledSKU(e.target.checked);
            }}
          />
        </div>

        <div className="width100">
          <button
            className="table-modal-form-button width100"
            onClick={(e) => {
              e.preventDefault();

              exportAllProductInfo({ zeoosName, schemeId, disabledSKU, skus, brand, sellerId })
                .then(() =>
                  toast.success(
                    "It will land in your email inbox once finished processing (usually takes less than a minute)"
                  )
                )
                .catch((error) => {
                  console.error(error);
                  toast.error("Something went wrong");
                });
            }}
          >
            Export
          </button>
        </div>
      </div>
    </Modal>
  );
};
