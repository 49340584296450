import { useMemo } from "react";
import { useQuery } from "react-query";
import { ReactQueryConfig } from "../interfaces";
import { getTaxMappings } from "../actions/taxMapping";


function useTaxes(config?: ReactQueryConfig) {  
  const key = useMemo(() => `taxes`, []);
  const data = useQuery(key, async () => await getTaxMappings(), config);

  return data;
}

export default useTaxes;
