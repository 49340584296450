import React, { useState } from "react";
import { Modal } from "../../ui/modal/Modal";
import { toast } from "react-toastify";
import { deletePlatformRate, editPlatformRate } from "../../../actions/marketplace";
import Preloader from "../../ui/preloader/Preloader";

interface Props {
  modal: boolean;
  setModal: (modal: boolean) => void;
  onModalClose: any;
  selectedCategory: any;
  updateTable: any
}

export const EditCategoryRate: React.FC<Props> = ({
  modal,
  setModal,
  onModalClose,
  selectedCategory,
  updateTable
}) => {
  const [newRate, setNewRate] = useState(selectedCategory.rate[selectedCategory.platform])
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    try {
      setIsLoading(true)
      await editPlatformRate({ newRate }, selectedCategory.platform, selectedCategory.code)
        .then(() => updateTable())

      toast.success('Rate updated successfully!')
    } catch (error) {
      console.error(error)
      toast.error('Error with editing rate!');
    } finally {
      setIsLoading(false)
      setModal(false)
    }
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)
      await deletePlatformRate(selectedCategory.platform, selectedCategory.code)
        .then(() => updateTable())
      toast.success('Rate deleted successfully!')
    } catch (error) {
      console.error(error)
      toast.error('Error with deleting rate!');
    } finally {
      setIsLoading(false)
      setModal(false)
    }
  }

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <Modal onModalClose={onModalClose} isOpened={modal}>
        <div
            style={{
                width: '520px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
          <h3 className="create-heading">Edit category rate in {selectedCategory.platform}</h3>
          <div className="table-border-bottom pb2"></div>
            <p className="product-p mt4">
                Zeoos Category:
                <span className="profile-popup-subtitle ml1">
                    {selectedCategory.label}
                </span>
            </p>
            {/* <p className="product-p mb2">
                Platform Category:
                <span className="profile-popup-subtitle">
                    {' Category name'}
                </span>
            </p> */}
          <div className="table-modal-border"></div>
          <p className="product-p">
            Category rate in {selectedCategory.platform}
          </p>
          <div
              className="product-p"
              style={{
                  display: 'flex',
                  marginBlock: '8px',
                  justifyContent: 'space-between',
                  width: '100%'
              }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <input
                  className="mapping-modal-table-input mr2 mwidth60"
                  type="number"
                  step=".01"
                  value={newRate}
                  onChange={e => setNewRate(e.target.value)}
              />
              <p>
                  (%)
              </p>
            </div>
            <div
                className="in-row align-center justify-between width13 pointer"
                onClick={async () => {
                  if (
                    window.confirm("Are you sure you want to delete this entry")
                  ) {
                    handleDelete();
                  }
                }}
            >
                <img
                    src="/icons/grey-trash-can.svg"
                    alt=""
                    width="20px;"
                    height="20px;"
                />
                <span>delete</span>
            </div>
          </div>
          <div className="table-modal-border"></div>
          <div className="table-modal-form-button-box width100 mb0">
            <button
              className="table-modal-form-button-cancel"
              onClick={() => setModal(false)}
            >
              Cancel
            </button>
            <button
              className="table-modal-form-button"
              onClick={handleClick}
            >
              Save
            </button>
          </div>
        </div>
    </Modal>
  );
};
