"use strict";
exports.__esModule = true;
exports.DiscountType = exports.EventType = exports.EventStatus = void 0;
var EventStatus;
(function (EventStatus) {
    EventStatus["SCHEDULED"] = "SCHEDULED";
    EventStatus["ACTIVE"] = "ACTIVE";
    EventStatus["EXPIRED"] = "EXPIRED";
    EventStatus["ABORTED"] = "ABORTED";
})(EventStatus = exports.EventStatus || (exports.EventStatus = {}));
var EventType;
(function (EventType) {
    EventType["CUSTOM"] = "CUSTOM";
    EventType["AUTOMATIC"] = "AUTOMATIC";
})(EventType = exports.EventType || (exports.EventType = {}));
var DiscountType;
(function (DiscountType) {
    DiscountType["PERCENTAGE"] = "PERCENTAGE";
    DiscountType["VALUE"] = "VALUE";
})(DiscountType = exports.DiscountType || (exports.DiscountType = {}));
