import React, { useEffect } from "react";
import "./dropdown.css";
import ReactDOM from "react-dom";

interface Props {
  children: any;
  onModalClose: any;
  isOpened: boolean;
  bodyClassName?: string;
}

export const Dropdown: React.FC<Props> = ({
  children,
  onModalClose,
  isOpened,
  bodyClassName,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [isOpened]);

  return ReactDOM.createPortal(
    <div className="dropdown" onClick={onModalClose}>
      <div
        className={`dropdown__body ${bodyClassName}`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* <div className="dropdown__close-button" onClick={onModalClose}>
					×
				</div> */}
        {children}
      </div>
    </div>,
    document.getElementById("portal") as any
  );
};
