import React, { useContext, useState } from "react";
import { Redirect, withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
	loginUser,
	PermissionScope,
	sendMFACode,
	TPermission,
} from "../../../actions/user";
import { UserContext } from "../../context";
import "./login.css";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toast } from "react-toastify";
import Contato from "./Contato";
import { Role } from "../../../interfaces";
import { queryClient } from "../../App";
import useUser from "../../../hooks/useUser";

const Login: React.FC<any> = ({ history }) => {
	const [state, setState] = useState({
		checkedB: true,
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const { user, setUser } = useUser();
	const [textButton, setTextButton] = useState("LOGIN");
	const [classButton, setClassButton] = useState("");

	if (user?.role === Role.admin) {
		return history.location.state &&
			history.location.state?.from?.pathname !== "permission-denied" ? (
			<Redirect to={`${history.location.state.from.pathname}`} />
		) : (
			<Redirect to="/" />
		);
	} else if (user) {
		return <Redirect to={getRedirectRoute(user.permissions) || "/"} />;
	}

	const handleLogin = async (e: any) => {
		setTextButton("loading...");
		setClassButton("wait");
		e.preventDefault();

		try {
			const { user } = await loginUser({ email, password }, state.checkedB);
			setUser(user);

			await queryClient.invalidateQueries();

			if (user?.role === Role.admin) {
				history.push(
					history.location.state &&
						history.location.state?.from?.pathname !== "permission-denied"
						? history.location.state.from.pathname
						: "/"
				);
			} else if (user) {
				history.push(getRedirectRoute(user.permissions) || "/");
			}
		} catch (error: any) {
			if (error?.message === "MFA email required") {
				history.push("/login/mfa");
				const res = await sendMFACode({ email });
				toast.success(res.message);
				return;
			}
			toast.error(error.response.data.message, { autoClose: 5000 });
			setTextButton("LOGIN");
			setClassButton("");
		}
	};

	return (
		<div>
			<div className="s_main-login">
				<div className="login-box">
					<div className="in-column">
						<div className="titel_box">
							{/* <div className="login-title-text"></div> */}
							<img src="/icons/zeoos-logo.svg" width="160px;" alt="" />
						</div>
						<form className="login-form-block" onSubmit={handleLogin}>
							<div className="email_text_box">
								<label className="email-text-login">Email:</label>
							</div>
							<div className="login-input">
								<img
									className="image-profile"
									src="/icons/carbon_email.svg"
									alt="settings"
									width="30px"
									height="30px"
								/>
								<input
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									type="email"
									className="email-field"
									name="Email"
									data-name="Email"
									id="Email"
									required
								/>
							</div>
							<div className="email_text_box">
								<label className="password-text-login">Password:</label>
							</div>
							<div className="login-input">
								<div>
									<img
										className="image-profile"
										src="/icons/lock-password.svg"
										alt="settings"
										width="30px"
										height="30px"
									/>
								</div>
								<input
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									type="password"
									className="password-field"
									name="Password"
									data-name="Password"
									id="Password"
									required
								></input>
							</div>
							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											checked={state.checkedB}
											onChange={handleChange}
											name="checkedB"
											color="primary"
											required
										/>
									}
									className="lembre-me"
									label="Keep me signed in"
								/>
							</FormGroup>
							<div className="button_box">
								<button
									onClick={handleLogin}
									disabled={classButton === "wait" ? true : false}
									className={`log-in-login ${classButton}`}
								>
									{textButton}
								</button>
							</div>
							<div className="login-text-box">
								<div className="esqueci-text">
									<Link to="/login/esqueci">Forgot your password?</Link>
								</div>
								{/* <Language /> */}
							</div>
						</form>
					</div>
				</div>
				<Contato />
			</div>
		</div>
	);
};

function getRedirectRoute(permissions: TPermission[]) {
	return routes[permissions?.[0]?.scope]?.path;
}

const routes = {
	DASHBOARD: {
		path: "/dashboard",
	},
	INTELLIGENCE: {
		path: "/intelligence",
	},
	PLATFORMS: {
		path: "/platforms",
	},
	INVENTORY: {
		path: "/offers/all",
	},
	ORDERS: {
		path: "/orders",
	},
	VENDORS: {
		path: "/vendors-management",
	},
	BILLINGS: {
		path: "/billing",
	},
	PIM: {
		path: "/catalog/integrated",
	},
	MAPPINGS: {
		path: "/freight",
	},
	SYNCS: {
		path: "/jobs-panel",
	},
	SETTINGS: {
		path: "/users-management",
	},
} as {
		[key in PermissionScope]: {
			path: string;
		};
	};

export default withRouter(Login);
