import React, { useEffect, useMemo, useState } from "react";
import "./pagination.css";

interface Props {
  data: any;
  rowsPerPage: number;
  setPaginatedData: (data: any) => void;
  setLimit: (limit: number) => void;
  limit: number;
}

export const limitArr = [
  { text: "Show 50", limit: 50 },
  { text: "Show 100", limit: 100 },
  { text: "Show 200", limit: 200 },
];

export const Paginator: React.FC<Props> = ({
  data,
  rowsPerPage,
  setPaginatedData,
  setLimit,
  limit,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(
    Math.ceil(data.length / rowsPerPage)
  );

  useEffect(() => {
    const lastPage = Math.ceil(data.length / rowsPerPage);
    if (lastPage === 1) {
      setCurrentPage(1);
    }
    setCurrentPage(1);
    setLastPage(lastPage);
  }, [data.length, rowsPerPage]);

  // const pageNumbers = [];
  // const pagesCount = Math.ceil(data.length / rowsPerPage);

  // for (let i = 1; i <= pagesCount; i++) {
  // 	pageNumbers.push(i);
  // }

  const pageNumbers = useMemo(() => {
    const pageNumbers = [];
    const pagesCount = Math.ceil(data.length / rowsPerPage);

    for (let i = 1; i <= pagesCount; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }, [data.length, rowsPerPage]);

  const handleChangePage = (e: React.MouseEvent, newPage: number) => {
    e.preventDefault();
    const from = newPage * rowsPerPage - rowsPerPage;
    const to = newPage * rowsPerPage;
    setPaginatedData(data.slice(from, to));
    if (typeof setCurrentPage === "function") {
      setCurrentPage(newPage);
    }
  };

  const prevPage = (e: React.MouseEvent) => {
    e.preventDefault();
    if (currentPage === 1) {
      setCurrentPage(1);
    } else {
      const newPage = currentPage - 1;
      const from = newPage * rowsPerPage - rowsPerPage;
      const to = newPage * rowsPerPage;
      setCurrentPage(newPage);
      setPaginatedData(data.slice(from, to));
    }
  };

  const nextPage = (e: React.MouseEvent) => {
    e.preventDefault();
    const newPage = currentPage + 1;
    const from = newPage * rowsPerPage - rowsPerPage;
    const to = newPage * rowsPerPage;
    setCurrentPage(newPage);
    setPaginatedData(data.slice(from, to));
  };

  const paginateNumbers = (array: number[], currentPage: number) => {
    const newArr = array.slice(currentPage - 1, currentPage + 2);
    if (newArr.length < 3 && lastPage! >= 3) {
      return array.slice(lastPage! - 3, lastPage);
    }
    if (newArr.length < 3 && lastPage! <= 3) {
      return array.slice(0, lastPage);
    }
    return newArr;
  };

  return (
    <>
      {data.length > 10 && (
        <select
          className="main-select-bi"
          value={`Show ${limit}`}
          onChange={(e) => {
            const selectedLimit = limitArr.find(
              (item: any) => item.text === e.target.value
            )?.limit;
            setLimit(selectedLimit!);
            setPaginatedData(data.slice(0, selectedLimit));
          }}
        >
          {limitArr.map((item: any, index: number) => (
            <option className="" key={index}>
              {item.text}
            </option>
          ))}
        </select>
      )}
      <div className="pagination-container">
        <div
          className={`pagination-admin-button ${
            currentPage === 1 ? "admin-button-disabled" : ""
          }`}
          onClick={prevPage}
        >
          <img src="/icons/less-blue.svg" width="10px;" alt="" />
        </div>
        {paginateNumbers(pageNumbers, currentPage).map(
          (num: number, index: number) => {
            return (
              <div
                key={index}
                onClick={(e) => handleChangePage(e, num)}
                className={`pagination-admin-button ${
                  currentPage === num
                    ? "current-admin-button"
                    : "prev-next-color-button"
                }`}
              >
                {num}
              </div>
            );
          }
        )}
        <div
          className={`pagination-admin-button ${
            currentPage === lastPage
              ? "admin-button-disabled"
              : "prev-next-color-button"
          }`}
          onClick={nextPage}
        >
          <img src="/icons/more-blue.svg" width="10px;" alt="" />
        </div>
      </div>
    </>
  );
};
