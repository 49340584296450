import React, { useState } from "react";
import { Modal } from "../../../../../ui/modal/Modal";
// @ts-ignore
import { DateTime } from "luxon-business-days";
// import Button from '@mui/material/Button';
// import ButtonGroup from '@mui/material/ButtonGroup';
// import moment from "moment";

const SingleOrderPickupModal: React.FC<any> = ({
	shipping,
	onModalClose,
	onModalSubmit,
}) => {
	const ptTime = DateTime.now().setZone("Europe/Lisbon");
	const [volume, setVolume] = useState(shipping.volume || "1");
	const [weight, setWeight] = useState(shipping.weight);
	// eslint-disable-next-line
	const [pickupDay, setPickupDay] = useState("");

	const pickUpDay = () => {
		return ptTime.plusBusiness({ days: 1 }).toLocaleString(DateTime.DATE_HUGE);
	};

	const isPickUpAvailable = () => {
		const isBusinessDay = ptTime.isBusinessDay();
		const hour = ptTime.toObject().hour;

		// return shipping.carrier !== "DPD" || isBusinessDay && hour >= 9 && hour < 16;
		return true;
	};

	const onSubmit = async (e: any) => {
		e.target.disabled = true;
		await onModalSubmit(volume, weight, pickupDay);
		e.target.disabled = false;
	};

	// const buttons = ()  => {
	//     const buttons = [];
	//     let days = 1;
	//     while (buttons.length < 3) {
	//         const datem = moment().add(days, 'days');
	//         const selected = pickupDay === datem.format("YYYY-MM-DD") ? ' selected' : '';
	//         if (!['Sat','Sun'].includes(datem.format("ddd"))) {
	//             buttons.push(
	//                 <Button className={`so-btn-group${selected}`} key={`plus${days}`} value={datem.format("YYYY-MM-DD")}
	//                     onClick={(e) => {
	//                         setPickupDay(e.currentTarget.getAttribute('value')||'')
	//                     }}>
	//                     {datem.format("ddd, MMMM D YYYY")}
	//                 </Button>
	//             );
	//         }
	//         days++;
	//     }
	//     return buttons;
	// }

	return (
		<Modal onModalClose={onModalClose} isOpened={true}>
			<>
				<form className="table-modal-cont" onSubmit={onSubmit}>
					<div className="table-modal-title-box">
						<div>
							<div className="table-modal-title-pricing">Pickup Scheduling</div>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="single-order-text width100">
						<div className="so-details-box">
							<b className="mr1">Carrier:</b> {shipping.carrier}
						</div>
						<div className="so-details-box">
							<b className="mr1">Reference:</b> {shipping.reference}
						</div>
						{isPickUpAvailable() ? (
							<>
								<div className="so-details-box">
									<b className="mr1">Volume Number:</b>
									<input
										className="so-input-modal"
										value={volume}
										onChange={(e) => setVolume(e.target.value)}
									/>
								</div>
								<div className="so-details-box">
									<b className="mr1">Total Weight (Kg):</b>
									<input
										className="so-input-modal"
										type="number"
										step=".001"
										min={0}
										value={weight}
										onChange={(e) => setWeight(parseFloat(e.target.value))}
										required
									/>
								</div>
								<div className="so-pickup-days">
									<b className="mr1">Day for order pickup from:</b>
									{pickUpDay()}
								</div>
							</>
						) : (
							<div className="so-pickup-days in-column width100 items-start">
								<div className="bold light-red">
									The pickup service is temporarily unavailable
								</div>
								<div className="">
									Please come back to schedule the next business day between 9h
									and 16h
								</div>
							</div>
						)}
					</div>
					<div className="table-modal-border"></div>
					{/* <div className='single-order-text width100 in-row align-center justify-start mb2'>
                        <b>Select a day for order pickup:</b>
                    </div>
                    <ButtonGroup size="large" aria-label="large button group" className="pickup-day">
                        {buttons()}
                    </ButtonGroup>
                    <div className="table-modal-border"></div> */}
					<div
						className={`table-modal-form-button-box width100 ${!isPickUpAvailable() && "justify-end"
							}`}
					>
						{isPickUpAvailable() ? (
							<>
								<button
									onClick={onModalClose}
									className="table-modal-form-button-cancel"
								>
									Cancel
								</button>
								<button
									className="table-modal-form-button"
									type="submit"
									onClick={async (e: any) => {
										e.target.disabled = true;
										await onModalSubmit(volume, weight, pickupDay);
										e.target.disabled = false;
									}}
								>
									confirm
								</button>
							</>
						) : (
							<button
								className="table-modal-form-button"
								onClick={onModalClose}
							>
								close
							</button>
						)}
					</div>
				</form>
			</>
		</Modal>
	);
};

export default SingleOrderPickupModal;
