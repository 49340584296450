import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { editMarketplace } from "../../../actions/marketplace";
import useMarketplace from "../../../hooks/useMarketplace";
import ModalHeader from "./ModalHeader";

type Params = { zeoosName: string; };

const StatusOrder: React.FC<RouteComponentProps<Params>> = (props) => {
    const [statusMap, setStatusMap] = useState([] as { fromLabel: string, toLabel: string; }[]);
    const marketplace = useMarketplace(props.match.params.zeoosName);

    useEffect(() => {
        if (!marketplace.data) {
            return;
        }

        setStatusMap(marketplace.data?.statusMap);
    }, [marketplace.data]);

    if (marketplace.isLoading) {
        return <></>;
    }

    return (
        <div className="product-main-cont">
            <ModalHeader zeoosName={props.match.params.zeoosName}>
                <button
                    className="product-header-button"
                    onClick={async () => {
                        try {
                            await editMarketplace({ zeoosName: props.match.params.zeoosName, statusMap });
                            toast.success('Statuses has been modified!');
                            marketplace.invalidate();
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                >
                    save
                </button>
            </ModalHeader>

            <div className="product-cont">
                <h1 className="product-h">Edit Status Order</h1>
                <p className="product-p">Assign platform status order to Zeoos Status Order.</p>
                <div className="product-content">
                    <div className="product-box mb1">
                        <span className="product-content-title">{props.match.params.zeoosName} Status</span>
                        <span className="product-content-title text-aligns ml4">Zeoos status order</span>
                    </div>

                    {statusMap.map((item, index) => (
                        <div className="product-box" key={index}>
                            <div className="product-input-cont">
                                <input
                                    className="product-input"
                                    placeholder="Order Status"
                                    value={item.fromLabel}
                                    onChange={e => setStatusMap(prev => prev.map((prevItem, prevIndex) => (
                                        prevIndex === index ? { ...prevItem, fromLabel: e.target.value } : prevItem
                                    )))}
                                />
                            </div>
                            <img className="ml2 mr2" src="/icons/product-arrow-right.svg" alt="" />
                            <div className="product-input-cont">
                                <TextField
                                    value={item.toLabel}
                                    select
                                    sx={{
                                        minWidth: 300
                                    }}
                                    inputProps={{
                                        className: "product-select",
                                    }}
                                    onChange={e => setStatusMap(prev => prev.map((prevItem, prevIndex) => (
                                        prevIndex === index ? { ...prevItem, toLabel: e.target.value } : prevItem
                                    )))}
                                >
                                    {statuses.map(status => (
                                        <MenuItem className="compa-option" value={status} key={status}>
                                            {status}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                    ))}

                    <div className="product-box" onClick={() => setStatusMap(prev => [...prev, {} as any])}>
                        <div className="product-add-cont-order">
                            <img className="mr2" src="/icons/product-add-circle-blue.svg" alt="" />
                            ADD A NEW STATUS ORDER
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const statuses = [
    "waiting_acceptance",
    "waiting_shipment",
    "shipped",
    "closed",
    "refused",
    "canceled",
    "refunded",
];

export default withRouter(StatusOrder);
