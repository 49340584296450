import React, { useState } from "react";
import { Modal } from "../../../../ui/modal/Modal";
import useVendors from "../../../../../hooks/useVendors";
import { exportPricePointData } from "../../../../../actions/vendor";
import { toast } from "react-toastify";

const ExportSellerOffersModal: React.FC<any> = (props) => {
  const [sellerId, setSellerId] = useState("");
  const sellers = useVendors();

  return (
    <Modal {...props}>
      <div className="table-modal-form-cont p4">
        <div className="table-modal-title-box m0">
          <div className="import-title">Export price & stock</div>
        </div>
        <div style={{ marginBottom: 0 }} className="table-modal-border" />

        <div
          style={{ textAlign: "start", lineHeight: `22px`, margin: `12px 0px` }}
        >
          Select a seller to view the pricing breakdown of <br />
          offers by platform
        </div>

        <div className="width100 items-start justify-between mb5">
          <label className="mapping-modal-text pr2" htmlFor="">
            Seller:
          </label>
          <select
            className="freight-modal-input"
            style={{ width: 230 }}
            onChange={(e) => {
              setSellerId(e.target.value);
            }}
            value={sellerId}
          >
            <option value="">Select seller</option>
            {sellers.data?.map((x: any) => (
              <option value={x._id}>{x.name}</option>
            ))}
          </select>
        </div>

        <div className="width100">
          <button
            className="table-modal-form-button width100"
            onClick={(e) => {
              e.preventDefault();

              exportPricePointData(sellerId).then(() =>
                toast.success(
                  "Success! Check your mailbox, it should be there in seconds."
                )
              );
            }}
          >
            Export
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ExportSellerOffersModal;
