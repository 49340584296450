import Avatar from "@mui/material/Avatar";
import React, { useRef, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import LogoutWrap from "../../utils/logout-wrap/LogoutWrap";
import { NotiesSection } from "./NotiesSection";
import { TicketNoties } from "./TicketNoties";
import { useLocation } from "react-router-dom";
import { UpdateProfile } from "./UpdateProfile";
import "./header.css";

type PathType = {
  path: string;
  label: string;
};

const labels: Array<PathType> = [
  { path: "/password-management", label: " Settings / Password Management" },
  { path: "/billing", label: " Billings" },
  { path: "/payouts/fulfillment", label: " Payouts / Fulfillment" },
  { path: "/payouts/treasury", label: " Payouts / Treasury" },
  { path: "/payouts/batches", label: " Payouts / Payment Batches" },
  { path: "/payouts", label: " Payouts / Fulfilment" },
  { path: "/dashboard", label: "Dashboard" },
  { path: "/platforms", label: " Platforms" },
  { path: "/platform/", label: " Platforms " },
  { path: "/offers/all", label: " Offers / All my offers" },
  { path: "/offers/non-competitive", label: " Offers / Non Competitive" },
  { path: "/offers/without-price", label: " Offers / Offers without price" },
  { path: "/offer-import", label: " Offers / Offer Import Reports" },
  { path: "/seller-information", label: " Seller Information" },
  { path: "/shipping-settings", label: " Shipping Settings" },
  { path: "/comparador", label: " Price comparison" },
  { path: "/orders", label: " Orders" },
  { path: "/long-product-page", label: " Long Product Page" },
  { path: "/singleorder", label: "Single Order" },
  { path: "/singleorderview", label: "Single Order View" },
  { path: "/singleorderpurchase", label: "Single Order Purchase" },
  { path: "/profile", label: "Profile" },
  { path: "/users-management", label: " Settings / User Management" },
  { path: "/reviews", label: " Settings / Reviews" },
  { path: "/translator", label: "Translator" },
  { path: "/requests", label: "Requests & Notifications" },
  { path: "/tax", label: "Tax Mapping" },
  { path: "/notifications", label: "Notifications" },
  { path: "/tickets", label: " Support / Tickets" },
  { path: "/ticket/", label: " Support / Ticket" },
  { path: "/my/catalog/reports", label: " Catalog / Import product reports" },
  {
    path: "/my/catalog/unregistered",
    label: " My Catalog / Unregistered products",
  },
  {
    path: "/single-notification",
    label: " Notifications / Manual Notification",
  },
  {
    path: "/notifications-settings",
    label: "Settings / Notifications Settings ",
  },
  { path: "/pricing-event", label: "Discount" },
  { path: "/pricing", label: "Price Campaings" },
  { path: "/freight", label: "Freight Mapping" },
  { path: "/platform-rates", label: "Platform Rates" },
  { path: "/pim-management", label: "Pim management" },
  { path: "/pim/scheme", label: "Pim scheme" },
  { path: "/country-management", label: " Settings / Country Management" },
  { path: "/sellers", label: " Sellers" },
  { path: "/sellers-onboarding", label: " Sellers" },
  { path: "/demo-request", label: " Sellers" },
  { path: "/sellers-newonboarding", label: " Sellers" },
  { path: "/productInfo", label: " Pim" },
  { path: "/file-management", label: "Syncs / File management" },
  { path: "/products", label: " Products" },
  { path: "/jobs-panel", label: " Syncs / Jobs Panel" },
  { path: "/queue", label: " Syncs / Queue Monitor" },
  { path: "/vendor", label: " Vendor Mapping" },
  { path: "/intelligence", label: " Intelligence" },
  { path: "/intelligence/products", label: " Intelligence" },
  { path: "/intelligence/overview", label: " Intelligence" },
  { path: "/intelligence/settings", label: " Intelligence" },
  { path: "/intelligence/settings-match", label: " Intelligence" },
  { path: "/intelligence/settings-single", label: " Intelligence" },
  { path: "/digital-assets", label: " Digital Assets" },
  { path: "/permission-denied", label: " Permission Denied" },
  { path: "/pim-translation", label: " Settings / PIM Translation" },
  { path: "/history-log", label: " History Log" },
  { path: "/batch-list", label: " Syncs / Export product" },
  { path: "/batch/", label: " Syncs / Export product " },
  { path: "/logistic", label: " Logistic " },
  {
    path: "/my/catalog/integrated",
    label: " My Catalog / Integrated Products ",
  },
  {
    path: "/my/catalog/non-integrated",
    label: " My Catalog / Non-integrated Products ",
  },
  {
    path: "/my/catalog/unregistered",
    label: " My Catalog / Unregistered Products ",
  },
  {
    path: "/catalog/integrated",
    label: " Zeoos Catalog / Integrated Products ",
  },
  {
    path: "/catalog/non-integrated",
    label: " Zeoos Catalog / Non-integrated Products ",
  },
  {
    path: "/catalog/unregistered",
    label: " Zeoos Catalog / Unregistered Products ",
  },
  { path: "/product-batch-log", label: " Batch / Products" },
  { path: "/catalog-export-reports", label: " Catalog / Export Reports" },
];

const Header: React.FC<RouteComponentProps<any>> = (props) => {
  const { user } = useUser() as any;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const sellerName = searchParams.get("sellerName");
  // const [username, setUsername] = useState<string>(user.username);

  const [modal, setModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropDownFocus = (state: boolean) => {
    setOpen(!state);
  };

  const handleClickOutsideDropdown = (e: any) => {
    if (open && !dropdownRef.current?.contains(e.target as Node)) {
      setOpen(false);
    }
  };
  window.addEventListener("click", handleClickOutsideDropdown);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  const getRestLabel = (path: string) => {
    if (
      path.includes("/platform/") ||
      path.includes("/batch/") ||
      path.includes("/productInfo/") ||
      path.includes("/pricing-event/") ||
      path.includes("/ticket/") ||
      path.includes("/product-batch-log/")
    ) {
      const pathArr = path.split("/");
      const lastElementOfPath = pathArr[pathArr.length - 1];

      if (lastElementOfPath === 'category-rate') {
        return `/ ${pathArr[pathArr.length - 2]} / Category Rate`;
      }

      return `/ ${lastElementOfPath}`;
    } else {
      return "";
    }
  };

  const pathname = props.history.location.pathname;
  let label =
    pathname === "/"
      ? "Dashboard"
      : `${labels.find((x: any) => pathname.includes(x.path))?.label
      } ${getRestLabel(pathname)}`;

  if (sellerName) {
    label += `/ ${sellerName}`;
  }

  return (
    <>
      {user ? (
        <div className="navbar-2">
          <div className="container">
            <div className="panel-menu-box">
              <div className="breadcrumbs">
                <Link to="/">
                  <img
                    className="home-image"
                    src="/icons/breadscrumbs-home.svg"
                    alt=""
                  />
                </Link>
                <div className="breadcrumbs-box">
                  <div className="margin-left5">/</div>
                  <div className="margin-left5">{label}</div>
                </div>
              </div>
            </div>
            <div className="header-button-box">
              <TicketNoties />
              <div className="header-circle-cont">
                <NotiesSection />
              </div>
              {/* <div
								onClick={() => {
									setModal(true);
								}}
								className="in-row align-center justify-between pointer"
							>
								<Avatar
										alt={user.username}
										className="profile-bg-image user-avatar"
										src={
											user.profileImage === undefined ? "df" : user.profileImage
										}
										sx={{ width: 40, height: 40, borderRadius: "50%" }}
									/>
								<div className="profile-box-header pointer">
									Hey, {user.username}
								</div>
							</div> */}
              {/* <div>
								<LogoutWrap>
									<button className="log-in-out-btn-header">
										<div>Logout</div>
									</button>
								</LogoutWrap>
							</div> */}
              <div
                className="app-drop-down-container justify-end ml2"
                ref={dropdownRef}
              >
                <button onClick={(e) => handleDropDownFocus(open)}>
                  <Avatar
                    alt={user.username}
                    className="profile-bg-image user-avatar"
                    src={
                      user.profileImage === undefined ? "df" : user.profileImage
                    }
                    sx={{ width: 40, height: 40, borderRadius: "50%" }}
                  />
                </button>
                {open && (
                  <ul>
                    <li
                      style={{ cursor: "default" }}
                      className="in-row align-center justify-start"
                    >
                      <Avatar
                        alt={user.username}
                        className="profile-bg-image user-avatar"
                        src={
                          user.profileImage === undefined
                            ? "df"
                            : user.profileImage
                        }
                        sx={{ width: 40, height: 40, borderRadius: "50%" }}
                      />
                      <span className="email-text">
                        {user.username}
                        <br />
                        {user.email}
                      </span>
                    </li>
                    <div className="border-bottom" />
                    <li
                      onClick={() => {
                        setModal(true);
                      }}
                      className="in-row align-center justify-start not-menuitem-cont "
                    >
                      <span className="email-text">Profile</span>
                    </li>
                    <Link to="/notifications-settings">
                      <li className="in-row align-center justify-start not-menuitem-cont ">
                        <span className="email-text">Settings</span>
                      </li>
                    </Link>
                    <LogoutWrap>
                      <li className="in-row align-center justify-start not-menuitem-cont ">
                        <span className="email-text">Log out</span>
                      </li>
                    </LogoutWrap>
                  </ul>
                )}
              </div>
            </div>
          </div>
          {modal && (
            <UpdateProfile
              modal={modal}
              onModalClose={onModalClose}
              setModal={setModal}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(Header);
