import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { toStringConverter } from "../tables/gestao/UpdateGestao";

interface Props {
  data: any;
}

export const fillLevel = (value: number, total: number) => {
  return value < total ? `${Math.round((value * 100) / total)}%` : "100%";
};

export const Performance: React.FC<Props> = ({ data }) => {
  const totalValores = (amount: any) => {
    return data
      .reduce((prev: any, next: any) => {
        return !next[amount] ? prev + 0 : prev + next[amount];
      }, 0)
      .toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  };

  return (
    <>
      <div className="billing-graph-box">
        <div className="billing-modal-text bold pb1">
          Performance - Amounts Paid
        </div>
        <div className="billing-large-text green">
          {totalValores("valor_recebido")} €
        </div>
        <div className="billing-mid-text grey pb1">
          {totalValores("total_amount")} €
        </div>
        <div className="billing-progress-cont pb1">
          {data.map((item: any, index: number) => (
            <div className="billing-progress-box" key={index}>
              <div className="billing-width-progress billing-text bold">
                #{item.shipment_id}
              </div>
              <Tooltip
                title={`Recebido em #${item.bill_ref}: ${toStringConverter(
                  item.valor_recebido
                )} €`}
                placement="top"
                className="pointer width100"
              >
                <div className="billing-table-line height24">
                  <div
                    className="billing-table-line-active"
                    style={{
                      width: fillLevel(item.valor_recebido, item.total_amount),
                    }}
                  ></div>
                  <div className="billing-table-line-text">
                    {toStringConverter(item.valor_recebido)} €
                  </div>
                </div>
              </Tooltip>
              <div className="billing-width-progress billing-text">
                {toStringConverter(!item.total_amount ? 0 : item.total_amount)}{" "}
                €
              </div>
            </div>
          ))}
        </div>
        <div className="billing-perf-total-cont pt2">
          <div className="billing-perf-total-cont">
            <div className="billing-ball greenbg mr4"></div>
            <div className="billing-text bold grey2">Paid</div>
          </div>
          <div className="billing-perf-total-cont">
            <div className="billing-ball semigreenbg mr4"></div>
            <div className="billing-text bold grey2">Total Invoice</div>
          </div>
        </div>
      </div>
    </>
  );
};
