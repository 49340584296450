import React, { memo, useCallback, useState } from "react";
import { Modal } from "../../ui/modal/Modal";

interface Props {
  jobName: string;
  report: string;
}

const LogReportModal: React.FC<Props> = ({ jobName, report }) => {
  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setModal(false);
    },
    [setModal]
  );

  const onModalOpen = useCallback(
    (e: React.MouseEvent) => {
      setModal(true);
    },
    [setModal]
  );

  return (
    <>
      <div onClick={onModalOpen} className="pointer underline">
        Log Report
      </div>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <>
            <div className="mapping-modal-cont">
              <div className="table-modal-title-box m0">
                <div className="mapping-modal-title-text">Log Report</div>
              </div>
              <form className="table-modal-form-cont">
                <div className="table-modal-border"></div>
                <div className="tax-modal-cat">
                  <div className="mapping-modal-text bold">
                    Job Name: {jobName}
                  </div>
                </div>
                <p
                  dangerouslySetInnerHTML={{ __html: report }}
                  style={{ textAlign: "left" }}
                />
                {/* <table className="mapping-modal-table">
                                    <thead>
                                        <tr className="mapping-modal-results-title justify-between">
                                            <th className="">ACTION</th>
                                            <th className="">STATUS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="mapping-modal-result-box p0 justify-between pt1">
                                            <td className="report-text">Action 1</td>
                                            <td className="in-row align-center">
                                                <div className="report-func-text report-ok">OK</div>
                                                <img src="/icons/report-ok.svg" alt="" />
                                            </td>
                                        </tr>
                                        <tr className="mapping-modal-result-box p0 justify-between pt1">
                                            <td className="report-text">Action 1</td>
                                            <td className="in-row align-center">
                                                <div className="report-func-text report-error">ERROR</div>
                                                <img src="/icons/report-error.svg" alt="" />
                                            </td>
                                        </tr>
                                        <tr className="mapping-modal-result-box p0 justify-between pt1">
                                            <td className="report-text">Action 1</td>
                                            <td className="in-row align-center">
                                                <div className="report-func-text"></div>
                                                <img src="/icons/report-inactive.svg" alt="" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}
                <div className="table-modal-border m0"></div>
                <div className="table-modal-form-button-box width100 m0 justify-center">
                  <button className="table-modal-form-button mt4" type="submit"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setModal(false);
                    }}>
                    CLOSE
                  </button>
                </div>
              </form>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

export default memo(LogReportModal);
