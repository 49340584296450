import React, { useMemo } from "react";
import MultiSelect from "../multi-select";
import useCountries from "../../../hooks/useCountries";
import useActiveVendors from "../../../hooks/useActiveVendors";
import CircularPreloader from "../circular-preloader/CircularPreloader";
import { MenuItem, TextField } from "@mui/material";

interface Props {
  currentConfig: any;
  setCurrentConfig: any;
}

const CatalogSelect: React.FC<Props> = ({
  currentConfig,
  setCurrentConfig,
}) => {
  const vendors = useActiveVendors();
  const onChangeCatalog = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    if (e.target.value === "All Seller Catalogs") {
      setCurrentConfig({ seller: "all" });
    } else if (e.target.value === "Zeoos Catalog") {
      setCurrentConfig({ seller: "" });
    } else {
      setCurrentConfig({
        seller: vendors.data?.find((x: any) => x.name === e.target.value)._id,
      });
    }
  };

  const options = useMemo(() => {
    return [
        "All Seller Catalogs",
        "Zeoos Catalog",
        ...(vendors?.data ? vendors?.data : [])?.map((vendor: any) => vendor.name),
      ]
  }, [vendors])

  return (
    <div className="align-center">
      {!vendors.isLoading ? (
          <TextField
          label={'Catalog:'}
          select
          className="filter-select"
          sx={{ mb: 1 }}
          SelectProps={{
              multiple:false,
              value:
                  currentConfig.seller === "all"
                    ? "All Seller Catalogs"
                    : vendors?.data?.find(
                        (x: any) => x._id === currentConfig.seller
                      )?.name || "Zeoos Catalog"
                ,
              onChange: (e: any) => {
                  e.preventDefault();
                  onChangeCatalog(e)
              },
          }}
      >
          {options.map((option, index) => (
              <MenuItem value={option} className="acompan-option" key={index}>
                  {option}
              </MenuItem>
          ))}
      </TextField>
      ) : (
        <CircularPreloader />
      )}
    </div>
  );
};

export default CatalogSelect;
