import React, { useCallback } from "react";
import { Modal } from "../../../../../ui/modal/Modal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { singleUploadInvoice } from "../../../../../../actions/order";

interface Props {
  seller_id: number;
  order_id: number;
  onModalClose: any;
  onModalSubmit: any;
}

const SingleOrderInvoiceUploadModal: React.FC<Props> = ({
  onModalClose,
  seller_id,
  order_id,
  onModalSubmit
}) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: [".pdf"],
    onDropRejected: () => {
      toast.error('File type rejected. Only ".pdf" files are allowed.');
    },
  });

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      try {
        const data = await singleUploadInvoice(acceptedFiles[0], `${order_id}`, `${seller_id}`);
        onModalSubmit(data);
      } catch (error) {
        console.log(error);
      }
    },
    [acceptedFiles]
  );

  return (
    <Modal onModalClose={onModalClose} isOpened={true}>
      <div className="table-modal-form-cont in-column justify-center p4" style={{width:430}}>
        <div className="table-modal-title-box m0">
          <div className="import-title">Customer Invoice Upload</div>
        </div>
        <div className="table-modal-border"></div>
        <p className="text-start">          
        </p>

        <span className="mt2 in-row width100 align-center">
          <b className="mr1">Add invoice</b>
          (.pdf file)
        </span>
        <div
          className="width100 pointer import-reviews-dropzone"
          style={{ width: "400px", height: "150px", background: "" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <h4>
              <img src="/icons/upload-spreadsheet.svg" alt="" />
              Drop the file here
            </h4>
          ) : (
            <h2 className="pc-modal-dropzone-text">
              {acceptedFiles.length > 0
                ? `Click 'Import' to finish upload ${acceptedFiles[0].name}`
                : <>
                  <img src='/icons/upload-spreadsheet.svg' alt='' />
                  Drop the file here
                </>
              }
            </h2>
          )}
        </div>
        <div className="table-modal-border"></div>
        <div style={{ marginBottom: "0" }} className="table-modal-form-button-box width100">
          <button
            className="sel-modal-form-button-cancel"
            onClick={onModalClose}
          >
            Cancel
          </button>
          <button 
          onClick={(e: React.FormEvent) => {
            onSubmit(e);
          }}
            disabled={!acceptedFiles.length}
            className="table-modal-form-button initial" type="submit">
            Import
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SingleOrderInvoiceUploadModal;
