import axios from "axios";
import { ICombinedOrders } from "../interfaces";
import { getHeaders } from "./user";

export const getOrders = async (body: any) => {
  const res = await axios.post("/fetch-orders", body, getHeaders());

  return res.data.data.orders as ICombinedOrders;
};

export const getOrdersZeoos = async (body: any) => {
  const res = await axios.post("/fetch-orders-zeoos", body, getHeaders());

  return res.data.data.orders as ICombinedOrders;
};

export const getProducts = async () => {
  const res = await axios.get("/fetch-products", getHeaders());

  const data = res.data;

  return data;
};

export const getCombinedProductsBI = async () => {
  const res = await axios.get("/combinedProducts/", getHeaders());

  const data = res.data;

  return data;
};

export const generateProductsBI = async () => {
  const res = await axios.post("/combinedProducts/", {}, getHeaders());

  const data = res.data;

  return data;
};

export const getVendors = async () => {
  const res = await axios.get("/getVendors", getHeaders());

  const data = res.data;

  return data;
};
