import React, { useState } from "react";
import { Modal } from "../../../../../ui/modal/Modal";
import FilterSelect from "../../../../../ui/select/FilterSelect";

const SingleOrderShippingModal: React.FC<any> = ({
	shipping,
	onModalClose,
	onModalSubmit,
  carriers
}) => {
  const [carrier, setCarrier] = useState(carriers[0]);
	const [trackingCode, setTrackingCode] = useState("");
  const [trackingUrl, setTrackingUrl] = useState("");


	const onSubmit = async (e: any) => {
    e.preventDefault();
		e.target.disabled = true;
		await onModalSubmit(carrier, trackingCode, trackingUrl);
		e.target.disabled = false;
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={true}>
			<>
				<form className="table-modal-cont" onSubmit={onSubmit}>
					<div className="table-modal-title-box">
						<div>
							<div className="table-modal-title-pricing">Shipping Info</div>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="single-order-text width100">
						<div className="so-details-box">
							<b className="mr1">Carrier:</b> 
              <FilterSelect
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCarrier(e.target.value)
                }
                value={carrier}
                data={carriers}
							/>
						</div>				
            <div className="so-details-box">
              <b className="mr1">Tracking Code:</b>
              <input
                className="so-input-modal"
                value={trackingCode}
                onChange={(e) => setTrackingCode(e.target.value)}
              />
            </div>
            <div className="so-details-box">
              <b className="mr1">Tracking URL:</b>
              <input
                className="so-input-modal big"
                type="text"                
                value={trackingUrl}
                onChange={(e) => setTrackingUrl(e.target.value)}
              />
            </div>
					</div>
					<div className="table-modal-border"></div>
					<div
						className={`table-modal-form-button-box width100 justify-end}`}
					>
            <button
              onClick={onModalClose}
              className="table-modal-form-button-cancel"
            >
              Cancel
            </button>
            <button
              className="table-modal-form-button"
              type="submit"
            >
              confirm
            </button>
					</div>
				</form>
			</>
		</Modal>
	);
};

export default SingleOrderShippingModal;
