import React, { useState, useContext, useCallback } from "react";
import {
	IMarketplace,
	IPaginatedRequest,
	IProductGestao,
} from "../../../../interfaces";
import { CountriesContext } from "../../../context";
import { ICountryM } from "../../country-management/CountryManagement";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";
import FilterSelect from "../../../ui/select/FilterSelect";
import * as _ from "lodash";
import queryString from "query-string";
import { useQuery } from "react-query";
import {
	getMarketplaceProduct,
	getPaginatedMarketplaces,
	updateMarketplaceProductMarketplaces,
	updateProductsMarketplaces,
} from "../../../../actions/marketplace";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import { toast } from "react-toastify";
import { queryClient } from "../../../App";

interface Props {
	setOpenActive: (openActive: boolean) => void;
	key: string;
	selectedProducts: string[];
	allSelectedFlag: boolean;
	setAllSelectedFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InnerProps extends Props {
	product?: IProductGestao;
}

const ActivateWindowModal: React.FC<InnerProps> = ({
	setOpenActive,
	key,
	selectedProducts,
	product,
	allSelectedFlag,
	setAllSelectedFlag,
}) => {
	const countries = useContext(CountriesContext) as ICountryM[];

	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		platformName: "All platforms",
		type: "All types",
		country: "All countries",
	});
	const [toggledMplc, setToggledMplc] = useState<any>(
		product?.marketplaces || {}
	);
	const [toggledAllMplc, setToggledAllMplc] = useState<any>({});
	const [zeoosNames, setZeoosNames] = useState<string[]>([]);

	const { isLoading: loadMarketplaces, data: marketplaces } = useQuery(
		`marketplaces/admin?${queryString.stringify(currentConfig)}`,
		async () => {
			const marketplaces = await getPaginatedMarketplaces(currentConfig);
			setZeoosNames((prev) =>
				!!prev.length
					? prev
					: [
							"All platforms",
							..._.uniq(
								_.map(marketplaces?.data, (item: any) => item.zeoosName)
							),
					  ]
			);
			return marketplaces;
		}
	);

	const onSaveHandler = async (e: React.MouseEvent) => {
		e.preventDefault();

		try {
			if (product) {
				await updateMarketplaceProductMarketplaces(toggledMplc, product.sku);

				toast.success("Product successfully saved!");
			} else {
				await updateProductsMarketplaces(selectedProducts, toggledAllMplc);

				toast.success("Products successfully saved!");
			}

			setOpenActive(false);
			queryClient.invalidateQueries(key);
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong! Please, reload the page");
		}

		queryClient.invalidateQueries(key);
	};

	const handleChangeSwitch = (
		event: React.ChangeEvent<HTMLInputElement>,
		zeoosName: string
	) => {
		const upd = {
			...product?.marketplaces?.[zeoosName],
			available: event.target.checked,
		};

		setToggledMplc((prev: any) => ({ ...prev, [zeoosName]: upd }));
	};

	const handleChangeAll = useCallback(
		(isActivate: boolean) => (event: React.MouseEvent) => {
			const toggleFn = (prev: any, available?: string) => {
				marketplaces?.data.forEach((item: IMarketplace) => {
					if (!prev[item.zeoosName]) {
						prev[item.zeoosName] = {};
					}

					if (available) {
						prev[item.zeoosName][available] = isActivate;
					} else {
						prev[item.zeoosName] = isActivate;
					}
				});

				return { ...prev };
			};

			if (selectedProducts.length > 1) {
				setToggledAllMplc((prev: any) => toggleFn(prev));
			} else {
				setToggledMplc((prev: any) => toggleFn(prev, "available"));
			}
		},
		[marketplaces?.data, selectedProducts.length]
	);

	const handleClick =
		(zeoosName: string, upd: boolean) => (event: React.MouseEvent) => {
			setToggledAllMplc((prev: any) => ({
				...prev,
				[zeoosName]: upd,
			}));
		};

	return (
		<div className="p4 mwidth550">
			<div className="mr4">
				<div className="align-start bold ft4 black-color">
					Manage {allSelectedFlag ? "All" : selectedProducts.length} selected product platforms
				</div>
			</div>
			<div className="table-modal-border mb2 mt1"></div>
			<div className="in-row align-start justify-start width50">
				<FilterSelect
					onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
						setCurrentConfig((conf: IPaginatedRequest) => ({
							...conf,
							country: e.target.value,
						}))
					}
					value={currentConfig?.country}
					data={[
						"All countries",
						..._.uniq(_.map(countries, (item: any) => item.name)),
					]}
				/>
				<FilterSelect
					onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
						setCurrentConfig((conf: IPaginatedRequest) => ({
							...conf,
							platformName: e.target.value,
						}))
					}
					value={currentConfig?.platformName}
					data={zeoosNames}
				/>
			</div>
			<div className="table-modal-border mb2 mt2"></div>
			{loadMarketplaces ? (
				<div className="width100 height40 align-center justify-center">
					<CircularPreloader />
				</div>
			) : (
				<div className="width100 height40 scroll">
					<table>
						<thead></thead>
						<tbody>
							{marketplaces?.data.map(
								(marketplace: IMarketplace, index: number) => {
									const isAvailable =
										!!toggledMplc?.[marketplace.zeoosName]?.available;

									return (
										<React.Fragment key={marketplace._id}>
											<tr className="in-row justify-between align-center">
												<td className="mwidth220 in-row align-center ft2 black-color mr2">
													<img
														className="mr1"
														width={60}
														height={40}
														src={
															countries.find(
																(country: ICountryM) =>
																	country.name === marketplace.country
															)?.iconUrl
														}
														alt=""
													/>
													<div>{marketplace.zeoosName}</div>
												</td>
												<td className="mwidth150 align-center justify-start ft2 black-color">
													{marketplace.marketplaceName}
												</td>
												<td className="mwidth150 align-center justify-start ft2 black-color">
													{selectedProducts.length > 1 ? (
														<div className="in-row">
															<div
																className="in-row mr5 pointer"
																onClick={handleClick(
																	marketplace.zeoosName,
																	false
																)}
															>
																<img
																	className="mr1"
																	width="20px"
																	height="20px"
																	src={`${
																		toggledAllMplc[marketplace.zeoosName] ===
																		false
																			? "/icons/reject-icon-active.svg"
																			: "/icons/reject-icon.svg"
																	}`}
																	alt=""
																/>
																<div
																	className={`bold ${
																		toggledAllMplc[marketplace.zeoosName] ===
																		false
																			? "red"
																			: "grey"
																	}`}
																>
																	Deactivate
																</div>
															</div>
															<div
																className="in-row pointer"
																onClick={handleClick(
																	marketplace.zeoosName,
																	true
																)}
															>
																<img
																	className="mr1"
																	width="20px"
																	height="20px"
																	src={`${
																		toggledAllMplc[marketplace.zeoosName] ===
																		true
																			? "/icons/accept-icon-active.svg"
																			: "/icons/accept-icon.svg"
																	}`}
																	alt=""
																/>
																<div
																	className={`bold ${
																		toggledAllMplc[marketplace.zeoosName] ===
																		true
																			? "green"
																			: "grey"
																	}`}
																>
																	Activate
																</div>
															</div>
														</div>
													) : (
														<>
															<AntSwitch
																checked={isAvailable}
																name="checkedC"
																onChange={(
																	e: React.ChangeEvent<HTMLInputElement>
																) =>
																	handleChangeSwitch(e, marketplace.zeoosName)
																}
																required
															/>
															<span className="ft2 black-color ml2">
																{isAvailable ? "available" : "unavailable"}
															</span>
														</>
													)}
												</td>
											</tr>
											<div
												className={`${
													marketplaces?.data.length === index + 1
														? ""
														: "table-modal-border mt2"
												}`}
											></div>
										</React.Fragment>
									);
								}
							)}
						</tbody>
					</table>
				</div>
			)}
			<div className="table-modal-border mt2"></div>
			<div className="in-row justify-between align-center">
				<div className="in-row">
					<button
						type="button"
						className="mass-import-button maxwidth100"
						onClick={handleChangeAll(true)}
					>
						Activate all
					</button>
					<button
						type="button"
						className="mass-import-button maxwidth100 ml2"
						onClick={handleChangeAll(false)}
					>
						Deactivate all
					</button>
				</div>
				<div>
					<button className="filter-apply-btn mb-none" onClick={onSaveHandler}>
						Save and close
					</button>
				</div>
			</div>
		</div>
	);
};

const ActivateWindow: React.FC<Props> = ({ ...props }) => {
	const marketplaceProduct = useQuery(
		`marketplaceProductActivateWindow?sku=${props.selectedProducts[0]}`,
		async () => {
			if (props.selectedProducts.length !== 1) {
				return;
			}

			return await getMarketplaceProduct({ sku: props.selectedProducts[0] });
		}
	);

	if (marketplaceProduct.isLoading) {
		return <></>;
	}

	return <ActivateWindowModal product={marketplaceProduct.data} {...props} />;
};

export default ActivateWindow;
