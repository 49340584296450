import React, { useCallback, useContext, useState } from "react";
import { IReview } from "../../../../actions/reviews";
import { ReviewsDelete } from "./ReviewsDelete";
import { Modal } from "../../../ui/modal/Modal";
import { CreateOrEditReviewForm } from "../create-review/CreateReview";
import { CountriesContext } from "../../../context";
import { Link } from "react-router-dom";
import { ICountryM } from "../../country-management/CountryManagement";
import Paginator from "../../../ui/pagination";
import { IPaginatedRequest } from "../../../../interfaces";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";

interface Props {
  reviews: any;
  loadReviews: boolean;
  rqKeyReviews: string;
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  currentConfig: IPaginatedRequest;
}

const RenderReviews: React.FC<Props> = ({
  reviews,
  loadReviews,
  rqKeyReviews,
  setCurrentConfig,
  currentConfig,
}) => {
  const countries = useContext(CountriesContext) as ICountryM[];

  const [openEdit, setOpenEdit] = useState(false);
  const [selectedReview, setSelectedReview] = useState<IReview>();

  const onChangePage = useCallback(
    async (config: IPaginatedRequest) => {
      setCurrentConfig((c: IPaginatedRequest) => {
        return {
          ...c,
          ...config,
        };
      });
    },
    [setCurrentConfig]
  );

  const findACountryImg = (country: string) => {
    if (!country || country === "") {
      return "https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/no-country.svg";
    } else {
      return countries.find((x: ICountryM) => x.name === country)?.iconUrl;
    }
  };

  return (
    <div className="table-main-cont">
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search review"
                    onChange={(e) =>
                      setCurrentConfig((conf: IPaginatedRequest) => ({
                        ...conf,
                        search: e.target.value,
                      }))
                    }
                    value={currentConfig.search}
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-main-title-text pl2">
              {loadReviews ? (
                <CircularPreloader />
              ) : (
                <span className="table-main-title-text">
                  {reviews?.total} reviews of all clients
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="marketplace-pagination">
          <Paginator
            data={!loadReviews ? reviews : {}}
            refetch={onChangePage as any}
          />
        </div>
      </div>
      <div style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{
            position: "sticky",
            zIndex: 101,
            top: "2px",
            width: "100%",
          }}
        >
          <thead>
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="rew-img"></th>
              <th className="rew-mins">SKU</th>
              <th className="rew-mins">Origin</th>
              <th className="rew-mins">Country</th>
              <th className="rew-meds">Reviewer’s Name</th>
              <th className="rew-lgs">Title</th>
              <th className="rew-minc">Content</th>
              <th className="rew-minc">Rating</th>
              <th className="rew-action justify-center">Actions</th>
            </tr>
          </thead>
        </table>
        <table className="table-results mb2" style={{ minHeight: "auto" }}>
          <tbody>
            {reviews?.data.map((review: IReview, index: number) => (
              <tr className="table-result-box height100px" key={index}>
                <td className="rew-img">
                  <Link to={`/productInfo/${review.sku}`}>
                    <img
                      className="gestao-table-image"
                      src={review.product?.image}
                      alt=""
                    />
                  </Link>
                </td>
                <td className="rew-mins">{review.sku}</td>
                <td className="rew-mins">{review.origin}</td>
                <td className="rew-mins">
                  <div className="in-row align-center">
                    <img
                      className="mr2"
                      src={findACountryImg(review?.country as string)}
                      alt=""
                      width="30px"
                    />
                  </div>
                </td>
                <td className="rew-meds">{review.name}</td>
                <td className="rew-lgs">{review.title}</td>
                <td
                  className="rew-minc bold underline pointer"
                  onClick={() => {
                    setSelectedReview(review);
                    setOpenEdit(true);
                  }}
                >
                  See
                </td>
                <td className="rew-minc">{review.rating}</td>
                <td className="rew-action justify-evenly">
                  <img
                    className="pointer"
                    src="/icons/grey-edit.svg"
                    alt=""
                    onClick={() => {
                      setOpenEdit(true);
                      setSelectedReview(review);
                    }}
                  />
                  <ReviewsDelete review={review} rqKeyReviews={rqKeyReviews} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {openEdit && (
          <Modal onModalClose={() => setOpenEdit(false)} isOpened={openEdit}>
            <CreateOrEditReviewForm
              rqKeyReviews={rqKeyReviews}
              review={selectedReview}
              setModal={setOpenEdit}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default RenderReviews;
