import React, { useDeferredValue, useEffect, useMemo, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import SellerProductInventory from "./SellerProductInventory";
// import SellerProductContryPrice from "./broken_SellerProductContryPrice";
// import SellerProductContryPrice from "./SellerProductContryPrice";
import { withStyles } from "@material-ui/core/styles";
import {
  Link,
  NavLink,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import {
  usePaginatedVendorProducts,
  useSingleVendor,
  // useVendorProducts,
} from "../../../../hooks/useVendors";
import Preloader from "../../../ui/preloader/Preloader";
import { countProducts } from "../VendorsManagement";
import { IPaginatedRequest, IVendor } from "../../../../interfaces";
import useTaxes from "../../../../hooks/useTaxes";
import { CreateEditVendor } from "../CreateEditVendor";
import { updateVendor } from "../../../../actions/vendor";
import MassUpdateCountryPrice from "./MassUpdateCountryPrice";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import { toast } from "react-toastify";
import { Role } from "../../../../interfaces";
import { exportPricePointData } from "../../../../actions/vendor";
import { GestaoFilter } from "../../tables/gestao/GestaoFilter";
import useSanitizedMarketplaces from "../../../../hooks/useSanitizedMarketplaces";
import useActiveVendors from "../../../../hooks/useActiveVendors";
import { STOCK_CONDITIONS } from "types";
import { useDebouncedValue } from "@mantine/hooks";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import { SellerProductPlatforms } from "../seller-platforms/SellerProductPlatforms";
import { PimMainTable } from "../../pim/PimMainTable";
import {
  exportProductResults,
  getSellersCatalog,
  useProducts,
} from "../../../../actions/v2/product";
import { useParamsState } from "hooks";
import { ProductRequest } from "types/product";
import useUser from "../../../../hooks/useUser";
import { useAtom } from "jotai";
import { selectedProductsAtom } from "../../tables/gestao/Gestao";
import { CatalogFilter } from "../../catalog/filter";
import {
  exportMyPriceStockData,
  exportPriceStockData,
} from "../../../../actions/pim";
import { TableGestao } from "../../tables/gestao/TableGestao";
import { useOffers } from "../../../../actions/v2/offer";
import { OfferRequest } from "types/offer";
import OfferFilter from "../../tables/gestao/filter";
import { ImportNewProductsModal } from "../../catalog/sub-header";
import UploadPriceStockModal from "../../tables/gestao/UploadPriceStockModal";
import SellerCreateEditModal from "../SellerCreateEditModal";
import queryString from "query-string";

const AntTabPanel = withStyles({
  root: {
    background: "#e8edf2 !important",
    padding: "0 !important",
  },
})(TabPanel);

const checkTab = (path: string) => {
  switch (path.split("/").pop()) {
    case "products":
      return "1";
    case "platforms":
      return "2";
    default:
      return "1";
  }
};

const SellerProduct: React.FC<RouteComponentProps<{ id: string; }>> = (
  props
) => {
  const [modal, setModal] = useState<boolean>(false);
  const [modalImport, setModalImport] = useState<boolean>(false);
  const [modalEditSeller, setModalEditSeller] = useState<boolean>(false);
  const [modalMassUpdate, setModalMassUpdate] = useState<boolean>(false);
  const [modalOffers, setModalOffers] = useState<boolean>(false);
  const [value, setValue] = React.useState(checkTab(props.location.pathname));
  const [modalNewOnboarding, setModalNewOnboarding] = useState<boolean>(false);
  const [isExportingCatalog, setIsExportingCatalog] = useState(false);

  const vendor = useSingleVendor(props.match.params.id);

  const isVendorDataLoaded = vendor.data && vendor.data._id;

  const [currentConfig, setCurrentConfig] = useParamsState<
    ProductRequest & OfferRequest & { sellerId?: string; sellerName?: string; }
  >({
    perPage: 10,
    page: 1,
  });
  const debouncedConfig = useDeferredValue(currentConfig);
  const [open, setOpen] = useState<boolean>(false);

  const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);
  // const products = useVendorProducts(props.match.params.id);
  //Lucio comment here

  // eslint-disable-next-line
  //   const { isLoading: productsLoading, data: products } =
  //     usePaginatedVendorProducts(props.match.params.id, debouncedConfig);


  useEffect(() => {
    if (isVendorDataLoaded) {
      setCurrentConfig({
        seller: vendor.data._id,
        sellerName: vendor.data.name,
      });
    }
  }, [isVendorDataLoaded]);

  const { isLoading, data: products } = useProducts({
    ...debouncedConfig,
    ...(isVendorDataLoaded ? { seller: vendor.data._id || "myseller" } : {}),
  });

  // setCurrentConfig({seller: vendor.data._id})

  // console.log(debouncedConfig);

  const offers = useOffers({
    ...debouncedConfig,
    ...(isVendorDataLoaded ? { seller: vendor.data._id || "myseller" } : {}),
  });

  const taxes = useTaxes();
  const marketplaces = useSanitizedMarketplaces();
  const vendors = useActiveVendors() as any;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  const listCategories = (vendor: IVendor) => {
    const lista: string[] = [];
    const show = 3;
    vendor?.categories.map((c: any) => lista.push(c.label));

    const plus =
      lista.length > show ? ` + ${lista.length - show} categories` : "";

    return lista.slice(0, show).join(", ") + plus;
  };

  const exportOffer = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await exportPriceStockData();
      toast.success("Success! You will receive your offers by email.");
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  const rqKeyOnboardingSellers = useMemo(() => {
    return `/onboarding-sellers?${queryString.stringify(debouncedConfig)}`;
  }, [debouncedConfig]);

  const exportVendorOffer = async () => {
    try {
      await exportPricePointData(vendor.data._id);
      toast.success(
        "Success! Check your mailbox, it should be there in seconds."
      );
    } catch (error) {
      toast.error(
        "Error! Please try again"
      );
      console.log("Error exporting vendor offer", error);
    }
  };
  const exportSellerCatalog = async () => {
    if (isExportingCatalog) return;
    setIsExportingCatalog(true);
    try {
      await getSellersCatalog(vendor.data._id);
      toast.success(
        "Success! Check your mailbox, it should be there in seconds."
      );
    } catch (error) {
      toast.error(
        "Error! Please try again"
      );
      console.log("Error exporting seller catalog", error);
    } finally {
      setIsExportingCatalog(false);
    }
  };

  return (
    <>
      {modalNewOnboarding ? (
        <SellerCreateEditModal
          rqKey={rqKeyOnboardingSellers}
          setModal={setModalNewOnboarding}
          seller={vendor.data}
          label={"Edit Vendor"}
          tab="onboarding"
        />
      ) : (
        <div className="main-body">
          <div className="main-container">
            {!vendor.isLoading &&
              !taxes.isLoading &&
              !marketplaces.isLoading ? (
              <>
                <div className="s-users-management-container">
                  <div className="table-main-cont">
                    <div className="single-billing-nav pointer m0">
                      <Link className="in-row align-center" to={`/sellers`}>
                        <img
                          src="/icons/billing-arrow-back.svg"
                          alt=""
                          width="25px;"
                        />
                        <div className="table-subtext underline pl2">
                          back to Sellers
                        </div>
                      </Link>
                    </div>
                    <div className="table-title-cont p0 mt4 mb4">
                      <div className="mplc-filter-cont width100">
                        <div className="in-row align-center">
                          {vendor.data?.vendorIcon && (
                            <img
                              width="150px"
                              className="mr4"
                              src={vendor.data?.vendorIcon}
                              alt=""
                            />
                          )}
                          <div className="in-column align-start justify-start pl4">
                            <h2 className="seller-product-text">
                              #{vendor.data?.id} {' '}
                              {vendor.data?.name}
                            </h2>
                            <div className="seller-product-subtext">
                              Products in
                              <b
                                onClick={(e) => {
                                  setModal(true);
                                }}
                                className="seller-product-subtext-blue pointer"
                              >
                                {` ${listCategories(vendor.data)}`}
                              </b>
                            </div>
                          </div>
                        </div>
                        <div className="in-column mr4 mt4 mb1 align-end">
                          <button
                            className="tax-title-button pointer pim-avaliacoes-title-button-text mb4"
                            onClick={(e) => {
                              setModalNewOnboarding(true);
                            }}
                          >
                            EDIT
                          </button>
                          {/* <img
                            style={{ cursor: "pointer" }}
                            src="/icons/sellers-contact.svg"
                            alt=""
                            onClick={(e) => {
                              setModalNewOnboarding(true);
                            }}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="table-title-cont p0 mt4 mb4">
                      <div className="mplc-filter-cont width100">
                        <div className="in-row align-center">
                          <TabContext value={value}>
                            <TabList
                              onChange={handleChange}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                className={`seller-product-tab ${value === "1" && "active-tab-default"
                                  }`}
                                label="Catalog"
                                value="1"
                              />
                              <Tab
                                className={`seller-product-tab ${value === "2" && "active-tab-default"
                                  }`}
                                label="Offers"
                                value="2"
                              />
                              <Tab
                                className={`seller-product-tab ${value === "3" && "active-tab-default"
                                  }`}
                                label="Platforms"
                                value="3"
                              />
                            </TabList>
                          </TabContext>
                        </div>
                        {value === "1" ? (
                          <div className="in-row align-center">
                            <div className="">
                              {/* <SellerProductFilter /> */}
                              {/* <GestaoFilter
                            marketplaces={marketplaces?.data || []}
                            vendors={[]}
                            currentConfig={currentConfig}
                            setCurrentConfig={setCurrentConfig}
                          /> */}
                            </div>
                            <div style={{ width: "160px" }}>
                              <CatalogFilter
                                currentConfig={currentConfig}
                                setCurrentConfig={setCurrentConfig}
                              />
                            </div>
                            <div className="single-main-title-end">
                              <div className="in-row">
                                <div className="app-drop-down-container ml2">
                                  <button
                                    style={{
                                      border: "1px solid #ACACAC",
                                      width: "120px",
                                    }}
                                    className="main-select-bi no-shadow p1"
                                    onClick={() => setOpen((p) => !p)}
                                  >
                                    <img
                                      src="/icons/not-set-dotts.svg"
                                      alt=""
                                    />
                                    <span className="email-text">More</span>
                                    <img
                                      width="14px"
                                      src="/icons/down-arrow.png"
                                      alt=""
                                    />
                                  </button>

                                  {open && (
                                    <ul
                                      style={{
                                        marginLeft: "-80px",
                                        boxShadow:
                                          "0px 0px 4px 0px rgba(0, 0, 0, 0.50)",
                                        borderRadius: "5px",
                                        zIndex: "1099",
                                      }}
                                    >
                                      {/* <li
                                        style={{
                                          padding: "10px 5px",
                                          width: "200px",
                                        }}
                                        className="in-row align-center not-menuitem-cont"
                                        onClick={() =>
                                          exportPriceStockData().then(() =>
                                            toast.success(
                                              "Success! Check your mailbox, it should be there in seconds."
                                            )
                                          )
                                        }
                                      >
                                        <span className="not-menuitem-text">
                                          Export my catalog
                                        </span>
                                      </li> */}
                                      <li
                                        style={{
                                          padding: "10px 5px",
                                          width: "200px",
                                        }}
                                        className="in-row align-center not-menuitem-cont"
                                        onClick={exportSellerCatalog}
                                      >
                                        <span className="not-menuitem-text">
                                          {isExportingCatalog ? "Exporting..." : "Export Catalog"}
                                        </span>
                                      </li>
                                      <li
                                        onClick={() => {
                                          setModalImport(true);
                                        }}
                                        style={{
                                          padding: "10px 5px",
                                          width: "200px",
                                        }}
                                        className="in-row align-center not-menuitem-cont"
                                      >
                                        <span className="not-menuitem-text">
                                          Import products
                                        </span>
                                      </li>
                                      <NavLink
                                        className="width100"
                                        to={`/my/catalog/reports?seller=${currentConfig.seller}`}
                                      >
                                        <li
                                          style={{
                                            padding: "10px 5px",
                                            width: "200px",
                                          }}
                                          className="in-row align-center not-menuitem-cont"
                                        >
                                          <span className="not-menuitem-text">
                                            Product import reports
                                          </span>
                                        </li>
                                      </NavLink>
                                      <li
                                        onClick={() => {
                                          exportProductResults()
                                            .then(() =>
                                              toast.success(
                                                "Success! Check your mailbox in some minutes."
                                              )
                                            )
                                            .catch(() =>
                                              toast.error(
                                                "Something went wrong."
                                              )
                                            );
                                        }}
                                        style={{
                                          padding: "10px 5px",
                                          width: "200px",
                                        }}
                                        className="in-row align-center not-menuitem-cont"
                                      >
                                        <span className="not-menuitem-text">
                                          Export results
                                        </span>
                                      </li>
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="in-row align-center">
                            <div className=""></div>
                            <div style={{ width: "160px" }}>
                              <OfferFilter
                                currentConfig={currentConfig}
                                setCurrentConfig={setCurrentConfig}
                              />
                            </div>
                            <div className="single-main-title-end">
                              <div className="in-row">
                                <div className="app-drop-down-container ml2">
                                  <button
                                    style={{
                                      border: "1px solid #ACACAC",
                                      width: "120px",
                                    }}
                                    className="main-select-bi no-shadow p1"
                                    onClick={() => setOpen((p) => !p)}
                                  >
                                    <img
                                      src="/icons/not-set-dotts.svg"
                                      alt=""
                                    />
                                    <span className="email-text">More</span>
                                    <img
                                      width="14px"
                                      src="/icons/down-arrow.png"
                                      alt=""
                                    />
                                  </button>

                                  {open && (
                                    <ul
                                      style={{
                                        marginLeft: "-80px",
                                        boxShadow:
                                          "0px 0px 4px 0px rgba(0, 0, 0, 0.50)",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <li
                                        style={{
                                          padding: "10px 5px",
                                          width: "200px",
                                        }}
                                        onClick={() => {
                                          setModalOffers(true);
                                        }}
                                        className="in-row align-center not-menuitem-cont"
                                      >
                                        <span className="not-menuitem-text">
                                          Update price and stock
                                        </span>
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 5px",
                                          width: "200px",
                                        }}
                                        className="in-row align-center not-menuitem-cont"
                                        onClick={exportOffer}
                                      >
                                        <span className="not-menuitem-text">
                                          Export my catalog
                                        </span>
                                      </li>
                                      <NavLink
                                        className="width100"
                                        to={`/offer-import?seller=${currentConfig.seller}`}
                                      >
                                        <li
                                          style={{
                                            padding: "10px 5px",
                                            width: "200px",
                                          }}
                                          className="in-row align-center not-menuitem-cont"
                                        >
                                          <span className="not-menuitem-text">
                                            Offer import reports
                                          </span>
                                        </li>
                                      </NavLink>
                                      <RoleRequired>
                                        <li
                                          style={{
                                            padding: "10px 5px",
                                            width: "200px",
                                          }}
                                          className="in-row align-center not-menuitem-cont"
                                          onClick={exportVendorOffer}
                                        >
                                          <span className="not-menuitem-text">
                                            Export Vendor Offers
                                          </span>
                                        </li>
                                      </RoleRequired>
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-main-cont">
                  {!isLoading ? (
                    <TabContext value={value}>
                      <AntTabPanel
                        style={{ background: "#e8edf2 !important" }}
                        value="1"
                      >
                        <PimMainTable
                          label="Products"
                          products={products!}
                          setCurrentConfig={setCurrentConfig}
                          currentConfig={currentConfig}
                          selectedProducts={selectedProducts}
                          setSelectedProducts={setSelectedProducts}
                          isLoading={isLoading}
                          subHeader={false}
                        />
                      </AntTabPanel>
                      <AntTabPanel value="2">
                        <TableGestao
                          vendors={vendor.data}
                          products={offers.data!}
                          refetchProducts={offers.refetch}
                          loadProducts={offers.isLoading}
                          marketplaces={marketplaces as any}
                          currentConfig={currentConfig}
                          setCurrentConfig={setCurrentConfig}
                        />
                      </AntTabPanel>
                      <AntTabPanel value="3">
                        <SellerProductPlatforms
                          vendor={vendor.data}
                          marketplaces={
                            !marketplaces.isLoading ? marketplaces.data : []
                          }
                        />
                      </AntTabPanel>
                    </TabContext>
                  ) : (
                    <CircularPreloader />
                  )}
                </div>
              </>
            ) : (
              <Preloader />
            )}
            {modal && (
              <Modal onModalClose={onModalClose} isOpened={modal}>
                <div className="mapping-modal-cont">
                  <div className="table-modal-form-cont">
                    <div className="freight-modal-table-box">
                      <table className="mapping-modal-table">
                        <thead>
                          <tr className="mapping-modal-results-title justify-between">
                            <th>Category</th>
                            <th>Products</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vendor.data.categories.map((cat: any) => (
                            <tr className="mapping-modal-result-box justify-between">
                              <td style={{ textAlign: "left" }}>{cat.label}</td>
                              <td>{cat.count}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
            {modalOffers && (
              <UploadPriceStockModal
                onModalClose={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  setModalOffers(false);
                }}
                isOpened={modalOffers}
                setModal={setModalOffers}
              />
            )}
            <ImportNewProductsModal
              modal={modalImport}
              setModal={setModalImport}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(SellerProduct);
