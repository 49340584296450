import { useMemo } from "react";
import { useQuery } from "react-query";
import { IPaginatedRequest, ReactQueryConfig } from "../interfaces";
import {
	getVendors,
	getVendor,
	getVendorProducts,
	getPaginatedVendorProducts,
} from "../actions/vendor";
import { queryClient } from "../components/App";
import queryString from "query-string";
import useUser from "./useUser";
import { Role } from "../interfaces";

function useVendors(config?: ReactQueryConfig) {
	const { user } = useUser();
	const key = useMemo(() => `vendors`, []);
	const data = useQuery(key, async () => await getVendors(), {
		refetchOnWindowFocus: false,
		enabled: user.role === Role.admin || user.role === Role.user,
		...config,
	});

	return useMemo(
		() => ({
			...data,
			invalidate: () => queryClient.invalidateQueries(key),
			key,
		}),
		[data, key]
	);
}

export function useSingleVendor(id: string, config?: ReactQueryConfig) {
	const { user } = useUser();
	const key = useMemo(() => `singleVendor/${id}`, [id]);
	const data = useQuery(key, async () => await getVendor(id), {
		enabled: user.role === Role.admin,
		...config,
	});

	return useMemo(
		() => ({
			...data,
			invalidate: () => queryClient.invalidateQueries(key),
		}),
		[data, key]
	);
}

export function useVendorProducts(id: string, config?: ReactQueryConfig) {
	const { user } = useUser();
	const key = useMemo(() => `vendorProducts/${id}`, [id]);
	const data = useQuery(key, async () => await getVendorProducts(id), {
		enabled: user.role === Role.admin,
		...config,
	});

	return useMemo(
		() => ({
			...data,
			invalidate: () => queryClient.invalidateQueries(key),
		}),
		[data, key]
	);
}

export function usePaginatedVendorProducts(
	id: string,
	config: IPaginatedRequest
) {
	const { user } = useUser();
	const key = useMemo(
		() => `paginatedVendorProducts/${id}?${queryString.stringify(config)}`,
		[id, config]
	);
	const data = useQuery(
		key,
		async () =>
			await getPaginatedVendorProducts(id, config as IPaginatedRequest),
		{
			enabled: user.role === Role.admin,
		}
	);

	return useMemo(
		() => ({
			...data,
			invalidate: () => queryClient.invalidateQueries(key),
		}),
		[data, key]
	);
}

export default useVendors;
