import React from "react";
import "./businessIntelligence.css";
import { OrderDetails } from "./OrderDetails";
import { LineChart } from "./LineChart";
import { Top5Chart } from "./Top5Chart";
import { columnsB2C } from "./B2CMarketplace";
import { ICombinedOrders, ICombinedOrder } from "../../../interfaces";
import { PSPChart } from "./PSPChart";

interface Props {
	orders: ICombinedOrders;
	ordersWaiting: number;
	avgOrderCounter: (array: ICombinedOrder[]) => string | number;
	grossValueCounter: (array: ICombinedOrder[]) => string | number;
	countryb2b: string;
	category: string;
}

export const B2BMarketplace: React.FC<Props> = ({
	orders,
	ordersWaiting,
	avgOrderCounter,
	grossValueCounter,
	countryb2b,
	category
}) => {
	function addb2bMarketplace(array: ICombinedOrder[]) {
		array.forEach((x: ICombinedOrder) => (x.sale_marketplace_account = "B2B"));
		return array;
	}

	const filteredByCountry =
		countryb2b === "All Countries"
			? orders.b2b
			: orders.b2b.filter((x: ICombinedOrder) => x.country === countryb2b);

	const filteredByCategory =
		category === "All Categories"
			? orders.b2b.flatMap((x: ICombinedOrder) => x.sale_line_ids)
			: orders.b2b
				.flatMap((x: ICombinedOrder) => x.sale_line_ids)
				.filter((x: any) => x.category_id === category);

	const totalOrdersB2B = filteredByCountry.length.toLocaleString("de-DE");
	const avgOrderValueB2B = avgOrderCounter(filteredByCountry);
	const grossValueB2B = grossValueCounter(filteredByCountry);

	return (
		<>
			<div className="bi__cards_group">
				<OrderDetails
					orders={addb2bMarketplace(filteredByCountry)}
					totalOrders={totalOrdersB2B}
					ordersWaiting={ordersWaiting}
					columns={columnsB2B}
				/>
				<div className="bi__card">
					<div className="bi__card_title">GMV</div>
					<div className="bi__card_content_avg">
						<div>
							<span>{grossValueB2B} </span>
							<span className="bi__card_content_avg_currency">€</span>
						</div>
					</div>
				</div>
				<div className="bi__card">
					<div className="bi__card_title">Average Order Value</div>
					<div className="bi__card_content_avg">
						<div>
							<span>{avgOrderValueB2B} </span>
							<span className="bi__card_content_avg_currency">€</span>
						</div>
					</div>
				</div>
				<LineChart array={filteredByCountry} />
				<Top5Chart
					orders={filteredByCountry}
					orders2={filteredByCategory}
					category={category}
				/>
				<PSPChart ordersList={filteredByCountry} />
			</div>
		</>
	);
};

const b2bBlackList = [
	"sale_lengow_status",
	"sale_marketplace_account",
	"sale_lengow_id",
];

const columnsB2B = columnsB2C.filter(
	(x: any) => !b2bBlackList.includes(x.field)
);
