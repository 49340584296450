import useTicketNotifications from "../../../hooks/useTicketNotifications";
import { NavLink } from "react-router-dom";

export const TicketNoties = () => {
	const { data } = useTicketNotifications();

	return (
		<NavLink style={{ height: "40px" }} to="/tickets">
			<div className="header-circle-cont">
				<div className="header-circle border-none">
					<img width={40} src="/icons/new-ticket.svg" alt="" />
					<div className="new-notification">
						<img src="/icons/new-notification.svg" width="18px" alt="" />
						<div className="number-notifications">{data}</div>
					</div>
				</div>
			</div>
		</NavLink>
	);
};
