import React, { useCallback, useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  createReview,
  updateReview,
  IReview,
} from "../../../../actions/reviews";
import { Modal } from "../../../ui/modal/Modal";
import Rating from "@material-ui/lab/Rating";
import Box from "@mui/material/Box";
import { CountriesContext } from "../../../context";
import * as _ from "lodash";
import { ICountryM } from "../../country-management/CountryManagement";
import { useMutation, useQuery } from "react-query";
import Preloader from "../../../ui/preloader/Preloader";
import { getSanitizedMarketplaces } from "../../../../actions/marketplace";
import { IMarketplace } from "../../../../interfaces";
import { queryClient } from "../../../App";

export interface CreateOrEditReviewFormProps {
  review?: IReview | null;
  setModal: (modal: boolean) => void;
  rqKeyReviews?: string;
}

export const CreateOrEditReviewForm: React.FC<CreateOrEditReviewFormProps> = ({
  review,
  setModal,
  rqKeyReviews,
}) => {
  const countries = useContext(CountriesContext) as ICountryM[];

  const [sku, setSku] = useState(review?.sku || "");
  const [origin, setOrigin] = useState(review?.origin || "");
  const [name, setName] = useState(review?.name || "");
  const [title, setTitle] = useState(review?.title || "");
  const [country, setCountry] = useState(review?.country || "");
  const [content, setContent] = useState(review?.content || "");
  const [rating, setRating] = useState(review?.rating || 5);

  const { isLoading, data: marketplaces } = useQuery(
    "sanitizedMarkeplaces",
    () => getSanitizedMarketplaces()
  );

  const newData = useMemo(
    () => ({
      sku,
      origin,
      name,
      title,
      country,
      content,
      rating,
    }),
    // eslint-disable-next-line
    [sku, origin, name, title, country, content, rating, countries]
  );

  const refetchReviewConfig = {
    onSuccess: () => {
      queryClient.invalidateQueries(rqKeyReviews);
      toast.success(
        `The review is successfully ${review ? "updated" : "created"}!`
      );
    },
    onError: () => {
      toast.error("Something went wrong!");
    },
  };

  const createMutation = useMutation(
    () => createReview(newData as IReview),
    refetchReviewConfig
  );

  const editMutation = useMutation(
    () => updateReview(review?._id as string, newData as IReview),
    refetchReviewConfig
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (review) {
        await editMutation.mutateAsync();
      } else {
        await createMutation.mutateAsync();
      }

      setModal(false);
    },
    [createMutation, setModal, editMutation, review]
  );

  const createCountriesArray = (array: ICountryM[]) => {
    return [
      "Select country",
      ..._.uniq(_.map(array, (item: ICountryM) => item.name)),
    ];
  };

  if (isLoading) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="table-modal-cont">
      <div className="table-modal-title-box m0">
        <div className="pim-modal-title-ava">Avaliar produto</div>
      </div>
      <form className="table-modal-form-cont">
        <div className="table-modal-border"></div>
        <div className="in-row width100 mb4">
          <div className="in-column width100 pl2 align-start justify-start">
            <div className="pim-modal-text">SKU:</div>
            <input
              className="pim-modal-input"
              required
              value={sku}
              onChange={(e) => setSku(e.target.value)}
            />
          </div>
          <div className="in-column width100 pl2 align-start justify-start">
            <div className="pim-modal-text">Rewiewer's Name:</div>
            <input
              className="pim-modal-input width100"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="in-row width100">
          <div className="in-column width100 pl2 align-start justify-start">
            <div className="pim-modal-text">Origin:</div>
            <select
              className="pim-count-select m0 width100"
              value={origin}
              onChange={(e) => {
                if (e.target.value === "Select origin") {
                  setOrigin("");
                } else {
                  setOrigin(e.target.value);
                }
              }}
            >
              {[
                "Select origin",
                ...marketplaces.map((mplс: IMarketplace) => mplс.zeoosName),
              ].map((marketplace: string, index: number) => (
                <option className="pim-title-select-option" key={index}>
                  {marketplace}
                </option>
              ))}
            </select>
          </div>
          <div className="in-column width100 pl2 align-start justify-start">
            <div className="pim-modal-text">Country:</div>
            <select
              className="pim-count-select m0 width100"
              value={country}
              onChange={(e) => {
                if (e.target.value === "Select country") {
                  setCountry("");
                } else {
                  setCountry(e.target.value);
                }
              }}
            >
              {createCountriesArray(countries).map(
                (country: string, index: number) => (
                  <option className="pim-title-select-option" key={index}>
                    {country}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
        <div className="in-column width50 pl2 align-start justify-start mt2">
          <div className="pim-modal-text">Rating:</div>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <Rating
              value={rating}
              onChange={(e, newValue: any) => {
                setRating(newValue);
              }}
            />
          </Box>
        </div>
        <div className="in-column width100 pl2 align-start justify-start">
          <div className="pim-modal-text">Title:</div>
          <input
            className="pim-modal-text pim-modal-input width100"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="in-column width100 pl2 align-start justify-start mt2">
          <div className="pim-modal-text">Content:</div>
          <textarea
            className="pim-modal-text width100 minheight150 pl1 pt1"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </div>
        <div className="table-modal-border"></div>
        <div className="table-modal-form-button-box width100">
          <button
            className="table-modal-form-button-cancel"
            onClick={() => setModal(false)}
          >
            Cancel
          </button>
          <button className="table-modal-form-button" onClick={handleSubmit}>
            SAVE
          </button>
        </div>
      </form>
    </div>
  );
};

interface ICreateReviewProps {
  rqKeyReviews: string;
}

const CreateReview: React.FC<ICreateReviewProps> = ({ rqKeyReviews }) => {
  const [modal, setModal] = useState(false);

  return (
    <div>
      <button onClick={() => setModal(true)} className="create-btn-lbox">
        + ADD review
      </button>
      {modal && (
        <Modal onModalClose={() => setModal(false)} isOpened={modal}>
          <CreateOrEditReviewForm
            setModal={setModal}
            rqKeyReviews={rqKeyReviews}
          />
        </Modal>
      )}
    </div>
  );
};

export default CreateReview;
