import axios from "axios";
import { IPaginatedRequest } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";

export async function getPaginatedBatchList(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/batch-items?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}

export async function getSingleBatch(id: string) {
  const res = await axios.get(`/batch-item/${id}`, getHeaders());
  return res.data.singleBatch;
}

export async function createBatch(body: any) {
  const res = await axios.post("/batch-item", body, getHeaders());
  return res.data as string;
}

export async function executeBatch(batchId: string) {
  const res = await axios.post(`/execute/batch-list/${batchId}`, {}, getHeaders());
  return res.data as string;
}

export async function downloadBatchFile(
  batchId: string,
  marketplaceId: string
) {
  const res = await axios.get(
    `/batch-item/${batchId}/download/${marketplaceId}`,
    getHeaders()
  );
}
