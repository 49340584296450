import React, { useCallback, useMemo, useState } from "react";
import IntelligenceNav from "../../../ui/int-nav/IntelligenceNav";
import IntelligenceFilter from "./IntelligenceFilter";
import { Modal } from "../../../ui/modal/Modal";
import { useQuery } from "react-query";
import {
	getMarketplaces,
	getSellers,
	getPaginatedProducts,
} from "../../../../actions/intelligence";
import { getImageURL } from "../../../../actions/pim";
import Preloader from "../../../ui/preloader/Preloader";
import { IPaginatedRequest } from "../../../../interfaces";
import { useDebouncedValue } from "@mantine/hooks";
import queryString from "query-string";
import Paginator from "../../../ui/pagination";

export default function IntelligenceProduct() {
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		seller: "",
		perPage: 50,
		page: 1,
		search: "",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
	const [modal, setModal] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<any>({});

	const rqKeyProducts = useMemo(() => {
		return `/intelligence/overview?${queryString.stringify(debouncedConfig)}`;
	}, [debouncedConfig]);

	const { isLoading, data: products } = useQuery(rqKeyProducts, () =>
		getPaginatedProducts(debouncedConfig)
	);
	const marketplaces = useQuery(`/intelligence/marketplaces`, () =>
		getMarketplaces()
	);
	const sellers = useQuery(`/intelligence/sellers`, () => getSellers());

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	if (isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont p0">
					<IntelligenceNav />
				</div>
				<div className="table-main-cont">
					<div
						style={{ marginBottom: "20px", padding: "0 20px" }}
						className="table-main-title-cont"
					>
						<div className="in-row align-center justify-start">
							<span
								style={{ marginRight: "10px" }}
								className="int-prod-grey-text"
							>
								Seller:
							</span>
							<select
								id="compa-channels"
								name="compa-channels"
								className="compa-select"
								onChange={(e) =>
									setCurrentConfig((conf: IPaginatedRequest) => ({
										...conf,
										seller:
											e.target.value === "All sellers" ? "" : e.target.value,
									}))
								}
								value={currentConfig.seller}
							>
								<option className="compa-option" value="All sellers">
									All sellers
								</option>

								{sellers.data?.map((seller, index) => (
									<option className="compa-option" value={seller} key={index}>
										{seller}
									</option>
								))}
							</select>
						</div>
						<IntelligenceFilter />
					</div>
					<div className="table-main-title-cont">
						<div className="table-main-title-search-value-cont">
							<div className="search-box">
								<div className="search_form">
									<div className="table-search-box">
										<div className="table-search-form">
											<input
												className="table-search-input"
												placeholder="Search product or sku"
												onChange={(e) =>
													setCurrentConfig((conf: IPaginatedRequest) => ({
														...conf,
														search: e.target.value,
													}))
												}
												value={currentConfig.search}
											/>
											<img
												className="table-search-image"
												src="/icons/search.svg"
												width="25px;"
												alt=""
											/>
										</div>
									</div>
								</div>
								<div className="table-value">
									<span className="table-main-title-text">
										{products!.total}{" "}
										{products!.total > 1 ? "products" : "product"}
									</span>
								</div>
							</div>
						</div>
						<div className="marketplace-pagination">
							<Paginator data={products} refetch={onChangePage as any} />
						</div>
					</div>
					<div style={{ height: "max-content" }} className="table-overflow">
						<table
							style={{
								position: "sticky",
								zIndex: 101,
								top: "2px",
								width: "100%",
							}}
						>
							<thead>
								<tr
									style={{ marginTop: "-5px", width: "100%" }}
									className="table-results-title"
								>
									<th className="ml3 mr3">
										<img
											className="pointer"
											src="/icons/check-box.svg"
											alt=""
										/>
									</th>
									<th className="int-prod-sku-ean">
										SKU <br /> EAN
									</th>
									<th className="int-prod-title">Title</th>
									<th className="table-border-right" />

									{marketplaces.data?.map((zeoosName) => (
										<React.Fragment key={zeoosName}>
											<th className="int-prod-item">
												<div className="align-center height100">
													{zeoosName}
												</div>
												<div className="in-row width100 height100 gestao-sort">
													<div className="int-stock justify-center">Stock</div>
													<div className="int-stock justify-center">Price</div>
													<div className="int-stock justify-center">Buybox</div>
													<div className="int-stock justify-center">Rank</div>
												</div>
											</th>
											<th className="table-border-right" />
										</React.Fragment>
									))}
								</tr>
							</thead>
						</table>
						<table className="table-results">
							<tbody>
								{products!.data?.map((item) => (
									<tr key={item.id} className="table-result-box">
										<th className="ml3 mr3">
											<img
												className="pointer"
												src="/icons/check-box.svg"
												alt=""
											/>
										</th>

										<td className="int-prod-sku-ean">
											<img
												width="60px"
												src={getImageURL(item.sku)}
												alt={item.sku}
											/>
											<span
												style={{ textAlign: "left", marginLeft: "10px" }}
												className="int-text"
											>
												{item.sku} <br /> {item.ean}
											</span>
										</td>
										<td className="int-prod-title">
											<span style={{ textAlign: "left" }} className="int-text">
												{item.title}
											</span>
										</td>
										<td className="table-border-right" />

										{marketplaces.data?.map((zeoosName) => {
											const data = item.marketplaces[zeoosName];

											return (
												<React.Fragment key={`${item.id}-${zeoosName}`}>
													<td
														onClick={() => {
															setSelectedProduct({ ...item, zeoosName });
															setModal(true);
														}}
														className="int-prod-item"
													>
														<div className="in-row width100 height100 gestao-sort">
															<div className="int-stock justify-center">
																{data?.stock || "NA"}
															</div>
															<div className="int-stock justify-center">
																{data?.price || "NA"}
															</div>
															<div className="int-stock int-prod-green justify-center">
																{data?.buybox ? `${data.buybox}º` : "NA"}
															</div>
															<div className="int-stock int-prod-grey justify-center">
																{data?.rank ? `${data.rank}º` : "NA"}
															</div>
														</div>
													</td>
													<td className="table-border-right" />
												</React.Fragment>
											);
										})}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>

				{modal && (
					<Modal onModalClose={onModalClose} isOpened={modal}>
						<>
							<div
								style={{ maxWidth: "740px", textAlign: "left" }}
								className="table-modal-form-cont p4"
							>
								<div className="table-modal-title-box m0">
									<img
										className="int-modal-image"
										src={getImageURL(selectedProduct.sku)}
										alt=""
									/>
									<div className="int-prod-modal-title-cont">
										<div className="int-prod-modal-title-box">
											SKU: &nbsp;&nbsp;<b>{selectedProduct.sku}</b>
											&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; EAN: &nbsp;&nbsp;
											<b>{selectedProduct.ean}</b>
										</div>
										<span className="int-modal-title-text">
											{selectedProduct.title}
										</span>
									</div>
								</div>
								<div className="table-modal-border"></div>
								<div className="in-row pb1 width100 align-center justify-between">
									<div className="int-text in-row align-center justify-start">
										<span className="mr2 bold">Buybox</span>
										<span style={{ color: "#006DD2" }} className="mr2 bold">
											Category Ranking
										</span>
										<span style={{ color: "#006DD2" }} className="mr2 bold">
											Product Details
										</span>
									</div>
									<div
										style={{ textTransform: "uppercase" }}
										className="int-text"
									>
										{selectedProduct.zeoosName}
									</div>
								</div>
								<div className="table-modal-border"></div>
								{/* <div className="in-row align-center pt2 pb2 justify-center width100">
									<span className="int-modal-buybox-text">2º</span>
									<span className="int-modal-price-text">98,95€</span>
								</div> */}
								{/* <table className="mapping-modal-table">
									<thead>
										<tr className="mapping-modal-results-title">
											<th className="int-modal-table-position">Position</th>
											<th className="int-modal-table-price">Price</th>
											<th className="int-modal-table-seller">Seller</th>
											<th className="int-modal-table-page">Product Page</th>
										</tr>
									</thead>
									<tbody>
										<tr className="mapping-modal-result-box pt1 pb1 pointer int-modal-hover int-text">
											<td className="int-modal-table-position">1.</td>
											<td className="int-modal-table-price">98,90€</td>
											<td className="int-modal-table-seller">Seller 1</td>
											<td className="int-modal-table-page">
												<span
													style={{ textAlign: "left" }}
													className="blue-text mwidth120"
												>
													See page
												</span>{" "}
												Product_Page_URL
											</td>
										</tr>
										<tr className="mapping-modal-result-box pt1 pb1 pointer int-modal-hover int-text">
											<td className="int-modal-table-position">1.</td>
											<td className="int-modal-table-price">98,90€</td>
											<td className="int-modal-table-seller">Seller 1</td>
											<td className="int-modal-table-page">
												<span
													style={{ textAlign: "left" }}
													className="blue-text mwidth120"
												>
													See page
												</span>{" "}
												Product_Page_URL
											</td>
										</tr>
									</tbody>
								</table> */}
							</div>
						</>
					</Modal>
				)}
			</div>
		</div>
	);
}
