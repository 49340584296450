import React, { useCallback, useState } from "react";
import { deleteReview, IReview } from "../../../../actions/reviews";
import { Modal } from "../../../ui/modal/Modal";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";

interface Props {
	review: IReview;
	rqKeyReviews: string;
}

export const ReviewsDelete: React.FC<Props> = ({ review, rqKeyReviews }) => {
	const [modal, setModal] = useState<boolean>(false);

	const deleteMutation = useMutation(
		() => deleteReview(review?._id as string),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(rqKeyReviews);
				toast.success("Review successfully deleted!");
			},
			onError: () => {
				toast.error("Something went wrong!");
			},
		}
	);

	const onDelete = useCallback(
		async (e: React.FormEvent | React.MouseEvent) => {
			e.preventDefault();

			await deleteMutation.mutateAsync();

			setModal(false);
		},
		[deleteMutation]
	);

	return (
		<>
			<img
				onClick={() => {
					setModal(true);
				}}
				className="cm-delete-button"
				src="/icons/grey-trash-can.svg"
				alt=""
				width="30px;"
			/>
			{modal && (
				<Modal onModalClose={() => setModal(false)} isOpened={modal}>
					<>
						<div className="table-modal-form-cont p4">
							<div className="updateForm__container">
								<div className="vendor-mapping-modal-text">
									Are you sure you want to delete? <br />{" "}
									<strong>{review.sku}</strong>?
								</div>
								<div className="table-modal-border"></div>
								<div className="table-modal-form-button-box width100 m0">
									<button
										className="table-modal-form-button-cancel"
										onClick={() => setModal(false)}
									>
										Cancel
									</button>
									<button
										className="table-modal-form-button"
										onClick={onDelete}
									>
										DELETE
									</button>
								</div>
							</div>
						</div>
					</>
				</Modal>
			)}
		</>
	);
};
