import React, { useState } from "react";
import { Modal } from "../../ui/modal/Modal";
import { toast } from "react-toastify";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { ComponentsVariant, ComponentsMix } from "./CreateProductModal";

export interface IEditMetaInfoModal {
  modal: boolean;
  setModal: (modal: boolean) => void;
  schemes: any;
  productInfo?: any;
  sendRequest: (data: any) => Promise<void>;
}

export const EditMetaInfoModal: React.FC<IEditMetaInfoModal> = ({
  modal,
  setModal,
  schemes,
  productInfo,
  sendRequest,
}) => {
  const [productType, setProductType] = useState<any>(
    productInfo?.productType || schemes[0]?._id
  );
  const [isMix, setIsMix] = useState<boolean>(productInfo?.isMix || false);
  const [isVariant, setIsVariant] = useState<boolean>(
    productInfo?.variantOfSku ? true : false
  );
  const [variantOfSku, setVariantOfSku] = useState<any>(
    productInfo?.variantOfSku ? { sku: productInfo.variantOfSku } : {}
  );
  const [components, setComponents] = useState<any>(
    productInfo?.components?.map((x: any) => ({
      sku: x,
    })) || []
  );
  const [quantities, setQuantities] = useState<any>([]);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  const setVariant = (sku?: string) => {
    setComponents(sku ? [{ sku: sku }] : []);
    setVariantOfSku(sku ? { sku: sku } : {});
  };

  const setVariantQty = (qty: string) => {
    setQuantities(qty ? [qty] : []);
  };

  const addComponent = (sku: string, qty: string, name: string) => {
    if (sku && qty) {
      setComponents([...components, { sku, name }]);
      setQuantities([...quantities, qty]);
    }
  };

  const removeComponent = (index: number) => {
    let newComponents = components;
    let newQuantities = quantities;
    newComponents.splice(index, 1);
    newQuantities.splice(index, 1);
    setComponents([...newComponents]);
    setQuantities([...newQuantities]);
  };

  const handleSubmit = async (e: any) => {
    e.target.disabled = true;
    e.preventDefault();

    try {
      await sendRequest({
        productType,
        isVariant,
        variantOfSku: variantOfSku?.sku,
        isMix,
        components: components.map((x: any) => x.sku),
        sku: productInfo?.sku,
      });
    } catch (error) {
      console.error(error);
      e.target.disabled = false;
      return toast.error("Something went wrong");
    }

    toast.success("Success! Product meta updated");
    setModal(false);
  };

  return (
    <>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <div className="pim-modal-form-container edit-meta">
            <div className="pim-modal-form-content">
              <div className="pim-modal-form-sku">
                <div className="pim-modal-title-sku">Edit Product</div>
              </div>

              <div className="table-modal-border"></div>

              <div className="in-row align-center width100 mb2">
                <span className="mr2">Scheme:</span>
                <Select
                  className="scheme"
                  value={productType || ""}
                  onChange={(e) => setProductType(e.target.value)}
                >
                  {schemes.map((scheme: any, index: number) => (
                    <MenuItem value={scheme._id} key={index}>
                      {scheme.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="width100 in-row align-center">
                <div className="pim-modal-form-mixed">
                  <div className="pim-modal-variant-checkbox">
                    <label
                      className={`pim-modal-title-mixed ${
                        isVariant ? "disabled" : ""
                      }`}
                    >
                      <Checkbox
                        disabled={isVariant}
                        className="checkbox-input"
                        color="primary"
                        checked={isMix}
                        onChange={(e) => {
                          setIsMix(e.target.checked);
                          setVariantOfSku({});
                          setComponents([]);
                          setQuantities([]);
                        }}
                      />
                      Is mix?
                    </label>
                  </div>
                </div>
                <div className="pim-modal-form-variant">
                  <div className="pim-modal-variant-checkbox">
                    <label
                      className={`pim-modal-title-variant ${
                        isMix ? "disabled" : ""
                      }`}
                    >
                      <Checkbox
                        disabled={isMix}
                        color="primary"
                        className="checkbox-input"
                        checked={isVariant}
                        onChange={(e) => {
                          setIsVariant(e.target.checked);
                          setVariantOfSku({});
                          setComponents([]);
                          setQuantities([]);
                        }}
                      />
                      Is variant?
                    </label>
                  </div>
                </div>
              </div>

              {isVariant && (
                <ComponentsVariant
                  info={{ parent: variantOfSku }}
                  setVariant={setVariant}
                  setVariantQty={setVariantQty}
                />
              )}

              {isMix && (
                <ComponentsMix
                  components={components}
                  quantities={quantities}
                  addComponent={addComponent}
                  removeComponent={removeComponent}
                />
              )}

              <div className="table-modal-border"></div>

              <div className="table-modal-form-button-box width100 m0">
                <button
                  className="table-modal-form-button-cancel"
                  onClick={() => setModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="table-modal-form-button"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
