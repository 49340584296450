const ArrowIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.8002 12.0004L8.8002 8.00039L4.8002 4.00039L5.6002 2.40039L11.2002 8.00039L5.6002 13.6004L4.8002 12.0004Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowIcon;
