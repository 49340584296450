import React from "react";
import { DataGrid, GridValueGetterParams } from "@material-ui/data-grid";
import { Modal } from "../../ui/modal/Modal";
import { IHistory } from "../../../interfaces";

interface Props {
	userHistory: IHistory[] | [];
	setModal: (modal: boolean) => void;
	modal: boolean;
}

export const HistoryUser: React.FC<Props> = ({
	userHistory,
	setModal,
	modal,
}) => {
	const addUniqueId = (array: IHistory[]) => {
		array?.forEach((x: IHistory) => (x.id = x._id));
		return array;
	}

	return (
		<Modal
			onModalClose={(e: React.MouseEvent) => {
				e.stopPropagation();
				setModal(false);
			}}
			isOpened={modal}
		>
			{userHistory.length ? (
				<DataGrid
					className="bi__card_order_details"
					rows={addUniqueId(userHistory)}
					columns={columnsHistory}
					disableColumnMenu={true}
					showColumnRightBorder={true}
					hideFooter={false}
					pageSize={7}
				/>
			) : (
				<div className="bi__card_order_details">
					<div className="height100 dark-gray-color align-center justify-center">
						Selected user doesn't have the history
					</div>
				</div>
			)}
		</Modal>
	);
};

export const columnsHistory = [
	{
		field: "_id",
		headerName: "id",
		width: 100,
		valueGetter: (params: GridValueGetterParams) => `${params.row._id}`,
	},
	{
		field: "created",
		headerName: "Date",
		width: 180,
		valueGetter: (params: GridValueGetterParams) =>
			`${params.row.created.slice(0, 10)}`,
	},
	{
		field: "action",
		headerName: "Action",
		width: 720,
		valueGetter: (params: GridValueGetterParams) => `${params.row.action}`,
	},
];
