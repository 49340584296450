import React, { useState } from "react";
import "./jobs-panel.css";
import JobsPanelTable from "./JobsPanelTable";
import * as JobController from "../../../../actions/job";
import Preloader from "../../../ui/preloader/Preloader";
import { useQuery } from "react-query";
import CreateEditJob from "./CreateEditJob";
import SyncNav from "../../../ui/sync-nav/SyncNav";

export default function JobsPanel() {
  const [modal, setModal] = useState<boolean>(false);
  const { isLoading, data: jobs } = useQuery("jobs", () =>
    JobController.getJobs()
  );

  if (isLoading) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont p0">
          <SyncNav />
          <div className="mapping-title-border"></div>
          <div className="table-title-cont">
            <div className="mplc-filter-cont width100">
              <div className="mplc-filter">
                <div className="filter-text">Filter by:</div>
                <div className="">
                  <select className="main-select-bi ml1" value="">
                    <option className="acompan-option">All Statuses</option>
                  </select>
                </div>
              </div>
              <div
                onClick={() => {
                  setModal(true);
                }}
                className="tax-title-button pointer mr2"
              >
                <img src="/icons/pim-plus.svg" alt="" />
                <div className="pim-avaliacoes-title-button-text">
                  ADD new job
                </div>
              </div>
            </div>
          </div>
          <JobsPanelTable jobs={jobs as JobController.IJob[]} />
        </div>
      </div>
      {modal && <CreateEditJob open={modal} setOpen={setModal} />}
    </div>
  );
}
