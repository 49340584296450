import React, { useState, useContext, useRef } from "react";
import { Modal } from "../../../ui/modal/Modal";
import { toast } from "react-toastify";
import {
  createFreight,
  getTimes,
  getWeights,
  updateFreight,
} from "../../../../actions/freight";
import { useQuery } from "react-query";
import { CountriesContext } from "../../../context";
import { ICountryM } from "../../country-management/CountryManagement";

interface Props {
  onModalClose: any;
  isOpened: boolean;
  freight: any;
}

export const FreightModal: React.FC<Props> = ({
  onModalClose,
  isOpened,
  freight,
}) => {
  const title = freight ? "Editar Freight Mapping" : "Novo Freight Mapping";
  const countries = useContext(CountriesContext) as ICountryM[];

  const weights = useQuery("weights", () => getWeights());
  const times = useQuery("times", () => getTimes());
  const coluna1 = weights.data.slice(0, weights.data.length / 2);
  const coluna2 = weights.data.slice(weights.data.length / 2);
  const [objFreight, setObjFreight] = useState(freight || { mapping: [] });

  const weightPrefix = "weight-";
  const closeButton = useRef<HTMLButtonElement>(null);
  const update = freight ? true : false;

  const handleChange = (e: React.ChangeEvent<any>) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name.indexOf(weightPrefix) === 0) {
      const index = objFreight.mapping.findIndex(
        (map: any) => map.weight === Number(name.replace(weightPrefix, ""))
      );
      if (index >= 0) {
        objFreight.mapping[index].price = Number(value);
      } else {
        objFreight.mapping.push({
          price: Number(value),
          weight: Number(name.replace(weightPrefix, "")),
        });
      }
      setObjFreight({ ...objFreight });
    } else {
      setObjFreight({ ...objFreight, [name]: value });
    }
  };

  const handleSubmit = async (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    try {
      if (update) {
        await updateFreight(objFreight);
      } else {
        await createFreight(objFreight);
      }
    } catch (error) {
      console.error(error);
      return toast.error("Something went wrong");
    }
    if (closeButton.current !== null) {
      closeButton.current.click();
    }
  };

  const getPrice = (weight: Number) => {
    const price = objFreight.mapping.find((map: any) => map.weight === weight);
    return price ? price.price : "";
  };

  return (
    <Modal onModalClose={onModalClose} isOpened={isOpened}>
      <div className="mapping-modal-cont">
        <div className="table-modal-title-box m0">
          <div className="mapping-modal-title-text">{title}</div>
        </div>
        <form className="table-modal-form-cont">
          <div className="table-modal-border"></div>
          <div className="tax-modal-cat">
            <div className="mapping-modal-text">Nome do país:</div>
            <select
              id="compa-channels"
              name="country"
              required
              className="tax-modal-input"
              onChange={handleChange}
              value={objFreight.country}
            >
              <option value="">-</option>
              {countries.map((country, index) => (
                <option value={country.name} key={index}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="in-row width70 mt2 justify-between">
            <div className="in-column width100 align-start pr2">
              <div className="mapping-modal-text"></div>
              <div className="mapping-modal-text">Nome da região:</div>
              <input
                className="tax-modal-input"
                placeholder=""
                value={objFreight.region}
                name="region"
                onChange={handleChange}
              />
            </div>
            <div className="in-column width100 align-start">
              <div className="mapping-modal-text">Prazo:</div>
              <div className="in-row width100 align-center justify-between">
                <select
                  id="compa-channels"
                  name="timeMin"
                  required
                  className="freight-modal-input"
                  onChange={handleChange}
                  value={objFreight.timeMin}
                >
                  <option value="">-</option>
                  {times.data.map((prazo: any, index: any) => (
                    <option value={prazo} key={index}>
                      {prazo}h
                    </option>
                  ))}
                </select>
                <div className="mapping-modal-subtext">a</div>
                <select
                  id="compa-channels"
                  name="timeMax"
                  required
                  className="freight-modal-input"
                  onChange={handleChange}
                  value={objFreight.timeMax}
                >
                  <option value="">-</option>
                  {times.data.map((prazo: any, index: any) => (
                    <option value={prazo} key={index}>
                      {prazo}h
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="tax-modal-cat">
            <label htmlFor="freight-vat" className="mapping-modal-text">IVA% (VAT):</label>
            <input
              className="mapping-modal-table-input mapping-modal-widthx2"
              type="number"
              step=".1"
              id={'freight-vat'}
              value={objFreight.vat}
              onChange={e => setObjFreight((prev: any) => ({ ...prev, vat: parseFloat(e.target.value) }))}
            />
          </div>
          <div className="freight-modal-table-cont">
            <div className="freight-modal-table-box">
              <table className="mapping-modal-table">
                <thead>
                  <tr className="mapping-modal-results-title">
                    <th className="freight-modal-column">Peso:</th>
                    <th className="freight-modal-column">Valor:</th>
                  </tr>
                </thead>
                <tbody>
                  {coluna1.map((weight: any, index: any) => (
                    <React.Fragment key={index}>
                      <tr className="mapping-modal-result-box">
                        <td className="freight-modal-column">
                          <div className="mapping-modal-subtext">{weight}</div>
                          <div className="mapping-modal-subtext">kg</div>
                        </td>
                        <td className="freight-modal-column">
                          <input
                            className="mapping-modal-table-input mapping-modal-widthx2"
                            type="number"
                            step=".01"
                            name={`${weightPrefix}${weight}`}
                            onChange={handleChange}
                            value={getPrice(weight)}
                          />
                          <div className="mapping-modal-subtext">€</div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="freight-modal-table-box">
              <table className="mapping-modal-table">
                <thead>
                  <tr className="mapping-modal-results-title hide7">
                    <th className="freight-modal-column">Peso:</th>
                    <th className="freight-modal-column">Valor:</th>
                  </tr>
                </thead>
                <tbody>
                  {coluna2.map((weight: any, index: any) => (
                    <React.Fragment key={index}>
                      <tr className="mapping-modal-result-box">
                        <td className="freight-modal-column">
                          <div className="mapping-modal-subtext">{weight}</div>
                          <div className="mapping-modal-subtext">kg</div>
                        </td>
                        <td className="freight-modal-column">
                          <input
                            className="mapping-modal-table-input mapping-modal-widthx2"
                            type="number"
                            step=".01"
                            name={`${weightPrefix}${weight}`}
                            onChange={handleChange}
                            value={getPrice(weight)}
                          />
                          <div className="mapping-modal-subtext">€</div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="table-modal-border"></div>
          <div className="table-modal-form-button-box width100">
            <button
              className="table-modal-form-button-cancel"
              ref={closeButton}
              onClick={onModalClose}
            >
              Cancelar
            </button>
            <button className="table-modal-form-button" onClick={handleSubmit}>
              salvar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
