import axios from "axios";
import { IPaginatedRequest, IPaginatedResponse } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";
import * as T from "types";

export async function getTickets(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/support/tickets?${queryString.stringify(pagination)}`,
    getHeaders()
  );

  return res.data as IPaginatedResponse<T.Ticket[]>;
}

export async function createTicket(data: Partial<T.Ticket & T.TicketMessage>) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    // @ts-ignore
    formData.append(key, data[key]);
  });

  await axios.post(`/support/ticket`, formData, getHeaders());
}

export async function createTicketMessage(
  ticketId: string,
  data: Partial<T.TicketMessage>
) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    // @ts-ignore
    formData.append(key, data[key]);
  });

  await axios.post(
    `/support/ticket/${ticketId}/message`,
    formData,
    getHeaders()
  );
}

export async function updateTicket(id: string, data: Partial<T.Ticket>) {
  await axios.put(`/support/ticket/${id}`, data, getHeaders());
}

export async function getTicketMessages(ticketId: string) {
  const res = await axios.get(
    `/support/ticket/${ticketId}/messages`,
    getHeaders()
  );

  return res.data as IPaginatedResponse<T.TicketMessage[]>;
}

export async function getTicket(id: string) {
  const res = await axios.get(`/support/ticket/${id}`, getHeaders());

  return res.data as T.Ticket;
}
