import React, { useState } from "react";
import "./change.css";
import Preloader from "../../ui/preloader/Preloader";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../../../interfaces";
import useUser from "../../../hooks/useUser";
import { EditCategoryRate } from "./EditCategoryRate";
import { useDebouncedValue } from "@mantine/hooks";
import { usePlatformRatesByMarketplaceIntegration } from "../../../actions/v2";
import { useParamsState } from "hooks";
import Pagination from "../../ui/pagination/Pagination";
import { round } from "lodash";

interface Props {
  marketplace: any;
}

export const CategoryRateTable: React.FC<Props> = ({
  marketplace,
}) => {
  const [modal, setModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const { user } = useUser() as any;

  const [currentConfig, setCurrentConfig] = useParamsState({
    perPage: 50,
    page: 1,
    search: "",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

  const { isLoading, data: platformCategories, refetch } = usePlatformRatesByMarketplaceIntegration(
    { marketplaceIntegration: marketplace.data.marketplaceIntegration, ...debouncedConfig }
  );

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="table-search-box ml2">
            <form
              className="table-search-form"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className="table-search-input"
                placeholder="Search category"
                onChange={(e) =>
                  setCurrentConfig({
                    search: e.target.value
                  })
                }
                value={currentConfig.search}
              />
              <img
                className="table-search-image"
                src="/icons/search.svg"
                width="25px;"
                alt=""
              />
            </form>
          </div>
          <div className="table-value">
            {["gv@vinuus.com", "pj@vinuus.com"].includes(user.email) && (
              <RoleRequired role={Role.admin}>
                <img
                  className="refresh-image"
                  src="/icons/refresh.svg"
                  width="20px;"
                  alt=""
                />
              </RoleRequired>
            )}
          </div>
        </div>
        <div className="marketplace-pagination">
          <Pagination
            lastPage={platformCategories?.lastPage || 0}
            setConfig={setCurrentConfig}
            config={currentConfig}
          />
        </div>
      </div>
      <div style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
        >
          <tr
            style={{
              marginTop: "-5px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 60px 0 26px",
            }}
            className="table-results-title"
          >
            <th
              style={{
                maxWidth: "500px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
              >
                <p>Category</p>
                {/* <img src="/icons/filterArrows.svg" alt="filter arrows" /> */}
              </div>
              {/* <p>Platform category name</p> */}
            </th>
            <th
              style={{
                maxWidth: "200px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>Rate</p>
              <p>Edit</p>
            </th>
          </tr>
        </table>
        <div>
          <table className="table-results p0">
            <tbody>
              {platformCategories?.data?.map((category: any) => (
                <tr
                  key={category._id}
                  className="table-result-box"
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    padding: "0 44px 0 26px",
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  <th
                    style={{
                      maxWidth: "500px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      fontWeight: 400,
                    }}
                  >
                    <p>{category.label}</p>
                    {/* <p>Platform category name</p> */}
                  </th>
                  <th
                    style={{
                      maxWidth: "215px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      fontWeight: 400,
                    }}
                  >
                    <p>{round(category.rate[category.platform] || 0, 2)}%</p>
                    <div
                      style={{
                        display: "flex",
                        gap: "11px",
                        alignItems: "center",
                        color: "#ACACAC",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setModal(true);
                        setSelectedCategory(category);
                      }}
                    >
                      <img src="/icons/edit.svg" alt="edit icon" />
                      <p>Edit</p>
                    </div>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {modal && (
        <EditCategoryRate
          modal={modal}
          onModalClose={onModalClose}
          setModal={setModal}
          selectedCategory={selectedCategory}
          updateTable={refetch}
        />
      )}
    </>
  );
};

const getPriceOrDefault = (marketplaceData: any, defaultPrice = 0) =>
  String(marketplaceData?.price || defaultPrice).replace(".", ",");
