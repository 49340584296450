import React, { useEffect, useState } from "react";
import "./pim-management.css";
import { Modal } from "../../../ui/modal/Modal";
import { toast } from "react-toastify";
import Autocomplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  SchemeField,
  SchemeItem,
  SchemeTab,
  SchemeTag,
  TranslationValue,
} from "../../../../interfaces";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";

export const itemTypes = ["input", "textArea", "array", "checkbox", "assets"];

export const ItemBlock: React.FC<any> = ({
  selectedNode,
  setSelectedNode,
  data,
  setUpdatedData,
}) => {
  const [label, setLabel] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [inputLength, setInputLength] = useState<string>("");
  const [textAreaLength, setTextAreaLength] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [itemValue, setItemValue] = useState<string[] | []>([]);
  const [localized, setLocalized] = useState<boolean>(false);
  const [requiredField, setRequiredField] = useState<boolean>(false);
  const [fieldLength, setFieldLength] = useState<string>("");
  const [tooltipLabel, setTooltipLabel] = useState<string>("");
  const [readonlyField, setReadonlyField] = useState<boolean>(false);
  const [autoValues, setAutoValues] = useState<boolean>(false);
  const [tags, setTags] = useState<SchemeTag[]>([]);
  const [defaultTags, setDefaultTags] = useState<SchemeTag[]>([]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoValues(event.target.checked);
  };
  const [translationValue, setTranslationValue] = useState<TranslationValue[]>(
    []
  );

  useEffect(() => {
    setLabel(selectedNode.label);
    setType(selectedNode.type);
    setInputLength(selectedNode.inputLength);
    setTextAreaLength(selectedNode.textAreaLength);
    setName(selectedNode.name);
    setItemValue(
      selectedNode.value === undefined || selectedNode.value === null
        ? []
        : selectedNode.value
    );
    setLocalized(selectedNode.localized);
    setRequiredField(selectedNode.requiredField);
    setFieldLength(selectedNode.fieldLength);
    setTooltipLabel(selectedNode.tooltipLabel);
    setReadonlyField(selectedNode.readonlyField);
    setAutoValues(selectedNode.autoValues || false);
    setTags(selectedNode.tags || []);
    setDefaultTags(
      data.details.tabs
        .flatMap((tab: SchemeTab) => {
          return tab.fields.flatMap((field: SchemeField) => {
            return field.fields.map((item: SchemeItem) =>
              item.label !== selectedNode.label
                ? {
                    label: item.label,
                    name: item.name,
                    disabled: false,
                    createdByScheme: true,
                  }
                : ""
            );
          });
        })
        .filter((item: SchemeTag | string) => item !== "")
    );
    setTranslationValue(selectedNode.translationValue || []);
  }, [selectedNode, data.details.tabs]);

  const setFields = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const isSame = data.details.tabs.some((tab: SchemeTab) => {
      return tab.fields.some((field: SchemeField) => {
        return field.fields.some(
          (item: SchemeItem) =>
            item.label === label && selectedNode.label !== label
        );
      });
    });

    if (isSame) {
      toast.error("This name already exists");
    } else {
      const updatedTabs = data.details.tabs.map((tab: SchemeTab) => {
        tab.fields.map((field: SchemeField) => {
          field.fields.map((item: SchemeItem) => {
            if (item.label === selectedNode.label) {
              item.label = label;
              item.type = type;
              item.inputLength = inputLength;
              item.textAreaLength = textAreaLength;
              item.name = name;
              item.value = [...itemValue];
              item.localized = localized;
              item.requiredField = requiredField;
              item.fieldLength = fieldLength;
              item.tooltipLabel = tooltipLabel;
              item.autoValues = autoValues;
              item.tags = tags;
              item.readonlyField = readonlyField;
              item.translationValue = translationValue;
              setSelectedNode(item);
            }

            return item;
          });
          return field;
        });
        return tab;
      });

      setUpdatedData({
        ...data,
        details: {
          ...data.details,
          tabs: updatedTabs,
        },
      });
    }
  };

  const trnlValue = (values: string[]) => {
    let newArr = [] as TranslationValue[];

    if (values.length > translationValue.length) {
      newArr = [...translationValue, { pt: values[values.length - 1] }];
    }

    if (values.length < translationValue.length) {
      newArr = values.flatMap((val: string) => {
        return translationValue.filter(
          (x: TranslationValue) => x["pt"] === val
        );
      });
    }

    if (!translationValue.length) {
      newArr = values.map((val: string) => {
        return { pt: val };
      });
    }

    return newArr;
  };

  return (
    <>
      <form onSubmit={setFields}>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Title (label):</div>
          <div>
            <input
              type="text"
              className="item-blocks-input"
              required
              value={label}
              onChange={(e) => {
                e.preventDefault();
                setLabel(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Type:</div>
          <div className="width100">
            <select
              className="item-blocks-input m0"
              value={type}
              onChange={(e) => {
                e.preventDefault();
                setType(e.target.value);
              }}
            >
              {itemTypes.map((item: string, index: number) => (
                <option key={index}>{item}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Input length:</div>
          <div>
            <input
              type="text"
              className="item-blocks-input"
              value={inputLength}
              onChange={(e) => setInputLength(e.target.value)}
            />
          </div>
        </div>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Textarea length:</div>
          <div>
            <input
              type="text"
              className="item-blocks-input"
              value={textAreaLength}
              onChange={(e) => setTextAreaLength(e.target.value)}
            />
          </div>
        </div>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Name:</div>
          <div>
            <input
              type="text"
              className="item-blocks-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        {(type === "array" || type === "select" || type === "checkbox") && (
          <div className="pim-scheme-item-items">
            <div className="item-blocks-title">Value:</div>
            <div>
              <Autocomplete
                multiple
                freeSolo
                id="tags-outlined"
                options={itemValue}
                getOptionLabel={(option: any) =>
                  typeof option === "string" ? option : ""
                }
                defaultValue={[...itemValue] as any}
                value={[...itemValue] as any}
                onChange={(e: any, values: any) => {
                  e.preventDefault();
                  setItemValue(values);
                  setTranslationValue(trnlValue(values));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Option"
                    placeholder="Option"
                  />
                )}
              />
            </div>
          </div>
        )}
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Localized:</div>
          <div>
            <input
              type="checkbox"
              className="checkbox-input"
              checked={localized}
              onChange={(e) => setLocalized(!localized)}
            />
          </div>
        </div>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Required field:</div>
          <div>
            <input
              type="checkbox"
              className="checkbox-input"
              checked={requiredField}
              onChange={(e) => setRequiredField(!requiredField)}
            />
          </div>
        </div>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Field length:</div>
          <div>
            <input
              type="text"
              className="item-blocks-input"
              value={fieldLength}
              onChange={(e) => setFieldLength(e.target.value)}
            />
          </div>
        </div>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Tooltip:</div>
          <div>
            <input
              type="text"
              className="item-blocks-input"
              value={tooltipLabel}
              onChange={(e) => setTooltipLabel(e.target.value)}
            />
          </div>
        </div>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Auto values:</div>
          <div>
            <AntSwitch
              checked={autoValues}
              onChange={handleChange}
              name="checkedC"
            />
          </div>
          {autoValues ? (
            <div className="tags-block-status green"> Actived</div>
          ) : (
            <div className="tags-block-status grey">Desactived</div>
          )}
        </div>
        <div
          className={`pim-scheme-item-items ${
            !autoValues && "tags-block-disabled"
          }`}
        >
          <div className="item-blocks-title"></div>
          <Autocomplete
            className="tags-autocomplete-container"
            multiple
            id="tags"
            options={defaultTags}
            getOptionLabel={(option: SchemeTag) => option.label}
            defaultValue={tags}
            value={tags}
            onChange={(e: any, values: SchemeTag[]) => {
              e.preventDefault();
              setTags(values);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Option"
                placeholder="Option"
              />
            )}
          />
        </div>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Readonly:</div>
          <div>
            <input
              type="checkbox"
              className="checkbox-input"
              checked={readonlyField}
              onChange={(e) => setReadonlyField(!readonlyField)}
            />
          </div>
        </div>
        <div className="pim-scheme-set-button">
          <input type="submit" value={"Save"} className="formEdit__button" />
        </div>
      </form>
    </>
  );
};

export const CreateItemForm: React.FC<any> = ({
  onModalClose,
  modal,
  setModal,
  data,
  setUpdatedData,
  fieldsetLabel,
  setSelectedNode,
}) => {
  const [itemName, setItemName] = useState<string>("");
  const [itemType, setItemType] = useState<string>("input");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const itemBody = { label: itemName, type: itemType };
    const updatedSchemeTabs = data.details.tabs.map((tab: any) => {
      tab.fields.forEach((fieldset: any) => {
        if (fieldset?.label === fieldsetLabel) {
          fieldset.fields.push(itemBody);
        }
      });

      return tab;
    });

    data.details.tabs = updatedSchemeTabs;

    setSelectedNode(itemBody);
    setUpdatedData(data);

    setModal(false);
  };

  return (
    <Modal onModalClose={onModalClose} isOpened={modal}>
      <form className="pim-modal-form-container" onSubmit={handleSubmit}>
        <div className="pim-modal-form-content">
          <div className="pim-modal-form-section">
            <div>
              <div className="pim-modal-title-sku">New Tab</div>
              <div className="pim-modal-title-sku-sub">In Wine Scheme</div>
            </div>
          </div>
          <div className="table-modal-border"></div>
          <div className="pim-modal-form-section">
            <label className="pim-modal-form-title">Item name:</label>
            <input
              type="text"
              className="pim-modal-input width100 m0"
              required
              value={itemName}
              onChange={(e) => {
                e.preventDefault();
                setItemName(e.target.value);
              }}
            />
          </div>
          <div className="pim-modal-form-section">
            <label className="pim-modal-form-title">Item type:</label>
            <select
              className="pim-modal-input width100 m0"
              value={itemType}
              onChange={(e) => {
                e.preventDefault();
                setItemType(e.target.value);
              }}
            >
              {itemTypes.map((item: string, index: number) => (
                <option key={index}>{item}</option>
              ))}
            </select>
          </div>
          <div className="table-modal-border"></div>
          <div className="pim-modal-form-button-box width100">
            <button className="pim-modal-form-button-cancel">Cancel</button>
            <input
              type="submit"
              value={"Create"}
              className="pim-modal-form-button"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};
