import React from "react";
import * as _ from "lodash";
import { IPaginatedRequest, IRequest } from "../../../interfaces";

interface Props {
  requests: IRequest[];
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  currentConfig: IPaginatedRequest;
  vendors: any;
}

export const RNFilter: React.FC<Props> = ({
  requests,
  setCurrentConfig,
  currentConfig,
  vendors,
}) => {
  const statusesArray = ["All Statuses", "In Process", "Accepted", "Rejected"];
  const typesArray = ["All Types", "Pricing Proposed", "System message"];

  const vendorsArray = (array: any) => {
    return ["All Vendors", ..._.uniq(_.map(array, (item: any) => item?.name))];
  };

  const usersArray = (array: IRequest[]) => {
    return [
      "All Users",
      ..._.uniq(_.map(array, (item: IRequest) => item?.user?.username)),
    ];
  };

  return (
    <div className="mplc-filter">
      <div className="filter-text">Filter by:</div>
      <div>
        <select
          className="main-select-bi ml1"
          onChange={(e) =>
            setCurrentConfig((conf: IPaginatedRequest) => ({
              ...conf,
              status: e.target.value,
            }))
          }
          value={currentConfig.status}
        >
          {statusesArray.map((status: string, index: number) => (
            <option className="acompan-option" key={index}>
              {status}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          className="main-select-bi"
          onChange={(e) =>
            setCurrentConfig((conf: IPaginatedRequest) => ({
              ...conf,
              type: e.target.value,
            }))
          }
          value={currentConfig.type}
        >
          {typesArray.map((type: string, index: number) => (
            <option className="acompan-option" key={index}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          className="main-select-bi"
          onChange={(e) =>
            setCurrentConfig((conf: IPaginatedRequest) => ({
              ...conf,
              vendor: e.target.value,
            }))
          }
          value={currentConfig.vendor}
        >
          {vendorsArray(vendors).map((vendor: string, index: number) => (
            <option className="acompan-option" key={index}>
              {vendor}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          className="main-select-bi"
          onChange={(e) =>
            setCurrentConfig((conf: IPaginatedRequest) => ({
              ...conf,
              user: e.target.value,
            }))
          }
          value={currentConfig.user}
        >
          {usersArray(requests).map((user: string, index: number) => (
            <option className="acompan-option" key={index}>
              {user}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
