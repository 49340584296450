import React, { useCallback, useState } from "react";
import Preloader from "../../ui/preloader/Preloader";
import "./pim-translation.css";
import { useMutation, useQuery } from "react-query";
import { getSchemes } from "../../../actions/pim";
import { TranslationFilter } from "./TranslationFilter";
import { SchemeTabsTrnl } from "./SchemeTabsTrnl";
import { toast } from "react-toastify";
import { queryClient } from "../../App";
import { setScheme as updateScheme } from "../../../actions/scheme";
import SettingsNav from "../../ui/settings-nav/SettingsNav";

export default function PIMTranslation() {
  const [targetLanguage, setTargetLanguage] = useState("es");
  const [trlnScheme, setTrlnScheme] = useState<any>([]);
  const [search, setSearch] = useState("");

  const { isLoading, data: schemes } = useQuery("schemes", () => getSchemes(), {
    refetchOnWindowFocus: false,
  });

  const mutation = useMutation((data) => updateScheme(data), {
    onSuccess: () => {
      toast.success(`The scheme has been modified!`);
    },
    onError: () => {
      toast.error(`Something went wrong...`);
    },
  });

  const handleMutate = useCallback(() => {
    queryClient.setQueryData(targetLanguage, trlnScheme);
    mutation.mutate(trlnScheme);
    // eslint-disable-next-line
  }, [targetLanguage, mutation]);

  if (isLoading) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont p0">
          <SettingsNav />
          <div className="trnl-cont">
            <div className="trln-content-box">
              <TranslationFilter
                search={search}
                setSearch={setSearch}
                targetLanguage={targetLanguage}
                setTargetLanguage={setTargetLanguage}
                saveTranslation={handleMutate}
              />
              <div className="table-border-bottom"></div>
              <SchemeTabsTrnl
                search={search}
                schemes={schemes}
                setTrlnScheme={setTrlnScheme}
                lang={targetLanguage}
              />
              {/* <div className="table-border-bottom"></div> */}
              {/* <div className="trnl-submit-button-cont">
								<button className="trnl-filter-button" type="submit">
									salvar
								</button>
							</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
