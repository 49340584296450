import React, { useEffect, useState } from "react";
import "./pim-management.css";
import { Modal } from "../../../ui/modal/Modal";
import { CreateItemForm } from "./ItemBlock";
import { toast } from "react-toastify";

export const FieldsetBlock: React.FC<any> = ({
  selectedNode,
  data,
  setUpdatedData,
  setSelectedNode,
}) => {
  const [label, setLabel] = useState<string>("");
  const [elementsPosition, setElementsPosition] = useState<string>("");
  const [modalItems, setModalItems] = useState<boolean>(false);

  useEffect(() => {
    setLabel(selectedNode.label);
    setElementsPosition(selectedNode.elementsPosition);
  }, [selectedNode]);

  const options = ["", "row", "mixed"];

  const setFields = async (e: React.FormEvent) => {
    e.preventDefault();
    const isSame = data.details.tabs.some((tab: any) => {
      return tab.fields.some(
        (field: any) => field.label === label && selectedNode.label !== label
      );
    });

    if (isSame) {
      toast.error("This name already exists");
    } else {
      const updatedTabs = data.details.tabs.map((tab: any) => {
        tab.fields.map((field: any) => {
          if (field.label === selectedNode.label) {
            field.label = label;
            field.elementsPosition = elementsPosition;
            setSelectedNode(field);
          }
          return field;
        });
        return tab;
      });

      setUpdatedData({
        ...data,
        details: {
          ...data.details,
          tabs: updatedTabs,
        },
      });
    }
  };

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalItems(false);
  };

  return (
    <>
      <form onSubmit={setFields}>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Title (label):</div>
          <div>
            <input
              type="text"
              className="item-blocks-input"
              required
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </div>
        </div>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Position:</div>
          <div>
            <select
              className="item-blocks-input"
              value={elementsPosition}
              onChange={(e) => setElementsPosition(e.target.value)}
            >
              {options.map((option: string, index: number) => (
                <option key={index}>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="pim-scheme-set-button">
          <button
            className="pim-create-button"
            onClick={(e) => {
              e.preventDefault();
              setModalItems(true);
            }}
          >
            New item
          </button>
          <input type="submit" value={"Save"} className="formEdit__button" />
        </div>
      </form>
      {modalItems && (
        <CreateItemForm
          onModalClose={onModalClose}
          modal={modalItems}
          setModal={setModalItems}
          data={data}
          fieldsetLabel={selectedNode.label}
          setUpdatedData={setUpdatedData}
          setSelectedNode={setSelectedNode}
        />
      )}
    </>
  );
};

export const CreateFieldsetForm: React.FC<any> = ({
  onModalClose,
  modal,
  setModal,
  data,
  setUpdatedData,
  tabLabel,
  setSelectedNode,
}) => {
  const [fieldsetName, setFieldsetName] = useState<string>("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const fieldsetBody = { label: fieldsetName, type: "fieldset", fields: [] };
    const updatedSchemeTabs = data.details.tabs.map((tab: any) => {
      if (tab.label === tabLabel) {
        tab.fields.push(fieldsetBody);
      }

      return tab;
    });

    data.tabs = updatedSchemeTabs;
    setSelectedNode(fieldsetBody);
    setUpdatedData(data);

    setModal(false);
  };

  return (
    <Modal onModalClose={onModalClose} isOpened={modal}>
      <form className="pim-modal-form-container" onSubmit={handleSubmit}>
        <div className="pim-modal-form-content">
          <div className="pim-modal-form-section">
            <div>
              <div className="pim-modal-title-sku">New Tab</div>
              <div className="pim-modal-title-sku-sub">In Wine Scheme</div>
            </div>
          </div>
          <div className="table-modal-border"></div>
          <div className="pim-modal-form-section">
            <label className="pim-modal-form-title">Fieldset name:</label>
            <input
              type="text"
              className="pim-modal-input width100 m0"
              required
              value={fieldsetName}
              onChange={(e) => {
                e.preventDefault();
                setFieldsetName(e.target.value);
              }}
            />
          </div>
          <div className="table-modal-border"></div>
          <div className="pim-modal-form-button-box width100">
            <button className="pim-modal-form-button-cancel">Cancel</button>
            <input
              type="submit"
              value={"Create"}
              className="pim-modal-form-button"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};
