import React, { useEffect, useState } from "react";
import { getLogoSvg } from "../../marketplaces/MarketplacesList";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import { PlatformAvailability } from "./PlatformAvailability";
import { IMarketplace } from "../../../../interfaces";
import { Paginator } from "../../../ui/pagination/Paginator";

interface Props {
  vendor: any;
  marketplaces: IMarketplace[] | undefined;
}

export const SellerProductPlatforms: React.FC<Props> = ({
  vendor,
  marketplaces,
}) => {
  const [search, setSearch] = useState<any>("");
  const [paginatedData, setPaginatedData] = useState<any>(
    marketplaces?.slice(0, 50)
  );
  const [limit, setLimit] = useState<number>(50);
  const [results, setResults] = useState<any>(marketplaces);

  useEffect(() => {
    const results = marketplaces
      // eslint-disable-next-line
      ?.filter((item: any) => {
        if (search === "") {
          return item;
        } else if (
          Object.keys(item).filter((x: any) =>
            `${item[x]}`
              .toLowerCase()
              .includes(search.toLowerCase().replace(",", "."))
          )?.length > 0
        ) {
          return item;
        }
      });
    setResults(results);
    setPaginatedData(results?.slice(0, limit));
    // eslint-disable-next-line
  }, [search, marketplaces]);

  return (
    <>
      <div className="table-main-title-cont">
        <div className="mplc-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search marketplace"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-value">
              <span className="table-main-title-text">
                <span className="table-main-title-text">
                  {marketplaces?.length} marketplaces
                </span>
              </span>
            </div>
          </div>
          <div className="marketplace-pagination">
            <Paginator
              data={search !== "" ? results : marketplaces}
              rowsPerPage={limit}
              setPaginatedData={setPaginatedData}
              setLimit={setLimit}
              limit={limit}
            />
          </div>
        </div>
      </div>
      <div style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{
            position: "sticky",
            zIndex: 101,
            top: "2px",
            width: "100%",
          }}
        >
          <thead>
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="marketplace-actions">Platform</th>
              <th className="marketplace-actions">Platform account</th>
              <th className="marketplace-pais">Country</th>
              <th className="marketplace-sell">Platform Type</th>
              {/* <th className="marketplace-actions">Products</th> */}
              <th className="marketplace-sell align-center">
                Activated by seller
              </th>
              <th className="marketplace-actions">Availability</th>
            </tr>
          </thead>
        </table>
        <table className="table-results">
          <tbody>
            {paginatedData.map((item: IMarketplace, index: number) => (
              <tr
                className={`table-result-box minheight100 ${
                  !vendor.activeMarketplaces.includes(item.zeoosName) &&
                  "bg-noneactive"
                }`}
                key={index}
              >
                <td className="marketplace-actions">
                  <img
                    className="img-border p1"
                    src={
                      item.marketplaceImg ||
                      `https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${getLogoSvg(
                        item
                      )}.svg`
                    }
                    alt=""
                    width="90px"
                    height="90px"
                  />
                </td>
                <td className="marketplace-actions">
                  <h3 className="marketplace-text">{item.zeoosName}</h3>
                </td>
                <td className="marketplace-pais">
                  <div className="marketplace-text">
                    <CountryFlag
                      country={item.country}
                      className="table-search-image"
                      width="35px"
                    />
                  </div>
                </td>
                <td className="marketplace-sell">
                  <span className="marketplace-text">{item.type}</span>
                </td>
                {/* <td className="marketplace-actions">
									<span className="marketplace-text">8888</span>
								</td> */}
                <td className="marketplace-sell align-center">
                  <span className="marketplace-text">
                    {vendor.activeMarketplaces.includes(item.zeoosName)
                      ? "YES"
                      : "NO"}
                  </span>
                </td>
                <PlatformAvailability
                  vendorId={vendor.id}
                  isActive={vendor.activeMarketplaces.includes(item.zeoosName)}
                  label1={"Available"}
                  label2={"Unavailable"}
                  module={"sellers"}
                  platform={item.zeoosName}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
