import React, { useEffect, useState } from "react";
import "./pim-management.css";
import { Modal } from "../../../ui/modal/Modal";
import { CreateFieldsetForm } from "./FieldsetBlock";
import { toast } from "react-toastify";

export const TabBlock: React.FC<any> = ({
  selectedNode,
  data,
  setUpdatedData,
  setSelectedNode,
}) => {
  const [label, setLabel] = useState<string>("");
  const [modalFieldset, setModalFieldset] = useState<boolean>(false);

  useEffect(() => {
    setLabel(selectedNode.label);
  }, [selectedNode]);

  const setFields = async (e: React.FormEvent) => {
    e.preventDefault();
    const isSame = data.details.tabs.some(
      (tab: any) => tab.label === label && selectedNode.label !== label
    );

    if (isSame) {
      toast.error("This name already exists");
    } else {
      const updatedTabs = data.details.tabs.map((tab: any) => {
        if (tab.label === selectedNode.label) {
          tab.label = label;
          setSelectedNode(tab);
        }
        return tab;
      });
      data.details.tabs = updatedTabs;

      setUpdatedData({
        ...data,
        details: {
          ...data.details,
          tabs: updatedTabs,
        },
      });
    }
  };

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalFieldset(false);
  };

  return (
    <>
      <form onSubmit={setFields}>
        <div className="pim-scheme-item-items">
          <div className="item-blocks-title">Title (label):</div>
          <div>
            <input
              type="text"
              className="item-blocks-input"
              required
              value={label}
              onChange={(e) => {
                e.preventDefault();
                setLabel(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="pim-scheme-set-button">
          <button
            className="pim-new-button"
            onClick={(e) => {
              e.preventDefault();
              setModalFieldset(true);
            }}
          >
            <img src="/icons/pim-main-plus.svg" className="mr1" alt="" />
            New item
          </button>
          <input type="submit" value={"Save"} className="formEdit__button" />
        </div>
      </form>
      {modalFieldset && (
        <CreateFieldsetForm
          onModalClose={onModalClose}
          modal={modalFieldset}
          setModal={setModalFieldset}
          data={data}
          tabLabel={selectedNode.label}
          setUpdatedData={setUpdatedData}
          setSelectedNode={setSelectedNode}
        />
      )}
    </>
  );
};

export const CreateTabForm: React.FC<any> = ({
  onModalClose,
  modal,
  setModal,
  data,
  setUpdatedData,
  setSelectedNode,
}) => {
  const [tabName, setTabName] = useState<string>("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const tab = { label: tabName, type: "tab", fields: [] };
    data.details.tabs.push(tab);
    setUpdatedData(data);
    setSelectedNode(tab);

    setModal(false);
  };

  return (
    <Modal onModalClose={onModalClose} isOpened={modal}>
      <form className="pim-modal-form-container" onSubmit={handleSubmit}>
        <div className="pim-modal-form-content">
          <div className="pim-modal-form-section">
            <div>
              <div className="pim-modal-title-sku">New Tab</div>
              <div className="pim-modal-title-sku-sub">In Wine Scheme</div>
            </div>
          </div>
          <div className="table-modal-border"></div>
          <div className="pim-modal-form-section mb4">
            <label className="pim-modal-form-title">Name:</label>
            <input
              type="text"
              className="pim-modal-input width100 m0"
              required
              value={tabName}
              onChange={(e) => {
                e.preventDefault();
                setTabName(e.target.value);
              }}
            />
          </div>
          <div className="table-modal-border mt4"></div>
          <div className="pim-modal-form-button-box width100">
            <button className="pim-modal-form-button-cancel">Cancel</button>
            <input
              type="submit"
              value={"Create"}
              className="pim-modal-form-button"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};
