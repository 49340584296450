import { useMemo } from "react";
import { useQuery } from "react-query";
import { IPaginatedRequest } from "../interfaces";
import { queryClient } from "../components/App";
import { stringify } from "query-string";
import { getTicket, getTicketMessages, getTickets } from "../actions/ticket";

export function useTickets(config: IPaginatedRequest) {
  const key = useMemo(() => `tickets?${stringify(config)}`, [config]);
  const data = useQuery(key, async () => await getTickets(config));

  return useMemo(
    () => ({
      ...data,
      invalidate: () => queryClient.invalidateQueries(key),
      key,
    }),
    [data, key]
  );
}

export function useTicketMessages(ticketId: string) {
  const key = useMemo(
    () => `ticket-messages?${stringify({ ticketId })}`,
    [ticketId]
  );
  const data = useQuery(key, async () => await getTicketMessages(ticketId));

  return useMemo(
    () => ({
      ...data,
      invalidate: () => queryClient.invalidateQueries(key),
      key,
    }),
    [data, key]
  );
}

export function useTicket(ticketId: string) {
  const key = useMemo(() => `ticket?${stringify({ ticketId })}`, [ticketId]);
  const data = useQuery(key, async () => await getTicket(ticketId));

  return useMemo(
    () => ({
      ...data,
      invalidate: () => queryClient.invalidateQueries(key),
      key,
    }),
    [data, key]
  );
}

export default useTickets;
