import { useMemo } from "react";
import { useQuery } from "react-query";
import { ReactQueryConfig } from "../interfaces";
import { getVendorsActive } from "../actions/vendor";

function useActiveVendors(config?: ReactQueryConfig) {
  const key = useMemo(() => `activeVendors`, []);
  const data = useQuery(key, async () => await getVendorsActive(), {
    refetchOnWindowFocus: false,
    ...config,
  });

  return data;
}

export default useActiveVendors;
