import React, { useState, memo, useCallback } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { PricingEvent } from "types";
import { useQuery } from "react-query";
import { getPricingEventsMetrics } from "../../../actions/pricingEvent";
import Preloader from "../../ui/preloader/Preloader";
import { round } from "lodash";
import { formatValue } from "./Pricing";
import CountryFlag from "../../ui/country-flag/CountryFlag";

interface Props {
	events: PricingEvent[];
}

const GraphBoxes: React.FC<Props> = ({ events }) => {
	const [hidegr, setHidegr] = useState(false);
	const [value, setValue] = useState<string>("value");

	const metrics = useQuery("/pricing-events/metrics", getPricingEventsMetrics);

	if (metrics.isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<>
			<div
				style={{
					boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
					minHeight: hidegr ? "auto" : "330px",
				}}
				className="pricing-graph-box"
			>
				<div className="pricing-graph-hide">
					<span className="pricing-graph-hide-text">
						{hidegr ? "view" : "hide"}
					</span>
					<img
						onClick={() => setHidegr(!hidegr)}
						className="mr1 pointer"
						style={{
							width: "20px",
							display: hidegr ? "none" : "flex",
						}}
						src="/icons/pricing-graph-hide.svg"
						alt=""
					/>
					<img
						onClick={() => setHidegr(!hidegr)}
						className="mr1 pointer"
						style={{
							width: "20px",
							display: hidegr ? "flex" : "none",
						}}
						src="/icons/pricing-graph-view.svg"
						alt=""
					/>
				</div>
				<div
					style={{ paddingBottom: hidegr ? "0px" : "8px" }}
					className="billing-modal-text bold pb1"
				>
					Overall Result of Active Events
				</div>
				<div
					style={{ display: hidegr ? "none" : "flex" }}
					className="width100 align-center in-column justify-center"
				>
					<div className="pricing-text pb1">
						{metrics.data?.nbActive} active discount events
					</div>
					<div className="billing-quan-box">
						<div className="width100 justify-center">
							<div
								className="billing-circular"
								style={{ width: 200, height: 200 }}
							>
								<CircularProgressbar
									strokeWidth={15}
									styles={buildStyles({
										pathColor: "#F4733C",
										trailColor: "#e5e5e5",
										strokeLinecap: "round",
									})}
									value={
										(metrics.data?.sales?.overall?.sold! * 100) /
										metrics.data?.sales?.overall?.goal!
									}
								/>
								<div className="billing-circular-box">
									<div className="billing-large-text pricing-orange bold">
										{formatValue(metrics.data?.sales?.overall?.sold!)}
									</div>
									<div className="billing-modal-text grey2">
										{formatValue(metrics.data?.sales?.overall?.goal!)}
									</div>
								</div>
							</div>
						</div>
						<div className="billing-quan-cont">
							<div className="billing-perf-total-cont justify-start pb2">
								<div className="billing-ball pricing-orangebg mr4"></div>
								<div className="billing-text bold grey2 ml2">Sold Products</div>
							</div>
							<div className="billing-perf-total-cont justify-start">
								<div className="billing-ball semibluebg mr4"></div>
								<div className="billing-text bold grey2 ml2">
									Estimated Result
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
					minHeight: hidegr ? "auto" : "330px",
				}}
				className="pricing-graph-box"
			>
				<div className="pricing-graph-hide">
					<span className="pricing-graph-hide-text">
						{hidegr ? "view" : "hide"}
					</span>
					<img
						onClick={() => setHidegr(!hidegr)}
						className="mr1 pointer"
						style={{
							width: "20px",
							display: hidegr ? "none" : "flex",
						}}
						src="/icons/pricing-graph-hide.svg"
						alt=""
					/>
					<img
						onClick={() => setHidegr(!hidegr)}
						className="mr1 pointer"
						style={{
							width: "20px",
							display: hidegr ? "flex" : "none",
						}}
						src="/icons/pricing-graph-view.svg"
						alt=""
					/>
				</div>
				<div
					style={{ paddingBottom: hidegr ? "0px" : "8px" }}
					className="billing-modal-text bold pb1"
				>
					Sold Products by Platform Account
				</div>
				<div
					style={{ display: hidegr ? "none" : "flex" }}
					className="width100 align-center in-column justify-center"
				>
					<div className="pricing-text pb1">
						{metrics.data?.sales?.byPlatform.length} platform accounts with
						active discount events.
					</div>
					<div className="pricing-progress-cont pb1">
						<PlatformsCounter
							data={metrics?.data?.sales?.byPlatform || []}
							value={value}
						/>
						<div
							className="width100"
							style={{ borderTop: "1px solid #E1E1E1" }}
						/>
					</div>
					<div className="billing-perf-total-cont pt2">
						<RadioBtn
							setValue={setValue}
							flag={"value"}
							value={value}
							label={"Value in Sales"}
						/>
						<RadioBtn
							setValue={setValue}
							flag={"products"}
							value={value}
							label={"Sold Products"}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

interface RadioBtnProps {
	setValue: React.Dispatch<React.SetStateAction<string>>;
	flag: string;
	value: string;
	label: string;
}

const RadioBtn: React.FC<RadioBtnProps> = ({
	setValue,
	flag,
	value,
	label,
}) => {
	return (
		<div
			className="billing-perf-total-cont pointer"
			onClick={() => setValue(flag)}
		>
			<div
				style={{
					border: `2px solid ${value === flag ? "#f4733c" : "#acacac"}`,
					padding: "2px",
					borderRadius: "100%",
				}}
			>
				<div
					className={`billing-ball ${value === flag && "pricing-orangebg"}`}
				></div>
			</div>
			<div className="billing-text bold grey2 pl1">{label}</div>
		</div>
	);
};

interface IDataByPlatform {
	count?: number;
	platformId: string;
	total: number;
	zeoosName: string;
	country?: string;
}

interface PlatformsCounterProps {
	value: string;
	data: IDataByPlatform[] | [];
}

const PlatformsCounter: React.FC<PlatformsCounterProps> = ({ value, data }) => {
	const getPlatformWidth = useCallback(
		(total: number) => {
			const best = data?.[0];

			if (!best) {
				return 0;
			}

			return round((total / best?.total) * 100);
		},
		[data]
	);

	return (
		<>
			{data?.map((m: IDataByPlatform) => (
				<div
					style={{ borderTop: "1px solid #E1E1E1" }}
					className="billing-progress-box"
					key={m.platformId}
				>
					<div
						className="billing-width-progress billing-text bold align-center"
						style={{ width: 220 }}
					>
						<CountryFlag
							country={m.country}
							className="table-search-image"
							width="28px"
						/>
						<span className="pricing-text ml2">{m.zeoosName}</span>
					</div>

					<div className="width100 height24 in-row">
						<div
							className="pricing-line-active"
							style={{
								width: `${getPlatformWidth(
									value === "value" ? m.total : m.count || 0
								)}%`,
							}}
						/>

						<span className="pricing-line-text">
							{formatValue(value === "value" ? m.total : m.count || 0)}
							{value === "value" ? "€" : " qty"}
						</span>
					</div>
					{/* <PricingGraphTooltip
									text={
										<div className="pricingTooltopgraph">
											<div className="servicegraph">
												<img
													className="mr2"
													width="24px"
													src="/icons/portugal.svg"
													alt=""
												/>
												Amazon ES
											</div>
											<div className="textgraph">
												245 SKUs in 4 discount events.
											</div>
											<div className="textgraph">360 sold products</div>
											<div className="textgraph">
												<b className="pricing-orange">{m.total}€ sold</b>
											</div>
										</div>
									}
								/> */}
				</div>
			))}
		</>
	);
};

export default memo(GraphBoxes);
