import React from "react";
import { NavLink } from "react-router-dom";
import SingleNotificationTable from "./SingleNotificationTable";

export default function SingleNotification() {

    return (
        <>
            <div className="main-body">
				<div className="main-container">
                    <div className="table-main-cont">
                    <NavLink to="/notifications"
						className="single-billing-nav pointer"
					>
						<img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
						<div className="email-text underline pl2">Back to Notifications</div>
					</NavLink>
                    <div className="table-title-cont p0 mb4">
                        <div style={{padding: "10px"}} className="mplc-filter-cont width100">
                        <div style={{paddingLeft: "0"}} className="not-notifications">
                                <img width={60} src="/icons/not-zeoos.svg" alt="" />
                                <div className="not-table-cont bold">
                                    <b style={{fontSize: "18px"}}>7 offers reached Buybox on Amazon ES.</b>
                                    <span className="green">Verified on 8/12/2023 8:45 AM.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SingleNotificationTable />
                    </div>
                    </div>
                    </div>
                    
        </>
    );
}