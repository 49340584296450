import { useEffect, useState } from "react";
import "./filter.css";
import ArrowIcon from "./ArrowIcon";
import { ProductRequest } from "types/product";
import { useCategories } from "../../../actions/v2";
import { Category } from "types/category";

interface Props {
  isViewFilter: boolean;
  setIsViewFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentConfig: (config: Partial<ProductRequest>) => void;
  setFilterName: React.Dispatch<React.SetStateAction<string | undefined>>;
  onClearFilter: boolean;
}

const FilterCategory: React.FC<Props> = ({
  isViewFilter,
  setIsViewFilter,
  setCurrentConfig,
  setFilterName,
  onClearFilter,
}) => {
  const [category, setCategory] = useState<Category>();
  const [secondCategory, setSecondCategory] = useState<Category>();

  const firstCategories = useCategories({ level: 1 });
  const secondCategories = useCategories(
    { level: 2, code: category?.code },
    { enabled: !!category }
  );

  const thirdCategories = useCategories(
    { level: 3, code: secondCategory?.code },
    { enabled: !!secondCategory }
  );

  useEffect(() => {
    document.body.style.overflow = isViewFilter ? "hidden" : "auto";
  }, [isViewFilter]);

  const clearFilter = () => {
    setCategory(undefined);
    setSecondCategory(undefined);
    setCurrentConfig({ categoryCode: undefined });
    setIsViewFilter(false);
    setFilterName(undefined);
  };

  useEffect(() => {
    clearFilter();
  }, [onClearFilter]);

  return (
    <>
      <button
        onClick={() => setIsViewFilter(true)}
        className="filter-button"
        style={{ border: "1px solid #F1692E", padding: "4px 12px" }}
      >
        <img src="/icons/filter-category.svg" alt="filter icon" />
        <p>
          <span>Categories</span>
        </p>
      </button>

      <div
        onClick={() => {
          setIsViewFilter(false);
        }}
        style={{
          opacity: isViewFilter ? 1 : 0,
          pointerEvents: isViewFilter ? "auto" : "none",
        }}
        className="filter-modal-back"
      />
      <div
        style={{ left: isViewFilter ? 0 : "-320px", padding: 0 }}
        className="filter-modal"
      >
        <div
          style={{ position: "relative", overflowY: "scroll", height: "100vh" }}
          className="filter-modal-main"
        >
          <div
            style={{
              background: "#F1692E",
              padding: "20px 10px 20px 30px",
              fontWeight: 700,
              fontSize: "18px",
              color: "white",
              zIndex: "10002",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Products by category</p>
            <button
              onClick={() => clearFilter()}
              style={{ background: "transparent", boxShadow: "none" }}
            >
              Clear
            </button>
          </div>

          {isViewFilter && (
            <>
              {firstCategories.data?.map((item, i) => (
                <>
                  <button
                    onClick={() => {
                      setCategory(item);
                      setCurrentConfig({
                        categoryCode: item.code,
                        category: undefined,
                      });
                      setSecondCategory(undefined);
                      setFilterName(item.name);
                    }}
                    key={i}
                    style={{
                      boxShadow: "none",
                      padding: "17px 20px 17px 30px",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      textAlign: "left",
                      background:
                        category?.code === item.code ? "#F2F2F2" : "white",
                      width: "100%",
                      color:
                        category?.code === item.code ? "#F1692E" : "#5A5A5A",
                      transition: ".3s ease",
                    }}
                  >
                    {item.label}
                    <ArrowIcon
                      fill={
                        category?.code === item.code ? "#F1692E" : "#5A5A5A"
                      }
                    />
                  </button>

                  {category && !!secondCategories.data?.length && (
                    <div
                      className="filter-modal"
                      style={{
                        left: "320px",
                        padding: 0,
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          padding: "20px 30px",
                          fontWeight: 700,
                          fontSize: "18px",
                          color: "#5A5A5A",
                        }}
                      >
                        {category.label}
                      </div>
                      {secondCategories.data?.map((subItem, index) => (
                        <button
                          onClick={() => {
                            setSecondCategory(subItem);
                            setFilterName(subItem.name);
                            setCurrentConfig({
                              categoryCode: subItem.code,
                              category: undefined,
                            });
                          }}
                          key={index}
                          style={{
                            boxShadow: "none",
                            padding: "17px 20px 17px 30px",
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            alignItems: "center",
                            textAlign: "left",
                            background:
                              secondCategory?.code === subItem.code
                                ? "#F2F2F2"
                                : "white",
                            width: "100%",
                            color:
                              secondCategory?.code === subItem.code
                                ? "#F1692E"
                                : "#5A5A5A",
                            transition: ".3s ease",
                          }}
                        >
                          {subItem.label}
                          <ArrowIcon
                            fill={
                              secondCategory?.code === subItem.code
                                ? "#F1692E"
                                : "#5A5A5A"
                            }
                          />
                        </button>
                      ))}
                    </div>
                  )}
                  {secondCategory && !!thirdCategories.data?.length && (
                    <div
                      className="filter-modal"
                      style={{
                        left: "640px",
                        padding: 0,
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          padding: "20px 30px",
                          fontWeight: 700,
                          fontSize: "18px",
                          color: "#5A5A5A",
                        }}
                      >
                        {secondCategory.label}
                      </div>
                      {thirdCategories.data?.map((subItem, index) => (
                        <button
                          key={index}
                          style={{
                            boxShadow: "none",
                            padding: "17px 20px 17px 30px",
                            background: "transparent",
                            width: "100%",
                            textAlign: "left",
                            color: "#5A5A5A",
                            alignItems: "start",
                          }}
                          onClick={() => {
                            setCurrentConfig({
                              categoryCode: subItem.code,
                              category: undefined,
                            });
                            setFilterName(subItem.name);
                          }}
                        >
                          {subItem.label}
                        </button>
                      ))}
                    </div>
                  )}
                </>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FilterCategory;
