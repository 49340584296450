import { useMemo } from "react";
import { useQuery } from "react-query";
import { queryClient } from "../components/App";
import { findMarketplace } from "../actions/marketplace";

function useMarketplace(zeoosName: string, sellerType?:string) {
  const key = useMemo(
    () => `single-marketplace?zeoosName=${zeoosName}`,
    [zeoosName]
  );
  const data = useQuery(key, async () => await findMarketplace(zeoosName, sellerType));

  return useMemo(
    () => ({
      ...data,
      invalidate: () => queryClient.invalidateQueries(key),
    }),
    [data, key]
  );
}

export default useMarketplace;
