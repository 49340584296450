import React, { useEffect, useState } from 'react';
import "./translator.css";
import Preloader from '../../ui/preloader/Preloader';
import { translate, getOfferCode } from '../../../actions/translator';

type LanguageType = {
  language: string;
  langCode: string;
};

export const languages: Array<LanguageType> = [
  { language: "Bulgarian", langCode: "BG" },
  { language: "Czech", langCode: "CS" },
  { language: "Danish", langCode: "DA" },
  { language: "German", langCode: "DE" },
  { language: "Greek", langCode: "EL" },
  { language: "English (British)", langCode: "EN-GB" },
  { language: "English (American)", langCode: "EN-US" },
  { language: "Spanish", langCode: "ES" },
  { language: "Estonian", langCode: "ET" },
  { language: "Finnish", langCode: "FI" },
  { language: "French", langCode: "FR" },
  { language: "Hungarian", langCode: "HU" },
  { language: "Italian", langCode: "IT" },
  { language: "Japanese", langCode: "JA" },
  { language: "Lithuanian", langCode: "LT" },
  { language: "Latvian", langCode: "LV" },
  { language: "Dutch", langCode: "NL" },
  { language: "Polish", langCode: "PL" },
  { language: "Portuguese", langCode: "PT-PT" },
  { language: "Portuguese (Brazilian)", langCode: "PT-BR" },
  { language: "Romanian", langCode: "RO" },
  { language: "Russian", langCode: "RU" },
  { language: "Slovak", langCode: "SK" },
  { language: "Slovenian", langCode: "SL" },
  { language: "Swedish", langCode: "SV" },
  { language: "Chinese", langCode: "ZH" },
];

export default function Translator() {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState<string>("");
  const [result, setResult] = useState<string>("");
  const [language, setLanguage] = useState<string>("Spanish");

  const [code, setCode] = useState<any>(null);
  const [path, setPath] = useState<any>(null);

  const onClickHandler = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      text,
      langCode: languages.find((x: LanguageType) => x.language === language)!.langCode,
      sourceLang: language,
    }
    const response = await translate(data);
    setResult(response);
    setLoading(false);
  }

  useEffect(() => {
    setPath(null);
  }, []);

  const cdiscountGenerator = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    const toUpdate = {
      sku: "1",
      ean: "5600179589617",
      price: "10",
      stock: "42",
    };
    const res = await getOfferCode(toUpdate);
    setCode(res.data.res);
    setPath(res.data.path);
    setLoading(false);
  }

  const getS3Path = (path: string) => {
    return `https://vinuus-portal.s3.eu-west-3.amazonaws.com/${path}`;
  }

  return (
    <div className="main-body">
      <div className="main-container">
        {!loading ? (
          <form>
            <div className="main-area">
              <div className="section">
                <label className="section-label">Enter your text:</label>
                <input
                  className="input-area"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
              <div className="section">
                <label className="section-label">Translate into:</label>
                <select
                  className="formEdit__input"
                  required
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  {languages.map((obj: LanguageType, index: number) => (
                    <option key={index}>
                      {obj.language}
                    </option>
                  ))}
                </select>
              </div>
              <div className="section">
                <label className="section-label">Result:</label>
                <input
                  className="input-area"
                  value={result}
                  onChange={(e) => setResult(e.target.value)}
                />
              </div>
            </div>
            <div className="translate-button">
              <button onClick={onClickHandler} className="table-modal-form-button">Translate</button>
            </div>
            {code !== null ? (
              <>
                <input
                  className="input-area"
                  value={code}
                />
                <div className="translate-button">
                  <button className="table-modal-form-button">
                    <a href={getS3Path(path)}>Get zip</a>
                  </button>
                </div>
              </>
            ) : (
              <div className="translate-button">
                <button onClick={cdiscountGenerator} className="table-modal-form-button">Generate</button>
              </div>
            )}
          </form>
        ) : (
          <Preloader />
        )}
      </div>
    </div>
  );
}
