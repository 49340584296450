import axios from "axios";
import { getHeaders } from "./user";

export async function createTaxMapping(name: string, countries: any) {
	const res = await axios.post(
		"/taxMapping",
		{ name, countries },
		getHeaders()
	);
	return res.data.taxMapping;
}

export async function updateTaxMapping(
	id: string,
	name: string,
	countries: any
) {
	await axios.put(`/taxMapping/${id}`, { name, countries }, getHeaders());
}

export async function deleteTaxMapping(id: string) {
	await axios.delete(`/taxMapping/${id}`, getHeaders());
}

export async function getTaxMappings() {
	const res = await axios.get(`/taxMappings`, getHeaders());
	return res.data.taxMappings || [];
}

export async function getTaxMapping(id: string) {
	const res = await axios.get(`/taxMapping/${id}`, getHeaders());
	return res.data.taxMapping;
}

export async function pushShopifyTaxCollections() {
	await axios.post("/shopify/collections/tax", {}, getHeaders());
}
