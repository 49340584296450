import React from "react";
import { IZeoosOrder } from "../../../../../../interfaces";

interface Props {
	order: IZeoosOrder;
	shipping: string;
}

const formatDate = (
	date: Date,
	style?: "medium" | "full" | "long" | "short" | undefined
) => {
	if (!date) return "";
	return new Intl.DateTimeFormat("en", {
		dateStyle: "full",
		timeStyle: style,
		timeZone: "UTC",
	}).format(new Date(date));
};

const SingleOrderStepperInfo: React.FC<Props> = ({ order, shipping }) => {
	const shippingFiltered = order.shipping_list?.filter(
		(s: any) => s._id === shipping
	)[0];
	// if (['approved','purchase'].includes(shippingFiltered.timeline)) return <></>;

	return (
		<div className="single-order-cont single-order-details">
			<div className="so-adresse-cont">
				{["approved", "purchase"].includes(shippingFiltered.timeline) ? (
					<></>
				) : (
					<div className="width100">
						<div className="single-order-title-cont">
							<h2 className="single-order-title2">Details</h2>
						</div>
						<div className="order-border"></div>
						<div className="mt4 single-order-text">
							<div className="so-details-box">
								<b className="mr1">Carrier:</b> {shippingFiltered.agency || shippingFiltered.carrier}
							</div>
							<div className="so-details-box">
								<b className="mr1">Tracking Reference:</b> { shippingFiltered?.clientReference || shippingFiltered?.tracking}
                {shippingFiltered.tracking_link && shippingFiltered.tracking_link !== "false" &&
                  <a href={shippingFiltered.tracking_link} target="_blank" rel="noreferrer" style={{marginLeft:5}}>
                    <img className='' src='/icons/so-details-link.svg' title='Tracking URL' />
                  </a>
                }
							</div>
							{/* <div className='so-details-box'>
                    <b className='mr1'>Tracking Link:</b>
                      <span className='blue mr1'>
                      <a href={shippingFiltered.tracking_link} target="_blank" rel="noreferrer">
                        {shippingFiltered.tracking_link}
                        <img className='' src='/icons/so-details-link.svg' alt='' />
                      </a>
                      </span>
                  </div> */}
							<div className="so-details-box">
								<b className="mr1">Reference:</b> {shippingFiltered.reference}
							</div>
							<div className="so-details-box">
								<b className="mr1">Volume Number:</b> {shippingFiltered.volume}
							</div>
							<div className="so-details-box">
								<b className="mr1">Day for order pickup:</b>
								{formatDate(shippingFiltered.pickup_date)}
							</div>
							<div className="so-details-box">
								<b className="mr1">Pickup Number:</b>
								{shippingFiltered.pickup_number !== "false" ? shippingFiltered.pickup_number : ''}
							</div>
						</div>
					</div>
				)}

				<div className="width100">
					<div className="single-order-title-cont">
						<h2 className="single-order-title2">History & Files</h2>
					</div>
					<div className="order-border"></div>
					<div className="mt4 single-order-text">
						{shippingFiltered.history.map((h: any, index: number) => (
							<div className="so-details-box" key={index}>
								{`${h.description || h.event} on ${formatDate(
									h.date,
									"medium"
								)}.`}
							</div>
						))}
						{/* TODO */}
						{/*
                <div className='so-details-box'>
                  Created
                  <span className='in-row align-center pointer'>
                    <img className='mr1 ml1' src='/icons/so-file-pdf.svg' alt='' />
                    <b className='underline mr1'>Shipping Label</b>
                  </span>
                  on July 8, 2022, 12:30:28.
                </div>
                */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SingleOrderStepperInfo;
