import React, { useContext } from "react";
import { removeToken } from "../../../actions/token";
import { queryClient } from "../../App";
import { UserContext } from "../../context";
import useUser from "../../../hooks/useUser";

const LogoutWrap: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { user, setUser } = useUser();

	return !user ? (
		<></>
	) : (
		<div
			onClick={(e) => {
				e.preventDefault();
				removeToken();
				setUser(null);
				queryClient.invalidateQueries();
			}}
		>
			{children}
		</div>
	);
};

export default LogoutWrap;
