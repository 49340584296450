import axios from "axios";
import { getHeaders } from "./user";
import queryString from "query-string";
import * as T from "types/intelligence";
import { ICombinedMarketplaceProduct, IPaginatedRequest } from "../interfaces";

export async function getSellerOveriewItems(seller?: string) {
	const res = await axios.get(
		`/intelligence/overview?${queryString.stringify({ seller })}`,
		getHeaders()
	);

	return res.data as T.OveriewItem[];
}

export async function getProducts(query: { seller?: string; search?: string }) {
	const res = await axios.get(
		`/intelligence/products?${queryString.stringify(query)}`,
		getHeaders()
	);

	return res.data as T.Product[];
}

export async function getPaginatedProducts(pagination: IPaginatedRequest) {
	const res = await axios.get(
		`/intelligence/paginated-products?${queryString.stringify(pagination)}`,
		getHeaders()
	);

	return res.data as T.PaginatedProducts;
}

export async function getMarketplaces() {
	const res = await axios.get(`/intelligence/marketplaces`, getHeaders());

	return res.data as string[];
}

export async function getSellers() {
	const res = await axios.get(`/intelligence/sellers`, getHeaders());

	return res.data as string[];
}

export async function getRobots(search?: string) {
	const res = await axios.get(
		`/intelligence/robots?${queryString.stringify({ search })}`,
		getHeaders()
	);

	return res.data as T.ClientRobot[];
}

export async function getPaginatedRobots(pagination: IPaginatedRequest) {
	const res = await axios.get(
		`/intelligence/paginated-robots?${queryString.stringify(pagination)}`,
		getHeaders()
	);

	return res.data as T.PaginatedClientRobot;
}

export async function getRobot(id: string) {
	const res = await axios.get(`/intelligence/robot/${id}`, getHeaders());

	return res.data as T.Robot;
}

export async function updateRobot(id: number, data: T.Robot) {
	await axios.put(`/intelligence/robot/${id}`, data, getHeaders());
}

export type AvailableProduct = ICombinedMarketplaceProduct & {
	sku: string;
	name: string;
	barcode: string;
	modified?: boolean | undefined;
	enabled: boolean;
};

export async function getAvailableProducts(query: {
	zeoosName: string;
	sellerId: number | string;
	processId?: number | string;
}) {
	const res = await axios.get(
		`/intelligence/available-products?${queryString.stringify(query)}`,
		getHeaders()
	);

	return res.data as AvailableProduct[];
}

export async function createRobot(
	robot: T.RobotToCreate,
	products: T.ProductToCreate[]
) {
	await axios.post(
		`/intelligence/robot`,
		{
			robot,
			products,
		},
		getHeaders()
	);
}

export async function getCategories(zeoosName?: string) {
	const res = await axios.get(
		`/intelligence/categories?${queryString.stringify({ zeoosName })}`,
		getHeaders()
	);

	return res.data as T.Category[];
}

export async function updateCategories(categories: T.Category[]) {
	const res = await axios.put(
		`/intelligence/categories`,
		{ categories },
		getHeaders()
	);

	return res.data as T.Category[];
}
