"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
exports.__esModule = true;
exports.TicketStatus = exports.Type = exports.DiscountType = exports.EventType = exports.EventStatus = exports.STOCK_CONDITIONS = exports.DemoRequestStatus = exports.BatchListStatus = exports.RuleOrientation = exports.RuleType = void 0;
var RuleType;
(function (RuleType) {
    RuleType["ADD_CONTENT"] = "ADD_CONTENT";
    RuleType["CAPITALIZE"] = "CAPITALIZE";
    RuleType["LOWERCASE"] = "LOWERCASE";
    RuleType["UPPERCASE"] = "UPPERCASE";
    RuleType["REPLACE"] = "REPLACE";
    RuleType["MAKE_ARRAY"] = "MAKE_ARRAY";
    RuleType["SPLIT"] = "SPLIT";
})(RuleType = exports.RuleType || (exports.RuleType = {}));
var RuleOrientation;
(function (RuleOrientation) {
    RuleOrientation["START"] = "START";
    RuleOrientation["END"] = "END";
})(RuleOrientation = exports.RuleOrientation || (exports.RuleOrientation = {}));
var BatchListStatus;
(function (BatchListStatus) {
    BatchListStatus["WAITING"] = "WAITING";
    BatchListStatus["RUNNING"] = "RUNNING";
    BatchListStatus["FINISHED"] = "FINISHED";
    BatchListStatus["ERROR"] = "ERROR";
})(BatchListStatus = exports.BatchListStatus || (exports.BatchListStatus = {}));
var DemoRequestStatus;
(function (DemoRequestStatus) {
    DemoRequestStatus["NEW"] = "NEW";
    DemoRequestStatus["ARCHIVED"] = "ARCHIVED";
    DemoRequestStatus["ONBOARDING"] = "ONBOARDING";
})(DemoRequestStatus = exports.DemoRequestStatus || (exports.DemoRequestStatus = {}));
var inventory_1 = require("./inventory");
__createBinding(exports, inventory_1, "STOCK_CONDITIONS");
var pricingEvent_1 = require("./pricingEvent");
__createBinding(exports, pricingEvent_1, "EventStatus");
__createBinding(exports, pricingEvent_1, "EventType");
__createBinding(exports, pricingEvent_1, "DiscountType");
var notification_1 = require("./notification");
__createBinding(exports, notification_1, "Type");
var ticket_1 = require("./ticket");
__createBinding(exports, ticket_1, "TicketStatus");
