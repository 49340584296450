import React, { useContext } from "react";
import "./marketplaces.css";
import * as _ from "lodash";
import { IPaginatedRequest } from "../../../interfaces";
import { CountriesContext } from "../../context";
import { ICountryM } from "../country-management/CountryManagement";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../../../interfaces";
import FilterSelect from "../../ui/select/FilterSelect";
import { useHistory } from "react-router-dom";

export const types = ["Webstore", "Marketplace"];

interface Props {
  setOpenCreate: (openCreate: boolean) => void;
  marketplaces: any;
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  currentConfig: IPaginatedRequest;
}

export const PlatformFilter: React.FC<Props> = ({
  setOpenCreate,
  marketplaces,
  setCurrentConfig,
  currentConfig,
}) => {
  const countries = useContext(CountriesContext) as ICountryM[];
  const history = useHistory()
  return (
    <div className="marketplace-heading-and-create-box">
      <div className="mplc-filter-cont">
        <div className="mplc-filter">
          <div className="filter-text">Filter by:</div>
          <FilterSelect
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentConfig((conf: IPaginatedRequest) => ({
                ...conf,
                platformName: e.target.value,
              }))
            }
            value={currentConfig?.platformName}
            data={[
              "All platforms",
              ..._.uniq(_.map(marketplaces, (item: any) => item.zeoosName)),
            ]}
          />
          <FilterSelect
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentConfig((conf: IPaginatedRequest) => ({
                ...conf,
                type: e.target.value,
              }))
            }
            value={currentConfig?.type}
            data={["All types", ..._.uniq(_.map(types, (item: any) => item))]}
          />
          <FilterSelect
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentConfig((conf: IPaginatedRequest) => ({
                ...conf,
                country: e.target.value,
              }))
            }
            value={currentConfig?.country}
            data={[
              "All countries",
              ..._.uniq(_.map(countries, (item: any) => item.name)),
            ]}
          />
        </div>
        {/* <RoleRequired roles={[Role.admin, Role.sellerAdmin]} role={Role.admin}> */}
          <div className="create-button-box">
            <button
              onClick={() => {
                history.push("/platforms/create-edit?action=create");
              }}
              className="create-btn-lbox"
            >
              + ADD platform
            </button>
          </div>
        {/* </RoleRequired> */}
      </div>
    </div>
  );
};
