import React, { memo, useMemo } from "react";
import "./rates.css";
import { useDebouncedValue } from "@mantine/hooks";
import MappingsNav from "../../../ui/mappings-nav/MappingsNav";
import { usePlatformRates } from "../../../../actions/v2";
import { useParamsState } from "hooks";
import { getImagePath } from "../../../../utils";
import { round } from "lodash";

const PlatformRates: React.FC = () => {
  const [currentConfig, setCurrentConfig] = useParamsState({
    search: "",
    name: "All categories",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
  const { data } = usePlatformRates(debouncedConfig);

  const platforms = useMemo(
    () =>
      data?.platforms?.map((platform) => (
        <th className="table-title" key={platform}>
          <img
            src={getImagePath(platform.split(" ")[0].toLowerCase())}
            alt=""
            width="60px"
            height="60px"
            className=""
          />
          <span className="capital">{platform}</span>
        </th>
      )),
    [data?.platforms]
  );

  const rates = useMemo(
    () =>
      data?.rates.map((rate) => (
        <tr className="table-info">
          <th style={{ minWidth: "300px" }}>{rate.name}</th>

          {data?.platforms?.map((platform) => (
            <th key={`${platform}-${rate.code}`}>
              {rate.rate[platform] ? `${round(rate.rate[platform], 2)} %` : "-"}
            </th>
          ))}
        </tr>
      )),
    [data?.rates, data?.platforms]
  );

  return (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont p0">
          <div className="table-value-search-cont">
            <MappingsNav />
          </div>
          <div
            style={{ marginTop: "4px" }}
            className="rates-title-border"
          ></div>
          <div className="table-title-cont">
            <div
              className="mplc-filter-cont width100"
              style={{ paddingLeft: "16px", paddingRight: "16px" }}
            >
              <p
                style={{
                  fontWeight: "500",
                  color: "#5A5A5A",
                  fontSize: "14px",
                }}
              >
                {data?.platforms?.length} Platforms and {data?.rates?.length}{" "}
                Categories
              </p>
            </div>
          </div>

          <div className="table-main-cont">
            <div style={{ padding: "0 40px" }} className="width100">
              <div className="table-main-title-cont">
                <div className="table-main-title-search-value-cont">
                  <div className="search-box">
                    <div className="search_form">
                      <div className="table-search-box">
                        <form
                          className="table-search-form"
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <input
                            className="table-search-input"
                            placeholder="Search category"
                            onChange={(e) =>
                              setCurrentConfig({
                                search: e.target.value,
                              })
                            }
                            value={currentConfig.search}
                          />
                          <img
                            className="table-search-image"
                            src="/icons/search.svg"
                            width="25px;"
                            alt=""
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ height: "max-content", minHeight: "80vh" }}
                className="table-overflow"
              >
                <table
                  style={{
                    position: "sticky",
                    zIndex: 101,
                    top: "2px",
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        marginTop: "-5px",
                        width: "100%",
                        height: "auto",
                        padding: "11px 26px",
                        gap: "60px",
                      }}
                      className="table-results-title"
                    >
                      <th style={{ width: "100%", minWidth: "300px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "11px",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          Category
                        </div>
                      </th>
                      {platforms}
                    </tr>
                  </thead>
                </table>
                <table className="table-results">
                  <tbody>{rates}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PlatformRates);
