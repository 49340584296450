import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IZeoosOrder } from "../../../../../interfaces";
import RoleRequired from "../../../../utils/role-required/RoleRequired";
import { Role } from "../../../../../interfaces";
import { isUndefined } from "lodash";

interface Props {
  order: IZeoosOrder;
  shipping?: string;
  purchase?: boolean;
}

const formatNumber = (number: number) => {
  return new Intl.NumberFormat("de", {
    style: "currency",
    currency: "EUR",
  }).format(!number ? 0 : number);
};

const calcVAT = (price: number, vat: number) => {
  return (price * vat) / 100;
};

const SingleOrderProducts: React.FC<Props> = ({
  order,
  shipping,
  purchase,
}) => {
  let shipping_obj: any, shipping_ref, shipping_price;
  let products: any = [];
  let products_total = 0;

  if (shipping) {
    shipping_obj = order.shipping_list?.find((s: any) => s._id === shipping);
    // eslint-disable-next-line
    shipping_ref = shipping_obj ? `${shipping_obj.type}-${shipping} ` : "";
    products = order.products?.filter((p: any) =>
      shipping_obj.products.includes(p._id)
    );
    shipping_price = 9.99; //shipping from odoo
    products?.map(
      (p: any) =>
        (products_total +=
          (purchase
            ? p.vendor_price +
              calcVAT(
                p.vendor_price,
                isUndefined(p.taxPO) ? order.taxPO : p.taxPO
              )
            : p.price) * p.quantity)
    );
  } else {
    products = order.products;
    shipping_price = order.shipping_price;
    products_total = order.price;
  }

  return (
    <>
      <div className="single-order-title-cont">
        <h2 className="single-order-title2">
          {shipping_obj?.reference} Order Products
        </h2>
        <div className="in-row align-center"></div>
      </div>
      <div className="order-border"></div>
      <TableContainer className="mt4">
        <Table>
          <TableHead className="single-order-head-text">
            <TableRow>
              <TableCell className="so-image"></TableCell>
              <TableCell className="so-product">Product</TableCell>
              <TableCell className="so-sku">SKU</TableCell>
              <TableCell className="so-ean">EAN</TableCell>
              {/* <TableCell className="so-tax">Tax</TableCell> */}
              <TableCell className="so-price">
                {purchase ? "Unit" : "Sold"} Price
              </TableCell>
              {purchase && <TableCell className="so-price">IVA</TableCell>}
              <TableCell className="so-qty">Qty</TableCell>
              <TableCell className="so-total">Line Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((prod: any, index: number) => (
              <TableRow
                key={index}
                className="so-row-cont"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="so-image" component="th" scope="row">
                  <img
                    width="60px"
                    className="pointer mt1"
                    src={prod.image}
                    alt=""
                  />
                </TableCell>
                <TableCell className="so-product">{prod.name}</TableCell>
                <TableCell className="so-sku">{prod.sku}</TableCell>
                <TableCell className="so-ean">{prod.ean}</TableCell>
                {/* <TableCell className="so-tax">{prod.tax}</TableCell> */}
                <TableCell className="so-price">
                  {formatNumber(purchase ? prod.vendor_price : prod.price)}
                </TableCell>
                {purchase && (
                  <TableCell className="so-price">
                    {formatNumber(
                      calcVAT(
                        prod.vendor_price,
                        isUndefined(prod.taxPO) ? order.taxPO : prod.taxPO
                      )
                    )}
                  </TableCell>
                )}
                <TableCell className="so-qty">{prod.quantity}</TableCell>
                <TableCell className="so-total">
                  {formatNumber(
                    prod.quantity *
                      (purchase
                        ? prod.vendor_price +
                          calcVAT(
                            prod.vendor_price,
                            isUndefined(prod.taxPO) ? order.taxPO : prod.taxPO
                          )
                        : prod.price)
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RoleRequired role={Role.admin} isHubOnly={true}>
        <div className="so-footer-cont">
          {!shipping ? (
            <div className="so-footer-box single-order-text">
              <b>Shipping Costs</b>
              <span>{formatNumber(shipping_price)}</span>
            </div>
          ) : (
            ""
          )}
          <div className="so-footer-box single-order-text">
            <b>Product Totals</b>
            <span>{formatNumber(products_total)}</span>
          </div>
          {!shipping ? (
            <div className="so-footer-box single-order-text">
              <b>Order Total</b>
              <span>{formatNumber(order.price + order.shipping_price)}</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </RoleRequired>
    </>
  );
};

export default SingleOrderProducts;
