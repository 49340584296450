import React, { useState, useMemo } from "react";
import "./gestao.css";
import "../tables.css";
import { TableGestao } from "./TableGestao";
import Preloader from "../../../ui/preloader/Preloader";
import {
  IPaginatedResponse,
  IProductGestao,
  Role,
} from "../../../../interfaces";
// import { GestaoFilter } from "./GestaoFilter";
import { useDebouncedValue } from "@mantine/hooks";
import useActiveVendors from "../../../../hooks/useActiveVendors";
import { Modal } from "../../../ui/modal/Modal";
import ActivateWindow from "./ActivateWindow";
import { atom, useAtom } from "jotai";
import BatchDropDown from "../../../ui/batch-dropdown/BatchDropDown";
import useSanitizedMarketplaces from "../../../../hooks/useSanitizedMarketplaces";
import CardButtons from "./CardButtons";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { useClickOutside } from "@mantine/hooks";
import { LinearProgress } from "@mui/material";
// import OptimazeOffers from "./OptimazeOffers";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import UploadPriceStockModal from "./UploadPriceStockModal";
import { toast } from "react-toastify";
import { exportPriceStockData } from "../../../../actions/pim";
import OfferImportUploadedReportModal from "../../../ui/offer-import-uploaded-report-modal";
import ExportSellerOffersModal from "./export-seller-offers-modal";
import { useOffers } from "../../../../actions/v2/offer";
import { useParamsState } from "hooks";
import { OfferRequest } from "types/offer";
import OfferFilter from "./filter";

// import Box from "@mui/material/Box";
// import ClickAwayListener from "@mui/material/ClickAwayListener";
// import { SxProps } from "@mui/system";

// const styles: SxProps = {
// 	position: "absolute",
// 	top: 50,
// 	right: 40,
// 	left: 0,
// 	zIndex: 102,
// 	border: "1px solid",
// 	p: 1,
// 	bgcolor: "background.paper",
// 	marginLeft: -20,
// 	borderRadius: "5px !important",
// 	boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.5) !important",
// 	padding: "2px",
// };

// const buttons = [
// 	<Button className="prod-batch-logs-modal-btn" key="one">Only Stock</Button>,
// 	<Button className="prod-batch-logs-modal-btn" key="two">PVP</Button>,
// 	<Button className="prod-batch-logs-modal-btn" key="three">Purchase Price</Button>,
// ];

export type ProductResponse = IPaginatedResponse<
  IProductGestao[],
  {
    all: { sku: string }[];
  }
>;

// Object ids
export const selectedProductsAtom = atom([] as string[]);

const exportOffer = async (e: React.MouseEvent) => {
  e.preventDefault();

  try {
    await exportPriceStockData();
    toast.success("Success! You will receive your offers by email.");
  } catch (error) {
    console.error(error);
    toast.error("Something went wrong");
  }
};

const Gestao: React.FC<RouteComponentProps<{ tab: string }>> = ({
  match,
  location,
}) => {
  const tab: any = useMemo(() => match.params?.tab || "integrated", [match]);
  const [openActive, setOpenActive] = useState<boolean>(false);
  const [allSelectedFlag, setAllSelectedFlag] = useState<boolean>(false);
  const [batch, setBatch] = useState<boolean>(false);
  const [filteredMarketplaces, setFilteredMarketplaces] = useState([]);
  const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);
  const [modal1, setModal1] = useState<boolean>(false);
  const [exportSellerOffersModal, setExportSellerOffersModal] = useState(false);

  const [open, setOpen] = useState<boolean>(false);
  const dropdownRef = useClickOutside(() => {
    if (open) {
      setOpen(false);
    }
  });

  const { isLoading: loadMarketplaces, data: marketplaces } =
    useSanitizedMarketplaces();

  const vendors = useActiveVendors() as any;

  const [currentConfig, setCurrentConfig] = useParamsState<OfferRequest>({
    perPage: 10,
    page: 1,
    type: tab as any,
  });

  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

  const offers = useOffers(debouncedConfig);

  if (loadMarketplaces) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  // console.log(offers.data);

  return (
    <div className="main-body">
      {offers.isRefetching && (
        <div
          style={{
            position: "fixed",
            top: 0,
            width: "100vw",
            zIndex: 1000000,
          }}
        >
          <LinearProgress />
        </div>
      )}
      <div className="main-container">
        <BatchDropDown
          products={offers.data}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          batch={batch}
          setBatch={setBatch}
          productsFrom={"inventory"}
          setOpenWindow={setOpenActive}
          allSelectedFlag={allSelectedFlag}
          setAllSelectedFlag={setAllSelectedFlag}
        />
        <RoleRequired role={Role.user} strict>
          <div className="table-main-cont pt4">
            <CardButtons setCurrentConfig={setCurrentConfig} />
          </div>

          <OfferImportUploadedReportModal />
        </RoleRequired>

        <div className="table-title-cont">
          <div className="mplc-filter-cont mt1">
            <div className="gestao-select-cont justify-between">
              <div className="in-row align-center">
                <span
                  className="pim-language-text pl4"
                  style={{ textTransform: "capitalize" }}
                >
                  All my offers
                </span>

                {/* <button className="tax-title-button pointer ml1">
									<div className="pim-avaliacoes-title-button-text initial p0">Optimize offers</div>
								</button>
								<button className="tax-title-button pointer ml1">
									<div className="pim-avaliacoes-title-button-text initial p0">Export all</div>
								</button> */}
              </div>

              <div className="in-row align-center">
                <OfferFilter
                  currentConfig={currentConfig}
                  setCurrentConfig={setCurrentConfig}
                />

                <div className="app-drop-down-container ml2" ref={dropdownRef}>
                  <button
                    style={{ border: "1px solid #000", width: "120px" }}
                    className="main-select-bi no-shadow p1"
                    onClick={() => setOpen((p) => !p)}
                  >
                    <img src="/icons/not-set-dotts.svg" alt="" />
                    <span className="email-text">More</span>
                    <img width="14px" src="/icons/down-arrow.png" alt="" />
                  </button>

                  {open && (
                    <ul
                      style={{
                        marginLeft: "-80px",
                        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.50)",
                        borderRadius: "5px",
                      }}
                    >
                      <li
                        style={{ padding: "10px 5px", width: "200px" }}
                        onClick={() => {
                          setModal1(true);
                        }}
                        className="in-row align-center not-menuitem-cont"
                      >
                        <span className="not-menuitem-text">
                          Update price and stock
                        </span>
                      </li>
                      <li
                        style={{ padding: "10px 5px", width: "200px" }}
                        className="in-row align-center not-menuitem-cont"
                        onClick={exportOffer}
                      >
                        <span className="not-menuitem-text">
                          Export my catalog
                        </span>
                      </li>
                      <NavLink className="width100" to="/offer-import">
                        <li
                          style={{ padding: "10px 5px", width: "200px" }}
                          className="in-row align-center not-menuitem-cont"
                        >
                          <span className="not-menuitem-text">
                            Offer import reports
                          </span>
                        </li>
                      </NavLink>
                      <RoleRequired>
                        <li
                          style={{ padding: "10px 5px", width: "200px" }}
                          className="in-row align-center not-menuitem-cont"
                          onClick={() => setExportSellerOffersModal(true)}
                        >
                          <span className="not-menuitem-text">
                            Export seller offers
                          </span>
                        </li>
                      </RoleRequired>
                    </ul>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="mr2">
							<ClickAwayListener onClickAway={() => setOpen(false)}>
								<Box sx={{ position: "relative" }}>
									<button
										type="button"
										className="mass-import-button"
										onClick={() => setOpen((prev) => !prev)}
									>
										<img src="/icons/inv-settings-line.svg" alt="" />
										More
										<img width="11px" src="/icons/down-arrow.png" alt="" />
									</button>
									{open ? (
										<Box sx={styles}>
											<button className="mass-import-option">
												Mass update price/stock
											</button>
											<button className="mass-import-option">
												Export product price/stock
											</button>
										</Box>
									) : null}
								</Box>
							</ClickAwayListener>
						</div> */}
          </div>
        </div>
        <TableGestao
          marketplaces={
            filteredMarketplaces.length
              ? filteredMarketplaces
              : (marketplaces as any)
          }
          vendors={vendors.data!}
          products={offers.data!}
          // @ts-ignore
          setCurrentConfig={setCurrentConfig}
          currentConfig={currentConfig}
          refetchProducts={offers.refetch}
          loadProducts={offers.isLoading}
          allSelectedFlag={allSelectedFlag}
        />
        <div className="table-title-cont" />
      </div>
      {openActive && (
        <Modal onModalClose={() => setOpenActive(false)} isOpened={openActive}>
          <ActivateWindow
            setOpenActive={setOpenActive}
            selectedProducts={selectedProducts}
            allSelectedFlag={allSelectedFlag}
            setAllSelectedFlag={setAllSelectedFlag}
            key={""}
          />
        </Modal>
      )}
      {exportSellerOffersModal && (
        <ExportSellerOffersModal
          isOpened={exportSellerOffersModal}
          onModalClose={(e: React.MouseEvent) => {
            e.stopPropagation();
            setExportSellerOffersModal(false);
          }}
        />
      )}

      {/* {showExportForm && (
				<ExportAllProductInfo
					modal={showExportForm}
					setModal={setShowExportForm}
					skus={selectedProducts}
				/>
			)} */}
      {modal1 && (
        <UploadPriceStockModal
          onModalClose={(e: React.MouseEvent) => {
            e.stopPropagation();
            setModal1(false);
          }}
          isOpened={modal1}
          setModal={setModal1}
        />
      )}
    </div>
  );
};

export default withRouter(Gestao);
