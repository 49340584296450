import React, { useCallback, useState } from "react";
import useUser from "../../../hooks/useUser";
import { Role } from "../../../interfaces";
import "./no-contract-box.css";
import { toast } from "react-toastify";
import { uploadContractFile } from "../../../actions/onboardingSeller";

const NoContractFileBox = () => {
  const [file, setFile] = useState<File>();
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { user, invalidate } = useUser();


  const saveContractFile = useCallback(async () => {
    if(isSubmitting){
        return
    }

    if (!file) {
      toast.error("Please Select a file");
      console.log({file})
      return;
    }
    setIsSubmitting(true);
    try {
      await uploadContractFile(file);
      invalidate()
      window.location.reload();
    } catch (error) {
      toast.error("Something went wrong...");
    }
    setIsSubmitting(false);
    setFile(undefined)
  }, [invalidate]);

  const readUploadFile = useCallback(async (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  }, []);

  if (user?.role === Role.admin || user.seller?.contractURL !== null) {
    return <></>;
  }

  return (
    <div className="no-contract-box-container">
      {file?.name ? (
        <p>{file?.name}</p>
      ) : (
        <p>Pending Action: Upload Contract File</p>
      )}
      <div className="no-contract-box-buttons">
        {file?.name ? (
          <div onClick={saveContractFile} className="no-contract-box-button mr2">
            <p>{isSubmitting ? "Uploading...." : "Upload"}</p>
          </div>
        ) : (
          <>
            <div className="no-contract-box-button mr2">
              <a href="/template.pdf" target="_blank">
                {" "}
                Download Template File
              </a>
            </div>
            <div className="no-contract-box-button">
              <label htmlFor="upload">Upload the file</label>
              <input
                type="file"
                name="file"
                id="upload"
                onChange={readUploadFile}
                hidden
                accept="application/pdf"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NoContractFileBox;
