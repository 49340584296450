import axios from "axios";
import { getHeaders } from "./user";

export async function createCountry(body: any) {
  const formData = new FormData();
  formData.append("file", body.file);
  formData.append("name", body.name);
  formData.append("language", body.language);
  formData.append("langCode", body.langCode);
  const res = await axios.post("/countryManagement", formData, getHeaders());
  return res.data.countryManagement;
}

export async function getCountries() {
  const res = await axios.get("/countriesManagement", getHeaders());
  return res.data.countriesManagement;
}

export async function getCountry(id: string) {
  const res = await axios.get(`/countryManagement/${id}`, getHeaders());
  return res.data.countryManagement;
}

export async function updateCountry(body: any) {
  const formData = new FormData();
  formData.append("file", body.file);
  formData.append("name", body.name);
  formData.append("language", body.language);
  formData.append("isLang", body.isLang);
  formData.append("langCode", body.langCode);

  const res = await axios.put(
    `/countryManagement/${body._id}`,
    formData,
    getHeaders()
  );
  return res.data.newCountry;
}

export async function deleteCountry(id: string) {
  await axios.delete(`/countryManagement/${id}`, getHeaders());
}
