import React from "react";
import { Modal } from "../../../ui/modal/Modal";
import { IZeoosDemoRequest } from "types";

interface Props {
	modal: boolean;
	setModal: (value: React.SetStateAction<boolean>) => void;
	info: IZeoosDemoRequest;
}

const CompanyInformation: React.FC<Props> = ({ modal, setModal, info }) => {
	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<>
				<div className="table-modal-form-cont p4">
					<div className="table-modal-title-box m0">
						<div className="mapping-modal-title-text">Company Information</div>
					</div>
					<form className="table-modal-form-cont">
						<div className="table-modal-border"></div>
						<div className="table-vendormm-title-cont">
							<div className="table-vendormm-title-box">
								<div className="in-row width100">
									<div className="mapping-modal-text mr3 bold">
										Vendor Name:
									</div>
									<div>{info.companyName}</div>
								</div>
								<div className="in-row width100">
									<div className="mapping-modal-text mr3 bold">Country:</div>
									<div>{info.country}</div>
								</div>
								<div className="in-row width100">
									<div className="mapping-modal-text mr3 bold">Phone:</div>
									<div>{info.phone}</div>
								</div>
							</div>
						</div>
						<div className="mb4">
							<div className="table-modal-border"></div>
							<div className="width100 mt2 mb2">
								<div className="in-row width100">
									<div className="mapping-modal-text mr3 bold">
										Contact Name:
									</div>
									<div>{`${info.firstName} ${info.lastName}`}</div>
								</div>
								<div className="in-row width100">
									<div className="mapping-modal-text mr3 bold">E-mail:</div>
									<div>{info.email}</div>
								</div>
							</div>
						</div>
						<div className="table-modal-border"></div>
						<div className="in-row align-center justify-center width100">
							<button
								className="table-modal-form-button"
								onClick={onModalClose}
							>
								CLOSE
							</button>
						</div>
					</form>
				</div>
			</>
		</Modal>
	);
};

export default CompanyInformation;
