import { createContext } from "react";
import { getCountries } from "../actions/countryManagement";

export const UserContext: any = createContext(true);
export const CountriesContext: any = createContext(
  async () => await getCountries()
);

let token: string | null = null;

export function getToken() {
  return token;
}

export function setToken(newToken: string) {
  token = newToken;
}
