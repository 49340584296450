import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Modal } from "../../ui/modal/Modal";
import { ICombinedOrder } from "../../../interfaces";
import { NavLink } from "react-router-dom";

interface Props {
  orders: ICombinedOrder[];
  totalOrders: string;
  ordersWaiting?: number;
  columns: any;
}

export const OrderDetails: React.FC<Props> = ({
  orders,
  totalOrders,
  ordersWaiting,
  columns,
}) => {
  ordersWaiting = ordersWaiting || 0;
  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  function addUniqueId(array: ICombinedOrder[]) {
    array.forEach((x: ICombinedOrder) => (x.id = x.sale_id));
    return array;
  }

  return (
    
    <div
      className="bi__card orders"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setModal(true);
      }}
    >
      <NavLink className="width100" to="/orders">
      <div className="bi__card_title">Orders for the period</div>
      <div className="bi__card_details">*click to see more details</div>
      <div className="bi__card_content">
        {ordersWaiting > 0 && (
          <div>
            {ordersWaiting}
            <span>Awaiting</span>
          </div>
        )}
        <div>
          {totalOrders}
          <span>Invoiced</span>
        </div>
      </div>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <DataGrid
            className="bi__card_order_details"
            rows={addUniqueId(orders)}
            columns={columns}
            disableColumnMenu={true}
            showColumnRightBorder={true}
            hideFooter={false}
            pageSize={7}
          />
        </Modal>
      )}
      </NavLink>
    </div>
  );
};
