import React, { useState } from "react";
import { toast } from "react-toastify";

interface Props {
  password: string;
}

export const Password: React.FC<Props> = ({ password }) => {
  // const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <>
      <div className="bold primary-color pointer" onClick={() => {
        navigator.clipboard.writeText(password);
        toast.success('Password copied!');
      }}>
        *************
      </div>
      {/* <div className="bold primary-color">
        {showPassword ? password : "*************"}
      </div>
      <img
        className="pointer"
        width="25px"
        src={showPassword ? "/icons/grey-view.svg" : "/icons/closed-eye.svg"}
        alt=""
        onClick={() => setShowPassword(!showPassword)}
      /> */}
    </>
  );
};
