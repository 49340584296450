import React, { useState, useEffect, useCallback } from "react";
import "./single-billing.css";
import { Preco } from "./Preco";
import { TooltipText } from "../../tables/gestao/Tooltip";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import Paginator from "../../../ui/pagination";
import { IBillings, IBillLineIds } from "./SingleBilling";

export const getBillingLogoSvg = (platformName: string) => {
	if (
		platformName === "B2C" ||
		platformName === "B2B" ||
		platformName.split(" ")[0].toLowerCase() === "vinuus"
	) {
		return "shopify";
	}

	return platformName.split(" ")[0].toLowerCase();
};

export const MarketplaceImage: React.FC<
	{ zeoosName: string } & React.DetailedHTMLProps<
		React.ImgHTMLAttributes<HTMLImageElement>,
		HTMLImageElement
	>
> = ({ zeoosName, ...props }) => {
	return (
		<img
			src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${getBillingLogoSvg(
				zeoosName
			)}.svg`}
			{...props}
			alt=""
		/>
	);
};

interface IConfigPagination {
	page: number;
	perPage: number;
}

interface IPaginatedBillings {
	data: IBillings[];
	lastPage: number;
	page: number;
	perPage: number;
	total: number;
}

interface Props {
	billings: IBillings[];
	platformName: string;
	country: string;
}

export const SingleBillingTable: React.FC<Props> = ({
	billings,
	platformName,
	country,
}) => {
	const [paginatedData, setPaginatedData] = useState<IPaginatedBillings>({
		data: billings,
		perPage: 50,
		page: 1,
		total: billings.length,
		lastPage: Math.ceil(billings.length / 50),
	});
	const [results, setResults] = useState<any>(billings);
	const [search, setSearch] = useState<string>("");

	useEffect(() => {
		const _filter =
			platformName === "All platforms"
				? { ...paginatedData, data: billings }
				: {
						...paginatedData,
						data: billings.filter(
							(x: IBillings) => x.platform_name === platformName
						),
				  };
		const filtered =
			country === "All countries"
				? _filter
				: {
						...paginatedData,
						data: billings.filter((x: IBillings) => x.country === country),
				  };

		setPaginatedData(filtered);
		// eslint-disable-next-line
	}, [platformName, country]);

	useEffect(() => {
		const results = billings
			// eslint-disable-next-line
			.filter((item: any) => {
				if (search === "") {
					return item;
				} else if (
					Object.keys(item).filter(
						(x: any) =>
							typeof item[x] === "string" &&
							item[x].toLowerCase().includes(search.toLowerCase())
					)?.length > 0 ||
					item.bill_line_ids.filter((y: any) =>
						y.product_description.toLowerCase().includes(search.toLowerCase())
					)?.length > 0 ||
					item.bill_id === Number(search)
				) {
					return item;
				}
			});
		setResults(results);
		setPaginatedData({
			...paginatedData,
			data: results.slice(0, paginatedData.perPage),
		});
		// eslint-disable-next-line
	}, [search, billings]);

	const onChangePage = useCallback(
		async (config: IConfigPagination) => {
			setPaginatedData((c: IPaginatedBillings) => {
				const getData = () => {
					if (config.page === 1) {
						return billings.slice(0, config.perPage);
					}

					return config.perPage * config.page > c.total
						? billings.slice(config.perPage * c.page)
						: billings.slice(config.perPage, config.perPage * config.page);
				};

				return {
					...c,
					page: config.page,
					perPage: config.perPage,
					lastPage: Math.ceil(c.total / config.perPage),
					data: getData(),
				};
			});
		},
		// eslint-disable-next-line
		[setPaginatedData]
	);

	const productsSold = () => {
		return paginatedData.data
			.map((billing: IBillings) =>
				billing.bill_line_ids.reduce(
					(prev: number, next: IBillLineIds) => prev + next.quantity_sold,
					0
				)
			)
			.reduce((prev: number, next: number) => prev + next, 0);
	};

	// const totalPaidValue = () => {
	// 	return paginatedData.data
	// 		.map((billing: IBillings) =>
	// 			billing.bill_line_ids.reduce(
	// 				(prev: number, next: IBillLineIds) =>
	// 					prev + next.subtotal * next.quantity_sold,
	// 				0
	// 			)
	// 		)
	// 		.reduce((prev: number, next: number) => prev + next, 0)
	// 		.toLocaleString("de-DE", {
	// 			minimumFractionDigits: 2,
	// 			maximumFractionDigits: 2,
	// 		});
	// };

	return (
		<>
			<div className="table-main-title-cont">
				<div className="table-main-title-search-value-cont">
					<div className="search-box">
						<div className="search_form">
							<div className="table-search-box">
								<form
									className="table-search-form"
									onSubmit={(e) => e.preventDefault()}
								>
									<input
										className="table-search-input"
										placeholder="Search billing"
										onChange={(e) => setSearch(e.target.value)}
									/>
									<img
										className="table-search-image"
										src="/icons/search.svg"
										width="25px;"
										alt=""
									/>
								</form>
							</div>
						</div>
					</div>
					<span className="table-main-title-text">
						{results.length} {results.length > 1 ? "Billings" : "Billing"} of
						shipment {billings[0].shipment_id}
					</span>
				</div>
				<div className="marketplace-pagination">
					<Paginator data={paginatedData} refetch={onChangePage as any} />
				</div>
			</div>
			<div className="table-border-bottom"></div>
			<div
				style={{ height: "max-content", minHeight: "80vh" }}
				className="table-overflow"
			>
				<table
					style={{
						position: "sticky",
						zIndex: 101,
						top: "2px",
						width: "100%",
					}}
				>
					<tbody>
						<tr
							style={{ marginTop: "-5px", width: "100%" }}
							className="table-results-title"
						>
							<th className="single-billing-medium">Billing ID</th>
							<th className="single-billing-medium">ID Order</th>
							<th className="single-billing-large">Product Name</th>
							{/* <th className="single-billing-medium align-center">Platform</th> */}
							<th style={{ alignItems: "center" }} className="single-billing-medium">Plaform Name</th>
							<th className="single-billing-small">Country</th>
							{/* <th className="single-billing-medium align-center">
								Quantity Sold
							</th> */}
							<th className="single-billing-medium align-center">Price</th>
							<th className="single-billing-small">Date / Time</th>
							<th className="single-billing-small">Status</th>
						</tr>
					</tbody>
				</table>
				<table className="table-results">
					<tbody>
						{paginatedData.data.map((item: IBillings) => {
							return item.bill_line_ids.map(
								(bill: IBillLineIds, index: number) => (
									<tr className="table-result-box billing-text" key={index}>
										<td className="single-billing-medium">
											<div className="billing-text">{item.bill_id}</div>
										</td>
										<td className="single-billing-medium">
											<div className="billing-text">{item.bill_name}</div>
										</td>
										<td className="single-billing-large justify-start">
											<div className="billing-text">
												<TooltipText
													text={bill.product_description}
													items={6}
													separator={" "}
												/>
											</div>
										</td>
										{/* <td className="single-billing-medium align-center">
											{item.platform_name && (
												<img
													src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${getBillingLogoSvg(
														item.platform_name
													)}.svg`}
													alt=""
													width="50px"
												/>
											)}
										</td> */}
										<td style={{ alignItems: "center" }} className="single-billing-medium">
											<div className="billing-text">{item.platform_name}</div>
										</td>
										<td className="single-billing-small">
											<CountryFlag
												country={item.country}
												className="table-search-image"
												width="32px"
											/>
										</td>
										{/* <td className="single-billing-medium align-center">
											<div className="billing-text">{bill.quantity_sold}</div>
										</td> */}
										<td className="single-billing-medium align-center">
											<Preco item={item} bill={bill} />
										</td>
										<td className="single-billing-small">
											<div className="billing-text">{item.invoice_date}</div>
										</td>
										<td className="single-billing-small">
											<div className="paybatch-paid">Paid</div>
										</td>
									</tr>
								)
							);
						})}
						<tr style={{ height: "96px" }} className="table-result-box">
							<td className="single-billing-total">
								{platformName !== "All platforms" ||
								country !== "All countries" ||
								search !== "" ? (
									<></>
								) : (
									<div className="billing-text bold uppercase-text">
										{billings.length} billings
									</div>
								)}
							</td>
							<td className="single-billing-total-large">
								<div className="billing-text bold">
									{productsSold()} sold products
								</div>
							</td>
							<td className="single-billing-total-small">
								<div className="in-column align-end line-height-medium billing-text bold">
									<span>Total Open:</span>
									<span className="ft3">
										{billings[0].total_open.toLocaleString("de-DE", {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}{" "}
										€
									</span>
								</div>
							</td>
							<td className="">
								<div className="in-column align-end line-height-medium billing-text bold">
									<span>Total Paid:</span>
									{/* <span className="ft3">{totalPaidValue()} €</span> */}
									<span className="ft3">
										{billings[0].total_paid.toLocaleString("de-DE", {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}{" "}
										€
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};
