import React, { useCallback, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Button, ButtonGroup } from "@mui/material";
import { downloadPriceStockData } from "../../../../actions/onboardingSeller";
import { ProductImportReport } from "types/seller";
import { massUpdateCountryPrice } from "../../../../actions/vendor";

interface Props {
  onModalClose: any;
  isOpened: boolean;
  report?: ProductImportReport;
  setModal: any;
}

const UploadPriceStockModal: React.FC<Props> = ({
  onModalClose,
  isOpened,
  report,
  setModal
}) => {
  const [mode, setMode] = useState<string>("cost");

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: [".xlsx"],
    onDropRejected: () => {
      toast.error('File type rejected. Only ".xlsx" files are allowed.');
    },
  });

  const downloadFile = async (e: React.MouseEvent) => {
    e.preventDefault();

    try {
      await downloadPriceStockData(mode, report?._id);
      toast.success("Your spreadsheet will be sent by email!", { autoClose: 10000 });
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      try {
        const data = await massUpdateCountryPrice(acceptedFiles[0], mode, report?._id);
        if (data.success) {
          toast.success(`${data.report.uploaded} offers were found for update. ${data.report.messages[0]}.`, { autoClose: 10000 });
        } else {
          toast.error(`${data.report.messages.join('\n')}`, { autoClose: false });
        }
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong. Please, check you file once again");
      }
    },
    [acceptedFiles, report?._id, mode]
  );

  return (
    <Modal onModalClose={onModalClose} isOpened={isOpened}>
      <div className="table-modal-form-cont in-column justify-center p4">
        <div className="table-modal-title-box m0">
          <div className="import-title">Update price & stock</div>
        </div>
        <div className="table-modal-border"></div>
        <span className="mt2 in-row width100 align-center">
          <b className="mr1">Select what you want to update</b>
        </span>

        <ButtonGroup className="width100 mt2 align-center" aria-label="small button group">
          <Button className="prod-batch-logs-modal-btn" key="one"
            onClick={() => setMode("stock")}>
            Only Stock
          </Button>
          <Button className="prod-batch-logs-modal-btn" key="two"
            onClick={() => setMode("pvp")}>
            PVP
          </Button>
          <Button className="prod-batch-logs-modal-btn" key="three"
            onClick={() => setMode("cost")}>
            Purchase Price
          </Button>
          <Button className="prod-batch-logs-modal-btn" key="four"
            onClick={() => setMode("auto")}>
            Cost + PVP
          </Button>
        </ButtonGroup>

        <span className="mt2 in-row width100 align-center">
          <b className="mr1">Add the Purchase Price file</b>
          (.xlsx file)
        </span>
        <div
          className="width100 pointer import-reviews-dropzone"
          style={{ width: "400px", height: "150px", background: "" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <h4>
              <img src="/icons/upload-spreadsheet.svg" alt="" />
              Drop the file here
            </h4>
          ) : (
            <h2 className="pc-modal-dropzone-text">
              {acceptedFiles.length > 0
                ? `Click 'Import' to finish upload ${acceptedFiles[0].name}`
                : <>
                  <img src='/icons/upload-spreadsheet.svg' alt='' />
                  Drop the file here
                </>
              }
            </h2>
          )}
        </div>
        <div className="bold mt2 in-row width100 align-center" style={{ color: "#55718a" }}>
          <div style={{ fontSize: "12px" }} className="pointer"
            onClick={downloadFile}
          >
            Download Template Spreadsheet ({mode})
          </div>
        </div>
        <div className="table-modal-border"></div>
        <div style={{ marginBottom: "0" }} className="table-modal-form-button-box width100">
          <button
            className="sel-modal-form-button-cancel"
            onClick={onModalClose}
          >
            Cancel
          </button>
          <button onClick={(e: React.FormEvent) => {
            setModal(false);
            onSubmit(e);
          }}
            disabled={!acceptedFiles.length}
            className="table-modal-form-button initial" type="submit">
            Import
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadPriceStockModal;
