"use strict";
exports.__esModule = true;
exports.getEventProductDiscountPrice = void 0;
var types_1 = require("types");
function getEventProductDiscountPrice(pricingEvent, oldPrice) {
    switch (pricingEvent.discountType) {
        case types_1.DiscountType.PERCENTAGE: {
            return oldPrice - (oldPrice * pricingEvent.discountAmount) / 100;
        }
        case types_1.DiscountType.VALUE:
        default:
            return oldPrice - pricingEvent.discountAmount;
    }
}
exports.getEventProductDiscountPrice = getEventProductDiscountPrice;
