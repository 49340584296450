import { useMemo } from "react";
import { useCategories } from ".";

export const useCategoryByLevel = (code: string) => {
  const { data: categories } = useCategories({});
  const levels = useMemo(() => {
    if (!categories) return [];

    const targetCategory = categories.find((c) => c.code === code);
    if (!targetCategory) return [];

    const levelNames = [];
    let currentCategory = targetCategory;

    while (currentCategory) {
      levelNames.push(currentCategory.name);
      if (currentCategory.level <= 1) break;
      currentCategory = categories.find((c) => c._id === currentCategory.parent)!;
    }

    return levelNames.reverse();
  }, [categories, code]);

  return levels
};
