import React, { useState, memo } from "react";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

interface Props {
	title: string;
	handleClick?: (e: React.MouseEvent) => void;
	icon: string;
	iconHover: string;
	size: { w: string; h: string };
}

const TooltipIcon: React.FC<Props> = ({
	title,
	handleClick,
	icon,
	iconHover,
	size,
}) => {
	const [over, setOver] = useState<boolean>(false);

	return (
		<Tooltip
			title={title}
			placement="top-start"
			className="pointer"
			TransitionComponent={Zoom}
		>
			<img
				onMouseOver={() => setOver(true)}
				onMouseOut={() => setOver(false)}
				onClick={handleClick}
				width={size.w}
				height={size.h}
				src={!over ? icon : iconHover}
				alt=""
			/>
		</Tooltip>
	);
};

export default memo(TooltipIcon);
