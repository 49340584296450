import React, { useEffect, useState } from "react";
import "./country-management.css";
import { deleteCountry } from "../../../actions/countryManagement";
import { toast } from "react-toastify";
import { ICountryM } from "./CountryManagement";
import { CountryDelete } from "./CountryDelete";
import { Paginator } from "../../ui/pagination/Paginator";
import { LangToggle } from "./LangToggle";

interface Props {
  countries: ICountryM[];
  setCountries: (countries: any) => void;
  setModal: (modal: boolean) => void;
  setCountry: (country: ICountryM) => void;
  setAction: (action: string) => void;
}

export const CountriesTable: React.FC<Props> = ({
  countries,
  setCountries,
  setModal,
  setCountry,
  setAction,
}) => {
  const [search, setSearch] = useState<any>("");
  const [paginatedData, setPaginatedData] = useState<any>([]);
  const [limit, setLimit] = useState<number>(10);
  const [results, setResults] = useState<any>(countries);

  useEffect(() => {
    const results = countries
      // eslint-disable-next-line
      .filter((item: any) => {
        if (search === "") {
          return item;
        } else if (
          Object.keys(item).filter(
            (x: any) =>
              typeof item[x] === "string" &&
              item[x].toLowerCase().includes(search.toLowerCase())
          )?.length > 0 ||
          String(item.sale_amount).includes(search)
        ) {
          return item;
        }
      });
    setResults(results);
    setPaginatedData(results.slice(0, limit));
    // eslint-disable-next-line
  }, [search, countries]);

  const handleRemoveCountry = async (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    try {
      await deleteCountry(id);
      setCountries((prevState: ICountryM[]) =>
        prevState.filter((x: ICountryM) => x._id !== id)
      );
      toast.success("Country successfully deleted!");
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong! Please, reload the page");
    }
  };

  return (
    <div className="table-main-cont">
      <div style={{padding: "0 40px"}} className="width100">
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search country"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-main-title-text pl2">
              {results.length} {results.length > 1 ? "countries" : "country"}
            </div>
          </div>
        </div>
        <div className="countries-pagination">
          <Paginator
            data={search !== "" ? results : countries}
            rowsPerPage={limit}
            setPaginatedData={setPaginatedData}
            setLimit={setLimit}
            limit={limit}
          />
        </div>
      </div>
      <div  style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
        >
          <thead>
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="cm-flag">Flag Icon</th>
              <th className="cm-reg">Country</th>
              <th className="cm-reg">Language</th>
              <th className="cm-reg">Language code</th>
              <th className="cm-reg">Is lang</th>
              <th className="cm-delete justify-center">Actions</th>
            </tr>
          </thead>
        </table>
        <table className="table-results">
          <tbody>
            {paginatedData.map((country: ICountryM, index: number) => (
              <React.Fragment key={index}>
                <tr className="table-result-box freight-body">
                  <td className="cm-flag">
                    <img
                      src={country.iconUrl}
                      alt=""
                      width="40px"
                      height="30px"
                    />
                  </td>
                  <td
                    onClick={() => {
                      setModal(true);
                      setCountry(country);
                      setAction("edit");
                    }}
                    className="cm-reg pointer"
                  >
                    {country.name}
                  </td>
                  <td className="cm-reg">{country.language}</td>
                  <td className="cm-reg">{country.langCode}</td>
                  <LangToggle country={country} />
                  <td className="cm-delete">
                    {/* <div className="in-row align-center pointer underline">
											<img src="/icons/grey-edit.svg" alt="" />
											<div className="ml2">Edit</div>
										</div> */}
                    <CountryDelete
                      handleRemoveCountry={handleRemoveCountry}
                      country={country}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};
