import React, { useState } from "react";
import { Paginator } from "../../../ui/pagination/Paginator";
import { NavLink } from "react-router-dom";

export default function SingleNotificationTable() {
    const [limit, setLimit] = useState<number>(50);
    const [paginatedData, setPaginatedData] = useState<any>();

    return (
        <>
            <div className="table-main-title-cont">
                <div className="table-main-title-search-value-cont">
                    <div className="search-box">
                        <div className="search_form">
                            <div className="table-search-box">
                                <form
                                    className="table-search-form"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <input
                                        className="table-search-input"
                                        placeholder="Search product or sku"
                                    />
                                    <img
                                        className="table-search-image"
                                        src="/icons/search.svg"
                                        width="25px;"
                                        alt=""
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="table-main-title-search-value-cont">
                        <div className="table-main-title-text">7 offers</div>
                    </div>
                </div>
                <div className="marketplace-pagination">
                    {/* Pagination */}
                    <Paginator
                        data=""
                        rowsPerPage={limit}
                        setPaginatedData={setPaginatedData}
                        setLimit={setLimit}
                        limit={limit}
                    />
                </div>
            </div>
            <div
                style={{ height: "max-content", marginBottom: "20px" }}
                className="table-overflow"
            >
                <table
                    style={{
                        zIndex: 101,

                        width: "100%",
                    }}
                >
                    <tr
                        style={{ marginTop: "-5px", width: "100%" }}
                        className="table-results-title"
                    >
                        <th className="not-snot-title">SKU - Title - Price</th>
                        <th className="not-snot-offer">Offer</th>
                    </tr>
                </table>
                <table style={{ marginTop: "-5px" }} className="table-results">
                    <tbody>
                    <tr className="table-result-box email-text border0 m0 height80px width100">
                        <td className="not-snot-title">
                        00011122 - Suplemento Alimentar GOLDNUTRITION Omega 3 Concentrado-Gn Clinical (Cítricos - 3 g) - 25,69 € on Amazon ES
                        </td>
                        <NavLink to="/">
                        <td className="not-snot-offer">
                            <img src="/icons/not-preview.svg" alt="" />
                        </td>
                        </NavLink>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}