import React from "react";
import { NavLink } from "react-router-dom";

export default function MappingsNav() {
  return (
    <div className="table-title-nav-cont">
      <NavLink
        style={{
          padding: "10px 10px 24px",
        }}
        to="/freight"
        className="table-tab-mapping"
        activeClassName="table-tab-mapping-active"
      >
        Freight
      </NavLink>
      <NavLink
        style={{ padding: "10px 10px 24px" }}
        to="/tax"
        className="table-tab-mapping"
        activeClassName="table-tab-mapping-active"
      >
        Tax
      </NavLink>
      <NavLink
        style={{ padding: "10px 10px 24px" }}
        to="/platform-rates"
        className="table-tab-mapping"
        activeClassName="table-tab-mapping-active"
      >
        Platform Rates
      </NavLink>
      {/* <NavLink to="/vendor" className="table-tab-mapping">
                      Vendor Mapping
                    </NavLink> */}
    </div>
  );
}
