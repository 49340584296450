import axios from "axios";
import { ISchemeCategoryItem } from "types";
import { getHeaders } from "./user";
import queryString from "query-string";

export async function find(categoryId: string) {
  const res = await axios.get(
    `/scheme-category-items?categoryId=${categoryId}`,
    getHeaders()
  );
  return res.data as ISchemeCategoryItem[];
}

export async function create(item: Partial<ISchemeCategoryItem>) {
  await axios.post("/scheme-category-item", item, getHeaders());
}

export async function createMany(items: Partial<ISchemeCategoryItem>[]) {
  await axios.post("/scheme-category-items", items, getHeaders());
}

export async function update(id: string, item: Partial<ISchemeCategoryItem>) {
  await axios.put(`/scheme-category-item/${id}`, item, getHeaders());
}

export async function remove(id: string) {
  await axios.delete(`/scheme-category-item/${id}`, getHeaders());
}

export async function removeMany(ids: string[]) {
  if (!ids.length) {
    return;
  }

  const query = queryString.stringify({ ids });
  await axios.delete(`/scheme-category-items?${query}`, getHeaders());
}
