import React, { useCallback, useState } from "react";
import { Delete } from "./Delete";
import { Password } from "./Password";
import "./password-management.css";
import { toast } from "react-toastify";
import { IPaginatedRequest, IPassword } from "../../../interfaces";
import { CreateEditPassword } from "./CreateEditPassword";
import { useMutation } from "react-query";
import { queryClient } from "../../App";
import * as PasswordController from "../../../actions/passwordManagement";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import Paginator from "../../ui/pagination";
import { useOTP } from 'hooks';

interface Props {
  passwordsInfo: any;
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  currentConfig: IPaginatedRequest;
  loadPasswordsInfo: boolean;
  rqKeyPasswordsInfo: string;
}

export const PasswordManagementtable: React.FC<Props> = ({
  passwordsInfo,
  setCurrentConfig,
  currentConfig,
  loadPasswordsInfo,
  rqKeyPasswordsInfo,
}) => {
  const [selectedPassword, setSelectedPassword] = useState<null | IPassword>(
    null
  );

  const onChangePage = useCallback(
    async (config: IPaginatedRequest) => {
      setCurrentConfig((c) => {
        return {
          ...c,
          ...config,
        };
      });
    },
    [setCurrentConfig]
  );

  const deleteMutation = useMutation(
    PasswordController.deletePasswordManagement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(rqKeyPasswordsInfo);
        toast.success("Password successfully deleted!");
      },
      onError: () => {
        toast.error("Something went wrong!");
      },
    }
  );

  const onDelete = useCallback(
    async (e: React.MouseEvent, id: string) => {
      e.preventDefault();

      await deleteMutation.mutateAsync(id);
    },
    [deleteMutation]
  );

  return (
    <div className="table-main-cont">
      <div style={{padding: "0 40px"}} className="width100">
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search marketplace"
                    onChange={(e) =>
                      setCurrentConfig((conf: IPaginatedRequest) => ({
                        ...conf,
                        search: e.target.value,
                      }))
                    }
                    value={currentConfig.search}
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-main-title-text pl2">
              {loadPasswordsInfo ? (
                <CircularPreloader />
              ) : (
                <span className="table-main-title-text">
                  {passwordsInfo?.total} passwords
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="marketplace-pagination">
          <Paginator
            data={!loadPasswordsInfo ? passwordsInfo : {}}
            refetch={onChangePage as any}
          />
        </div>
      </div>
      <div style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
        >
          <thead>
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="pass-site">Site</th>
              <th className="pass-country">Country</th>
              <th className="pass-name">User's Name</th>
              <th className="pass-password">Password</th>
              <th className="pass-mfa">MFA Code</th>
              <th className="pass-act">Actions</th>
            </tr>
          </thead>
        </table>
        <table className="table-results mb2" style={{ minHeight: "auto" }}>
          <tbody>
            {passwordsInfo?.data.map(
              (password: IPassword, index: number) => (
                <React.Fragment key={index}>
                  <tr className="table-result-box height100px">
                    <td className="pass-site">
                      {/* <img
                        width="25px"
                        src={`${password.site}/favicon.ico`}
                        alt=""
                      /> */}
                      <a href={password.site} target='_blank' rel='noreferrer'>
                        <span className="underline">{password.label}</span>
                      </a>
                    </td>
                    <td className="pass-country">
                      <img
                        width="30px;"
                        alt=""
                        src={password.country.iconUrl}
                      />
                      <span className="ml1">{password.country.name}</span>
                    </td>
                    <td className="pass-name">{password.userName}</td>
                    <td className="pass-password">
                      <Password password={password.password} />
                    </td>
                    <td className="pass-mfa">{password.otpToken ? <OTPCode otpToken={password.otpToken} /> : 'MFA not set up'}</td>
                    <td className="pass-act justify-evenly">
                      <img
                        className="pointer"
                        src="/icons/grey-edit.svg"
                        alt=""
                        onClick={() => setSelectedPassword(password)}
                      />
                      <Delete
                        handleRemovePassword={onDelete}
                        passwordId={password._id as string}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              )
            )}
          </tbody>
        </table>
      </div>
      {selectedPassword && (
        <CreateEditPassword
          open={!!selectedPassword}
          selectedPassword={selectedPassword}
          setOpen={() => setSelectedPassword(null)}
          rqKeyPasswordsInfo={rqKeyPasswordsInfo}
        />
      )}
    </div>
    </div>
  );
};

const OTPCode: React.FC<{ otpToken: string; }> = ({ otpToken }) => {
  const { value, pct, isValid } = useOTP(otpToken);

  if (!isValid) {
    return <>Invalid OTP Token!</>;
  }

  return (
    <div className="in-row pointer" onClick={() => {
      navigator.clipboard.writeText(value!);
      toast.success('Password copied!');
    }}>
      <span className="mr1">
        {value}
      </span>

      <svg
        className="totp-token-remaining-seconds-pie"
        viewBox="0 0 0.318310 0.318310"
      >
        <circle
          style={{
            strokeDashoffset: -1 + pct!
          }}
          r="0.159155"
          cx="0.159155"
          cy="0.159155"
        />
      </svg>
    </div>
  );
};
