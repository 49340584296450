import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import { IZeoosDemoRequest } from "types";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";
import { interaction } from "../../../../actions/vendor";
import { dateFormat } from "./DemoRequestTable";

interface Props {
	modal: boolean;
	setModal: (value: React.SetStateAction<boolean>) => void;
	selectedDemoRequest: IZeoosDemoRequest;
	rqKeyDemoRequests: string;
}

const Interactions: React.FC<Props> = ({
	modal,
	setModal,
	selectedDemoRequest,
	rqKeyDemoRequests,
}) => {
	const [message, setMessage] = useState<string>("");

	const interactions = useMemo(() => {
		return selectedDemoRequest.interactions.reverse();
	}, [selectedDemoRequest]);

	const refetchOnboardingConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyDemoRequests);
			toast.success("The action is successfully completed");
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const editMutation = useMutation(
		(data: IZeoosDemoRequest) => interaction(data),
		refetchOnboardingConfig
	);

	const onInteractionHandler = useCallback(
		async (data: IZeoosDemoRequest) => {
			await editMutation.mutateAsync(data);
		},
		[editMutation]
	);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<>
				<div className="table-modal-form-cont p4">
					<div className="table-modal-title-box m0">
						<div className="mapping-modal-title-text">Interactions</div>
					</div>
					<form className="table-modal-form-cont">
						<div className="table-modal-border"></div>
						<div className="table-vendormm-title-cont">
							<div className="table-vendormm-title-box">
								<div className="tax-modal-cat width100">
									<div className="mapping-modal-text">New Message:</div>
									<textarea
										className="sel-pricing-modal-area"
										value={message}
										onChange={(e) => setMessage(e.target.value)}
									/>
								</div>
								<div className="width100 align-center in-row mt2 mb2 justify-end">
									<button
										className="table-modal-form-button"
										onClick={(e) => {
											e.preventDefault();

											onInteractionHandler({
												...selectedDemoRequest,
												interactions: [
													...selectedDemoRequest.interactions,
													{ message, messageDate: new Date(Date.now()) },
												],
											});
											setMessage("");
										}}
									>
										ADD
									</button>
								</div>
							</div>
						</div>
						<div className="table-modal-border"></div>
						{selectedDemoRequest.interactions.length ? (
							<>
								<div className="interactions-cont">
									<table className="table-results">
										<thead className="sticky">
											<tr className="table-results-title width100">
												<th className="width70 p2 justify-start">Message</th>
												<th className="width30 p2 justify-center">Date</th>
											</tr>
										</thead>
										<tbody>
											{interactions.map(
												(
													interaction: {
														message: string;
														messageDate: Date | string;
													},
													index: number
												) => (
													<React.Fragment key={index}>
														<tr
															style={{ marginTop: "-5px" }}
															className="overflow-wrap email-text border0 minheight70"
														>
															<td className="width70 p2 justify-start text-align-left">
																{interaction.message}
															</td>
															<td className="width30 p2 justify-center">
																{dateFormat(interaction.messageDate)}
															</td>
														</tr>
														<div className="border-custom"></div>
													</React.Fragment>
												)
											)}
										</tbody>
									</table>
								</div>
								<div className="table-modal-border"></div>
							</>
						) : (
							<></>
						)}
						<div className="in-row align-center justify-center width100">
							<button
								className="table-modal-form-button"
								onClick={onModalClose}
							>
								CLOSE
							</button>
						</div>
					</form>
				</div>
			</>
		</Modal>
	);
};

export default Interactions;
