import React, { useState } from "react";
import { withRouter } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { resetPassword } from "../../../actions/user";
import "./login.css";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Contato from "./Contato";
// import Language from './Language';

const Reset: React.FC<any> = () => {
	const { token } = useParams<{ token?: string; }>();
	const [newPass, setNewPass] = useState("");
	const [newPass2, setNewPass2] = useState("");
	const [textButton, setTextButton] = useState("Enviar");
	const [classButton, setClassButton] = useState("");
	let history = useHistory();

	const handleReset = async (e: any) => {
		setTextButton("loading...");
		setClassButton("wait");
		e.preventDefault();

		try {
			const res = await resetPassword({ token, newPass, newPass2 });
			toast.success(res.message, { autoClose: 5000 });
			setTimeout(() => {
				history.push("/login");
			}, 5000);
		} catch (error: any) {
			toast.error(error.response.data.message, { autoClose: 5000 });
		}

		setTextButton("REDEFINE PASSWORD");
		setClassButton("");
	};

	return (
		<div>
			<div className="s_main-login">
				<div className="login-box">
					<div className="in-column">
						<div className="titel_box">
							{/* <div className="login-title-text"></div> */}
							<img src="/icons/zeoos-logo.svg" width="160px;" alt="" />
						</div>
						<form className="login-form-block" onSubmit={handleReset}>
							<input type="hidden" name="token" value={token} />
							<div className="esqueci_text_box">
								<h3>CREATE PASSWORD</h3>
							</div>
							<div className="email_text_box">
								<label className="password-text-login">New password:</label>
							</div>
							<div className="login-input">
								<div>
									<img
										className="image-profile"
										src="/icons/lock-password.svg"
										alt="settings"
										width="30px"
										height="30px"
									/>
								</div>
								<input
									value={newPass}
									onChange={(e) => setNewPass(e.target.value)}
									type="password"
									className="password-field"
									name="Password"
									data-name="Password"
									id="Password"
									required
								></input>
							</div>
							<div className="email_text_box">
								<label className="password-text-login">Password:</label>
							</div>
							<div className="login-input">
								<div>
									<img
										className="image-profile"
										src="/icons/lock-password.svg"
										alt="settings"
										width="30px"
										height="30px"
									/>
								</div>
								<input
									value={newPass2}
									onChange={(e) => setNewPass2(e.target.value)}
									type="password"
									className="password-field"
									name="Password2"
									data-name="Password2"
									id="Password2"
									required
								></input>
							</div>
							<div className="button_box">
								<button
									onClick={handleReset}
									disabled={classButton === "wait" ? true : false}
									className={`log-in-login ${classButton}`}
								>
									{textButton}
								</button>
							</div>
							<div className="login-text-box">
								<div className="esqueci-text">
									<Link to="/login">Login</Link>
								</div>
								{/* <Language /> */}
							</div>
						</form>
					</div>
				</div>
				<Contato />
			</div>
		</div>
	);
};

export default withRouter(Reset);
