import React, { useMemo } from "react";
import useUser from "../../../hooks/useUser";
import Contrato from "./contrato/Contrato";
import Payment from "./payment/Payment";
import OnboardingSteps from "./OnboardingSteps";

export enum Status {
	na = "Na",
	awaiting = "Awaiting acceptance",
	accepted = "Accepted",
}

const Onboarding: React.FC = () => {

	return (
		<main style={{ marginLeft: "0" }} className="content">
			<Contrato/>
		</main>
	);
};

export default Onboarding;
