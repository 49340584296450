import React, { useState, memo, useCallback } from "react";
import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
import { Modal } from "../../ui/modal/Modal";
import Backdrop from "@mui/material/Backdrop";
import { StandingRule } from "types";
import { queryClient } from "../../App";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import * as PricingController from "../../../actions/pricingEvent";
import { IMarketplace, SchemeRootObject } from "../../../interfaces";
import { getSchemes } from "../../../actions/pim";
import Preloader from "../../ui/preloader/Preloader";

interface Props {
	rule: StandingRule;
	platform: IMarketplace;
	rqKeyRules: string;
}

const StandingEditModal: React.FC<Props> = ({ rule, platform, rqKeyRules }) => {
	const [isShownEdit, setIsShownEdit] = useState(false);
	const [modal, setModal] = useState<boolean>(false);
	const [open, setOpen] = useState(false);
	const [discountAmount, setDiscountAmount] = useState<string>(
		String(rule.discountAmount)
	);
	const [isActive, setIsActive] = useState<boolean>(rule.isActive);

	const { isLoading: loadSchemes, data: schemes } = useQuery("schemes", () =>
		getSchemes()
	);

	const refetchRulesConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyRules);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const updateMutation = useMutation(
		(data: StandingRule) => PricingController.updateStandingRule(data),
		refetchRulesConfig
	);

	const deleteMutation = useMutation(
		() => PricingController.deleteStandingRule(rule?._id as string),
		refetchRulesConfig
	);

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			const data = {
				...rule,
				discountAmount: Number(discountAmount),
				isActive,
			};
			await updateMutation.mutateAsync(data);

			setModal(false);
		},
		[updateMutation, discountAmount, isActive, rule]
	);

	const deleteRule = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			await deleteMutation.mutateAsync();
		},
		[deleteMutation]
	);

	if (loadSchemes) {
		return <Preloader />;
	}

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<>
			<button
				onClick={() => {
					setModal(true);
				}}
				onMouseEnter={() => setIsShownEdit(true)}
				onMouseLeave={() => setIsShownEdit(false)}
				className="pricing-text-grey stand-actions-cont"
			>
				{isShownEdit ? (
					<img src="/icons/standing-edit-active.svg" alt="" />
				) : (
					<img src="/icons/standing-edit.svg" alt="" />
				)}
				<span className="ml4">Edit</span>
			</button>
			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<>
						<form className="table-modal-form-cont p4">
							<div className="table-modal-title-box m0">
								<h3 className="mapping-modal-title-text">Edit Standing Rule</h3>
								<div
									onClick={() => setOpen(!open)}
									className="mapping-modal-title-text in-row align-center pointer"
								>
									<img src="/icons/trash-blue.svg" alt="" width="28px" />
									<div
										style={{ color: "#55718A" }}
										className="pricing-text ml1"
									>
										delete
									</div>
								</div>
							</div>
							<div className="table-modal-border"></div>
							<div className="rules-modal-cont">
								<div className="in-row align-center mb2">
									<span className="pricing-text text-start mwidth150">
										Platform Account:
									</span>
									<span className="pricing-text ml4">{platform.zeoosName}</span>
								</div>
								<div className="in-row align-center mb2">
									<span className="pricing-text text-start mwidth150">
										Product type:
									</span>
									<span className="pricing-text ml4">{rule.product}</span>
								</div>
								<div className="in-row align-center mb2">
									<span className="pricing-text text-start mwidth150">
										Category:
									</span>
									<span className="pricing-text ml4">
										{
											schemes?.find(
												(scheme: SchemeRootObject) =>
													scheme._id === rule.category
											)?.name
										}
									</span>
								</div>
								<div className="table-modal-border"></div>
								<div className="in-column align-start justify-start width100">
									<span className="pricing-text">Discount applied:</span>
									<div className="width100 in-row justify-between align-center">
										<div className="mwidth120 in-row align-center">
											<input
												className="mapping-modal-table-input mt2"
												value={discountAmount}
												onChange={(e) => setDiscountAmount(e.target.value)}
											/>
											<span>%</span>
										</div>
										<div className="in-row align-center mr4">
											<AntSwitch
												checked={isActive}
												onChange={() => setIsActive(!isActive)}
												name="checkedC"
												required
											/>
											<span className="email-textm ml2">
												{isActive ? "Active" : "Inactive"}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="table-modal-border"></div>
							<div className="table-modal-form-button-box width100">
								<button
									className="table-modal-form-button-cancel"
									onClick={onModalClose}
								>
									Cancel
								</button>
								<button className="table-modal-form-button" onClick={onSubmit}>
									Save
								</button>
							</div>
						</form>
					</>
				</Modal>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: 1201 }}
				open={open}
				className="relative"
			>
				<div className="filter-bg" onClick={() => setOpen(false)} />
				<div className="rules-delete-bacldrop p4">
					<div style={{ padding: "40px" }} className="updateForm__container">
						<div className="vendor-mapping-modal-text">
							{`Are you sure you want to delete`} <br />{" "}
							<strong>{rule._id}</strong>?
						</div>
						<div className="table-modal-border"></div>
						<div
							style={{ zIndex: "1202" }}
							className="table-modal-form-button-box width100 m0"
						>
							<button
								className="table-modal-form-button-cancel"
								onClick={() => setOpen(false)}
							>
								Cancel
							</button>
							<button
								className="table-modal-form-button pointer"
								onClick={deleteRule}
							>
								DELETE
							</button>
						</div>
					</div>
				</div>
			</Backdrop>
		</>
	);
};

export default memo(StandingEditModal);
