import React, { useDeferredValue, useEffect, useRef, useState } from "react";
import "./ticket.css";
import TicketsNav from "../../ui/tickets-nav/TicketsNav";
import Paginator from "../../ui/pagination";
import { Modal } from "../../ui/modal/Modal";
import { Link } from "react-router-dom";
import useTickets from "../../../hooks/useTickets";
import useVendors from "../../../hooks/useVendors";
import { useQuery } from "react-query";
import { getPaginatedUsers } from "../../../actions/user";
import useUser from "../../../hooks/useUser";
import { Role } from "../../../interfaces";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { DateTime } from "luxon";
import { createTicket } from "../../../actions/ticket";
import { toast } from "react-toastify";
import { TicketStatus } from "types";
import { allReadTicketNotifications } from "../../../actions/notifications";
import { queryClient } from "../../App";

export default function Tickets() {
	const [config, setConfig] = useState({
		seller: "",
		perPage: 50,
		page: 1,
		search: "",
		status: undefined as undefined | string,
	});
	const debouncedConfig = useDeferredValue(config);
	const tickets = useTickets(debouncedConfig);

	const [open, setOpen] = useState<boolean>(false);

	useEffect(() => {
		async function initializeFn() {
			await allReadTicketNotifications();
			queryClient.invalidateQueries("/ticket-notifications");
		}
		initializeFn();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="main-body">
				<div className="main-container">
					<TicketsNav />
					<div className="table-main-cont">
						<div className="table-title-cont p0 mt4 mb4">
							<div className="mplc-filter-cont width100">
								<div className="in-row align-center justify-start ml2">
									<button
										onClick={() =>
											setConfig((c) => ({ ...c, status: undefined }))
										}
										className={`ticket-open-close-btn${
											config.status === undefined ? "-active" : ""
										}`}
									>
										All
									</button>
									<button
										onClick={() =>
											setConfig((c) => ({ ...c, status: TicketStatus.OPEN }))
										}
										className={`ticket-open-close-btn${
											config.status === TicketStatus.OPEN ? "-active" : ""
										}`}
									>
										Open
									</button>
									<button
										onClick={() =>
											setConfig((c) => ({ ...c, status: TicketStatus.CLOSED }))
										}
										className={`ticket-open-close-btn${
											config.status === TicketStatus.CLOSED ? "-active" : ""
										}`}
									>
										Closed
									</button>
								</div>
								<div className="in-row align-center">
									<button
										onClick={() => setOpen(true)}
										className="tax-title-button mr4"
									>
										<span
											style={{ textTransform: "initial", paddingLeft: "0px" }}
											className="pim-avaliacoes-title-button-text align-center"
										>
											<img className="mr2" src="/icons/pim-plus.svg" alt="" />
											New ticket
										</span>
									</button>

									{/* <button

                                        className="main-select-bi no-shadow ml2"
                                    >
                                        <img src="/icons/bi-filter.svg" alt="" />
                                        FILTER <span className="orange-text bold">(2)</span>
                                    </button> */}
								</div>
							</div>
						</div>

						<div className="table-main-title-cont">
							<div className="table-main-title-search-value-cont">
								<div className="search-box">
									<div className="search_form">
										<div className="table-search-box">
											<form
												className="table-search-form"
												onSubmit={(e) => e.preventDefault()}
											>
												<input
													className="table-search-input"
													placeholder="Search for ticket number or subject"
												/>
												<img
													className="table-search-image"
													src="/icons/search.svg"
													width="25px;"
													alt=""
												/>
											</form>
										</div>
									</div>
								</div>
								<div className="table-main-title-search-value-cont">
									<div className="table-main-title-text">
										{tickets.data?.total} tickets
									</div>
								</div>
							</div>
							<div className="marketplace-pagination">
								<Paginator
									data={tickets.isLoading ? {} : tickets.data}
									// @ts-ignore
									refetch={(config: any) => {
										setConfig((c) => {
											return {
												...c,
												...config,
											};
										});
									}}
								/>
							</div>
						</div>
						<div
							style={{ height: "max-content", marginBottom: "20px" }}
							className="table-overflow"
						>
							<table
								style={{
									zIndex: 101,

									width: "100%",
								}}
							>
								<tr
									style={{ marginTop: "-5px", width: "100%" }}
									className="table-results-title"
								>
									<th className="tickets-table-reason">
										Ticket number - Reason
									</th>

									<RoleRequired role={Role.admin}>
										<th className="tickets-table-seller">Seller</th>
									</RoleRequired>

									<th className="tickets-table-column">Opened on</th>
									<th className="tickets-table-column">Last update</th>
									<th className="tickets-table-column justify-center">
										Status
									</th>
									<th className="tickets-table-column justify-center">Edit</th>
								</tr>
							</table>
							<table style={{ marginTop: "-5px" }} className="table-results">
								<tbody>
									{tickets.data?.data.map((ticket) => (
										<Link
											key={ticket._id}
											className="pointer"
											to={`/ticket/${ticket._id}`}
										>
											<tr
												style={{ height: "auto" }}
												className="table-result-box in-column border0 m0 email-text width100"
											>
												<td className="width100 in-row align-center pt2 justify-start">
													<td className="tickets-table-reason bold">
														{ticket._id.slice(0, 12)} - {ticket.reason}
													</td>

													<RoleRequired role={Role.admin}>
														<td className="tickets-table-seller">
															{/* @ts-ignore */}
															{ticket.seller?.name}
														</td>
													</RoleRequired>

													<td className="tickets-table-column">
														{DateTime.fromISO(ticket.created).toFormat(
															"LLL dd, yyyy"
														)}
													</td>
													<td className="tickets-table-column">
														{DateTime.fromISO(ticket.updated).toFormat(
															"LLL dd, yyyy"
														)}
													</td>
													<td className="tickets-table-column justify-center">
														{/* <div className="ticket-status-open">open</div> */}
														<div
															className={`ticket-status-${ticket.status.toLowerCase()}`}
														>
															{ticket.status}
														</div>
													</td>
													<td className="tickets-table-column justify-center bold">
														Edit ticket
													</td>
												</td>
												<td className="width100 in-row align-startemail-text justify-start pb2 pt2 pl4">
													<img
														className="user-avatar"
														/* @ts-ignore */
														src={ticket.initiator.profileImage}
														alt=""
													/>
													<span>
														{ticket.description}
														<br />
														<b>See more</b>
													</span>
												</td>
											</tr>
										</Link>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<CreateTicketModal
				setOpen={setOpen}
				open={open}
				onSuccess={tickets.invalidate}
			/>
		</>
	);
}

const DEFAULT_DATA = {
	file: undefined as undefined | File,
	description: "",
	users: [] as string[],
	seller: "",
	reason: "",
};

export const CreateTicketModal: React.FC<{
	open: boolean;
	setOpen: (s: boolean) => void;
	onSuccess: () => void;
}> = ({ open, setOpen, onSuccess }) => {
	const { user } = useUser();
	const sellers = useVendors();
	const [data, setData] = useState(DEFAULT_DATA);
	const ref = useRef<HTMLInputElement>(null);

	const users = useQuery(
		`users-${data.seller}`,
		() =>
			getPaginatedUsers({
				page: 1,
				perPage: 10,
				vendor: data.seller,
			}),
		{ enabled: user.role === Role.admin }
	);

	if (!open) {
		return <></>;
	}

	return (
		<Modal onModalClose={() => {}} isOpened={open}>
			<form
				onSubmit={async (e) => {
					e.preventDefault();

					try {
						// @ts-ignore
						await createTicket(data);
						onSuccess();

						setOpen(false);
						setData(DEFAULT_DATA);
					} catch (error) {
						console.error(error);
						toast.error("Something went wrong...");
					}
				}}
				style={{ minWidth: "640px", padding: "30px" }}
				className="table-modal-cont"
			>
				<div className="table-modal-title-box mb2">
					<div className="table-modal-title-pricing">
						<div style={{ fontSize: "21px" }}>New ticket</div>
					</div>
				</div>
				<div className="table-modal-border"></div>
				<div className="width100 in-column align-start justify-between">
					<RoleRequired role={Role.admin}>
						<div className="width100 justify-between align-center">
							<div className="tax-modal-cat width40">
								<div className="mapping-modal-text bold">Seller:</div>
								<select
									id="compa-channels"
									name="country"
									required
									className="tax-modal-input"
									value={data.seller}
									onChange={(e) =>
										setData((c) => ({ ...c, seller: e.target.value }))
									}
								>
									<option className="compa-option" value="">
										All Selers
									</option>

									{sellers.data?.map((seller: any) => (
										<option
											key={seller._id}
											className="compa-option"
											value={seller._id}
										>
											{seller.name}
										</option>
									))}
								</select>
							</div>

							<div className="tax-modal-cat width40">
								<div className="mapping-modal-text bold">Notified user:</div>
								<select
									id="compa-channels"
									name="country"
									className="tax-modal-input"
									value={data.users[0]}
									onChange={(e) =>
										setData((c) => ({ ...c, users: [e.target.value] }))
									}
								>
									<option value="">All users</option>

									{users.data?.data.map((user: any) => (
										<option
											key={user._id}
											className="compa-option"
											value={user._id}
										>
											{user.username}
										</option>
									))}
								</select>
							</div>
						</div>
					</RoleRequired>

					<div className="tax-modal-cat mt2 width40">
						<div className="mapping-modal-text bold">Reason:</div>
						<select
							id="compa-channels"
							name="country"
							required
							className="tax-modal-input"
							value={data.reason}
							onChange={(e) =>
								setData((d) => ({ ...d, reason: e.target.value }))
							}
						>
							<option value="">Select reason</option>

							{["Pricing issue", "Order issue", "Billing issue", "Other"].map(
								(x) => (
									<option key={x} value={x}>
										{x}
									</option>
								)
							)}
						</select>
					</div>

					<div className="tax-modal-cat mt4 width100">
						<div className="mapping-modal-text bold">
							Write details about what you need:
						</div>
						<textarea
							required
							className="ticket-modal-textarea"
							value={data.description}
							onChange={(e) =>
								setData((d) => ({ ...d, description: e.target.value }))
							}
						/>
					</div>

					<div className="in-row items-center align-center">
						<button
							type="button"
							className="ticket-modal-add-btn"
							onClick={() => ref.current?.click()}
						>
							Add a file
						</button>

						{data.file && (
							<span className="ml1 mapping-modal-text bold">
								{data.file.name}
							</span>
						)}
					</div>

					<input
						onChange={(e) =>
							setData((d) => ({ ...d, file: e.target.files?.[0] }))
						}
						ref={ref}
						style={{ display: "none" }}
						type="file"
					/>
				</div>

				<div className="table-modal-border" />

				<div className="table-modal-form-button-box width100 m0">
					<button
						style={{
							textDecoration: "none",
							color: "#55718A",
							textTransform: "initial",
						}}
						className="table-modal-form-button-cancel bold"
						onClick={() => setOpen(false)}
					>
						Cancel
					</button>

					<button type={"submit"} className="table-modal-form-button pointer">
						Create ticket
					</button>
				</div>
			</form>
		</Modal>
	);
};
