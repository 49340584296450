import { Modal } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { consultsEANs } from "../../../actions/v2/product";

const ConsultsEans: React.FC<{
    modal: boolean;
    setModal: (s: boolean) => void;
}> = ({ modal, setModal }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);
    const [files, setFiles] = useState<File[]>([]);

    const reset = useCallback(() => {
        setFiles([]);
        setData(null);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: [".csv"],
        onDropRejected: () => {
            toast.error('File type rejected. Only ".csv" files are allowed.');
        },
        onDropAccepted: (files) => {
            setFiles(files);
        },
    });

    const onSubmit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault();
            setLoading(true);

            try {
                await consultsEANs(files[0]);
                setFiles([]);
            } catch (error) {
                console.error(error);
                toast.error("Something went wrong. Please, verify your file's format");
            }

            setLoading(false);
        },
        [files]
    );

    useEffect(reset, [modal]);

    if (!modal) {
        return <></>;
    }

    return (
        <Modal
            onModalClose={(e: React.MouseEvent) => {
                e.stopPropagation();
                setModal(false);
            }}
            isOpened={modal}
        >
            <form className="table-modal-form-cont p4" onSubmit={onSubmit}>
                <div className="table-modal-title-box m0">
                    <div className="import-title">Consult EANs</div>
                </div>
                <div className="table-modal-border" />

                <span className="mt2 in-row width100 align-center">
                    <b className="mr1">Upload the file</b>
                    (Allowed file format .csv)
                </span>

                <div
                    className="width100 pointer import-reviews-dropzone justify-start"
                    style={{
                        padding: "5px 10px",
                        width: "400px",
                        height: "150px",
                        background: "",
                        borderWidth: "1px",
                        borderColor: "#c2c2c2",
                        borderStyle: isDragActive ? "solid" : "dashed",
                    }}
                    {...getRootProps()}
                >
                    {/* @ts-ignore */}
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <h4>Drop the file here</h4>
                    ) : (
                        <span style={{ textAlign: "start" }}>
                            {!!files.length &&
                                `The file "${files[0].name}" has been selected.`}
                        </span>
                    )}
                </div>

                <div
                    className="bold mt2 in-row width100 align-center"
                    style={{ color: "#55718a" }}
                >
                    <div style={{ fontSize: "12px" }}>
                        File in CSV with a column "ean"
                    </div>
                </div>

                <div className="table-modal-border" />

                <div
                    style={{ marginBottom: "0" }}
                    className="table-modal-form-button-box width100"
                >
                    <button
                        className={`sel-modal-form-button-cancel ${data ? "width100 justify-center" : ""
                            }}`}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            setModal(false);
                        }}
                    >
                        Cancel
                    </button>

                    {!data && (
                        <button
                            className="table-modal-form-button initial"
                            type="submit"
                            disabled={loading}
                        >
                            Consult
                        </button>
                    )}
                </div>
            </form>
        </Modal>
    );
};

export default ConsultsEans;
