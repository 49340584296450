import React, { memo, useState } from "react";
import "./logistic.css";
import Partners from "./partners/Partners";
import Warehouses from "./warehouses/Warehouses";
import CreateEditPartner from "./partners/CreateEditPartner";
import CreateEditWarehouse from "./warehouses/CreateEditWarehouse";
import SingleWarehouse from "./warehouses/SingleWarehouse";
import ShippingCost from "./shipping-cost/ShippingCost";

export const logisticSections = [
  "Warehouses",
  "Logistic Partners",
  "Shipping Cost",
];

const Logistic: React.FC = () => {
  const [section, setSection] = useState<string>("Warehouses");
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [warehouseId, setWarehouseId] = useState<string>("");

  const renderTable = (section: string) => {
    switch (section) {
      case "Warehouses":
        return <Warehouses setWarehouseId={setWarehouseId} />;
      case "Logistic Partners":
        return <Partners />;
      case "Shipping Cost":
        return <ShippingCost />;
      default:
        return;
    }
  };

  return (
    <div className="main-body">
      <div className="main-container">
        <>
          {warehouseId !== "" ? (
            <SingleWarehouse
              warehouseId={warehouseId}
              setWarehouseId={setWarehouseId}
            />
          ) : (
            <>
              <div className="table-main-cont p0">
                <div className="mapping-title-border"></div>
                <div
                  style={{ background: "transparent" }}
                  className="table-title-cont"
                >
                  <div
                    style={{ background: "transparent", boxShadow: "none" }}
                    className="mplc-filter-cont width100"
                  >
                    <div
                      style={{ marginLeft: "0" }}
                      className="mplc-filter bold ft2"
                    >
                      {logisticSections.map((item: string, index: number) => (
                        <button
                          className={`gestao-select-button ${
                            section === item ? "gestao-active" : ""
                          }`}
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                            setSection(item);
                          }}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                    {section !== "Shipping Cost" && (
                      <div
                        onClick={() => setCreateModal(true)}
                        className="tax-title-button pointer mr2"
                      >
                        <img src="/icons/pim-plus.svg" alt="" />
                        <div className="pim-avaliacoes-title-button-text">
                          ADD{" "}
                          {section === "Warehouses"
                            ? "Warehouse"
                            : "Logistic Partner"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {renderTable(section)}
              </div>
            </>
          )}
        </>
      </div>
      {createModal && (
        <>
          {section === "Warehouses" ? (
            <CreateEditWarehouse
              modal={createModal}
              setModal={setCreateModal}
            />
          ) : (
            <CreateEditPartner modal={createModal} setModal={setCreateModal} />
          )}
        </>
      )}
    </div>
  );
};

export default memo(Logistic);
