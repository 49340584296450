import React, { memo, useCallback, useMemo, useState } from "react";
import Paginator from "../../../ui/pagination";
import type { ILogisticPartner } from "../../../../../../../packages/types/index";
import { IPaginatedRequest } from "../../../../interfaces";
import { useDebouncedValue } from "@mantine/hooks";
import { useQuery } from "react-query";
import queryString from "query-string";
import { getPaginatedPartners } from "../../../../actions/logistic";
import CreateEditPartner from "./CreateEditPartner";
import Preloader from "../../../ui/preloader/Preloader";
import StatusPartner from "./StatusPartner";
import CardInfoPartner from "./CardInfoPartner";
import TooltipIcon from "../TooltipIcon";

const Partners: React.FC = () => {
	const [editModal, setEditModal] = useState<boolean>(false);
	const [cardModal, setCardModal] = useState(false);
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
	const [selectedPartner, setSelectedPartner] = useState<ILogisticPartner>();

	const rqKeyPartners = useMemo(() => {
		return `/logistic-partners?${queryString.stringify(debouncedConfig)}`;
	}, [debouncedConfig]);

	const { isLoading, data: partners } = useQuery(rqKeyPartners, () =>
		getPaginatedPartners(debouncedConfig)
	);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	return (
		<>
			{isLoading ? (
				<Preloader />
			) : (
				<div className="table-main-cont">
					<div style={{padding: "0 40px"}} className="width100">
					<div className="table-main-title-cont">
						<div className="table-main-title-search-value-cont">
							<div className="search-box">
								<div className="search_form">
									<div className="table-search-box">
										<form
											className="table-search-form"
											onSubmit={(e) => e.preventDefault()}
										>
											<input
												className="table-search-input"
												placeholder="Search partner"
												onChange={(e) =>
													setCurrentConfig((conf: IPaginatedRequest) => ({
														...conf,
														search: e.target.value,
													}))
												}
												value={currentConfig.search}
											/>
											<img
												className="table-search-image"
												src="/icons/search.svg"
												width="25px;"
												alt=""
											/>
										</form>
									</div>
								</div>
								<div className="table-main-title-text pl2">
									<span className="table-main-title-text">
										{partners?.total}{" "}
										{partners?.total > 1 ? "partners" : "partner"}
									</span>
								</div>
							</div>
						</div>
						<div className="marketplace-pagination">
							<Paginator data={partners} refetch={onChangePage as any} />
						</div>
					</div>
					<div style={{ height: "max-content" }} className="table-overflow">
						<table
							style={{
								position: "sticky",
								zIndex: 101,
								top: "2px",
								width: "100%",
							}}
						>
							<thead>
								<tr
									style={{ marginTop: "-5px", width: "100%" }}
									className="table-results-title"
								>
									<th className="partner-name">Logistic Partners</th>
									<th className="partner-warehouses">Warehouses</th>
									<th className="partner-status">Status</th>
									<th className="partner-contact">Contact</th>
								</tr>
							</thead>
						</table>
						<table
							className="table-results mb2 batch-text"
							style={{ minHeight: "auto" }}
						>
							<tbody>
								{partners?.data?.map((partner: ILogisticPartner) => (
									<tr
										className="table-result-box height100px"
										key={partner._id}
									>
										<td
											className="partner-name pointer"
											onClick={(e) => {
												e.preventDefault();

												setSelectedPartner(partner);
												setEditModal(true);
											}}
										>
											<img
												className="mr2"
												width="70px"
												// height="30px"
												src={partner.logo || ""}
												alt=""
											/>
											<span>{partner.partnerName}</span>
										</td>
										<td className="partner-warehouses">
											<span>{partner?.warehouses || "-"}</span>
										</td>
										<StatusPartner
											partner={partner}
											rqKeyPartners={rqKeyPartners}
										/>
										<td className="partner-contact">
											<TooltipIcon
												title={"See Contact Info"}
												handleClick={(e: React.MouseEvent) => {
													e.preventDefault();

													setCardModal(true);
													setSelectedPartner(partner);
												}}
												icon={"/icons/contact-icon.svg"}
												iconHover={"/icons/contact-icon-hover.svg"}
												size={{ w: "60px", h: "40px" }}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						{editModal && (
							<CreateEditPartner
								partner={selectedPartner}
								rqKeyPartners={rqKeyPartners}
								modal={editModal}
								setModal={setEditModal}
							/>
						)}
						{cardModal && (
							<CardInfoPartner
								partner={selectedPartner || partners?.data[0]}
								rqKeyPartners={rqKeyPartners}
								modal={cardModal}
								setModal={setCardModal}
							/>
						)}
					</div>
					</div>
				</div>
			)}
		</>
	);
};

export default memo(Partners);
