import axios from "axios";
import { getHeaders } from "./user";

interface IBody {
	text: string;
	langCode: string;
	sourceLang: string;
}

export async function translate(body: IBody) {
	const res = await axios.post(
		"/translateText",
		{ text: body.text, code: body.langCode, sourceLang: body.sourceLang },
		getHeaders()
	);
	return res.data.translatedText;
}

export async function getOfferCode(body: any) {
	const res = await axios.post("/offerCode", body, getHeaders());
	return res.data;
}
