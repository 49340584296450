import React from "react";
import { NavLink } from "react-router-dom";

export default function TicketsNav() {
	return (
		<>
			<div className="table-value-search-cont mt2">
				<div className="table-title-nav-cont width100">
					<NavLink
						to="/tickets"
						className="table-tab-sync"
						activeClassName="table-tab-sync-active"
					>
						Tickets
					</NavLink>
				</div>
			</div>
			<div className="mapping-title-border"></div>
		</>
	);
}
