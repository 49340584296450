import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import * as _ from "lodash";
import {
	IMarketplace,
	IPaginatedRequest,
	IVendor,
} from "../../../../interfaces";
import { getPlatformPriceRange } from "../../../../actions/marketplace";
import { useQuery } from "react-query";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import {
	getDistinctBrands,
	getEssentialProductSchemeData,
} from "../../../../actions/pim";
import { useMemo } from "react";
import { MenuItem, Tab, Tabs, TextField } from "@mui/material";
import { StockCondition, STOCK_CONDITIONS } from "types";
import { getSchemeById } from "../../../../actions/scheme";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import { Role } from "../../../../interfaces";
import { isEqual } from "lodash";

export const PriceSlider = styled(Slider)({
	color: "#ef5350",
	"& .MuiSlider-thumb": {
		height: 18,
		width: 18,
		backgroundColor: "#fff",
		border: "2px solid currentColor",
	},
	"& .MuiSlider-valueLabel": {
		fontSize: 12,
		fontWeight: "bold",
		top: 3,
		backgroundColor: "unset",
		color: "#ef5350",
	},
});

interface Props {
	marketplaces: IMarketplace[];
	vendors: IVendor[];
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
	setFilteredMarketplaces?: any;
	filterFrom?: string;
}

export const GestaoFilter: React.FC<Props> = ({
	marketplaces,
	vendors,
	currentConfig,
	setCurrentConfig,
	setFilteredMarketplaces,
	filterFrom,
}) => {
	const [open, setOpen] = useState(false);
	const [counter, setCounter] = useState(0);
	const [platformName, setPlatformName] = useState("All Marketplaces");
	const [country, setCountry] = useState(["All Countries"]);
	const [vendorName, setVendorName] = useState("All Sellers");
	const [brand, setBrand] = useState(["All Brands"]);
	const [stockCondition, setStockCondition] = useState<StockCondition>("ALL");

	const brands = useQuery(`pimBrands`, () => getDistinctBrands(), {
		refetchOnWindowFocus: false,
	});

	const schemes = useQuery(`schemesEssential`, getEssentialProductSchemeData, {
		refetchOnWindowFocus: false,
	});

	const countriesArray = (array: any) => {
		return [
			"All Countries",
			..._.uniq(_.map(array, (item: any) => item.country)),
		];
	};

	const platformNamesArray = (array: any) => {
		return [
			"All Marketplaces",
			..._.uniq(_.map(array, (item: any) => item.zeoosName)),
		];
	};

	const brandOptions = useMemo(() => {
		return ["All Brands", ...(brands.data || [])];
	}, [brands]);

	const clearFilterHandle = (e: React.MouseEvent) => {
		e.preventDefault();
		setCountry(["All Countries"]);
		setPlatformName("All Marketplaces");
		setBrand(["All Brands"]);
		setVendorName("All Sellers");
		setStockCondition(STOCK_CONDITIONS[0]);
		setCounter(0);
	};

	const filterCounter = (
		value: string | string[],
		flag: string | string[],
		defaultValue: string | string[]
	) => {
		if (!isEqual(value, defaultValue) && !isEqual(defaultValue, flag)) {
			setCounter(counter);
		} else if (!isEqual(value, defaultValue)) {
			setCounter(counter + 1);
		} else {
			setCounter(counter - 1);
		}
	};

	const vendorOptions = useMemo(() => [
		{
			label: "All Sellers",
			value: "All Sellers",
		},
		...vendors.map((x) => ({
			label: x.name,
			value: x.id as any,
		}))
	], [vendors]);

	const applyFilter = (e: React.MouseEvent) => {
		e.preventDefault();

		const filteredResults =
			platformName === "All Marketplaces"
				? marketplaces
				: marketplaces.filter((x: any) => x.zeoosName === platformName);

		if (setFilteredMarketplaces) {
			setFilteredMarketplaces(filteredResults);
		}

		setCurrentConfig({
			...currentConfig,
			platformName,
			page: 1,
			country,
			vendorName,
			brand,
			stockCondition,
		});

		setOpen(false);
	};

	return (
		<>
			<button
				onClick={() => setOpen(!open)}
				className="main-select-bi no-shadow ml2"
			>
				<img src="/icons/bi-filter.svg" alt="" />
				FILTER <span className="orange-text bold">({counter})</span>
			</button>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={open}
				className="relative"
			>
				<div className="filter-bg" onClick={() => setOpen(false)} />
				<div className="filter-cont-position">
					<div className="filter-cont">
						<div className="filter-content justify-start">
							<div className="filter-header pt4">
								<div className="filter-header-title">
									Filter <span className="orange-text">({counter})</span>
								</div>
								<div
									className="filter-header-reset"
									onClick={clearFilterHandle}
								>
									Clear filters
								</div>
							</div>
							<div className="filter-content-cont mt2">
								<MultiSelect
									resetFilter={(newValue, oldValue) =>
										filterCounter(newValue, oldValue, ["All Countries"])
									}
									value={country}
									setValue={(value: any) =>
										setCountry(
											value[value.length - 1] === "All Countries"
												? ["All Countries"]
												: value.filter((x: string) => x !== "All Countries")
										)
									}
									options={stringsToOptions(countriesArray(marketplaces))}
								/>

								<MultiSelect
									multiple={false}
									resetFilter={(newValue, oldValue) =>
										filterCounter(newValue, oldValue, "All Marketplaces")
									}
									value={platformName}
									setValue={setPlatformName}
									options={stringsToOptions(platformNamesArray(marketplaces))}
								/>

								<MultiSelect
									resetFilter={(newValue, oldValue) =>
										filterCounter(newValue, oldValue, ["All Brands"])
									}
									value={brand}
									setValue={(value: any) =>
										setBrand(
											value[value.length - 1] === "All Brands"
												? ["All Brands"]
												: value.filter((x: string) => x !== "All Brands")
										)
									}
									options={stringsToOptions(brandOptions)}
								/>

								{filterFrom === "inventory" && (
									<RoleRequired role={Role.admin}>
										<MultiSelect
											resetFilter={(newValue, oldValue) =>
												filterCounter(newValue, oldValue, "All Sellers")
											}
											value={vendorName}
											setValue={setVendorName}
											options={vendorOptions}
											multiple={false}
										/>
									</RoleRequired>
								)}

								{/* <select className="filter-select">
									<option className="">All Types</option>
								</select>
								<select className="filter-select">
									<option className="">All Colors</option>
								</select>
								<select className="filter-select">
									<option className="">All Years</option>
								</select>
								<select className="filter-select">
									<option className="">All Years</option>
								</select>
								<select className="filter-select">
									<option className="">All Years</option>
								</select> */}

								{/* {filterFrom === "inventory" && (
									<div className="sale-cont">
										<div
											className="sale-price-cont"
											onClick={() => {
												if (
													salePriceFlag &&
													!_.isEqual(sliderValue, [0, 100])
												) {
													setSliderValue([0, 100]);
												}

												!salePriceFlag
													? setCounter(counter + 1)
													: setCounter(counter - 1);
												setSalePriceFlag(!salePriceFlag);
											}}
										>
											<Checkbox
												sx={{
													color: "#55718A",
													paddingLeft: "0",
													"&.Mui-checked": {
														color: "#55718A",
														paddingLeft: "0",
													},
												}}
												checked={salePriceFlag}
											/>
											<div className="sale-text">Sale Price</div>
										</div>
										<div className="sale-box">
											{platformPriceRange.isLoading && <CircularPreloader />}

											<PriceSlider
												getAriaLabel={() => "Sale Price"}
												value={sliderValue}
												onChange={(event: Event, newValue: number | number[]) =>
													setSliderValue(newValue as number[])
												}
												valueLabelDisplay={salePriceFlag ? "on" : "off"}
												valueLabelFormat={sliderValueText}
												max={platformPriceRange.data?.max || 100}
												min={platformPriceRange.data?.min || 0}
												disabled={!salePriceFlag}
											/>
										</div>
									</div>
								)} */}

								<div className="in-row align-center justify-start width100 mb3">
									<p>Select stock filters</p>
								</div>

								<div className="width100">
									<Tabs
										value={STOCK_CONDITIONS.findIndex(
											(s) => s === stockCondition
										)}
										onChange={(
											event: React.SyntheticEvent,
											newValue: number
										) => {
											setStockCondition(STOCK_CONDITIONS[newValue]);
											filterCounter(
												STOCK_CONDITIONS[newValue],
												stockCondition,
												STOCK_CONDITIONS[0]
											);
										}}
										aria-label="stock items"
										sx={{
											boxShadow: "none",
											"& .MuiTabs-flexContainer": {
												width: "100%",
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
												alignItems: "center",
											},
											"MuiTab-root": {
												boxShadow: "none",
											},
											"& button:hover": {
												color: "#F1692E",
											},
											"& button.Mui-selected": {
												color: "#F1692E",
											},
										}}
									>
										{STOCK_CONDITIONS.map((c) => (
											<Tab
												label={c}
												key={c}
												sx={{
													textTransform: "none",
													boxShadow: "none",
													border: "1px solid",
													borderRadius: "10px",
													minWidth: "50px",
												}}
											/>
										))}
									</Tabs>
								</div>
							</div>
						</div>
						<div className="filter-border mt4 mb2"></div>
						<div className="filter-buttons width100 m0">
							<button
								className="filter-apply-btn width80"
								onClick={applyFilter}
							>
								APPLY
							</button>
							<button
								className="filter-cancel-btn mb2"
								onClick={() => setOpen(false)}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</Backdrop>
		</>
	);
};

export type Option = { label: string; value: string };

export function stringsToOptions(strings: string[]): Option[] {
	return strings.map((s) => ({ label: s, value: s }));
}

type SharedMultiSelectProps = {
	multiple?: boolean;
	options: Option[];
	resetFilter?: (newValue: any, oldValue: any) => void;
	helperText?: string;
};

type _MultiSelectProps = {
	value: string[];
	setValue: React.Dispatch<React.SetStateAction<string[]>>;
} & SharedMultiSelectProps;

type _SingleSelectProps = {
	value: string;
	setValue: React.Dispatch<React.SetStateAction<string>>;
} & SharedMultiSelectProps;

type MultiSelectProps = _SingleSelectProps | _MultiSelectProps;

export const MultiSelect: React.FC<MultiSelectProps> = ({
	value,
	setValue,
	options,
	resetFilter,
	helperText,
	multiple = true,
}) => {
	return (
		<TextField
			label={helperText}
			select
			className="filter-select"
			sx={{ mb: 1 }}
			SelectProps={{
				multiple,
				value,
				onChange: (e: any) => {
					e.preventDefault();
					resetFilter?.(e.target.value, value);
					setValue(e.target.value);
				},
			}}
		>
			{options.map((option, index) => (
				<MenuItem value={option.value} className="acompan-option" key={index}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};
