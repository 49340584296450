import React, { memo, useCallback, useMemo, useState } from "react";
import { withRouter, RouteComponentProps, NavLink } from "react-router-dom";
import Preloader from "../../../ui/preloader/Preloader";
import * as PricingController from "../../../../actions/pricingEvent";
import { useQuery, useMutation } from "react-query";
import { PricingEvent, EventStatus, EventType } from "types";
import {
	getPaginatedInventoryProducts,
	getSanitizedMarketplaces,
} from "../../../../actions/marketplace";
import { getSchemes } from "../../../../actions/pim";
import queryString from "query-string";
import {
	IMarketplace,
	IPaginatedRequest,
	IProductGestao,
	SchemeRootObject,
} from "../../../../interfaces";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import { DateTime } from "luxon";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";
import Paginator from "../../../ui/pagination";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import { PricingEventUtils } from "shared";
import useSanitizedMarketplaces from "../../../../hooks/useSanitizedMarketplaces";

type TParams = { id: string };

const SinglePricingEvent: React.FC<RouteComponentProps<TParams>> = (props) => {
	const { id } = props.match.params;

	const { isLoading: loadEvent, data: event } = useQuery(
		`/pricing-event/${id}`,
		() => PricingController.getEvent(id)
	);

	const { isLoading: loadSchemes, data: schemes } = useQuery("schemes", () =>
		getSchemes()
	);

	const { isLoading: loadPlatforms, data: platforms } =
		useSanitizedMarketplaces();

	const refetchEventsConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(`/pricing-event/${id}`);
			toast.success(`The event was successfully "aborted"!`);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const editMutation = useMutation(
		(data: PricingEvent) => PricingController.updateEvent(data),
		refetchEventsConfig
	);

	const abortedHandle = useCallback(
		async (e: React.MouseEvent) => {
			e.preventDefault();
			const data = {
				...event,
				status: EventStatus.ABORTED,
				eventPeriod: { ...event.eventPeriod, aborted: DateTime.now() },
			};
			await editMutation.mutateAsync(data);
		},
		[editMutation, event]
	);

	if (loadEvent || loadSchemes || loadPlatforms) {
		return <Preloader />;
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<div
					style={{
						background: "#E8EDF2",
						height: "max-content",
						minHeight: "93vh",
					}}
					className="product-cont m0 pt4"
				>
					<div className="new-pricing-column">
						<NavLink to="/pricing" className="single-billing-nav pointer">
							<img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
							<div className="pricing-text underline pl2">
								back to Discount Events
							</div>
						</NavLink>
						<div className="new-pricing-cont">
							<div className="in-row align-center justify-between width100 mb4">
								<span className="new-pricing-title-text">
									Discount information
								</span>
								<div className="new-pricing-text in-row align-center">
									<button className="pricint-event-export mr4">Export</button>
									<button
										className="pricint-event-abort"
										onClick={abortedHandle}
									>
										Abort
									</button>
								</div>
							</div>
							<div className="new-pricing-box">
								<span className="new-pricing-text bold mwidth120">Name:</span>
								<span className="new-pricing-text">{event.name}</span>
							</div>
							<div className="new-pricing-box align-start">
								<span className="new-pricing-text bold mwidth120">
									Description:
								</span>
								<span className="new-pricing-text">{event.description}</span>
							</div>
							<div className="border-bottom width100 mb2"></div>
							{event.type === EventType.AUTOMATIC && (
								<>
									<div className="new-pricing-box align-start mt4">
										<span className="new-pricing-text bold mwidth120">
											Discount Type:
										</span>
										<span className="new-pricing-text">
											{event.discountType}
										</span>
									</div>
									<div className="new-pricing-box align-start">
										<span className="new-pricing-text bold mwidth120">
											Discount Amount:
										</span>
										<span className="new-pricing-text">
											{event.discountAmount}%
										</span>
									</div>
								</>
							)}
							<div className="new-pricing-box align-start">
								<span className="new-pricing-text bold mwidth120">
									Sale Goal:
								</span>
								<span className="new-pricing-text">
									{event.eventResult.goal} €
								</span>
							</div>
							<div className="new-pricing-box align-start">
								<span className="new-pricing-text bold mwidth120">Start:</span>
								<span className="new-pricing-text">
									{DateTime.fromISO(event.eventPeriod.start).toFormat(
										"MM/dd/yyyy hh:mm a"
									)}
								</span>
							</div>
							<div className="new-pricing-box align-start">
								<span className="new-pricing-text bold mwidth120">End:</span>
								<span className="new-pricing-text">
									{DateTime.fromISO(event.eventPeriod.end).toFormat(
										"MM/dd/yyyy hh:mm a"
									)}
								</span>
							</div>
							<div className="border-bottom width100 mb2"></div>
							{event.type === EventType.AUTOMATIC && (
								<div className="new-pricing-box align-start mt4">
									<span className="new-pricing-text bold mwidth150">
										Categories:
									</span>
									<div className="pricing-event-cat-cont">
										{event.categories.length ? (
											<>
												{event.categories.map((catId: string) => (
													<div className="pricing-event-cat-box" key={catId}>
														<span className="new-pricing-text mr2">
															{
																schemes?.find(
																	(scheme: SchemeRootObject) =>
																		catId === scheme._id
																)?.name
															}
														</span>
													</div>
												))}
											</>
										) : (
											<span className="new-pricing-text">All</span>
										)}
									</div>
								</div>
							)}
							<div className="new-pricing-box align-start">
								<span className="new-pricing-text bold mwidth150">
									Platforms accounts:
								</span>
								<div className="pricing-event-coutry-cont">
									{event.platforms.length ? (
										<>
											{event.platforms.map((platformId: string) => (
												<div
													className="pricing-event-coutry-box"
													key={platformId}
												>
													<CountryFlag
														country={
															platforms?.find(
																(platform: IMarketplace) =>
																	platformId === platform._id
															)?.country
														}
														className="table-search-image"
														width="28px"
													/>
													<span className="ml2 new-pricing-text">
														{
															platforms?.find(
																(platform: IMarketplace) =>
																	platformId === platform._id
															)?.zeoosName
														}
													</span>
												</div>
											))}
										</>
									) : (
										<span className="ml2 new-pricing-text">All</span>
									)}
								</div>
							</div>
						</div>
						{!loadEvent && !loadPlatforms ? (
							<PaginatedProducst event={event} platforms={platforms!} />
						) : (
							<CircularPreloader />
						)}
						<div className="in-row align-center justify-between mt4 width100">
							<button className="new-pricing-discard new-pricing-text">
								Cancel
							</button>
							<button className="new-pricing-save new-pricing-title-text">
								save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

interface IPaginatedProducts {
	event: PricingEvent;
	platforms: IMarketplace[];
}

const PaginatedProducst: React.FC<IPaginatedProducts> = ({
	event,
	platforms,
}) => {
	const [productsConfig, setProductsConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
		skus: event?.products?.map((p: any) => p.sku),
	});

	const rqKeyProducts = useMemo(() => {
		return `/pricing-event/${event._id}/products?${queryString.stringify(
			productsConfig
		)}`;
	}, [productsConfig, event._id]);

	const { isLoading: loadProducts, data: products } = useQuery(
		rqKeyProducts,
		() => getPaginatedInventoryProducts(productsConfig),
		{ enabled: !!event?.products.length }
	);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setProductsConfig((c) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setProductsConfig]
	);

	const getAutomaticDiscountPrice = useCallback(
		(oldPrice: number) => {
			return PricingEventUtils.getEventProductDiscountPrice(event, oldPrice);
		},
		[event]
	);

	const getCustomDiscountPrice = (
		data: any,
		products: {
			sku: string;
			price?: number;
		}[]
	) => {
		return products?.find((p: any) => p.sku === data?.sku)?.price;
	};

	return loadProducts ? (
		<CircularPreloader />
	) : (
		<div className="new-pricing-cont">
			<div className="in-row align-center justify-between width100 mb4">
				<div className="new-pricing-title-text">
					Discount applied to products
				</div>
				<div className="marketplace-pagination">
					{products!.total > productsConfig.perPage && (
						<Paginator
							data={!loadProducts ? products : {}}
							refetch={onChangePage as any}
						/>
					)}
				</div>
			</div>
			<div className="border-bottom width100"></div>
			{products?.data.length ? (
				<div style={{ height: "max-content" }} className="table-overflow">
					<table style={{ position: "sticky", zIndex: 101, top: "2px" }}>
						<thead>
							<tr
								style={{ marginTop: "-5px", width: "100%" }}
								className="table-results-title"
							>
								<th className="pricing-event-sticky-block admin-titlebg title-border">
									<th className="width100">
										<b>
											Displaying 1 to{" "}
											{products?.data.length < 50
												? String(products?.data.length)
												: "50"}{" "}
											products out of {products?.data.length} added.
										</b>
									</th>
								</th>
								{event.platforms.map((platformId: string, index: number) => (
									<React.Fragment key={index}>
										<th className="table-border-right"></th>
										<th className="gestao-vinuus">
											<div>
												{
													platforms?.find(
														(platform: IMarketplace) =>
															platformId === platform._id
													)?.zeoosName
												}
											</div>
											<div className="in-row width100 height100 gestao-sort">
												<div className="gestao-cost justify-center">
													Old Price
												</div>
												<div className="gestao-stock justify-center">
													New Price
												</div>
											</div>
										</th>
									</React.Fragment>
								))}
							</tr>
						</thead>
						<tbody></tbody>
					</table>
					<table className="table-results p0">
						<thead></thead>
						<tbody>
							{products?.data.map((product: IProductGestao) => (
								<tr
									className="table-result-box gestao-height-box"
									key={product._id}
								>
									<td className="pricing-event-sticky-block title-border">
										<div className="in-row align-center">
											<img src={product?.image} width="50px" alt="" />
											<span className="new-pricing-text ml2">
												{product?.name}
											</span>
											{/* <span className="new-pricing-text">
												<b>Seller name 1</b>
											</span> */}
										</div>
									</td>
									{event.platforms.map((platformId: string) => {
										const platformsBySku = product?.marketplaces;

										const zeoosName = platforms?.find(
											(platform: IMarketplace) => platformId === platform._id
										)?.zeoosName;

										const data = {
											...(platformsBySku?.[zeoosName!] || {}),
											sku: product.sku,
										};

										return (
											<React.Fragment key={platformId}>
												<td className="table-border-right"></td>
												<td className="gestao-vinuus">
													<div className="in-row width100 align-center justify-center">
														<div
															style={{ color: "#ACACAC" }}
															className="new-pricing-text"
														>
															{data?.price || 0}
														</div>
														<img
															className="ml2 mr2"
															src="/icons/pricing-arrow-right.svg"
															alt=""
														/>
														<div
															style={{
																color: "#299C00",
																fontWeight: "700",
															}}
															className="new-pricing-text"
														>
															{event.type === EventType.CUSTOM
																? getCustomDiscountPrice(data, event.products)
																: getAutomaticDiscountPrice(
																		Number(data?.price || 0)
																  ).toFixed(1)}{" "}
															€
														</div>
													</div>
												</td>
											</React.Fragment>
										);
									})}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default withRouter(memo(SinglePricingEvent));
