import React, { useCallback, useState } from "react";
import { IPaginatedRequest, IPaginatedUser, IUser } from "../../../interfaces";
import Paginator from "../../ui/pagination";
import { HistoryUser } from "./HistoryUser";
import { UserActivity } from "./UserActivity";
import { Role } from "../../../interfaces";
import { UserGroup } from "../../../actions/userGroup";
import RoleRequired from "../../utils/role-required/RoleRequired";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
import { roleOptions } from "./UsersManagement";
import { CreateEditUserForm, USER_TYPES, getUserType } from "./CreateUser";
import { queryClient } from "../../App";
import { toast } from "react-toastify";
import { getPreviewLink } from "../../../actions/user";

interface Props {
	users: IPaginatedUser;
	vendorOptions: any;
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
	rqKey: string;
	loadUsers: boolean;
}

export const UMTable: React.FC<Props> = ({
	users,
	vendorOptions,
	setCurrentConfig,
	currentConfig,
	rqKey,
	loadUsers,
}) => {
	const [editModal, setEditModal] = useState<boolean>(false);
	const [activityModal, setActivityModal] = useState<boolean>(false);
	const [historyModal, setHistoryModal] = useState<boolean>(false);
	const [mappedUser, setMappedUser] = useState<any>({});

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	return (
		<div className="table-main-cont">
			<div style={{ padding: "0 40px" }} className="width100">
				<div className="table-main-title-cont">
					<div className="table-main-title-search-value-cont">
						<div className="search-box">
							<div className="search_form">
								<div className="table-search-box">
									<form
										className="table-search-form"
										onSubmit={(e) => e.preventDefault()}
									>
										<input
											className="table-search-input"
											placeholder="Search user"
											onChange={(e) =>
												setCurrentConfig((conf: IPaginatedRequest) => ({
													...conf,
													search: e.target.value,
												}))
											}
											value={currentConfig.search}
										/>
										<img
											className="table-search-image"
											src="/icons/search.svg"
											width="25px;"
											alt=""
										/>
									</form>
								</div>
							</div>
							<div className="table-value">
								{loadUsers ? (
									<CircularPreloader />
								) : (
									<span className="table-main-title-text">
										{users?.total} users
									</span>
								)}
							</div>
						</div>
					</div>
					<div className="marketplace-pagination">
						<Paginator
							data={!loadUsers ? users : {}}
							refetch={onChangePage as any}
						/>
					</div>
				</div>
				<div style={{ height: "max-content" }} className="table-overflow">
					<table
						style={{
							position: "sticky",
							zIndex: 101,
							top: "2px",
							width: "100%",
						}}
					>
						<thead>
							<tr
								style={{ marginTop: "-5px", width: "100%" }}
								className="table-results-title"
							>
								<th className="users-email">Email</th>
								<RoleRequired role={Role.admin}>
									<th className="users-role">Type</th>
									<th className="users-vendor-permission">Seller List</th>
								</RoleRequired>
								<th className="users-username">Name</th>
								<th className="users-status">Status</th>
								<th className="users-edit">Edit</th>
								<RoleRequired role={Role.admin}>
									<th className="users-history">Preview</th>
								</RoleRequired>
							</tr>
						</thead>
					</table>
					<table className="table-results">
						<tbody>
							{users?.data.map((user: IUser, index: number) => {
								const type = getUserType(user);

								const vendorPermissions = [
									Role.sellerAdmin,
									Role.sellerUser,
								].includes(user.role)
									? (user.group as UserGroup)?.vendorPermissions
									: user.vendorPermissions;

								return (
									<React.Fragment key={index}>
										<tr className="table-result-box">
											<td className="users-email">
												<div className="email-text">{user.email}</div>
											</td>
											<td className="users-role">
												<div
													className={
														user.role === "ADMIN"
															? "role-text-admin"
															: "role-text-user"
													}
													style={{ backgroundColor: type?.bgColor || '#E1E1E1' }}
												>
													{type?.type || 'Other'}
												</div>
											</td>
											<RoleRequired role={Role.admin}>
												<td className="users-vendor-permission">
													<div className="permission-text">
														{/* @ts-ignore */}
														{user.seller ? user.seller?.name : vendorPermissions?.map(
															(vendor: any) => vendor.name + "\n"
														)}
													</div>
												</td>
											</RoleRequired>
											<td className="users-username justify-start">
												<img
													style={{ borderRadius: "60px", marginRight: "10px" }}
													height={34}
													width={34}
													src={
														!user.profileImage
															? "/icons/image-bot.svg"
															: user.profileImage
													}
													alt=""
												/>
												<div className="username-text">{user.username}</div>
											</td>
											<td
												onClick={() => {
													setMappedUser(user);
													setActivityModal(true);
												}}
												className="users-status"
											>
												<AntSwitch
													checked={user.isActive}
													name="checkedC"
													required
												/>
												<span className="email-textm ml2">
													{user.isActive ? "Active" : "Inactive"}
												</span>
											</td>
											<td className="users-edit">
												<button
													className="users-status-btn"
													onClick={(e) => {
														e.stopPropagation();
														e.preventDefault();
														setMappedUser(user);
														setEditModal(true);
													}}
												>
													Edit
												</button>
											</td>

											<RoleRequired role={Role.admin}>
												<td className="users-history">
													{(user.role !== Role.admin && user.isActive) && (
														<button
															className="users-history-btn ml2"
															onClick={async (e) => {
																try {
																	navigator.clipboard.writeText(await getPreviewLink(user._id));
																	toast.success('Preview URL copied to clipboard');
																} catch (error) {
																	console.error(error);
																}
															}}
														>
															<img
																src="/icons/password-hide.svg"
																alt=""
															/>
														</button>
													)}
												</td>
											</RoleRequired>
										</tr>
									</React.Fragment>
								);
							})}
						</tbody>
					</table>
					{editModal && (
						<CreateEditUserForm
							user={mappedUser}
							setModal={setEditModal}
							modal={editModal}
							onSuccess={() => queryClient.invalidateQueries(rqKey)}
						/>
					)}
					{activityModal && (
						<UserActivity
							mappedUser={mappedUser}
							setModal={setActivityModal}
							modal={activityModal}
							rqKey={rqKey}
						/>
					)}
					{historyModal && (
						<HistoryUser
							userHistory={mappedUser?.history || []}
							setModal={setHistoryModal}
							modal={historyModal}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
