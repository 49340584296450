import React from "react";
import "./vendors.css";
import VendorsTable from "./VendorsTable";
import { NavLink } from "react-router-dom";

export default function Vendors() {
  return (
    <>
      <div className="main-body">
        <div className="main-container">
          <div className="table-main-cont p0">
            <div className="table-value-search-cont mt2">
              <div className="table-title-nav-cont">
                <NavLink to="/freight" className="table-tab-mapping">
                  Freight Mapping
                </NavLink>
                <NavLink to="/tax" className="table-tab-mapping">
                  Tax Mapping
                </NavLink>
                <NavLink
                  to="/vendor"
                  className="table-tab-mapping"
                  activeClassName="table-tab-mapping-active"
                >
                  Vendor Mapping
                </NavLink>
              </div>
            </div>
            <div className="mapping-title-border"></div>
            <div className="table-title-cont">
              <div className="mplc-filter-cont width100">
                <div className="mplc-filter">
                  <div className="filter-text">Filter by:</div>
                  <div className="">
                    <select className="main-select-bi ml1" value="">
                      <option className="acompan-option">All Countries</option>
                    </select>
                  </div>
                  <div className="">
                    <select className="main-select-bi" value="">
                      <option className="acompan-option">All Platforms</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <VendorsTable />
          </div>
        </div>
      </div>
    </>
  );
}
