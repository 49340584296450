import React, { useState } from "react";

interface Props extends Omit<React.ComponentProps<"img">, "src"> {
	primarySrc: string;
	fallbackSrc: string;
}

const ImageWithFallback: React.FC<Props> = ({
	primarySrc,
	fallbackSrc,
	...props
}) => {
	// TODO: this is not very good...
	const [safeList, setSafeList] = useState([
		...['.png', '.jpg', '.jpeg'].map((ext) => primarySrc.replace('.svg', ext)),
		fallbackSrc
	]);
	const [currentSrc, setCurrentSrc] = useState(primarySrc);

	return (
		<img
			alt=''
			src={encodeURI(currentSrc)}
			onError={() => {
				setCurrentSrc(safeList[0]);
				setSafeList((safeList) => safeList.slice(1));
			}}
			{...props}
		/>
	);
};

export default ImageWithFallback;
