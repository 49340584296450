import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  getSchemeVersions,
  deleteSchemeVersion,
} from "../../../../actions/scheme";
import { IPaginatedRequest } from "../../../../interfaces";
import { queryClient } from "../../../App";
import Paginator from "../../../ui/pagination";
import Preloader from "../../../ui/preloader/Preloader";

export const SchemeVersions: React.FC<any> = ({
  schemeById,
  setSchemeById,
}) => {
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
  });

  const {
    isLoading,
    data: versions,
    refetch,
  } = useQuery("versions", () =>
    getSchemeVersions(schemeById.name, currentConfig)
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [currentConfig]);

  const onChangePage = useCallback(async (config: IPaginatedRequest) => {
    setCurrentConfig(config);
    // eslint-disable-next-line
  }, []);

  const deleteMutation = useMutation(
    (schemeVersion: any) => deleteSchemeVersion(schemeVersion._id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("versions");
        // setSchemeById(schemeVersion);
        toast.success("Version successfully deleted!");
      },
      onError: () => {
        toast.error("Something went wrong!");
      },
    }
  );

  const onDelete = useCallback(
    async (e: React.FormEvent | React.MouseEvent, schemeVersion: any) => {
      e.preventDefault();

      await deleteMutation.mutateAsync(schemeVersion);
    },
    [deleteMutation]
  );

  if (isLoading) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="table-main-cont whitebg mheighti">
      <div className="version-table-overflow">
        <div className="table-main-title-cont">
          <div></div>
          <div className="marketplace-pagination">
            <Paginator data={versions} refetch={onChangePage as any} />
          </div>
        </div>
        <div className="width100">
          <table
            style={{ position: "sticky", zIndex: 101, top: "0", width: "100%" }}
          >
            <tr style={{ marginTop: "-5px" }} className="table-results-title">
              <th className="version-reg">Version</th>
              <th className="table-border-right"></th>
              <th className="version-reg">Date</th>
              <th className="table-border-right"></th>
              <th className="version-reg">Status</th>
              <th className="table-border-right"></th>
              <th className="version-reg">Author</th>
              <th className="table-border-right"></th>
              <th className="version-delete"></th>
            </tr>
          </table>
          <div style={{ marginTop: "-5px" }}>
            <table className="table-results p0">
              <tbody>
                {versions.data.map((schemeVersion: any) => (
                  <tr
                    style={{
                      backgroundColor:
                        schemeVersion.version !== schemeById.version
                          ? `transparent`
                          : "#e4e4e4",
                    }}
                    className="table-result-box freight-body"
                    key={schemeVersion._id}
                    onClick={async (e) => {
                      e.preventDefault();
                      setSchemeById(schemeVersion);
                    }}
                  >
                    <td className="version-reg bold pointer">
                      {schemeVersion.version}
                    </td>
                    <td className="table-border-right"></td>
                    <td className="version-reg">
                      {moment(schemeVersion.created).format(
                        "MMM Do YYYY, hh:mm a"
                      )}
                    </td>
                    <td className="table-border-right"></td>
                    <td className="version-reg">
                      {schemeVersion.version === schemeById.version &&
                        "current"}
                    </td>
                    <td className="table-border-right"></td>
                    <td className="version-reg">
                      {schemeVersion.user?.username &&
                        `Authored by ${schemeVersion.user.username}`}
                    </td>
                    <td className="table-border-right"></td>
                    <td className="version-delete">
                      {schemeVersion.version !== schemeById.version && (
                        <img
                          onClick={(e) => onDelete(e, schemeVersion)}
                          className="version-delete-button"
                          src="/icons/trash-bin.svg"
                          alt=""
                          width="20px;"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
