import React, { memo, useMemo } from "react";
import { DateTime } from "luxon";

interface Props {
  date: DateTime;
  formatDate?: (date: DateTime) => string;
}

const Date: React.FC<Props> = ({ date, formatDate }) => {
  const formattedDate = useMemo(() => {
    return formatDate
      ? formatDate(date)
      : date.toFormat("yyyy LLL dd HH:mm:ss");
  }, [date, formatDate]);

  return <div>{formattedDate}</div>;
};

export default memo(Date);
