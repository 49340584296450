import React, {
  ChangeEvent,
  FC,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, withRouter } from "react-router-dom";
import ModalHeader from "../../single-marketplace/ModalHeader";
import { useAtom } from "jotai";
import { addAssetsByBatch, getProductBySkuNew, useProducts } from "../../../../actions/v2/product";
import { useParamsState } from "hooks";
import { Product, ProductRequest } from "types/product";
import "./upload-files.css";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import { IProductGestao, Role } from "../../../../interfaces";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import useUser from "../../../../hooks/useUser";
import Pagination from "../../../ui/pagination/Pagination";
import { getMarketplaceProductImage, getProductBySku, uploadFile } from "../../../../actions/pim";
import { selectedProductsAtom } from "../../tables/gestao/Gestao";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  PointerSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Modal } from "../../../ui/modal/Modal";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { checkProductInfo, saveProductInfo } from "../../../../actions/marketplace";
import { toast } from "react-toastify";
import zIndex from "@mui/material/styles/zIndex";
import { createImagesObject } from "../../../../utils";

type SidebarItemProps = {
  file: File;
  selectedDragFiles: File[];
  onFileSelect: (file: File) => void;
};

const UploadFilesInBatch = (props: any) => {
  const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);
  const [selectedFiles, _] = useState<File[]>(
    props.history.location?.state?.files || []
  );
  const [selectedDragFiles, setSelectedDragFiles] = useState<File[]>([]);
  const [selectedDragImageFiles, setSelectedDragImageFiles] = useState<File[]>([]);
  const [allImageFiles, setAllImageFiles] = useState<(File | string)[]>([]);
  const [selectedDragVideoFiles, setSelectedDragVideoFiles] = useState<File[]>([]);
  const [allVideoFiles, setAllVideoFiles] = useState<(File | string)[]>([]);
  const [selectedDragOtherFiles, setSelectedDragOtherFiles] = useState<File[]>([]);
  const [selectedProductToAddTo, setSelectedProductToAddTo] = useState<any>();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingProductInfo, setIsFetchingProductInfo] = useState(false)
  const [overId, setOverId] = useState("");

  const history = useHistory();

  const [currentConfig, setCurrentConfig] = useParamsState<ProductRequest>({
    perPage: 10,
    page: 1,
    type: "integrated" as any,
  });
  const debouncedConfig = useDeferredValue(currentConfig);

  const { user } = useUser() as any;
  const { isLoading, data: products } = useProducts({
    ...debouncedConfig,
    ...{},
  });

  const handleSelectOne = useCallback(
    (item: IProductGestao) => () => {
      setSelectedProducts((prev) => {
        if (prev.find((x) => x === item.sku)) {
          return prev.filter((x) => x !== item.sku);
        }
        return [...prev, item.sku];
      });
    },
    [setSelectedProducts]
  );

  async function handleDragEnd(event: DragEndEvent) {
    const { over } = event;

    if (over) {
      setIsFetchingProductInfo(true)
      const productToAddFilesTo = products?.data?.find((p) => p._id === over?.id);
      const productInfo:any = await getProductBySkuNew(productToAddFilesTo?.sku!)
      console.log({productInfo})
      setSelectedProductToAddTo(productInfo);
      setAllImageFiles([...(productInfo.images), ...selectedDragImageFiles])
      setOpenAddModal(true);
      setIsFetchingProductInfo(false)
    }
  }

  const checkFileType = (file: File) => {
    if (file.type.startsWith("image/")) {
      return "image";
    } else if (file.type.startsWith("video/")) {
      return "video";
    } else {
      return "others";
    }
  };

  const onFileSelect = (file: File | string) => {

    if(typeof file === 'string'){
      const imageIndex = allImageFiles.findIndex((s) => s === file)
      const newImageFile = allImageFiles.filter((_, i) => i !== imageIndex)
      setAllImageFiles(newImageFile)
      return
    }
    
    const fileType =  checkFileType(file); 
    const isExist = selectedDragFiles.find((s) => s.name === file.name);
    if (!isExist) {
      setSelectedDragFiles((prev) => [...prev, file]);
      if (fileType === "image") {
        setSelectedDragImageFiles((prev) => [...prev, file]);
      } else if (fileType === "video") {
        setSelectedDragVideoFiles((prev) => [...prev, file]);
      } else {
        setSelectedDragOtherFiles((prev) => [...prev, file]);
      }
    } else {
      const newDragFiles = selectedDragFiles?.filter((s) => s.name !== file.name);
      setSelectedDragFiles(newDragFiles);
      if (fileType === "image") {
        const newDragFiles = selectedDragImageFiles?.filter((s) => s.name !== file.name);
        
        setSelectedDragImageFiles(newDragFiles);
      } else if (fileType === "video") {
        const newDragFiles = selectedDragVideoFiles?.filter((s) => s.name !== file.name);
        setSelectedDragVideoFiles(newDragFiles);
      } else {
        const newDragFiles = selectedDragOtherFiles?.filter((s) => s.name !== file.name);
        setSelectedDragOtherFiles(newDragFiles);
      }
    }
  };

  const onDragOver = (event: DragOverEvent) => {
    const { over } = event;
    setOverId(over?.id as string);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const onModalClose = () => {
    setOpenAddModal(false);
    setOverId("");
  };

  const onDragSortEnd = (event: DragEndEvent, type: string) => {
    const { active, over } = event;
    if (active.id === over?.id) return;
    if (type === "image") {
      const originalPos = allImageFiles.findIndex((_, i) => i === active.id);
      const newPos = allImageFiles.findIndex((_, i) => i === over?.id);
      const updatedList = arrayMove(allImageFiles, originalPos, newPos);
      setAllImageFiles(updatedList);
    }
    if (type === "video") {
      const originalPos = selectedDragVideoFiles.findIndex((_, i) => i === active.id);
      const newPos = selectedDragVideoFiles.findIndex((_, i) => i === over?.id);
      const updatedList = arrayMove(selectedDragVideoFiles, originalPos, newPos);
      setSelectedDragImageFiles(updatedList);
    }
    if (type === "file") {
      const originalPos = selectedDragOtherFiles.findIndex((_, i) => i === active.id);
      const newPos = selectedDragOtherFiles.findIndex((_, i) => i === over?.id);
      const updatedList = arrayMove(selectedDragOtherFiles, originalPos, newPos);
      setSelectedDragImageFiles(updatedList);
    }
  };

  const onAddandSave = async () => {
    setIsSubmitting(true);
    let savedImagesLinks: string[] = [];
    let savedVideoLinks: string[] = [];

    try {
      //save all selected files and get back links
      if (allImageFiles?.length > 0) {
        savedImagesLinks = await Promise.all(
          allImageFiles?.map(async (file) => {
            if(typeof file === 'string'){
              return file
            }else{
              return await uploadFile(file);
            }
          })
        );
      }
      if (selectedDragVideoFiles?.length > 0) {
        savedVideoLinks = await Promise.all(
          selectedDragVideoFiles?.map(async (file) => {
            return await uploadFile(file);
          })
        );
      }


      if (selectedProductToAddTo) {
        const productInfo = await checkProductInfo(selectedProductToAddTo?.sku!);
        const updatedProductInfo = {
          ...productInfo.productInfo,
          productType: productInfo.productInfo.productType,
          lang: productInfo.productInfo.lang,
          details: {
            ...productInfo?.productInfo?.details!,
            pack:savedImagesLinks[0],
            individual1:savedImagesLinks[0],
            rotulo:savedImagesLinks[1],
            contrarotulo:savedImagesLinks[2],
            maxima:savedImagesLinks[3],
            ...createImagesObject(savedImagesLinks)

          },
        };

        // save the updated product
        const res = await saveProductInfo(updatedProductInfo);


        console.log({res, savedImagesLinks})
        //clear selected states
        setOverId("");
        setSelectedDragFiles([]);
        setSelectedDragImageFiles([]);
        setSelectedDragVideoFiles([]);
        setSelectedDragOtherFiles([]);
        setOpenAddModal(false);

        toast.success(" Files Added Succesfully");
      }
    } catch (error) {
      toast.error("Error occured.... Please try again");
      console.log({ error });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (selectedDragFiles.length < 1) {
      setOpenAddModal(false);
      setOverId("");
    }

    if(selectedFiles.length < 1) {
      history.push("/catalog/integrated")
    }
  }, [selectedDragFiles, selectedFiles]);

  console.log({allImageFiles})

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd} onDragOver={onDragOver}>
      <div className="product-main-cont">
        <ModalHeader zeoosName="Upload files in batch" linkTo="/catalog/integrated">
          <button
            onClick={() => history.push("/catalog/integrated")}
            className="product-header-button"
          >
            FINISH
          </button>
        </ModalHeader>
        <div className="file-batch-upload-wrapper">
          <div className="file-batch-upload-sidebar">
            <p className="file-batch-upload-sidebar-header-text">
              Select and drag files unto the product to add them
            </p>
            <p className="file-batch-upload-sidebar-header-text" style={{ fontSize: "13px" }}>
              {selectedFiles?.length} files
            </p>
            {selectedFiles?.map((file, index) => (
              <SideBarItems
                key={index}
                file={file}
                selectedDragFiles={selectedDragFiles}
                onFileSelect={onFileSelect}
              />
            ))}
          </div>
          <div className="file-batch-upload-main">
            <div className="table-main-title-cont">
              <div className="table-main-title-search-value-cont">
                <div className="table-search-box ml2">
                  <form className="table-search-form" onSubmit={(e) => e.preventDefault()}>
                    <input
                      className="table-search-input"
                      placeholder="Search product or sku"
                      value={currentConfig.search}
                      onChange={(e) =>
                        setCurrentConfig({
                          search: e.target.value,
                        })
                      }
                      autoFocus
                    />
                    <img
                      className="table-search-image"
                      src="/icons/search.svg"
                      width="25px;"
                      alt=""
                    />
                  </form>
                </div>
                <div className="table-value">
                  {["gv@vinuus.com", "pj@vinuus.com"].includes(user.email) && (
                    <RoleRequired role={Role.admin}>
                      <img
                        className="refresh-image"
                        src="/icons/refresh.svg"
                        width="20px;"
                        alt=""
                      />
                    </RoleRequired>
                  )}
                  {isLoading ? (
                    <CircularPreloader />
                  ) : (
                    <span className="table-main-title-text">{products?.total} PRODUCTS</span>
                  )}
                </div>
              </div>

              <div className="marketplace-pagination">
                <Pagination
                  lastPage={products?.lastPage || 0}
                  setConfig={setCurrentConfig}
                  config={currentConfig}
                />
              </div>
            </div>
            <div
              style={{ display: "flex", gap: "6px" }}
              className="mt2 mb2 width100 wrap justify-start aligm-start"
            >
              {products?.data.map((item: Product) => (
                <ProductBox
                  key={`${item._id}-${item.ean}-${item.sku}`}
                  item={item}
                  canAdd={false}
                  overId={overId}
                  onSelect={handleSelectOne(item as any)}
                  selected={!!selectedProducts.find((x) => x === item.sku)}
                  selectedDragFiles={selectedDragFiles}
                />
              ))}
            </div>
          </div>
        </div>
        <Modal
          bodyClassName="file-batch-save-items-modal-container"
          isOpened={openAddModal}
          onModalClose={onModalClose}
        >
          <div className="file-batch-save-items-modal-wrapper">
            <p className="file-batch-save-items-modal-text1">
              Add {selectedDragFiles?.length} files to the EAN {selectedProductToAddTo?.details?.ean} product
            </p>
            <p className="file-batch-save-items-modal-text2">{selectedProductToAddTo?.name}</p>
            <div className="file-batch-sku-ean-container">
              <p style={{ fontWeight: 700 }}>
                SKU: <span style={{ fontWeight: 400 }}>{selectedProductToAddTo?.sku}</span>
              </p>
              <p style={{ fontWeight: 700, marginLeft: "49px" }}>
                EAN: <span style={{ fontWeight: 400 }}>{selectedProductToAddTo?.details?.ean}</span>
              </p>
            </div>

            {isFetchingProductInfo && <CircularPreloader/>}

            <div className="file-batch-assets-container">
              {allImageFiles?.length > 0 && (
                <div className="file-batch-image-asset-container">
                  <p style={{ marginBottom: "12px" }} className="file-batch-save-items-modal-text2">
                    <span style={{ fontWeight: 700 }}>Images </span> - Click and drag to set file
                    order
                  </p>
                  <DndContext sensors={sensors} onDragEnd={(e) => onDragSortEnd(e, "image")}>
                    <SortableContext
                      strategy={verticalListSortingStrategy}
                      items={allImageFiles.map((s, i) => ({ id: i, s }))}
                    >
                      {allImageFiles?.map((s, i) => (
                        <SortableItems
                          onDelete={onFileSelect}
                          type="image"
                          key={i}
                          index={i}
                          file={s}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                </div>
              )}
              {selectedDragVideoFiles?.length > 0 && (
                <div className="file-batch-image-asset-container">
                  <p style={{ marginBottom: "12px" }} className="file-batch-save-items-modal-text2">
                    <span style={{ fontWeight: 700 }}>Videos </span> - Click and drag to set file
                    order
                  </p>
                  <DndContext sensors={sensors} onDragEnd={(e) => onDragSortEnd(e, "video")}>
                    <SortableContext
                      strategy={verticalListSortingStrategy}
                      items={selectedDragVideoFiles.map((s, i) => ({ id: i, ...s }))}
                    >
                      {selectedDragVideoFiles?.map((s, i) => (
                        <SortableItems
                          onDelete={onFileSelect}
                          type="video"
                          key={i}
                          index={i}
                          file={s}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                </div>
              )}
              {selectedDragOtherFiles?.length > 0 && (
                <div className="file-batch-image-asset-container">
                  <p style={{ marginBottom: "12px" }} className="file-batch-save-items-modal-text2">
                    <span style={{ fontWeight: 700 }}>Other files </span> - Click and drag to set
                    file order
                  </p>
                  <DndContext sensors={sensors} onDragEnd={(e) => onDragSortEnd(e, "other")}>
                    <SortableContext
                      strategy={verticalListSortingStrategy}
                      items={selectedDragOtherFiles.map((s, i) => ({ id: i, ...s }))}
                    >
                      {selectedDragOtherFiles?.map((s, i) => (
                        <SortableItems
                          onDelete={onFileSelect}
                          type="other"
                          key={i}
                          index={i}
                          file={s}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                </div>
              )}
            </div>

            <div className="file-batch-save-container">
              <p onClick={onModalClose} style={{ color: "#55718A", cursor: "pointer" }}>
                Cancel
              </p>
              <button
                onClick={onAddandSave}
                disabled={isSubmitting}
                className="file-batch-save-button"
              >
                {isSubmitting ? "Adding..." : "ADD AND SAVE"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </DndContext>
  );
};

const SideBarItems: FC<SidebarItemProps> = ({ file, onFileSelect, selectedDragFiles }) => {
  const isSelected = selectedDragFiles.find((s) => s.name === file.name);
  const selectedId = isSelected ? "selected" : file.name;
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: selectedId,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <>
      <div
        ref={isSelected ? setNodeRef : null}
        {...(isSelected ? listeners : {})}
        {...(isSelected ? attributes : {})}
        className="file-batch-upload-sidebar-select-items"
        style={{ backgroundColor: isSelected ? "#F2F2F2" : "#fff", ...style }}
      >
        <div className="in-row justify-center items-center">
          <img
            className="pointer"
            src={isSelected ? "/icons/check-box-checked.svg" : "/icons/check-box.svg"}
            alt=""
            onClick={() => onFileSelect(file)}
          />
        </div>
        {file.type.startsWith("image/") ? (
          <img
            className="file-batch-upload-sidebar-select-item-image"
            src={URL.createObjectURL(file)}
            alt={file.name}
          />
        ) : (
          <img
            className="file-batch-upload-sidebar-select-item-image"
            src={"/no-image.jpg"}
            alt="No Image"
          />
        )}
        <p className="file-batch-upload-gray-text">{file?.name}</p>
      </div>
    </>
  );
};

const ProductBox: React.FC<{
  item: Product;
  canAdd?: boolean;
  selected?: boolean;
  onSelect: any;
  overId: string;
  selectedDragFiles: File[];
}> = ({ item, overId, selectedDragFiles }) => {
  const [integratedChannelsModal, setIntegratedChannelsModal] = useState<boolean>(false);
  const { user } = useUser();

  const { setNodeRef } = useDroppable({ id: item._id });

  return (
    <div ref={setNodeRef} className="product-main-box">
      {/* <ConditionalLink
          condition={user.role === Role.admin}
          to={`/productInfo/${item.sku}`}
          className="in-row"
        > */}
      {overId !== item._id && (
        <div className="width100 justify-center">
          <img className="product-main-box-image" src={getMarketplaceProductImage(item)} alt="" />
        </div>
      )}
      {overId === item._id && (
        <div className="file-batch-selected-droppable-over-container">
          <p className="file-batch-selected-droppable-over-text">
            Add {selectedDragFiles?.length} selected files to this product.
          </p>
        </div>
      )}
      {/* </ConditionalLink> */}
      <div className="in-column justify-start align-start width100 mheight100px">
        <div className="product-main-box-title-cont">
          <div className="in-column align-start">
            <div>SKU:</div>
            <div className="bold">{item.sku}</div>
          </div>
          <div className="reverse-pim-main-box-title">
            <div>EAN:</div>
            <div className="bold">{item.ean}</div>
          </div>
        </div>
        <div style={{ fontSize: "13px", color: "#5A5A5A" }}>{item.name}</div>
      </div>
    </div>
  );
};

const SortableItems: FC<{
  index: number;
  file: File | string;
  type: string;
  onDelete: (file: File | string) => void;
}> = ({ index, file, type, onDelete}) => {
  const { listeners, setNodeRef, attributes, transform, transition } = useSortable({ id: index });

  const isExistingAsset = typeof(file) === 'string'
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };


  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className="file-batch-sort-items-container"
    >
      <p style={{ color: "#5a5a5a" }}>{index + 1}</p>
      <div className="file-batch-sort-items-detail-container">
        <img
        // @ts-ignore
          src={isExistingAsset ? file : type === "image" ? URL.createObjectURL(file) : "/no-image.jpg"}
          width={80}
          height={80}
        />
        <div className="file-batch-sort-items-detail-info-container">
          {/* @ts-ignore */}
           <p className="file-batch-save-items-modal-text2">{isExistingAsset ? `Image ${index + 1}` : file.name}</p>
        </div>
      </div>
      <div onClick={() => onDelete(file)}>
        <img src="/icons/trash-bin.svg" width={24} height={24} />
      </div>
    </div>
  );
};

export default withRouter(UploadFilesInBatch);
