import axios from "axios";
import fileDownload from "js-file-download";
import { getHeaders } from "../user";

export async function downloadPurchaseOrder(purchase_order:string) {
    try {
      const res = await axios.get(`/v2/order/download-purcharse-order?po_name=${purchase_order}`, {
        ...getHeaders(),
        responseType: "arraybuffer",
      });
  
      return fileDownload(res.data, `${purchase_order}.pdf`);
    } catch (error: any) {
      console.log("Purchase Order Generation Error", error);
      throw new Error("Purchase Order Generation Error");
    }
  }

  export async function listOrders () {
    const res = await axios.get(`/v2/orders/list`)
    return res.data.orders
  }