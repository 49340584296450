import axios from "axios";
import { getToken, setToken } from "./token";
import * as accessToken from "../components/context";
import { IPaginatedRequest, IUser } from "../interfaces";
import queryString from "query-string";
import { ProductImportReport, OfferImportReport } from "types/seller";

axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "https://portal.vinuus.com/api";

export function getHeaders(optionalHeaders = {}) {
  return {
    headers: {
      authorization: `Bearer ${accessToken.getToken()}`,
      ...optionalHeaders,
    },
  };
}

export enum MFA {
  email = "EMAIL",
  none = "NONE",
}

export const loginUser = async (body: any, keepLoggedIn = true) => {
  const res = await axios.post("/user/login", body);

  const { data, success } = res.data;

  if (!success) {
    throw new Error("Login failed");
  }

  if (data?.user?.mfa === MFA.email) {
    throw new Error("MFA email required");
  }

  accessToken.setToken(data?.user?.accessToken);

  if (keepLoggedIn) {
    setToken(data.refreshToken);
  }

  return data;
};

export const refreshTokens = async () => {
  try {
    const refreshToken = getToken();

    if (!refreshToken) {
      // return new Error("Refresh token is not set");
      return null;
    }

    const res = await axios.post("/user/refreshTokens", { refreshToken });

    const { data, success } = res.data;

    if (!success) {
      // return new Error("Refresh tokens failed");
      return null;
    }

    setToken(data.refreshToken);
    accessToken.setToken(data?.user?.accessToken);

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export async function getUsers() {
  const res = await axios.get("/user", getHeaders());
  return res.data.users;
}

export async function getPaginatedUsers(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/user?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}

export async function getPaginatedUserHistory(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/user/history?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}

export async function createUser(user: Partial<IUser>) {
  await axios.post("/user", { user }, getHeaders());
}

export async function updateUser(id: string, user: Partial<IUser> | IUser) {
  await axios.put(`/user/${id}`, { user }, getHeaders());
}

export async function updatePassword(
  password: string,
  currentPassword: string
) {
  await axios.post(
    `/user/changePassword`,
    { password, currentPassword },
    getHeaders()
  );
}

export async function deleteProfileImage() {
  await axios.delete(`/user/image`, getHeaders());
}

export async function getVendors() {
  const res = await axios.get("/getVendors", getHeaders());
  return res.data.vendors;
}

export async function deleteUser(username: string) {
  await axios.delete(`/user/${username}`, getHeaders());
}

export async function countryUser() {
  const res = await axios.get(`/shopify/url`, getHeaders());
  return res.data;
}

export async function forgotPassword(body: any) {
  const res = await axios.post("/user/forgot", {
    ...body,
    src: "zeoos",
  });
  return res.data;
}

export async function resetPassword(body: any) {
  const res = await axios.post("/user/reset", body);
  return res.data;
}

export enum PermissionScope {
  dashboard = "DASHBOARD",
  onboarding = "ONBOARDING",
  intelligence = "INTELLIGENCE",
  platforms = "PLATFORMS",
  inventory = "INVENTORY",
  orders = "ORDERS",
  vendors = "VENDORS",
  logistic = "LOGISTIC",
  billings = "BILLINGS",
  pim = "PIM",
  // digital_assets = "DIGITAL_ASSETS",
  mappings = "MAPPINGS",
  syncs = "SYNCS",
  settings = "SETTINGS",
}

export enum PermissionType {
  read = "READ",
  write = "WRITE",
  all = "*",
}

export const PermissionOptions = [
  {
    key: "dashboard",
    value: "DASHBOARD",
    label: "Dashboard",
  },
  {
    key: "intelligence",
    value: "INTELLIGENCE",
    label: "Intelligence",
  },
  {
    key: "platforms",
    value: "PLATFORMS",
    label: "Platforms",
  },
  {
    key: "inventory",
    value: "INVENTORY",
    label: "Inventory",
  },
  {
    key: "orders",
    value: "ORDERS",
    label: "Orders",
  },
  {
    key: "vendors",
    value: "VENDORS",
    label: "Vendors",
  },
  {
    key: "logistic",
    value: "LOGISTIC",
    label: "LOGISTIC",
  },
  {
    key: "billings",
    value: "BILLINGS",
    label: "Billings",
  },
  {
    key: "pim",
    value: "PIM",
    label: "Pim",
  },
  // {
  //   key: "digital_assets",
  //   value: "DIGITAL_ASSETS",
  //   label: "Digital assets",
  // },
  {
    key: "mappings",
    value: "MAPPINGS",
    label: "Mappings",
  },
  {
    key: "syncs",
    value: "SYNCS",
    label: "Syncs",
  },
  {
    key: "settings",
    value: "SETTINGS",
    label: "Settings",
  },
] as {
  key: keyof typeof PermissionScope;
  value: PermissionScope;
  label: string;
}[];

export const PermissionTypeOptions = [
  {
    key: "read",
    value: "READ",
    label: "Basis",
  },
  {
    key: "write",
    value: "WRITE",
    label: "Admin",
  },
  {
    key: "all",
    value: "*",
    label: "Both",
  },
];

export type TPermission = { scope: PermissionScope; type: PermissionType };

export function isValidPermission(
  permissionRequested: TPermission,
  permissionsToValidate: TPermission[]
) {
  const permission = permissionsToValidate.find(
    (permission) => permission.scope === permissionRequested.scope
  );
  return (
    permission?.type === permissionRequested.type ||
    permission?.type === PermissionType.all
  );
}

export function createPermission(scope: PermissionScope, type: PermissionType) {
  return {
    scope,
    type,
  } as TPermission;
}

export async function sendMFACode(body: any) {
  const res = await axios.post("/user/mfa", body);
  return res.data;
}

export async function loginWithMFACode(body: any) {
  const res = await axios.post("/user/login/mfa", body);

  setToken(res.data.data.refreshToken);
  accessToken.setToken(res.data.data?.user?.accessToken);

  return res.data;
}

export async function getAdminNames() {
  const res = await axios.get("/admin/names", getHeaders());
  return res.data as string[];
}

export async function getMyServiceToken() {
  const res = await axios.get(`/service-token`, getHeaders());
  return res.data as string;
}

export async function getPreviewLink(id: string) {
  const res = await axios.get(`/user/${id}/preview`, getHeaders());
  return res.data as string;
}

export async function getProductCounts(allCatalog: boolean) {
  const res = await axios.get(
    `/v2/my/product-counts${allCatalog ? "" : "?seller=true"}`,
    getHeaders()
  );
  return res.data as {
    integrated: number;
    nonIntegrated: number;
    unregistered: number;
  };
}

export async function getOfferCounts() {
  const res = await axios.get(`/v2/my/offer-counts`, getHeaders());
  return res.data as {
    all: number;
    nonCompetitive: number;
    withoutPrice: number;
  };
}

export async function importNewProducts(file: File) {
  const formData = new FormData();
  formData.append("file", file);

  const res = await axios.post(
    `/my/products${window.location.search}`,
    formData,
    getHeaders()
  );

  return res.data;
}

export async function getProductImportReports() {
  const res = await axios.get(
    `/my/products/reports${window.location.search}`,
    getHeaders()
  );
  return res.data as ProductImportReport[];
}

export async function getUnreadProductImportReport() {
  const res = await axios.get("/my/products/unread-report", getHeaders());
  return res.data as ProductImportReport;
}

export async function getProductImportReport(id: string) {
  const res = await axios.get(`/my/products/report/${id}`, getHeaders());
  return res.data as ProductImportReport;
}

export async function readProductImportReport(id: string) {
  await axios.post(`/my/products/read-report/${id}`, {}, getHeaders());
}

export async function getOfferImportReports() {
  const res = await axios.get(
    `/my/offers/reports${window.location.search}`,
    getHeaders()
  );
  return res.data as OfferImportReport[];
}

export async function getUnreadOfferImportReport() {
  const res = await axios.get("/my/offers/unread-report", getHeaders());
  return res.data as OfferImportReport;
}
export async function readOfferImportReport(id: string) {
  await axios.post(`/my/offers/read-report/${id}`, {}, getHeaders());
}
