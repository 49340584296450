import React, { memo, useCallback } from "react";
import { Modal } from "../../../ui/modal/Modal";
import type { ILogisticWarehouse } from "../../../../../../../packages/types/index";

interface Props {
	warehouse: ILogisticWarehouse;
	modal: boolean;
	setModal: (value: React.SetStateAction<boolean>) => void;
}

const CardInfoWarehouse: React.FC<Props> = ({ warehouse, modal, setModal }) => {
	const onModalClose = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			setModal(false);
		},
		[setModal]
	);

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="warehouse-modal-cont">
				<div className="table-modal-title-box mt3">
					<div className="warehouse-modal-title-text">Warehouse Contact</div>
				</div>
				<form className="table-modal-form-cont text-start">
					<div className="table-modal-border"></div>
					<div className="in-row align-center justify-between width100">
						<div className="width50 in-column justify-center align-start text-start pr2">
							<div className="bold">Warehouse Name:</div>
							<div>{warehouse.warehouseName}</div>
						</div>
						<div className="width50 in-column justify-center align-start text-start p2">
							<div className="in-row align-center justify-between width100">
								<img
									className="width30 img-border"
									height="60px"
									src={warehouse.logisticPartner.logo}
									alt=""
								/>
								<div className="width60">
									<div className="bold">Logistic Partner:</div>
									<div>{warehouse.logisticPartner.partnerName}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="width100 mb4">
						<div className="width100 in-row align-center justify-between mb4">
							<div className="width40 pr2">
								<div className="bold">Contact Name:</div>
								<div>{warehouse.contactName}</div>
							</div>
							<div className="width60 pl2">
								<div className="bold">E-mail:</div>
								<div>{warehouse.email}</div>
							</div>
						</div>
						<div className="width100 in-row align-center justify-between">
							<div className="width40">
								<div className="bold">Country of Operation:</div>
								<div>{warehouse.country}</div>
							</div>
							<div className="width30">
								<div className="bold">Phone:</div>
								<div>{warehouse.phone}</div>
							</div>
							<div className="width25">
								<div className="bold">Postal Code:</div>
								<div>{warehouse.postalCode}</div>
							</div>
						</div>
					</div>
					<div className="width100 mb4">
						<div className="bold">Address:</div>
						<div>{warehouse.address}</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="width100 in-row align-center justify-center pt3 pb3">
						<button className="table-modal-form-button" onClick={onModalClose}>
							Close
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(CardInfoWarehouse);
