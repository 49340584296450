import React, { memo, useCallback, useState } from "react";
import { useMutation } from "react-query";
import { Modal } from "../../../ui/modal/Modal";
import * as JobController from "../../../../actions/job";
import Preloader from "../../../ui/preloader/Preloader";
import { queryClient } from "../../../App";
import { ReactComponent as ActiveSyncLink } from "../../../../assets/ActiveSyncLink.svg";

interface Props {
  job: JobController.IJob;
}

const JobsRun: React.FC<Props> = ({ job }) => {
  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setModal(false);
    },
    [setModal]
  );

  const onModalOpen = useCallback(
    (e: React.MouseEvent) => {
      setModal(true);
    },
    [setModal]
  );

  const mutation = useMutation(() => JobController.executeJob(job._id), {
    onSuccess: () => {
      queryClient.invalidateQueries("jobs");
      setModal(false);
    },
  });

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      mutation.mutate();
    },
    [mutation]
  );

  if (mutation.isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <td onClick={onModalOpen} className="jobs-run pointer">
        <ActiveSyncLink />
      </td>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <>
            <div className="table-modal-form-cont p4">
              <div className="updateForm__container">
                <div className="vendor-mapping-modal-text">
                  Are you sure you want to run the job <br />{" "}
                  <strong>{job.name}</strong>?
                </div>
                <div className="table-modal-border"></div>
                <div className="table-modal-form-button-box width100 m0">
                  <button
                    className="table-modal-form-button-cancel"
                    onClick={onModalClose}
                  >
                    Cancel
                  </button>
                  <button className="table-modal-form-button" onClick={onClick}>
                    PROCEED
                  </button>
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

export default memo(JobsRun);
