import React, { useCallback, useMemo, useState } from "react";
import "./unregistered-table.css";
import { IPaginatedRequest, Role } from "../../../../interfaces";
import Paginator from "../../../ui/pagination";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Modal } from "../../../ui/modal/Modal";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useDebouncedValue } from "@mantine/hooks";
import { NavLink } from "react-router-dom";
import {
	downloadCategorizationFile,
	downloadExampleFile,
	downloadSellerFile,
	finishUnregisteredBatch,
	getPaginatedUnregisteredProducts,
	sendCategorizationFile,
	sendSellerFiles,
} from "../../../../actions/onboardingSeller";
import SubHeader from "../sub-header";
import queryString from "query-string";
import { useQuery } from "react-query";
import Preloader from "../../../ui/preloader/Preloader";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import { DateTime } from "luxon";
import { queryClient } from "../../../App";
import { round } from "lodash";

interface Props { }

enum ProductBathStatus {
	CREATED = "CREATED",
	FINISHED = "FINISHED",
}

export const UnregisteredTable: React.FC<Props> = () => {
	const [categorizationModal, setCategorizationModal] =
		useState<boolean>(false);
	const [dataUploadModal, setDataUploadModal] = useState<boolean>(false);
	const [importProductsModal, setImportProductsModal] =
		useState<boolean>(false);
	const [modal1, setModal1] = useState<boolean>(false);
	const [selectedBatch, setSelectedBatch] = useState<any>();
	const [finishModal, setFinishModal] = useState<boolean>(false);

	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const key = useMemo(
		() => `marketplaces/admin?${queryString.stringify(debouncedConfig)}`,
		[debouncedConfig]
	);

	const { isLoading: loadUnregisteredProducts, data: unregisteredProducts } =
		useQuery(key, () => getPaginatedUnregisteredProducts(debouncedConfig));

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	const handleFinishBatch = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			try {
				await finishUnregisteredBatch(selectedBatch._id);
				toast.success("Success");
				queryClient.invalidateQueries(key);
			} catch (error) {
				console.error(error);
				toast.error("Something went wrong");
			} finally {
				setFinishModal(false);
			}
		},
		[selectedBatch, setFinishModal, key]
	);

	const registeredPercentage = (registered: number, goal: number) => {
		return round((registered * 100) / goal);
	};

	const onModalClose1 = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal1(false);
	};

	return (
		<>
			<SubHeader label="Unregistered Products" />
			{loadUnregisteredProducts ? (
				<Preloader />
			) : (
				<>
					<div className="unregistered-table-cont">
						<div className="unregistered-table-search-cont">
							<div className="ml2">
								<form
									className="unregistered-table-search-form"
									onSubmit={(e) => e.preventDefault()}
								>
									<input
										className="table-search-input"
										placeholder="Search batch"
										onChange={(e) =>
											setCurrentConfig((conf: IPaginatedRequest) => ({
												...conf,
												search: e.target.value,
											}))
										}
										value={currentConfig.search}
									/>
									<img
										className="unregistered-table-search-image"
										src="/icons/search.svg"
										width="25px;"
										alt=""
									/>
								</form>
							</div>
							<div className="unregistered-table-value">
								<span className="unregistered-table-main-title-text">
									{loadUnregisteredProducts ? (
										<CircularPreloader />
									) : (
										<span className="table-main-title-text">
											{unregisteredProducts?.total} batches
										</span>
									)}
								</span>
							</div>
						</div>
						<div className="unregistered-table-pagination">
							<Paginator
								data={!loadUnregisteredProducts ? unregisteredProducts : {}}
								refetch={onChangePage as any}
							/>
						</div>
					</div>
					<div className="unregistered-table-overflow">
						<table className="unregistered-table-header">
							<tbody>
								<tr className="unregistered-table-results-title">
									<RoleRequired roles={[Role.user]} strict>
										<th className="unregistered-biggest-cell">
											Unregistered Product Batch
										</th>
									</RoleRequired>
									<RoleRequired>
										<th className="unregistered-small-cell">
											Unregistered Product Batch
										</th>
										<th className="unregistered-big-cell">Seller</th>
									</RoleRequired>
									<th className="unregistered-table-border-right" />
									<th className="unregistered-middle-cell">Categorization</th>
									<th className="unregistered-arrow-cell">
										<img src="/icons/orange-arrow.svg" alt="" />
									</th>
									<th className="unregistered-middle-cell">
										Product data upload
									</th>
									<th className="unregistered-arrow-cell">
										<img src="/icons/orange-arrow.svg" alt="" />
									</th>
									<th className="unregistered-middle-cell">
										Product Registration Status
									</th>
									<th className="unregistered-table-border-right" />
									<th className="unregistered-middle-cell">Batch Status</th>
								</tr>
							</tbody>
						</table>
						<div>
							<table className="unregistered-table-results p0">
								<tbody>
									{unregisteredProducts.data?.map((item: any) => (
										<tr
											className="unregistered-table-result-box unregistered-text"
											key={item._id}
										>
											<RoleRequired roles={[Role.user]} strict>
												<td className="unregistered-biggest-cell">
													UN Batch {item._id.slice(-5)}
												</td>
											</RoleRequired>
											<RoleRequired>
												<td className="unregistered-small-cell">
													UN Batch {item._id.slice(-5)}
												</td>
												<td className="unregistered-big-cell">
													{item.sellerName}
												</td>
											</RoleRequired>
											<td className="unregistered-table-border-right"></td>
											<td className="unregistered-middle-cell">
												<RoleRequired>
													<button
														className="unregistered-btn-upload"
														onClick={() => {
															setSelectedBatch(item);
															setCategorizationModal(true);
														}}
													>
														Send the file
													</button>
													{item?.categoryFile?.url && (
														<span className="unregistered-font-file">
															file sent
														</span>
													)}
												</RoleRequired>
												<RoleRequired roles={[Role.user]} strict>
													{item?.categoryFile?.url ? (
														<button
															className="unregistered-btn-upload"
															onClick={() => {
																setSelectedBatch(item);
																setCategorizationModal(true);
															}}
														>
															Check the result
														</button>
													) : (
														<span>Analyzing</span>
													)}
												</RoleRequired>
											</td>
											<td className="unregistered-arrow-cell"></td>
											<td className="unregistered-middle-cell">
												<RoleRequired>
													{item?.sellerFiles?.length ? (
														<button
															className="unregistered-btn-upload"
															onClick={() => {
																setSelectedBatch(item);
																setDataUploadModal(true);
															}}
														>
															See recieved files
														</button>
													) : (
														<span>No files recieved</span>
													)}
													{item.uploadedFiles > 0 && (
														<span className="unregistered-font-file">
															{item.uploadedFiles} uploaded files
														</span>
													)}
												</RoleRequired>
												<RoleRequired roles={[Role.user]} strict>
													{item?.categoryFile?.url ? (
														<button
															className="unregistered-btn-upload"
															onClick={() => {
																setSelectedBatch(item);
																setDataUploadModal(true);
															}}
														>
															<img src="/icons/upload-spreadsheet.svg" alt="" />
															<span className="ml1">Upload data</span>
														</button>
													) : (
														<span>Awaiting analysis</span>
													)}
													{item?.sellerFiles?.length > 0 && (
														<span className="unregistered-font-file">
															{item?.sellerFiles?.length} uploaded files
														</span>
													)}
												</RoleRequired>
											</td>
											<td className="unregistered-arrow-cell"></td>
											<td className="unregistered-middle-cell">
												<NavLink
													to={`/product-batch-log/${item._id}`}
													className="in-row align-center width100 pointer"
												>
													<div className="width40px mr2">
														<CircularProgressbar
															value={registeredPercentage(
																item.registered,
																item.total
															)}
															strokeWidth={20}
															styles={buildStyles({
																pathColor:
																	item.status === ProductBathStatus.FINISHED
																		? "#4CAB2B"
																		: "#F4733C",
																trailColor: "#e5e5e5",
																strokeLinecap: "round",
															})}
														/>
													</div>
													<div className="in-column align-start justify-start">
														<div
															style={{
																color:
																	item.status === ProductBathStatus.FINISHED
																		? "#4CAB2B"
																		: "",
															}}
															className="unregistered-font-light"
														>
															{item.registered} / {item.total}
														</div>
														<div
															style={{
																color:
																	item.status === ProductBathStatus.FINISHED
																		? "#4CAB2B"
																		: "",
															}}
															className="unregistered-font-bold"
														>
															{registeredPercentage(
																item.registered,
																item.total
															)}
															% Registered
														</div>
													</div>
												</NavLink>
											</td>
											<td className="unregistered-table-border-right"></td>
											<td className="unregistered-middle-cell">
												<RoleRequired roles={[Role.user]} strict>
													{item.status === ProductBathStatus.CREATED ? (
														<button className="unregistered-btn-progress">
															<span>In progress</span>
														</button>
													) : (
														<div className="in-column align-center">
															<span className="unregistered-btn-finished-seller">
																Finished
															</span>
															<span>
																{DateTime.fromISO(item.finished).toLocaleString(
																	DateTime.DATE_SHORT
																)}
															</span>
														</div>
													)}
												</RoleRequired>
												<RoleRequired>
													{item.status === ProductBathStatus.CREATED ? (
														<button
															className="unregistered-btn-finished"
															onClick={() => {
																setSelectedBatch(item);
																setFinishModal(true);
															}}
														>
															<img
																className="mr1"
																src="/icons/sel-green-check.svg"
																alt=""
															/>
															<span>Finish</span>
														</button>
													) : (
														<div className="in-column align-center">
															<span className="unregistered-btn-finished-seller">
																Finished
															</span>
															<span>
																{DateTime.fromISO(item.finished).toLocaleString(
																	DateTime.DATE_SHORT
																)}
															</span>
														</div>
													)}
												</RoleRequired>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						{categorizationModal && (
							<CategorizationModal
								batch={selectedBatch}
								modal={categorizationModal}
								setModal={setCategorizationModal}
								key={key}
							/>
						)}
						{dataUploadModal && (
							<DataUploadModal
								batch={selectedBatch}
								modal={dataUploadModal}
								setModal={setDataUploadModal}
								key={key}
							/>
						)}
					</div>
				</>
			)}
			{importProductsModal && (
				<ImportProductsModal
					modal={dataUploadModal}
					setModal={setDataUploadModal}
				/>
			)}
			{modal1 && (
				<Modal onModalClose={onModalClose1} isOpened={modal1}>
					<div className="table-modal-form-cont p4">
						<div className="table-modal-title-box m0">
							<div className="import-title">Import new product</div>
						</div>
						<div className="table-modal-border"></div>
						<div className="import-title">
							500 products were found for import.
						</div>
						<div className="email-text pt4 pb4">
							You will be notified when your products are completele imported.
						</div>
						<div className="table-modal-border"></div>
						<button
							className="sel-modal-form-button-cancel width100"
							onClick={onModalClose1}
						>
							Close
						</button>
					</div>
				</Modal>
			)}
			{finishModal && (
				<Modal
					onModalClose={() => setFinishModal(false)}
					isOpened={finishModal}
				>
					<div
						style={{ maxWidth: "420px" }}
						className="table-modal-form-cont p4"
					>
						<div className="updateForm__container">
							<div className="onb-modal-text">
								<strong className="pb1">
									Are you sure you want to finish this batch?
								</strong>
							</div>
							<div className="table-modal-border"></div>
							<div
								style={{ textAlign: "center" }}
								className="table-modal-form-button-box width100 m0"
							>
								<div
									onClick={() => setFinishModal(false)}
									style={{ background: "#fff", border: "1px solid #55718a" }}
									className="tax-title-button pointer"
								>
									<div
										style={{ color: "#55718a" }}
										className="pim-avaliacoes-title-button-text initial pn"
									>
										Cancel
									</div>
								</div>
								<div
									style={{ background: "#55718a", border: "1px solid #55718a" }}
									className="tax-title-button pointer"
								>
									<div
										style={{ color: "#fff" }}
										className="pim-avaliacoes-title-button-text initial pn"
										onClick={handleFinishBatch}
									>
										Yes, finish it
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

interface CategorizationModalProps {
	batch: any;
	modal: boolean;
	setModal: (modal: React.SetStateAction<boolean>) => void;
	key: string;
}

const CategorizationModal: React.FC<CategorizationModalProps> = ({
	batch,
	modal,
	setModal,
	key,
}) => {
	const [file, setFile] = useState<File>();

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setFile(e.target.files[0]);
		}
	};

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			try {
				await sendCategorizationFile(batch?._id, file!);
				toast.success("Success");
				queryClient.invalidateQueries(key);
			} catch (error) {
				console.error(error);
				toast.error("Something went wrong. Please, check you file once again");
			}

			setModal(false);
		},
		[setModal, file, batch?._id]
	);

	const handleGetFile = async (e: React.FormEvent) => {
		e.preventDefault();

		try {
			await downloadCategorizationFile(batch._id, batch.categoryFile.name);
			toast.success("Success");
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}

		setModal(false);
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="pc-modal-cont">
				<div className="pc-modal-close pointer" onClick={() => setModal(false)}>
					<img src="/icons/delete-x.svg" alt="" />
				</div>
				<div className="pc-modal-title-box">
					<div className="pc-modal-title-text">Product categorization</div>
				</div>
				<div className="table-modal-border mb1"></div>
				<RoleRequired>
					<div className="pc-modal-main-text mt3 mb3">
						Send the spreadsheet of the seller's categorized products to
						facilitate the completion of registration.
					</div>
					<div className="pc-modal-spreadsheet-button pointer">
						<img src="/icons/upload-spreadsheet.svg" alt="" />
						<div className="pc-modal-spreadsheet-button-text initial">
							<label className="pointer">
								Upload the spreadsheet
								<input type="file" onChange={handleFileChange} />
							</label>
						</div>
					</div>
					{batch?.categoryFile?.created && (
						<div className="pc-modal-date-text">
							Sent on{" "}
							{DateTime.fromISO(batch.categoryFile.created).toLocaleString(
								DateTime.DATETIME_MED
							)}
						</div>
					)}
					<div className="table-modal-border mb3"></div>
					<div className="table-modal-form-button-box width100">
						<button
							className="table-modal-form-button-cancel"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button
							className={`table-modal-form-button ${!file && "disabled"}`}
							onClick={onSubmit}
						>
							Save
						</button>
					</div>
				</RoleRequired>
				<RoleRequired roles={[Role.user]} strict>
					<div className="pc-modal-main-text mt3">
						Get the spreadsheet of your categorized products to see what
						information is needed to complete of registration
					</div>
					<div className="pc-modal-text">
						You can send us the completed spreadsheet to speed up the
						registration of your products
					</div>
					<div
						className="pc-modal-spreadsheet-button pointer"
						onClick={handleGetFile}
					>
						<img src="/icons/file-download.svg" alt="" />
						<div className="pc-modal-spreadsheet-button-text initial">
							Get the spreadsheet
						</div>
					</div>
					{batch?.categoryFile?.created && (
						<div className="pc-modal-date-text">
							Created on{" "}
							{DateTime.fromISO(batch.categoryFile.created).toLocaleString(
								DateTime.DATETIME_MED
							)}
						</div>
					)}
					<div className="table-modal-border mb3"></div>
					<div
						className="width100 align-center justify-center text-color bold ft2 pointer"
						onClick={() => setModal(false)}
					>
						Close
					</div>
				</RoleRequired>
			</div>
		</Modal>
	);
};

interface DataUploadModalProps {
	batch: any;
	modal: boolean;
	setModal: (modal: React.SetStateAction<boolean>) => void;
	key: string;
}

const DataUploadModal: React.FC<DataUploadModalProps> = ({
	batch,
	modal,
	setModal,
	key,
}) => {
	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({
			accept: [".xlsx"],
			onDropRejected: () => {
				toast.error('File type rejected. Only ".xlsx" files are allowed.');
			},
		});

	const onSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		try {
			await sendSellerFiles(batch._id, acceptedFiles);
			toast.success("Success");
			queryClient.invalidateQueries(key);
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong. Please, check you files once again");
		}

		setModal(false);
	};

	const handleGetSellerFile = async (e: React.FormEvent, id: string) => {
		e.preventDefault();

		const file = batch?.sellerFiles?.find((file: any) => file._id === id);

		try {
			await downloadSellerFile(batch._id, file._id, file.name);
			toast.success("Success");
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}

		setModal(false);
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="pc-modal-cont">
				<div className="pc-modal-close pointer" onClick={onModalClose}>
					<img src="/icons/delete-x.svg" alt="" />
				</div>
				<RoleRequired>
					<div className="pc-modal-title-box">
						<div className="pc-modal-title-text">Received data files</div>
					</div>
					<div className="table-modal-border mb1"></div>
					<div className="pc-modal-main-text mb3 mt3">
						Download the product registration information files sent by the
						seller:
					</div>
					{batch?.sellerFiles?.map((file: any) => (
						<React.Fragment key={file?._id}>
							<div
								className="in-row align-center pointer"
								onClick={(e) => handleGetSellerFile(e, file._id)}
							>
								<img src="/icons/filetype-xlsx.svg" alt="" />
								<div className="pc-modal-file-name-text">{file?.name}</div>
							</div>
							<div className="pc-modal-date-text">
								Posted on{" "}
								{DateTime.fromISO(file?.created).toLocaleString(
									DateTime.DATETIME_MED
								)}
							</div>
							<div className="pc-modal-file-border"></div>
						</React.Fragment>
					))}
					<div
						className="width100 align-center justify-center text-color bold ft2 pointer"
						onClick={() => setModal(false)}
					>
						Close
					</div>
				</RoleRequired>
				<RoleRequired roles={[Role.user]} strict>
					<div className="pc-modal-title-box">
						<div className="pc-modal-title-text">Product data upload</div>
					</div>
					<div className="table-modal-border mb5"></div>
					<div className="in-row align-center">
						<span className="pc-modal-main-text mr0">
							Upload the spreadsheet of categorized products with the
							information filled in:{" "}
							<small className="pc-modal-small-text">(file format .xlsx)</small>
						</span>
					</div>
					<div className="pc-modal-dropzone pointer" {...getRootProps()}>
						{/* @ts-ignore */}
						<input {...getInputProps()} />
						{isDragActive ? (
							<h4>Drop the file here</h4>
						) : (
							<h2 className="pc-modal-dropzone-text">
								{acceptedFiles.length > 0
									? `Click 'Save' to finish upload`
									: "Drop the file here"}
							</h2>
						)}
					</div>
					<div className="table-modal-border mb3"></div>
					<div className="table-modal-form-button-box width100">
						<button
							className="table-modal-form-button-cancel"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button
							className={`table-modal-form-button ${!acceptedFiles.length && "disabled"
								}`}
							onClick={onSubmit}
						>
							Save
						</button>
					</div>
				</RoleRequired>
			</div>
		</Modal>
	);
};

interface ImportProductsModalProps {
	modal: boolean;
	setModal: (modal: React.SetStateAction<boolean>) => void;
}

const ImportProductsModal: React.FC<ImportProductsModalProps> = ({
	modal,
	setModal,
}) => {
	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({
			accept: [".xlsx"],
			onDropRejected: () => {
				toast.error('File type rejected. Only ".xlsx" files are allowed.');
			},
		});

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			setModal(false);
		},
		[setModal]
	);

	const downloadFile = async (e: React.MouseEvent) => {
		e.preventDefault();

		try {
			await downloadExampleFile();
			toast.success("Success");
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="table-modal-form-cont p4">
				<div className="table-modal-title-box m0">
					<div className="import-title">Import new product</div>
				</div>
				<div className="table-modal-border"></div>
				<span className="mt2 in-row width100 align-center">
					<b className="mr1">Upload the file</b>
					(Allowed file format .xlsx)
				</span>
				<div className="pc-modal-dropzone pointer" {...getRootProps()}>
					{/* @ts-ignore */}
					<input {...getInputProps()} />
					{isDragActive ? (
						<h4>Drop the file here</h4>
					) : (
						<h2 className="pc-modal-dropzone-text">
							{acceptedFiles.length > 0
								? `Click 'Save' to finish upload ${acceptedFiles[0].name}`
								: "Drop the file here"}
						</h2>
					)}
				</div>
				<div
					className="bold mt2 in-row width100 align-center"
					style={{ color: "#55718a" }}
					onClick={downloadFile}
				>
					<div style={{ fontSize: "12px" }} className="pointer">
						Download Template Spreadsheet
					</div>
				</div>
				<div className="table-modal-border"></div>
				<div
					style={{ marginBottom: "0" }}
					className="table-modal-form-button-box width100"
				>
					<button
						className="sel-modal-form-button-cancel"
						onClick={onModalClose}
					>
						Cancel
					</button>
					<button
						onClick={onSubmit}
						className="table-modal-form-button initial"
					>
						Import
					</button>
				</div>
			</div>
		</Modal>
	);
};
